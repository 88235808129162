export class Permission {
  scope: string;
  action: string;
  context?: string;
  value: string;

  constructor(value: string) {
    const parts = value.split('.');

    this.scope = parts[0];
    this.action = parts[1];
    this.context = parts.length > 2 ? parts[2] : undefined;
    this.value = value;
  }
}