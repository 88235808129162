import { useAssetFilter } from '../../assets';
import { getWorkspaceFilter } from '../../auth';
import { ActivityFilterModel } from './activityFilterModel';

export const getActivityFilter = (
  filter: ActivityFilterModel,
  workspaceId: string,
  assetId?: string
) => {
  let f = filter?.getFilter() || {};
  f = getWorkspaceFilter(workspaceId, f);
  f = useAssetFilter(assetId, f);
  return f;
};
