import { useCallback, useEffect, useState } from "react";

const hasWindow = typeof window !== 'undefined';

export const useWindowDimensions = () => {

  const getDimensions = useCallback(() => {
    return getCurrentDimensions();
  }, []);
  const [windowDimensions, setWindowDimensions] = useState(getDimensions());

   useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [getDimensions]);

  return windowDimensions;
};

export const getCurrentDimensions = () => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;

    return {
      width,
      height
    };
};