import { cacheKeys, useDeleteMutation } from '@gripp/shared-logic';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import CustomAlert from '../customAlert';

type DeleteIssueModalProps = {
  visible: boolean;
  hide: () => void;
  issueId: string;
  onDelete: () => void;
};

export const DeleteIssueModal = (props: DeleteIssueModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync } = useDeleteMutation({
    modelName: 'issue',
    query: DELETE_ISSUE,
  });

  const doDelete = async () => {
    const input = {
      id: props.issueId,
    };

    await mutateAsync(
      { input: input },
      {
        onSuccess: async () => {
          const cacheKey = cacheKeys('workspaceasset').all;
          await queryClient.invalidateQueries({ queryKey: cacheKey });
          props.onDelete();
        },
      }
    );
  };

  return (
    <CustomAlert
      visible={props.visible}
      hideAlert={props.hide}
      action={doDelete}
      title={t('issue.delete.confirm')}
      primaryButtonLabel={t('topNav.customAlert.yes')}
      secondaryButtonLabel={t('topNav.customAlert.cancel')}
    />
  );
};

export const DELETE_ISSUE = `
mutation deleteIssue($input: MutateIssueInput!) {
  deleteIssue(input: $input) {
    id
  }
}
`;

const styles = StyleSheet.create({});
