import { Workspace } from '@gripp/shared-logic';
import { TFunction } from 'i18next';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../themes/colors';

export const InvitationIntro = ({
  workspace,
  t,
}: {
  workspace?: Workspace;
  t: TFunction;
}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.intro}>
        {t('invitation.pending.joining', { workspaceName: workspace?.name })}
      </Text>
      <Text>...</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.primaryGrayBackground,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  intro: {
    fontSize: 22,
    fontWeight: '700',
  },
});
