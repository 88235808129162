import { Text } from 'react-native-paper';
import { MessageComponent, MessageComponentProps } from '../../issue/types';
import { CommonRoutineMessageHeader } from './commonRoutineMessageHeader';
import { routineMessageStyles } from './routineMessageStyles';

export const CommonRoutineMessage: MessageComponent = (
  props: MessageComponentProps
) => {
  return (
    <CommonRoutineMessageHeader {...props}>
      <Text style={routineMessageStyles.text}>{props.routineText}</Text>
    </CommonRoutineMessageHeader>
  );
};
