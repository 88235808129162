import { StyleSheet } from 'react-native';
import { Colors } from '../../../themes';

export const routineMessageStyles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
  },
  messageTitle: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.grayText,
  },
  questionContainer: {
    marginVertical: 5,
    borderRadius: 8,
    padding: 12,
  },
  exceptionsContainer: {
    backgroundColor: Colors.redBackground,
  },
  noExceptionsContainer: {
    backgroundColor: Colors.primaryGrayBackground,
  },
  questionLabel: {
    color: Colors.tabInactive,
  },
  questionLabelException: {
    color: Colors.redText,
  },
  text: {
    color: Colors.tabInactive,
  },
  textException: {
    color: Colors.redText,
  },
});
