import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { Checkbox } from 'react-native-paper';

type CheckboxOption = {
  value: string;
  label?: string;
};

type ControlledCheckboxItemProps = {
  name: string;
  control: Control;
  options: CheckboxOption[];
  position: 'leading' | 'trailing';
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  mode?: 'android' | 'ios';
};

export const ControlledCheckboxItems = (props: ControlledCheckboxItemProps) => {
  const { append, remove } = useFieldArray({
    name: props.name,
    control: props.control,
  });
  const values = useWatch({
    name: props.name,
    control: props.control,
  });

  const onChange = (value: string) => {
    if (values?.includes(value)) {
      remove(values.indexOf(value));
    } else {
      append(value);
    }
  };

  return (
    <View>
      {props.options.map((option) => (
        <Checkbox.Item
          key={option.value}
          style={props.style}
          labelStyle={props.labelStyle}
          label={option.label || option.value}
          position={props.position}
          status={values?.includes(option.value) ? 'checked' : 'unchecked'}
          onPress={() => onChange(option.value)}
          mode={props.mode}
        />
      ))}
    </View>
  );
};
