import { StorageHandler } from "@gripp/shared-logic";

export const localStorageHandler: StorageHandler = {
  getItem: function (key: string): Promise<string | null> {
    return Promise.resolve(localStorage.getItem(key));
  },
  setItem: function (key: string, value: string): Promise<void> {
    localStorage.setItem(key, value);
    return Promise.resolve();
  },
  deleteItem: function (key: string): Promise<void> {
    localStorage.removeItem(key);
    return Promise.resolve();
  },
  clear: function (): Promise<void> {
    localStorage.clear();
    return Promise.resolve();
  }
};