import { Role } from '@gripp/shared-logic';
import { AccountTypeMenu } from './accountTypeMenu';

export interface onPressProps {
  id: string;
  selectedRoles: string[];
  action?: 'accountType' | 'remove';
}

export const getAccountColumn = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info: any,
  roleList: Role[] = [],
  onPress: (props: onPressProps) => Promise<void>
) => {
  const account = info.row.original;
  const { id, roles } = account || {};

  if (!id || !roles || roles.length === 0) {
    return null;
  }

  return (
    <AccountTypeMenu onPress={onPress} roleList={roleList} account={account} />
  );
};
