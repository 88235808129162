import {
  default as Icon,
  default as MaterialCommunityIcons,
} from '@expo/vector-icons/MaterialCommunityIcons';
import { StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors, FormFactor } from '../themes';

export enum SectionHeaderVariant {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
}

export interface SectionHeaderProps {
  title: string;
  icon: React.ComponentProps<typeof MaterialCommunityIcons>['name'];
  variant: FormFactor;
}

export const SectionHeader = (props: SectionHeaderProps) => {
  const containerStyle = styles[`${props.variant}_Container`];
  const textStyle = styles[`${props.variant}_Text`];
  const iconStyle = styles[`${props.variant}_Icon`];

  return (
    <View style={containerStyle}>
      <Icon name={props.icon} style={iconStyle} />
      <Text style={textStyle}>{props.title}</Text>
    </View>
  );
};

const baseContainer: ViewStyle = {
  backgroundColor: Colors.primaryGrayHeader,
  flex: 1,
  width: '100%',
  alignItems: 'center',
  flexDirection: 'row',
};

const baseText: TextStyle = {
  color: Colors.black,
  letterSpacing: 0,
  lineHeight: 28,
  fontWeight: '700',
  textAlign: 'left',
};

const baseIcon: TextStyle = {
  color: Colors.black,
};

const styles = StyleSheet.create({
  Desktop_Container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  Desktop_Text: {
    ...baseText,
    fontSize: 22,
  },
  Desktop_Icon: {
    ...baseIcon,
    paddingRight: 8,
    fontSize: 24,
  },
  Mobile_Container: {
    ...baseContainer,
    borderStyle: 'solid',
    borderColor: Colors.grayDivider,
    borderBottomWidth: 0.5,
    padding: 20,
    justifyContent: 'flex-start',
  },
  Mobile_Text: {
    ...baseText,
    fontSize: 22,
  },
  Mobile_Icon: {
    ...baseIcon,
    paddingRight: 5,
    fontSize: 20,
  },
});
