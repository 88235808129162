import {
  AssetRoutineState,
  Severity,
  WorkspaceRoutine,
} from '@gripp/shared-logic';
import { StyleProp, View, ViewStyle } from 'react-native';
import { RoutineModules } from '../routineModules';
import { RoutineModule } from '../types';

type RoutineModuleButtonProps = {
  routine: WorkspaceRoutine;
  state?: AssetRoutineState<any>;
  buttonIndex: number;
  buttonCount: number;
  buttonStyle?: StyleProp<ViewStyle>;
  buttonIcon?: Severity;
  buttonIconStyle?: StyleProp<ViewStyle>;
  onClick: (
    routine: WorkspaceRoutine,
    module: RoutineModule<any, any>,
    state?: AssetRoutineState<any>,
    additionalState?: any
  ) => void;
};

export const RoutineModuleButton = (props: RoutineModuleButtonProps) => {
  const module = RoutineModules[props.routine.type];
  const ModuleButtons = module.buttons;

  return (
    <View
      key={props.routine.id}
      style={
        props.buttonIndex === props.buttonCount - 1 && props.buttonCount > 1
          ? { marginBottom: 0 }
          : { marginBottom: 16 }
      }
    >
      <ModuleButtons
        key={props.routine.id}
        config={props.routine.config}
        currentState={props.state}
        styleOverrides={{
          buttonStyle: props.buttonStyle,
          buttonIcon: props.buttonIcon,
          buttonIconStyle: props.buttonIconStyle,
        }}
        onClick={async (additionalState: any) =>
          await props.onClick(
            props.routine,
            module,
            props.state,
            additionalState
          )
        }
      />
    </View>
  );
};
