import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { styles } from './styles';

type ToggleLoginTypeProps = {
  text: string;
  onChangeLoginType: () => void;
};

export const ToggleLoginType = (props: ToggleLoginTypeProps) => {
  const { t } = useTranslation();

  return (
    <>
      <View style={styles.toggleContainer}>
        <Button
          style={[styles.button, styles.toggleButton]}
          mode="contained"
          onPress={props.onChangeLoginType}
        >
          <Text style={styles.toggleText}>{props.text}</Text>
        </Button>
      </View>
    </>
  );
};
