import { Account, Role } from '@gripp/shared-logic';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { RadioButton } from 'react-native-paper';
import { Colors } from '../../themes';
import CustomAlert from '../customAlert';
import { onPressProps } from './getAccountColumn';

type Props = {
  account: Account;
  onSelect: (props: onPressProps) => void;
  roleList: Role[];
  closeMenu: () => void;
};

export const AccountTypeSelector = ({
  account,
  onSelect,
  roleList,
  closeMenu,
}: Props) => {
  const { id, roles } = account;
  const { t } = useTranslation();
  const [selectedRoleId, setSelectedRoleId] = useState(roles?.[0].id ?? '');
  const [openRemoveConfirmModal, setOpenRemoveConfirmModal] = useState(false);

  useEffect(() => {
    if (roles && roles.length > 0) {
      setSelectedRoleId(roles[0].id);
    }
  }, [roles]);

  const handleSelectItem = useCallback(
    (roleId: string) => {
      setSelectedRoleId(roleId);
      onSelect({ id: id || '', selectedRoles: [roleId] });
      closeMenu();
    },
    [closeMenu, id, onSelect]
  );

  const handleOpenModal = () => {
    setOpenRemoveConfirmModal(true);
  };

  const handleRemove = () => {
    onSelect({ id: id || '', selectedRoles: [], action: 'remove' });
    closeMenu();
  };

  return (
    <View>
      <RadioButton.Group
        onValueChange={handleSelectItem}
        value={selectedRoleId}
      >
        {roleList.map(({ id, name }) => (
          <RadioButton.Item
            key={id}
            label={name}
            value={id}
            labelStyle={[styles.label, styles.checkItem]}
            style={styles.item}
          />
        ))}
      </RadioButton.Group>
      <Pressable onPress={handleOpenModal}>
        <Text style={[styles.label, styles.remove]}>Remove</Text>
      </Pressable>
      <CustomAlert
        visible={openRemoveConfirmModal}
        hideAlert={() => {
          setOpenRemoveConfirmModal(false);
          closeMenu();
        }}
        primaryButtonLabel={t('account.menu.confirmDelete.primaryButton')}
        secondaryButtonLabel={t('account.menu.confirmDelete.secondaryButton')}
        title={t('account.menu.confirmDelete.title', {
          accountName: `${account.name?.first} ${account.name?.last}`,
        })}
        action={handleRemove}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    paddingVertical: 0,
    paddingRight: 4,
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
  },
  checkItem: { color: Colors.blackText },
  remove: {
    paddingTop: 10,
    paddingBottom: 2,
    paddingHorizontal: 16,
    color: Colors.red,
    borderTopWidth: 1,
    borderColor: Colors.graySpacer,
  },
});
