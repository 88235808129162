import { Pressable, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { Text } from 'react-native-paper';
import { EmojiType } from 'rn-emoji-keyboard';

type SingleEmojiProps = {
  emoji: EmojiType['emoji'];
  onPress?: () => void;
  emojiContainerStyle?: StyleProp<ViewStyle>;
  emojiStyle: StyleProp<TextStyle>;
  onLongPress?: () => void;
};

export const SingleEmoji = (props: SingleEmojiProps) => (
  <Pressable
    onPress={props?.onPress}
    style={props.emojiContainerStyle}
    onLongPress={props?.onLongPress}
  >
    <Text style={props.emojiStyle}>{props.emoji || '😀'}</Text>
  </Pressable>
);
