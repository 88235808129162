import { StyleSheet, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { Colors } from '../themes';

type LoadingIndicatorProps = {
  backgroundColor?: string;
  size?: number;
  animating?: boolean;
  flexContainer?: boolean;
};

export const LoadingIndicator = (props: LoadingIndicatorProps) => {
  return (
    <View
      style={[
        styles.activityIndicatorContainer,
        {
          backgroundColor: props.backgroundColor || Colors.white,
          flex: props.flexContainer ? 1 : undefined,
        },
      ]}
    >
      <ActivityIndicator
        animating={props.animating || true}
        size={props.size || 35}
        color={Colors.primary}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  activityIndicatorContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default LoadingIndicator;
