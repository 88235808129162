import { createContext, ReactNode, useContext, useState } from "react";

type Dispatch = (issue: any) => void;
export const CurrentIssueContext = createContext<{currentIssue: any; setCurrentIssue: Dispatch} | undefined>(undefined);

export type CurrentIssueContextProviderProps = {
  issue?: any;
  children: ReactNode;
};

export const useCurrentIssueContext = () => {
  return useContext(CurrentIssueContext);
}

export const CurrentIssueContextProvider =(props: CurrentIssueContextProviderProps) => {
  const [currentIssue, setCurrentIssue] = useState(props.issue);

  return <CurrentIssueContext.Provider value={{ currentIssue, setCurrentIssue}}>{props.children}</CurrentIssueContext.Provider>;
};