import { useAccountFilter, useSetAccountFilter } from '../../account';
import {
  ActivityFilterKey,
  useActivityListFilter,
  useSetActivityListFilter,
} from '../../activities';
import {
  useAssetListFilter,
  useSetAssetListFilter,
  useValidateAssetListOrder,
} from '../../assets';
import { useSetCurrentWorkspace } from '../../auth';
import { FilterModel } from '../../filter';
import { Workspace } from '../domain';
import { useCallback } from 'react';

export const useImpersonateWorkspace = () => {
  const { mutateAsync: setCurrentWorkspace } = useSetCurrentWorkspace();
  const { data: accountFilter } = useAccountFilter();
  const { mutateAsync: setAccountFilter } = useSetAccountFilter();

  const { data: activityFilter } = useActivityListFilter(
    ActivityFilterKey.Activity
  );
  const { mutateAsync: setActivityListFilter } = useSetActivityListFilter(
    ActivityFilterKey.Activity
  );

  const { data: assetFilter } = useAssetListFilter();
  const { mutateAsync: setAssetListFilter } = useSetAssetListFilter();
  const validateAssetListOrder = useValidateAssetListOrder();

  const resetFilter = useCallback(
    async (filter: FilterModel, setFilter: (f: any) => Promise<void>) => {
      if (filter?.scrubWorkspaceData) {
        filter.scrubWorkspaceData();
        await setFilter(filter);
      }
    },
    []
  );

  const resetFilters = useCallback(async () => {
    await resetFilter(accountFilter, setAccountFilter);
    await resetFilter(activityFilter, setActivityListFilter);
    await resetFilter(assetFilter, setAssetListFilter);
    await validateAssetListOrder(assetFilter);
  }, [
    accountFilter,
    activityFilter,
    assetFilter,
    resetFilter,
    setAccountFilter,
    setActivityListFilter,
    setAssetListFilter,
    validateAssetListOrder,
  ]);

  const impersonateWorkspace = async (workspace?: Workspace) => {
    await setCurrentWorkspace(
      workspace ? JSON.stringify(workspace) : undefined
    );
    await resetFilters();
  };

  return {
    impersonateWorkspace,
  };
};
