import {
  WorkflowRoutineConfig,
  WorkflowRoutineState,
} from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { useFormContext } from '../../../forms';
import { RoutineExecutionForm, RoutineExecutionFormProps } from '../../types';
import { WorkflowExecutionPage } from './workflowExecutionPage';

export const WorkflowExecutionForm: RoutineExecutionForm<
  WorkflowRoutineConfig,
  WorkflowRoutineState
> = (
  props: RoutineExecutionFormProps<WorkflowRoutineConfig, WorkflowRoutineState>
) => {
  const [initialized, setInitialized] = useState(false);
  const { control, setValue } = useFormContext();

  control.register('responses');

  useEffect(() => {
    const questions: { questionId: string }[] = [];
    props.config.pages.forEach((page) => {
      page.questions.forEach((question) => {
        questions.push({ questionId: question.id });
      });
    });

    setValue('responses', questions);
    setInitialized(true);
  }, []);

  return (
    initialized && (
      <WorkflowExecutionPage
        page={props.config.pages[0]}
        workspaceAsset={props.workspaceAsset}
        toggleProcessing={props.toggleProcessing}
        currentState={props.currentState}
      />
    )
  );
};
