import {
  ActivityFilterKey,
  useAccountFilter,
  useAccountTypeFilter,
  useActivityListFilter,
  useAssetListFilter,
  useGroupListFilter,
} from '@gripp/shared-logic';
import { BaseFilterButton } from './baseFilterButton';

export enum FilterType {
  ACCOUNT,
  ACCOUNTCOLUMNS,
  ACCOUNTGROUPS,
  ACCOUNTTYPES,
  ACTIVITYTYPES,
  ACTIVITYGROUPS,
  ACTIVITYDATE,
  ASSETGROUPS,
  ASSETCOLUMNS,
  GROUPCOLUMNS,
}

type FilterButtonProps = {
  type: FilterType;
  filterKey?: string;
  onGoFilter: () => void;
};

export const FilterButton = (props: FilterButtonProps) => {
  let FilterComponent;

  switch (props.type) {
    case FilterType.ACCOUNTGROUPS:
      FilterComponent = AccountGroupsFilterButton;
      break;
    case FilterType.ACCOUNTCOLUMNS:
      FilterComponent = AccountColumnsFilterButton;
      break;
    case FilterType.ACCOUNTTYPES:
      FilterComponent = AccountTypesFilterButton;
      break;
    case FilterType.ACTIVITYTYPES:
      FilterComponent = ActivityTypesFilterButton;
      break;
    case FilterType.ACTIVITYDATE:
      FilterComponent = ActivitDateFilterButton;
      break;
    case FilterType.ACTIVITYGROUPS:
      FilterComponent = ActivityGroupsFilterButton;
      break;
    case FilterType.ASSETGROUPS:
      FilterComponent = AssetGroupsFilterButton;
      break;
    case FilterType.ASSETCOLUMNS:
      FilterComponent = ColumnsFilterButton;
      break;
    case FilterType.GROUPCOLUMNS:
      FilterComponent = GroupColumnsFilterButton;
      break;
    default:
      FilterComponent = () => null;
      break;
  }

  return <FilterComponent {...props} />;
};

const ActivityTypesFilterButton = (props: FilterButtonProps) => {
  const { data } = useActivityListFilter(props.filterKey as ActivityFilterKey);
  const icon = 'label-outline';
  const label = 'activity.filter.labels';

  return (
    data && (
      <BaseFilterButton
        icon={icon}
        label={label}
        filter={data}
        isTypesFilter={true}
        onGoFilter={props.onGoFilter}
      />
    )
  );
};

const ActivityGroupsFilterButton = (props: FilterButtonProps) => {
  const { data } = useActivityListFilter(props.filterKey as ActivityFilterKey);
  const icon = 'google-circles-extended';
  const label = 'activity.filter.groups';

  return (
    data && (
      <BaseFilterButton
        icon={icon}
        label={label}
        filter={data}
        onGoFilter={props.onGoFilter}
      />
    )
  );
};

const AssetGroupsFilterButton = (props: FilterButtonProps) => {
  const { data } = useAssetListFilter();

  const icon = 'google-circles-extended';
  const label = 'assets.filter.groupsButton';

  return (
    data && (
      <BaseFilterButton
        icon={icon}
        label={label}
        filter={data}
        onGoFilter={props.onGoFilter}
      />
    )
  );
};

const ColumnsFilterButton = (props: FilterButtonProps) => {
  const { data } = useAssetListFilter();

  const icon = 'table-chart';
  const label = 'assets.filter.columnButton';

  return (
    data && (
      <BaseFilterButton
        icon={icon}
        label={label}
        filter={data}
        onGoFilter={props.onGoFilter}
        isColumnsFilter={true}
      />
    )
  );
};

const GroupColumnsFilterButton = (props: FilterButtonProps) => {
  const { data } = useGroupListFilter();

  const icon = 'table-chart';
  const label = 'groups.filter.columnButton';

  return (
    data && (
      <BaseFilterButton
        icon={icon}
        label={label}
        filter={data}
        onGoFilter={props.onGoFilter}
        isColumnsFilter={true}
      />
    )
  );
};

const AccountGroupsFilterButton = (props: FilterButtonProps) => {
  const { data } = useAccountFilter();

  const icon = 'google-circles-extended';
  const label = 'account.filter.groupsButton';

  return (
    data && (
      <BaseFilterButton
        icon={icon}
        label={label}
        filter={data}
        onGoFilter={props.onGoFilter}
      />
    )
  );
};

const AccountColumnsFilterButton = (props: FilterButtonProps) => {
  const { data } = useAccountTypeFilter();

  const icon = 'table-chart';
  const label = 'account.filter.columnButton';

  return (
    data && (
      <BaseFilterButton
        icon={icon}
        label={label}
        filter={data}
        onGoFilter={props.onGoFilter}
        isColumnsFilter={true}
      />
    )
  );
};

const AccountTypesFilterButton = (props: FilterButtonProps) => {
  const { data } = useAccountFilter();

  const icon = 'shield-key-outline';
  const label = 'account.filter.accountTypesButton';

  return (
    data && (
      <BaseFilterButton
        icon={icon}
        label={label}
        filter={data}
        onGoFilter={props.onGoFilter}
        isAccountTypesFilter={true}
      />
    )
  );
};

const ActivitDateFilterButton = (props: FilterButtonProps) => {
  const { data } = useActivityListFilter(props.filterKey as ActivityFilterKey);
  const icon = 'calendar';
  const label = 'activity.filter.date';

  return (
    data && (
      <BaseFilterButton
        icon={icon}
        label={label}
        filter={data}
        isDateFilter
        onGoFilter={props.onGoFilter}
      />
    )
  );
};
