import {
  EnvConfig,
  useCreateMutation,
  useCurrentAccount,
  useCurrentWorkspace,
} from '@gripp/shared-logic';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import QRCode from 'react-native-qrcode-svg';
import { Colors } from '../../themes';
import { LoadingIndicator } from '../loadingIndicator';

type CreateInvitationProps = {
  qrSize: number;
  onSetInvitation?: ({
    url,
    invitationId,
  }: {
    url: string;
    invitationId: string;
  }) => void;
};

const createInvitaionUrl = (
  invitationId: string,
  workspaceId: string
): string => {
  const subDomain = EnvConfig.prefix === 'prod' ? 'www' : `${EnvConfig.prefix}`;

  return `https://${subDomain}.gripp.it/invitation/${invitationId}/${workspaceId}`;
};

export const CreateInvitation = (props: CreateInvitationProps) => {
  const [invitationId, setInvitationId] = useState<string | undefined>(
    undefined
  );
  const { t } = useTranslation();

  const getInvitationUrl = useCallback(() => {
    if (!invitationId) return undefined;

    return createInvitaionUrl(invitationId, workspace.id);
  }, [invitationId]);

  const { account } = useCurrentAccount();
  const workspace = useCurrentWorkspace();

  const { mutateAsync: createInvitation } = useCreateMutation({
    modelName: 'invitation',
    query: CREATE_INVITATION,
  });

  useEffect(() => {
    const getInvitation = async () => {
      await createInvitation(
        {
          input: {
            workspace: { id: workspace.id },
          },
        },
        {
          onSuccess: (data) => {
            setInvitationId(data.id);
            if (props?.onSetInvitation) {
              props.onSetInvitation({
                url: createInvitaionUrl(data.id, workspace.id),
                invitationId: data.id,
              });
            }
          },
        }
      );
    };

    if (workspace) getInvitation();
  }, [workspace]);

  return (
    <View style={styles.container}>
      {invitationId ? (
        <>
          <View style={styles.header}>
            <Text style={[styles.text, styles.bold]}>
              {`${account?.name?.first} `}
            </Text>
            <Text style={styles.text}>{`${t(
              'invitation.qr.invitesYou'
            )} `}</Text>
            <Text style={[styles.text, styles.bold]}>{workspace?.name}</Text>
          </View>
          <QRCode value={getInvitationUrl()} size={props.qrSize} />
        </>
      ) : (
        <LoadingIndicator
          backgroundColor={
            Platform.OS === 'web' ? Colors.primaryGrayBackground : undefined
          }
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 20,
  },
  header: {
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    ...Platform.select({
      web: {
        flexDirection: 'row',
      },
      default: {
        flexDirection: 'column',
      },
    }),
  },
  text: {
    ...Platform.select({
      web: {
        fontSize: 16,
        fontWeight: '500',
        lineHeight: 20.8,
      },
      default: {
        fontSize: 22,
        fontWeight: '400',
        lineHeight: 26.4,
      },
    }),
  },
  bold: {
    ...Platform.select({
      web: {
        fontWeight: '700',
      },
      default: {
        fontWeight: '600',
      },
    }),
  },
});

const CREATE_INVITATION = `
  mutation Create_Invitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      id
    }
  }
`;
