import i18n from 'i18next';

export const isOnlyEmojis = (str: string) => {
  const emojiRegex = /^(\p{Emoji_Presentation}|\p{Emoji}\uFE0F|\p{Emoji_Modifier}\uFE0F?)$/u
  return emojiRegex.test(str);
};

export const getLanguage = () => {
  let language = i18n.languages[0];
  if (language.includes('_')) {
    language = language.split('_')[0];
  } else if (language.includes('-')) {
    language = language.split('-')[0];
  }
  return language;
};
