import { useDeleteMutation, WorkspaceRoutine } from '@gripp/shared-logic';
import { CustomAlert } from '@gripp/shared-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'react-native-paper';
import { menuStyles } from './menuStyles';

type DeleteRoutineMenuItemProps = {
  onSelected: () => void;
  workspaceRoutine: WorkspaceRoutine;
};

export const DeleteRoutineMenuItem = (props: DeleteRoutineMenuItemProps) => {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const { mutate } = useDeleteMutation({
    modelName: 'WorkspaceRoutine',
    query: DELETE_ROUTINE,
  });

  const onMenuItemPress = () => {
    setShowAlert(true);
  };

  const onDelete = () => {
    mutate({ input: { id: props.workspaceRoutine.id } });
    props.onSelected();
  };

  return (
    <>
      <Menu.Item
        leadingIcon="delete"
        style={[menuStyles.white, menuStyles.menuItem]}
        onPress={onMenuItemPress}
        title={t('routines.menu.delete.item')}
      />
      <CustomAlert
        visible={showAlert}
        title={t('routines.menu.delete.title')}
        action={onDelete}
        hideAlert={props.onSelected}
        primaryButtonLabel={t('routines.menu.delete.confirm')}
        secondaryButtonLabel={t('routines.menu.delete.cancel')}
      />
    </>
  );
};

const DELETE_ROUTINE = `
  mutation DeleteWorkspaceRoutine($input: MutateWorkspaceRoutineInput!) {
    deleteWorkspaceRoutine(input: $input) {
      id
    }
  }
`;
