import { StatusBaseConfig, StatusResponse } from '../../status';
import { WorkflowQuestionConfig } from '../workflowQuestionConfig';
import { WorkflowResponseState } from '../workflowResponseState';
import { QuestionMultipleValueExceptionConfig } from './questionException';

export interface StatusQuestionConfig
  extends WorkflowQuestionConfig,
    StatusBaseConfig,
    QuestionMultipleValueExceptionConfig {}

export interface StatusResponseState
  extends WorkflowResponseState,
    StatusResponse {}
