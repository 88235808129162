import { Modal, Platform, StyleSheet, View } from 'react-native';
import { Colors } from '../../themes/colors';
import { Carousel, CarouselBaseProps } from './carousel';

type ModalImageProps = {
  isVisible: boolean;
  onClose: () => void;
  mediaItems?: CarouselBaseProps;
};

export const ModalImage: React.FC<ModalImageProps> = ({
  isVisible,
  onClose,
  mediaItems = { mediaItems: [], activeIndexImage: 0 },
}: ModalImageProps) => (
  <Modal transparent={true} visible={isVisible} onRequestClose={onClose}>
    <View style={styles.modalContainer}>
      <View style={styles.innerContainer}>
        <Carousel
          mediaItems={mediaItems?.mediaItems}
          activeIndexImage={mediaItems?.activeIndexImage}
          onPressOut={onClose}
        />
      </View>
    </View>
  </Modal>
);

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.blackInactive,
    postion: 'relative',
    ...Platform.select({
      web: {
        cursor: 'default',
      },
    }),
  },
  innerContainer: {
    padding: 20,
    alignItems: 'center',
  },
});
