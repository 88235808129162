import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  cacheKeys,
  Issue as DomainIssue,
  MediaType,
  Message,
  NewMessage,
  PickerMode,
  useCreateIssueMessage,
  useFindUnreadActivityInbox,
  useIssue,
  useSetActivityInboxRead,
  useUpdateIssue,
} from '@gripp/shared-logic';
import {
  Colors,
  IssueContext,
  IssueFooter,
  IssueHeader,
  IssueMessages,
  IssueMessagesRef,
  Layout,
  RoutineExecutionProps,
  RoutineModal,
} from '@gripp/shared-ui';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { activityPaths } from '../../activity/routes';
import { assetPaths } from '../../assets/assetPaths';
import { MediaPicker } from '../../shared/components/media/mediaPicker';
import { getMediaBaseUrl } from '../../shared/mediaUtils';
import { useCurrentIssueContext } from '../issueContext';

type IssueProps = {
  isComingFromActivities?: boolean;
};

export const Issue = (props: IssueProps) => {
  const { setCurrentIssue } = useCurrentIssueContext() || {};
  const { issueId } = useParams();
  const messagesRef = useRef<IssueMessagesRef>();
  const navigate = useNavigate();
  const { issue, isLoading } = useIssue(issueId ?? '');
  const [messagesCount, setMessagesCount] = useState<number | undefined>(
    undefined
  );
  const [showFooter, setShowFooter] = useState(true);
  const { mutate: updateIssue } = useUpdateIssue();
  const { createIssueMessage } = useCreateIssueMessage();
  const { t } = useTranslation();
  const [routineModalVisible, setRoutineModalVisible] = useState(false);
  const [routineModalProps, setRoutineModalProps] = useState<
    RoutineExecutionProps | undefined
  >();
  const queryClient = useQueryClient();
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: async () => {
      setShowFooter(false);
      setIsPrinting(true);
      await new Promise((resolve) => setTimeout(resolve, 1));
    },
    onAfterPrint: () => {
      setShowFooter(true);
      setIsPrinting(false);
    },
    // pageStyle: '@page { margin: 10px; }',    // setting this causes images to not show up
    documentTitle: (issue as any)?.title,
  });
  const [isPrinting, setIsPrinting] = useState(false);

  const onPrint = async () => {
    handlePrint();
  };

  useEffect(() => {
    setMessagesCount(undefined);
  }, [issueId]);

  const onIssueDelete = () => {
    if (history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/activities');
    }
  };

  const { data: inboxData } = useFindUnreadActivityInbox();

  const { setAsRead } = useSetActivityInboxRead();

  useEffect(() => {
    if (issue && inboxData) {
      setAsRead(issue.id, inboxData.items, true);
    }
  }, [issue, inboxData]);

  const viewAssetInfo = props.isComingFromActivities
    ? () => navigate(`/assets/${issue.asset.id}`)
    : undefined;

  const onIssueHeaderChanged = (severity: string) => {
    const input = {
      id: issueId,
      severity: severity,
    };

    updateIssue(
      { input: input },
      {
        onSettled: () => {
          const updated = {
            ...issue,
            ...input,
            updatedAt: new Date().toISOString(),
          };
          setCurrentIssue && setCurrentIssue(updated);
        },
      }
    );
  };

  const onNewMessage = async (message: NewMessage) => {
    await createIssueMessage({
      issue: issue,
      message: message,
      options: {
        onSettled: async (data: Message) => {
          const newMessage = data;
          messagesRef.current?.addNewMessage(newMessage);

          await queryClient.invalidateQueries({
            queryKey: cacheKeys('issue').all,
          });
        },
      },
    });
  };

  const onRoutineSelected = async (props: RoutineExecutionProps) => {
    setRoutineModalProps(props);
    setRoutineModalVisible(true);
  };

  const onRoutineCompleted = async () => {
    await queryClient.invalidateQueries({ queryKey: cacheKeys('issue').all });
    await queryClient.invalidateQueries({
      queryKey: cacheKeys('issuemessage').all,
    });
    setRoutineModalVisible(false);
  };

  const onRouteToIssue = async (issueId: string) => {
    if (props.isComingFromActivities) {
      navigate(`/${activityPaths.root}/${issueId}`);
    } else {
      navigate(
        `/${assetPaths.root}/${(issue as any).asset.id}/activities/${issueId}`
      );
    }
  };

  return (
    !isLoading &&
    issue && (
      <IssueContext.Provider value={{ onRoutineSelected, onRouteToIssue }}>
        <View
          style={{
            flex: 1,
            minWidth: '50%',
            maxWidth: '55%',
          }}
          ref={printRef}
        >
          <View style={[styles.issueContainer]}>
            {!isPrinting && (
              <View style={styles.headerOptions}>
                <View>
                  {messagesCount !== undefined && (
                    <Text style={styles.resultsLabel}>
                      {messagesCount}&nbsp;{t('issue.topBar.allReplies')}
                    </Text>
                  )}
                </View>

                {viewAssetInfo && issue?.asset && (
                  <View
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    <Pressable
                      style={styles.viewAssetButton}
                      onPress={() => viewAssetInfo()}
                    >
                      <Text style={styles.viewAssetButtonLabel}>
                        {t('topNav.assetInfo')}
                      </Text>
                      <MaterialCommunityIcons
                        size={21}
                        color={Colors.primaryDark}
                        name="chevron-right"
                        style={{
                          marginLeft: -4,
                          textAlign: 'justify',
                        }}
                      />
                    </Pressable>
                  </View>
                )}
              </View>
            )}
            <IssueHeader
              issue={issue}
              onChanged={onIssueHeaderChanged}
              setShowFooter={setShowFooter}
              titleStyle={styles.issueTitle}
              viewAssetInfo={viewAssetInfo}
              onIssueDelete={onIssueDelete}
              onPrint={onPrint}
            />
            <View style={[styles.messages, { flex: 1 }]}>
              <IssueMessages
                ref={messagesRef}
                issue={issue as DomainIssue}
                mediaUrl={getMediaBaseUrl()}
                inbox={inboxData?.items}
                onMessagesCountChange={setMessagesCount}
                setAsRead={setAsRead}
              />
            </View>
            {showFooter && (
              <IssueFooter
                onNewMessage={onNewMessage}
                mediaPicker={MediaPicker}
                workspaceId={issue?.workspace?.id}
                mode={PickerMode.ATTACHMENT}
                containerStyle={styles.footer}
                acceptedMediaTypes={[MediaType.Image, MediaType.Document]}
              />
            )}
          </View>
        </View>
        {routineModalProps && (
          <RoutineModal
            {...routineModalProps}
            visible={routineModalVisible}
            onCanceled={() => {
              setRoutineModalVisible(false);
            }}
            onCompleted={onRoutineCompleted}
          />
        )}
      </IssueContext.Provider>
    )
  );
};

const styles = StyleSheet.create({
  issueContainer: {
    ...Layout.container,
    borderRadius: 12,
    overflow: 'hidden',
  },
  headerOptions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.secondaryGrayHeader,
  },
  resultsLabel: {
    fontSize: 14,
    color: Colors.grayText,
    fontWeight: '500',
    lineHeight: 17,
  },
  viewAssetButton: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 17,
  },
  viewAssetButtonLabel: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.primaryDark,
    lineHeight: 17,
    marginRight: 0,
    padding: 0,
  },
  body: {
    ...Layout.container,
  },
  messages: {
    flexGrow: 2,
  },
  issueTitle: {
    paddingLeft: 15,
  },
  footer: {
    backgroundColor: Colors.white,
    borderTopColor: Colors.primaryGrayBackground,
    borderTopWidth: 3,
  },
  print: {
    flex: 1,
    margin: 10,
  },
});
