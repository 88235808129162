import { Forbidden } from './components/forbidden';
import { Login } from './components/login';
import VerifyAuthCode from './components/verifyAuthCode';
import { authPaths } from './paths';

export const authRoutes = [
  { path: authPaths.login, element: <Login /> },
  { path: authPaths.forbidden, element: <Forbidden /> },
  { path: authPaths.verifyAuthCode, element: <VerifyAuthCode /> },
];
