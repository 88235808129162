import {
  CounterQuestionConfig,
  CounterResponseState,
} from '@gripp/shared-logic';
import { RoutineQuestionModule } from '../types';
import { CounterQuestionAdminForm } from './counterQuestionAdminForm';
import { CounterQuestionExecutionForm } from './counterQuestionExecutionForm';
import { CounterQuestionMessage } from './counterQuestionMessage';

export const CounterQuestionModule: RoutineQuestionModule<
  CounterQuestionConfig,
  CounterResponseState
> = {
  adminForm: CounterQuestionAdminForm,
  executionForm: CounterQuestionExecutionForm,
  message: CounterQuestionMessage,
};
