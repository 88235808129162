import { CounterQuestionConfig } from '@gripp/shared-logic';
import { CounterAdminComponent } from '../../../counter/counterAdminForm';
import {
  RoutineQuestionAdminForm,
  RoutineQuestionAdminFormProps,
} from '../types';

export const CounterQuestionAdminForm: RoutineQuestionAdminForm<
  CounterQuestionConfig
> = (props: RoutineQuestionAdminFormProps<CounterQuestionConfig>) => {
  return (
    <CounterAdminComponent
      config={props.config}
      control={props.control}
      indexPrefix={props.indexPrefix}
    />
  );
};
