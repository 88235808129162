import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';
import { Colors } from '../../themes';

type Props = { onPress: () => void };

export const StepBackButton = ({ onPress }: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      contentStyle={styles.content}
      labelStyle={styles.label}
      onPress={onPress}
    >
      {t('permissions.backButton')}
    </Button>
  );
};

const styles = StyleSheet.create({
  content: {
    height: 44,
  },
  label: {
    fontWeight: '500',
    fontSize: 16,
    color: Colors.orangeDark,
  },
});
