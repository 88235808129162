export const LIST_ASSETS = `
  query FindAssets($filter: ListAssetsFilter!, $page: PageRequest, $orderBy: OrderByInput) {
    asset {
      listAssets(filter: $filter, page: $page, orderBy: $orderBy) {
        count
        limit
        offset
        items {
          id
          name
          description
          lastAddress
          lastScannedAt
          lastUpdated
          createdAt
          numNotes
          numReportsRed
          numReportsYellow
          numRoutines
          groups {
            id
            name
          }
          tagCode
          routineState {
            state
            workspaceRoutineId
            workspaceRoutineVersionId
          }
          workspaces {
            id
            name
          }
        }
      }
    }
  }
`;
