export const PermissionValues = {
  globalAdmin: '*.*',
  account: {
    all: 'account.*',
    allWorkspace: 'account.*.workspace',
    create: 'account.create',
  },
  activity: {
    all: 'activity.*',
  },
  alerts: {
    all: 'workflow.*',
    create: 'workflow.create',
  },
  asset: {
    all: 'asset.*',
    create: 'asset.create',
  },
  group: {
    all: 'group.*',
    create: 'group.create',
  },
  feature: {
    all: 'feature.*',
    create: 'feature.create',
  },
  issue: {
    all: 'issue.*',
  },
  tag: {
    all: 'tag.*',
  },
  tagbatch: {
    all: 'tagbatch.*',
    create: 'tagbatch.create',
  },
  workspace: {
    all: 'workspace.*',
    create: 'workspace.create',
  },
};
