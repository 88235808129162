import {
  Media,
  MediaQuestionConfig,
  MediaResponseState,
  MediaType,
  useGrippContext,
} from '@gripp/shared-logic';
import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useFormContext } from '../../../../forms';
import { HorizontalMediaGallery } from '../../../../media';
import {
  RoutineQuestionExecutionForm,
  RoutineQuestionExecutionFormProps,
} from '../types';

export const MediaQuestionExecutionForm: RoutineQuestionExecutionForm<
  MediaQuestionConfig,
  MediaResponseState
> = (
  props: RoutineQuestionExecutionFormProps<
    MediaQuestionConfig,
    MediaResponseState
  >
) => {
  const [localMediaIds, setLocalMediaIds] = useState<string[]>([]);
  const { mediaPickerComponent: MediaPicker } = useGrippContext();
  const fieldName = `responses.${props.responseIndex}.mediaIds`;
  const { control } = useFormContext();
  control.register(fieldName, { value: [] });
  const { append, remove } = useFieldArray({
    control: control,
    name: fieldName,
  });

  const onMediaAdded = (mediaItem: Media) => {
    append(mediaItem.id);
    setLocalMediaIds([...localMediaIds, mediaItem.id]);
  };

  const onMediaDeleted = (mediaId: string) => {
    const index = localMediaIds.indexOf(mediaId);
    remove(index);
    setLocalMediaIds(localMediaIds.filter((id) => id !== mediaId));
  };

  return (
    <HorizontalMediaGallery
      onMediaAdded={onMediaAdded}
      mediaPicker={MediaPicker}
      acceptedMediaTypes={[MediaType.Image]}
      onMediaDeleted={onMediaDeleted}
      canEditMedia={true}
      onUploadingChanged={props.toggleProcessing}
    />
  );
};
