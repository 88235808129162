export type Preferences = {
  preferredLanguage?: string;
  notificationPreferences?: any;
};

export type NotificationPreferences = {
  newIssueSeverities?: string[];
  issueReplies?: boolean;
  issueSeverityChanges?: boolean;
  issueTitleChanges?: boolean;
  groupIds?: string[];
};

export const defaultNotificationPreferences: NotificationPreferences = {
  newIssueSeverities: ['blue', 'yellow', 'red', 'purple'],
  issueReplies: true,
  issueSeverityChanges: true,
  issueTitleChanges: true,
  groupIds: [],
};

export enum NotificationSchedule {
  EveryDay = 'EVERY_DAY',
  WeekDays = 'WEEK_DAYS',
}
