import { StatusQuestionConfig, StatusResponseState } from '@gripp/shared-logic';
import { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useFormContext } from '../../../../forms';
import { StatusExecutionComponent } from '../../../status/statusExecutionComponent';
import {
  RoutineQuestionExecutionForm,
  RoutineQuestionExecutionFormProps,
} from '../types';

export const StatusQuestionExecutionForm: RoutineQuestionExecutionForm<
  StatusQuestionConfig,
  StatusResponseState
> = (
  props: RoutineQuestionExecutionFormProps<
    StatusQuestionConfig,
    StatusResponseState
  >
) => {
  const fieldPrefix = `responses.${props.responseIndex}`;
  const formKey = `${fieldPrefix}.status`;

  const { control, setValue } = useFormContext();
  const { field } = useController({
    control: control,
    name: formKey,
  });

  useEffect(() => {
    if (props.currentState?.state?.status) {
      setValue(formKey, props.currentState.state.status, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [props.currentState]);

  return (
    <StatusExecutionComponent
      statuses={props.config.statuses}
      onChange={field.onChange}
      value={field.value}
    />
  );
};
