import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import DateTimePickerModal, {
  CustomConfirmButtonPropTypes,
} from 'react-native-modal-datetime-picker';
import { Colors } from '../../themes/colors';

type ControlledTimeInputProps = {
  control: any;
  name: string;
  modalLabel: string;
  cancelLabel: string;
  acceptLabel: string;
  defaultHours: number;
  defaultMinutes: number;
};

export const ControlledTimeInput = (props: ControlledTimeInputProps) => {
  const isDesktop = Platform.OS === 'web';
  const [visible, setVisible] = useState(false);

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => {
        if (isDesktop) {
          return <></>;
        } else {
          return (
            <>
              <View style={styles.modalButtonContainer}>
                <Pressable
                  style={styles.modalButton}
                  onPress={() => setVisible(true)}
                >
                  <Text style={styles.buttonLabel}>{props.modalLabel}</Text>
                </Pressable>
              </View>
              <DateTimePickerModal
                textColor={Colors.grayText}
                cancelTextIOS={props.cancelLabel}
                confirmTextIOS={props.acceptLabel}
                positiveButton={{
                  label: props.acceptLabel,
                  textColor: Colors.primary,
                }}
                negativeButton={{
                  label: props.cancelLabel,
                  textColor: Colors.grayText,
                }}
                buttonTextColorIOS={Colors.grayText}
                customConfirmButtonIOS={(
                  props: CustomConfirmButtonPropTypes
                ) => (
                  <Pressable
                    style={styles.confirmButton}
                    onPress={props.onPress}
                  >
                    <Text style={styles.confirmButtonText}>{props.label}</Text>
                  </Pressable>
                )}
                isVisible={visible}
                mode="time"
                display="spinner"
                date={
                  value
                    ? new Date(value)
                    : new Date(
                        1970,
                        0,
                        1,
                        props.defaultHours,
                        props.defaultMinutes
                      )
                }
                onConfirm={(date) => {
                  setVisible(false);
                  onChange(date);
                }}
                onCancel={() => setVisible(false)}
              />
            </>
          );
        }
      }}
    />
  );
};

const styles = StyleSheet.create({
  modalButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  modalButton: {
    height: 30,
    width: 80,
    borderWidth: 2,
    borderColor: Colors.primary,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmButton: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.primary,
  },
  confirmButtonText: {
    fontSize: 20,
    fontWeight: '500',
    color: Colors.primary,
  },
});
