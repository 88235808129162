import {
  FeatureName,
  PermissionValues as Permissions,
} from '@gripp/shared-logic';
import { FC } from 'react';
import { AssetMenuItem } from '../../assets/components/assetsMenuItem';
import { NavLinkProps } from './menuLink';

export interface IMenu {
  titleKey: string;
  icon: string;
  route: string;
  requiredPermission: string;
  requiredFeature?: FeatureName;
  component?: FC<NavLinkProps>;
}

export enum Routes {
  AccountPage = 'account-page',
  Accounts = 'accounts',
  Activities = 'activities',
  Assets = 'assets',
  Groups = 'groups',
  Routines = 'routines',
  Tags = 'tags',
  TagBatches = 'tagbatches',
  Workspaces = 'workspaces',
  Features = 'features',
  Alerts = 'alerts',
}

export const routeNames = {
  [Routes.AccountPage]: `/account-page`,
  [Routes.Accounts]: `/accounts`,
  [Routes.Activities]: `/activities`,
  [Routes.Assets]: `/assets`,
  [Routes.Groups]: `/groups`,
  [Routes.Routines]: `/routines`,
  [Routes.Tags]: `/tags`,
  [Routes.TagBatches]: `/tagbatches`,
  [Routes.Workspaces]: `/workspaces`,
  [Routes.Features]: `/features`,
  [Routes.Alerts]: `/alerts`,
};

const guestMenuItems = [
  {
    titleKey: 'activity.menu.title',
    icon: 'bell-outline',
    route: routeNames[Routes.Activities],
    requiredPermission: Permissions.activity.all,
  },
  {
    titleKey: 'assets.menu.title',
    icon: 'qrcode',
    route: routeNames[Routes.Assets],
    requiredPermission: Permissions.asset.all,
    component: AssetMenuItem,
  },
  {
    titleKey: 'admin.menu.admin',
    icon: 'account-multiple',
    route: routeNames[Routes.Accounts],
    requiredPermission: Permissions.account.all,
  },
];

const adminMenuItems = [
  {
    titleKey: 'assets.list.routines',
    icon: 'format-list-checks',
    route: routeNames[Routes.Routines],
    requiredPermission: Permissions.globalAdmin,
  },
  {
    titleKey: 'tags.title',
    icon: 'tag',
    route: routeNames[Routes.Tags],
    requiredPermission: Permissions.globalAdmin,
  },
  {
    titleKey: 'tags.batches.title',
    icon: 'tag-multiple',
    route: routeNames[Routes.TagBatches],
    requiredPermission: Permissions.globalAdmin,
  },
  {
    titleKey: 'features.title',
    icon: 'test-tube',
    route: routeNames[Routes.Features],
    requiredPermission: Permissions.globalAdmin,
  },
  {
    titleKey: 'alerts.title',
    icon: 'bell-plus-outline',
    route: routeNames[Routes.Alerts],
    requiredPermission: Permissions.globalAdmin,
  },
  {
    titleKey: 'admin.menu.workspaces',
    icon: 'group',
    route: routeNames[Routes.Workspaces],
    requiredPermission: Permissions.globalAdmin,
  },
];

const primaryAdminMenuItems = [
  {
    titleKey: 'groups.menu.title',
    icon: 'google-circles-extended',
    route: routeNames[Routes.Groups],
    requiredPermission: Permissions.group.all,
    requiredFeature: FeatureName.GroupsPage,
  },
];

const supportMenuItems = [
  {
    titleKey: 'accountPage.menu.accountPage',
    icon: 'shield-key',
    route: routeNames[Routes.AccountPage],
    requiredPermission: Permissions.account.allWorkspace,
    requiredFeature: FeatureName.AccountPage,
  },
];

export const MenuItems: IMenu[][] = [
  primaryAdminMenuItems,
  guestMenuItems,
  adminMenuItems,
  supportMenuItems,
];
