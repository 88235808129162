import { MaterialCommunityIcons } from '@expo/vector-icons';
import { WorkspaceAsset } from '@gripp/shared-logic';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { Colors } from '../../../themes';
import { EditRoutineModal } from './editRoutineModal';
import { RoutineButton } from './routineButton';

export type EditRequestRoutineProps = {
  workspaceAsset: WorkspaceAsset;
};

// the name of the component may not be very representative, perhaps it could be editRoutineWorkspaceAsset.
export const EditRequestRoutine = (props: EditRequestRoutineProps) => {
  const { t } = useTranslation();

  const [editRequestRoutine, setEditRequestRoutine] = useState(false);

  const onClose = () => {
    setEditRequestRoutine(false);
  };

  return (
    <>
      <RoutineButton
        onClick={() => setEditRequestRoutine(true)}
        text={t('routines.editRoutines.editRoutinesButton')}
        buttonStyle={styles.editRoutinesButton}
        buttonLabelStyle={styles.editRoutinesButtonLabel}
        leftButtonIcon={
          <MaterialCommunityIcons
            name="pencil-outline"
            color={Colors.primaryDark}
            size={24}
            style={{ marginRight: 4 }}
          />
        }
      />
      <EditRoutineModal
        onClose={onClose}
        editRoutineModalVisible={editRequestRoutine}
        workspaceAsset={props.workspaceAsset}
      />
    </>
  );
};

const styles = StyleSheet.create({
  editRoutinesButton: {
    borderColor: 'transparent',
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editRoutinesButtonLabel: {
    color: Colors.primaryDark,
  },
});
