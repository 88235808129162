import { getFullName, useCurrentAccount } from '@gripp/shared-logic';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Layout } from '../../styles/index';
import { Colors } from '../../themes/colors';
import { CurrentAccountAvatar } from '../currentAccountAvatar';
import { ImagePickerComponent } from '../media/types';
import { EditProfileForm } from './editProfileForm';

export type ProfileFormProps = {
  control: any;
  mediaUrl: string;
  imagePicker: ImagePickerComponent;
  hasBorder?: boolean;
  inputMaxWidth?: number;
  allowsEditingAvatar?: boolean;
};

export const ProfileForm = (props: ProfileFormProps) => {
  const { t } = useTranslation();
  const { account } = useCurrentAccount();

  return (
    <View style={props.hasBorder && styles.border}>
      <View style={styles.row}>
        <CurrentAccountAvatar
          {...props}
          size={80}
          isIconInBox={true}
          editable={true}
        />
        <Text style={styles.title} variant="titleLarge">
          {getFullName(account?.name)}
        </Text>
      </View>
      <EditProfileForm
        control={props.control}
        inputMaxWidth={props.inputMaxWidth}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    ...Layout.row,
    ...Layout.alignCenter,
    ...Layout.justifyStart,
    gap: 8,
  },

  title: {
    fontWeight: '700',
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.grayDivider,
  },
});
