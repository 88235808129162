import { useEffect, useState } from 'react';
import { useExportHook, useExportProps } from './types';
import {
  getFullName,
  RoutineType,
  useCustomQuery,
  useFilteredWorkspaceRoutines,
} from '@gripp/shared-logic';

export const useRoutineExport: useExportHook = (props: useExportProps) => {
  const [queryEnabled, setQueryEnabled] = useState(false);
  const [rows, setRows] = useState([]);
  const [routineIds, setRoutineIds] = useState<string[]>([]);
  const routines = useFilteredWorkspaceRoutines(
    {
      workspace: { eq: props.workspace.id },
      type: { ne: RoutineType.Workflow },
    },
    props.workspace.id,
    !props.download
  );
  const [headers, setHeaders] = useState<any[]>([
    { label: 'Asset ID', key: 'assetId' },
    { label: 'Asset Name', key: 'assetName' },
    { label: 'Created At', key: 'createdAt' },
    { label: 'Created By', key: 'createdBy' },
    { label: 'Groups', key: 'groups' },
  ]);

  useEffect(() => {
    if (!routines || routines.length === 0) return;

    const routineHeaders: any[] = [];
    const newRoutineIds: string[] = [];
    routines.forEach((routine) => {
      routineHeaders.push({
        label: routine.config.title ?? routine.name,
        key: routine.id,
      });
      newRoutineIds.push(routine.id);
    });

    setRoutineIds(newRoutineIds);
    setHeaders([...headers, ...routineHeaders]);
    setQueryEnabled(true);
  }, [routines]);

  const { data, isLoading } = useCustomQuery({
    query: GET_ROUTINES,
    cacheKey: ['exportRoutines', props.workspace.id],
    variables: {
      filter: {
        workspace: { eq: props.workspace.id },
        routine: { in: routineIds },
      },
      orderBy: {
        field: 'createdAt',
        order: 'ASC',
      },
    },
    options: {
      enabled: queryEnabled,
      gcTime: 10000,
      staleTime: 10000,
    },
  });

  useEffect(() => {
    if (!data) return;

    const exportRows = data.findAssetRoutines.items
      .map((item: any) => {
        const row: any = {
          assetId: item.asset.id,
          assetName: item.asset.name,
          createdAt: item.createdAt,
          createdBy: getFullName(item.createdBy.name),
          groups: item.workspaceAsset.groups
            ? item.workspaceAsset.groups.map((g: any) => g.name).join(', ')
            : '',
        };

        const routine = routines.find(
          (routine) => routine.id === item.routine.id
        );
        if (!routine) return undefined;

        let value: any;
        switch (routine?.type) {
          case RoutineType.Counter:
            value = item.data.count;
            break;
          case RoutineType.Status:
            value = item.data.status;
            break;
          case RoutineType.QuickConfirm:
            value = item.data.confirmedValue;
            break;
        }
        row[item.routine.id] = value;

        return row;
      })
      .filter((x) => x);

    setRows(exportRows);
    props.onDataReady();
  }, [data]);

  return { headers, rows, isLoading };
};

const GET_ROUTINES = `
  query FindAssetRoutines($filter: AssetRoutineFilter, $orderBy: OrderByInput) {
    findAssetRoutines(filter: $filter, orderBy: $orderBy) {
      items {
        asset {
          id
          name
        }
        workspaceAsset {
          groups {
            name
          }
        }
        routine {
          id
        }
        createdAt
        createdBy {
          name {
            first
            last
          }
        }
        data
      }
    }
  }
`;
