import {
  QuickConfirmQuestionConfig,
  QuickConfirmResponseState,
} from '@gripp/shared-logic';
import { useController } from 'react-hook-form';
import { useFormContext } from '../../../../forms';
import { QuickConfirmExecutionComponent } from '../../../quickConfirm/quickConfirmExecutionComponent';
import {
  RoutineQuestionExecutionForm,
  RoutineQuestionExecutionFormProps,
} from '../types';

export const QuickConfirmQuestionExecutionForm: RoutineQuestionExecutionForm<
  QuickConfirmQuestionConfig,
  QuickConfirmResponseState
> = (
  props: RoutineQuestionExecutionFormProps<
    QuickConfirmQuestionConfig,
    QuickConfirmResponseState
  >
) => {
  const fieldPrefix = `responses.${props.responseIndex}`;
  const { control } = useFormContext();
  const { field } = useController({
    control: control,
    name: `${fieldPrefix}.confirmedValue`,
  });

  return (
    <QuickConfirmExecutionComponent
      options={props.config.options}
      onChange={field.onChange}
      confirmedValue={field.value}
    />
  );
};
