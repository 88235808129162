import { SortDirectionEnum, useFindByQuery } from '@gripp/shared-logic';
import { useCallback, useEffect, useState } from 'react';
import { BulkUpdateFileUploader } from './bulkUpdateFileUploader';

export const WorkspaceRoutineAdmin = ({ workspaceId, workspaceName }) => {
  const [rows, setRows] = useState(undefined);
  const [headers, setHeaders] = useState(undefined);

  const filter = {
    workspace: { eq: workspaceId },
  };

  const { data: workspaceAssets } = useFindByQuery({
    query: FIND_WORKSPACE_ASSET,
    modelName: 'workspaceAsset',
    variables: { filter },
  }) as any;

  const { data: workspaceRoutines } = useFindByQuery({
    query: FIND_WORKSPACE_ROUTINE,
    modelName: 'workspaceRoutine',
    variables: {
      filter,
      orderBy: { field: 'order', order: SortDirectionEnum.Asc },
    },
  }) as any;

  const routineKey = useCallback(
    (routine: any) => `${routine.name.substring(0, 20)}+${routine.id}`,
    []
  );

  const processData = useCallback(
    (assets: any, routines: any) => {
      const rows = assets
        .map((asset) => {
          if (!asset.asset) {
            return undefined;
          }
          const row: { [key: string]: string } = {
            WorkspaceAssetId: asset.id,
            AssetName: asset.asset.name,
            AssetDescription: asset.asset.description,
          };
          routines.forEach((routine) => {
            row[routineKey(routine)] = asset.routines?.some(
              (r) => r.id === routine.id
            )
              ? 'x'
              : '';
          });
          return row;
        })
        .filter((row) => row !== undefined);
      return rows;
    },
    [routineKey]
  );

  const processHeaders = (workspaceRoutines: any) => {
    return [
      'WorkspaceAssetId',
      'AssetName',
      'AssetDescription',
      ...workspaceRoutines.map((x) => routineKey(x)),
    ];
  };

  useEffect(() => {
    if (!workspaceAssets || !workspaceRoutines) return;

    const rows = processData(workspaceAssets.items, workspaceRoutines.items);
    const header = processHeaders(workspaceRoutines.items);
    setHeaders(header);
    setRows(rows);
  }, [workspaceAssets, workspaceRoutines]);

  return (
    <BulkUpdateFileUploader
      title="Routines"
      headers={headers}
      rows={rows}
      workspaceId={workspaceId}
      workspaceName={workspaceName}
    />
  );
};

const FIND_WORKSPACE_ASSET = `
  query FindWorkspaceAssets($filter: WorkspaceAssetFilter) {
    findWorkspaceAssets(filter: $filter) {
      items {
        id
        asset {
          name
          description
        }
        routines {
          id
        }
      }
    }
  }
`;

const FIND_WORKSPACE_ROUTINE = `
  query FindWorkspaceRoutines($filter: WorkspaceRoutineFilter, $orderBy: OrderByInput) {
    findWorkspaceRoutines(filter: $filter, orderBy: $orderBy) {
      items {
        id,
        name
        order
      }
    }
  }
`;
