import { Colors, DebouncedTextInput } from '@gripp/shared-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { TextInput } from 'react-native-paper';

type GroupAssignmentSearchInput = {
  keyword: string;
  onChange: (text: string) => void;
};

export const GroupAssignmentSearchInput = ({
  keyword,
  onChange,
}: GroupAssignmentSearchInput) => {
  const { t } = useTranslation();
  const [text, setText] = useState('');

  useEffect(() => {
    setText(keyword);
  }, [keyword]);

  return (
    <DebouncedTextInput
      debounceTimeMs={500}
      debounceLength={3}
      debounceFunc={onChange}
      placeholder={t('assets.filter.searchPlaceholder')}
      value={text}
      onBlur={() => {}}
      onChangeText={(text) => setText(text)}
      left={<TextInput.Icon icon="magnify" color={Colors.black} size={24} />}
      right={
        text ? (
          <TextInput.Icon
            icon="close"
            size={20}
            color={Colors.black}
            onPress={() => setText('')}
          />
        ) : (
          <View />
        )
      }
      height={44}
      outlineStyle={styles.inputStyle}
      disableInnerStyle={true}
    />
  );
};

const styles = StyleSheet.create({
  inputStyle: {
    backgroundColor: Colors.white,
    borderColor: Colors.grayDivider,
    borderWidth: 1,
    borderRadius: 8,
  },
});
