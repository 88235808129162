export const GET_TAG_BATCHES = `
  query FindTagBatches {
    findTagBatches {
      items {
        id
        createdAt
        memo
        name
        quantity
        status
        version {
          version
        }
        updatedAt
        workspace {
          name
        }
      }
    }
  }
`;