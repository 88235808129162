import { useCallback, useRef } from 'react';

export const useAutoFocusRef = () => {
  const hasFocused = useRef(false);

  return useCallback((node) => {
    if (node !== null && !hasFocused.current) {
      setTimeout(() => {
        node.focus();
      }, 300);
      hasFocused.current = true;
    }
  }, []);
};
