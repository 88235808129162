import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { useAutoFocusRef } from '../../hooks/useAutoFocusRef';
import { Spacing } from '../../styles/index';
import { Colors } from '../../themes/colors';
import { ControlledPhoneInput } from '../forms/controlledPhoneInput';
import { ControlledTextInput } from '../forms/controlledTextInput';

export type EditProfileFormProps = {
  control: any;
  inputMaxWidth?: number;
  autoFocus?: boolean;
};

export const EditProfileForm = (props: EditProfileFormProps) => {
  const { t } = useTranslation();
  const elementFocusCallBack = useAutoFocusRef();

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ?  undefined : 'padding'}
      enabled
      keyboardVerticalOffset={Platform.OS === 'ios' ? 100 : 120}
    >
      <ScrollView automaticallyAdjustKeyboardInsets={true} showsVerticalScrollIndicator={true} >
        <View style={styles.saveForm}>
          <View style={[styles.formItem, { maxWidth: props.inputMaxWidth }]}>
            <View>
              <Text variant={'titleMedium'} style={styles.formItemTitle}>
                {t('firstName')}
              </Text>
            </View>
            <ControlledTextInput
              control={props.control}
              name="firstName"
              placeholder={t('firstName')}
              innerRef={props.autoFocus ? elementFocusCallBack : undefined}
              autoComplete="given-name"
            />
          </View>
          <View style={[styles.formItem, { maxWidth: props.inputMaxWidth }]}>
            <View>
              <Text variant={'titleMedium'} style={styles.formItemTitle}>
                {t('lastName')}
              </Text>
            </View>
            <ControlledTextInput
              control={props.control}
              name="lastName"
              placeholder={t('lastName')}
              autoComplete="family-name"
            />
          </View>
          <View style={[styles.formItem, { maxWidth: props.inputMaxWidth }]}>
            <View>
              <Text variant={'titleMedium'} style={styles.formItemTitle}>
                {t('email')}
              </Text>
            </View>
            <ControlledTextInput
              control={props.control}
              name="email"
              placeholder={t('email')}
              keyboardType="email-address"
            />
          </View>
          <View style={[styles.formItem, { maxWidth: props.inputMaxWidth }]}>
            <View>
              <Text variant={'titleMedium'} style={styles.formItemTitle}>
                {'Phone'}
              </Text>
            </View>
            <ControlledPhoneInput
              control={props.control}
              name="phone"
              placeholder={'phone'}
            />
          </View>
          <View style={[styles.formItem, { maxWidth: props.inputMaxWidth }]}>
            <View>
              <Text variant={'titleMedium'} style={styles.formItemTitle}>
                {t('jobTitle')}
              </Text>
            </View>
            <ControlledTextInput
              control={props.control}
              name="jobTitle"
              placeholder={t('jobTitle')}
            />
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};
const styles = StyleSheet.create({
  saveForm: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingBottom: Spacing.baseMargin.base * 1.5,
  },

  formItem: {
    marginTop: Spacing.baseMargin.marginVertical,
    width: '100%',
    maxWidth: 400,
  },
  formItemTitle: {
    fontSize: 16,
    lineHeight: 16,
    color: Colors.black,
    marginBottom: 10,
    fontWeight: '700',
  },
});
