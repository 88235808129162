import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { Colors } from '../../themes';
import { filterStyles as styles } from './filterStyle';

type ApplyButtonProps = {
  isDirty: boolean;
  onSubmit: (data: any) => Promise<void>;
};

export const ApplyButton = ({ isDirty, onSubmit }: ApplyButtonProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.applyButtonContainer}>
      <Button
        disabled={!isDirty}
        style={styles.applyButton}
        buttonColor={Colors.primary}
        labelStyle={{
          fontSize: 16,
          fontWeight: '600',
        }}
        mode="contained"
        onPress={onSubmit}
      >
        {t('assets.filter.apply')}
      </Button>
    </View>
  );
};
