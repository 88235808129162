import { TFunction } from 'i18next';

export enum WhatItemType {
  Assets = 'Assets',
  Groups = 'Groups',
}

export type WhatStepProps = {
  workspaceId: string;
  control: any;
  name: string;
  type: string;
  t: TFunction;
};

export type WhyStepProps = {
  control: any;
  whatItemId: string;
  whatItemType: string;
  name: string;
  t: TFunction;
};

export type WhenStepProps = {
  control: any;
  whyItem: any;
  name: string;
};

export type PromptRoutineSelectorProps = {
  whatItemId: string;
  whatItemType: string;
  whyItemId: string;
  control: any;
  name: string;
  t: TFunction;
};
