import {
  Asset,
  Issue,
  SortDirectionEnum,
  useInfiniteFindByQuery,
  useUpdateMutation,
} from '@gripp/shared-logic';
import { ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Animated,
  FlatList,
  Modal,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';
import { RadioButton, Text, TextInput } from 'react-native-paper';
import { useAnimation } from '../../hooks';
import { Colors } from '../../themes';
import { DebouncedTextInput, SaveFormTop } from '../forms';
import LoadingIndicator from '../loadingIndicator';
import { TopModalBar } from '../modals/topModalBar';

type ReassignModalProps = {
  visible: boolean;
  hide: () => void;
  issue: Issue;
  presentWebStyle: boolean;
};

export const ReassignModal = ({
  visible,
  hide,
  issue,
  presentWebStyle,
}: ReassignModalProps) => {
  const PAGE_SIZE = 30;
  const { t } = useTranslation();
  const defaultFilter = {
    ...(issue.asset && { id: { ne: issue.asset.id } }),
    workspaces: { eq: issue.workspace.id },
  };
  const defaultValue = issue.asset?.id;
  const [filter, setFilter] = useState<any>(defaultFilter);
  const [assetSearch, setAssetSearch] = useState('');
  const [currentValue, setCurrentValue] = useState<string | undefined>(
    defaultValue
  );
  const [isDirty, setIsDirty] = useState(false);
  const [assets, setAssets] = useState<Asset[]>([]);

  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useInfiniteFindByQuery({
      modelName: 'Asset',
      query: FIND_ASSETS,
      filter: filter,
      orderBy: {
        field: 'name',
        order: SortDirectionEnum.Asc,
      },
      pageSize: PAGE_SIZE,
    });
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(data?.pages?.length || 0);
  // if a user goes back and then revisits this page, data might be cached and we could already have more than 1 page
  const initialPages = data?.pages?.length || 0;

  const { mutateAsync: updateIssue } = useUpdateMutation({
    modelName: 'Issue',
    query: UPDATE_ISSUE,
  });

  useEffect(() => {
    if (
      isLoading ||
      isFetchingNextPage ||
      !data ||
      !data.pages ||
      data.pages.length === 0
    )
      return;

    if (page > 0 && page > initialPages) {
      setAssets((prevAssets) => [...prevAssets, ...data.pages[page]?.items]);
    } else {
      setTotalCount(data.pages[0].count);
      setAssets(data.pages.flatMap((page: any) => page?.items));
    }
  }, [initialPages, data, isFetchingNextPage, isLoading, page]);

  const onEndReached = () => {
    if (!totalCount || totalCount <= PAGE_SIZE) return;
    if (!isFetchingNextPage && hasNextPage) {
      setPage(page + 1);
      fetchNextPage();
    }
  };

  const updateSearch = (text: string) => {
    const textFilter = { contains: text };
    setFilter({
      ...defaultFilter,
      and: [
        {
          or: [{ name: textFilter }, { description: textFilter }],
        },
      ],
    });
  };

  const onItemSelected = async (assetId?: string) => {
    setIsDirty(assetId !== defaultValue);
    setCurrentValue(assetId);
  };

  const onSubmit = async () => {
    const input = {
      id: issue.id,
      asset: { id: currentValue },
    };

    await updateIssue(
      { input: input },
      {
        onSettled: () => {
          hide();
        },
      }
    );
  };

  const Wrapper = presentWebStyle ? WebWrapper : NativeWrapper;

  return (
    <Modal
      presentationStyle={presentWebStyle ? undefined : 'pageSheet'}
      onRequestClose={hide}
      onDismiss={hide}
      animationType={presentWebStyle ? 'fade' : 'slide'}
      transparent={presentWebStyle}
      visible={visible}
    >
      <Wrapper visible={visible}>
        <SaveFormTop
          onSubmit={onSubmit}
          isValid={true}
          isDirty={isDirty}
          topBar={TopModalBar}
          title={t('issue.reassign.title')}
          saveLabel={t('issue.reassign.saveLabel')}
          saveButtonType="button"
          onCancel={hide}
        >
          <View style={styles.formContainer}>
            <View style={styles.upperFormContainer}>
              <Text style={styles.instructionsLabel}>
                {t('issue.reassign.instructions')}
              </Text>
              <DebouncedTextInput
                debounceTimeMs={500}
                debounceLength={3}
                debounceFunc={updateSearch}
                onBlur={() => {}}
                height={45}
                value={assetSearch}
                onChangeText={setAssetSearch}
                placeholder={t('issue.reassign.placeholder')}
                left={<TextInput.Icon icon="magnify" color={Colors.grayFill} />}
                outlineStyle={styles.inputStyle}
                disableInnerStyle={true}
              />

              <View
                style={{
                  marginTop: 15,
                }}
              >
                {/* show existing*/}
                {issue.asset ? (
                  <AssetItem
                    onItemSelected={onItemSelected}
                    currentValue={currentValue}
                    asset={issue.asset}
                  />
                ) : (
                  <Item
                    onItemSelected={onItemSelected}
                    currentValue={currentValue}
                  >
                    <Text numberOfLines={1} style={styles.itemAssetLabel}>
                      {issue.workspace.name}
                    </Text>
                  </Item>
                )}
              </View>
            </View>

            {/* show items from search */}
            {isFetchingNextPage || isLoading ? (
              <LoadingIndicator
                backgroundColor={Colors.white}
                flexContainer={true}
              />
            ) : (
              <FlatList
                refreshing={isFetchingNextPage}
                style={styles.scrollViewContainer}
                data={assets}
                extraData={assets}
                onEndReached={onEndReached}
                renderItem={({ item }) => (
                  <AssetItem
                    onItemSelected={onItemSelected}
                    currentValue={currentValue}
                    asset={item}
                  />
                )}
              />
            )}
          </View>
        </SaveFormTop>
      </Wrapper>
    </Modal>
  );
};

const NativeWrapper = ({
  children,
  visible,
}: {
  children: ReactNode;
  visible: boolean;
}) => {
  return children;
};

const WebWrapper = ({
  children,
  visible,
}: {
  children: ReactNode;
  visible: boolean;
}) => {
  const { singleTranslationRef, startAnimationTiming } = useAnimation();
  const modalOpenCloseInitialY = 100;
  const modalOpenCloseFinalY = 0;

  const subModalOpenCloseAnimation = (isShown: boolean) => {
    return isShown
      ? startAnimationTiming(
          modalOpenCloseInitialY,
          modalOpenCloseFinalY,
          300,
          300
        )
      : startAnimationTiming(modalOpenCloseFinalY, modalOpenCloseInitialY);
  };

  useLayoutEffect(() => {
    visible
      ? subModalOpenCloseAnimation(true)
      : subModalOpenCloseAnimation(false);
  }, [visible]);

  return (
    <View style={styles.modalBackground}>
      <Animated.View
        style={[
          styles.modalContainer,
          {
            opacity: singleTranslationRef.interpolate({
              inputRange: [0, 100],
              outputRange: [1, 0],
            }),
            transform: [{ translateY: singleTranslationRef }],
          },
        ]}
      >
        {children}
      </Animated.View>
    </View>
  );
};

type BaseItemProps = {
  onItemSelected: (item?: string) => Promise<void>;
  currentValue?: string;
};

type ItemProps = BaseItemProps & {
  children: ReactNode;
  value?: string;
};

const Item = ({ onItemSelected, currentValue, children, value }: ItemProps) => {
  return (
    <Pressable
      style={styles.itemContainer}
      onPress={() => onItemSelected(value)}
    >
      <RadioButton.Android
        value={value || ''}
        status={currentValue === value ? 'checked' : 'unchecked'}
        onPress={() => onItemSelected(value)}
      />
      {children}
    </Pressable>
  );
};

type AssetItemProps = BaseItemProps & {
  asset: Asset;
};

const AssetItem = ({ asset, onItemSelected, currentValue }: AssetItemProps) => {
  return (
    <Item
      onItemSelected={onItemSelected}
      currentValue={currentValue}
      value={asset.id}
    >
      <View style={styles.itemLabelsContainer}>
        <Text numberOfLines={1} style={styles.itemAssetLabel}>
          {asset.name}
        </Text>
        <Text numberOfLines={1} style={styles.itemDescriptionLabel}>
          {asset.description}
        </Text>
      </View>
    </Item>
  );
};

const styles = StyleSheet.create({
  modalBackground: {
    backgroundColor: Colors.blackInactive,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    width: 560,
    height: 600,
    borderRadius: 12,
    overflow: 'hidden',
    backgroundColor: Colors.primaryGrayBackground,
  },
  formContainer: {
    width: '100%',
    height: '100%',
    flex: 1,
  },
  upperFormContainer: {
    paddingTop: 20,
    paddingHorizontal: 20,
  },
  instructionsLabel: {
    fontSize: 16,
    fontWeight: '700',
    marginBottom: 15,
  },
  inputStyle: {
    borderColor: Colors.grayDivider,
    backgroundColor: Colors.white,
    borderWidth: 0.5,
    borderRadius: 12,
  },
  scrollViewContainer: {
    backgroundColor: Colors.white,
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxHeight: 40,
    paddingRight: 16,
    marginBottom: 16,
  },
  itemLabelsContainer: {
    flex: 1,
  },
  itemAssetLabel: {
    fontSize: 16,
    color: Colors.black,
    fontWeight: '400',
    lineHeight: 19,
  },
  itemDescriptionLabel: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19,
    color: Colors.grayText,
  },
});

const FIND_ASSETS = `
  query FindAssetsForReassignment($filter: AssetFilter, $page: PageRequest, $orderBy: OrderByInput) {
    findAssets(filter: $filter, page: $page, orderBy: $orderBy) {
      items {
        id
        description
        name
      }
      count
      limit
      offset
    }
  }
`;

const UPDATE_ISSUE = `
  mutation UpdateIssueAssignment($input: MutateIssueInput!) {
    updateIssue(input: $input) {
      id
    }
  }
`;
