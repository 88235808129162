import { TouchableOpacity, View } from 'react-native';
import { Colors } from '../../themes/colors';
import { LoginHeader } from './loginheader';
import { styles } from './styles';

type logoHeaderProps = {
  onEasterEgg?: () => void;
};

export const LogoHeader = (props: logoHeaderProps) => {
  return (
    <TouchableOpacity
      onLongPress={() => props.onEasterEgg && props.onEasterEgg()}
    >
      <View style={styles.headerImage}>
        <LoginHeader fillColor={Colors.primary} />
      </View>
    </TouchableOpacity>
  );
};
