import {
  MediaItem,
  MediaUploadArgs,
  getContentTypeByExt,
  getUploadBlobNameForMediaType,
  useGrippContext,
  useMediaUpload as useMediaUploadBase,
} from '@gripp/shared-logic';
import { useContainerClient } from './useContainerClient';

export const useMediaUpload = (args: MediaUploadArgs) => {
  const containerClient = useContainerClient();
  const { logger } = useGrippContext();

  const uploadToBlobStorage = async (mediaItem: MediaItem, file?: File) => {
    if (!containerClient || !file) return;

    const onUploadProgress = (progress: any, mediaSize: number) => {
      if (!args.onProgress) return;

      const percent = progress.loadedBytes / mediaSize;
      args.onProgress(mediaItem.id, percent);
    };

    const blobName = getUploadBlobNameForMediaType(
      mediaItem.name,
      mediaItem.id,
      mediaItem.type
    );
    const blobContentType = getContentTypeByExt(mediaItem.extension);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const fileMediaUrl = (await createWebUrlForLocalFile(file)) as string;
    mediaItem.url = fileMediaUrl;

    args.onStart && args.onStart(mediaItem);

    const response = await blockBlobClient
      .uploadData(file, {
        blobHTTPHeaders: {
          blobContentType: blobContentType,
        },

        onProgress: (progressEvent) =>
          onUploadProgress(progressEvent, file.size),
      })
      .catch((err) => {
        logger?.error(err, `Failed to upload media item: ${mediaItem.id}.`);
      });

    if (response?.errorCode) {
      logger?.error(
        `Failed to upload media item: ${mediaItem.id}. Error: ${response.errorCode}`
      );
    }

    args.onComplete && args.onComplete(mediaItem.id);
  };

  const uploadMediaBase = useMediaUploadBase({
    args: args,
    upload: uploadToBlobStorage,
  });

  const uploadMedia = async (file: File, workspaceId?: string) => {
    return uploadMediaBase(file?.name, workspaceId, file);
  };

  return uploadMedia;
};

const createWebUrlForLocalFile = (file: File) => {
  return new Promise((resolve, reject) => {
    try {
      const url = URL.createObjectURL(file);
      resolve(url);
    } catch (error) {
      reject(error);
    }
  });
};
