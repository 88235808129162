import {
  Media,
  MediaData,
  MediaPickerComponent,
  MediaPickerProps,
  MediaType,
} from '@gripp/shared-logic';
import { ReactNode } from 'react';
import { ImageStyle, StyleProp } from 'react-native';

//props
export type MediaComponentProps<T extends MediaData> = {
  mediaItem: T;
  hasLightbox?: boolean;
  displayWidth?: number;
  displayMode?: 'outlined' | 'card';
};
export type MediaComponent<T extends MediaData> = (
  props: MediaComponentProps<T>
) => ReactNode;

export type MediaGalleryProps = {
  media?: Media[];
  workspaceId?: string;
  onMediaAdded: (media: Media) => void;
  onMediaDeleted?: (mediaId: string) => void;
  mediaPicker: MediaPickerComponent;
  onUploadingChanged?: (isUploading: boolean) => void;
  acceptedMediaTypes?: MediaType[];
  canEditMedia?: boolean;
};

export type ImagePickerProps = MediaPickerProps & {
  allowsEditing?: boolean;
  IsSquareCameraView?: boolean;
};

export type SingleImageInputProps = {
  image?: Media;
  onImageAdded: (image: Media) => void;
  onUploadingChanged?: (isUploading: boolean) => void;
  imagePicker: ImagePickerComponent;
  allowsEditing?: boolean;
};

export type ImageThumbnailProps = {
  imageUri?: string;
  progress?: number;
  uploading?: boolean;
  style?: StyleProp<ImageStyle>;
};

//components
export type MediaGalleryComponent = (props: MediaGalleryProps) => ReactNode;
export type ImagePickerComponent = (props: ImagePickerProps) => ReactNode;
export type ImageThumbnailComponent = (props: ImageThumbnailProps) => ReactNode;

//types

export interface ImageData extends MediaData {
  urlsBySize?: Record<SizePrefix, string>;
  dimensions?: Dimensions;
}

export interface DocumentData extends MediaData {
  name: string;
  thumbnailUrl?: string;
  sizeInBytes?: number;
}

// export interface VideoData extends MediaData {
//   //placeholder
// }

// export interface AudioData extends MediaData {
//   //placeholder
// }

export type Dimensions = {
  width: number | null;
  height: number | null;
};

export enum SizePrefix {
  extraSmall = 'xs',
  small = 'sm',
  original = 'original',
}
