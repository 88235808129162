import {
  RoutineQuestionConfig,
  RoutineResponseState,
} from '@gripp/shared-logic';
import { RoutineQuestionModule } from '../types';
import { ExistingRoutineQuestionAdminForm } from './routineQuestionAdminForm';
import { ExistingRoutineQuestionExecutionForm } from './routineQuestionExecutionform';
import { ExistingRoutineQuestionMessage } from './routineQuestionMessage';

export const ExistingRoutineQuestionModule: RoutineQuestionModule<
  RoutineQuestionConfig,
  RoutineResponseState
> = {
  adminForm: ExistingRoutineQuestionAdminForm,
  executionForm: ExistingRoutineQuestionExecutionForm,
  message: ExistingRoutineQuestionMessage,
};
