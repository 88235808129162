import {
  cacheKeys,
  RoutineType,
  useCurrentWorkspace,
  useCustomMutation,
  useFilteredWorkspaceRoutines,
  useFindByQuery,
  useGrippContext,
  WorkflowQuestionType,
  WorkflowRoutineConfig,
} from '@gripp/shared-logic';
import { Colors, DragList, LoadingIndicator } from '@gripp/shared-ui';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Pressable, StyleSheet, View } from 'react-native';
import { RoutineCard } from './routineCard';

type RoutinesListProps = {
  listMaxHeight?: number;
  onRoutineSelected: (routineId: string) => void;
};

type Routine = {
  routineId: string;
  name: string;
  order: number;
};

type FormValues = {
  routines: Routine[];
};

export const RoutinesList = (props: RoutinesListProps) => {
  const workspace = useCurrentWorkspace();
  const heightStyle = {
    minHeight: props.listMaxHeight,
    maxHeight: props.listMaxHeight,
  };
  const [routineSelectedStyle, setRoutineSelectedStyle] =
    useState<boolean>(false);
  const [routineSelectedId, setRoutineSelectedId] = useState<string>('');
  const queryClient = useQueryClient();
  const cacheKey = cacheKeys('workspaceRoutine').all;
  const { logger } = useGrippContext();

  const routines = useFilteredWorkspaceRoutines(
    {
      workspace: { eq: workspace?.id },
      deactivated: { ne: true },
    },
    workspace?.id,
    !workspace
  );

  const { data: assignedRoutines } = useFindByQuery({
    modelName: 'workspaceAsset',
    query: GET_ASSIGNED_ROUTINES,
    variables: {
      filter: {
        workspace: { eq: workspace?.id },
      },
    },
    options: {
      enabled: !!workspace?.id,
    },
  });

  const { control, reset } = useForm<FormValues>({
    defaultValues: { routines: [] },
    mode: 'onChange',
  });

  useEffect(() => {
    if (!routines || !assignedRoutines) return;

    const routineCounts = assignedRoutines.items.reduce(
      (acc: Record<string, number>, item: any) => {
        item.routines?.forEach((routine: any) => {
          acc[routine.id] = (acc[routine.id] || 0) + 1;
        });
        return acc;
      },
      {}
    );

    const embeddedRoutines: Record<string, string[]>[] = [];
    routines
      ?.filter((routine) => routine.type === RoutineType.Workflow)
      .forEach((routine) => {
        const embeddedRoutineIds = (
          routine.config as WorkflowRoutineConfig
        ).pages[0].questions
          .filter((question) => question.type === WorkflowQuestionType.Routine)
          ?.map((x) => x.workspaceRoutineId);

        if (embeddedRoutineIds) {
          embeddedRoutineIds.forEach((embeddedRoutineId) => {
            let embeddedRoutine: Record<string, string[]> =
              embeddedRoutines[embeddedRoutineId];
            if (!embeddedRoutine) {
              embeddedRoutine = {};
              embeddedRoutine[embeddedRoutineId] = [routine.id];
              embeddedRoutines.push(embeddedRoutine);
            } else {
              embeddedRoutine[embeddedRoutineId].push(routine.id);
            }
          });
        }
      });

    embeddedRoutines.forEach((entry) => {
      const embeddedRoutineId = Object.keys(entry)[0];
      const routineIds = entry[embeddedRoutineId];

      const routineCount = routineIds.reduce((acc, routineId) => {
        return acc + (routineCounts[routineId] || 0);
      }, 0);

      if (!routineCount) return;

      if (routineCounts[embeddedRoutineId]) {
        routineCounts[embeddedRoutineId] += routineCount;
      } else {
        routineCounts[embeddedRoutineId] = routineCount;
      }
    });

    const sortedRoutines = routines
      .map((routine) => {
        return {
          routineId: routine.id,
          name: routine.config.title,
          order: routine.order,
          config: routine.config,
          type: routine.type,
          assignedCount: routineCounts[routine.id] || 0,
        };
      })
      .sort((a, b) => a.order - b.order);

    const defaultValues = { routines: sortedRoutines };

    reset(defaultValues);
  }, [routines, reset, assignedRoutines]);

  const { fields: routinesList, move: moveRoutine } = useFieldArray({
    control,
    name: 'routines',
  });

  const { mutateAsync: reorderWorkspaceRoutines } = useCustomMutation({
    query: REORDER_WORKSPACEROUTINES,
  });

  const renderRoutine = (row: any) => (
    <Pressable
      key={`${row.id}-${row.name}`}
      style={[styles.routineButton]}
      onPress={() => {
        onRoutineSelected(row);
        setRoutineSelectedStyle(true);
      }}
    >
      <RoutineCard routine={row} />
    </Pressable>
  );

  const onRoutineSelected = (routine: any) => {
    if (props.onRoutineSelected) {
      props.onRoutineSelected(routine.routineId);
      setRoutineSelectedId(routine.id);
    }
  };

  const handleMove = (fromIndex: number, toIndex: number) => {
    moveRoutine(fromIndex, toIndex);
  };

  const handleReorderedList = async (list: any[]) => {
    const routinesToUpdate = list.map((routine) => routine.routineId);

    const input = {
      workspaceRoutineIds: routinesToUpdate,
    };

    await reorderWorkspaceRoutines(
      { input },
      {
        onSettled: async (data, error?: Error) => {
          if (error) {
            logger?.error(error, 'Failed to reorder workspace routines');
          }
          await queryClient.invalidateQueries({ queryKey: cacheKey });
        },
      }
    );
  };

  return (
    <View style={[heightStyle, styles.container]}>
      {routinesList.length > 0 ? (
        <DragList
          list={routinesList}
          pivotKey={'routineId'}
          sortKey={'order'}
          renderCustomItem={renderRoutine}
          selectedItem={routineSelectedStyle}
          setSelectedItem={setRoutineSelectedStyle}
          selectedItemId={routineSelectedId}
          setSelectedItemId={setRoutineSelectedId}
          onReorderedFromTo={handleMove}
          onReordered={handleReorderedList}
        />
      ) : (
        <View style={styles.noRoutines}>
          <LoadingIndicator />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  noRoutines: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: Colors.white,
    borderRadius: 12,
    overflow: 'hidden',
  },
  routineButton: {
    flex: 1,
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

export default RoutinesList;

const REORDER_WORKSPACEROUTINES = `
  mutation Mutation($input: ReorderWorkspaceRoutinesInput!) {
    routine {
      reorderWorkspaceRoutines(input: $input) {
        success
        message
      }
    }
  }
`;

const GET_ASSIGNED_ROUTINES = `
  query FindWorkspaceAssets($filter: WorkspaceAssetFilter) {
    findWorkspaceAssets(filter: $filter) {
      items {
        routines {
          id
        }
      }
    }
  }
`;
