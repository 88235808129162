import { Tags } from "./components/tags";
import { TagBatches } from "./components/tagBatches";
import { TagsToolbar } from "./components/tagsToolbar";
import { PermissionValues } from "@gripp/shared-logic";
import { TagBatchesToolbar } from "./components/tagBatchesToolbar";

export const tagPaths = {
  tags: 'tags',
  tagBatches: 'tagbatches'
};

export const tagRoutes = [
  {
    path: tagPaths.tags,
    element: <Tags />,
    handle: {
      header: {
        titleKey: 'tags.title',
        icon: 'tag'
      },
      requiredPermission: PermissionValues.tag.all,
      toolbar: <TagsToolbar />,
    }
  },
  {
    path: tagPaths.tagBatches,
    element: <TagBatches />,
    handle: {
      header: {
        titleKey: 'tags.batches.title',
        icon: 'tag-multiple'
      },
      requiredPermission: PermissionValues.tagbatch.all,
      toolbar: <TagBatchesToolbar />
    }
  },
];