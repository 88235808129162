import {
  PermissionValues,
  useCurrentAccount,
  useLogout,
} from '@gripp/shared-logic';
import {
  AdminWorkspacePicker,
  Colors,
  KeyboardSafeView,
  Layout,
  ProfileForm,
  ProfileOptions,
  SaveFormBottom as SaveForm,
  Spacing,
  useProfileForm,
} from '@gripp/shared-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { MediaPicker } from '../../shared/components/media/mediaPicker';
import { getMediaBaseUrl } from '../../shared/mediaUtils';

export const Profile = () => {
  const mediaUrl = getMediaBaseUrl();
  const { t } = useTranslation();
  const { account } = useCurrentAccount();
  const navigate = useNavigate();
  const logout = useLogout();

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useProfileForm();

  const isGlobalAdmin = useCallback(() => {
    return account && account.hasPermission(PermissionValues.globalAdmin);
  }, [account]);

  const onNavigate = (path: string) => {
    navigate(`/${path}`);
  };

  const onLogout = () => {
    logout().then(() => {
      navigate('/');
    });
  };

  return (
    <KeyboardSafeView style={styles.container}>
      <ScrollView
        contentContainerStyle={styles.contentContainer}
        scrollEnabled={true}
        showsVerticalScrollIndicator={true}
      >
        <SaveForm
          title={t('avatar.menu.profile')}
          onSubmit={handleSubmit}
          isDirty={isDirty}
          isValid={isValid}
          hasBorder={true}
        >
          <ProfileForm
            control={control}
            mediaUrl={mediaUrl}
            imagePicker={MediaPicker}
            inputMaxWidth={322}
          />
        </SaveForm>
        {isGlobalAdmin() && (
          <View style={styles.workspacePicker}>
            <AdminWorkspacePicker />
          </View>
        )}
        <ProfileOptions onNavigate={onNavigate} onLogout={onLogout} />
      </ScrollView>
    </KeyboardSafeView>
  );
};

const styles = StyleSheet.create({
  container: {
    ...Layout.container,
    backgroundColor: Colors.primaryGrayBackground,
    maxWidth: 668,
  },
  contentContainer: {
    flex: 1,
    paddingVertical: Spacing.basePadding.paddingVertical,
  },

  workspacePicker: {
    marginTop: 8,
  },
});
