import { MediaStatus, useCreateMutation } from '../../graphql';
import { useConfig } from '../../hooks';
import { getFileDataFromUrl, getMediaTypeByExt } from '../mediaUtils';
import { MediaItem, MediaUploadArgs } from '../types';

type BaseHookArgs = {
  args: MediaUploadArgs;
  upload: (
    mediaItem: MediaItem,
    file?: File,
    fileUrl?: string
  ) => Promise<void>;
};

export const useMediaUpload = (args: BaseHookArgs) => {
  const { data } = useConfig();
  const { mutate } = useCreateMutation({
    query: CREATE_MEDIA,
    modelName: 'media',
  });

  const uploadMedia = async (
    fileUrl: string,
    workspaceId?: string,
    file?: File
  ) => {
    if (!data || !fileUrl) return;

    const mediaItem = createMediaItem(fileUrl);

    const input: any = {
      name: mediaItem.name,
      status: mediaItem.status,
      type: mediaItem.type,
      extension: mediaItem.extension,
    };

    if (workspaceId) {
      input.workspace = { id: workspaceId };
    }

    mutate(
      { input: input },
      {
        onSuccess: async (data) => {
          mediaItem.id = data?.id;
          await args.upload(mediaItem, file, fileUrl);
        },
      }
    );
  };

  return uploadMedia;
};

const createMediaItem = (fileUrl: string): MediaItem => {
  const fileData = getFileDataFromUrl(fileUrl);
  return {
    id: '',
    name: fileData.name,
    url: fileUrl,
    extension: fileData.ext,
    type: getMediaTypeByExt(fileData.ext),
    status: MediaStatus.Pending,
  };
};

const CREATE_MEDIA = `
  mutation Mutation($input: CreateMediaInput!) {
    createMedia(input: $input) {
      id
    }
  }
`;
