import React from 'react';
import { useWindowDimensions } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { AlertsFold } from './alertsFold';

export const Alerts = () => {
  const navigate = useNavigate();
  const windowDimensions = useWindowDimensions();
  const maxHeight = (windowDimensions?.height ?? 0) - 185; // substract banner + padding height

  const onAlertSelected = (alertId: string) => {
    navigate(`${alertId}/edit`);
  };

  return <AlertsFold maxHeight={maxHeight} onAlertSelected={onAlertSelected} />;
};
