import { Platform, StyleSheet } from 'react-native';
import { Colors } from '../../../themes';

export const datePickerStyle = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: Colors.primaryGrayBackground,
  },
  calendar: {
    backgroundColor: Colors.primaryGrayBackground,
    width: 258,
  },
  dateRangeContainer: {
    flexDirection: 'row',
    columnGap: 16,
    paddingBottom: 12,
  },
  dateRangeFieldContainer: {
    backgroundColor: Colors.primaryGrayBackground,
    borderRadius: 6,
    borderWidth: 1,
    padding: 12,
    height: 61,
    width: 153,
    ...Platform.select({
      web: {
        height: 61,
        width: 153,
      },
      ios: {
        paddingTop: 0,
        paddingHorizontal: 12,
        paddingBottom: 12,
      },
    }),
  },
  dateRangeFieldContainerActive: {
    borderColor: Colors.primary,
    backgroundColor: Colors.orangeActive,
  },
  label: {
    paddingBottom: 12,
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 9,
    ...Platform.select({
      ios: {
        paddingTop: 12,
      },
      android: {
        paddingTop: 4,
        paddingBottom: 8,
      },
    }),
  },
  inputContainer: {
    flexDirection: 'row',
    height: 16,
    justifyContent: 'space-around',
  },
  inputInnerStyle: {
    paddingLeft: 0,
    paddingRight: 0,
    width: 105,
    borderRadius: 0,
    backgroundColor: 'transparent',
  },
  calendarIcon: {
    margin: 0,
    padding: 0,
    width: 16,
    height: 16,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    columnGap: 10,
  },
  buttonHeaderMonth: {
    backgroundColor: Colors.white,
    height: 30,
    minWidth: 78,
    maxWidth: 140,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'center',
  },
  labelHeader: {
    fontSize: 16,
    fontWeight: '600',
    lineHeight: 16,
    color: Colors.black,
  },
  labelHeaderSelected: {
    color: Colors.primary,
    zIndex: 9,
  },
  textDay: {
    borderRadius: 8,
    width: 30,
    height: 30,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
