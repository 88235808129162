import { MaterialCommunityIcons } from '@expo/vector-icons';
import { AssetTag } from '@gripp/shared-logic';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import { Card, Text } from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { CustomAlert } from '../customAlert';
import { AssetTagGalleryProps } from './types';

const TAG_WIDTH = 80;

// checks for firefox web browser
// https://stackoverflow.com/questions/49328382/browser-detection-in-reactjs
const isFirefox = typeof InstallTrigger !== 'undefined';

export const AssetTagGallery = (props: AssetTagGalleryProps) => {
  const [assetTags, setAssetTags] = useState<AssetTag[]>(
    props.existingAssetTags
  );
  const TagPicker = props.tagPicker || (() => null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [contentContainerStyle, setContentContainerStyle] = useState({} as any);
  const { t } = useTranslation();

  const getHorizontalMediaGalleryWidth = useCallback((length: number) => {
    if (Platform.OS === 'web') {
      return (length - 1) * TAG_WIDTH + 80;
    } else {
      return (length + 1.1) * TAG_WIDTH;
    }
  }, []);

  useEffect(() => {
    setAssetTags(props.existingAssetTags);
    setContentContainerStyle({
      minWidth: getHorizontalMediaGalleryWidth(props.existingAssetTags.length),
      paddingRight: 8,
    });
  }, [getHorizontalMediaGalleryWidth, props.existingAssetTags]);

  const handleAdd = (newAssetTag: AssetTag) => {
    newAssetTag &&
      setAssetTags((prevState) => {
        if (prevState.find((t) => t.id === newAssetTag.id)) {
          setAlertTitle(t('asset.customAlert.tagAlreadyAdded'));
          setShowAlert(true);

          return prevState;
        }
        props.onAssetTagAdded(newAssetTag);
        setContentContainerStyle({
          width: getHorizontalMediaGalleryWidth(prevState.length + 1),
        });
        return [...prevState, newAssetTag];
      });
  };

  const handleDelete = (assetTagId: string) => {
    setAssetTags((prevState) => {
      props.onAssetTagDeleted(assetTagId);
      setContentContainerStyle({
        width: getHorizontalMediaGalleryWidth(prevState.length - 0.1),
      });
      return prevState.filter((t) => t.id !== assetTagId);
    });
  };

  return (
    <View style={styles.container}>
      {props.tagPicker && (
        <View style={styles.pickerContainer}>
          <TagPicker
            setAlertTitle={(title: string) => setAlertTitle(title)}
            setShowAlert={(visible: boolean) => setShowAlert(visible)}
            setTag={handleAdd}
          />
        </View>
      )}
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={true}
        scrollEnabled={true}
        contentContainerStyle={contentContainerStyle}
        persistentScrollbar={true}
      >
        {assetTags.map((t) => (
          <View style={styles.itemContainer} key={t.id}>
            <Pressable
              hitSlop={10}
              style={styles.deleteButton}
              onPress={() => handleDelete(t.id)}
            >
              <MaterialCommunityIcons
                name="close-circle"
                color={Colors.primary}
                size={24}
              />
            </Pressable>
            <Card style={styles.tag}>
              <MaterialCommunityIcons
                name="qrcode"
                size={80}
                color={Colors.primaryGrayHeader}
                style={isFirefox && styles.qrCodeIcon}
              />
              <Text style={styles.text}>{t.tagCode}</Text>
            </Card>
          </View>
        ))}
      </ScrollView>
      <CustomAlert
        visible={showAlert}
        hideAlert={() => setShowAlert(false)}
        title={alertTitle}
        secondaryButtonLabel={t('customAlert.ok')}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    alignSelf: 'flex-start',
    justifyContent: 'space-between',
  },
  itemContainer: {
    marginLeft: 2,
    marginBottom: 4,
    paddingRight: 10,
    paddingTop: 10,
  },
  pickerContainer: {
    marginRight: 8,
    marginBottom: 4,
    marginTop: 10,
    marginLeft: 2,
  },
  tag: {
    alignSelf: 'center',
    height: TAG_WIDTH,
    width: 80,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  qrCodeIcon: {
    ...Platform.select({
      web: {
        bottom: 8,
      },
    }),
  },
  text: {
    alignSelf: 'center',
    top: 33,
    position: 'absolute',
    fontSize: 14,
    fontWeight: '500',
    color: Colors.black,
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
  },
});
