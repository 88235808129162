import { Severity } from '@gripp/shared-logic';
import { Colors } from '../../themes/colors';
import { SeverityIcons } from '../issue';

export type NotificationSectionType = {
  backgroundColor: string;
  color: string;
  icon: string;
  sizeIcon: number;
  labelKey: string;
  value?: string;
  name: string;
};

export const notificationsSection: NotificationSectionType[] = [
  {
    backgroundColor: Colors[Severity.Red],
    color: Colors.white,
    icon: SeverityIcons[Severity.Red],
    sizeIcon: 16,
    labelKey: 'preferences.notifications.severities.red',
    value: 'red',
    name: 'newIssueSeverities',
  },
  {
    backgroundColor: Colors[Severity.Purple],
    color: Colors.white,
    icon: SeverityIcons[Severity.Purple],
    sizeIcon: 16,
    labelKey: 'preferences.notifications.severities.purple',
    value: 'purple',
    name: 'newIssueSeverities',
  },
  {
    backgroundColor: Colors[Severity.Blue],
    color: Colors.white,
    icon: SeverityIcons[Severity.Blue],
    sizeIcon: 16,
    labelKey: 'preferences.notifications.severities.blue',
    value: 'blue',
    name: 'newIssueSeverities',
  },
  {
    backgroundColor: 'transparent',
    color: Colors.black,
    icon: 'message-badge-outline',
    sizeIcon: 20,
    labelKey: 'preferences.notifications.issueReplies',
    value: 'replies',
    name: 'issueReplies',
  },
];
