import { useEffect, useState } from 'react';
import { useFindByQuery } from '../../graphql';

export const useTagByTagCode = (tagCode: string) => {
  const [tag, setTag] = useState<any>(undefined);
  const variables = {
    tagCode: tagCode,
  };

  const { data, isLoading } = useFindByQuery({
    modelName: 'tag',
    query: FIND_TAG_BY_TAGCODE,
    variables: variables,
    options: {
      staleTime: 0,
      gcTime: 0,
    },
  });

  useEffect(() => {
    if (!data) return;

    setTag(data.items[0]);
  }, [data]);

  return {
    tag,
    isLoading,
  };
};

const FIND_TAG_BY_TAGCODE = `
  query FindTags($tagCode: String) {
    findTags(filter: {
      tagCode: { eq: $tagCode}
    }) {
      items {
        id
        tagCode
        link {
          linkData
          type
        }
      }
    }
  }
`;
