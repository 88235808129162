import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TFunction } from 'i18next';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { LoginHeader } from '../../screens/login/loginheader';

export const InvitationAccepted = ({ t }: { t: TFunction }) => {
  return (
    <View style={styles.accepted}>
      <View style={styles.acceptedImage}>
        <LoginHeader fillColor={Colors.primary} />
      </View>
      <View>
        <Text style={styles.acceptedText}>
          {t('invitation.pending.accepted')}
        </Text>
        <MaterialCommunityIcons
          name="email-check-outline"
          size={48}
          color={Colors.grayText}
          style={styles.acceptedIcon}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  accepted: {
    backgroundColor: Colors.primaryGrayBackground,
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  acceptedImage: {
    paddingVertical: 85,
  },
  acceptedText: {
    fontSize: 16,
    alignContent: 'center',
    textAlign: 'center',
  },
  acceptedIcon: {
    alignSelf: 'center',
    marginTop: 20,
  },
});
