import { QuickConfirmConfig, QuickConfirmState } from '@gripp/shared-logic';
import { RoutineModule } from '../types';
import { QuickConfrimButtons } from './quickConfirmButtons';
import {
  QuickConfirmExecutionForm,
  getExecutionFormDefaultValues,
  getExecutionFormResolver,
} from './quickConfirmExecutionForm';
import { QuickConfirmMessage } from './quickConfirmMessage';
import {
  QuickConfirmAdminForm,
  QuickConfirmAdminSchema,
} from './quickConfirmAdminForm';

export const QuickConfirmModule: RoutineModule<
  QuickConfirmConfig,
  QuickConfirmState
> = {
  buttons: QuickConfrimButtons,
  executionForm: QuickConfirmExecutionForm,
  getExecutionFormDefaultValues,
  getExecutionFormResolver,
  adminForm: QuickConfirmAdminForm,
  adminSchema: QuickConfirmAdminSchema,
  message: QuickConfirmMessage,
};
