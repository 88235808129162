import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { Severity, WorkspaceAsset } from '@gripp/shared-logic';
import {
  Colors,
  EditRoutineModal,
  Layout,
  RoutineButtons,
  RoutineExecutionProps,
  RoutineModal,
  SeverityIcons,
  TopModalBar,
  TouchableButton,
} from '@gripp/shared-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

export type RoutinesAssignedListModalProps = {
  workspaceAsset: WorkspaceAsset;
  isOpened: boolean;
  onCompleteRoutines: () => Promise<void>;
  onCancel?: () => void;
};

export const RoutinesAssignedListModal = (
  props: RoutinesAssignedListModalProps
) => {
  const [routinesModalStyle, setRoutinesModalStyle] = useState<
    StyleProp<ViewStyle>
  >(styles.outerContainer);
  const [editModalStyle, setEditModalStyle] = useState<StyleProp<ViewStyle>>(
    styles.hidden
  );
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [executeModalVisible, setExecuteModalVisible] = useState(false);
  const [executeModalProps, setExecuteModalProps] = useState<
    RoutineExecutionProps | undefined
  >();

  useEffect(() => {
    setRoutinesModalStyle(styles.outerContainer);
  }, [props.isOpened]);

  const { t } = useTranslation();

  const icon = (
    <MaterialCommunityIcons
      name={SeverityIcons[Severity.Blue]}
      color={Colors.blue}
      size={24}
      style={{ paddingRight: 6 }}
    />
  );

  const showRoutinesModal = () => {
    setRoutinesModalStyle(styles.outerContainer);
  };

  const hideRoutinesModal = () => {
    setRoutinesModalStyle(styles.hidden);
  };

  const showEditModal = () => {
    setEditModalVisible(true);
    setEditModalStyle(styles.outerContainer);
  };

  const hideEditModal = () => {
    setEditModalVisible(false);
    setEditModalStyle(styles.hidden);
  };

  const onEdit = () => {
    hideRoutinesModal();
    showEditModal();
  };

  const onRoutineSelected = (props: RoutineExecutionProps) => {
    setExecuteModalProps(props);
    hideRoutinesModal();
    setExecuteModalVisible(true);
  };

  return (
    <>
      <Modal
        transparent
        visible={props.isOpened}
        onRequestClose={props.onCancel}
      >
        <View style={routinesModalStyle}>
          <View style={styles.innerContainer}>
            <View style={styles.container}>
              <TopModalBar
                title={t('asset.header.buttons.routines')}
                onCancel={props.onCancel}
                additionalHeaderBlock={icon}
                right={<EditButton onPress={onEdit} />}
              />
              <RoutineButtons
                workspaceAsset={props.workspaceAsset}
                onRoutineSelected={onRoutineSelected}
                hideAssignRoutinesButton={true}
                buttonIcon={Severity.Blue}
                buttonIconStyle={styles.buttonIconStyle}
                buttonStyle={styles.buttonStyle}
                buttonsContainerStyle={styles.buttonContainerStyle}
                showAll={true}
                initialRoutinesToShow={10}
              />
            </View>
          </View>
        </View>
      </Modal>
      <EditRoutineModal
        workspaceAsset={props.workspaceAsset}
        editRoutineModalVisible={editModalVisible}
        onClose={() => {
          hideEditModal();
          showRoutinesModal();
        }}
        modalStyle={editModalStyle}
        isWeb={Platform.OS === 'web'}
      />
      {executeModalProps && (
        <RoutineModal
          {...executeModalProps}
          visible={executeModalVisible}
          confirmCancel={true}
          onCanceled={() => {
            setExecuteModalVisible(false);
            props.onCancel && props.onCancel();
          }}
          onCompleted={async () => {
            setExecuteModalVisible(false);
            await props.onCompleteRoutines();
          }}
        />
      )}
    </>
  );
};

const EditButton = ({ onPress }: { onPress: () => void }) => {
  const { t } = useTranslation();
  return (
    <TouchableButton
      label={t('routines.editRoutines.title')}
      style={styles.editButton}
      onPress={onPress}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    ...Layout.container,
    backgroundColor: Colors.primaryGrayBackground,
    borderRadius: 12,
    paddingBottom: 12,
  },
  innerContainer: {
    width: '100%',
    maxWidth: 560,
    height: 560,
  },
  hidden: {
    height: 0,
    width: 0,
    maxHeight: 0,
    minHeight: 0,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  outerContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 40,
    backgroundColor: Colors.blackInactive,
  },
  buttonIconStyle: {
    color: Colors.white,
    backgroundColor: Colors.blue,
    borderRadius: 4,
    height: 25,
    width: 25,
    marginBottom: 0,
    marginRight: 12,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  buttonStyle: {
    backgroundColor: Colors.white,
    borderRadius: 0,
    borderWidth: 0,
    height: 64,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: Colors.grayDivider,
  },
  buttonContainerStyle: {
    flex: 0,
    flexGrow: 0,
    minHeight: 461,
    backgroundColor: Colors.white,
  },
  editButton: {
    borderColor: Colors.primaryDark,
    borderWidth: 2,
    borderRadius: 12,
    height: 40,
    justifyContent: 'center',
    paddingHorizontal: 24,
    marginRight: 12,
  },
});
