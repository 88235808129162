import { WorkflowQuestionType } from 'libs/shared-logic/src/routine/domain/workflow/workflowQuestionType';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Button } from 'react-native-paper';
import { Select } from '../../../forms';

export const AddQuestion = ({
  i18nPrefix,
  onPress,
}: {
  i18nPrefix: string;
  onPress: (type: WorkflowQuestionType) => void;
}) => {
  const { t } = useTranslation();
  const [showQuestionTypes, setShowQuestionTypes] = useState(false);

  const questionTypes = useMemo(() => {
    const types = [];
    const typePrefix = 'routines.workflow.questionTypes';

    for (const type in WorkflowQuestionType) {
      const value =
        WorkflowQuestionType[type as keyof typeof WorkflowQuestionType];
      const label = t(`${typePrefix}.${value}`);
      types.push({ label, value });
    }

    types.sort((a, b) => a.label.localeCompare(b.label));

    return types;
  }, []);

  const onTypeSelected = (type: WorkflowQuestionType) => {
    setShowQuestionTypes(false);
    onPress(type);
  };

  return (
    <View style={styles.addQuestion}>
      {showQuestionTypes ? (
        <View style={styles.questionType}>
          <Select
            items={questionTypes}
            onSelectItem={(item) => onTypeSelected(item)}
            label={t(`${i18nPrefix}.questionType`)}
            listMode="SCROLLVIEW"
          />
        </View>
      ) : (
        <Button icon="plus" onPress={() => setShowQuestionTypes(true)}>
          {t(`${i18nPrefix}.addQuestion`)}
        </Button>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  addQuestion: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  questionType: {
    maxWidth: 550,
  },
});
