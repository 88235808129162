import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  AssetFilterModel,
  RoutineType,
  formatShortDateOrSameDay,
  useCurrentWorkspace,
  useSetAssetListFilter,
  useStatefulWorkspaceRoutines,
} from '@gripp/shared-logic';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleProp, StyleSheet, Text, View } from 'react-native';
import { Colors } from '../../themes';
import { BaseColumnCheckbox } from '../baseColumnCheckbox';
import { getGroupColumn } from '../group';
import { WorkspaceColumn } from '../workspace';

const IconSize = 18;

const BaseColumns: Record<
  string,
  { element: (info: any) => JSX.Element; exportCell?: (info: any) => string }
> = {
  name: {
    element: (info) => getInfoLabel(styles.nameLabel, info),
  },
  description: {
    element: (info) => getInfoLabel(styles.greyLabel, info),
  },
  lastUpdated: {
    element: (info) => getShortDateLabel(info),
  },
  lastScannedAt: {
    element: (info) => getShortDateLabel(info),
  },
  reports: {
    element: (info) => {
      const data = info.row.original;

      return (
        <View style={styles.iconRow}>
          <View style={[styles.iconContainer, { marginRight: 12 }]}>
            <MaterialCommunityIcons
              name="flag"
              color={Colors.red}
              size={IconSize}
              style={styles.icon}
            />
            {getLabel(styles.greyLabel, data.numReportsRed)}
          </View>

          <View style={styles.iconContainer}>
            <MaterialCommunityIcons
              name="flag-outline"
              color={Colors.yellow}
              size={IconSize}
              style={styles.icon}
            />
            {getLabel(styles.greyLabel, data.numReportsYellow)}
          </View>
        </View>
      );
    },
    exportCell: (data) =>
      `Red: ${data.numReportsRed}, Yellow: ${data.numReportsYellow}`,
  },
  routines: {
    element: (info) => {
      const data = info.row.original;

      return (
        <View style={styles.iconRow}>
          <MaterialCommunityIcons
            name="format-list-checks"
            color={Colors.blue}
            size={IconSize}
            style={styles.icon}
          />
          {getLabel(styles.greyLabel, data.numRoutines)}
        </View>
      );
    },
    exportCell: (data) => data.numRoutines,
  },
  notes: {
    element: (info) => {
      const data = info.row.original;
      return (
        <View style={styles.iconRow}>
          <MaterialCommunityIcons
            name="note-plus"
            color={Colors.purple}
            size={IconSize}
            style={styles.icon}
          />
          {getLabel(styles.greyLabel, data.numNotes)}
        </View>
      );
    },
    exportCell: (data) => data.numNotes,
  },
  lastAddress: {
    element: (info) => getInfoLabel(styles.greyLabel, info),
  },
  tagCode: {
    element: (info) => getInfoLabel(styles.greyLabel, info),
  },
  createdAt: {
    element: (info) => getShortDateLabel(info),
  },
};

const getWorkspaceColumn = (info: any, canImpersonate: boolean) => {
  const workspaces = info.getValue();
  const workspace =
    workspaces && workspaces.length > 0 ? workspaces[0] : undefined;

  return (
    workspace && (
      <WorkspaceColumn canImpersonate={canImpersonate} workspace={workspace} />
    )
  );
};

export const useColumns = (
  canImpersonate: boolean,
  filter?: AssetFilterModel
) => {
  const [columns, setColumns] = useState<ColumnDef<unknown, never>[]>([]);
  const { mutateAsync: setAssetListFilter } = useSetAssetListFilter();

  const { t } = useTranslation();
  const columnHelper = createColumnHelper();
  const workspace = useCurrentWorkspace(true);
  const routines = useStatefulWorkspaceRoutines(workspace?.id);
  const isWeb = Platform.OS === 'web';

  const updateGroupFilter = async (groupId: string) => {
    await setAssetListFilter(
      new AssetFilterModel({
        ...filter,
        groups: [groupId],
      })
    );
  };

  const getRoutineExportValue = (routineState, routine) => {
    if (!routineState || !routine) return '';

    const state = routineState.find((x) => x.workspaceRoutineId === routine.id);

    let value;
    switch (routine.type) {
      case RoutineType.Counter:
        const count = state?.state?.count;
        if (count !== undefined) {
          value = `${state?.state?.count} ${routine.config?.uom}`;
        }
        break;
      case RoutineType.Status:
        value = state?.state?.status;
        break;
    }
    return value;
  };

  BaseColumns['groups'] = {
    element: (info) => getGroupColumn(info, updateGroupFilter),
    exportCell: (data) =>
      data.groups?.map((group) => group.name).join(', ') || '',
  };

  BaseColumns['workspaces'] = {
    element: (info) => getWorkspaceColumn(info, canImpersonate),
    exportCell: (data) =>
      data.workspaces?.map((workspace) => workspace.name).join(', ') || '',
  };

  useEffect(() => {
    if (!filter) return;

    const updatedColumns: ColumnDef<unknown, never>[] = [];

    if (isWeb && workspace) {
      updatedColumns.push(
        createCheckboxColumn(
          'selected',
          BaseColumnCheckbox.checkbox,
          BaseColumnCheckbox.checkboxHeader
        )
      );
    }

    updatedColumns.push(createInfoColumn('name', BaseColumns.name.element));

    Object.entries(BaseColumns).forEach(([key, cell]) => {
      if (filter.columns?.includes(key)) {
        const sortable = key !== 'tagCode';
        updatedColumns.push(
          createInfoColumn(
            key,
            cell.element,
            undefined,
            cell.exportCell,
            sortable
          )
        );
      }
    });

    if (routines) {
      routines.forEach((routine) => {
        if (![RoutineType.Counter, RoutineType.Status].includes(routine.type))
          return;

        if (filter.columns?.includes(`routine:${routine.id}`)) {
          updatedColumns.push(
            createInfoColumn(
              `routine:${routine.id}`,
              (info) => {
                const routineStates = info.row.original.routineState;
                if (!routineStates) return <></>;

                const routineState = info.row.original.routineState.find(
                  (x: any) => x.workspaceRoutineId === routine.id
                );
                let value;
                switch (routine.type) {
                  case RoutineType.Counter:
                    const count = routineState?.state?.count;
                    if (count !== undefined) {
                      value = `${routineState?.state?.count} ${
                        (routine.config as any)?.uom
                      }`;
                    }
                    break;
                  case RoutineType.Status:
                    value = routineState?.state?.status;
                    break;
                }
                return getLabel(styles.greyLabel, value);
              },
              routine.config.title ?? routine.name,
              (data) => getRoutineExportValue(data.routineState, routine)
            )
          );
        }
      });
    }

    setColumns(updatedColumns);
  }, [filter, workspace, routines]);

  const createCheckboxColumn = (
    accessor: string,
    cell: (info: any) => JSX.Element,
    header: (info: any) => JSX.Element
  ) => {
    return columnHelper.accessor(accessor, {
      cell: cell,
      header: header,
      meta: { style: styles.checkboxCell },
    });
  };

  const createInfoColumn = (
    accessor: string,
    cell: (info: any) => JSX.Element,
    headerOverride?: string,
    exportCell?: (info: any) => string,
    sortable = true
  ) => {
    return columnHelper.accessor(accessor, {
      cell: cell,
      header: () => headerOverride ?? t(`assets.list.${accessor}`),
      meta: {
        style: styles.cell,
        sortable: sortable,
        exportCell: exportCell,
      },
    });
  };

  return columns;
};

const getLabel = (labelStyle: StyleProp<any>, label: string) => {
  return <Text style={[labelStyle, styles.cellText]}>{label}</Text>;
};

const getInfoLabel = (labelStyle: StyleProp<any>, info: any) => {
  return getLabel(labelStyle, info.getValue());
};

const getShortDateLabel = (info: any) => {
  const value = info.getValue();
  const date = value ? formatShortDateOrSameDay(value) : '';

  return getLabel(styles.greyLabel, date);
};

const styles = StyleSheet.create({
  cell: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    paddingVertical: 4,
    ...Platform.select({
      web: {
        height: 50,
        paddingRight: 16,
      },
      default: {
        height: 68,
        paddingRight: 12,
      },
    }),
  },
  checkboxCell: {
    maxWidth: 50,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  nameLabel: {
    color: Colors.black,
  },
  greyLabel: {
    color: Colors.grayText,
  },
  cellText: {
    fontSize: 16,
    lineHeight: 17,
    ...Platform.select({
      web: {
        fontWeight: '500',
        flexWrap: 'nowrap',
      },
      default: {
        fontWeight: '400',
        flexWrap: 'wrap',
      },
    }),
  },
  iconContainer: {
    flexDirection: 'row',
  },
  icon: {
    marginRight: 2,
  },
  iconRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
  },
});
