import {
  AssetRoutineState,
  Issue,
  Severity,
  WorkspaceAsset,
  WorkspaceRoutine,
} from '@gripp/shared-logic';
import { useMemo } from 'react';
import {
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { RoutineModule } from '../types';
import { EditRequestRoutine } from './editRoutine';
import { RoutineModuleButton } from './routineModuleButton';

type RoutineButtonsProps = {
  workspaceAsset: WorkspaceAsset;
  showAll: boolean;
  initialRoutinesToShow: number;
  hideAssignRoutinesButton?: boolean;
  onRoutineSelected: (props: RoutineExecutionProps) => void;
  buttonStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<ViewStyle>;
  buttonIcon?: Severity;
  buttonIconStyle?: StyleProp<ViewStyle>;
  buttonsContainerStyle?: StyleProp<ViewStyle>;
};

export type RoutineExecutionProps = {
  workspaceAsset: WorkspaceAsset;
  module: RoutineModule<any, any>;
  routine?: WorkspaceRoutine;
  state?: AssetRoutineState<any>;
  additionalState?: any;
  sourceIssue?: Issue;
};

export const RoutineButtons = (props: RoutineButtonsProps) => {
  const { workspaceAsset, showAll, initialRoutinesToShow } = props;

  const buttonData = useMemo(() => {
    if (!workspaceAsset.routines || workspaceAsset.routines.length === 0) {
      return [];
    }

    return workspaceAsset.routines
      .filter((r) => r && r.deactivated !== true)
      .sort((a, b) => {
        let routineA, routineB;

        if (
          workspaceAsset.routineOrder &&
          workspaceAsset.routineOrder.length > 0
        ) {
          routineA = workspaceAsset.routineOrder.find(
            (x) => x.workspaceRoutineId === a.id
          );
          routineB = workspaceAsset.routineOrder.find(
            (x) => x.workspaceRoutineId === b.id
          );
        }
        const aOrder = routineA?.order ?? a.order;
        const bOrder = routineB?.order ?? b.order;
        return aOrder - bOrder;
      })
      .slice(0, showAll ? undefined : initialRoutinesToShow)
      .map((routine) => ({
        routine,
        state: workspaceAsset.routineState?.find(
          (state) => state.workspaceRoutineId === routine.id
        ),
      }));
  }, [workspaceAsset, showAll, initialRoutinesToShow]);

  const onClick = (
    routine: WorkspaceRoutine,
    module: RoutineModule<any, any>,
    state?: AssetRoutineState<any>,
    additionalState?: any
  ) => {
    props.onRoutineSelected({
      workspaceAsset,
      routine,
      module,
      state,
      additionalState,
    });
  };

  const Buttons = () => {
    if (buttonData?.length === 0) return null;

    return buttonData.map((data, index) => {
      return (
        <RoutineModuleButton
          key={`routine-button-${index}`}
          routine={data.routine}
          state={data.state as AssetRoutineState<any>}
          buttonIndex={index}
          buttonCount={buttonData.length}
          buttonStyle={props.buttonStyle}
          buttonIcon={props.buttonIcon}
          buttonIconStyle={props.buttonIconStyle}
          onClick={onClick}
        />
      );
    });
  };

  return (
    <ScrollView
      scrollEnabled={showAll}
      showsVerticalScrollIndicator={false}
      style={
        props.buttonsContainerStyle
          ? props.buttonsContainerStyle
          : styles.scrollView
      }
    >
      <Buttons />
      {(buttonData.length < initialRoutinesToShow || showAll) &&
        !props.hideAssignRoutinesButton && (
          <View style={buttonData.length > 1 && { marginTop: 16 }}>
            <EditRequestRoutine workspaceAsset={workspaceAsset} />
          </View>
        )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flex: 0,
  },
});
