import { useRef } from 'react';
import { Animated } from 'react-native';

export const useAnimation = () => {
  const opacity = useRef(new Animated.Value(0)).current;
  const singleTranslationRef = useRef(new Animated.Value(0)).current; // for single component animation
  const translationRef = useRef(new Animated.Value(0)).current;
  const translationRefTwo = useRef(new Animated.Value(0)).current;
  const translationRefThree = useRef(new Animated.Value(0)).current;

  const translationRefXY = useRef(
    new Animated.ValueXY({
      x: 0,
      y: 0,
    })
  ).current;
  const translationTwoRefXY = useRef(
    new Animated.ValueXY({ x: 0, y: 0 })
  ).current;

  const fadeIn = (duration = 300) => {
    Animated.timing(opacity, {
      toValue: 1,
      duration,
      useNativeDriver: true,
    }).start();
  };

  const fadeOut = (duration = 300) => {
    Animated.timing(opacity, {
      toValue: 0,
      duration: duration,
      useNativeDriver: true,
    }).start();
  };

  const startAnimationTiming = (
    initPosition: number,
    finalPosition?: number,
    duration?: number,
    delay?: number,
    useNativeDriver?: boolean
  ) => {
    singleTranslationRef.setValue(initPosition);

    Animated.timing(singleTranslationRef, {
      toValue: finalPosition || 0,
      duration: duration || 300,
      delay: delay || 0,
      useNativeDriver: useNativeDriver || true,
    }).start();
  };

  const startTwoComponentStaggerAnimationXY = (
    initialPosX: number,
    initialPosY: number,
    finalPosX?: number,
    finalPosY?: number,
    duration?: number,
    staggerDuration?: number,
    reverseAnimationOrder?: boolean
  ) => {
    translationRefXY.x.setValue(initialPosX);
    translationRefXY.y.setValue(initialPosY);
    translationTwoRefXY.x.setValue(initialPosX);
    translationTwoRefXY.y.setValue(initialPosY);

    if (reverseAnimationOrder) {
      Animated.stagger(staggerDuration || 25, [
        // animate component two in the X,Y axis
        Animated.timing(translationTwoRefXY.x, {
          toValue: finalPosX || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),
        Animated.timing(translationTwoRefXY.y, {
          toValue: finalPosY || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),
        // animate component one in the X and Y axis

        Animated.timing(translationRefXY.x, {
          toValue: finalPosX || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),
        Animated.timing(translationRefXY.y, {
          toValue: finalPosY || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      Animated.stagger(staggerDuration || 25, [
        // animate component one in the X and Y axis
        Animated.timing(translationRefXY.x, {
          toValue: finalPosX || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),
        Animated.timing(translationRefXY.y, {
          toValue: finalPosY || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),

        // animate component two in the X,Y axis
        Animated.timing(translationTwoRefXY.x, {
          toValue: finalPosX || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),
        Animated.timing(translationTwoRefXY.y, {
          toValue: finalPosY || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),
      ]).start();
    }
  };

  const startThreeComponentStaggerAnimationY = (
    staggerDelay: number,
    initialPosY: number,
    finalPosY?: number,
    duration?: number,
    reverseAnimationOrder?: boolean
  ) => {
    translationRef.setValue(initialPosY);
    translationRefTwo.setValue(initialPosY);
    translationRefThree.setValue(initialPosY);

    if (reverseAnimationOrder) {
      Animated.stagger(staggerDelay, [
        // animate component three in the Y axis
        Animated.timing(translationRefThree, {
          toValue: finalPosY || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),

        // animate component two in the Y axis
        Animated.timing(translationRefTwo, {
          toValue: finalPosY || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),

        // animate component one in Y axis
        Animated.timing(translationRef, {
          toValue: finalPosY || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      Animated.stagger(staggerDelay, [
        // animate component one in Y axis
        Animated.timing(translationRef, {
          toValue: finalPosY || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),

        // animate component two in the Y axis
        Animated.timing(translationRefTwo, {
          toValue: finalPosY || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),

        // animate component three in the Y axis
        Animated.timing(translationRefThree, {
          toValue: finalPosY || 0,
          duration: duration || 300,
          useNativeDriver: true,
        }),
      ]).start();
    }
  };

  return {
    opacity,
    singleTranslationRef,
    translationRef,
    translationRefTwo,
    translationRefThree,
    translationRefXY,
    translationTwoRefXY,
    fadeIn,
    fadeOut,
    startAnimationTiming,
    startThreeComponentStaggerAnimationY,
    startTwoComponentStaggerAnimationXY,
  };
};
