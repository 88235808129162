import { StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';

type Props = {
  label: string;
  onPress: () => void;
};

export const StepPrimaryButton = ({ label, onPress }: Props) => {
  return (
    <Button
      mode="contained"
      style={{ borderRadius: 12 }}
      contentStyle={styles.content}
      labelStyle={styles.label}
      onPress={onPress}
    >
      {label}
    </Button>
  );
};

const styles = StyleSheet.create({
  content: {
    height: 44,
  },
  button: {
    borderRadius: 12,
  },
  label: {
    fontWeight: '500',
    fontSize: 16,
  },
});
