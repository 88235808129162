import { useState } from 'react';
import { RoutineType } from '../../graphql';
import { WorkspaceRoutine } from '../domain';
import { useFilteredWorkspaceRoutines } from './useFilteredWorkspaceRoutines';

export const useStatefulWorkspaceRoutines = (
  workspaceId?: string,
  disabled?: boolean
): WorkspaceRoutine[] => {
  const [routines, setRoutines] = useState<WorkspaceRoutine[]>([]);

  const filter = {
    workspace: { eq: workspaceId },
    type: { in: [RoutineType.Counter, RoutineType.Status] },
  };

  return useFilteredWorkspaceRoutines(filter, workspaceId, disabled);
};
