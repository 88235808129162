import { WorkflowQuestionType } from './workflowQuestionType';

export interface WorkflowQuestionConfig {
  id: string;
  name: string;
  order: number;
  required: boolean;
  instructions?: string;
  type: WorkflowQuestionType;
}
