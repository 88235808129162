import { Colors } from '../../themes';
import { StyleSheet } from 'react-native';

export const filterStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: Colors.primaryGrayBackground,
    width: '100%',
  },
  scrollViewContainer: {
    flex: 1,
  },
  scrollViewContent: {
    backgroundColor: Colors.primaryGrayBackground,
    flexGrow: 1,
    padding: 12,
  },
  groupsSection: {
    flex: 1,
    marginBottom: 8,
  },
  filterRowHeader: {
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.25,
    marginBottom: 12,
    color: Colors.blackText,
  },
  applyButtonContainer: {
    flexDirection: 'row',
    padding: 12,
  },
  applyButton: {
    borderRadius: 8,
    height: 44,
    flex: 1,
    bottom: 32,
  },
  markersContainer: {
    flex: 1,
    backgroundColor: Colors.white,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.graySpacer,
  },
  markerItemRow: {
    flex: 1,
    width: '100%',
    height: 48,
    maxHeight: 48,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    paddingLeft: 12,
    paddingRight: 4,
  },
  markerLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  markerLabel: {
    fontSize: 16,
    fontWeight: '400',
    letterSpacing: -0.25,
  },
  colorButton: {
    width: 30,
    height: 24,
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
  colorButtonSelected: {
    borderWidth: 2,
    borderColor: Colors.black,
  },
  rowContainer: {
    height: 44,
    marginBottom: 12,
    backgroundColor: Colors.white,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.graySpacer,
    paddingLeft: 12,
    paddingRight: 6,
  },
  rowDateFilterContainer: {
    height: 44,
  },
  checkboxLabel: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: '500',
    letterSpacing: -0.25,
    color: Colors.black,
  },
  rowRadioButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  dateFilterRadioButton: {
    flexDirection: 'column',
  },
  dateFilterRowRadioButton: {
    marginBottom: 12,
    borderRadius: 8,
    borderWidth: 1,
    paddingLeft: 12,
    paddingRight: 6,
    flexDirection: 'column',
    backgroundColor: Colors.white,
    borderColor: Colors.graySpacer,
  },
  renderCustomDate: {
    flexDirection: 'row',
  },
});
