export enum MentionsType {
  User = 'USER_MENTION',
}

export const LIST_MENTIONABLE_USERS = `
  query ListMentionableUsers($filter: ListMentionableUsersFilter!, $page: PageRequest, $orderBy: OrderByInput) {
    account {
      listMentionableUsers(filter: $filter, page: $page, orderBy: $orderBy) {
        count
        limit
        offset
        items {
          id
          name {
            first
            last
          }
          email
          avatar {
            id
            extension
          }
        }
      }
    }
  }
`;
