import axios, { AxiosInstance } from 'axios';
import { EnvConfig } from '../envConfig';

export class Graphback {
  private static instance: AxiosInstance | null;

  public static initialize(accessToken: string): void {
    if (this.instance) return;

    this.instance = axios.create({
      baseURL: EnvConfig.graphqlUrl,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  public static dispose(): void {
    this.instance = null;
  }

  //return authenticated instance, if available, otherwise return unauthenticated instance
  public static getInstance(): AxiosInstance {
    return this.instance ? this.instance : this.getDefaultAxios();
  }

  private static getDefaultAxios(): AxiosInstance {
    axios.defaults.baseURL = EnvConfig.graphqlUrl;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    return axios;
  }
}
