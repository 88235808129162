import { AssetFilter, DropDownView, FilterType } from '@gripp/shared-ui';
import { View } from 'react-native';

type AssetGroupsFilterMenuProps = {
  onFilterOpen: () => void;
  onFilterClose: () => void;
  isOpened: boolean;
  anchorRef: React.RefObject<View>;
  type: FilterType;
};

export const AssetGroupsFilterMenu = ({
  onFilterClose,
  onFilterOpen,
  isOpened,
  anchorRef,
  type,
}: AssetGroupsFilterMenuProps) => {
  return (
    <DropDownView
      anchorRef={anchorRef}
      isVisible={isOpened}
      onDismiss={onFilterClose}
    >
      <AssetFilter closeFilter={onFilterClose} type={type} />
    </DropDownView>
  );
};
