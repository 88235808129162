import { useFindByQuery } from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { BulkUpdateFileUploader } from './bulkUpdateFileUploader';

export const AssetAdmin = ({ workspaceId, workspaceName }) => {
  const [rows, setRows] = useState(undefined);
  const { data } = useFindByQuery({
    query: GET_ASSETS,
    modelName: 'asset',
    variables: { workspaceId: workspaceId },
  });

  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Description', key: 'description' },
    { label: 'Make', key: 'make' },
    { label: 'Model', key: 'model' },
  ];

  useEffect(() => {
    if (!data) return;

    setRows(data.items);
  }, [data]);

  return (
    <BulkUpdateFileUploader
      title="Assets"
      headers={headers}
      rows={rows}
      workspaceId={workspaceId}
      workspaceName={workspaceName}
    />
  );
};

const GET_ASSETS = `
  query FindAssets($workspaceId: ID!) {
    findAssets(filter: {
      workspaces: {
        eq: $workspaceId
      }
    }) {
      items {
        id
        name
        description
        make
        model
      }
    }
  }
`;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    margin: 20,
  },
  link: {
    textDecorationLine: 'none',
  },
  text: {
    padding: 20,
  },
});
