import { TextQuestionConfig, TextResponseState } from '@gripp/shared-logic';
import { RoutineQuestionModule } from '../types';
import { TextQuestionAdminForm } from './textQuestionAdminForm';
import { TextQuestionExecutionForm } from './textQuestionExecutionForm';
import { TextQuestionMessage } from './textQuestionMessage';

export const TextQuestionModule: RoutineQuestionModule<
  TextQuestionConfig,
  TextResponseState
> = {
  adminForm: TextQuestionAdminForm,
  executionForm: TextQuestionExecutionForm,
  message: TextQuestionMessage,
};
