import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { Text } from 'react-native-paper';
import { Spacing } from '../../styles';
import { Colors } from '../../themes/colors';
import { CustomAlert } from '../customAlert';

/**
 * Props for the ProfileOptions component.
 */
export type ProfileOptionsProps = {
  buttonStyle?: StyleProp<ViewStyle>;
  onNavigate: (path: string) => void;
  onLogout?: () => void;
  hasNavigationArrow?: boolean;
  hasDelete?: boolean;
  hasDebug?: boolean;
};

export const ProfileOptions = (props: ProfileOptionsProps) => {
  const [confirmSignOutVisible, setConfirmSignOutVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <View>
      <View style={styles.action}>
        <Pressable
          style={props.buttonStyle || styles.button}
          onPress={() => props.onNavigate('profile/preferences')}
        >
          <View style={styles.notification}>
            <MaterialCommunityIcons
              name="cog-outline"
              size={24}
              color={Colors.black}
            />
            <Text style={styles.buttonText}>{t('preferences.menu.title')}</Text>
          </View>
          {props.hasNavigationArrow && (
            <MaterialCommunityIcons
              size={24}
              color={Colors.black}
              name="chevron-right"
            />
          )}
        </Pressable>
      </View>
      {/* <View style={styles.action}>
        <Pressable
          style={props.buttonStyle || styles.button}
          onPress={() => props.onNavigate('/profile/resetPassword')}
        >
          <Text style={styles.buttonText}>{t('resetPassword')}</Text>
           {props.hasNavigationArrow && (
            <MaterialCommunityIcons
              size={24}
              color={Colors.black}
              name="chevron-right"
            />
          )}
        </Pressable>
      </View> */}
      <View style={styles.action}>
        <Pressable
          style={props.buttonStyle || styles.button}
          onPress={() => setConfirmSignOutVisible(true)}
        >
          <Text style={[styles.buttonText, { color: Colors.red }]}>
            {t('signOut')}
          </Text>
        </Pressable>
      </View>
      {props.hasDelete && (
        <View style={styles.action}>
          <Pressable
            style={props.buttonStyle || styles.button}
            onPress={() => props.onNavigate('profile/delete')}
          >
            <Text style={[styles.buttonText, { color: Colors.red }]}>
              {t('deleteAccount.button')}
            </Text>
          </Pressable>
        </View>
      )}
      {props.hasDebug && (
        <View style={styles.action}>
          <Pressable onPress={() => props.onNavigate('debug')}>
            <Text style={{ color: Colors.grayDisabled }}>Debug</Text>
          </Pressable>
        </View>
      )}
      <CustomAlert
        visible={confirmSignOutVisible}
        hideAlert={() => setConfirmSignOutVisible(false)}
        action={props.onLogout}
        title={t('topNav.customAlert.signOut')}
        primaryButtonLabel={t('topNav.customAlert.yes')}
        secondaryButtonLabel={t('topNav.customAlert.cancel')}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  action: {
    marginTop: Spacing.baseMargin.base * 1.5,
  },
  container: {
    borderTopColor: Colors.grayDivider,
    borderTop: 1,
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 16,
    color: Colors.black,
  },
  notification: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
});
