import { Phone, useSendAuthCode, useWorkspace } from '@gripp/shared-logic';
import {
  BaseTheme,
  Colors,
  EditProfileForm,
  InvitationAccepted,
  InvitationIntro,
  SaveFormTop,
  Spacing,
  TopModalBar,
  useAcceptInvitationForm,
} from '@gripp/shared-ui';
import { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Dimensions, Modal, StyleSheet, View } from 'react-native';
import { PaperProvider } from 'react-native-paper';
import { useNavigate, useParams } from 'react-router-dom';

export default function AcceptInvitation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { invitationId, workspaceId } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [visibleScreen, setVisibleScreen] = useState('intro');
  const { showBoundary } = useErrorBoundary();
  const workspace = useWorkspace(workspaceId as string);
  const { sendAuthCodeAsync } = useSendAuthCode();

  useEffect(() => {
    if (!workspace) return;
    setTimeout(() => {
      setModalVisible(true);
    }, 2000);
  }, [workspace]);

  const onAccept = async (
    success: boolean,
    phone: Phone | undefined,
    email: string
  ) => {
    const input = {
      phone,
      email,
    };

    await sendAuthCodeAsync(input, () => navigate('/verify-auth-code'));
  };

  const { control, handleSubmit, formState } = useAcceptInvitationForm({
    invitationId: invitationId as string,
    onAccept,
  });

  return (
    <PaperProvider theme={BaseTheme}>
      {visibleScreen === 'intro' && (
        <InvitationIntro workspace={workspace} t={t} />
      )}
      {visibleScreen === 'accepted' && <InvitationAccepted t={t} />}
      <Modal visible={modalVisible}>
        <View style={styles.modalContainer}>
          <View style={styles.contentContainer}>
            <SaveFormTop
              title={t('invitation.pending.title')}
              saveLabel={t('invitation.pending.join')}
              onSubmit={handleSubmit}
              hasBorder={true}
              onCancel={() => navigate('/login')}
              saveButtonType="button"
              topBar={TopModalBar}
              isDirty={formState.isDirty}
              isValid={formState.isValid}
            >
              <View style={styles.formContainer}>
                <EditProfileForm
                  control={control}
                  inputMaxWidth={520}
                  autoFocus
                />
              </View>
            </SaveFormTop>
          </View>
        </View>
      </Modal>
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: Dimensions.get('window').height / 10,
    backgroundColor: Colors.primaryGrayHeader,
  },
  contentContainer: {
    backgroundColor: Colors.primaryGrayBackground,
    width: 560,
    borderRadius: 12,
  },
  formContainer: {
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    height: '100%',
    width: '100%',
  },
});
