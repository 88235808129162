export const FIND_GROUP_ASSIGNMENTS = `
  query FindGroupAssignments($groupId: String!) {
    group {
      findGroupAssignments(groupId: $groupId) {
        assets
        accounts
      }
    }
  }
`;
