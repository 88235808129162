import { useEffect, useState } from 'react';
import { OrderByInput, useCustomQuery } from '../graphql';
import { ColumnDef } from '@tanstack/react-table';

export type UseDataGridExportProps = {
  query: any;
  filter: any;
  orderBy: OrderByInput;
  dataPrefix: string;
  columns: ColumnDef<any, any>[];
  download: boolean;
  onDataReady: () => void;
};

type ExportHeader = {
  key: string;
  label: string;
};

export const useExportAssets = (props: UseDataGridExportProps) => {
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState<ExportHeader[]>([]);

  useEffect(() => {
    setHeaders(
      props.columns
        .filter((column) => column.accessorKey !== 'selected')
        .map((column) => ({
          key: column?.accessorKey,
          label: column.header(),
        }))
    );
  }, [props.columns]);

  const { data: exportData, isLoading } = useCustomQuery({
    query: props.query,
    cacheKey: [],
    variables: {
      filter: props.filter,
      orderBy: props.orderBy,
      page: { limit: 10000, offset: 0 },
    },
    options: {
      enabled: props.download,
      gcTime: 0,
      staleTime: 0,
    },
  });

  useEffect(() => {
    if (!exportData || !props.download) return;

    const exportRows = props.dataPrefix
      .split('.')
      .reduce((acc, part) => acc && acc[part], exportData)
      .items.map((item: any) => {
        const row = {};

        headers.forEach((header) => {
          const exporter = props.columns.find(
            (col) => col.accessorKey === header.key
          )?.meta?.exportCell;

          row[header.key] = exporter ? exporter(item) : item[header.key];
        });

        return row;
      });

    setRows(exportRows);
    props.onDataReady();
  }, [exportData, props.download]);

  return {
    headers,
    rows,
    isLoading,
  };
};
