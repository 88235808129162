import { PermissionValues as Permissions } from '@gripp/shared-logic';
import { AccountPage } from './components/accountPage';

export const accountPageRoutes = [
  {
    path: 'account-page',
    element: <AccountPage />,
    handle: {
      header: {
        titleKey: 'accountPage.menu.accountPage',
        icon: 'account-multiple',
      },
      requiredPermission: Permissions.account.allWorkspace,
    },
  },
];
