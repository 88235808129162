import { FilterModel } from '../../filter/domain/filterModel';
import { DateTime } from 'luxon';
import { DATE_FORMAT_MMDDYYYYLUXON } from '../../utils';

export enum ActivityOrderBy {
  MostRecentReported = 'MOST_RECENT_REPORTED',
  MostRecentUpdated = 'MOST_RECENT_UPDATED',
}

export enum ActivityFilterKey {
  Activity = 'ACTIVITY_FILTER',
  AssetActivity = 'ASSET_ACTIVITY_FILTER',
}

export enum DateTypeFilter {
  ALL = 'all',
  TODAY = 'today',
  THISWEEK = 'thisWeek',
  THISMONTH = 'thisMonth',
  CUSTOMDATE = 'customDate',
}

export class ActivityFilterModel implements FilterModel {
  orderBy?: ActivityOrderBy;
  severity?: string[];
  assetName?: string;
  groupIds?: string[];
  keyword?: string;
  dateType?: DateTypeFilter;
  startDate?: string;
  endDate?: string;

  constructor(data?: any) {
    this.orderBy = data?.orderBy ?? ActivityOrderBy.MostRecentUpdated;
    this.severity = data?.severity;
    this.assetName = data?.assetName;
    this.groupIds = data?.groupIds;
    this.keyword = data?.keyword;
    this.dateType = data?.dateType ?? DateTypeFilter.ALL;
    this.startDate = data?.startDate ?? '';
    this.endDate = data?.endDate ?? '';
  }

  public getFilter(): any {
    const filter: any = {};

    if (this.severity && this.severity.length > 0) {
      filter.severity = this.severity;
    }

    if (this.groupIds && this.groupIds.length > 0) {
      filter.groupIds = this.groupIds;
    }

    if (this.keyword) {
      filter.keyword = this.keyword;
    }

    const dateRange = this.getDateRangeByType();
    if (dateRange?.start && dateRange?.end) {
      filter.startDate = dateRange?.start;
      filter.endDate = dateRange?.end;
    }

    return filter;
  }

  public getTypesCount(): number {
    let count = 0;

    if (this.severity) {
      count += this.severity.length;
    }

    return count;
  }

  public getGroupsCount(): number {
    let count = 0;

    if (this.groupIds) {
      count += this.groupIds.length;
    }

    return count;
  }

  public scrubWorkspaceData() {
    this.groupIds = undefined;
  }

  public isDateFilterActive() {
    return this.dateType !== DateTypeFilter.ALL;
  }

  private getDateRangeByType() {
    const now = DateTime.now();

    const dateRanges: Record<
      DateTypeFilter,
      () => { start: DateTime; end: DateTime } | undefined
    > = {
      [DateTypeFilter.TODAY]: () => {
        const start = now.startOf('day').toUTC();
        const end = now.endOf('day').toUTC();
        return { start, end };
      },
      [DateTypeFilter.THISWEEK]: () => {
        const start = now.startOf('week').toUTC();
        const end = now.endOf('week').toUTC();
        return { start, end };
      },
      [DateTypeFilter.THISMONTH]: () => {
        const start = now.startOf('month').toUTC();
        const end = now.endOf('month').toUTC();
        return { start, end };
      },
      [DateTypeFilter.CUSTOMDATE]: () => {
        if (!this.startDate || !this.endDate) return undefined;

        const start = DateTime.fromFormat(
          this.startDate,
          DATE_FORMAT_MMDDYYYYLUXON
        );
        const end = DateTime.fromFormat(
          this.endDate,
          DATE_FORMAT_MMDDYYYYLUXON
        );

        if (!start.isValid || !end.isValid) {
          console.error('Invalid start or end date');
          return undefined;
        }

        const startUTC = start.startOf('day').toUTC();
        const endUTC = end.endOf('day').toUTC();

        return { start: startUTC, end: endUTC };
      },

      [DateTypeFilter.ALL]: () => undefined,
    };

    return dateRanges[this.dateType ?? DateTypeFilter.ALL]?.();
  }
}
