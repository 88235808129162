import {
  QuickConfirmQuestionConfig,
  QuickConfirmResponseState,
} from '@gripp/shared-logic';
import { RoutineQuestionModule } from '../types';
import { QuickConfirmQuestionAdminForm } from './quickConfirmQuestionAdminForm';
import { QuickConfirmQuestionMessage } from './quickConfirmQuestionMessage';
import { QuickConfirmQuestionExecutionForm } from './quickConfirmQuestionExecutionForm';

export const QuickConfirmQuestionModule: RoutineQuestionModule<
  QuickConfirmQuestionConfig,
  QuickConfirmResponseState
> = {
  adminForm: QuickConfirmQuestionAdminForm,
  executionForm: QuickConfirmQuestionExecutionForm,
  message: QuickConfirmQuestionMessage,
};
