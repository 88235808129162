import { cleanNumber, isValidNumber } from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { TextInput } from 'react-native-paper';
import { Colors } from '../../../themes';
import { PillToggle, StyledTextInput } from '../../forms';
import { CounterExecutionComponentProps } from './counterExecutionComponent';
import { CounterSummary } from './counterSummary';

export const TallyCounterExecutionComponent = (
  props: CounterExecutionComponentProps
) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState<string>('');
  const [addOrRemove, setAddOrRemove] = useState(-1);

  const addRemoveOptions = [
    { value: -1, label: t('routines.counter.user.tallyCounter.remove') },
    { value: 1, label: t('routines.counter.user.tallyCounter.add') },
  ];

  useEffect(() => {
    if (!inputValue) {
      props.onChange(props.initialValue);
    }
  }, [props.value]);

  useEffect(() => {
    change(inputValue);
  }, [addOrRemove]);

  const change = (input: string) => {
    input = cleanNumber(input, props.decimalPlaces, false);
    setInputValue(input);

    if (isValidNumber(input)) {
      props.onChange(props.initialValue + addOrRemove * parseFloat(input));
    } else {
      props.onChange(NaN);
    }
  };

  const initialValueLabel = t('routines.counter.user.tallyCounter.oldValue');

  const getDeltaValueLabel = () =>
    addOrRemove === 1
      ? t('routines.counter.user.tallyCounter.adding')
      : t('routines.counter.user.tallyCounter.removing');

  const getDeltaValue = () => {
    if (!inputValue) {
      return '--';
    }

    return addOrRemove === 1 ? inputValue : `-${inputValue}`;
  };

  const getNewValueLabel = () =>
    t('routines.counter.user.tallyCounter.newValue');

  const getNewValue = () => {
    return String(parseFloat((props.value || 0).toFixed(props.decimalPlaces)));
  };

  return (
    <View
      style={props.containerStyle ? props.containerStyle : styles.container}
    >
      {props.instructions ? (
        <Text style={styles.sectionTitle}>{props.instructions}</Text>
      ) : null}
      <View
        style={[styles.pillToggleContainer, props?.pillToggleContainerStyle]}
      >
        <PillToggle
          options={addRemoveOptions}
          value={addOrRemove}
          setValue={setAddOrRemove}
        />
      </View>
      <StyledTextInput
        value={inputValue}
        autoFocus={props.autoFocus}
        onFocus={props.onFocus}
        innerRef={props.inputRef}
        onBlur={props.onBlur}
        onChangeText={change}
        placeholder={t('routines.counter.user.tallyCounter.placeholder')}
        inputMode={props.decimalPlaces ? 'decimal' : 'numeric'}
        disableInnerStyle={true}
        outlineStyle={styles.inputStyle}
        right={
          <TextInput.Icon size={20} icon="close" onPress={() => change('')} />
        }
      />
      <CounterSummary
        initialValueLabel={initialValueLabel}
        initialValue={props.initialValue}
        getDeltaValueLabel={getDeltaValueLabel}
        getDeltaValue={getDeltaValue}
        getNewValueLabel={getNewValueLabel}
        getNewValue={getNewValue}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderRadius: 12,
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: Colors.blackText,
    letterSpacing: -0.25,
  },
  pillToggleContainer: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputStyle: {
    backgroundColor: Colors.white,
    borderColor: Colors.grayDivider,
    borderWidth: 0.5,
    borderRadius: 12,
  },
});
