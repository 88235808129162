import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useDeleteMutation, useUpdateMutation } from '@gripp/shared-logic';
import { Colors, CustomAlert } from '@gripp/shared-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import AlertMenu from './alertMenu';

export interface AlertCardData {
  id: string;
  name: string;
  disabled: boolean;
  workspaceAsset: {
    asset: {
      name: string;
    };
  };
  group: {
    name: string;
  };
  workspace: {
    name: string;
  };
}

export type AlertCardProps = {
  alert: AlertCardData;
  alertMenuSelectedId?: string;
  alertMenuHoverId?: string;
};

export const AlertCard = (props: AlertCardProps) => {
  const { alert } = props;
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const { t } = useTranslation();

  const { mutateAsync: updateWorkflow } = useUpdateMutation({
    modelName: 'workflow',
    query: UPDATE_WORKFLOW,
  });
  const { mutateAsync: deleteWorkflow } = useDeleteMutation({
    modelName: 'workflow',
    query: DELETE_WORKFLOW,
  });

  const toggleAlert = async () => {
    const input = {
      id: alert.id,
      disabled: !alert.disabled,
    };

    await updateWorkflow({ input });
  };

  const deleteAlert = async () => {
    const input = {
      id: alert.id,
    };

    await deleteWorkflow({ input });
  };

  const AlertStatus = (props: { disabled: boolean }) => {
    return (
      <View
        style={[
          styles.statusCircle,
          { backgroundColor: alert.disabled ? Colors.red : Colors.green },
        ]}
      />
    );
  };

  const AlertSubHeader = (props: { alert: AlertCardData }) => {
    return alert.workspaceAsset || alert.group ? (
      <View style={[styles.row, styles.subHeaderContainer]}>
        <MaterialCommunityIcons
          name={alert.workspaceAsset ? 'qrcode' : 'google-circles-extended'}
          color={Colors.black}
          size={16}
        />
        <Text variant="bodyMedium" style={styles.detailText}>
          {alert.workspaceAsset
            ? alert.workspaceAsset.asset.name
            : alert.group.name}
        </Text>
      </View>
    ) : null;
  };

  return (
    <View style={styles.card}>
      <View style={styles.content}>
        <View style={styles.row}>
          <AlertStatus disabled={alert.disabled} />
          <Text style={styles.title} numberOfLines={1}>
            {alert.name}
          </Text>
        </View>
        <View style={styles.row}>
          <AlertSubHeader alert={alert} />
        </View>
      </View>
      {(props.alertMenuSelectedId === alert.id ||
        props.alertMenuHoverId === alert.id) && (
        <AlertMenu
          toggleAlert={toggleAlert}
          deleteAlert={async () => setConfirmDeleteVisible(true)}
          isDisabled={alert.disabled}
        />
      )}
      <CustomAlert
        visible={confirmDeleteVisible}
        title={t('alerts.menu.confirmDelete.title', { alertName: alert.name })}
        primaryButtonLabel={t('alerts.menu.confirmDelete.primaryButton')}
        secondaryButtonLabel={t('alerts.menu.confirmDelete.secondaryButton')}
        hideAlert={() => setConfirmDeleteVisible(false)}
        action={deleteAlert}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    borderRadius: 0,
    width: '100%',
    height: 100,
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 20,
    paddingLeft: 20,
  },
  content: {
    flexDirection: 'column',
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
  },
  subHeaderContainer: {
    backgroundColor: '#E9E1DC',
    padding: 5,
    paddingVertical: 4,
    borderRadius: 4,
  },
  title: {
    fontSize: 16,
    color: Colors.black,
    fontWeight: '700',
    lineHeight: 16,
  },
  detailText: {
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 14,
    bottom: 1,
    paddingLeft: 4,
    color: Colors.grayText,
  },
  statusCircle: {
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: Colors.green,
    marginRight: 8,
  },
});

const UPDATE_WORKFLOW = `
  mutation UpdateWorkflow($input: MutateWorkflowInput!) {
    updateWorkflow(input: $input) {
      id
      name
      disabled
    }
  }
`;

const DELETE_WORKFLOW = `
  mutation DeleteWorkflow($input: MutateWorkflowInput!) {
    deleteWorkflow(input: $input) {
      id
    }
  }
`;
