import { WorkspaceAsset } from '../../assets';
import { Group } from '../../group';
import { Workspace } from '../../workspace';
import { Action } from './actions';
import { Trigger } from './triggers';

export interface Workflow {
  id: string;
  name: string;
  workspace: Workspace;
  workspaceAsset?: WorkspaceAsset;
  group?: Group;
  trigger: Trigger;
  action: Action;
  disabled?: boolean;
  disabledBetween?: DateRange;
}

interface DateRange {
  start?: Date;
  end?: Date;
}
