import { useCustomMutation } from '../../graphql';
import { useGrippContext } from '../../grippContext';
import { useStorageKeys } from '../../storage/useStorageKeys';

export const useSendAuthCode = () => {
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();

  const { mutateAsync, isPending } = useCustomMutation({
    query: sendAuthCode,
  });

  return {
    sendAuthCodeAsync: async (input: any, onSettled: () => void) => {
      await storageHandler.setItem(
        storageKeys.authCodeInput,
        JSON.stringify(input)
      );

      await mutateAsync(
        { input },
        {
          onSettled: () => {
            onSettled();
          },
        }
      );
    },
    isPending,
  };
};

const sendAuthCode = `
  mutation Mutation($input: AuthCodeInput!) {
    authentication {
      sendAuthCode(input: $input) {
        success
      }
    }
  }
`;
