import {
  RoutineConfig,
  RoutineType,
  useCreateMutation,
  useCurrentWorkspace,
  useCustomQuery,
} from '@gripp/shared-logic';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { routinesPaths } from '../routinesPaths';
import { RoutineEditForm } from './routineEditForm';

export const NewRoutine = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { type } = useParams();
  const newConfig: RoutineConfig = {
    title: '',
    routineButtonText: '',
    issuePrefix: '',
  };
  const [routineCount, setRoutineCount] = useState<number>(0);
  const workspace = useCurrentWorkspace();
  const { data: countData } = useCustomQuery({
    cacheKey: ['workspaceRoutineCount'],
    query: GetWorkspaceRoutineCount,
    variables: {
      filter: {
        workspace: { eq: workspace?.id },
      },
    },
    options: {
      enabled: !!workspace,
      staleTime: 0,
      gcTime: 0,
    },
  });
  const { mutateAsync: createRoutine } = useCreateMutation({
    modelName: 'WorkspaceRoutine',
    query: CreateWorkspaceRoutine,
  });

  useEffect(() => {
    if (countData) {
      setRoutineCount(countData.findWorkspaceRoutines.count);
    }
  }, [countData]);

  const onSubmit = async (data: RoutineConfig) => {
    cleanConfig(data);
    const input = {
      name: data.title,
      workspace: { id: workspace.id },
      order: routineCount + 1,
      config: data,
      type: type as RoutineType,
    };

    await createRoutine(
      { input },
      {
        onSettled: () => {
          navigate(`/${routinesPaths.root}`);
        },
      }
    );
  };

  const cleanConfig = useCallback((config: any) => {
    Object.keys(config).forEach((key) => {
      if (config[key] === '') {
        delete config[key];
      }
    });
  }, []);

  return (
    <RoutineEditForm
      config={newConfig}
      type={type as RoutineType}
      onSubmit={onSubmit}
      saveButtonText={t('routines.admin.create')}
    />
  );
};

const GetWorkspaceRoutineCount = `
  query GetWorkspaceRoutineCount($filter: WorkspaceRoutineFilter) {
    findWorkspaceRoutines(filter: $filter) {
      count
    }
  }
`;

const CreateWorkspaceRoutine = `
  mutation CreateWorkspaceRoutine($input: CreateWorkspaceRoutineInput!) {
    createWorkspaceRoutine(input: $input) {
      id
    }
  }
`;
