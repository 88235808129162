import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '../../shared/hooks/useWindowDimensions';
import { RoutineFold } from './routineFold';

export const Routines = () => {
  const navigate = useNavigate();
  const windowDimensions = useWindowDimensions();
  const maxHeight = (windowDimensions?.height ?? 0) - 185; // substract banner + padding height

  const onRoutineSelected = (routineId: string) => {
    navigate(routineId);
  };

  return (
    <RoutineFold maxHeight={maxHeight} onRoutineSelected={onRoutineSelected} />
  );
};
