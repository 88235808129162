import { Workspace } from '@gripp/shared-logic';
import { Colors } from '@gripp/shared-ui';
import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { StyleSheet } from 'react-native';
import { IconButton, Tooltip } from 'react-native-paper';
import { useExportHook } from './types';

type ExportButtonProps = {
  icon: string;
  toolTip: string;
  useExport: useExportHook;
  workspace?: Workspace;
  fileName: string;
};

export const ExportButton = (props: ExportButtonProps) => {
  const csvRef = useRef();
  const [download, toggleDownload] = useState(false);

  const onDataReady = () => {
    if (download) doDownload();
  };

  const { headers, rows, isLoading } = props.useExport({
    download: download,
    workspace: props.workspace,
    onDataReady: onDataReady,
  });

  const doDownload = () => {
    setTimeout(() => {
      csvRef.current.link.click();
      toggleDownload(false);
    }, 50);
  };

  const onDownload = () => {
    if (rows?.length > 0) {
      // user already clicked on icon 1x, so just download it
      doDownload();
    } else {
      toggleDownload(true);
    }
  };

  return (
    <>
      <Tooltip title={props.toolTip} leaveTouchDelay={500}>
        <IconButton
          icon={props.icon}
          iconColor={isLoading ? Colors.grayDisabled : Colors.primary}
          onPress={onDownload}
          disabled={isLoading}
          style={styles.icon}
        />
      </Tooltip>
      <CSVLink
        data={rows}
        headers={headers}
        filename={
          props.workspace
            ? `${props.workspace.name}-${props.fileName}.csv`
            : `${props.fileName}.csv`
        }
        ref={csvRef}
      />
    </>
  );
};

const styles = StyleSheet.create({
  icon: {
    margin: 0,
  },
});
