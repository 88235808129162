import { useEffect, useMemo, useState } from 'react';
import { Pressable, StyleProp, ViewStyle } from 'react-native';
import { ActivityIndicator, Avatar } from 'react-native-paper';
import { useOpenedState } from '../hooks/useOpenedState';
import { Colors } from '../themes/colors';
import { ImageLightBox } from './modalImages/imageLightbox';

export type AccountAvatarProps = {
  avatar?: any;
  avatarIcon?: string;
  mediaUrl: string;
  directAvatarUrl?: string;
  size: number;
  isProcessing?: boolean;
  style?: StyleProp<ViewStyle>;
  iconColor?: string;
  hasLightbox?: boolean;
};

export const AccountAvatar = ({
  avatar,
  avatarIcon,
  mediaUrl,
  directAvatarUrl,
  size,
  style = {},
  iconColor,
  hasLightbox,
}: AccountAvatarProps) => {
  const [imageUrl, setImageUrl] = useState(directAvatarUrl ?? '');
  const [lightboxImageUrl, setLightboxImageUrl] = useState(
    directAvatarUrl ?? ''
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const { isOpened, onOpen, onClose } = useOpenedState();

  //todo refactor this
  useEffect(() => {
    if (!avatar) {
      setImageUrl('');
    } else if (avatar.status === 'PENDING') {
      setIsProcessing(true);
    } else {
      setIsProcessing(false);
      if (!directAvatarUrl) {
        setImageUrl(`${mediaUrl}/images/${avatar.id}/xs.${avatar.extension}`);
        setLightboxImageUrl(
          `${mediaUrl}/images/${avatar.id}/original.${avatar.extension}`
        );
      }
    }
    if (directAvatarUrl) {
      setImageUrl(directAvatarUrl);
      setLightboxImageUrl(directAvatarUrl);
    }
  }, [avatar]);

  const renderAvatar = useMemo(() => {
    if (isProcessing) {
      return (
        <>
          <Avatar.Image
            source={{ uri: directAvatarUrl }}
            size={size}
            style={style}
          />
          <ActivityIndicator
            animating={true}
            color={Colors.primary}
            size={size}
            style={style}
          ></ActivityIndicator>
        </>
      );
    }
    if (!imageUrl) {
      if (avatarIcon) {
        return (
          <Avatar.Icon
            icon={avatarIcon}
            size={size}
            style={style}
            color={iconColor}
          />
        );
      } else {
        return <Avatar.Icon icon="account-circle" size={size} style={style} />;
      }
    }
    if (hasLightbox) {
      return (
        <Pressable onPress={onOpen}>
          <Avatar.Image source={{ uri: imageUrl }} size={size} style={style} />

          <ImageLightBox
            url={lightboxImageUrl}
            isOpened={isOpened}
            onClose={onClose}
          />
        </Pressable>
      );
    }
    return (
      <Avatar.Image source={{ uri: imageUrl }} size={size} style={style} />
    );
  }, [isProcessing, imageUrl, isOpened]);

  return renderAvatar;
};
