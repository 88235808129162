import { useQueryClient } from '@tanstack/react-query';
import { useUpdateMutation } from '../../graphql';
import { NotificationPreferenceFields } from '../notificationPreferenceFields';
import { PreferencesCacheKey } from './cacheKeys';

export const useUpdateAccountPreferences = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: updatePreferences, isPending: isLoading } =
    useUpdateMutation({
      modelName: 'account',
      query: UPDATE_PREFERENCES,
    });

  const clearCache = async () => {
    await queryClient.invalidateQueries({ queryKey: PreferencesCacheKey });
  };

  return {
    updatePreferences,
    clearCache,
    isUpdating: isLoading,
  };
};

const UPDATE_PREFERENCES = `
  mutation UPDATE_PREFERENCES($input: MutateAccountInput!) {
    updateAccount(input: $input) {
      preferences {
        ${NotificationPreferenceFields}
        preferredLanguage
      }
    }
  }
`;
