import { Colors } from 'libs/shared-ui/src/themes';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    padding: 20,
    marginBottom: 20,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 20,
  },
  scrollviewContainer: {
    flex: 1,
    marginBottom: 20,
  },
  questionContainer: {
    backgroundColor: Colors.white,
    paddingVertical: 24,
    paddingHorizontal: 16,
    marginBottom: 20,
    borderRadius: 8,
  },
  instructionsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: 24,
    marginBottom: 20,
  },
  instructionsText: {
    fontSize: 16,
    fontWeight: '700',
  },
});
