import { useAssetListFilter, useSetAssetListFilter } from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { TextInput } from 'react-native-paper';
import { Colors } from '../../themes';
import { DebouncedTextInput } from '../forms';

export const AssetSearchInput = () => {
  const { t } = useTranslation();
  const { data: assetFilter } = useAssetListFilter();
  const { mutateAsync } = useSetAssetListFilter();

  const [text, setText] = useState('');

  useEffect(() => {
    if (!text && assetFilter?.keyword) {
      setText(assetFilter.keyword);
    }
  }, [assetFilter]);

  const updateSearch = async (keyword: string) => {
    assetFilter.keyword = keyword;
    await mutateAsync(assetFilter);
  };

  return (
    assetFilter && (
      <DebouncedTextInput
        debounceTimeMs={500}
        debounceLength={3}
        debounceFunc={updateSearch}
        placeholder={t('assets.filter.searchPlaceholder')}
        value={text}
        onBlur={() => {}}
        onChangeText={(text) => setText(text)}
        left={<TextInput.Icon icon="magnify" color={Colors.black} size={24} />}
        right={
          text ? (
            <TextInput.Icon
              icon="close"
              size={20}
              color={Colors.black}
              onPress={() => setText('')}
            />
          ) : (
            <View />
          )
        }
        height={44}
        outlineStyle={styles.inputStyle}
        disableInnerStyle={true}
      />
    )
  );
};

const styles = StyleSheet.create({
  inputStyle: {
    backgroundColor: Colors.white,
    borderColor: Colors.grayDivider,
    borderWidth: 1,
    borderRadius: 8,
  },
});
