import {
  AssetRoutineState,
  CounterQuestionConfig,
  CounterResponseState,
  CounterState,
  RoutineState,
  WorkflowRoutineState,
} from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import { Colors } from '../../../../../themes/colors';
import { useFormContext } from '../../../../forms';
import { CounterExecutionComponent } from '../../../counter/counterExecutionComponent';
import {
  RoutineQuestionExecutionForm,
  RoutineQuestionExecutionFormProps,
} from '../types';

export const CounterQuestionExecutionForm: RoutineQuestionExecutionForm<
  CounterQuestionConfig,
  CounterResponseState
> = (
  props: RoutineQuestionExecutionFormProps<
    CounterQuestionConfig,
    CounterResponseState
  >
) => {
  const fieldPrefix = `responses.${props.responseIndex}`;
  const formKey = `${fieldPrefix}.count`;

  const { control, setValue } = useFormContext();
  const { field } = useController({
    control: control,
    name: formKey,
  });

  const [initialValue, setInitialValue] = useState(0);

  const getStateCount = (
    responseIndex: number,
    currentState: AssetRoutineState<RoutineState> | undefined
  ) => {
    const responses = (currentState?.state as WorkflowRoutineState)?.responses;

    if (responses && responseIndex < responses.length) {
      const response = responses[responseIndex] as unknown;
      return (response as CounterState)?.count;
    }

    return (currentState?.state as CounterState)?.count;
  };

  useEffect(() => {
    const count = getStateCount(props.responseIndex, props.currentState);

    if (count) {
      setInitialValue(count);
    }
  }, [props.currentState]);

  useEffect(() => {
    setValue(formKey, initialValue, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [initialValue]);

  return (
    <CounterExecutionComponent
      onBlur={field.onBlur}
      instructions={''}
      initialValue={initialValue}
      onChange={field.onChange}
      value={field.value}
      formKeyPrefix={fieldPrefix}
      autoFocus={false}
      decimalPlaces={
        props.config.decimal ? props.config.decimalPlaces : undefined
      }
      tally={props.config.tally || false}
      containerStyle={styles.container}
      inputContainerStyle={styles.inputContainerStyle}
      pillToggleContainerStyle={styles.pillToggleContainer}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderRadius: 12,
  },
  inputContainerStyle: {
    marginTop: 0,
  },
  pillToggleContainer: {
    paddingTop: 0,
    marginTop: -10,
  },
});
