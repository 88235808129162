/* eslint-disable @typescript-eslint/no-explicit-any */
import { FIND_ASSIGNABLE_ASSETS } from '@gripp/shared-logic';
import { InfiniteDataGrid } from '@gripp/shared-ui';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAssetColumns } from './useAssetColumns';

export type EditGroupAssetsListProps = {
  workspace: any;
  groupId: string;
  maxHeight?: number;
  keyword?: string;
  assignments: string[];
  updateAssignments: (assignments: string[]) => void;
};

export const EditGroupAssetsList = ({
  workspace,
  groupId,
  maxHeight,
  keyword,
  assignments,
  updateAssignments,
}: EditGroupAssetsListProps) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<any>(undefined);
  const columns = useAssetColumns() as ColumnDef<any, any>[];

  useEffect(() => {
    setFilter({
      keyword,
      groupId,
      assignments,
      workspaceId: workspace?.id,
    });
  }, [keyword, groupId, workspace]);

  return !filter ? null : (
    <InfiniteDataGrid
      modelName="asset"
      query={FIND_ASSIGNABLE_ASSETS}
      filter={filter}
      pageSize={50}
      columns={columns}
      listMaxHeight={maxHeight as number}
      paddingBottom={0}
      textEmpty={t('assets.textEmpty')}
      dataPrefix="group.findAssignableAssets"
      updateRowsSelected={(rows) => {
        updateAssignments(rows);
      }}
      isRowSelected={(row) => {
        return assignments.includes(row.id);
      }}
      enableScrollRefresh={true}
      showScrollIndicator={true}
    />
  );
};
