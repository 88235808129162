import { StyleProp, ViewStyle } from 'react-native';
import { MediaType } from '../graphql/enums';
import { MediaData } from './domain';
import { Media } from './domain/media';
import { ReactNode } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

export type ImageUploadArgs = {
  onStart?: (image: Image) => void;
  onProgress?: (id: string, progress: number) => void;
  onComplete?: (image: Image) => void;
};

export type MediaUploadArgs = {
  onStart?: (mediaItem: MediaItem) => void;
  onProgress?: (mediaId: string, progress: number) => void;
  onComplete?: (mediaId: string) => void;
};

export type Image = {
  id: string;
  url: string;
  extension?: string;
  imageData?: string;
};

export type Dimensions = {
  width: number | null;
  height: number | null;
};

export interface MediaItem extends Media {
  name: string;
  url: string;
}

export type MediaPickerProps = {
  setMedia: (mediaData: MediaData) => void;
  setProgress: (mediaId: string, progress: number) => void;
  setUploading?: (uploading: boolean) => void;
  acceptedMediaTypes?: MediaType[];
  notifyCameraVisible?: (cameraVisible: boolean) => void;
  dismissKeyboard?: () => void;
  mode?: PickerMode;
  style?: StyleProp<ViewStyle>;
  iconColor?: string;
  workspaceId?: string;
  icon?: string;
  iconSize?: number;
  cardStyle?: 'elevated' | 'outlined' | 'contained';
  isIconInBox?: boolean;
};

export type MediaPickerComponent = (props: MediaPickerProps) => ReactNode;

export enum PickerMode {
  CAMERA,
  PICKER,
  ATTACHMENT,
}

export type SignatureScreenProps = {
  setValue: UseFormSetValue<FieldValues>;
  fieldName: string;
  toggleProcessing?: (processing: boolean) => void;
};

export type SignatureScreenComponent = (
  props: SignatureScreenProps
) => ReactNode;
