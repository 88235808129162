import { useImpersonateWorkspace, Workspace } from '@gripp/shared-logic';
import { StyleProp, StyleSheet } from 'react-native';
import { Chip } from 'react-native-paper';
import { Colors } from '../../themes';

type WorkspaceChipProps = {
  workspace: Workspace;
  additionalStyle?: StyleProp<any>;
};

export const ImpersonateWorkspaceChip = (props: WorkspaceChipProps) => {
  const { impersonateWorkspace } = useImpersonateWorkspace();

  const chipStyle = props.additionalStyle
    ? [styles.chip, props.additionalStyle]
    : [styles.chip];

  return (
    <Chip
      onPress={async () => await impersonateWorkspace(props.workspace)}
      textStyle={styles.chipText}
      style={chipStyle}
      ellipsizeMode="tail"
    >
      {props.workspace?.name}
    </Chip>
  );
};

const styles = StyleSheet.create({
  chip: {
    backgroundColor: Colors.primaryGrayBackground,
  },
  chipText: {
    lineHeight: 14,
    fontWeight: '400',
    color: Colors.black,
    paddingVertical: 1,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 5,
    marginBottom: 5,
  },
});
