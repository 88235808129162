import {
  IssueType,
  Media,
  MediaPickerComponent,
  MediaType,
} from '@gripp/shared-logic';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { mixed, string, object as yupObject } from 'yup';
import { useAutoFocusRef } from '../../hooks';
import { Spacing } from '../../styles';
import { Colors } from '../../themes';
import { ControlledSelectButtons, ControlledTextInput } from '../forms';
import { KeyboardSafeView } from '../keyboard/keyboardSafeView';
import { MediaGalleryComponent } from '../media';

export const NewIssueReportFormSchema = yupObject({
  message: string().required(),
  title: string().optional(),
  type: mixed<string>().oneOf(Object.values(IssueType)).required(),
});

export type NewIssueReportFormProps = {
  workspaceId?: string;
  mediaGallery: MediaGalleryComponent;
  mediaPicker: MediaPickerComponent;
  acceptedMediaTypes?: MediaType[];
  control: Control<any>;
  addMedia: (media: Media) => void;
  setIsUploading?: (isUploading: boolean) => void;
};

export const NewIssueReportForm = (props: NewIssueReportFormProps) => {
  const { t } = useTranslation();
  const MediaPicker = props.mediaPicker;
  const MediaGallery = props.mediaGallery;

  const elementFocusCallBack = useAutoFocusRef();
  const buttonOptions = [
    {
      label: t('issue.common.report'),
      value: IssueType.Report,
      icon: {
        style: {
          backgroundColor: Colors.red,
        },
        name: 'flag',
      },
    },
    {
      label: t('issue.common.note'),
      value: IssueType.Note,
      icon: {
        style: {
          backgroundColor: Colors.purple,
        },
        name: 'note',
      },
    },
  ];

  return (
    <KeyboardSafeView style={styles.keyboardContainer}>
      <ScrollView
        contentContainerStyle={styles.formContainer}
        style={{ borderRadius: 12 }}
        keyboardShouldPersistTaps="handled"
      >
        <View style={styles.formItem}>
          <View>
            <Text style={styles.formItemTitle}>
              {t('issue.common.description')}
            </Text>
          </View>
          <ControlledTextInput
            textArea={true}
            control={props.control}
            name="message"
            placeholder={t('issue.new.placeholder')}
            innerRef={elementFocusCallBack}
            disableInnerStyle={false}
            outlinedTextInput
          />
        </View>
        <View style={styles.formItem}>
          <Text style={styles.formItemTitle}>
            {t('issue.common.addPicture')}
          </Text>
          <MediaGallery
            onMediaAdded={props.addMedia}
            mediaPicker={MediaPicker}
            onUploadingChanged={props.setIsUploading}
            workspaceId={props.workspaceId}
            acceptedMediaTypes={props.acceptedMediaTypes}
          />
        </View>
        <View style={styles.formItem}>
          <View>
            <Text style={styles.formItemTitle}>
              {t('issue.common.followUp')}
            </Text>
          </View>
          <ControlledSelectButtons
            control={props.control}
            name="type"
            options={buttonOptions}
          />
        </View>
        <View style={styles.formItem}>
          <View>
            <Text style={styles.formItemTitle}>{t('issue.new.title')}</Text>
          </View>
          <ControlledTextInput
            control={props.control}
            name="title"
            height={44}
            placeholder={t('issue.new.optionalPlaceholder')}
            disableInnerStyle={false}
            outlinedTextInput
          />
        </View>
      </ScrollView>
    </KeyboardSafeView>
  );
};

const styles = StyleSheet.create({
  keyboardContainer: {
    flex: 1,
  },
  formContainer: {
    gap: 8,
    ...Platform.select({
      web: {
        height: 500,
      },
    }),
  },
  formItemTitle: {
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 16,
    marginBottom: 16,
  },
  formItem: {
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    paddingVertical: Spacing.basePadding.paddingVertical,
    backgroundColor: Colors.white,
  },
});
