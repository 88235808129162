import { MediaType } from '../graphql/enums';

const imageExtensions = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'bmp',
  'webp',
  'tif',
  'tiff',
  'svg',
  'raw',
  'psd',
  'heif',
  'heic',
];
const videoExtensions = ['mp4', 'webm', 'ogg', 'mov', 'flv', 'avi', 'wmv'];
const documentExtensions = [
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'txt',
  'csv',
];

const acceptedImageFormats = ['image/*'];
const acceptedVideoFormats = videoExtensions.map((ext) => `video/.${ext}`);
const acceptedDocumentFormats = documentExtensions.map((ext) => `.${ext}`);

const contentTypeMap: { [key: string]: string } = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  bmp: 'image/bmp',
  webp: 'image/webp',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  svg: 'image/svg+xml',
  raw: 'image/x-raw',
  psd: 'image/vnd.adobe.photoshop',
  heif: 'image/heif',
  heic: 'image/heic',
  mp4: 'video/mp4',
  webm: 'video/webm',
  ogg: 'video/ogg',
  mov: 'video/mov',
  flv: 'video/flv',
  avi: 'video/avi',
  wmv: 'video/wmv',
  pdf: 'application/pdf',
  doc: 'application/vnd.msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  txt: 'text/plain',
  csv: 'text/csv',
};

export const getContentTypeByExt = (ext: string) => {
  const contentType = contentTypeMap[ext];
  if (!contentType) throw new Error('File type not supported');
  return contentType;
};

export const getMediaTypeByExt = (ext: string) => {
  if (imageExtensions.includes(ext)) return MediaType.Image;
  if (videoExtensions.includes(ext)) return MediaType.Video;
  if (documentExtensions.includes(ext)) return MediaType.Document;

  throw new Error('File type not supported');
};

export const getAcceptedFormatsForMediaTypes = (
  mediaTypes: MediaType[]
): string => {
  const acceptedFormats: string[] = [];
  mediaTypes.forEach((mediaType) => {
    switch (mediaType) {
      case MediaType.Image:
        acceptedFormats.push(...acceptedImageFormats);
        break;
      case MediaType.Video:
        acceptedFormats.push(...acceptedVideoFormats);
        break;
      case MediaType.Document:
        acceptedFormats.push(...acceptedDocumentFormats);
        break;
    }
  });
  return acceptedFormats.join(',');
};

export const getFileDataFromUrl = (url: string) => {
  const fileName = url.split('/').slice(-1)[0];
  const ext = fileName.split('.').slice(-1)[0];
  return {
    name: fileName,
    ext: ext,
  };
};

export const getUploadBlobNameForMediaType = (
  mediaName: string,
  mediaId: string,
  mediaType: MediaType
) => {
  switch (mediaType) {
    case MediaType.Image:
      return `images/${mediaId}/${mediaName}`;
    case MediaType.Document:
      return `documents/${mediaId}/${mediaName}`;
    default:
      throw new Error('Format not supported');
  }
};
