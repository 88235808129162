import { PickerMode } from '@gripp/shared-logic';
import { StyleSheet, View } from 'react-native';
import { Card } from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { ImageComponent } from './imageComponent';
import { ImageData, SingleImageInputProps } from './types';
import { useTypedMediaItem } from './useTypedMediaItem';

export const SingleImageInput = (props: SingleImageInputProps) => {
  const {
    typedMediaItem: image,
    handleSet,
    handleUploadProgress,
  } = useTypedMediaItem<ImageData>(props.image);

  const ImagePicker = props.imagePicker;

  return (
    <View style={styles.imageCardContainer}>
      {image && (
        <Card style={styles.imageCard}>
          <ImageComponent mediaItem={image} displayWidth={100} />
        </Card>
      )}

      <ImagePicker
        mode={PickerMode.CAMERA}
        icon={'camera-outline'}
        iconColor={image ? Colors.white : Colors.primaryDark}
        iconSize={image ? 16 : 30}
        setMedia={(media: ImageData) => handleSet(media, props.onImageAdded)}
        setProgress={handleUploadProgress}
        setUploading={props.onUploadingChanged}
        style={image ? styles.pickerOverlay : styles.picker}
        allowsEditing={props.allowsEditing}
        IsSquareCameraView={true}
      />
    </View>
  );
};
const styles = StyleSheet.create({
  imageCard: {
    height: 100,
    width: 100,
    borderRadius: 8,
    position: 'absolute',
  },
  imageCardContainer: {
    height: 101,
    width: 101,
    borderRadius: 8,
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    marginLeft: 2,
  },
  cardCover: {
    height: 100,
    width: 100,
    borderRadius: 8,
  },
  picker: {
    height: 100,
    width: 100,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pickerOverlay: {
    height: 20,
    width: 100,
    opacity: 0.75,
    backgroundColor: Colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderRadius: 0,
  },
});
