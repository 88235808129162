import { WorkspaceRoutine } from '@gripp/shared-logic';
import { Colors } from '@gripp/shared-ui';
import { StyleSheet, Text, View } from 'react-native';
import { RoutineMenu } from './routineMenu';

type RoutineHeaderProps = {
  workspaceRoutine: WorkspaceRoutine;
};

const RoutineHeader = (props: RoutineHeaderProps) => {
  const workspaceRoutine = props.workspaceRoutine;

  return (
    workspaceRoutine && (
      <View style={styles.container}>
        <Text style={styles.titleLabel}>{workspaceRoutine.config.title}</Text>
        <View>
          <RoutineMenu workspaceRoutine={workspaceRoutine} />
        </View>
        {/* TODO: Add Icon and description subheader */}
      </View>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    height: 165,
    paddingVertical: 24,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: Colors.graySpacer,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  titleLabel: {
    fontSize: 18,
    fontWeight: '700',
  },
});

export default RoutineHeader;
