import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Group } from '@gripp/shared-logic';
import { Colors, DropDownView, Spacing } from '@gripp/shared-ui';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { GroupsSelector } from './groupsSelector';

export type AddToGroupsProps = {
  entitySelectedLabel: string;
  entityAddToGroupLabel: string;
  entityIds: string[];
  workspaceId: string;
  onSubmit: (selectedGroups: Group[]) => void;
  isUpdating: boolean;
};

export const AddToGroups = (props: AddToGroupsProps) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const buttonRef = useRef<View>(null);

  const handleSubmit = (selectedGroups: Group[]) => {
    props.onSubmit(selectedGroups);
    handleSelectorClose();
  };

  const handleSelectorOpen = () => {
    setIsDropdownOpen(true);
  };
  const handleSelectorClose = () => {
    setIsDropdownOpen(false);
    setShowAddButton(true);
  };

  return (
    props.entityIds.length > 0 && (
      <View style={styles.container}>
        {showAddButton && (
          <>
            <Text style={styles.selectedLabel}>
              {props.entitySelectedLabel}
            </Text>
            <Pressable
              style={styles.addButton}
              onPress={handleSelectorOpen}
              ref={buttonRef}
            >
              <MaterialCommunityIcons
                name="plus-box-multiple-outline"
                color={Colors.primaryDark}
                size={16}
              />
              <Text style={styles.buttonLabel}>
                {t('groups.groupsSelector.addToGroup')}
              </Text>
            </Pressable>
          </>
        )}

        <DropDownView
          anchorRef={buttonRef}
          isVisible={isDropdownOpen}
          onDismiss={handleSelectorClose}
        >
          <View style={styles.groupsContainer}>
            <GroupsSelector
              headerLabel={props.entityAddToGroupLabel}
              workspaceId={props.workspaceId}
              onSubmit={handleSubmit}
              isUpdating={props.isUpdating}
            />
          </View>
        </DropDownView>
      </View>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 24,
  },
  selectedLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.primaryDark,
    alignSelf: 'center',
    marginRight: 8,
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.primaryDark,
  },
  addButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 12,
    width: 164,
    height: 32,
    borderWidth: 2,
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    borderColor: Colors.primaryDark,
  },
  groupsContainer: {
    flex: 1,
    paddingHorizontal: 20,
  },
});
