import { MutableRefObject, useLayoutEffect, useRef, useState } from 'react';
import { ScrollView } from 'react-native';

type ScrollXPosition = {
  hasUpdatedData: boolean;
  positionX: number;
};

export const useScrollXPosition = (
  isLoading: boolean,
  dataRows: never[],
  HScrollRef: MutableRefObject<ScrollView | null>
) => {
  const [scrollXPosition, setScrollXPosition] = useState<ScrollXPosition>({
    hasUpdatedData: false,
    positionX: 0,
  });
  const scrollXRef = useRef<ScrollXPosition>({
    hasUpdatedData: false,
    positionX: 0,
  });

  const handleHasUpdatedData = (hasUpdatedData: boolean) =>
    setScrollXPosition({ ...scrollXPosition, hasUpdatedData });
  const handlePositionX = (positionX: number) => {
    scrollXRef.current.positionX = positionX;
    setScrollXPosition((prev) => ({ ...prev, positionX }));
  };
  const handleResetScrollXPosition = () =>
    setScrollXPosition({ hasUpdatedData: false, positionX: 0 });

  useLayoutEffect(() => {
    if (
      !isLoading &&
      dataRows?.length &&
      scrollXRef.current.positionX > 0 &&
      HScrollRef?.current
    ) {
      setTimeout(() => {
        HScrollRef?.current?.scrollTo({
          x: Number(scrollXRef.current.positionX ?? 0),
          animated: false,
        });
        handleResetScrollXPosition();
      }, 300);
    }
  }, [isLoading, dataRows, HScrollRef]);

  return {
    onUpdateHasUpdatedData: handleHasUpdatedData,
    onUpdatePositionX: handlePositionX,
    onResetScrollXPosition: handleResetScrollXPosition,
  };
};
