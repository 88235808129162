export const FIND_ASSIGNABLE_ASSETS = `
  query FindAssignableAssets($filter: FindAssignableAssetsFilter!, $page: PageRequest) {
    group {
      findAssignableAssets(filter: $filter, page: $page) {
        count
        limit
        offset
        items {
          id
          name
          description
          groups {
            id
            name
          }
        }
      }
    }
  }
`;
