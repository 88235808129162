import { PermissionValues } from '@gripp/shared-logic';
import { Alerts } from './components/alerts';
import { AlertsToolbar } from './components/alertsToolbar';
import { EditAlert } from './components/editAlert';
import { NewAlert } from './components/newAlert';

const AlertsRoot = 'alerts';

export const alertsRoutes = [
  {
    path: AlertsRoot,
    element: <Alerts />,
    handle: {
      header: {
        titleKey: 'alerts.title',
        icon: 'bell-plus-outline',
      },
      requiredPermission: PermissionValues.alerts.all,
      toolbar: <AlertsToolbar />,
    },
    children: [
      {
        path: ':alertId/edit',
        element: <EditAlert />,
        handle: {
          toolbar: <AlertsToolbar />,
        },
      },
    ],
  },
  {
    path: `${AlertsRoot}/new`,
    element: <NewAlert />,
    handle: {
      header: {
        titleKey: 'alerts.new.header',
        icon: 'bell-plus-outline',
      },
      requiredPermission: PermissionValues.alerts.create,
    },
  },
];
