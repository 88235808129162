export interface QuestionMultipleValueExceptionConfig {
  responseExceptions?: string[];
}

export enum QuestionExceptionHandling {
  Off,
  HasResponse,
  MissingResponse,
}

export interface QuestionExceptionConfig {
  responseException?: QuestionExceptionHandling;
}
