import {
  cacheKeys,
  useCreateMutation,
  useCustomMutation,
  useGetQuery,
  useUpdateMutation,
} from '@gripp/shared-logic';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';

/**
 * MutationForm
 *
 * Handles common data-shuffling tasks for mutation forms
 *   build your form, setup the graphql operation & go
 *   see MutationFormProps below for arguments
 *
 *  what this does:
 *   - does data mutation on form submission
 *   - optionally invalidates cache when mutation is successful
 *
 * 3 components here, depending on what you are doing:
 *    - CreateForm
 *    - UpdateForm
 *    - CustomMutationForm
 */

export type MutationFormProps = {
  /** form component to display / collect data */
  formComponent: FormComponent;

  /* optional props to pass through to form component */
  formProps?: any;

  /** gql of mutation operation */
  mutationOperation: any;

  /** name of model */
  modelName: string;

  /** optional transformation to run on data submitted by form before executing mutation */
  dataTransformer?: (data: any) => any;

  /** default values for form (important to pass for shape of object given to form) */
  defaultValues: any;

  /** should cache be invalidated after mutation? */
  invalidateCache?: boolean;

  /** if cache should be invalidated, need filter of query that is to be invalidated */
  cacheFilter?: any;

  /** optional callback to run after form is submitted & everything is done */
  onComplete?: () => void;
};

export type FormComponentProps = MutationFormProps & {
  onSubmit: (data: any) => Promise<void>;
};
export type FormComponent = FC<FormComponentProps>;
export type UpdateFormComponent = FC<UpdateFormProps>;

export type UpdateFormProps = Omit<MutationFormProps, 'defaultValues'> & {
  /** gql of query to get data to update */
  queryOperation: any;

  /** id of model to update */
  id: string;

  /** optional transformation to run on data returned from query before passing to form */
  queryResultsTransformer?: (results: any) => any;
};

export const CreateForm = (props: MutationFormProps) => {
  return <MutationForm {...props} useMutation={useCreateMutation} />;
};
export const UpdateForm = (props: UpdateFormProps) => {
  const queryClient = useQueryClient();
  const [defaultValues, setDefaultValues] = useState<any | undefined>(
    undefined
  );
  const { data } = useGetQuery({
    modelName: props.modelName,
    query: props.queryOperation,
    id: props.id,
  });

  useEffect(() => {
    if (data) {
      if (props.queryResultsTransformer) {
        setDefaultValues(props.queryResultsTransformer(data));
      } else {
        setDefaultValues(data);
      }
    }
  }, [data, props]);

  const onUpdateComplete = () => {
    if (props.invalidateCache) {
      const cacheKey = cacheKeys(props.modelName).detail(props.id);
      queryClient.removeQueries({ queryKey: [cacheKey], exact: true });
    }

    props.onComplete && props.onComplete();
  };

  return data && defaultValues ? (
    <MutationForm
      {...props}
      defaultValues={defaultValues}
      onComplete={onUpdateComplete}
      useMutation={useUpdateMutation}
    />
  ) : (
    <h3>Loading...</h3>
  );
};
export const CustomMutationForm = (props: MutationFormProps) => {
  return <MutationForm {...props} useMutation={useCustomMutation} />;
};

type Props = MutationFormProps & {
  useMutation: any;
};

const MutationForm = (props: Props) => {
  const { mutateAsync } = props.useMutation({
    modelName: props.modelName,
    query: props.mutationOperation,
  });
  const queryClient = useQueryClient();

  const onSubmit = async (data: any) => {
    let input;
    if (props.dataTransformer) {
      input = props.dataTransformer(data);
    } else {
      input = data;
    }

    await mutateAsync(
      { input: input },
      {
        onSettled: async () => {
          if (props.invalidateCache) {
            await queryClient.invalidateQueries({
              queryKey: cacheKeys(props.modelName).lists(),
            });
          }

          props.onComplete && props.onComplete();
        },
      }
    );
  };

  const FormComponent = props.formComponent;

  return (
    <FormComponent
      defaultValues={props.defaultValues}
      {...props.formProps}
      onSubmit={onSubmit}
    />
  );
};
