import {
  Colors,
  NewAsset as NewAssetBase,
  Spacing,
  TopModalBar,
} from '@gripp/shared-ui';
import { StyleSheet, View } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { MediaPicker } from '../../shared/components/media/mediaPicker';

interface NewAssetModalProps {
  closeModal: () => void;
}

export const NewAssetModal = ({ closeModal }: NewAssetModalProps) => {
  const navigate = useNavigate();

  return (
    <View style={styles.modalContainer}>
      <NewAssetBase
        onCancel={closeModal}
        mediaPicker={MediaPicker}
        topComponentBar={TopModalBar}
        styles={styles}
        onSaved={(assetId: string) => {
          closeModal();
          navigate(`/assets/${assetId}`);
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    borderRadius: 12,
    backgroundColor: Colors.primaryGrayBackground,
  },
  container: {
    backgroundColor: Colors.primaryGrayBackground,
    borderRadius: 12,
    width: 560,
    height: 580,
  },
  contentContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    paddingVertical: Spacing.basePadding.paddingVertical,
  },
});

export default NewAssetModal;
