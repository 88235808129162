import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  AssetFilterModel,
  WorkspaceAsset,
  useSetAssetListFilter,
  useUpdateWorkspaceAsset,
} from '@gripp/shared-logic';
import { Colors, useResponsiveDesign } from '@gripp/shared-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { assetPaths } from '../assetPaths';

type GroupsHeaderProps = {
  workspaceAsset: WorkspaceAsset;
};

export const GroupsHeader = (props: GroupsHeaderProps) => {
  const { t } = useTranslation();
  const { workspaceAsset } = props;
  const { groups } = workspaceAsset;
  const isResponsiveDesign = useResponsiveDesign();
  const { mutateAsync } = useUpdateWorkspaceAsset();
  const { mutateAsync: setAssetListFilter } = useSetAssetListFilter();
  const navigate = useNavigate();

  const deleteGroup = async (groupId: string) => {
    if (!groups?.length) return;

    const groupsIds = groups
      ?.filter((group) => group.id !== groupId)
      .map((item) => ({ id: item.id }));

    const input: any = {
      groups: groupsIds,
      id: workspaceAsset.id,
    };

    await mutateAsync({ input: input });
  };

  const navigateToGroup = async (groupId: string) => {
    await setAssetListFilter(new AssetFilterModel({ groups: [groupId] }));
    navigate(`/${assetPaths.root}`);
  };

  return (
    <>
      <View style={styles.container}>
        <MaterialCommunityIcons
          name="google-circles-extended"
          size={isResponsiveDesign ? 14 : 20}
          color={Colors.black}
          style={{ marginRight: 4 }}
        />
        <Text
          style={[
            styles.groupTitle,
            { fontSize: isResponsiveDesign ? 14 : 18 },
          ]}
        >
          {t('assets.list.groups')}
        </Text>
        <Text
          style={[
            styles.groupLabel,
            { fontSize: isResponsiveDesign ? 10 : 12 },
          ]}
        >
          {groups?.length ?? 0} {t('assets.list.groups')}
        </Text>
      </View>
      <View style={styles.chipBox}>
        {groups &&
          groups.map((group) => (
            <ChipGroups
              key={group.id}
              label={group.name}
              onClick={async () => await navigateToGroup(group.id)}
              onDelete={async () => await deleteGroup(group.id)}
            />
          ))}
      </View>
    </>
  );
};

const ChipGroups = ({
  label,
  onClick,
  onDelete,
}: {
  label: string;
  onClick: () => void;
  onDelete: () => void;
}) => {
  const [labelStyle, setLabelStyle] = React.useState(styles.chipButton);
  const [closeStyle, setCloseStyle] = React.useState(styles.chipButton);
  return (
    <View style={styles.container}>
      <Pressable
        style={[labelStyle, styles.chipButtonLeft]}
        onHoverIn={() => setLabelStyle(styles.chipButtonHover)}
        onHoverOut={() => setLabelStyle(styles.chipButton)}
        onPress={onClick}
      >
        <Text style={styles.chipText}>{label}</Text>
      </Pressable>
      <Pressable
        style={[closeStyle, styles.chipButtonRight]}
        onHoverIn={() => setCloseStyle(styles.chipButtonHover)}
        onHoverOut={() => setCloseStyle(styles.chipButton)}
        onPress={onDelete}
      >
        <MaterialCommunityIcons name="close" size={18} />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  groupTitle: {
    fontWeight: '600',
  },
  groupLabel: {
    fontWeight: '400',
    paddingLeft: 12,
  },
  chipBox: {
    flex: 1,
    marginTop: 10,
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flexDirection: 'row',
    rowGap: 8,
    columnGap: 4,
    height: '100%',
    maxHeight: 82,
    overflowY: 'auto',
    alignContent: 'flex-start',
  },
  chipText: {
    fontSize: 12,
    fontWeight: '500',
    display: 'flex',
    lineHeight: 18,
    marginLeft: 8,
    marginTop: 0,
    marginBottom: 0,
    columnGap: 4,
    marginRight: 8,
  },
  chipButton: {
    backgroundColor: Colors.primaryGrayBackground,
  },
  chipButtonHover: {
    backgroundColor: Colors.graySpacer,
  },
  chipButtonLeft: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 0,
  },
  chipButtonRight: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 0,
  },
});
