export const GET_BATCH_TAGS = `
  query GetBatchTags($batch: ID!) {
    findTags(filter: {
      batch: { eq: $batch }
    }) {
      items {
        id
        tagCode
        version {
          version
        }
      }
    }
  }
`;
