/*
  RHF's useFormContext() was always returning null. So...simple context provider
*/
import { createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const FormContext = createContext<UseFormReturn | null>(null);

export const useFormContext = (): UseFormReturn => {
  return useContext(FormContext) as UseFormReturn;
};
