import { Group } from '@gripp/shared-logic';
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Chip, Menu } from 'react-native-paper';
import { Colors } from '../../themes';
import { GroupChip, GroupChipStyle, GroupChipTextStyle } from './groupChip';

type GroupChipMenuProps = {
  groups: Group[];
  onPress: (groupId: string) => Promise<void>;
};

export const GroupChipMenu = (props: GroupChipMenuProps) => {
  const [visible, setVisible] = useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  const onPress = async (groupId: string) => {
    await props.onPress(groupId);
    closeMenu();
  };

  return (
    <Menu
      visible={visible}
      onDismiss={closeMenu}
      contentStyle={styles.groupMenuContent}
      anchor={
        <Chip
          onPress={openMenu}
          textStyle={GroupChipTextStyle}
          style={GroupChipStyle}
        >
          {`+${props.groups.length - 1}`}
        </Chip>
      }
    >
      {props.groups.map((group) => (
        <View
          key={props.groups.indexOf(group)}
          style={styles.groupChipMenuItemContainer}
        >
          <GroupChip group={group} onPress={onPress} />
        </View>
      ))}
    </Menu>
  );
};

const styles = StyleSheet.create({
  groupChipMenuItemContainer: {
    marginVertical: 5,
    marginHorizontal: 15,
  },
  groupMenuContent: {
    backgroundColor: Colors.white,
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
    top: 30,
    right: 40,
  },
});
