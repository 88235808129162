import { EnvConfig } from '@gripp/shared-logic';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

const TIMER_DEFAULT_5_MIN = 300000;
type VersionCheckConfig = {
  checkInterval?: number;
  onVersionMismatch: (showModal: boolean) => void;
};

export const useVersionCheck = ({
  checkInterval = TIMER_DEFAULT_5_MIN,
  onVersionMismatch,
}: VersionCheckConfig) => {
  const location = useLocation();
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    const checkVersion = async () => {
      const response = await fetch(`${EnvConfig.apiUrl}/app-version`);
      const versionFromApi = (await response.text()).trim();
      const versionFromApp = EnvConfig.version;
      if (versionFromApi !== versionFromApp && timer) {
        onVersionMismatch(true);
        clearInterval(timer);
      }
    };

    const startTimer = () => {
      if (EnvConfig.version === 'dev') return;
      if (timer) clearInterval(timer);
      timer = setInterval(checkVersion, checkInterval);
    };

    startTimer();

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [location, checkInterval, onVersionMismatch]);
};
