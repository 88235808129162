import {
  RoutineQuestionConfig,
  RoutineResponseState,
  RoutineType,
  WorkspaceRoutine,
  useStatefulWorkspaceRoutines,
} from '@gripp/shared-logic';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from '../../../../forms';
import { CounterQuestionExecutionForm } from '../counter/counterQuestionExecutionForm';
import { StatusQuestionExecutionForm } from '../status/statusQuestionExecutionForm';
import {
  RoutineQuestionExecutionForm,
  RoutineQuestionExecutionFormProps,
} from '../types';

export const ExistingRoutineQuestionExecutionForm: RoutineQuestionExecutionForm<
  RoutineQuestionConfig,
  RoutineResponseState
> = (
  props: RoutineQuestionExecutionFormProps<
    RoutineQuestionConfig,
    RoutineResponseState
  >
) => {
  const { register } = useFormContext();
  const [execution, setExecution] = useState<any>(null);
  const fieldPrefix = useMemo(
    () => `responses.${props.responseIndex}`,
    [props.responseIndex]
  );
  const routines = useStatefulWorkspaceRoutines(
    props.workspaceAsset.workspace.id
  );

  const getExecution = useCallback(
    (routine: WorkspaceRoutine) => {
      const currentState = props.workspaceAsset.routineState?.find(
        (s) => s.workspaceRoutineId === props.config.workspaceRoutineId
      );
      const componentProps = {
        config: routine.config,
        currentState: currentState,
        workspaceAsset: props.workspaceAsset,
        responseIndex: props.responseIndex,
      };

      let component;

      switch (routine.type) {
        case RoutineType.Counter:
          component = CounterQuestionExecutionForm;
          break;
        case RoutineType.Status:
          component = StatusQuestionExecutionForm;
          break;
      }

      return {
        component,
        componentProps,
      };
    },
    [props.config.workspaceRoutineId, props.workspaceAsset.routineState]
  );

  useEffect(() => {
    if (!routines) return;

    const routine = routines.find(
      (r) => r.id === props.config.workspaceRoutineId
    );
    if (!routine) return;

    register(`${fieldPrefix}.workspaceRoutineId`, {
      value: routine.id,
    });
    register(`${fieldPrefix}.workspaceRoutineVersionId`, {
      value: routine.currentVersion.id,
    });

    const execution = getExecution(routine);

    setExecution(execution);
  }, [routines]);

  if (!execution) return null;

  return execution.component ? (
    <execution.component {...execution.componentProps} />
  ) : null;
};
