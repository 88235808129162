import { AccountFilterModel, AccountTypeFilterKey } from '../domain';
import { useFilter } from '../../filter';

export const useAccountTypeFilter = () => {
  return useFilter(
    AccountTypeFilterKey,
    'accountTypeFilter',
    (filter: any) => new AccountFilterModel(filter)
  );
};
