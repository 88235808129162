import { QuickConfirmState } from '@gripp/shared-logic';
import { MessageComponent, MessageComponentProps } from '../../issue/types';
import { CommonRoutineMessage } from '../components/commonRoutineMessage';

export const QuickConfirmMessage: MessageComponent = (
  props: MessageComponentProps
) => {
  const assetRoutine = props.message.assetRoutine;
  const data = assetRoutine.data as QuickConfirmState;
  const routineText = `Confirmed: ${data.confirmedValue}`;

  return <CommonRoutineMessage routineText={routineText} {...props} />;
};
