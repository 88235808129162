import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useStorageKeys } from '../../storage';
import { useGrippContext } from '../../grippContext';

export const useSetCurrentWorkspace = () => {
  const queryClient = useQueryClient();
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();
  return useMutation({
    mutationKey: ['currentWorkspace'],
    mutationFn: async (workspace?: string) => {
      if (!workspace) {
        await storageHandler.deleteItem(storageKeys.workspace);
      } else {
        await storageHandler.setItem(storageKeys.workspace, workspace);
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['currentWorkspace'] }),
  });
};
