import React, { useEffect, useRef, useState } from 'react';
import {
  Animated,
  Modal,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useResponsiveDesign } from '../../hooks';
import { Colors } from '../../themes/colors';

type DropDownViewProps = {
  children: React.ReactNode;
  isVisible: boolean;
  onDismiss: () => void;
  anchorRef: React.RefObject<View>;
  width?: number;
  height?: number;
  maxHeight?: number;
  backgroundColor?: string;
};

export const DropDownView = ({
  children,
  isVisible,
  onDismiss,
  anchorRef,
  width = 280,
  height = 472,
  maxHeight = 472,
  backgroundColor = Colors.white,
}: DropDownViewProps) => {
  const opacity = useRef(new Animated.Value(0)).current;
  const translateY = useRef(new Animated.Value(-20)).current;

  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const isResponsiveSize = useResponsiveDesign();

  const responsiveWidth = isResponsiveSize ? 240 : width;
  const responsiveHeight = isResponsiveSize ? 364 : height;

  useEffect(() => {
    if (isVisible) {
      Animated.parallel([
        Animated.timing(opacity, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(translateY, {
          toValue: 0,
          duration: 250,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.timing(opacity, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(translateY, {
          toValue: -20,
          duration: 250,
          useNativeDriver: true,
        }),
      ]).start();
    }
  }, [isVisible, opacity, translateY]);

  // Calculate position based on anchor ref
  useEffect(() => {
    if (isVisible && anchorRef?.current) {
      anchorRef.current.measureInWindow(
        (x, y, responsiveWidth, responsiveHeight) => {
          setDropdownPosition({
            top: y + responsiveHeight,
            left: x,
          });
        }
      );
    }
  }, [isVisible, anchorRef]);

  return (
    <Modal transparent visible={isVisible} animationType="none">
      <TouchableWithoutFeedback onPress={onDismiss}>
        <View style={styles.overlay}>
          <TouchableWithoutFeedback>
            <Animated.View
              style={[
                styles.dropdown,
                {
                  opacity,
                  transform: [{ translateY }],
                  top: dropdownPosition.top + 12,
                  left: dropdownPosition.left,
                  width: responsiveWidth,
                  height: responsiveHeight,
                  maxHeight,
                  backgroundColor,
                },
              ]}
            >
              {children}
            </Animated.View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: Colors.blackOverlay,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropdown: {
    position: 'absolute',
    borderRadius: 8,

    overflow: 'hidden',
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 8,
  },
});
