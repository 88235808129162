import { Workspace, useUpdateMutation } from '@gripp/shared-logic';
import {
  Colors,
  ControlledTextInput,
  SaveFormTop,
  TopModalBar,
} from '@gripp/shared-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { string, object as yupObject } from 'yup';

type EditWorkspaceProps = {
  workspace: Workspace;
  onComplete: () => void;
};

export const EditWorkspace = (props: EditWorkspaceProps) => {
  const formSchema = yupObject({
    name: string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    values: {
      name: props.workspace.name,
    },
  });

  const { mutateAsync } = useUpdateMutation({
    modelName: 'workspace',
    query: UPDATE_WORKSPACE,
  });

  const onSubmit = async (data: any) => {
    const workspace = {
      id: props.workspace.id,
      name: data.name,
    };
    await mutateAsync({ input: workspace });
    props.onComplete();
  };

  return (
    <View style={styles.container}>
      <SaveFormTop
        isDirty={isDirty}
        isValid={isValid}
        onSubmit={handleSubmit(onSubmit)}
        title="Edit Name"
        topBar={TopModalBar}
        onCancel={props.onComplete}
      >
        <ControlledTextInput control={control} name="name" autoFocus={true} />
      </SaveFormTop>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.primaryGrayBackground,
    width: 560,
    minHeight: 167,
    borderRadius: 12,
    justifyContent: 'center',
  },
});

const UPDATE_WORKSPACE = `
  mutation UpdateWorkspace($input: MutateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      id
    }
  }
`;
