import { FC } from 'react';
import { StyleSheet, Platform } from 'react-native';
import { Colors } from '../../themes';
import { WorkspaceAsset } from '@gripp/shared-logic';

export const HeaderStyles = StyleSheet.create({
  headerText: {
    color: Colors.black,
    backgroundColor: '#CFEAFC',
    borderRadius: 20,
    paddingHorizontal: 7,
    paddingVertical: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 5,
    ...Platform.select({
      web: {
        fontSize: 12,
        fontWeight: '400',
      },
      default: {
        fontSize: 12,
        fontWeight: '500',
      },
    }),
  },
});

export type SubHeaderProps = {
  workspaceAsset: WorkspaceAsset;
};

export type SubHeader = FC<SubHeaderProps>;
