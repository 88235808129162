import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DeleteMutationVariables, MutationArgs } from '../types';
import { cacheKeys } from './cacheKeys';
import { useFetcher } from './useFetcher';

export const useDeleteMutation = (opts: MutationArgs) => {
  const queryClient = useQueryClient();
  const cacheKey = cacheKeys(opts.modelName).lists();

  const fetcherFn = useFetcher(opts.query);

  return useMutation({
    mutationFn: async (variables: DeleteMutationVariables) => {
      await fetcherFn(variables);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: cacheKey });
    },
  });
};
