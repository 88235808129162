import { Media, MediaData, MediaStatus, MediaType } from '@gripp/shared-logic';
import { DocumentData, ImageData, SizePrefix } from './types';

export const getMediaTitleForWidth = (
  name: string,
  ext: string,
  maxWidth: number
) => {
  const maxChars = Math.floor(maxWidth / 7);
  const dots = 2;
  if (name.length > maxChars) {
    const start = name.substring(0, maxChars - ext.length - dots);
    return `${start}..${ext}`;
  }
  return name;
};

export const createTypedMediaItem = <T extends MediaData>(
  media: Media,
  baseMediaUrl: string,
  baseApiUrl: string
): T => {
  if (media.type === MediaType.Image) {
    return createImageData(media, baseMediaUrl) as T;
  } else if (media.type === MediaType.Document) {
    return createDocumentData(media, baseApiUrl) as T;
  } else {
    throw new Error(`Unknown media type: ${media.type}`);
  }
};

export const processTypedMediaItem = <T extends MediaData>(media: T) => {
  if (media.type === MediaType.Image) {
    processImageItem(media);
  }
};

const createImageData = (media: Media, baseUrl: string): ImageData => {
  return {
    ...media,
    showProcessing: media.status !== MediaStatus.Processed,
    url: getImageUrlBySize(
      baseUrl,
      media.id,
      media.extension,
      SizePrefix.original
    ),
    urlsBySize: getAvailableImageUrls(baseUrl, media.id, media.extension),
  };
};

const createDocumentData = (media: Media, baseUrl: string): DocumentData => {
  return {
    ...media,
    showProcessing: media.status !== MediaStatus.Processed,
    name: media.name ?? '',
    url: getAvailableDocumentUrl(baseUrl, media.id, media.name ?? ''),
  };
};

const getAvailableImageUrls = (
  baseUrl: string,
  Id: string,
  ext: string
): Record<SizePrefix, string> => {
  return Object.values(SizePrefix).reduce((acc, size) => {
    acc[size] = getImageUrlBySize(baseUrl, Id, ext, size);
    return acc;
  }, {} as Record<SizePrefix, string>);
};

const getImageUrlBySize = (
  baseUrl: string,
  Id: string,
  ext: string,
  size: SizePrefix
) => {
  return `${baseUrl}/images/${Id}/${size}.${ext}`;
};

const getAvailableDocumentUrl = (baseUrl: string, Id: string, name: string) => {
  return `${baseUrl}/document/${Id}`;
};

const processImageItem = (image: ImageData) => {
  const urlsBySize = Object.values(SizePrefix).reduce((acc, size) => {
    acc[size] = image.url; // set urls for all sizes to local url
    return acc;
  }, {} as Record<SizePrefix, string>);
  image.urlsBySize = urlsBySize;
};
