import { Account } from '../../auth';
import { ColumnModel, FilterModel } from '../../filter';

export const AccountFilterKey = 'ACCOUNT_FILTER';
export const AccountTypeFilterKey = 'ACCOUNT_TYPE_FILTER';
export const AccountListOrderKey = 'ACCOUNT_LIST_ORDER';

export const AccountFilterColumns = [
  'jobTitle',
  'roles',
  'email',
  'phone',
  'groups',
  'createdAt',
];
export const AccountFilterAdminColumns = ['workspace'];

export class AccountFilterModel implements FilterModel, ColumnModel {
  keyword?: string;
  columns?: string[];
  workspaceId?: string;
  groups?: string[];
  roles?: string[];

  constructor(data?: any) {
    this.keyword = data?.keyword;
    this.workspaceId = data?.workspaceId;
    this.columns = data?.columns || [
      'jobTitle',
      'roles',
      'email',
      'phone',
      'groups',
    ];
    this.groups = data?.groups;
    this.roles = data?.roles;
  }
  public scrubWorkspaceData(): void {
    this.workspaceId = undefined;
    this.groups = undefined;
    this.roles = undefined;
  }

  public getGroupsCount(): number {
    return this.groups && this.groups.length > 0 ? this.groups.length : 0;
  }

  public getAccountTypesCount(): number {
    return this.roles && this.roles.length > 0 ? this.roles.length : 0;
  }

  public getColumnsCount(): number {
    return this.columns && this.columns.length > 0 ? this.columns.length : 0;
  }

  public getGraphQLFilter(): any {
    const filter = {} as any;

    if (this.keyword) {
      const jsonFilter = { $regex: `${this.keyword}`, $options: 'i' };
      filter.or = [
        { json: { 'name.first': jsonFilter } },
        { json: { 'name.last': jsonFilter } },
        { email: { contains: this.keyword } },
        { json: { 'phone.phoneNumber': jsonFilter } },
      ];
    }

    if (this.workspaceId) {
      filter.workspace = { eq: this.workspaceId };
    }
    if (this.groups && this.groups.length > 0) {
      filter.groups = { in: this.groups };
    }
    if (this.roles && this.roles.length > 0) {
      filter.roles = { in: this.roles };
    }

    return filter;
  }

  public updateGroupFilterForAccount(account: Account): void {
    if (
      (this.groups && this.groups.length > 0) ||
      !account.groups ||
      account.groups.length < 1
    )
      return;

    this.groups = account.groups.map((group) => group.id);
  }

  public updateAccountTypeFilterForAccount(account: Account): void {
    if (
      (this.roles && this.roles.length > 0) ||
      !account.roles ||
      account.roles.length < 1
    )
      return;

    this.roles = account.roles.map((role) => role.id);
  }
}
