import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Colors, ControlledSelector } from '@gripp/shared-ui';
import { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { Text, TouchableRipple } from 'react-native-paper';

type RoutineSelectorProps = {
  dataItems: any;
  control: any;
  name: string;
  placeholder?: string;
  outlined?: boolean;
};

export const RoutineSelector = (props: RoutineSelectorProps) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  const promptWorkspaceRoutineItem = useWatch({
    control: props.control,
    name: props.name,
  });
  // to avoid additional routine pull when setting Form values for edit
  const expanderLabel = useMemo(() => {
    return (
      promptWorkspaceRoutineItem?.label ??
      props.dataItems?.find((i) => i.id === promptWorkspaceRoutineItem?.id)
        ?.label ??
      props.placeholder
    );
  }, [props.dataItems, promptWorkspaceRoutineItem]);

  useEffect(() => {
    if (promptWorkspaceRoutineItem) {
      setExpanded(false);
    }
  }, [promptWorkspaceRoutineItem]);

  return (
    <>
      <View style={styles.expanderContainer}>
        <TouchableRipple onPress={toggleExpanded}>
          <View style={[styles.expander, props.outlined && styles.outlined]}>
            <Text style={styles.expanderText} numberOfLines={1}>
              {expanderLabel}
            </Text>
            <MaterialCommunityIcons
              name={expanded ? 'chevron-up' : 'chevron-down'}
              size={24}
              color={Colors.primaryDark}
            />
          </View>
        </TouchableRipple>
      </View>
      {expanded && props.dataItems?.length > 0 && (
        <View style={styles.dropdown}>
          <ControlledSelector
            control={props.control}
            name={props.name}
            items={props.dataItems}
          />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  expanderContainer: {
    marginBottom: 20,
    borderRadius: 8,
    overflow: 'hidden',
    maxWidth: 541,
    zIndex: -1,
  },
  expander: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
    height: 52,
    backgroundColor: Colors.white,
    paddingHorizontal: 20,
  },
  expanderText: {
    maxWidth: '100%',
    fontSize: 16,
    fontWeight: '400',
    color: Colors.grayFill,
  },
  expanderIcon: {
    alignSelf: 'flex-end',
  },
  dropdown: {
    paddingVertical: 10,
    paddingLeft: 12,
    paddingRight: 16,
    position: 'absolute',
    zIndex: 100,
    width: 541,
    top: 90,
    backgroundColor: Colors.white,
    color: Colors.black,
    borderRadius: 8,
    maxHeight: 384,
    boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.15)',
  },
  outlined: {
    borderWidth: 1,
    borderColor: Colors.grayText,
  },
});
