import { useCurrentAccount } from '../../auth';
import { useFindByQuery } from '../../graphql';

export const useFindUnreadActivityInbox = () => {
  const { account } = useCurrentAccount();

  return useFindByQuery({
    modelName: 'activityInbox',
    query: FIND_INBOX,
    variables: {
      accountId: account?.id,
    },
    options: {
      enabled: account != null,
    },
  });
};

const FIND_INBOX = `
  query findActivityInboxes($accountId: ID!) {
    findActivityInboxes(filter: {
      and: [
        { for: { eq: $accountId } },
        { read: { eq: false } }
      ]      
    }) {
      items {
        id
        type
        data
      }
    }
  }
`;
