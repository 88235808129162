import {
  getMentionTextPartsForHighlighting,
  isOnlyEmojis,
  replaceMentionPlaceholderWithNames,
  useTranslatedText,
} from '@gripp/shared-logic';
import * as Linking from 'expo-linking';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, StyleSheet, View } from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import { Text } from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { getImageThumbnailWidth } from '../media/getMediaThumbnailWidth';
import { MessageMedia } from './messageMedia';
import { messageStyles } from './styles';
import { MessageComponent, MessageComponentProps } from './types';

export const UserMessage: MessageComponent = (props: MessageComponentProps) => {
  const { t } = useTranslation();
  const { message, imageDimensions } = props;
  const [mediaWidth, setMediaWidth] = useState<number>();
  const { originalText, translatedText, needsTranslation } = useTranslatedText(
    'text',
    message
  );
  const [text, setText] = useState(
    needsTranslation ? translatedText : originalText
  );

  return (
    <View
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout;
        const thumbnailWidth = getImageThumbnailWidth(
          width,
          imageDimensions.width ?? undefined
        );
        setMediaWidth(thumbnailWidth);
      }}
    >
      <View>
        <>
          {text && (
            <Hyperlink
              linkDefault={false}
              linkStyle={{ color: Colors.blue }}
              onPress={async (url, text) => {
                // see #1418, was having issue opening youtube urls on android
                // b/c canOpenURL was returning false
                // however, just opening the url without that check works.
                // posted to expo discord in case it's a bug:
                // https://discord.com/channels/695411232856997968/1287818543660011550
                await Linking.openURL(url);
              }}
            >
              <Text
                style={[
                  messageStyles.messageText,
                  props.textStyle,
                  isOnlyEmojis(text)
                    ? Platform.OS === 'web'
                      ? styles.emojiOnlyWeb
                      : styles.emojiOnlyMobile
                    : {},
                ]}
              >
                {getMentionTextPartsForHighlighting(
                  replaceMentionPlaceholderWithNames(
                    text,
                    message.userMentions,
                    t('mentions.unknownUser')
                  )
                ).map((part, index) => {
                  if (part.isHighlighted) {
                    return (
                      <Text key={index} style={styles.mentionHighlight}>
                        @{part.name}
                      </Text>
                    );
                  } else {
                    return part.text;
                  }
                })}
              </Text>
            </Hyperlink>
          )}
          {needsTranslation && (
            <View style={styles.translateButton}>
              <Pressable
                onPress={() => {
                  if (text === originalText) {
                    setText(translatedText);
                  } else {
                    setText(originalText);
                  }
                }}
              >
                <Text style={styles.translationButton}>
                  {text === originalText
                    ? t('translation.seeTranslation')
                    : t('translation.seeOriginal')}
                </Text>
              </Pressable>
            </View>
          )}
          <MessageMedia media={props.message.media} displayWidth={mediaWidth} />
        </>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mentionHighlight: {
    color: Colors.primaryDark,
    fontWeight: '400',
  },
  translateButton: {
    alignItems: 'flex-start',
    marginBottom: 4,
  },
  emojiOnlyMobile: {
    fontSize: 48,
    paddingTop: 40,
  },
  emojiOnlyWeb: {
    fontSize: 48,
    marginTop: 20,
    marginBottom: 16,
  },
  translationButton: {
    color: Colors.primaryDark,
    fontSize: 14,
    lineHeight: 16.8,
    marginTop: 8,
  },
});
