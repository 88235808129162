import { DATE_FORMAT_MMDDYYYY, getYearBounds } from '@gripp/shared-logic';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { View } from 'react-native';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { Colors } from '../../../themes';
import { DateRangeSelectorProps } from './dateRangeSelector';

type DatePickerWheelProps = DateRangeSelectorProps & {
  onDateSelected: (...event: any[]) => void;
  isStartDate?: boolean;
  isVisible: boolean;
  onClose: () => void;
};

export const DatePickerWheel: React.FC<DatePickerWheelProps> = (props) => {
  const { control, onDateSelected, isStartDate, setValue, isVisible, onClose } =
    props;
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const handleConfirm = useCallback(
    (date: Date) => {
      if (!date) return;

      const newDate = moment(date);
      if (isStartDate) {
        onDateSelected(newDate.format(DATE_FORMAT_MMDDYYYY));
        setCurrentDate(newDate.toDate());
        const startDate = moment(date);
        const endDate = moment(
          control._formValues.endDate,
          DATE_FORMAT_MMDDYYYY
        );

        if (startDate.isAfter(endDate)) setValue('endDate', '');

        onClose();
        return;
      }
      const startDate = moment(
        control._formValues.startDate,
        DATE_FORMAT_MMDDYYYY
      );
      const endDate = moment(date);

      if (startDate.isAfter(endDate)) return;

      onDateSelected(newDate.format(DATE_FORMAT_MMDDYYYY));
      setCurrentDate(newDate.toDate());
      onClose();
    },
    [isStartDate, onDateSelected, control, setValue, onClose]
  );

  const { firstDay, lastDay } = getYearBounds();

  return (
    <View>
      <DateTimePickerModal
        isVisible={isVisible}
        mode="date"
        onConfirm={handleConfirm}
        onCancel={onClose}
        buttonTextColorIOS={Colors.primary}
        maximumDate={lastDay}
        minimumDate={firstDay}
        date={currentDate}
        display={'spinner'}
        negativeButton={{ textColor: Colors.primary }}
        positiveButton={{ textColor: Colors.primary }}
        key={currentDate.toISOString()}
      />
    </View>
  );
};
