import {
  FeatureName,
  Issue,
  Message,
  useAddIsueMessageReaction,
  useIsFeatureEnabled,
} from '@gripp/shared-logic';
import { useState } from 'react';
import {
  Modal,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import EmojiPicker from 'rn-emoji-keyboard';
import { EmojiType } from 'rn-emoji-keyboard/lib/typescript/types';
import { Colors } from '../../themes';
import { AddEmojiIcon } from '../emoji/addEmojiIcon';
import { SingleEmoji } from '../emoji/singleEmoji';

export type ReactionProps = {
  reactionVisible: boolean;
  issue: Issue;
  onCloseReaction: () => void;
  message: Message;
};

export const NewReaction = (props: ReactionProps) => {
  const isUserReactionMessageEnabled = useIsFeatureEnabled(
    FeatureName.IssueMessageReaction
  );
  const isWeb = Platform.OS === 'web';
  const [visibleEmojiPicker, setVisibleEmojiPicker] = useState(false);
  const { mutateAsync: AddReaction, isPending } = useAddIsueMessageReaction();

  const handleSelect = async (emoji: EmojiType) => {
    if (isPending) return;

    await AddReaction({
      input: {
        issueMessageId: props.message.id,
        reactionType: emoji.emoji,
      },
    });
    setVisibleEmojiPicker(false);
    props.onCloseReaction();
  };

  if (!isUserReactionMessageEnabled || isWeb) return null;

  return (
    <Modal visible={props.reactionVisible} transparent animationType="slide">
      <TouchableWithoutFeedback onPress={props.onCloseReaction}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContentContainer}>
            <BaseReactionRow
              onOpenEmojiPicker={() => setVisibleEmojiPicker(true)}
              onEmojiSelected={handleSelect}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
      <EmojiPicker
        onEmojiSelected={handleSelect}
        open={visibleEmojiPicker}
        onClose={() => setVisibleEmojiPicker(false)}
        enableSearchBar
      />
    </Modal>
  );
};
type BaseReactionRowProps = {
  onOpenEmojiPicker: () => void;
  emojiContainerStyle?: StyleProp<ViewStyle>;
  onEmojiSelected: (emoji: EmojiType) => void;
};

const BaseReactionRow = (props: BaseReactionRowProps) => (
  <View style={styles.baseReactionRowContainer}>
    {Object.entries(BaseReaction).map(([key, value]) => (
      <SingleEmoji
        key={`single-emoji-${key}`}
        emojiContainerStyle={styles.singleEmoji}
        emojiStyle={styles.emojiStyle}
        emoji={BaseReactionMap[value].emoji}
        onPress={() => props.onEmojiSelected(BaseReactionMap[value])}
      />
    ))}
    <NewReactionButton {...props} />
  </View>
);

export const NewReactionButton = (props: Partial<BaseReactionRowProps>) => (
  <Pressable
    onPress={props.onOpenEmojiPicker}
    style={[styles.singleEmoji, props.emojiContainerStyle]}
  >
    <AddEmojiIcon />
  </Pressable>
);

enum BaseReaction {
  THUMBS_UP = 'thumbs_up',
  THUMBS_DOWN = 'thumbs_down',
  RAISING_HANDS = 'raising_hands',
  RED_QUESTION_MARK = 'red_question_mark',
}

const BaseReactionMap: Record<BaseReaction, EmojiType> = {
  [BaseReaction.THUMBS_UP]: {
    emoji: '👍',
    name: 'thumbs up',
    slug: 'thumbs_up',
    toneEnabled: true,
    unicode_version: '0.6',
  },
  [BaseReaction.THUMBS_DOWN]: {
    emoji: '👎',
    name: 'thumbs down',
    slug: 'thumbs_down',
    toneEnabled: true,
    unicode_version: '0.6',
  },
  [BaseReaction.RAISING_HANDS]: {
    emoji: '🙌',
    name: 'raising hands',
    slug: 'raising_hands',
    toneEnabled: true,
    unicode_version: '0.6',
  },
  [BaseReaction.RED_QUESTION_MARK]: {
    emoji: '❓',
    name: 'red question mark',
    slug: 'red_question_mark',
    toneEnabled: false,
    unicode_version: '0.6',
  },
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: Colors.blackInactive,
  },
  modalContentContainer: {
    height: '30%',
    backgroundColor: Colors.primaryGrayBackground,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 22,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  baseReactionRowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 16,
    width: '100%',
  },
  singleEmoji: {
    padding: 10,
    borderRadius: 60,
    backgroundColor: Colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
  },
  emojiStyle: {
    fontWeight: 400,
    fontSize: 32,
  },
});
