import { useQuery } from '@tanstack/react-query';
import { StorageKeys, useStorageKeys } from '../../storage';
import { SortDirectionEnum } from '../../graphql';
import { useGrippContext } from '../../grippContext';

export type UseListOrderResult = {
  data: any;
  isLoading: boolean;
};

export const useListOrder = (
  queryKey: string,
  storageKey: string
): UseListOrderResult => {
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();
  const { data, isLoading } = useQuery<any>({
    queryKey: [queryKey],
    queryFn: async () => {
      const defaultOrder = {
        field: 'name',
        order: SortDirectionEnum.Asc,
      };
      const order = JSON.parse(
        (await storageHandler.getItem(
          storageKeys[storageKey as keyof StorageKeys]
        )) as string
      );

      return order ?? defaultOrder;
    },
  });

  return { data, isLoading };
};
