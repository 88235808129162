import {
  ActionType,
  TriggerType,
  useCreateMutation,
  useCurrentWorkspace,
  useGrippContext,
  useWorkspaceAsset,
} from '@gripp/shared-logic';
import {
  Colors,
  RoutineTriggerConfigValidadationSchema,
  SaveFormBottom,
} from '@gripp/shared-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';
import { string, object as yupObject } from 'yup';
import {
  DateRangePicker,
  DateRangeValidationSchema,
} from '../../../shared/components/dates/controlledDatePicker';
import { alertsPaths } from '../../alertsPaths';
import { PromptRoutineSelector } from './commonSectionComponents';
import { CREATE_WORKFLOW } from './operations';
import {
  WhatItemTypeToggleButton,
  WhatStep,
  WhenStep,
  WhyStep,
} from './stepsSectionComponents';
import { WhatItemType } from './types';

export const NewAlert = () => {
  const { logger } = useGrippContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const workspace = useCurrentWorkspace();

  const createAlertFormSchema = yupObject({
    name: string().required(),
    whatItemType: string().required(),
    whatItem: yupObject().required(),
    whyItem: yupObject().required(),
    whenConfig: RoutineTriggerConfigValidadationSchema.config.required(),
    disabledBetween: DateRangeValidationSchema.dateRange.optional(),
    promptWorkspaceRoutineItem: yupObject().optional(),
  });

  const {
    handleSubmit,
    control,
    reset,
    resetField,
    watch,
    setValue,
    formState: { isDirty, isValid },
  } = useForm({
    resolver: yupResolver(createAlertFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      whatItemType: WhatItemType.Assets,
      whatItem: undefined,
      whyItem: undefined,
      whenConfig: undefined,
      disabledBetween: undefined,
      promptWorkspaceRoutineItem: undefined,
      name: '',
    },
  });
  const whatItemType = watch('whatItemType');
  const whatItem: any = watch('whatItem');
  const whyItem = watch('whyItem');
  const whenConfig = watch('whenConfig');

  const { workspaceAsset } = useWorkspaceAsset(
    whatItem?.id,
    whatItemType !== WhatItemType.Assets || !whatItem
  );

  useEffect(() => {
    resetField('whatItem');
  }, [whatItemType]);

  useEffect(() => {
    if (!whatItem) {
      resetField('name');
      resetField('disabledBetween');
    }
    resetField('whyItem');
  }, [whatItem]);

  useEffect(() => {
    resetField('whenConfig');
    resetField('promptWorkspaceRoutineItem');
  }, [whyItem]);

  useEffect(() => {
    const name = getAlertName(whenConfig, whatItem, whyItem);
    setValue('name', name);
  }, [whatItem, whyItem, whenConfig]);

  const { mutateAsync: createWorkflow } = useCreateMutation({
    modelName: 'Workflow',
    query: CREATE_WORKFLOW,
  });
  const onSubmit = async (data: any) => {
    const input = {
      name: data.name,
      workspace: { id: workspace.id },
      workspaceAsset:
        data.whatItemType === WhatItemType.Assets
          ? { id: workspaceAsset?.id }
          : undefined,
      group:
        data.whatItemType === WhatItemType.Groups
          ? { id: data.whatItem.id }
          : undefined,
      trigger: { type: TriggerType.Routine, config: data.whenConfig },
      action: {
        type: ActionType.Alert,
        config: data.promptWorkspaceRoutineItem?.id
          ? {
              promptWorkspaceRoutineId: data.promptWorkspaceRoutineItem?.id,
            }
          : undefined,
      },
      disabledBetween:
        data.disabledBetween?.start && data.disabledBetween?.end
          ? {
              start: data.disabledBetween.start.slice(0, 10),
              end: data.disabledBetween.end.slice(0, 10),
            }
          : undefined,
    };
    await createWorkflow(
      { input },
      {
        onSuccess: () => {
          reset();
          navigate(`/${alertsPaths.root}`);
        },
        onError: (error) => {
          alert('Could not create alert. Please try again.');
          logger?.error(error, 'Error create alert');
        },
      }
    );
  };

  return (
    <ScrollView scrollEnabled={true} showsVerticalScrollIndicator={true}>
      <SaveFormBottom
        onSubmit={handleSubmit(onSubmit)}
        isDirty={isDirty}
        isValid={isValid}
        saveLabel={t('alerts.create.title')}
      >
        <View style={styles.container}>
          <View style={styles.stepSections}>
            <View style={styles.stepSection}>
              <View style={styles.stepSectionHeader}>
                <Text style={styles.boldLabel}>{t('alerts.create.what')}</Text>
                <WhatItemTypeToggleButton
                  control={control}
                  name="whatItemType"
                />
              </View>
              <View style={styles.stepSectionBody}>
                {workspace && (
                  <WhatStep
                    workspaceId={workspace.id}
                    control={control}
                    name="whatItem"
                    type={whatItemType}
                    t={t}
                  />
                )}
              </View>
            </View>
            <View style={styles.stepSection}>
              <View style={styles.stepSectionHeader}>
                <Text style={styles.boldLabel}>{t('alerts.create.why')}</Text>
              </View>
              <View style={[styles.stepSectionBody, styles.rightPadded]}>
                <WhyStep
                  control={control}
                  name="whyItem"
                  whatItemId={whatItem?.id}
                  whatItemType={whatItemType}
                  t={t}
                />
              </View>
            </View>
            <View style={[styles.stepSection, styles.whenStepSection]}>
              <View style={styles.stepSectionHeader}>
                <Text style={styles.boldLabel}>{t('alerts.create.when')}</Text>
              </View>
              <View style={[styles.stepSectionBody, styles.rightPadded]}>
                <WhenStep
                  control={control}
                  whyItem={whyItem}
                  name="whenConfig"
                />
              </View>
            </View>
          </View>
          <View style={styles.commonSection}>
            <View style={styles.commonSectionItem}>
              <View style={styles.commonSectionItemTitle}>
                <Trans
                  i18nKey="alerts.create.doNotAlert"
                  style={styles.commonSectionItemTitle}
                  components={[
                    <Text style={styles.labelPart} children={undefined} />,
                    <Text style={styles.boldLabelPart} children={undefined} />,
                  ]}
                />
              </View>
              <DateRangePicker control={control} name="disabledBetween" />
            </View>
            <View style={styles.commonSectionItem}>
              <View style={styles.commonSectionItemTitle}>
                <Trans
                  i18nKey="alerts.create.promptRoutine"
                  style={styles.labelPart}
                  components={[
                    <Text style={styles.labelPart} children={undefined} />,
                    <Text style={styles.boldLabelPart} children={undefined} />,
                  ]}
                />
              </View>
              <PromptRoutineSelector
                whatItemId={whatItem?.id}
                whatItemType={whatItemType}
                whyItemId={whyItem?.id}
                control={control}
                name="promptWorkspaceRoutineItem"
                t={t}
              />
            </View>
            <View
              style={[
                styles.commonSectionItem,
                styles.removeZindex,
                styles.removeMarginBottom,
              ]}
            >
              <View style={styles.commonSectionItemTitle}>
                <Text style={styles.boldLabel}>
                  {t('alerts.create.alertName')}
                </Text>
              </View>
              <View style={styles.alertName}>
                <Text style={styles.alertNameText}>{watch('name')}</Text>
              </View>
            </View>
          </View>
        </View>
      </SaveFormBottom>
    </ScrollView>
  );
};

function getAlertName(whenConfig: any, whatItem: any, whyItem: any) {
  const whenNamePart = Object.values(whenConfig ?? {}).find(
    (value) => typeof value === 'number'
  );
  const nameParts = [whatItem?.label, whyItem?.label, whenNamePart].filter(
    Boolean
  );
  const name = nameParts.join(', ');
  return name;
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.primaryGrayBackground,
    maxHeight: 850,
    width: '100%',
  },
  stepSections: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 342,
    marginBottom: 36,
  },
  stepSection: {
    flexDirection: 'column',
    minWidth: 300,
    flex: 1,
    marginRight: 16,
  },
  whenStepSection: {
    marginRight: 0,
    minWidth: 530,
  },
  stepSectionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 42,
    marginBottom: 5,
  },
  boldLabel: {
    fontSize: 16,
    fontWeight: '600',
    color: Colors.blackText,
  },
  stepSectionBody: {
    height: 302,
    backgroundColor: Colors.white,
    borderRadius: 8,
    paddingTop: 10,
    paddingRight: 1,
    paddingLeft: 12,
    paddingBottom: 5,
    overflow: 'scroll',
  },
  rightPadded: {
    paddingRight: 16,
  },
  commonSection: {
    flexDirection: 'column',
    width: 541,
    marginBottom: 20,
  },
  commonSectionItem: {
    marginBottom: 20,
  },
  commonSectionItemTitle: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: 10,
  },
  labelPart: {
    fontSize: 16,
    fontWeight: '400',
    color: Colors.blackText,
  },
  boldLabelPart: {
    fontWeight: '600',
    fontSize: 16,
    color: Colors.blackText,
    marginLeft: 4,
    marginRight: 4,
  },
  alertName: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: 8,
    height: 52,
    backgroundColor: Colors.secondaryGrayHeader,
    paddingHorizontal: 20,
  },
  alertNameText: {
    fontSize: 16,
    fontWeight: '400',
    color: Colors.grayText,
  },
  removeZindex: {
    zIndex: -1,
  },
  removeMarginBottom: {
    marginBottom: 0,
  },
});
