import {
  TextQuestionConfig,
  TextResponseState,
  useTranslatedText,
} from '@gripp/shared-logic';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../../../../themes';
import { RoutineQuestionMessage, RoutineQuestionMessageProps } from '../types';

export const TextQuestionMessage: RoutineQuestionMessage<
  TextQuestionConfig,
  TextResponseState
> = (
  props: RoutineQuestionMessageProps<TextQuestionConfig, TextResponseState>
) => {
  const { t } = useTranslation();
  const { response } = props;
  const { originalText, translatedText, needsTranslation } = useTranslatedText(
    'value',
    response
  );
  const [text, setText] = useState(
    needsTranslation ? translatedText : originalText
  );

  return (
    <>
      <Text style={props.textStyle}>{text}</Text>
      {needsTranslation && (
        <View style={styles.translateButton}>
          <Pressable
            onPress={() => {
              if (text === originalText) {
                setText(translatedText);
              } else {
                setText(originalText);
              }
            }}
          >
            <Text style={styles.translationButton}>
              {text === originalText
                ? t('translation.seeTranslation')
                : t('translation.seeOriginal')}
            </Text>
          </Pressable>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  translateButton: {
    alignItems: 'flex-start',
    marginBottom: 4,
  },
  translationButton: {
    color: Colors.primaryDark,
    fontSize: 14,
    lineHeight: 16.8,
    marginTop: 8,
  },
});
