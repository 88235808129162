export const Colors = {
  primary: '#F47A20', // graphic/gutton/primary action
  primaryDark: '#B25000', //primary orange on gray background
  primaryWithAlpha: 'rgba(244, 122, 32, 0.1)', // hover style; this is when something is active and clicked

  white: '#fff',
  black: '#000',
  blackText: '#19191C', // used for text
  blackWithAlpha: 'rgba(0, 0, 0, 0.1)', // hover on the grids (web, activity list)
  blackInactive: 'rgba(0, 0, 0, 0.75)', // new issue modal background (overlay)
  blackOverlay: 'rgba(0, 0, 0, 0.3)', // used for dropdown overlay

  primaryGrayBackground: '#F2F1F6', // used in the background of the app
  grayText: '#6c6c70', // used for card text
  grayFill: '#8e8e93', //used for card info blocks & button
  graySpacer: '#D8D8DC', // used for horizontal spacer & right border
  grayDivider: '#AEAEB2', // used for card dividers
  grayButtonBorder: '#AEAEB2', // used for button borders
  grayDisabled: '#aeaeb2',
  primaryGrayHeader: '#D8D8DC', //
  secondaryGrayHeader: '#E9E9EF', // aka cellHover, also used in assets gray divider header
  unreadBackground: '#FFFAE6', // aka cell-selected
  chipBackground: '#FDE3D0',
  redBackground: '#FFE3DF',

  danger: '#ff3b30',
  warning: '#ffcc00',
  satisfactory: '#34c759',
  info: '#007aff',

  inactive: 'rgba(255, 59, 48, 0.35)',
  active: '#ecfdf3',
  orangeActive: '#FFE1C6',
  orangeActiveHovered: '#FFC795',
  orangeDark: '#883A00',

  secondaryContainer: 'transparent',
  tabActive: '#000', // replace with black
  tabInactive: '#6c6c70', // replace with grayText

  redText: '#C12219',

  // issue severities
  red: '#FF3B30',
  yellow: '#FFCC00',
  green: '#34C759',
  blue: '#007AFF',
  purple: '#7230FF',
  darkPink: '#C10097',

  // mentions list
  mentionName: '#242424',
  mentionEmail: '#616161',
};
