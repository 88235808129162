import {
  cacheKeys,
  useCustomMutation,
  useGrippContext,
} from '@gripp/shared-logic';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { AddToGroups } from '../../shared/components/groups/addToGroups';
import { ClearGroups } from './clearGroups';

export type AccountGroupsProps = {
  accountIds: string[];
  workspaceId: string;
};

export const AccountGroups = (props: AccountGroupsProps) => {
  const { mutateAsync: addAccountsToGroups, isPending: isUpdating } =
    useCustomMutation({
      query: ADD_ACCOUNTS_TO_GROUPS,
    });
  const queryClient = useQueryClient();
  const cacheKey = cacheKeys('account').all;
  const { logger } = useGrippContext();
  const { t } = useTranslation();
  const count = props.accountIds.length;

  const selectedLabel =
    count !== 1
      ? t('account.topBar.peopleSelected', {
          count: count,
        })
      : t('account.topBar.personSelected');
  const addToGroupLabel =
    count !== 1
      ? t('account.groups.addPeopleTo', {
          count: count,
        })
      : t('account.groups.addPersonTo');

  const handleSubmit = async (groupsToApply: any) => {
    const input = {
      groups: groupsToApply,
      accountIds: props.accountIds,
      workspaceId: props.workspaceId,
    };
    addAccountsToGroups(
      { input: input },
      {
        onSettled: async (data, error?: Error) => {
          if (error) {
            logger?.error(error, 'Failed to add accounts to groups');
          }
          await queryClient.invalidateQueries({ queryKey: cacheKey });
        },
      }
    );
  };

  const onCleared = async () => {
    await queryClient.invalidateQueries({ queryKey: cacheKey });
  };

  return (
    <View style={styles.container}>
      <AddToGroups
        entitySelectedLabel={selectedLabel}
        entityAddToGroupLabel={addToGroupLabel}
        entityIds={props.accountIds}
        workspaceId={props.workspaceId}
        onSubmit={handleSubmit}
        isUpdating={isUpdating}
      />
      <ClearGroups accountIds={props.accountIds} onSubmit={onCleared} />
    </View>
  );
};

const ADD_ACCOUNTS_TO_GROUPS = `
  mutation Account($input: AddAccountsToGroupsInput!) {
    account {
      addAccountsToGroups(input: $input) {        
        success
        message
      }
    }
  }
`;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
