import { RadioQuestionConfig, RadioState } from '@gripp/shared-logic';
import { useController } from 'react-hook-form';
import { ControlledRadioButtons } from '../../../../../components/forms';
import { useFormContext } from '../../../../forms';
import {
  RoutineQuestionExecutionForm,
  RoutineQuestionExecutionFormProps,
} from '../types';

export const RadioQuestionExecutionForm: RoutineQuestionExecutionForm<
  RadioQuestionConfig,
  RadioState
> = (
  props: RoutineQuestionExecutionFormProps<RadioQuestionConfig, RadioState>
) => {
  const fieldPrefix = `responses.${props.responseIndex}`;

  const { control } = useFormContext();
  const { field } = useController({
    control: control,
    name: `${fieldPrefix}.option`,
  });

  return (
    <ControlledRadioButtons
      control={control}
      name={field.name}
      options={props.config.options.map((option) => {
        return {
          value: option,
        };
      })}
    />
  );
};
