import { WorkspaceAsset } from '@gripp/shared-logic';
import { ReactNode } from 'react';
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { AssetDetails } from './assetDetails';
import { AssetHeaderImage } from './assetHeaderImage';

type AssetHeaderProps = {
  workspaceAsset: WorkspaceAsset;
  imgSize?: number;
  additionalHeaderBlock?: ReactNode;
  assetDetailsContainerStyle?: StyleProp<ViewStyle>;
};

export const AssetHeader = (props: AssetHeaderProps) => {
  const { workspaceAsset } = props;

  return (
    workspaceAsset && (
      <View style={styles.container}>
        <AssetHeaderImage
          assetCoverImage={workspaceAsset?.asset?.coverImage}
          imgSize={props.imgSize}
        />
        <View
          style={[
            styles.assetDetailsContainer,
            props.assetDetailsContainerStyle,
          ]}
        >
          <AssetDetails workspaceAsset={workspaceAsset} />
          {props.additionalHeaderBlock && props.additionalHeaderBlock}
        </View>
      </View>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    ...Platform.select({
      default: {
        marginTop: 10,
        marginBottom: 20,
        height: 56,
      },
      web: {
        flex: 1,
        margin: 0,
        height: '100%',
      },
    }),
  },
  assetDetailsContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
});
