import { StorageHandler } from './storage';
import { createContext, useContext } from 'react';
import { MediaPickerComponent, SignatureScreenComponent } from './media';
import { ILogger } from './logging';

export interface GrippContextData {
  storageHandler: StorageHandler;
  secureStorageHandler: StorageHandler;
  mediaPickerComponent?: MediaPickerComponent;
  signaturePadScreen?: SignatureScreenComponent;
  logger: ILogger;
}

export const GrippContext = createContext<GrippContextData | undefined>(
  undefined
);

export const useGrippContext = (): GrippContextData => {
  return useContext(GrippContext)!;
};
