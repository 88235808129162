import { Group } from '@gripp/shared-logic';
import { StyleSheet } from 'react-native';
import { GroupChip } from './groupChip';
import { GroupChipMenu } from './groupChipMenu';

export const getGroupColumn = (
  info: any,
  onPress: (groupId: string) => Promise<void>
) => {
  const groups = info.getValue() as Group[];

  if (groups && groups.length > 0) {
    const firstGroup = (
      <GroupChip
        group={groups[0]}
        additionalStyle={styles.firstGroupChip}
        onPress={onPress}
      />
    );
    if (groups.length === 1) {
      return firstGroup;
    } else {
      return (
        <>
          {firstGroup}
          <GroupChipMenu groups={groups} onPress={onPress} />
        </>
      );
    }
  }

  return <></>;
};

const styles = StyleSheet.create({
  firstGroupChip: {
    marginRight: 5,
    maxWidth: '70%',
  },
});
