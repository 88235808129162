import { MaterialCommunityIcons } from '@expo/vector-icons';
import { IssueType, useCreateIssueWithMessage } from '@gripp/shared-logic';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  KeyboardAvoidingView,
  Modal,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import { Button, Text, TextInput, useTheme } from 'react-native-paper';
import { useAutoFocusRef } from '../../../hooks';
import { Spacing } from '../../../styles';
import { Colors } from '../../../themes';
import { TopModalBar } from '../../modals/topModalBar';
import { RoutineButton } from './routineButton';

export type RequestRoutineModalProps = {
  assetId: string;
  workspaceId: string;
  isWeb?: boolean;
};

export const RequestRoutineModal = (props: RequestRoutineModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [routineIdea, setRoutineIdea] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showRequestRoutine, setShowRequestRoutine] = useState(false);

  const { mutateAsync } = useCreateIssueWithMessage();

  const elementFocusCallBack = useAutoFocusRef();

  const submit = async () => {
    setIsSubmitting(true);

    const input: any = {
      assetId: props.assetId,
      severity: 'blue',
      title: 'Routine Request',
      type: IssueType.Feedback,
      message: routineIdea,
      workspaceId: props.workspaceId,
    };

    await mutateAsync(
      { input: input },
      {
        onSettled: async () => {
          onClose();
          setIsSubmitting(false);
          setRoutineIdea('');
        },
      }
    );
  };

  const onClose = () => {
    setShowRequestRoutine(false);
  };

  const icon = (
    <MaterialCommunityIcons
      name={'note-plus'}
      size={22}
      style={styles.titleIcon}
    />
  );

  return (
    <>
      <RoutineButton
        onClick={() => {
          setShowRequestRoutine(true);
        }}
        text={t('routines.requestButton')}
        buttonStyle={styles.editRoutinesButton}
        buttonLabelStyle={styles.editRoutinesButtonLabel}
      />
      {props.isWeb ? (
        <Modal
          visible={showRequestRoutine}
          animationType="fade"
          style={{
            backfaceVisibility: 'hidden',
            backgroundColor: 'transparent',
          }}
          onRequestClose={onClose}
        >
          <View style={styles.webModalBackground}>
            <View style={styles.modalContainer}>
              <TopModalBar
                title={t('requestRoutine.header')}
                onCancel={onClose}
                showSave={false}
                additionalHeaderBlock={icon}
              />

              <ScrollView
                contentContainerStyle={[
                  styles.contentContainer,
                  { backgroundColor: Colors.primaryGrayBackground },
                ]}
                scrollEnabled={true}
                showsVerticalScrollIndicator={true}
              >
                <Text variant={'titleMedium'} style={styles.ideaTextHeader}>
                  {t('requestRoutine.instruction')}
                </Text>
                <TextInput
                  style={styles.textInput}
                  multiline
                  mode="outlined"
                  placeholder={t('requestRoutine.placeHolder')}
                  outlineStyle={styles.textOutline}
                  value={routineIdea}
                  onChangeText={setRoutineIdea}
                  contentStyle={styles.textInputContent}
                  ref={elementFocusCallBack}
                />
              </ScrollView>
              <View style={styles.bottomContainer}>
                <Button
                  disabled={!routineIdea || isSubmitting}
                  style={styles.applyButton}
                  buttonColor={Colors.primary}
                  labelStyle={{
                    fontSize: theme.fonts.bodyMedium.fontSize,
                    fontWeight: '600',
                  }}
                  mode="contained"
                  onPress={async () => await submit()}
                >
                  {t('requestRoutine.apply')}
                </Button>
              </View>
            </View>
          </View>
        </Modal>
      ) : (
        <Modal
          visible={showRequestRoutine}
          animationType="slide"
          presentationStyle="pageSheet"
          onRequestClose={onClose}
          style={styles.modalContainer}
        >
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : undefined}
            keyboardVerticalOffset={40}
            style={styles.modal}
          >
            <TopModalBar
              title={t('requestRoutine.header')}
              onCancel={onClose}
              showSave={false}
              additionalHeaderBlock={icon}
            />

            <ScrollView
              contentContainerStyle={[
                styles.contentContainer,
                { backgroundColor: Colors.primaryGrayBackground },
              ]}
              scrollEnabled={true}
              showsVerticalScrollIndicator={true}
            >
              <Text variant={'titleMedium'} style={styles.ideaTextHeader}>
                {t('requestRoutine.instruction')}
              </Text>
              <TextInput
                style={styles.textInput}
                multiline
                mode="outlined"
                placeholder={t('requestRoutine.placeHolder')}
                outlineStyle={styles.textOutline}
                value={routineIdea}
                onChangeText={setRoutineIdea}
                contentStyle={styles.textInputContent}
                ref={elementFocusCallBack}
              />
            </ScrollView>
            <View style={styles.bottomContainer}>
              <Button
                disabled={!routineIdea || isSubmitting}
                style={styles.applyButton}
                buttonColor={Colors.primary}
                labelStyle={{
                  fontSize: theme.fonts.bodyMedium.fontSize,
                  fontWeight: '600',
                }}
                mode="contained"
                onPress={async () => await submit()}
              >
                {t('requestRoutine.apply')}
              </Button>
            </View>
          </KeyboardAvoidingView>
        </Modal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  textInput: {
    height: 200,
    marginTop: 20,
  },
  textOutline: {
    borderWidth: 0,
  },
  textInputContent: {
    fontSize: 16,
  },
  webModalBackground: {
    backgroundColor: Colors.blackInactive,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    ...Platform.select({
      web: {
        width: 560,
        height: 650,
        borderRadius: 12,
        backgroundColor: Colors.primaryGrayBackground,
        overflow: 'hidden',
        backfaceVisibility: 'hidden',
      },
    }),
  },
  modal: {
    justifyContent: 'flex-start',
    backgroundColor: Colors.primaryGrayBackground,
    flex: 1,
    flexDirection: 'column',
  },
  contentContainer: {
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    marginVertical: Spacing.baseMargin.marginHorizontal,
    height: '80%',
  },
  bottomContainer: {
    bottom: 48,
    marginHorizontal: Spacing.baseMargin.marginHorizontal,
    flexDirection: 'row',
  },
  applyButton: {
    borderRadius: 8,
    height: 44,
    flex: 1,
  },
  ideaTextHeader: {
    fontSize: 16,
    fontWeight: '400',
    textAlign: 'center',
    lineHeight: 19,
  },
  titleIcon: {
    right: 4,
    color: Colors.blue,
  },
  editRoutinesButton: {
    borderColor: 'transparent',
  },
  editRoutinesButtonLabel: {
    color: Colors.primaryDark,
    lineHeight: 24,
  },
});
