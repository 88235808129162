import { getLanguage, isOnlyEmojis } from './utils';

export const useTranslatedText = (
  propertyName: string,
  containingObject: any
) => {
  const language = getLanguage();
  const originalText = containingObject && containingObject[propertyName];
  const translatedText = containingObject?.i18n?.[language]?.[propertyName];
  let needsTranslation =
    originalText?.length > 0 &&
    translatedText?.length > 0 &&
    !isOnlyEmojis(originalText) &&
    containingObject.i18n?.detectedLanguage !== language;

  // handle cases where original === translated
  if (needsTranslation) {
    const languages = Object.keys(containingObject.i18n);
    const translations = languages
      .map((language) => containingObject.i18n[language][propertyName])
      .filter((x) => x);

    if (
      translations.every(
        (translation) =>
          translation.toLowerCase() === originalText.toLowerCase()
      )
    ) {
      needsTranslation = false;
    }
  }

  const defaultText = needsTranslation ? translatedText : originalText;

  return {
    defaultText,
    originalText,
    translatedText,
    needsTranslation,
  };
};
