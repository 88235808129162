import { useTagByTagCode, useTagScan } from '@gripp/shared-logic';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AnonymousAsset } from '../anonymous/anonymousAsset';

export const Tag = () => {
  const { tagId } = useParams<{ tagId: string }>();
  const tagCode = tagId?.toUpperCase();
  const { tag } = useTagByTagCode(tagCode);

  const tagScan = useTagScan();

  useEffect(() => {
    if (tag) {
      tagScan(tag.id);
    }
  }, [tag]);

  return (
    tag && <AnonymousAsset asset={tag?.link?.linkData} tagCode={tagCode!} />
  );
};
