import { TextQuestionConfig, TextResponseState } from '@gripp/shared-logic';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledTextInput } from '../../../../../components/forms';
import { useFormContext } from '../../../../forms';
import {
  RoutineQuestionExecutionForm,
  RoutineQuestionExecutionFormProps,
} from '../types';

export const TextQuestionExecutionForm: RoutineQuestionExecutionForm<
  TextQuestionConfig,
  TextResponseState
> = (
  props: RoutineQuestionExecutionFormProps<
    TextQuestionConfig,
    TextResponseState
  >
) => {
  const { t } = useTranslation();
  const fieldPrefix = `responses.${props.responseIndex}`;
  const { control } = useFormContext();
  const { field } = useController({
    control: control,
    name: `${fieldPrefix}.value`,
  });

  return (
    <ControlledTextInput
      control={control}
      name={field.name}
      placeholder={t('routines.workflow.execution.optional')}
      textArea={props.config.multiline}
      outlinedTextInput={true}
    />
  );
};
