export const row = {
  flexDirection: 'row',
}

export const column = {
  flexDirection: 'column',
}

export const justifyCenter = {
  justifyContent: 'center',
}

export const justifyBetween = {
  justifyContent: 'space-between',
}

export const justifyEnd = {
  justifyContent: 'flex-end',
}

export const justifyStart = {
  justifyContent: 'flex-start',
}

export const alignCenter = {
  alignItems: 'center',
}

export const alignEnd = {
  alignItems: 'flex-end',
}

export const centeredRow = {
  ...row,
  ...justifyCenter,
  ...alignCenter,
}

export const container = {
  flex: 1,
};
