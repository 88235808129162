import { TagBatchStatus } from '@gripp/shared-logic';
import { ControlledSelect } from '@gripp/shared-ui';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { Button, Divider, Text } from 'react-native-paper';
import {
  FormComponent,
  FormComponentProps,
} from '../../shared/components/forms/mutationForm';

export const EditTagBatchForm: FormComponent = (props: FormComponentProps) => {
  const statuses = Object.keys(TagBatchStatus).map((key) => ({
    label: key,
    value: TagBatchStatus[key as keyof typeof TagBatchStatus].toString(),
  }));

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: props.defaultValues,
  });

  return (
    <View>
      <View>
        <Text variant="titleLarge">Edit Tag Batch</Text>
      </View>
      <Divider />
      <View>
        <Text variant="titleMedium">Batch: {props.defaultValues?.name}</Text>
        <ControlledSelect
          control={control}
          name="status"
          label="Status"
          errors={errors}
          items={statuses}
        />
        <Button onPress={handleSubmit(props.onSubmit)}>Update Batch</Button>
      </View>
    </View>
  );
};
