import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../themes/colors';

type DropdownFooterProps = {
  isDirty: boolean;
  canReset: () => boolean;
  onReset: () => void;
  onSubmit: (data: any) => Promise<void>;
};

export const DropdownFooter = (props: DropdownFooterProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Pressable style={styles.resetButtonContainer}>
        <Text
          style={[
            styles.resetLabel,
            {
              color: props.canReset()
                ? Colors.primaryDark
                : Colors.grayDisabled,
            },
          ]}
          disabled={!props.canReset()}
          onPress={props.onReset}
        >
          {t('dropdown.dropdownFooter.reset')}
        </Text>
      </Pressable>
      <Pressable
        disabled={!props.isDirty}
        style={[
          styles.applyButton,
          {
            backgroundColor: props.isDirty
              ? Colors.primary
              : Colors.grayDisabled,
          },
        ]}
        onPress={props.onSubmit}
      >
        <Text
          style={{
            fontSize: 16,
            fontWeight: '600',
            color: Colors.white,
            lineHeight: 18,
          }}
        >
          {t('dropdown.dropdownFooter.apply')}
        </Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingTop: 6,
    paddingBottom: 12,
  },
  resetButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    width: 60,
    maxWidth: 60,
    marginRight: 20,
  },
  resetLabel: {
    fontSize: 16,
    fontWeight: '500',
    letterSpacing: -0.25,
  },
  applyButton: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    height: 44,
    width: 176,
  },
});
