import { FindQueryVariables, useFindByQuery } from '../../graphql';
import { Media } from '../domain';
import { useEffect, useState } from 'react';

export const useFindMedia = (
  variables: FindQueryVariables
): Media[] | undefined => {
  const [media, setMedia] = useState<Media[] | undefined>(undefined);
  const { data } = useFindByQuery({
    modelName: 'media',
    query: FIND_MEDIA,
    variables,
    options: {
      enabled: !!variables.filter?.id?.in || !!variables.filter?.id?.eq,
    },
  });

  useEffect(() => {
    if (data) {
      setMedia(data.items);
    }
  }, [data]);

  return media;
};

const FIND_MEDIA = `
  query FindMedia($filter: MediaFilter, $orderBy: OrderByInput) {
    findMedia(filter: $filter, orderBy: $orderBy) {
      items {
        id
        name
        extension
        status
        type
      }
    }
  }
`;
