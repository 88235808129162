import {
  EnvConfig,
  LIST_MENTIONABLE_USERS,
  useCurrentWorkspace,
  useCustomQuery,
} from '@gripp/shared-logic';
import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import {
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import {
  MentionInput,
  MentionSuggestionsProps,
} from 'react-native-controlled-mentions';
import { Portal } from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { AccountAvatar } from '../accountAvatar';

type ConditionalWrapperProps = {
  children: ReactNode;
};

export type MentionInputFieldMobileProps = {
  inputFieldText: string;
  setInputFieldText: (text: string) => void;
  setIsMultiline: (value: boolean) => void;
  inputHeight: number;
  setInputHeight: (value: number) => void;
};

export const MentionsInputFieldMobile = (
  props: MentionInputFieldMobileProps
) => {
  const {
    inputFieldText,
    setInputFieldText,
    setIsMultiline,
    inputHeight,
    setInputHeight,
  } = props;

  const isAndroid = Platform.OS === 'android';
  const isWeb = Platform.OS === 'web';
  const { width } = useWindowDimensions();
  const workspace = useCurrentWorkspace();

  const [search, setSearch] = useState('');

  const { data: workspaceUsers } = useCustomQuery({
    query: LIST_MENTIONABLE_USERS,
    cacheKey: ['mentionableUsers', workspace?.id, search],
    variables: {
      filter: {
        workspaceId: workspace?.id,
        keyword: search,
      },
    },
    options: {
      enabled: !!workspace?.id,
    },
  });

  const messageInputRef = useRef(null);

  const handleContentSizeChange = useCallback((contentHeight: number) => {
    const { newInputHeight, contentHeightFloor } =
      getInputDynamicHeight(contentHeight);
    setInputHeight(newInputHeight);

    setIsMultiline(contentHeightFloor >= multilineThreshold);
  }, []);

  const mentionsContainerWidth = useMemo(() => {
    const adjustedMobileWidth =
      width - (imagePickerContainerWidth + inputMarginRight);

    return isWeb ? '100%' : adjustedMobileWidth;
  }, [width, isWeb]);

  const getInputDynamicHeight = useCallback((contentHeight: number) => {
    const contentHeightFloor = Math.floor(contentHeight);

    const newHeight =
      contentHeightFloor >= inputLineHeight
        ? contentHeightFloor
        : inputLineHeight;

    const newInputHeight =
      newHeight >= maxHeightInputContainer
        ? maxHeightInputContainer
        : newHeight + inputLineHeight;

    return { newInputHeight, contentHeightFloor };
  }, []);

  const MentionsWrapper: React.FC<ConditionalWrapperProps> = ({ children }) => {
    return isAndroid ? <Portal>{children}</Portal> : <>{children}</>;
  };

  const renderWorkspaceUsers: FC<MentionSuggestionsProps> = ({
    keyword,
    onSuggestionPress,
  }) => {
    if (keyword == null) {
      return null;
    }

    setSearch(keyword);

    return (
      <MentionsWrapper>
        <View
          style={[
            styles.mentionsContainer,
            {
              position: 'absolute',
              bottom: isAndroid ? inputHeight + 20 : inputHeight - 5,
              right: isAndroid ? inputMarginRight : null,
            },
          ]}
        >
          <ScrollView
            style={[
              styles.mentionsScrollViewContainer,
              { width: mentionsContainerWidth },
            ]}
            keyboardShouldPersistTaps={'always'}
            nestedScrollEnabled={true}
            onStartShouldSetResponder={(e) => true}
          >
            {workspaceUsers?.account?.listMentionableUsers?.items.map(
              (user) => (
                <Pressable
                  key={user.id}
                  onPress={() => {
                    onSuggestionPress({
                      id: user?.id,
                      name: `${user?.name?.first} ${user?.name?.last}`,
                    });
                  }}
                  style={[styles.mentionItemRow]}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      height: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <AccountAvatar
                      avatar={user.avatar}
                      mediaUrl={EnvConfig.mediaUrl}
                      size={36}
                    />

                    <View
                      style={{
                        marginLeft: 16,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: '400',
                          letterSpacing: -0.25,
                          color: Colors.mentionName,
                        }}
                      >
                        {user.name?.first} {user.name?.last}
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: '400',
                          letterSpacing: 0.2,
                          color: Colors.mentionEmail,
                        }}
                      >
                        {user.email}
                      </Text>
                    </View>
                  </View>
                </Pressable>
              )
            )}
          </ScrollView>
        </View>
      </MentionsWrapper>
    );
  };

  return (
    <MentionInput
      inputRef={messageInputRef}
      value={inputFieldText}
      onChange={setInputFieldText}
      placeholder="Aa"
      placeholderTextColor={Colors.grayText}
      autoFocus={false}
      onContentSizeChange={(e) =>
        handleContentSizeChange(e.nativeEvent.contentSize.height)
      }
      style={[styles.messageInput]}
      multiline={true}
      numberOfLines={3}
      cursorColor={Colors.primaryDark}
      containerStyle={styles.mentionInputField}
      partTypes={[
        {
          trigger: '@',
          renderSuggestions: renderWorkspaceUsers,
          textStyle: styles.mentionText,
          isInsertSpaceAfterMention: true,
        },
      ]}
    />
  );
};

const imagePickerContainerWidth = 68;
const inputMarginRight = 20;
const inputLineHeight = 18;
const maxHeightInputContainer = 72;
const multilineThreshold = 36;

const styles = StyleSheet.create({
  mentionInputField: {
    flex: 1,
    width: '100%',
  },
  mentionText: {
    color: Colors.primaryDark,
    fontWeight: '400',
  },
  messageInput: {
    width: '100%',
    paddingLeft: 12,
    paddingTop: 0,
    paddingRight: 0,
    fontSize: 16,
    textAlignVertical: 'center',
    textAlign: 'left',
    ...Platform.select({
      ios: {
        bottom: -4,
      },
      web: {
        paddingTop: 12,
        paddingBottom: 12,
        outlineStyle: 'none',
        height: 67,
      },
    }),
  },
  mentionsContainer: {
    flex: 1,
    maxHeight: 216,
    minHeight: 56,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.35,
    shadowRadius: 5,
    elevation: 10,
    borderRadius: 4,
    backgroundColor: Colors.white,
    zIndex: 100,
  },
  mentionsScrollViewContainer: {
    flex: 1,
    borderRadius: 6,
    maxHeight: 216,
    minHeight: 56,
    backgroundColor: Colors.white,
  },
  mentionItemRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 56,
    overflow: 'hidden',
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
});
