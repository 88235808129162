import { useWorkspaceRoutine } from '@gripp/shared-logic';
import { Colors } from '@gripp/shared-ui';
import { StyleSheet, View } from 'react-native';
import { useParams } from 'react-router-dom';
import RoutineHeader from './routineHeader';

export const Routine = () => {
  const { routineId } = useParams();
  const workspaceRoutine = useWorkspaceRoutine(routineId as string);

  return (
    workspaceRoutine && (
      <View style={styles.container}>
        <RoutineHeader workspaceRoutine={workspaceRoutine} />
      </View>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    borderRadius: 12,
    backgroundColor: Colors.white,
  },
});

export default Routine;
