import { PermissionValues, useCurrentAccount } from '@gripp/shared-logic';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Checkbox, Text } from 'react-native-paper';
import { useFormContext } from '../forms';
import { CheckBoxSelector } from './checkBoxSelector';
import { filterStyles as styles } from './filterStyle';

type ColumnSelectionProps = {
  baseColumns: string[];
  adminColumns?: string[];
  maxColumns?: number;
  i18nPrefix?: string;
  children?: React.ReactNode;
};

export const ColumnSelection = (props: ColumnSelectionProps) => {
  const { account } = useCurrentAccount();
  const isGlobalAdmin = useMemo(
    () => account && account.hasPermission(PermissionValues.globalAdmin),
    [account]
  );
  const { t } = useTranslation();

  const {
    setValue,
    watch,
    formState: { isDirty },
  } = useFormContext();
  const selectedColumns = watch('columns');

  const onColumnChange = (column: string) => {
    if (selectedColumns?.includes(column)) {
      setValue(
        'columns',
        selectedColumns.filter((c) => c !== column),
        { shouldDirty: true, shouldTouch: true, shouldValidate: true }
      );
    } else {
      if (
        props.maxColumns &&
        (selectedColumns?.length || 0) > props.maxColumns
      ) {
        return;
      }
      setValue('columns', [...(selectedColumns || []), column], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  };

  return (
    <View>
      <Text style={styles.filterRowHeader}>
        {t(`${props.i18nPrefix}.columnSelection`, { count: props.maxColumns })}
      </Text>
      <Checkbox.Item
        status="checked"
        labelStyle={styles.checkboxLabel}
        style={[styles.rowContainer]}
        position="trailing"
        label={t(`${props.i18nPrefix}.columns.name`)}
        disabled={true}
        mode="android"
      />
      {props.baseColumns.map((column) => (
        <CheckBoxSelector
          key={column}
          option={column}
          options={selectedColumns}
          onChange={onColumnChange}
          max={props.maxColumns}
          label={t(`${props.i18nPrefix}.columns.${column}`)}
        />
      ))}
      {isGlobalAdmin &&
        props.adminColumns?.map((column) => (
          <CheckBoxSelector
            key={column}
            option={column}
            options={selectedColumns}
            onChange={onColumnChange}
            max={props.maxColumns}
            label={t(`${props.i18nPrefix}.columns.${column}`)}
          />
        ))}
      {props.children}
    </View>
  );
};
