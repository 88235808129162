import { useQueryClient } from '@tanstack/react-query';
import { Graphback } from '../../graphql';
import { useStorageKeys } from '../../storage';
import { useSetCurrentWorkspace } from './useSetCurrentWorkspace';
import { useGrippContext } from '../../grippContext';

export const useLogout = () => {
  const { secureStorageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();
  const queryClient = useQueryClient();
  const { mutateAsync: setCurrentWorkspace } = useSetCurrentWorkspace();

  return async () => {
    await secureStorageHandler.deleteItem(storageKeys.accessToken);
    await queryClient.invalidateQueries();
    await queryClient.resetQueries();
    await setCurrentWorkspace(undefined);
    Graphback.dispose();
  };
};
