import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Colors } from '../../themes';

interface AssetMenuProps {
  onReportIssue: () => void;
  onAddNote: () => void;
  onAddAsset: () => void;
}

export const AssetMenu = (props: AssetMenuProps) => {
  const [reportIsHovered, setReportIsHovered] = useState(false);
  const [noteIsHovered, setNoteIsHovered] = useState(false);
  const [addAssetIsHovered, setAddAssetIsHovered] = useState(false);
  const { t } = useTranslation();

  return (
    <View style={[styles.menuItemStyle, styles.groupedButtonContainer]}>
      <Pressable
        onHoverIn={() => setReportIsHovered(true)}
        onHoverOut={() => setReportIsHovered(false)}
        style={[styles.menuButton, reportIsHovered && styles.hoveredStyle]}
        onPress={props.onReportIssue}
      >
        <MaterialCommunityIcons name={'flag'} style={styles.menuButtonIcon} />
        <Text style={styles.menuButtonLabel}>{t('plusMenu.report')}</Text>
      </Pressable>

      <Pressable
        onHoverIn={() => setNoteIsHovered(true)}
        onHoverOut={() => setNoteIsHovered(false)}
        style={[styles.menuButton, noteIsHovered && styles.hoveredStyle]}
        onPress={props.onAddNote}
      >
        <MaterialCommunityIcons
          name={'note-plus'}
          style={styles.menuButtonIcon}
        />
        <Text style={styles.menuButtonLabel}>{t('plusMenu.note')}</Text>
      </Pressable>

      <Pressable
        onHoverIn={() => setAddAssetIsHovered(true)}
        onHoverOut={() => setAddAssetIsHovered(false)}
        style={[
          styles.menuButton,
          addAssetIsHovered && styles.hoveredStyle,
          { borderBottomWidth: 0 },
        ]}
        onPress={props.onAddAsset}
      >
        <MaterialCommunityIcons name={'plus'} style={[styles.menuButtonIcon]} />
        <Text style={styles.menuButtonLabel}>{t('plusMenu.add-asset')}</Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  menuItemStyle: {
    backgroundColor: Colors.white,
    borderRadius: 12,
  },
  groupedButtonContainer: {
    width: '100%',
    height: 192,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  hoveredStyle: {
    backgroundColor: Colors.blackWithAlpha,
  },
  menuButton: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 0.25,
    borderBottomColor: Colors.blackInactive,
    cursor: 'pointer',
  },
  menuButtonIcon: {
    color: Colors.primary,
    fontSize: 24,
    marginRight: 6,
  },
  menuButtonLabel: {
    fontSize: 18,
    color: Colors.primary,
    fontWeight: '500',
  },
});

export default AssetMenu;
