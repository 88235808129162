import {
  AssetRoutineState,
  RoutineState,
  WorkflowQuestionConfig,
  WorkspaceAsset,
} from '@gripp/shared-logic';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { RoutineQuestionModules } from '../questions/routineQuestionModules';
import { styles } from './styles';

type WorkflowExecutionQuestionProps = {
  config: WorkflowQuestionConfig;
  questionIndex: number;
  responseIndex: number;
  workspaceAsset: WorkspaceAsset;
  toggleProcessing?: (value: boolean) => void;
  currentState: AssetRoutineState<RoutineState> | undefined;
};

export const WorkflowExecutionQuestion = (
  props: WorkflowExecutionQuestionProps
) => {
  const ExecutionForm = RoutineQuestionModules[props.config.type].executionForm;

  return (
    <View style={styles.questionContainer}>
      <View style={styles.instructionsContainer}>
        <Text style={styles.instructionsText}>
          {props.questionIndex + 1}. {props.config.instructions}
        </Text>
      </View>
      <ExecutionForm
        config={props.config}
        responseIndex={props.responseIndex}
        workspaceAsset={props.workspaceAsset}
        toggleProcessing={props.toggleProcessing}
        currentState={props.currentState}
      />
    </View>
  );
};
