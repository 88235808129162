import { Colors } from '@gripp/shared-ui';
import { StyleSheet } from 'react-native';

export const menuStyles = StyleSheet.create({
  menuContainer: {
    width: 254,
  },
  white: {
    backgroundColor: Colors.white,
  },
  menuContent: {
    top: 30,
    right: 20,
    paddingHorizontal: 8,
    paddingVertical: 24,
    rowGap: 20,
  },
  menuItem: {
    height: 24,
    fontSize: 16,
    fontWeight: 500,
  },
});
