import { QuickConfirmConfig, QuickConfirmState } from '@gripp/shared-logic';
import { FC } from 'react';
import { RoutineButton } from '../components/routineButton';
import { RoutineButtonProps } from '../types';
import { QuickConfirmComponentProps } from './types';

interface QuickConfrimButtonsProps
  extends QuickConfirmComponentProps,
    RoutineButtonProps<QuickConfirmConfig, QuickConfirmState> {}

export const QuickConfrimButtons: FC<QuickConfrimButtonsProps> = (
  props: QuickConfrimButtonsProps
) => {
  return (
    <RoutineButton
      text={props.config.routineButtonText}
      onClick={props.onClick}
      buttonStyle={props.styleOverrides?.buttonStyle}
      labelStyle={props.styleOverrides?.labelStyle}
      buttonIcon={props.styleOverrides?.buttonIcon}
      buttonIconStyle={props.styleOverrides?.buttonIconStyle}
    />
  );
};
