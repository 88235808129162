import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Group, useCurrentAccount, useGroups } from '@gripp/shared-logic';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Pressable, View } from 'react-native';
import { Checkbox, Divider, Text } from 'react-native-paper';
import { preferencesStyles } from './styles';
import { NotificationSectionType, notificationsSection } from './utils';

type NotificationPreferencesProps = {
  control: Control<any>;
  disabled?: boolean;
};

export const NotificationPreferences = ({
  control,
  disabled,
}: NotificationPreferencesProps) => {
  const { t } = useTranslation();
  const { account } = useCurrentAccount();
  const { groups } = useGroups(account?.workspace?.id);

  return (
    <View style={preferencesStyles.contentContainer}>
      <View>
        <Text style={preferencesStyles.heading}>
          {t('preferences.notifications.notificationTitle')}
        </Text>
        <View style={preferencesStyles.contentFieldForm}>
          {notificationsSection.map((notification, index) => (
            <NotificationItem
              key={notification.value}
              {...notification}
              name={getName(notification.name)}
              control={control}
              disabled={disabled}
              isLast={notificationsSection.length - 1 === index}
            />
          ))}
        </View>
      </View>
      {account && account.workspace && groups && groups.length > 0 && (
        <View>
          <Text style={preferencesStyles.heading}>
            {t('preferences.notifications.groupNotificationsTitle')}
          </Text>
          <View style={preferencesStyles.contentFieldForm}>
            {groups.map((group, index) => {
              return (
                <NotificationGroupItem
                  key={group.id}
                  {...group}
                  group={group}
                  control={control}
                  disabled={disabled}
                  isLast={groups.length - 1 === index}
                />
              );
            })}
          </View>
        </View>
      )}
    </View>
  );
};

const getName = (name: string) => `notificationPreferences.${name}`;

type PreferenceProps = NotificationSectionType & {
  name: string;
  control: Control<any>;
  disabled?: boolean;
  isLast: boolean;
};

const NotificationItem = (props: PreferenceProps) => {
  return (
    <View style={preferencesStyles.checkbox}>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field: { onChange, value } }) => (
          <CheckboxPressable {...props} onChange={onChange} valueForm={value} />
        )}
      />
    </View>
  );
};

type GroupPreferenceProps = {
  group: Group;
  control: Control<any>;
  disabled?: boolean;
  isLast: boolean;
};

const NotificationGroupItem = ({
  group,
  control,
  disabled,
  isLast,
}: GroupPreferenceProps) => {
  return (
    <View style={preferencesStyles.checkbox}>
      <Controller
        name={`notificationPreferences.groupIds`}
        control={control}
        render={({ field: { onChange, value = [] } }) => {
          const isChecked = value?.includes(group.id);
          return (
            <>
              <Pressable
                style={preferencesStyles.checkboxPressable}
                disabled={disabled}
                onPress={() => {
                  const updatedValue = value?.includes(group.id)
                    ? value.filter((id: string) => id !== group.id)
                    : [...(value || []), group.id];

                  onChange(updatedValue);
                }}
              >
                <View style={preferencesStyles.checkboxPressableIconLabel}>
                  <Text style={preferencesStyles.label}>{group.name}</Text>
                </View>
                <Checkbox.Item
                  label=""
                  position="trailing"
                  status={isChecked ? 'checked' : 'unchecked'}
                  mode="android"
                  style={preferencesStyles.checkboxPressableItem}
                />
              </Pressable>
              {!isLast && <Divider style={preferencesStyles.divider} />}
            </>
          );
        }}
      />
      {!isLast && <Divider style={preferencesStyles.divider} />}
    </View>
  );
};

type CheckboxPressableProps = PreferenceProps & {
  onChange: (...event: any[]) => void;
  valueForm: any;
};

const CheckboxPressable = (props: CheckboxPressableProps) => {
  const { t } = useTranslation();
  const checked = Array.isArray(props.valueForm)
    ? props.valueForm?.includes(props.value)
    : props.valueForm;
  return (
    <>
      <Pressable
        style={preferencesStyles.checkboxPressable}
        disabled={props.disabled}
        onPress={() => {
          if (!Array.isArray(props.valueForm)) {
            props.onChange(!props.valueForm);
            return;
          }

          const newValue = checked
            ? props.valueForm.filter((v: string) => v !== props.value)
            : [...props.valueForm, props.value];

          props.onChange(newValue);
        }}
      >
        <View style={preferencesStyles.checkboxPressableIconLabel}>
          <View
            style={[
              preferencesStyles.severityIcon,
              { backgroundColor: props.backgroundColor },
            ]}
          >
            <MaterialCommunityIcons
              name={props.icon}
              size={props.sizeIcon}
              color={props.color}
            />
          </View>
          <Text style={preferencesStyles.label}>{t(props.labelKey)}</Text>
        </View>
        <Checkbox.Item
          label=""
          position="trailing"
          status={checked ? 'checked' : 'unchecked'}
          mode="android"
          style={preferencesStyles.checkboxPressableItem}
        />
      </Pressable>
      {!props.isLast && <Divider style={preferencesStyles.divider} />}
    </>
  );
};
