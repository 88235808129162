import { StyleSheet, View } from 'react-native';
import { Outlet } from 'react-router-dom';
import { AlertsList } from './alertsList';

type AlertsFoldProps = {
  onAlertSelected: (alertId: string) => void;
  maxHeight: number;
};

export const AlertsFold = (props: AlertsFoldProps) => {
  const heightStyle = { maxHeight: props.maxHeight };

  return (
    <View style={[heightStyle, styles.pageContainer]}>
      <View style={styles.foldContainer}>
        <View style={{ width: '40%' }}>
          <AlertsList
            onAlertSelected={props.onAlertSelected}
            listMaxHeight={props.maxHeight}
          />
        </View>
        <View style={styles.gutter} />
        <Outlet />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
  },
  foldContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  gutter: {
    width: 24,
  },
});
