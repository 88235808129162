import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Colors } from '../../themes';

interface InviteButtonProps {
  buttonOnPress: () => void;
}

export const InviteButton = ({ buttonOnPress }: InviteButtonProps) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <View style={[styles.buttonContainer]}>
      <Pressable
        onHoverIn={() => setIsHovered(true)}
        onHoverOut={() => setIsHovered(false)}
        style={[styles.inviteButton, isHovered && styles.hoveredStyle]}
        onPress={buttonOnPress}
      >
        <MaterialCommunityIcons
          name="account-plus-outline"
          size={24}
          color={Colors.primary}
          style={{ marginRight: 8 }}
        />
        <Text style={[styles.label, styles.inviteLabel]}>
          {t('plusMenu.invite')}
        </Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    width: 200,
    height: 60,
    borderRadius: 12,
    backgroundColor: Colors.white,
    marginBottom: 16,
  },
  hoveredStyle: {
    backgroundColor: Colors.blackWithAlpha,
  },
  inviteButton: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    fontSize: 18,
    fontWeight: '500',
  },
  inviteLabel: {
    color: Colors.primary,
  },
});

export default InviteButton;
