import {
  GlobalAdminGroupColumns,
  GroupFilterBaseColumns,
  GroupFilterModel,
  useGroupListFilter,
  useSetGroupListFilter,
} from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ScrollView, View } from 'react-native';
import { array, string, object as yupObject } from 'yup';
import { FormContext } from '../forms';
import { DropdownFooter } from '../modals/dropdownFooter';
import { ColumnSelection } from './columnSelection';
import { FilterType } from './filterButton';
import { filterStyles as styles } from './filterStyle';

interface GroupPageFilterProps {
  closeFilter: () => void;
  type: FilterType;
}

export const GroupPageFilter = ({
  closeFilter,
  type,
}: GroupPageFilterProps) => {
  const { data: groupFilter } = useGroupListFilter();

  const { mutateAsync: setGroupListFilter } = useSetGroupListFilter();

  const formSchema = yupObject({
    ...{ keyword: string().optional().nullable() },
    columns: array().of(string().optional().nullable()).optional().nullable(),
    groups: array().of(string().optional().nullable()).optional().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    values: {
      columns: groupFilter?.columns,
      keyword: groupFilter?.keyword || '',
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    getValues,
    watch,
  } = formMethods;
  const selectedColumns = watch('columns');

  const isColumnsFilter = type === FilterType.GROUPCOLUMNS;

  const resetForm = useCallback(() => {
    reset({
      ...selectedColumns,
      ...(isColumnsFilter && { columns: new GroupFilterModel().columns }),
    });
  }, [reset, isColumnsFilter, selectedColumns]);

  const onReset = async () => {
    const updatedFields = {
      ...(isColumnsFilter && { columns: new GroupFilterModel().columns }),
    };

    resetForm();

    const mergedFilters = { ...groupFilter, ...updatedFields };
    await applyFilter(new GroupFilterModel(mergedFilters));
  };

  const onSubmit = async (data: any) => {
    if (!data?.keyword.length) {
      delete data.keyword;
    }
    await applyFilter(new GroupFilterModel(data));
  };

  const applyFilter = async (filterModel: any) => {
    await Promise.all([setGroupListFilter(filterModel)]);

    closeFilter();
  };

  const canReset = () => {
    return !Object.values(getValues()).every((value) => value === '');
  };

  useEffect(() => {
    if (!groupFilter) {
      resetForm();
    }
  }, [groupFilter, resetForm]);

  const renderStandardData = () => {
    return (
      <ColumnSelection
        baseColumns={GroupFilterBaseColumns}
        adminColumns={GlobalAdminGroupColumns}
        i18nPrefix="groups.filter"
      ></ColumnSelection>
    );
  };

  return (
    <FormContext.Provider value={formMethods}>
      <View style={styles.container}>
        <View style={[styles.scrollViewContainer, { height: 416 }]}>
          <ScrollView
            contentContainerStyle={styles.scrollViewContent}
            scrollEnabled={true}
            showsVerticalScrollIndicator={true}
          >
            {isColumnsFilter && renderStandardData()}
          </ScrollView>
        </View>

        <DropdownFooter
          isDirty={isDirty}
          onSubmit={handleSubmit(onSubmit)}
          canReset={canReset}
          onReset={onReset}
        />
      </View>
    </FormContext.Provider>
  );
};
