import { changeLanguage } from '@gripp/i18n';
import {
  Account,
  useCurrentAccount,
  useLoadAccountPreferences,
  useUpdateAccountPreferences,
} from '@gripp/shared-logic';
import {
  PreferencesForm as PreferencesFormBase,
  PreferencesFormSchema,
  Spacing,
} from '@gripp/shared-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button } from 'react-native-paper';

export const Preferences = () => {
  const { account } = useCurrentAccount();
  const preferences = useLoadAccountPreferences({
    account,
  });

  return (
    preferences &&
    account && (
      <ScrollView style={[styles.container]}>
        <PreferencesForm account={account} preferences={preferences} />
      </ScrollView>
    )
  );
};

type PreferencesFormProps = {
  account: Account;
  preferences: any;
};

const PreferencesForm = (props: PreferencesFormProps) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: props.preferences,
    resolver: yupResolver(PreferencesFormSchema),
    mode: 'onChange',
  });

  const { updatePreferences, isUpdating, clearCache } =
    useUpdateAccountPreferences();

  const onSubmit = async (formData: any) => {
    const input = {
      id: props.account.id,
      preferences: formData,
    };

    await updatePreferences(
      { input },
      {
        onSettled: async (data: any) => {
          await clearCache();
          await changeLanguage(data.preferences.preferredLanguage);
          reset(data.preferences);
        },
      }
    );
  };

  return (
    <View style={styles.formContainer}>
      <View style={styles.form}>
        <PreferencesFormBase control={control} isUpdating={isUpdating} />
      </View>
      <View>
        <Button
          onPress={handleSubmit(onSubmit)}
          disabled={!isDirty || !isValid}
          loading={isUpdating}
          mode="contained"
          style={styles.saveBtn}
        >
          {t('saveChanges')}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  formContainer: {
    flex: 1,
    flexDirection: 'column',
    marginBottom: Spacing.baseMargin.base,
  },
  form: {
    flex: 1,
    width: '30%',
    minWidth: 437,
  },
  saveBtn: {
    width: 150,
    borderRadius: 12,
  },
});
