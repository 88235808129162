import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useEffect, useState } from 'react';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { Pressable, StyleSheet, View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { StyledTextInput } from './controlledTextInput';

type ControlledTextArrayInputProps = {
  label: string;
  addMoreLabel: string;
  name: string;
  control: Control;
  defaultValues?: string[];
  showResponseExceptions?: boolean;
  defaultExceptionValues?: string[];
  exceptionsPrefix?: string;
};

export const ControlledTextArrayInput = (
  props: ControlledTextArrayInputProps
) => {
  const { append, remove } = useFieldArray({
    name: props.name,
    control: props.control,
  });

  const values = useWatch({
    name: props.name,
    control: props.control,
  });

  const exceptionsFieldName = props.exceptionsPrefix
    ? `${props.exceptionsPrefix}.responseExceptions`
    : 'responseExceptions';

  const exceptionValues = useWatch({
    name: props.showResponseExceptions ? exceptionsFieldName : '',
    control: props.control,
  });

  const { append: appendException, remove: removeException } = useFieldArray({
    name: props.showResponseExceptions ? exceptionsFieldName : '',
    control: props.control,
  });

  const [value, setValue] = useState('');

  useEffect(() => {
    const defaultValues = props.defaultValues ?? [];
    const defaultExceptionValues = props.defaultExceptionValues ?? [];

    defaultValues.forEach((value) => {
      if (!values || !values.includes(value)) {
        append(value);
      }
    });
    defaultExceptionValues.forEach((value) => {
      if (!exceptionValues || !exceptionValues.includes(value)) {
        appendException(value);
      }
    });
  }, []);

  const onDelete = (item: string) => {
    remove(values.indexOf(item));
    if (props.showResponseExceptions && !exceptionValues?.includes(item)) {
      removeException(exceptionValues.indexOf(item));
    }
  };

  const onAdd = () => {
    append(value);
    setValue('');
  };

  const onToggleException = (item: string) => {
    if (!props.showResponseExceptions) return;

    if (exceptionValues && exceptionValues.includes(item)) {
      removeException(exceptionValues.indexOf(item));
    } else {
      appendException(item);
    }
  };

  return (
    <View>
      <View>
        <Text style={styles.label}>{props.label}</Text>
      </View>
      <StyledTextInput
        onChangeText={setValue}
        onBlur={() => {}}
        value={value}
      />
      <View style={styles.optionsContainer}>
        <View style={styles.chipBox}>
          {values &&
            values.map((value: string) => (
              <ChoiceChip
                key={value}
                onClick={() => onToggleException(value)}
                onDelete={() => onDelete(value)}
                value={value}
                exceptionValues={exceptionValues}
              />
              /*
            <Chip
              key={value}
              style={styles.chip}
              textStyle={styles.chipText}
              onPress={() => onDelete(value)}
            >
              {value}
              <MaterialCommunityIcons name="close" size={18} />
            </Chip>
            */
            ))}
        </View>
        <Pressable>
          <Button
            disabled={!value || (values && values.some((v) => v === value))}
            style={[styles.button]}
            buttonColor={Colors.primary}
            labelStyle={styles.labelStyle}
            mode="contained"
            onPress={onAdd}
          >
            {props.addMoreLabel}
          </Button>
        </Pressable>
      </View>
    </View>
  );
};

type ChoiceChipProps = {
  value: string;
  exceptionValues?: string[];
  onClick: () => void;
  onDelete: () => void;
};

const ChoiceChip = (props: ChoiceChipProps) => {
  const [chipStyle, setChipStyle] = useState(styles.chipGreen);

  useEffect(() => {
    if (props.exceptionValues?.includes(props.value)) {
      setChipStyle(styles.chipRed);
    } else {
      setChipStyle(styles.chipGreen);
    }
  }, [props.exceptionValues, props.value]);

  return (
    <View style={styles.chipContainer}>
      <Pressable onPress={props.onClick} style={[styles.chipLeft, chipStyle]}>
        <Text style={styles.chipText}>{props.value}</Text>
      </Pressable>
      <Pressable onPress={props.onDelete} style={[styles.chipRight, chipStyle]}>
        <MaterialCommunityIcons name="close" size={18} />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  optionsContainer: {
    width: 500,
    paddingTop: 20,
  },
  label: {
    fontSize: 16,
    lineHeight: 16,
    color: Colors.black,
    marginBottom: 10,
    fontWeight: '700',
  },
  button: {
    borderRadius: 8,
    width: '100%',
    maxWidth: 156,
    height: 44,
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  labelStyle: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.white,
  },
  chipBox: {
    paddingBottom: 20,
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flexDirection: 'row',
    rowGap: 8,
    columnGap: 4,
    maxHeight: 82,
    overflowY: 'auto',
    alignContent: 'flex-start',
  },
  chipContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  chipText: {
    fontSize: 14,
    fontWeight: '500',
    display: 'flex',
    lineHeight: 18,
    marginLeft: 8,
    marginTop: 0,
    marginBottom: 0,
    cursor: 'pointer',
    columnGap: 4,
    marginRight: 8,
  },
  chipLeft: {
    height: 20,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 0,
  },
  chipRight: {
    height: 20,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 0,
  },
  chipGreen: {
    backgroundColor: Colors.green,
  },
  chipRed: {
    backgroundColor: Colors.red,
  },
});
