import {
  WorkflowPageConfig,
  WorkflowRoutineConfig,
  newGUID,
} from '@gripp/shared-logic';
import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { View } from 'react-native';
import { object as YupObject, array, string } from 'yup';
import { RoutineAdminForm, RoutineAdminFormProps } from '../../types';
import { WorkflowAdminPage } from './workflowAdminPage';

export const WorkflowAdminSchema = {
  pages: array()
    .of(
      YupObject({
        instructions: string(),
        questions: array()
          .of(
            YupObject({
              instructions: string().required(),
            })
          )
          .required()
          .min(1),
      })
    )
    .required()
    .min(1),
};

export const WorkflowAdminForm: RoutineAdminForm<WorkflowRoutineConfig> = (
  props: RoutineAdminFormProps<WorkflowRoutineConfig>
) => {
  const { control } = props;
  const { fields, append } = useFieldArray({
    control,
    name: 'pages',
  });
  const DefaultPage: WorkflowPageConfig = {
    id: newGUID(),
    name: 'Page 1',
    order: 1,
    instructions: '',
    questions: [],
  };

  //load initial pages
  useEffect(() => {
    if (fields.length > 0) return;
    append(DefaultPage);
  }, []);

  return (
    <View>
      {fields.map((field, index) => (
        <WorkflowAdminPage
          key={`page_${field.id}`}
          index={index}
          page={field as WorkflowPageConfig}
          control={control}
        />
      ))}
    </View>
  );
};
