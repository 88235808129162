{
  "accountPage": {
    "title": "Account",
    "header": {
      "accountTypes": "Account types",
      "newUsersSetup": "New users will be added as"
    },
    "types": {
      "general": "General",
      "admin": "Admin",
      "restricted": "Restricted",
      "anonymous": "Anonymous"
    },
    "table": {
      "headers": {
        "accountType": "Account Type",
        "accessActivity": "Access to Activity?",
        "accessSettings": "Access to Settings?"
      },
      "columns": {
        "restrictedToActivitiesNote": "Limited to shared Assets & Groups",
        "generalToSettingsNote": "Limited to Assets & People"
      }
    },
    "info": {
      "header": "Account Types explained",
      "admin": "Ideal for workplace owners, senior managers, and office administrators.",
      "general": "Ideal for full-time staff and employees. (Our recommended default.)",
      "restricted": "Ideal for known visitors, vendors, part-time workers, and contractors who require partial or limited access.",
      "anonymous": "For individuals who have not signed up with Gripp. THey can only submit Reports, Notes or Routines based on what they scan."
    },
    "menu": {
      "accountPage": "Account"
    }
  },
  "account": {
    "allWorkspaces": "All Workspaces",
    "create": {
      "button": "Add User",
      "form": {
        "email": "Email",
        "firstName": "First Name",
        "lastName": "Last Name",
        "role": "Role",
        "workspace": "Workspace"
      },
      "validation": {
        "emailRequired": "Email is required",
        "role": "Role is required",
        "validEmail": "Please enter a valid email"
      }
    },
    "filter": {
      "apply": "Apply",
      "columnSelection": "Columns (max {{count}})",
      "columns": {
        "createdAt": "Date Joined",
        "email": "Email",
        "groups": "Groups",
        "jobTitle": "Job Title",
        "name": "Name (required)",
        "phone": "Phone Number",
        "roles": "Account Type",
        "workspace": "Workspace"
      },
      "searchPlaceholder": "Search name, number, or email",
      "columnButton": "Columns",
      "groupsButton": "Groups",
      "accountTypesButton": "Account Types",
      "noAccountTypes": "No Account Types"
    },
    "groups": {
      "addPeopleTo": "Add {{count}} people to:",
      "addPersonTo": "Add 1 person to:"
    },
    "list": {
      "accountType": "Account Type",
      "added": "Date Joined",
      "email": "Email",
      "groups": "Groups",
      "jobTitle": "Job Title",
      "name": "Name",
      "phone": "Phone Number",
      "workspace": "Workspace"
    },
    "topBar": {
      "peopleSelected": "{{count}} people selected",
      "personSelected": "1 person selected",
      "results": "{{count}} results"
    },
    "menu": {
      "confirmDelete": {
        "primaryButton": "Yes",
        "secondaryButton": "Cancel",
        "title": "Are you sure you want to remove {{accountName}}?"
      }
    }
  },
  "activity": {
    "filter": {
      "apply": "Apply",
      "assetName": "Asset Name",
      "cancel": "Cancel",
      "enterKeywords": "Enter keywords...",
      "filter": "Filter",
      "groups": "Groups",
      "activityTypes": "Activity Types",
      "notes": "Notes",
      "pending": "Pending",
      "recentlyReported": "Recently Reported",
      "recentlyUpdated": "Recently Updated",
      "reset": "Reset",
      "routines": "Routines",
      "searchPlaceholder": "Search activity titles",
      "solved": "Solved",
      "sortBy": "Sort By",
      "title": "Filters",
      "labels": "Labels",
      "urgent": "Urgent",
      "date": "Date",
      "all": "All",
      "today": "Today",
      "thisWeek": "This Week",
      "thisMonth": "This Month",
      "customDate": "Custom Date",
      "from": "From",
      "to": "To"
    },
    "header": {
      "title": "Activity",
      "view": "View",
      "viewAll": "View All"
    },
    "menu": {
      "title": "Activity"
    },
    "textEmpty": "No activity",
    "topBar": {
      "results": "results"
    }
  },
  "admin": {
    "menu": {
      "admin": "People",
      "workspaces": "Workspaces"
    }
  },
  "alerts": {
    "create": {
      "alertName": "Alert Name",
      "asset(s)": "Asset(s)",
      "assetSearchPlaceholder": "Search an asset name",
      "assets": "Assets",
      "doNotAlert": "<0>(Optional)</0><1>Do not alert between:</1>",
      "groupSearchPlaceholder": "Search a group name",
      "groups": "Groups",
      "promptRoutine": "<0>(Optional)</0><1>Select a routine</1><0>to prompt with this alert:</0>",
      "promptRoutinePlaceholder": "no routine selected",
      "title": "Create Alert",
      "what": "1. Choose what:",
      "when": "3. Choose when:",
      "why": "2. Choose why:"
    },
    "createButton": "Create New",
    "edit": {
      "alertName": "Alert Name",
      "asset(s)": "Asset(s)",
      "assets": "Assets",
      "doNotAlert": "Do not alert between",
      "groups": "Groups",
      "none": "None",
      "promptRoutine": "<0>Select a routine</0><1>to prompt with this alert</1>",
      "promptRoutinePlaceholder": "no routine selected",
      "setFor": "This alert is set for",
      "setOn": "Set up the alert on",
      "title": "Edit Alert",
      "when": "When"
    },
    "menu": {
      "confirmDelete": {
        "primaryButton": "Yes",
        "secondaryButton": "Cancel",
        "title": "Are you sure you'd like to delete {{alertName}}?"
      },
      "delete": "Delete",
      "disableAlert": "Disable Alert",
      "enableAlert": "Enable Alert",
      "viewAlertActivities": "View Alert Activities"
    },
    "new": {
      "header": "Alerts > Create Alert"
    },
    "title": "Automation",
    "trigger": {
      "counter": {
        "ceiling": "The counter goes above",
        "daysSinceCompleted": "The routine has not been completed for",
        "daysSinceCompleted-end": "Days",
        "every": "Every",
        "every-end": "Days",
        "floor": "The counter goes below",
        "onCompletionCount": "The routine has been completed every",
        "onCompletionCount-end": "Times",
        "onInterval": "The counter reaches interval of"
      },
      "default": {
        "daysSinceCompleted": "The routine has not been completed for",
        "daysSinceCompleted-end": "Days",
        "every": "Every",
        "every-end": "Days",
        "onCompletionCount": "The routine has been completed every",
        "onCompletionCount-end": "Times"
      }
    }
  },
  "asset": {
    "additionalMedia": "Additional Media",
    "advancedAssetInformation": "Advanced Asset Information",
    "customAlert": {
      "tagAlreadyAdded": "This tag is already added to this asset.",
      "tryAnotherTag": "This Tag is already in use and cannot be selected, please try another tag."
    },
    "delete": {
      "button": "Delete Asset",
      "cancel": "Cancel",
      "confirm": "Delete",
      "title": "Are you sure you want to delete this asset?"
    },
    "description": "Description",
    "descriptionPlaceholder": "Optional",
    "details": "Details",
    "detailsPlaceHolder": "Optional",
    "editTitle": "Asset Info",
    "groups": "Groups",
    "groupsPlaceHolder": "(Optional) Select Group(s)",
    "groupsSearchPlaceHolder": "Search a group name",
    "header": {
      "activity": "Activity",
      "buttons": {
        "addNew": "Add Report / Note",
        "newNote": "Note",
        "reportIssue": "Report",
        "routines": "Routines"
      },
      "description": "Description",
      "lastChecked": "Last scanned",
      "lastUpdated": "Last updated",
      "recentActivity": "Recent Activity"
    },
    "make": "Make",
    "mediaLibrary": "Media Library",
    "model": "Model",
    "name": "Name",
    "namePlaceholder": "What is this?",
    "newIssue": "Something new?",
    "newTitle": "Asset Setup",
    "tagCode": "Gripp ID"
  },
  "assets": {
    "filter": {
      "apply": "Apply",
      "columnButton": "Columns",
      "columnSelection": "Columns",
      "columns": {
        "createdAt": "Created At",
        "description": "Description",
        "groups": "Groups",
        "lastAddress": "Location",
        "lastScannedAt": "Last Scanned",
        "lastUpdated": "Last Updated",
        "name": "Name (required)",
        "notes": "# of Notes",
        "reports": "# of Active Reports",
        "routines": "# of Routines",
        "tagCode": "Gripp ID",
        "workspaces": "Workspace"
      },
      "enterKeywords": "Enter keyword...",
      "groups": "Groups",
      "groupsButton": "Groups",
      "keywords": "Keyword",
      "reset": "Reset",
      "searchPlaceholder": "Search name, description, or Gripp ID",
      "title": "Filters"
    },
    "groups": {
      "addAssetTo": "Add 1 asset to:",
      "addAssetsTo": "Add {{count}} assets to:"
    },
    "list": {
      "createdAt": "Created At",
      "description": "Description",
      "groups": "Groups",
      "lastAddress": "Location",
      "lastScannedAt": "Last Scanned",
      "lastUpdated": "Last Updated",
      "name": "Name",
      "notes": "Notes",
      "reports": "Active Reports",
      "routines": "Routines",
      "tagCode": "Gripp ID",
      "workspaces": "Workspace"
    },
    "menu": {
      "title": "Assets"
    },
    "textEmpty": "No assets",
    "topBar": {
      "allAssets": "All Assets",
      "assetSelected": "1 asset selected",
      "assetsSelected": "{{count}} assets selected",
      "results": "{{count}} results"
    }
  },
  "avatar": {
    "menu": {
      "profile": "Profile",
      "signout": "Sign Out"
    }
  },
  "binaryCheck": {
    "button": "Update",
    "instructions1": "A new update is available!",
    "instructions2": "Gripp may not work until then."
  },
  "camera": {
    "Cancel": "Cancel",
    "grippInviteDetected": "Gripp Invite Detected!",
    "grippTagDetected": "Gripp Tag Detected!",
    "noCameraAccess": "No access to camera",
    "notGrippInvite": "Not a Gripp Invitation",
    "notGrippTagg": "Not a Gripp Tag",
    "tryAgain": "Please Try Again"
  },
  "changesSaved": "Changes Saved",
  "customAlert": {
    "ok": "OK"
  },
  "deleteAccount": {
    "button": "Delete account",
    "confirm": "Confirm Delete",
    "header": "Delete Account",
    "reasonInput": "Please let us know any additional details for deleting your account (optional):",
    "submitted": "Your account deletion request has been submitted."
  },
  "dropdown": {
    "dropdownFooter": {
      "apply": "Apply",
      "reset": "Reset"
    }
  },
  "edit": "",
  "email": "Email",
  "features": {
    "title": "Features"
  },
  "firstName": "First Name",
  "forbidden": "Forbidden",
  "groups": {
    "groupsSelector": {
      "addToGroup": "Add to Group",
      "addtoNewGroup": "Add to New Group",
      "apply": "Apply",
      "clearGroups": "Clear Groups"
    },
    "noGroups": "No Groups",
    "menu": {
      "title": "Groups"
    },
    "create": {
      "label": "Create a New Group",
      "validation": {
        "name_required": "Group name is required"
      },
      "cancel": "Cancel",
      "create": "Create",
      "placeholder": "e.g. Group of Tractors"
    },
    "list": {
      "name": "Group Name",
      "people": "People",
      "assets": "Assets",
      "reports": "Active Reports"
    },
    "common": {
      "textEmpty": "No group"
    },
    "filter": {
      "searchPlaceholder": "Search group name, assets, or people",
      "columnButton": "Columns",
      "columns": {
        "name": "Group Name",
        "reports": "Active Reports",
        "assets": "Assets",
        "people": "People"
      },
      "columnSelection": "Columns"
    }
  },
  "invitation": {
    "initial": {
      "heading": "You are invited to {{workspaceName}}!",
      "login": "Log In Key",
      "step1": "Step 1",
      "step1Instructions": "Download Gripp app",
      "step2": "Step 2",
      "step2Instructions": "Use this key to log in"
    },
    "invitePeople": {
      "copiedInvitationLink": "Copied invitation link",
      "copyInviteLink": "Copy invite link",
      "errorCopyingInvitationLink": "Something went wrong when copying the invitation url",
      "invitePeopleForm": "Invite people through emails",
      "invitePeopleQR": "Invite people through QR code",
      "linkAllowsToJoinWorkplace": "This link allows anyone to ask to join your workplace.",
      "placeholder": "Emails",
      "submit": "Submit",
      "to": "To"
    },
    "pending": {
      "accepted": "Follow the link sent to your email to sign into your Gripp Account.",
      "cancel": "Cancel",
      "join": "Join",
      "joining": "You are joining {{workspaceName}}",
      "title": "Create Profile"
    },
    "qr": {
      "invitesYou": "invites you to join"
    }
  },
  "issue": {
    "anonymous": {
      "addNote": "Add Note",
      "addPicture": "Add Picture",
      "cancel": "Cancel",
      "createReport": "Create Report",
      "issue": "Issue",
      "noteAdded": "Duly Noted!",
      "noteAddedDownloadGrippApp": "Download the Gripp app for even easier note taking!",
      "placeholder": "What are you seeing?",
      "report": "Report",
      "reportAddedDownloadGrippApp": "Download the Gripp app for even faster reporting!",
      "reported": "Issue Reported!",
      "routineCompleted": "Boom, update confirmed!",
      "routineCompletedDownloadGrippApp": "Download the Gripp app for the smoothest experience!",
      "submit": "Submit"
    },
    "common": {
      "addPicture": "Pictures",
      "description": "Description",
      "followUp": "Follow-up required?",
      "note": "No, just noting for reference",
      "report": "Yes, follow-up needed",
      "saveLabel": "Save"
    },
    "delete": {
      "confirm": "Confirm delete?"
    },
    "editTitleModal": {
      "cancel": "Cancel",
      "header": "Edit Title",
      "save": "Save"
    },
    "latestMessage": {
      "media": "[Media]"
    },
    "menu": {
      "delete": "Delete",
      "editTitle": "Edit Title",
      "print": "Print",
      "reassign": "Reassign"
    },
    "new": {
      "addIt": "Add Report / Note",
      "addPicture": "Add Picture",
      "cancel": "Cancel",
      "issue": "Issue",
      "note": "Note",
      "optionalPlaceholder": "Optional",
      "placeholder": "What are you seeing?",
      "report": "Report",
      "reported": "Problem Reported!",
      "submit": "Submit",
      "title": "Title"
    },
    "reassign": {
      "instructions": "Which one of the following assets do you want to reassign to?",
      "placeholder": "Search an asset name",
      "saveLabel": "Save",
      "title": "Reassign"
    },
    "topBar": {
      "allReplies": "replies"
    }
  },
  "job": "",
  "jobTitle": "Job Title",
  "lastName": "Last Name",
  "loading": "Loading",
  "login": {
    "codeSentTo": "A login code was sent to",
    "email": "Email",
    "error": {
      "emailNotFound": "Email not found, please contact your organization admin",
      "invalidCredentials": "Invalid credentials",
      "processError": "There was an error processing your request"
    },
    "login": "Log in",
    "password": "Password",
    "resendCode": "Resend code",
    "tabs": {
      "new": {
        "alreadyInvited": "if you already have an invite.",
        "inBrowser": "in your browser",
        "logInKey": "Tap the Log In Key",
        "scanQRInvitation": "Scan Gripp Tag or QR Invitation",
        "title": "I'm new!"
      },
      "returning": {
        "title": "I’m returning"
      }
    },
    "tryAnotherMethod": "Try another method",
    "withEmail": "Use email",
    "withMagicLink": "I don’t have a password",
    "withPassword": "I have a password",
    "withPhone": "Use Phone Number"
  },
  "logout": "Logout",
  "mentions": {
    "unknownUser": "Unknown User"
  },
  "modalMessages": {
    "completed": "Completed"
  },
  "or": "or",
  "people": {
    "menu": {
      "people": "People"
    }
  },
  "phone": "Phone",
  "plusMenu": {
    "add-asset": "Add Asset",
    "invite": "Invite",
    "no-tag": "No Tag?",
    "note": "Note",
    "report": "Report",
    "scan": "Scan"
  },
  "preferences": {
    "language": {
      "languages": {
        "english": "English",
        "spanish": "Spanish"
      },
      "preferredLanguageTitle": "Preferred Language"
    },
    "menu": {
      "title": "Settings"
    },
    "notifications": {
      "groupNotificationsTitle": "Group Notifications",
      "issueReplies": "Messages",
      "issueSeverityChanges": "Color changes",
      "issueTitleChanges": "Title changes",
      "notificationTitle": "Notifications",
      "schedule": {
        "accept": "Accept",
        "cancel": "Cancel",
        "endTime": "End time:",
        "everyDay": "Every Day",
        "notSet": "Not set",
        "notificationSchedule": "Notification Schedule",
        "set": "Set",
        "startTime": "Start time:",
        "weekDays": "Week Days"
      },
      "severities": {
        "blue": "New Routines",
        "purple": "New Notes",
        "red": "New Reports",
        "yellow": "New Alerts"
      }
    }
  },
  "profile": {
    "edit": {
      "menu": {
        "title": "Edit Profile"
      }
    }
  },
  "recent_activities": "Recent Activities",
  "requestRoutine": {
    "apply": "Apply",
    "cancel": "Cancel",
    "header": "Routine",
    "instruction": "Please submit your routine idea:",
    "placeHolder": "What should we start doing?"
  },
  "routines": {
    "admin": {
      "create": "Save",
      "instructions": "Instructions",
      "issuePrefix": "Issue Prefix",
      "reportOnException": "Report on Exception?",
      "routineButtonText": "Routine Button",
      "title": "Title",
      "type": "Type",
      "update": "Update"
    },
    "assign": "Assign Routines",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "copyToWorkspace": {
      "save": "Copy",
      "title": "Copy to Workspace",
      "workspace": "Workspace"
    },
    "counter": {
      "admin": {
        "decimalPlaces": "Enable Decimals (Optional)",
        "tallyCounter": "Tally Counter",
        "uom": "Unit of Measure"
      },
      "user": {
        "genericCounter": {
          "change": "Change:",
          "initialValue": "Initial Value:",
          "newValue": "New Value:"
        },
        "tallyCounter": {
          "add": "+ Add",
          "adding": "Adding:",
          "instructions": "",
          "newValue": "New Value:",
          "oldValue": "Old Value:",
          "placeholder": "Enter number...",
          "remove": "- Remove",
          "removing": "Removing:"
        }
      }
    },
    "createNew": "Create New Routine",
    "edit": {
      "header": "Routines > Edit Routine"
    },
    "editRoutines": {
      "editRoutinesButton": "Edit Routines",
      "label": "Label: ",
      "routineInWorkplace": "All routines available in your workplace",
      "title": "Edit"
    },
    "exceptions": {
      "addDetails": "Please add additional details or pictures of the issue:",
      "exceptionsNoticed": "{{count}} exception noticed",
      "exceptionsNoticed_plural": "{{count}} exceptions noticed",
      "reportCreated": "A report was created",
      "viewRelatedReport": "View Related Report",
      "viewRelatedRoutine": "View Related Routine"
    },
    "header": {
      "title": "Routines",
      "view": "View",
      "viewAll": "View All"
    },
    "menu": {
      "copyToWorkspace": "Copy to Workspace",
      "delete": {
        "cancel": "Cancel",
        "confirm": "Yes",
        "item": "Delete",
        "title": "Are you sure you want to delete this routine?"
      },
      "editRoutine": "Edit Routine",
      "export": "Export data",
      "exporting": "Exporting..."
    },
    "message": {
      "messageTitle": "{{name}} completed routine:"
    },
    "modalCancel": {
      "primary": "Yes",
      "secondary": "Cancel",
      "title": "Exit routine without submitting?"
    },
    "new": {
      "chooseTypeButton": "Next Step: Choose Options",
      "header": "Routines > Create Routine"
    },
    "quickConfirm": {
      "admin": {
        "addOption": "Add option",
        "options": "Options"
      }
    },
    "requestButton": "+ Request Routine",
    "signature": {
      "clear": "Clear",
      "done": "Done",
      "sign": "Sign",
      "signature": "Signature",
      "submit": "Submit"
    },
    "status": {
      "admin": {
        "addStatus": "Add status",
        "statuses": "Statuses"
      }
    },
    "submit": "Submit",
    "types": {
      "COUNTER": "Counter",
      "QUICK_CONFIRM": "Quick Confirm",
      "STATUS": "Status",
      "WORKFLOW": "Mini Form"
    },
    "update": "Update",
    "workflow": {
      "admin": {
        "page": {
          "addQuestion": "Add Question",
          "instructions": "Instructions",
          "page": "Page {{page}}",
          "questionType": "Question Type"
        },
        "question": {
          "addOption": "Add option",
          "exception": {
            "HasResponse": "Has Response",
            "MissingResponse": "Missing Response",
            "Off": "Off",
            "label": "Exception handling:"
          },
          "instructions": "Instructions",
          "multiline": "Multiline",
          "options": "Options",
          "question": "Question {{question}}"
        },
        "routine": {
          "label": "Routine",
          "select": "Select a routine"
        }
      },
      "execution": {
        "optional": "Optional",
        "submit": "Submit"
      },
      "questionTypes": {
        "CHECKBOX": "Multi-Select",
        "COUNTER": "Counter",
        "MEDIA": "Media",
        "QUICK_CONFIRM": "Quick Confirm",
        "RADIO": "Single-Select",
        "ROUTINE": "Routine",
        "SIGNATURE": "Signature",
        "STATUS": "Status",
        "TEXT": "Text"
      }
    }
  },
  "saveChanges": "Save Changes",
  "signOut": "Sign out",
  "support": {
    "genericDescription": "Sometimes hiccups happen, but if you see this page again, please let us know so we can make it right!",
    "sendEmail": "Or email me at",
    "title": "Shoot, we oopsed"
  },
  "tags": {
    "assetName": "Asset Name",
    "batches": {
      "title": "Orders"
    },
    "status": "Status",
    "tagCode": "Tag Number",
    "title": "Tags",
    "updatedAt": "Updated At",
    "version": "Version"
  },
  "textEmptyList": "No data",
  "topNav": {
    "Add": "",
    "activities": "Activity",
    "add": "Add",
    "assetInfo": "View Asset",
    "assets": "Assets",
    "back": "Back",
    "backButton": {
      "cancel": "Continue editing",
      "confirmBack": "There are unsaved changes!",
      "ok": "Exit without saving",
      "primary": "",
      "secondary": "",
      "title": ""
    },
    "cancel": "Cancel",
    "customAlert": {
      "cancel": "Cancel",
      "deleteAccount": "Are you sure you'd like to delete your account?",
      "exit": "Exit without saving?",
      "signOut": "Are you sure you'd like to sign out?",
      "yes": "Yes"
    },
    "edit": "Edit",
    "editAsset": "Asset Info",
    "invite": "Invite People",
    "moreAssetInfo": "More Asset Info",
    "save": "Save"
  },
  "translation": {
    "seeOriginal": "See Original",
    "seeTranslation": "See Translation"
  },
  "versionCheck": {
    "description": "Updates available! Some features may not work until you refresh the browser.",
    "refreshButton": "Refresh"
  },
  "workspace": {
    "create": {
      "addButton": "Submit",
      "administratorEmail": "Administrator Email",
      "administratorFirstName": "Administrator First Name",
      "administratorLastName": "Administrator Last Name",
      "copyRoutines": "Copy Routines",
      "title": "Create Workspace",
      "validation": {
        "admin_email_required": "Administrator email is required",
        "admin_first_name_required": "Administrator first name is required",
        "admin_last_name_required": "Administrator last name is required",
        "name_required": "Workspace name is required",
        "valid_emil": "Administrator email must be a valid email address"
      },
      "workspaceName": "Workspace Name"
    },
    "createButton": "Create Workspace",
    "list": {
      "administrator": "Administrator",
      "workspace": "Workspace"
    }
  },
  "permissions": {
    "backButton": "Back",
    "continueButton": "Continue",
    "enableInSettings": "You must enable this permission in system menu to continue.",
    "welcome": {
      "title": "Welcome To Gripp!",
      "description1": "To use Gripp, we need to enable several permissions.",
      "description2": "We’ll walk you through each one to explain why we need it.",
      "description3": "All settings can be adjusted through your phone’s settings at any time."
    },
    "location": {
      "title": "Access to your location",
      "description1": "Gripp uses your phone location to update the location of your assets when you scan them.",
      "description2": "At minimum, we ask you to allow location while using the app",
      "button": "Enable Location"
    },
    "camera": {
      "title": "Access to your camera",
      "description1": "Making notes and reporting issues often require you to take a picture of what you see, so enabling camera access is important to take pictures!",
      "button": "Enable Camera"
    },
    "gallery": {
      "title": "Access to your photo gallery",
      "description1": "Similar to camera, sometimes you already have pictures you want to upload into Gripp.",
      "description2": "Gripp will only have access to the pictures you choose at the point of upload.",
      "button": "Enable Gallery"
    },
    "notification": {
      "title": "Enable notifications",
      "description1": "Like many apps, be notified when a new alert, issue, or report comes through. You wouldn’t want to miss anything important.",
      "description2": "These are for team notifications only and are not related to marketing notifications.",
      "button": "Enable Notifications"
    },
    "allSet": "All set, let's get Gripp'n!"
  }
}
