import { DimensionValue, Image, ImageSourcePropType } from 'react-native';

type Props = {
  source?: ImageSourcePropType;
  width?: DimensionValue;
  height?: DimensionValue;
};

export const StepImage = ({ source, width = 149, height = 149 }: Props) => {
  return <Image source={source} style={{ width: width, height: height }} />;
};
