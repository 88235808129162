import { StyleSheet, View } from 'react-native';
import { useParams } from 'react-router-dom';
import { AssetCard } from './assetCard';

export const Asset = () => {
  const { assetId = '' } = useParams();

  return (
    <View style={styles.container}>
      <AssetCard assetId={assetId} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
});
