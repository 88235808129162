import {
  SignatureQuestionConfig,
  SignatureResponseState,
  useFindMedia,
} from '@gripp/shared-logic';
import { getImageThumbnailWidth } from 'libs/shared-ui/src/components/media/getMediaThumbnailWidth';
import { useState } from 'react';
import { View } from 'react-native';
import { MessageMedia } from '../../../../issue/messageMedia';
import { RoutineQuestionMessage, RoutineQuestionMessageProps } from '../types';

export const SignatureQuestionMessage: RoutineQuestionMessage<
  SignatureQuestionConfig,
  SignatureResponseState
> = (
  props: RoutineQuestionMessageProps<
    SignatureQuestionConfig,
    SignatureResponseState
  >
) => {
  const { response } = props;
  const [mediaWidth, setMediaWidth] = useState<number>();
  const media = useFindMedia({
    filter: {
      id: { eq: response.mediaId },
    },
  });

  return (
    media && (
      <View
        onLayout={(event) => {
          const { width } = event.nativeEvent.layout;
          const thumbnailWidth = getImageThumbnailWidth(width);
          setMediaWidth(thumbnailWidth);
        }}
      >
        <MessageMedia media={media} displayWidth={mediaWidth} />
      </View>
    )
  );
};
