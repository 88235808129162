import {
  EnvConfig,
  LIST_MENTIONABLE_USERS,
  useCurrentWorkspace,
  useFetcher,
} from '@gripp/shared-logic';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { DataFunc, Mention, MentionsInput } from 'react-mentions';
import { StyleSheet, Text, View } from 'react-native';
import { Colors } from '../../themes';
import { AccountAvatar } from '../accountAvatar';

export type MentionsInputFieldProps = {
  inputFieldText: string;
  setInputFieldText: (text: string) => void;
  send: () => void;
};

export const MentionsInputFieldWeb = (props: MentionsInputFieldProps) => {
  const { inputFieldText, setInputFieldText, send } = props;

  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);
  const portalRef = useRef<HTMLDivElement>(null);

  const workspace = useCurrentWorkspace();
  const queryClient = useQueryClient();

  const fetchUsers: DataFunc = async (search, callback) => {
    const workspaceUsers = await queryClient.fetchQuery({
      queryKey: ['mentionableUsers', workspace?.id, search],
      queryFn: useFetcher(LIST_MENTIONABLE_USERS).bind(null, {
        filter: {
          workspaceId: workspace?.id,
          keyword: search,
        },
        page: {
          limit: 10,
          offset: 0,
        },
      }),
    });

    const results = workspaceUsers?.account?.listMentionableUsers?.items?.map(
      (u) => ({
        id: u.id,
        display: `${u.name.first} ${u.name.last}`,
        email: u.email,
        avatar: u.avatar,
      })
    );

    callback(results);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (props.inputFieldText !== '' && e.code === 'Enter' && !e.shiftKey) {
      send();
      e.preventDefault();
      setInputFieldText('');
    }
  };

  useEffect(() => {
    if (portalRef.current) {
      setPortalNode(portalRef.current);
    }
  }, []);

  const renderMentionRow = (
    entry: any,
    search: string,
    highlightedDisplay: any,
    index: number,
    focused: boolean
  ) => {
    return (
      <div
        style={{
          ...mentionRowStyle,
          backgroundColor: focused ? Colors.blackWithAlpha : Colors.white,
          fontWeight: focused ? 'bold' : 'normal',
        }}
      >
        <AccountAvatar
          avatar={entry.avatar}
          mediaUrl={EnvConfig.mediaUrl}
          size={36}
        />
        <View
          style={{
            marginLeft: 16,
          }}
        >
          <Text style={styles.mentionRowName}>{highlightedDisplay}</Text>
          <Text style={styles.mentionRowEmail}>{entry.email}</Text>
        </View>
      </div>
    );
  };

  return (
    <div
      ref={portalRef}
      style={{
        width: '100%',
        height: 64,
        overflow: 'hidden',
        boxSizing: 'border-box',
      }}
    >
      <MentionsInput
        value={inputFieldText}
        placeholder="Aa"
        style={customStyle}
        onChange={(e) => {
          setInputFieldText(e.target.value);
        }}
        suggestionsPortalHost={portalNode}
        spellCheck={false}
        onKeyPress={handleKeyPress}
      >
        <Mention
          trigger="@"
          appendSpaceOnAdd
          data={fetchUsers}
          renderSuggestion={renderMentionRow}
          displayTransform={(id, display) => `@${display}`}
          style={mentionStyle}
        />
      </MentionsInput>
    </div>
  );
};

const styles = StyleSheet.create({
  mentionRowName: {
    fontSize: 14,
    fontWeight: '400',
    letterSpacing: -0.25,
    color: Colors.mentionName,
  },
  mentionRowEmail: {
    fontSize: 12,
    fontWeight: '400',
    letterSpacing: 0.2,
    color: Colors.mentionEmail,
  },
});

const mentionRowStyle: any = {
  flex: 1,
  width: '100%',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: 56,
  overflow: 'hidden',
  padding: '8px 16px',
  cursor: 'pointer',
};

const mentionStyle: any = {
  backgroundColor: Colors.primaryGrayBackground,
  color: Colors.primaryDark,
  position: 'relative',
  zIndex: 2,
  fontSize: '16px',
};

const customStyle: any = {
  control: {
    fontSize: '16px',
    paddingLeft: '12px',
    width: '100%',
    position: 'relative',
    fontFamily: 'Inter, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontWeight: '400',
  },
  highlighter: {
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    width: '100%',
    height: 60,
    paddingTop: '8px',
    fontSize: '16px',
  },
  input: {
    height: 60,
    paddingLeft: '12px',
    paddingTop: '8px',
    width: '100%',
    fontSize: '16px',
    outline: 'none',
    border: 'none',
  },
  suggestions: {
    list: {
      flex: 1,
      position: 'absolute',
      overflow: 'auto',
      bottom: 20,
      width: 320,
      maxHeight: 280,
      minHeight: 56,
      borderRadius: 4,
      backgroundColor: Colors.white,
      left: -10,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Bottom shadow
    },
  },
};
