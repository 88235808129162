import { StatusConfig, StatusState } from '@gripp/shared-logic';
import { FC } from 'react';
import { SubHeaderText } from '../../asset/subHeaderText';
import { RoutineHeaderProps } from '../types';

type StatusHeaderProps = RoutineHeaderProps<StatusConfig, StatusState>;

export const StatusHeader: FC<StatusHeaderProps> = (
  props: StatusHeaderProps
) => {
  const { config, currentState } = props;

  return <SubHeaderText>{currentState?.state.status}</SubHeaderText>;
};
