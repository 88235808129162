import {
  cacheKeys,
  useCustomMutation,
  useGrippContext,
} from '@gripp/shared-logic';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddToGroups } from '../../shared/components/groups/addToGroups';

export type AssetGroupsProps = {
  assetIds: string[];
  workspaceId: string;
};

export const AssetGroups = (props: AssetGroupsProps) => {
  const { mutateAsync: addAssetsToGroups, isPending: isUpdating } =
    useCustomMutation({
      query: ADD_ASSETS_TO_GROUPS,
    });
  const queryClient = useQueryClient();
  const cacheKey = cacheKeys('asset').all;
  const { logger } = useGrippContext();
  const { t } = useTranslation();
  const count = props.assetIds.length;

  const selectedLabel =
    count !== 1
      ? t('assets.topBar.assetsSelected', {
          count: count,
        })
      : t('assets.topBar.assetSelected');
  const addToGroupLabel =
    count !== 1
      ? t('assets.groups.addAssetsTo', {
          count: count,
        })
      : t('assets.groups.addAssetTo');

  const handleSubmit = async (groupsToApply: any) => {
    const input = {
      groups: groupsToApply,
      assetIds: props.assetIds,
      workspaceId: props.workspaceId,
    };
    addAssetsToGroups(
      { input: input },
      {
        onSettled: async (data, error?: Error) => {
          if (error) {
            logger?.error(error, 'Failed to add assets to groups');
          }
          await queryClient.invalidateQueries({ queryKey: cacheKey });
        },
      }
    );
  };

  return (
    <AddToGroups
      entitySelectedLabel={selectedLabel}
      entityAddToGroupLabel={addToGroupLabel}
      entityIds={props.assetIds}
      workspaceId={props.workspaceId}
      onSubmit={handleSubmit}
      isUpdating={isUpdating}
    />
  );
};

const ADD_ASSETS_TO_GROUPS = `
mutation Asset($input: AddAssetsToGroupsInput!) {
  asset {
    addAssetsToGroups(input: $input) {        
      success
      message
    }
  }
}
`;
