import { useState } from 'react';
import * as Location from 'expo-location';
import { GeoJsonType } from '../../graphql';
import { StorageHandler, useStorageKeys } from '../../storage';
import { Location as LocationModel } from '../domain';
import { useGrippContext } from '../../grippContext';

export const useWatchLocation = (storageHandler: StorageHandler) => {
  const [isWatching, setIsWatching] = useState(false);

  const watchLocation = async (requestPermission: boolean) => {
    const storageKeys = useStorageKeys();

    const permission = requestPermission
      ? await Location.requestForegroundPermissionsAsync()
      : await Location.getForegroundPermissionsAsync();

    if (permission.status === Location.PermissionStatus.GRANTED) {
      setIsWatching(true);
      await Location.watchPositionAsync(
        {
          accuracy: Location.LocationAccuracy.Balanced,
          distanceInterval: 10,
        },
        async (expoLocation: Location.LocationObject) => {
          const location: LocationModel = {
            type: GeoJsonType.Point,
            coordinates: [
              expoLocation.coords.longitude,
              expoLocation.coords.latitude,
            ],
          };

          await storageHandler.setItem(
            storageKeys.location,
            JSON.stringify(location)
          );
        }
      );
    } else {
      setIsWatching(false);
    }
  };

  return {
    watchLocation: (requestPermission: boolean) =>
      watchLocation(requestPermission),
    isWatching,
  };
};

export interface UseCurrentLocationHook {
  getLocation: () => Promise<LocationModel | undefined>;
}

export const useCurrentLocation = (): UseCurrentLocationHook => {
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();

  return {
    getLocation: async () => {
      const json = await storageHandler.getItem(storageKeys.location);
      return json ? JSON.parse(json) : undefined;
    },
  };
};
