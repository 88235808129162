import { StatusConfig, StatusState } from '@gripp/shared-logic';
import { RoutineModule } from '../types';
import { StatusButtons } from './statusButtons';
import { StatusMessage } from './statusMessage';
import {
  StatusExecutionForm,
  getExecutionFormDefaultValues,
  getExecutionFormResolver,
} from './statusExecutionForm';
import { StatusHeader } from './statusHeader';
import { StatusAdminForm, StatusAdminSchema } from './statusAdminForm';

export const StatusModule: RoutineModule<StatusConfig, StatusState> = {
  header: StatusHeader,
  buttons: StatusButtons,
  executionForm: StatusExecutionForm,
  getExecutionFormDefaultValues,
  getExecutionFormResolver,
  adminForm: StatusAdminForm,
  adminSchema: StatusAdminSchema,
  message: StatusMessage,
};
