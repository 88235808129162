import { QuickConfirmConfig, QuickConfirmState } from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { string, object as yupObject } from 'yup';
import { useFormContext } from '../../forms';
import { RoutineExecutionForm, RoutineExecutionFormProps } from '../types';
import { QuickConfirmExecutionComponent } from './quickConfirmExecutionComponent';

export const getExecutionFormResolver = () => {
  const validationSchema = yupObject({
    confirmedValue: string().required().nonNullable(),
  });

  return yupResolver(validationSchema);
};

export const getExecutionFormDefaultValues = (
  _: QuickConfirmConfig,
  state?: QuickConfirmState
) => {
  return {
    confirmedValue: state?.confirmedValue,
  };
};

export const QuickConfirmExecutionForm: RoutineExecutionForm<
  QuickConfirmConfig,
  QuickConfirmState
> = (
  props: RoutineExecutionFormProps<QuickConfirmConfig, QuickConfirmState>
) => {
  const formKey = 'confirmedValue';
  const { setValue, watch } = useFormContext();
  const currentConfirmedValue = watch(formKey);

  useEffect(() => {
    if (props.config.options.length === 1) {
      setTimeout(() => {
        onConfirmedValueChanged(props.config.options[0]);
      }, 10);
    }
  }, []);

  const onConfirmedValueChanged = (value: string) => {
    setValue(formKey, value, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <QuickConfirmExecutionComponent
      options={props.config.options}
      confirmedValue={currentConfirmedValue}
      onChange={onConfirmedValueChanged}
    />
  );
};
