export const getWorkspaceFilter = (workspaceId?: string, filter: any = {}) => {
  if (workspaceId) {
    filter.workspace = { eq: workspaceId };
  }

  return filter;
};

export const getWorkspacesFilter = (workspaceId?: string, filter: any = {}) => {
  if (workspaceId) {
    filter.workspaces = { eq: workspaceId };
  }

  return filter;
};
