import {
  useCustomMutation,
  useGrippContext,
  useInvalidateConfig,
  useStorageKeys,
} from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Button, Text, TextInput } from 'react-native-paper';
import { string, object as yupObject } from 'yup';
import { Colors } from '../../themes';
import { FieldCheckIcon } from './fieldCheckIcon';
import { LoginProps } from './props';
import { styles } from './styles';
import { useAccessTokenReceived } from './useAccessTokenReceived';
import { ViewError } from './viewError';

export default function Credentials(props: LoginProps) {
  const { t } = useTranslation();
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();
  const [error, setError] = useState<string | undefined>(undefined);
  const invalidateConfig = useInvalidateConfig();
  const onAccessTokenReceived = useAccessTokenReceived();
  const { mutateAsync, isPending: isLoading } = useCustomMutation({
    query: loginMutation,
  });
  const formSchema = yupObject({
    email: string().required().email(),
    password: string().required(),
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
    getFieldState,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    const loadEmail = async () => {
      const storedEmail = await storageHandler.getItem(storageKeys.email);
      if (storedEmail) {
        setValue('email', storedEmail);
      }
    };

    loadEmail();
  }, [setValue, storageKeys.email]);

  const onSubmit = (data: any) => {
    const input = {
      email: data.email,
      password: data.password,
    };
    mutateAsync(
      { input: input },
      {
        onSettled: async (data: any, error: any) => {
          const result = data?.authentication?.authenticate;

          if (result?.success) {
            storageHandler.setItem(storageKeys.email, input.email);
            const redirectPath = await storageHandler.getItem(
              storageKeys.redirectPath
            );

            const { accessToken } = result;
            await onAccessTokenReceived(accessToken);
            await invalidateConfig();

            if (props.handleRedirect) {
              await props.handleRedirect(redirectPath || '/');
            }
          } else {
            const requestError = t('login.error.processError');
            const invalidCredentials = t('login.error.invalidCredentials');

            if (error || !result) {
              setError(requestError);
            } else if (!result.success) {
              if (result.message === 'Invalid credentials') {
                setError(invalidCredentials);
              } else {
                setError(requestError);
              }
            }
          }
        },
      }
    );
  };

  function handleKeyPress(e: any): void {
    if (e.code === 'Enter' && isValid && !isLoading) {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <View
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 20,
      }}
    >
      <Controller
        control={control}
        name="email"
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            mode="outlined"
            outlineStyle={{ borderWidth: 0, borderRadius: 6 }}
            style={styles.textInput}
            value={value}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            placeholder={t('login.email')}
            placeholderTextColor={Colors.grayFill}
            keyboardType="email-address"
            onKeyPress={handleKeyPress}
            right={FieldCheckIcon({
              invalid: getFieldState('email').invalid,
              value: value,
            })}
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            mode="outlined"
            outlineStyle={{ borderWidth: 0, borderRadius: 6 }}
            activeOutlineColor={Colors.primaryDark}
            style={styles.textInput}
            value={value}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            secureTextEntry={secureTextEntry}
            placeholder={t('login.password')}
            placeholderTextColor={Colors.grayFill}
            onKeyPress={handleKeyPress}
            right={
              <TextInput.Icon
                icon={secureTextEntry ? 'eye-off-outline' : 'eye-outline'}
                onPress={(e) => setSecureTextEntry(!secureTextEntry)}
              />
            }
          />
        )}
      />
      <View style={styles.containerButton}>
        <Button
          mode="contained"
          disabled={!isValid || isLoading}
          onPress={handleSubmit(onSubmit)}
          loading={isLoading}
          style={[
            styles.button,
            !isValid || isLoading
              ? styles.disabledButton
              : styles.enabledButton,
          ]}
        >
          <Text style={styles.buttonText}>{t('login.login')}</Text>
        </Button>
      </View>
      <ViewError error={error} />
    </View>
  );
}

const loginMutation = `
  mutation Authenticate($input: AuthenticateInput!) {
    authentication {
      authenticate(input: $input) {
        accessToken
        message
        success
      }
    }
  }
`;
