import {
  ActionType,
  SortDirectionEnum,
  useCurrentWorkspace,
} from '@gripp/shared-logic';
import { InfiniteDataGrid } from '@gripp/shared-ui';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { AlertCard, AlertCardData } from './alertCard';

type AlertsListProps = {
  listMaxHeight?: number;
  onAlertSelected: (alertId: string) => void;
};

export const AlertsList = (props: AlertsListProps) => {
  const [filter, setFilter] = useState();
  const [alertMenuSelectedId, setAlertMenuSelectedId] = useState<
    string | undefined
  >(undefined);
  const [alertMenuHoverId, setAlertMenuHoverId] = useState<string | undefined>(
    undefined
  );

  const { t } = useTranslation();
  const workspace = useCurrentWorkspace();

  const heightStyle = {
    maxHeight: props.listMaxHeight,
  };

  useEffect(() => {
    if (!workspace) return;

    const filter = {} as any;
    filter.workspace = { eq: workspace?.id };
    filter.action = { type: { eq: ActionType.Alert } };
    setFilter(filter);
  }, [workspace]);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('id', {
      cell: ({ row }) => {
        const alert = row.original as AlertCardData;

        return (
          alert && (
            <AlertCard
              alert={alert}
              alertMenuSelectedId={alertMenuSelectedId}
              alertMenuHoverId={alertMenuHoverId}
            />
          )
        );
      },
      meta: {
        style: styles.cell,
        customRender: true,
      },
    }),
  ];

  const onAlertSelected = (alert: any) => {
    props.onAlertSelected && props.onAlertSelected(alert.id);
  };

  return (
    filter && (
      <View style={[heightStyle, styles.container]}>
        <InfiniteDataGrid
          columns={columns}
          modelName="workflow"
          orderBy={{ field: 'name', order: SortDirectionEnum.Asc }}
          filter={filter}
          query={getAlerts}
          pageSize={50}
          hideHeader={true}
          showScrollIndicator={true}
          listMaxHeight={props.listMaxHeight as number}
          onRowClick={onAlertSelected}
          rightButtonSelectedId={(id: string | undefined) =>
            setAlertMenuSelectedId(id)
          }
          rightButtonHoverId={(id: string | undefined) =>
            setAlertMenuHoverId(id)
          }
        />
      </View>
    )
  );
};

const getAlerts = `
  query findWorkflows($filter: WorkflowFilter, $page: PageRequest, $orderBy: OrderByInput) {
    findWorkflows(filter: $filter, page: $page, orderBy: $orderBy) {
      items {
        id
        name
        group {
           id
           name
        }
        workspaceAsset {
          id 
          asset {
            name
          }
        }
        disabled
        createdAt
        updatedAt
      }
      offset
      limit
      count
    }
  }
  `;

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    overflow: 'hidden',
  },
  cell: {},
});
