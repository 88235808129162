import {
  AssetRoutineState,
  WorkspaceRoutine,
  useWorkspaceAsset,
} from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../themes';
import { RoutineModule } from '../routine';
import { RoutineModuleButton } from '../routine/components/routineModuleButton';
import { useIssueContext } from './issueContext';
import { messageStyles } from './styles';
import { MessageComponent, MessageComponentProps } from './types';

// TODO: there is a bit of duplication between this component & RoutineButtons

export const RoutinePromptMessage: MessageComponent = (
  props: MessageComponentProps
) => {
  const { workspaceAsset } = useWorkspaceAsset(props.issue.asset.id);
  const [routineState, setRoutineState] = useState<
    AssetRoutineState<any> | undefined
  >();
  const { onRoutineSelected } = useIssueContext()!;

  useEffect(() => {
    if (
      !workspaceAsset ||
      !workspaceAsset.routineState ||
      workspaceAsset.routineState.length === 0
    )
      return;

    const state = workspaceAsset.routineState.find(
      (x) => x.workspaceRoutineId === props.message.promptRoutine.id
    );
    setRoutineState(state);
  }, [props.message.promptRoutine.id, workspaceAsset]);

  const onClick = (
    routine: WorkspaceRoutine,
    module: RoutineModule<any, any>,
    state?: AssetRoutineState<any>,
    additionalState?: any
  ) => {
    onRoutineSelected({
      workspaceAsset: workspaceAsset!,
      module: module,
      routine,
      state,
      additionalState,
      sourceIssue: props.issue,
    });
  };

  return (
    <View>
      <Text style={[messageStyles.messageText, props.textStyle]}>
        {props.message.text}
      </Text>
      <RoutineModuleButton
        routine={props.message.promptRoutine}
        state={routineState}
        buttonIndex={0}
        buttonCount={1}
        onClick={onClick}
        buttonStyle={styles.buttonStyle}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    maxWidth: 154,
    backgroundColor: Colors.white,
    borderRadius: 8,
    borderColor: Colors.blue,
    borderWidth: 2,
    height: 44,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
  },
});
