import { PermissionValues as Permissions } from '@gripp/shared-logic';
import { Issue } from '../issues/components/issue';
import { Activities } from './components/activities';
export const activityPaths = {
  root: 'activities',
};

export const activityRoutes = [
  {
    path: `${activityPaths.root}/*`,
    element: <Activities />,
    handle: {
      header: {
        titleKey: 'activity.menu.title',
        icon: 'bell-outline',
      },
      requiredPermission: Permissions.activity.all,
    },
    children: [
      {
        path: ':issueId',
        element: <Issue isComingFromActivities={true} />,
      },
    ],
  },
];
