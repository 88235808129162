import { changeLanguage } from '@gripp/i18n';
import {
  Graphback,
  useAuthentication,
  useCurrentAccount,
  useSetDefaultPreferredLanguage,
  useStorageKeys,
} from '@gripp/shared-logic';
import * as Sentry from '@sentry/react';
import { FC, useEffect, useState } from 'react';
import { Navigate, Outlet, useMatches, useNavigate } from 'react-router-dom';
import { usePathWithParams } from '../../shared/hooks/usePathWithParams';

export const ProtectedRoute: FC = () => {
  const authentication = useAuthentication();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    authentication?.isAuthenticated || false
  );
  const [isAuthorized, setIsAuthorized] = useState<boolean | undefined>(
    undefined
  );
  const matches = useMatches();
  const path = usePathWithParams();
  const { account } = useCurrentAccount();
  const navigate = useNavigate();
  const storageKeys = useStorageKeys();
  const setDefaultPreferredLanguage = useSetDefaultPreferredLanguage();

  useEffect(() => {
    if (!authentication || authentication.isLoading) return;
    if (authentication.isAuthenticated) {
      Graphback.initialize(authentication.accessToken!);
      setIsAuthenticated(true);
      return;
    }

    if (authentication && !authentication.isAuthenticated) {
      localStorage.setItem(storageKeys.redirectPath, path);
    }

    navigate('/login');
  }, [authentication, navigate, path, storageKeys.redirectPath]);

  useEffect(() => {
    if (!isAuthenticated || !account) return;

    const requiredPermission = matches
      .map((match) => (match.handle as any)?.requiredPermission)
      .filter((x) => x)[0];
    setIsAuthorized(account.hasPermission(requiredPermission));
  }, [account, isAuthenticated, matches]);

  useEffect(() => {
    const onInit = async () => {
      if (!account) return;
      //TODO: init

      if (account.preferences?.preferredLanguage) {
        await changeLanguage(account.preferences.preferredLanguage);
      } else {
        await setDefaultPreferredLanguage(account);
      }

      Sentry.setTag('account_id', account.id);
    };

    onInit();
  }, [account]);

  if (isAuthorized === undefined) {
    return <Authorizing />;
  }

  return isAuthorized ? <Outlet /> : <Navigate to="/forbidden" />;
};

const Authorizing = () => <h3>Authorizing...</h3>;
