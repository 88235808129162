import { StatusConfig, StatusState } from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { string, object as yupObject } from 'yup';
import { useFormContext } from '../../forms';
import { RoutineExecutionForm, RoutineExecutionFormProps } from '../types';
import { StatusExecutionComponent } from './statusExecutionComponent';

export const getExecutionFormResolver = () => {
  const validationSchema = yupObject({
    status: string().required().nonNullable(),
  });

  return yupResolver(validationSchema);
};

export const getExecutionFormDefaultValues = (
  _: StatusConfig,
  state?: StatusState
) => {
  return {
    status: state?.status,
  };
};

export const StatusExecutionForm: RoutineExecutionForm<
  StatusConfig,
  StatusState
> = (props: RoutineExecutionFormProps<StatusConfig, StatusState>) => {
  const formKey = 'status';
  const { control, setValue } = useFormContext();

  const { field } = useController({
    control: control,
    name: formKey,
  });

  useEffect(() => {
    if (props.currentState?.state?.status) {
      setValue(formKey, props.currentState.state.status, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [props.currentState]);

  const onStatusChange = (status: string) => {
    setValue(formKey, status, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <StatusExecutionComponent
      statuses={props.config.statuses}
      value={field.value}
      onChange={onStatusChange}
    />
  );
};
