import { FC } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { TouchableButton } from '../touchableButton';

type LeftRightButtonProps = {
  label: string;
  onPress?: () => void;
  hideCaret?: boolean;
  disabled?: boolean;
  color?: string;
  style?: StyleProp<ViewStyle>;
  isLoading?: boolean;
};

export const LeftButton: FC<LeftRightButtonProps> = (
  props: LeftRightButtonProps
) => {
  return (
    <TouchableButton
      onPress={props.onPress}
      icon={!props.hideCaret ? 'chevron-left' : undefined}
      iconSize={28}
      label={props.label}
      disabled={props.disabled}
      color={props.color}
      style={props.style}
    />
  );
};

export const RightButton: FC<LeftRightButtonProps> = (
  props: LeftRightButtonProps
) => {
  return (
    <TouchableButton
      onPress={props.onPress}
      icon={!props.hideCaret ? 'chevron-right' : undefined}
      iconSize={28}
      label={props.label}
      right={true}
      disabled={props.disabled}
      color={props.color}
      style={props.style}
      isLoading={props.isLoading}
    />
  );
};
