import { PermissionValues as Permissions } from '@gripp/shared-logic';
import { Issue } from '../issues/components/issue';
import { getCurrentDimensions } from '../shared/hooks/useWindowDimensions';
import { assetPaths } from './assetPaths';
import { Asset } from './components/asset';
import { Assets } from './components/assets';
import { EditAsset } from './components/edit';

export const assetsRoutes = [
  {
    path: 'assets/:assetId',
    element: <Asset />,
    handle: {
      header: {
        titleKey: 'assets.menu.title',
        icon: 'qrcode',
      },
      requiredPermission: Permissions.asset.all,
    },
    children: [
      {
        path: 'activities/:issueId',
        element: <Issue />,
        loader: () => {
          const dimensions = getCurrentDimensions();
          const maxHeight = (dimensions?.height || 0) - 580;

          return {
            dimensions: dimensions,
            maxHeight: maxHeight,
          };
        },
      },
    ],
  },
  {
    path: assetPaths.root,
    element: <Assets />,
    handle: {
      header: {
        titleKey: 'assets.menu.title',
        icon: 'qrcode',
      },
      requiredPermission: Permissions.asset.all,
    },
  },
  {
    path: 'assets/:assetId/edit',
    element: <EditAsset />,
    handle: {
      header: {
        titleKey: 'topNav.editAsset',
        icon: 'qrcode',
      },
      requiredPermission: Permissions.asset.all,
    },
  },
];
