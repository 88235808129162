import { useEffect, useState } from 'react';
import { useFindByQuery } from '../../graphql';

export interface Role {
  id: string;
  name: string;
}

export const useRoles = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const { data, isLoading, invalidateQuery } = useFindByQuery({
    modelName: 'role',
    query: GET_ROLES,
  });

  useEffect(() => {
    if (data && data.items) {
      setRoles(data.items);
    }
  }, [data]);

  return {
    roles,
    isLoading,
    invalidateQuery,
  };
};

const GET_ROLES = `
  query FindRoles {
    findRoles(filter: {
      type: { eq: "ACCOUNT" }
    }) {
      items {
        id
        name
      }
    }
  }
`;
