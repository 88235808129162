import { useGrippContext } from '../../grippContext';
import { useStorageKeys } from '../../storage/useStorageKeys';

export const useSetRedirectUrl = (
  getRedirectUrl: (path: string) => Promise<string>
) => {
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();

  const setRedirectUrl = async (path: string): Promise<void> => {
    const redirectUrl = await getRedirectUrl(path);
    await storageHandler.setItem(storageKeys.redirectUrl, redirectUrl);
  };
  return setRedirectUrl;
};
