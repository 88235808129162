export interface AssetRoutineState<T extends RoutineState> {
  workspaceRoutineId: string;
  workspaceRoutineVersionId: string;
  state: T;
}

export interface RoutineState {
  updatedBy: string;
  updatedAt: Date;
  exceptionCount?: number;
}
