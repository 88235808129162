import { EnvConfig, ILogger } from '@gripp/shared-logic';
import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const env = EnvConfig.sentryEnv;
const sentryDsn = EnvConfig.sentryDsn;
const version = EnvConfig.version;

Sentry.init({
  dsn: sentryDsn,
  release: version,
  dist: version,
  debug: false,
  environment: env,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

Sentry.setTag('gripp_version', version);

interface ISentrWebLogger extends ILogger {
  wrap: (router: any) => any;
}

export const SentryWebLogger: ISentrWebLogger = {
  fatal(error: unknown, message?: string): void {
    Sentry.captureException(formatError(error, message), {
      level: 'fatal',
    });
  },
  error(error: unknown, message?: string): void {
    Sentry.captureException(formatError(error, message), {
      level: 'error',
    });
  },
  warning(message: string): void {
    Sentry.captureMessage(message, 'warning');
  },
  log(message: string): void {
    Sentry.captureMessage(message, 'log');
  },
  info(message: string): void {
    Sentry.captureMessage(message, 'info');
  },
  debug(message: string): void {
    Sentry.captureMessage(message, 'debug');
  },
  wrap: (router: any): any => {
    return Sentry.wrapCreateBrowserRouter(router);
  },
};

//todo: move to shared
const formatError = (error: unknown, message?: string) => {
  let eString: string;

  if (error instanceof Error) {
    eString = error.stack || error.toString();
  } else if (typeof error === 'object' && error !== null) {
    eString = JSON.stringify(error);
  } else {
    eString = String(error);
  }

  const errorMessage = message ? `${message} - ${eString}` : eString;

  return new Error(errorMessage);
};
