import { DateTypeFilter } from '@gripp/shared-logic';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import {
  KeyboardTypeOptions,
  Platform,
  Pressable,
  Text,
  View,
} from 'react-native';
import { IconButton } from 'react-native-paper';
import { useOpenedState } from '../../../hooks';
import { Colors } from '../../../themes';
import { StyledTextInput } from '../controlledTextInput';
import { datePickerStyle as styles } from './datePickerStyle';
import { DatePickerWheel } from './datePickerWheel';
import { DateRangePickerSelector } from './dateRangePickerSelector';
import { DateRangeSelectorProps } from './dateRangeSelector';

type DatePickerSelectorProps = DateRangeSelectorProps & {
  name: string;
  placeholder?: string;
  textArea?: boolean;
  innerRef?: any;
  keyboardType?: KeyboardTypeOptions;
  height?: number;
  label: string;
  isStartDate?: boolean;
};

export const DatePickerSelector = (props: DatePickerSelectorProps) => {
  const isWeb = Platform.OS === 'web';
  const datePickerStartDateRef = useRef<View>(null);
  const {
    onOpen: onDateRangePickerSelectorOpen,
    onClose: onDateRangePickerSelectorClose,
    isOpened: isDateRangePickerSelectorOpened,
  } = useOpenedState();

  const handleDateRangeSelector = () => {
    props.setValue('dateType', DateTypeFilter.CUSTOMDATE);
    onDateRangePickerSelectorOpen();
  };

  return (
    <Pressable onPress={handleDateRangeSelector} style={{ zIndex: 9 }}>
      <View
        style={[
          styles.dateRangeFieldContainer,
          isDateRangePickerSelectorOpened &&
            styles.dateRangeFieldContainerActive,
        ]}
      >
        <Controller
          control={props.control}
          name={props.name}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <Text style={styles.label}>{props.label}</Text>
              <View style={styles.inputContainer}>
                <StyledTextInput
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder={props.placeholder}
                  editable={false}
                  textArea={props.textArea}
                  innerRef={props.innerRef}
                  keyboardType={props.keyboardType}
                  height={props.height}
                  backgroundColor={'transparent'}
                  inputInnerStyle={styles.inputInnerStyle}
                  pointerEvents="none"
                />
                <IconButton
                  icon="calendar"
                  size={16}
                  iconColor={Colors.grayText}
                  style={styles.calendarIcon}
                />
              </View>
              <View ref={datePickerStartDateRef}>
                {isWeb ? (
                  <DateRangePickerSelector
                    control={props.control}
                    setValue={props.setValue}
                    onDateSelected={onChange}
                    anchorRef={datePickerStartDateRef}
                    isVisible={isDateRangePickerSelectorOpened}
                    onClose={onDateRangePickerSelectorClose}
                    value={value}
                    isStartDate={props.isStartDate}
                  />
                ) : (
                  <DatePickerWheel
                    control={props.control}
                    setValue={props.setValue}
                    onDateSelected={onChange}
                    isVisible={isDateRangePickerSelectorOpened}
                    onClose={onDateRangePickerSelectorClose}
                    isStartDate={props.isStartDate}
                  />
                )}
              </View>
            </>
          )}
        />
      </View>
    </Pressable>
  );
};
