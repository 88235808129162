import { RoutineType } from '@gripp/shared-logic';
import { CounterModule } from './counter';
import { QuickConfirmModule } from './quickConfirm';
import { StatusModule } from './status';
import { RoutineModuleDictionary } from './types';
import { WorkflowModule } from './workflow';

export const RoutineModules: RoutineModuleDictionary = {
  [RoutineType.Counter]: CounterModule,
  [RoutineType.Status]: StatusModule,
  [RoutineType.QuickConfirm]: QuickConfirmModule,
  [RoutineType.Workflow]: WorkflowModule,
};
