import { Audit } from '../../auth/domain/audit';
import { Location } from '../../location';
import { Media } from '../../media';
import { Workspace } from '../../workspace';

export interface Asset extends Audit {
  id: string;
  workspaces: Workspace[];
  coverImage: Media;
  media: Media[];
  name: string;
  description?: string;
  details?: string;
  lastScannedAt?: Date;
  lastAddress: string;
  lastLocation: Location;
}
