export const isValidNumber = (input: string) => {
  return /^-?\d+(\.\d+)?$/.test(input);
};

export const cleanNumber = (
  input: string,
  decimalPlaces?: number,
  allowNegative?: boolean
) => {
  // Trim negative if not allowed
  if (!allowNegative && input && input.length > 1 && input[0] === '-') {
    input = input.slice(1);
  }

  // Remove any invalid characters (anything that's not a digit, decimal point, or minus sign)
  let formattedInput = decimalPlaces
    ? input.replace(/[^0-9.-]/g, '')
    : input.replace(/[^0-9-]/g, '');

  // Prevent more than one decimal point
  const decimalPointCount = (formattedInput.match(/\./g) || []).length;
  if (decimalPointCount > 1) {
    formattedInput = formattedInput.replace(/\.(?=.*\.)/g, ''); // Keep only the first decimal point
  }

  // Prevent more than one minus sign and ensure it's at the beginning
  const minusSignCount = (formattedInput.match(/-/g) || []).length;
  if (minusSignCount > 1) {
    formattedInput = formattedInput.replace(/-(?=.*-)/g, ''); // Keep only the first minus sign
  }
  if (formattedInput.includes('-') && formattedInput.indexOf('-') > 0) {
    formattedInput = '-' + formattedInput.replace(/-/g, ''); // Move minus sign to the start if it's found elsewhere
  }

  // Trim the decimal part to the specified length
  const parts = formattedInput.split('.');
  if (parts.length === 2) {
    parts[1] = parts[1].slice(0, decimalPlaces);
    formattedInput = parts.join('.');
  }

  // Trim leading zeros from the integer part (including handling negative numbers)
  if (parts[0]) {
    const isNegative = parts[0][0] === '-';
    parts[0] = parts[0].replace(/^-?0+(?!$)/, isNegative ? '-' : ''); // Remove leading zeros, keep single zero
    formattedInput = parts.join('.');
  }

  return formattedInput;
};

export const incrementNumber = (
  input: string,
  increment: number,
  decimalPlaces?: number
) => {
  let newCount = 0;
  if (decimalPlaces) {
    const inputValueNum = parseFloat(input);
    let decimalIncrement = Math.pow(increment / 10, decimalPlaces); // 0.1, 0.01, 0.001
    decimalIncrement =
      increment < 0 && decimalPlaces === 2
        ? decimalIncrement * -1
        : decimalIncrement;
    newCount = (isNaN(inputValueNum) ? 0 : inputValueNum) + decimalIncrement;
    newCount = parseFloat(newCount.toFixed(decimalPlaces)); // to decimal significant figures
  } else {
    const inputNumber = parseInt(input);
    newCount = (isNaN(inputNumber) ? 0 : inputNumber) + increment;
  }
  return newCount;
};
