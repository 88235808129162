import { WorkflowQuestionConfig } from '../workflowQuestionConfig';
import { WorkflowResponseState } from '../workflowResponseState';

export interface RoutineQuestionConfig extends WorkflowQuestionConfig {
  workspaceRoutineId: string;
}

export interface RoutineResponseState extends WorkflowResponseState {
  workspaceRoutineId: string;
  workspaceRoutineVersionId: string;
}
