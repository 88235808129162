import { useDeleteMutation, Workspace } from '@gripp/shared-logic';
import { CustomAlert } from '@gripp/shared-ui';
import { useState } from 'react';
import { Button } from 'react-native-paper';

type DeleteWorkspaceProps = {
  workspace: Workspace;
};

export const DeleteWorkspace = (props: DeleteWorkspaceProps) => {
  const [showAlert, setShowAlert] = useState(false);
  const label = null;
  const { mutateAsync } = useDeleteMutation({
    modelName: 'workspace',
    query: DELETE_WORKSPACE,
  });

  const onDelete = async () => {
    const input = {
      id: props.workspace.id,
    };

    await mutateAsync({ input });
  };

  return (
    <>
      <Button icon="delete" onPress={() => setShowAlert(true)}>
        {label}
      </Button>
      <CustomAlert
        visible={showAlert}
        hideAlert={() => setShowAlert(false)}
        primaryButtonLabel="Delete"
        secondaryButtonLabel="Cancel"
        title={`Are you sure you want to delete this workspace? (${props.workspace.name})`}
        action={onDelete}
      />
    </>
  );
};

const DELETE_WORKSPACE = `
  mutation Mutation($input: MutateWorkspaceInput!) {
    deleteWorkspace(input: $input) {
      id
    }
  }
`;
