import {
  SignatureQuestionConfig,
  SignatureResponseState,
} from '@gripp/shared-logic';
import { RoutineQuestionModule } from '../types';
import { SignatureQuestionExecutionForm } from './signatureQuestionExecutionForm';
import { SignatureQuestionMessage } from './signatureQuestionMessage';

export const SignatureQuestionModule: RoutineQuestionModule<
  SignatureQuestionConfig,
  SignatureResponseState
> = {
  executionForm: SignatureQuestionExecutionForm,
  message: SignatureQuestionMessage,
};
