import {
  AssetRoutineState,
  RoutineConfig,
  RoutineState,
  RoutineType,
  Severity,
  WorkspaceAsset,
} from '@gripp/shared-logic';
import { FC } from 'react';
import { MessageComponent } from '../issue/types';
import { Control, Resolver } from 'react-hook-form';
import { StyleProp, ViewStyle } from 'react-native';

export type RoutineModule<C extends RoutineConfig, S extends RoutineState> = {
  header?: RoutineHeader<C, S>;
  buttons: RoutineButtonComponent<C, S>;
  executionForm: RoutineExecutionForm<C, S>;
  getExecutionFormResolver?: (C, S) => Resolver<any>;
  getExecutionFormDefaultValues?: (C, S) => any;
  adminForm?: RoutineAdminForm<C>;
  adminSchema?: any;
  message: MessageComponent;
};

export type RoutineModuleDictionary = Record<
  RoutineType,
  RoutineModule<any, any>
>;

export interface RoutineComponentStyleOverrides {
  buttonStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<ViewStyle>;
  buttonIcon?: Severity;
  buttonIconStyle?: StyleProp<ViewStyle>;
}

export interface RoutineComponentProps<
  C extends RoutineConfig,
  S extends RoutineState = RoutineState
> {
  config: C;
  currentState?: AssetRoutineState<S>;
  styleOverrides?: RoutineComponentStyleOverrides;
}

export type RoutineHeader<C extends RoutineConfig, S extends RoutineState> = FC<
  RoutineHeaderProps<C, S>
>;
export type RoutineHeaderProps<
  C extends RoutineConfig,
  S extends RoutineState
> = RoutineComponentProps<C, S>;

export type RoutineButtonComponent<
  C extends RoutineConfig,
  S extends RoutineState
> = FC<RoutineButtonProps<C, S>>;
export interface RoutineButtonProps<
  C extends RoutineConfig,
  S extends RoutineState
> extends RoutineComponentProps<C, S> {
  onClick: (additionalState?: any) => void;
}

export type RoutineExecutionForm<
  C extends RoutineConfig,
  S extends RoutineState
> = FC<RoutineExecutionFormProps<C, S>>;

export interface RoutineExecutionFormProps<
  C extends RoutineConfig,
  S extends RoutineState
> extends RoutineComponentProps<C, S> {
  onSubmit?: (data: any) => Promise<void>;
  workspaceAsset: WorkspaceAsset;
  isPending?: boolean;
  toggleProcessing?: (value: boolean) => void;
}

export type RoutineAdminForm<C extends RoutineConfig> = FC<
  RoutineAdminFormProps<C>
>;

export interface RoutineAdminFormProps<C extends RoutineConfig>
  extends RoutineComponentProps<C> {
  control: Control;
}
