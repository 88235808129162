import { useEffect, useState } from 'react';
import { useFindByQuery } from '../../graphql';
import { WorkspaceAsset } from '../domain';

export const useWorkspaceAsset = (
  // for now, we'll assume that only one workspace works with an Asset
  assetId: string,
  disabled?: boolean
) => {
  const [workspaceAsset, setWorkspaceAsset] = useState<
    WorkspaceAsset | undefined
  >(undefined);

  const filter = {
    asset: { eq: assetId },
  };
  const { data, isLoading, invalidateQuery } = useFindByQuery({
    modelName: 'workspaceAsset',
    query: FIND_WORKSPACE_ASSET,
    variables: { filter },
    options: {
      enabled: disabled === undefined || !disabled,
    },
  });

  useEffect(() => {
    if (!data) return;

    const result = data.items[0];
    setWorkspaceAsset(result as WorkspaceAsset);
  }, [data]);

  return {
    workspaceAsset,
    isLoading,
    invalidateQuery,
  };
};

const FIND_WORKSPACE_ASSET = `
  query FindWorkspaceAssets($filter: WorkspaceAssetFilter) {
    findWorkspaceAssets(filter: $filter) {
      items {
        id
        asset {
          id
          name
          description
          details
          lastScannedAt
          coverImage {
            id
            name
            extension
            status
            type
          }
          media {
            id
            name
            type
            extension
            status
          }
          lastAddress
          lastLocation {
            coordinates
          }
          updatedAt
        }
        routineState {
          workspaceRoutineId
          workspaceRoutineVersionId
          state
        }
        workspace {
          id
        }
        routines {
          id
          order
          type
          config
          deactivated
          currentVersion {
            id
          }
        }
        groups {
          id
          name
        }
        routineOrder {
          workspaceRoutineId
          order
        }
      }
    }
  }
`;
