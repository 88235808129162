import { FindQueryVariables } from '../types';

/**
 * cache keys for useQuery & cache invalidation
 *    see for more info: https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const cacheKeys = (modelName: string) => {
  const keys = {
    all: [modelName.toLowerCase()] as const,
    lists: () => [...keys.all, 'list'] as const,
    list: (findVars?: FindQueryVariables) =>
      [...keys.lists(), findVars] as const,
    details: () => [...keys.all, 'details'] as const,
    detail: (id: string) => [...keys.details(), id] as const,
  };

  return keys;
};
