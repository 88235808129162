import { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { getMediaTitleForWidth } from './mediaUtils';

export type ProcessingPlaceholderProps = {
  processing: boolean;
  width: number;
  height: number;
  displayMode: 'outlined' | 'card';
  url: string;
  name: string;
  extension: string;
  children: React.ReactNode;
};

const MAX_RETRIES = 5;
const RETRY_DELAY = 2000;

export const ProcessingPlaceholder = (props: ProcessingPlaceholderProps) => {
  const [checkingResource, setCheckingResource] = useState(props.processing);

  //todo: turn into a hook for further reuse
  useEffect(() => {
    let retries = 0;
    let timeoutId: NodeJS.Timeout | null = null;
    setCheckingResource(props.processing);
    const checkResource = () => {
      fetch(props.url, { method: 'HEAD' })
        .then((response) => {
          if (response.ok) {
            setCheckingResource(false);
          } else if (retries < MAX_RETRIES) {
            retries++;
            timeoutId = setTimeout(checkResource, RETRY_DELAY);
          }
        })
        .catch((e) => {
          console.error('Failed to check resource:', e);
          if (retries < MAX_RETRIES) {
            retries++;
            timeoutId = setTimeout(checkResource, RETRY_DELAY);
          }
        });
    };

    if (props.processing && props.url) {
      checkResource();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [props.processing, props.url]);

  const containerStyle =
    props.displayMode === 'outlined' ? styles.outlined : styles.card;

  return checkingResource ? (
    <View
      style={[containerStyle, { width: props.width, height: props.height }]}
    >
      <ActivityIndicator color={Colors.primary} size={24} />
      <Text style={styles.mediaTitle}>
        {getMediaTitleForWidth(props.name, props.extension, props.width)}
      </Text>
    </View>
  ) : (
    <>{props.children}</>
  );
};

const styles = StyleSheet.create({
  outlined: {
    borderWidth: 0.5,
    borderColor: Colors.grayDivider,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: Colors.secondaryGrayHeader,
  },
  card: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 3px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: Colors.secondaryGrayHeader,
  },
  mediaTitle: {
    marginTop: 8,
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 12,
    width: '100%',
  },
});
