import { FC, ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

export type TopBarProps = {
  title: string;
  left?: ReactNode;
  right?: ReactNode;
  additionalHeaderBlock?: ReactNode;
  style?: StyleProp<ViewStyle>;
  hideBottomBorder?: boolean;
};

export type TopComponentBarProps = TopBarProps & {
  confirmBack?: boolean;
  showSave?: boolean;
  onRightPress?: () => void;
  rightPressDisabled?: boolean;
  showAlert?: () => void;
  additionalHeaderBlock?: ReactNode;
  saveLabel?: string;
  onCancel?: () => void;
};

export type TopComponent = FC<TopComponentBarProps>;

export type TopComponentBarBaseProps = TopComponentBarProps & {
  backButton: ReactNode;
};
