import { useEffect, useState } from 'react';
import { SortDirectionEnum, useFindByQuery } from '../../graphql';
import { WorkspaceRoutine } from '../domain';

export const useFilteredWorkspaceRoutines = (
  filter: any,
  workspaceId?: string,
  disabled?: boolean
): WorkspaceRoutine[] => {
  const [routines, setRoutines] = useState<WorkspaceRoutine[]>([]);

  const orderBy = { field: 'order', order: SortDirectionEnum.Asc };

  const { data } = useFindByQuery({
    modelName: 'WorkspaceRoutine',
    query: GET_STATEFUL_WORKSPACE_ROUTINES,
    variables: {
      filter,
      orderBy,
    },
    options: {
      enabled: workspaceId !== undefined && !disabled,
    },
  });

  useEffect(() => {
    if (data) {
      setRoutines(data.items);
    }
  }, [data]);

  return routines;
};

const GET_STATEFUL_WORKSPACE_ROUTINES = `
  query GetWorkspaceRoutines($filter: WorkspaceRoutineFilter, $orderBy: OrderByInput) {
    findWorkspaceRoutines(filter: $filter, orderBy: $orderBy) {
      items {
        id
        name
        type
        order
        config
        currentVersion {
          id
        }
      }
    }
  }
`;
