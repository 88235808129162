import {
  AssetRoutineState,
  Issue,
  useCreateMutation,
  useCurrentLocation,
  WorkspaceAsset,
  WorkspaceRoutine,
} from '@gripp/shared-logic';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Colors } from '../../../themes/colors';
import CustomAlert from '../../customAlert';
import { FormContext } from '../../forms';
import { KeyboardSafeView } from '../../keyboard/keyboardSafeView';
import { TopComponent } from '../../navigation';
import { RoutineExecutionForm, RoutineModule } from '../types';
import { CommonSubmitButton } from './commonSubmitButton';

export type RoutineFormProps = {
  workspaceAsset: WorkspaceAsset;
  module: RoutineModule<any, any>;
  routine?: WorkspaceRoutine;
  state?: AssetRoutineState<any>;
  additionalState?: any;
  onCompleted: () => Promise<void>;
  onCancel?: () => void;
  sourceIssue?: Issue;
  topBar: TopComponent;
};

export const RoutineForm = (props: RoutineFormProps) => {
  const { t } = useTranslation();
  const [isProcessing, toggleProcessing] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const { mutateAsync, isPending } = useCreateMutation({
    query: CREATE_ASSET_ROUTINE,
    modelName: 'assetRoutine',
  });
  const { getLocation } = useCurrentLocation();

  const ExecutionForm: RoutineExecutionForm<any, any> | undefined = useMemo(
    () => props.module.executionForm,
    [props.module.executionForm]
  );

  const formOptions = useMemo(() => {
    const options: any = {
      mode: 'onChange',
    };
    const formResolver =
      props.module.getExecutionFormResolver &&
      props.module.getExecutionFormResolver(props.routine?.config, props.state);
    const defaultFormData =
      props.module.getExecutionFormDefaultValues &&
      props.module.getExecutionFormDefaultValues(
        props.routine?.config,
        props.state
      );

    if (formResolver) {
      options.resolver = formResolver;
    }
    if (defaultFormData) {
      options.defaultValues = defaultFormData;
    }

    return options;
  }, [props.module, props.routine?.config, props.state]);

  const formMethods = useForm(formOptions);

  const onSubmit = async (data: any) => {
    if (isProcessing) return;

    toggleProcessing(true);
    const input: any = {
      asset: { id: props.workspaceAsset.asset.id },
      workspaceAsset: { id: props.workspaceAsset.id },
      workspace: { id: props.workspaceAsset.workspace.id },
      routine: { id: props.routine?.id },
      routineVersion: { id: props.routine?.currentVersion?.id },
      data: data,
      location: await getLocation(),
    };
    if (props.sourceIssue) input.sourceIssue = { id: props.sourceIssue.id };

    await mutateAsync(
      { input },
      {
        onSettled: async () => {
          await props.onCompleted();
          toggleProcessing(false);
        },
      }
    );
  };

  return (
    <>
      <FormContext.Provider value={formMethods}>
        <props.topBar
          title={props.routine?.config?.routineButtonText ?? ''}
          onCancel={() => {
            setConfirmCancel(false);
            props.onCancel && props.onCancel();
          }}
          showSave={false}
          confirmBack={formMethods.formState.isDirty}
          showAlert={() => setConfirmCancel(true)}
        />
        {ExecutionForm && (
          <KeyboardSafeView style={styles.container}>
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={styles.scrollviewContainer}
              keyboardShouldPersistTaps="handled"
            >
              <ExecutionForm
                config={props.routine?.config}
                currentState={props.state}
                workspaceAsset={props.workspaceAsset}
                toggleProcessing={toggleProcessing}
              />
              <View style={styles.row}>
                <CommonSubmitButton
                  formState={formMethods.formState}
                  onPress={formMethods.handleSubmit(onSubmit)}
                  buttonText="submit"
                  isPending={isProcessing || isPending}
                />
              </View>
            </ScrollView>
          </KeyboardSafeView>
        )}
      </FormContext.Provider>
      <CustomAlert
        visible={confirmCancel}
        title={t('routines.modalCancel.title')}
        primaryButtonLabel={t('routines.modalCancel.primary')}
        secondaryButtonLabel={t('routines.modalCancel.secondary')}
        action={() => {
          props.onCancel && props.onCancel();
        }}
        hideAlert={() => setConfirmCancel(false)}
      />
    </>
  );
};

const CREATE_ASSET_ROUTINE = `
  mutation CreateAssetRoutine($input: CreateAssetRoutineInput!) {
    createAssetRoutine(input: $input) {
      id
    }
  }
`;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    padding: 20,
    backgroundColor: Colors.primaryGrayBackground,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 10,
  },
  scrollviewContainer: {
    flex: 1,
    marginBottom: 20,
  },
});
