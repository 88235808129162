import { useFindByQuery } from '../../graphql';
import { Workspace } from '../domain';
import { useState, useEffect } from 'react';

export const useAllWorkspaces = (): Workspace[] => {
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);

  const { data } = useFindByQuery({
    modelName: 'Workspace',
    query: GET_WORKSPACES,
  });

  useEffect(() => {
    if (!data) return;

    setWorkspaces(
      data.items.map((w: any) => ({
        id: w.id,
        name: w.name,
      }))
    );
  }, [data]);

  return workspaces;
};

const GET_WORKSPACES = `
  query GetAllWorkspaces {
    findWorkspaces(orderBy: {field: "name"}) {
      items {
        id
        name
      }
    }
  }
`;
