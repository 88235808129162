import { RoutineModules } from '../routine';
import { MessageComponent, MessageComponentProps } from './types';

export const RoutineMessage: MessageComponent = (
  props: MessageComponentProps
) => {
  const routinModule =
    RoutineModules[props.message.assetRoutine.routineVersion.type];

  const Message = routinModule.message;

  return <Message {...props} />;
};
