import { WorkflowQuestionConfig } from '../workflowQuestionConfig';
import { WorkflowResponseState } from '../workflowResponseState';
import { QuestionMultipleValueExceptionConfig } from './questionException';

export interface CheckboxQuestionConfig
  extends WorkflowQuestionConfig,
    QuestionMultipleValueExceptionConfig {
  options: string[];
}

export interface CheckboxResponseState extends WorkflowResponseState {
  options: [string];
}
