import {
  useGrippContext,
  useSendAuthCode,
  useStorageKeys,
} from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Button, Text, TextInput } from 'react-native-paper';
import { string, object as yupObject } from 'yup';
import { Colors } from '../../themes';
import { FieldCheckIcon } from './fieldCheckIcon';
import { LoginProps } from './props';
import { styles } from './styles';
import { ViewError } from './viewError';

export default function EmailCodeLogin(props: LoginProps) {
  const { t } = useTranslation();
  const [error] = useState<string | undefined>(undefined);
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();

  const formSchema = yupObject({
    email: string().required().email(),
  });

  const { sendAuthCodeAsync, isPending: isLoading } = useSendAuthCode();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    getFieldState,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: any) => {
    const input = {
      email: data.email,
    };

    await sendAuthCodeAsync(input, props.onCodeSent!);
  };

  return (
    <View
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 20,
      }}
    >
      <Controller
        control={control}
        name="email"
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            mode="outlined"
            outlineStyle={{ borderWidth: 0, borderRadius: 6 }}
            style={styles.textInput}
            value={value}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
            placeholder={t('login.email')}
            placeholderTextColor={Colors.grayFill}
            keyboardType="email-address"
            right={FieldCheckIcon({
              invalid: getFieldState('email').invalid,
              value: value,
            })}
          />
        )}
      />
      <View style={styles.containerButton}>
        <Button
          mode="contained"
          disabled={!isValid || isLoading}
          onPress={handleSubmit(onSubmit)}
          loading={isLoading}
          style={[
            styles.button,
            !isValid || isLoading
              ? styles.disabledButton
              : styles.enabledButton,
          ]}
        >
          <Text style={styles.buttonText}>{t('login.login')}</Text>
        </Button>
      </View>
      <ViewError error={error} />
    </View>
  );
}
