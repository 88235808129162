// If adding a new field, ensure NotificationPreferenceFields is updated in the functions project to prevent overwrite
export const NotificationPreferenceFields = `
  notificationPreferences {
    issueReplies
    issueSeverityChanges
    issueTitleChanges
    newIssueSeverities
    groupIds
    schedule {
      scheduleDays
      startTime
      endTime
    }
  }
`;
