import { Colors } from '@gripp/shared-ui';
import { Buffer } from 'buffer';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import SignatureCanvas from 'react-signature-canvas';

type SignaturePadProps = {
  setImageString: (str: string) => void;
  imgFile: (file: File | null) => void;
};

export const SignaturePad = (props: SignaturePadProps) => {
  const signatureCanvasRef = useRef<SignatureCanvas>(null);
  const [hasSignature, setHasSignature] = useState(false);
  const { t } = useTranslation();

  const base64ToBlob = (base64: string, contentType = ''): Blob => {
    const byteCharacters = Buffer.from(base64, 'base64');
    return new Blob([byteCharacters], { type: contentType });
  };

  const blobToFile = (blob: Blob, fileName: string): File => {
    return new File([blob], fileName, { type: blob.type });
  };

  const clearSignature = () => {
    signatureCanvasRef.current?.clear();
    setHasSignature(false);
    props.setImageString('');
    props.imgFile(null);
  };

  const saveSignature = () => {
    if (!signatureCanvasRef.current) return;

    const signature = signatureCanvasRef.current
      .getCanvas()
      .toDataURL('image/png');

    // Convert base64 image to Blob
    const base64Image = signature.replace('data:image/png;base64,', '');
    const blob = base64ToBlob(base64Image, 'image/png');

    if (blob) {
      const imgStr = base64Image.slice(
        base64Image.length - 30,
        base64Image.length
      );

      // convert to file
      const file = blobToFile(blob, 'signature.png');

      props.setImageString(imgStr);
      props.imgFile(file);
    } else {
      console.error('could not get blob');
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.signatureContainer}>
        <SignatureCanvas
          penColor="black"
          canvasProps={{ style: styles.signaturePad }}
          backgroundColor={Colors.white}
          onEnd={() => setHasSignature(true)}
          ref={signatureCanvasRef}
        />
      </View>
      <View style={styles.buttonContainer}>
        <Pressable style={styles.button} onPress={clearSignature}>
          <Text style={styles.buttonLabel}>
            {t('routines.signature.clear')}
          </Text>
        </Pressable>

        <Pressable
          disabled={!hasSignature}
          style={[
            styles.button,
            !hasSignature && { backgroundColor: Colors.grayDisabled },
          ]}
          onPress={saveSignature}
        >
          <Text style={styles.buttonLabel}>{t('routines.signature.done')}</Text>
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  signatureContainer: {
    flex: 1,
    width: '100%',
    height: 300,
    maxHeight: 300,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.grayText,
    borderRadius: 8,
    marginBottom: 20,
    overflow: 'hidden',
  },
  signaturePad: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  beginDrawingLabel: {
    fontSize: 18,
    fontWeight: '400',
    color: Colors.grayText,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
    width: '100%',
    marginTop: 20,
  },
  button: {
    backgroundColor: Colors.primary,
    height: 44,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.white,
  },
});

export default SignaturePad;
