import { StyleSheet, View } from 'react-native';
import { Colors } from '../../themes';

export type Step = {
  key: string;
  title: string;
  description: string[];
  image: string;
  label: string;
};

type Props = {
  activeStep: number;
  steps: Step[];
};

export const Stepper = ({ activeStep, steps }: Props) => {
  return (
    <View style={styles.container}>
      {steps.map((step, index) => (
        <View
          key={step.key}
          style={[styles.step, index <= activeStep && styles.filled]}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 60,
  },
  step: {
    width: 10,
    height: 10,
    backgroundColor: Colors.grayDivider,
    borderRadius: 5,
  },
  filled: {
    backgroundColor: Colors.primary,
  },
});
