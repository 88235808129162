import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Dimensions, Modal, Platform, StyleSheet, View } from 'react-native';
import { string, object as yupObject } from 'yup';
import { useAutoFocusRef } from '../../hooks';
import { Spacing } from '../../styles';
import { Colors } from '../../themes/colors';
import { TopModalBar } from '../modals/topModalBar';
import { ControlledTextInput } from './controlledTextInput';
import { SaveFormTop } from './saveFormTop';

type SingleInputModalFormProps = {
  id?: string;
  formTitle: string;
  saveLabel?: string;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (inputValue: string) => void;
  currentInputValue?: string;
};

export const SingleInputModalForm = (props: SingleInputModalFormProps) => {
  const isDesktop = () => Platform.OS === 'web';

  const formSchema = yupObject({
    inputValue: string().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    values: {
      inputValue: props.currentInputValue ?? '',
    },
  });

  const onSubmit = async (data: any) => {
    props.onSubmit(data.inputValue);
    reset({ inputValue: props.currentInputValue });
  };

  const handleCancel = () => {
    props.onCancel();
    reset({ inputValue: props.currentInputValue });
  };

  const elementFocusCallBack = useAutoFocusRef();

  return (
    <Modal
      id={props.id}
      onRequestClose={handleCancel}
      visible={props.isOpen}
      animationType={isDesktop() ? 'none' : 'slide'}
      transparent={isDesktop()}
      presentationStyle={isDesktop() ? 'overFullScreen' : 'pageSheet'}
    >
      <View style={isDesktop() && styles.desktopWrapper}>
        <View
          style={isDesktop() ? styles.desktopContainer : styles.mobileContainer}
        >
          <SaveFormTop
            isDirty={isDirty}
            isValid={isValid}
            onSubmit={handleSubmit(onSubmit)}
            title={props.formTitle}
            saveLabel={props.saveLabel}
            saveButtonType="text"
            topBar={TopModalBar}
            onCancel={handleCancel}
          >
            <View style={styles.formContainer}>
              <ControlledTextInput
                control={control}
                name="inputValue"
                innerRef={elementFocusCallBack}
              />
            </View>
          </SaveFormTop>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  formContainer: {
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    paddingTop: Spacing.basePadding.paddingVertical,
  },
  desktopWrapper: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: Dimensions.get('window').height / 4,
    backgroundColor: Colors.blackInactive,
  },
  desktopContainer: {
    backgroundColor: Colors.primaryGrayBackground,
    width: 560,
    minHeight: 167,
    borderRadius: 12,
  },
  mobileContainer: {
    backgroundColor: Colors.primaryGrayBackground,
    height: '100%',
  },
});
