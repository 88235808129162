import { SortDirectionEnum, useInfiniteFindByQuery } from '../../graphql';
import { ISSUE_MESSAGE_FRAGMENT } from './issueMessageFragment';

export const ISSUE_MESSAGE_PAGE_COUNT = 20;

export const useFindIssueMessages = (issueId: string) => {
  return useInfiniteFindByQuery({
    modelName: 'issueMessage',
    query: FIND_ISSUE_MESSAGES,
    orderBy: {
      field: 'updatedAt',
      order: SortDirectionEnum.Desc,
    },
    filter: {
      issue: { eq: issueId },
    },
    pageSize: ISSUE_MESSAGE_PAGE_COUNT,
  });
};

const FIND_ISSUE_MESSAGES = `
  query findIssueMessages($filter: IssueMessageFilter, $page: PageRequest, $orderBy: OrderByInput) {
    findIssueMessages(filter: $filter, page: $page, orderBy: $orderBy) {
      items {
        ${ISSUE_MESSAGE_FRAGMENT}
        promptRoutine {
          id
          name
          currentVersion {
            id
          }
          type
          config
        }
      }
      count
      offset
      limit
    }
  }
`;
