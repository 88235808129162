import { SingleStateConfig } from './routineConfig';
import { RoutineState } from './routineState';

export interface CounterBaseConfig {
  uom: string;
  tally?: boolean;
  decimal?: boolean;
  decimalPlaces?: number;
  placeholder?: string;
}

export interface CounterConfig extends SingleStateConfig, CounterBaseConfig {}

export interface CounterResponse {
  count: number;
}
export interface CounterState extends RoutineState, CounterResponse {}
