import { Phone, useCustomMutation } from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { string, object as yupObject } from 'yup';
import { PhoneValidator } from '../forms/controlledPhoneInput';

type useAcceptInvitationFormProps = {
  invitationId: string;
  onAccept: (
    success: boolean,
    phone: Phone | undefined,
    email: string,
    reset?: () => void
  ) => void;
};

export const useAcceptInvitationForm = (
  props: useAcceptInvitationFormProps
) => {
  const formSchema = yupObject({
    firstName: string().required(),
    lastName: string().optional().nullable(),
    jobTitle: string().optional().nullable(),
    email: string().email().optional().nullable(),
    phone: PhoneValidator.optional().nullable(),
  }).test('email or phone', (value) => !!(value.phone || value.email));

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    values: {
      firstName: '',
      lastName: '',
      jobTitle: '',
      email: '',
      phone: null,
    },
  });

  const { mutateAsync } = useCustomMutation({
    query: ACCEPT_INVITATION,
  });

  const onSubmit = async (data: any) => {
    const input = {
      invitationId: props.invitationId,
      name: {
        first: data.firstName,
        last: undefinedIfEmpty(data.lastName),
      },
      jobTitle: undefinedIfEmpty(data.jobTitle),
      phone: data.phone,
      email: data.email,
    };

    await mutateAsync(
      { input },
      {
        onSettled: (data: any) => {
          const result = data?.authentication?.acceptInvitation;
          props.onAccept(result?.success, input.phone, input.email, reset);
        },
      }
    );
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    formState: { isValid, isDirty },
  };
};

const undefinedIfEmpty = (value: string) => {
  return value === '' ? undefined : value;
};

const ACCEPT_INVITATION = `
  mutation AcceptInvitation($input: AcceptInvitationInput!) {
    authentication {
      acceptInvitation(input: $input) {
        message
        success
      }
    }
  }
`;
