import {
  RoutineType,
  SortDirectionEnum,
  useInfiniteFindByQuery,
} from '@gripp/shared-logic';
import {
  Colors,
  ControlledSelector,
  CounterRoutineTrigger,
  DefaultRoutineTrigger,
} from '@gripp/shared-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import { SegmentedButtons } from 'react-native-paper';
import { FIND_ASSETS, FIND_GROUPS, useFindWorkspaceAssets } from './operations';
import {
  WhatItemType,
  WhatStepProps,
  WhenStepProps,
  WhyStepProps,
} from './types';

const PAGE_SIZE = 30;
export const WhatItemTypeToggleButton = (props: any) => {
  const buttons = Object.values(WhatItemType).map((item) => ({
    value: item,
    label: item,
    checkedColor: Colors.white,
    uncheckedColor: Colors.primaryDark,
    labelStyle: styles.toogleLabelStyle,
    style: styles.toggleContainer,
  }));

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value: selected } }) => (
        <SegmentedButtons
          theme={{
            colors: {
              secondaryContainer: Colors.primary,
            },
          }}
          onValueChange={(item) => {
            onChange(item);
          }}
          density="small"
          value={selected}
          buttons={buttons}
        />
      )}
    />
  );
};

export const WhatStep = (props: WhatStepProps) => {
  const [keyword, setKeyword] = useState('');
  const filter = useMemo(() => {
    const baseFilter = {} as any;
    baseFilter[
      props.type === WhatItemType.Assets ? 'workspaces' : 'workspace'
    ] = {
      eq: props.workspaceId,
    };
    if (keyword) {
      baseFilter.name = { contains: keyword };
    }
    return baseFilter;
  }, [props.workspaceId, props.type, keyword]);

  const updateSearch = async (term: string) => {
    setKeyword(term);
  };

  const searchPlaceholder = useMemo(() => {
    return props.t(
      props.type === WhatItemType.Assets
        ? 'alerts.create.assetSearchPlaceholder'
        : 'alerts.create.groupSearchPlaceholder'
    );
  }, [props.type]);

  const queryObject = useMemo(() => {
    const orderBy = { field: 'name', order: SortDirectionEnum.Asc };
    const query =
      props.type === WhatItemType.Assets ? FIND_ASSETS : FIND_GROUPS;
    const modelName = props.type === WhatItemType.Assets ? 'Asset' : 'Group';

    return {
      modelName: modelName,
      query: query,
      filter: filter,
      orderBy: orderBy,
      pageSize: PAGE_SIZE,
    };
  }, [props.type, filter]);

  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useInfiniteFindByQuery(queryObject);

  const [whatItems, setWhatItems] = useState<any[]>([]);

  useEffect(() => {
    if (!data || !data.pages || data.pages.length === 0) return;

    const items = data.pages
      .flatMap((page: any) => page?.items)
      .map((item: any) => ({
        id: item.id,
        label: item.name,
      })) as any[];

    setWhatItems(items);
  }, [data]);

  const onScrollEnd = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  return (
    <ControlledSelector
      control={props.control}
      name={props.name}
      items={whatItems}
      isLoading={isLoading || isFetchingNextPage}
      onScrollEnd={onScrollEnd}
      searchable
      searchOutline
      searchAutoFocus
      onSearch={updateSearch}
      searchPlaceholder={searchPlaceholder}
    />
  );
};

export const WhyStep = (props: WhyStepProps) => {
  const { data } = useFindWorkspaceAssets(
    props.whatItemType,
    props.whatItemId,
    !!props.whatItemId
  );

  const whyItems = useMemo(() => {
    if (!data || !data.items || data.items.length === 0) return [];

    const totalAssetsCount = data.items.length;
    const routineMap = new Map();
    const allRoutines = data.items.flatMap((wa) =>
      (wa.routines || []).filter((r) => {
        routineMap.set(r.id, (routineMap.get(r.id) || 0) + 1);
        return true;
      })
    );
    return [
      ...new Map(
        allRoutines.map((r) => [
          r.id,
          {
            id: r.id,
            label: r.name,
            type: r.type,
            infoLabel:
              totalAssetsCount > 1
                ? `${routineMap.get(r.id)}/${totalAssetsCount} ${props.t(
                    'alerts.create.asset(s)'
                  )}`
                : undefined,
          },
        ])
      ).values(),
    ] as any[];
  }, [data]);

  return (
    whyItems.length > 0 && (
      <ControlledSelector
        control={props.control}
        name={props.name}
        items={whyItems}
      />
    )
  );
};

export const WhenStep = (props: WhenStepProps) => {
  return (
    props.whyItem?.id &&
    (props.whyItem.type === RoutineType.Counter ? (
      <CounterRoutineTrigger
        control={props.control}
        name={props.name}
        workspaceRoutineId={props.whyItem.id}
      />
    ) : (
      <DefaultRoutineTrigger
        control={props.control}
        name={props.name}
        workspaceRoutineId={props.whyItem.id}
        type={props.whyItem.type}
      />
    ))
  );
};

const styles = StyleSheet.create({
  toogleLabelStyle: {
    fontSize: 14,
    lineHeight: 14,
  },
  toggleContainer: {
    width: 80,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.primary,
  },
});
