import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { FindByQueryArgs, FindByQueryResults } from '../types';
import { GraphbackOperationType, getDataObject } from './getDataObject';
import { cacheKeys } from './cacheKeys';
import { useFetcher } from './useFetcher';

/**
 * shareable logic for using a graphback findBy query
 **/

export const useFindByQuery = (opts: FindByQueryArgs): FindByQueryResults => {
  const queryClient = useQueryClient();
  const cacheKey = cacheKeys(opts.modelName).list(opts.variables);

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries({ queryKey: cacheKey });
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: cacheKey as QueryKey,
    queryFn: useFetcher(opts.query).bind(null, opts.variables),
    ...opts.options,
    select: (data) => {
      return getDataObject(data, opts.modelName, GraphbackOperationType.find);
    },
  });

  return {
    data,
    isLoading,
    invalidateQuery,
    refetch,
  };
};
