import {
  Colors,
  EditAsset as EditAssetBase,
  EditAssetStyles,
  SaveFormBottom as SaveForm,
  Spacing,
} from '@gripp/shared-ui';
import { StyleSheet } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { MediaPicker } from '../../shared/components/media/mediaPicker';

export const EditAsset = () => {
  const params = useParams();
  const { assetId } = params;
  const navigate = useNavigate();

  const onDeleted = async () => {
    navigate('/assets');
  };

  return (
    <EditAssetBase
      assetId={assetId as string}
      mediaPicker={MediaPicker}
      saveForm={SaveForm}
      styles={styles}
      onDeleted={onDeleted}
    />
  );
};

const styles = StyleSheet.create<EditAssetStyles>({
  imageGalleryContainer: {
    borderTopColor: Colors.grayDivider,
    borderTopWidth: 1,
    borderBottomColor: Colors.grayDivider,
    borderBottomWidth: 1,
    paddingTop: Spacing.basePadding.paddingVertical,
    paddingBottom: Spacing.basePadding.base,
  },
  container: {
    backgroundColor: Colors.primaryGrayBackground,
    maxHeight: 850,
    flex: 1,
  },

  contentContainer: {
    paddingVertical: Spacing.basePadding.paddingVertical,
    flex: 1,
  },
});
