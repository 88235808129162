import React from 'react';
import { Image, View } from 'react-native';

export function Logo({ width = 163, height = 38 }) {
  return (
    <View>
      <Image
        source={require('../../../static-assets/logo.png')}
        style={{ width: width, height: height }}
      />
    </View>
  );
}

export default Logo;
