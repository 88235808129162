import { useCreateMutation, useCustomQuery } from '../../graphql';
import { Reaction } from '../domain';

export const useAddIsueMessageReaction = () => {
  return useCreateMutation({
    modelName: 'issueMessage',
    query: ADD_USER_REACTION_MESSAGE,
  });
};

export const useDeleteIsueMessageReaction = () => {
  return useCreateMutation({
    modelName: 'issueMessage',
    query: DELETE_USER_REACTION_MESSAGE,
  });
};

export const useGetUsersIsueMessageReaction = (reactions: Reaction[] = []) => {
  const userIds = reactions?.map((reaction) => reaction.createdBy) ?? [];

  return useCustomQuery({
    query: GET_USER_INFORMATION_ISSUE_MESSAGE_REACTION,
    cacheKey: [],
    variables: {
      accountIds: userIds,
    },
    options: {
      enabled: userIds.length > 0,
    },
  });
};

const ADD_USER_REACTION_MESSAGE = `
  mutation AddReaction($input: AddReactionInput!) {
    issueMessage {
      addReaction(input: $input) {
        message
        success
      }
    }
  }
`;

const DELETE_USER_REACTION_MESSAGE = `
  mutation DeleteReactionIssue($input: DeleteReactionInput!) {
    issueMessage {
      deleteReaction(input: $input) {
        success
      }
    }
  }
`;

const GET_USER_INFORMATION_ISSUE_MESSAGE_REACTION = `
  query ListReactionUsers($accountIds: [String]!) {
    account {
      listReactionUsers(accountIds: $accountIds) {
        count
        items {
          id
          name {
            first
            last
          }
        }
      }
    }
  }
`;
