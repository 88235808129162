import {
  FeatureName,
  Issue,
  Message,
  Reaction,
  useAddIsueMessageReaction,
  useCurrentAccount,
  useDeleteIsueMessageReaction,
  useGetUsersIsueMessageReaction,
  useIsFeatureEnabled,
} from '@gripp/shared-logic';
import { useMemo, useState } from 'react';
import {
  FlatList,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { Avatar, Text } from 'react-native-paper';
import { EmojiType } from 'rn-emoji-keyboard';
import { useOpenedState } from '../../hooks';
import { Colors } from '../../themes';
import { SingleEmoji } from '../emoji/singleEmoji';
import { NewReactionButton } from './newReaction';

type ReactionlistProps = {
  onOpenNewReaction: () => void;
  issue: Issue;
  message: Message;
};
type UserInfoReaction = {
  name: {
    first: string;
    last: string;
  };
  id: string;
  reactionId: string;
};
type UserInfoModal = {
  users: UserInfoReaction[];
  reactionType: EmojiType['emoji'];
};
type ReactionMap = {
  reaction: Reaction;
  count: number;
  users: UserInfoReaction[];
};

export const ReactionList = (props: ReactionlistProps) => {
  const { onOpenNewReaction } = props;
  const isUserReactionMessageEnabled = useIsFeatureEnabled(
    FeatureName.IssueMessageReaction
  );
  const isWeb = Platform.OS === 'web';
  const { mutateAsync: DeleteReactionIssue, isPending: isPendingDeleteEmoji } =
    useDeleteIsueMessageReaction();
  const { data: usersReaction } = useGetUsersIsueMessageReaction(
    props?.message?.reactions
  );
  const { isOpened, onOpen, onClose } = useOpenedState();
  const { account } = useCurrentAccount();
  const { mutateAsync: AddReaction, isPending: isPendingNewEmoji } =
    useAddIsueMessageReaction();

  const [usersReactions, setUsersReactions] = useState<UserInfoModal>({
    users: [],
    reactionType: '',
  });

  const reactionCounts = useMemo(() => {
    const users = usersReaction?.account?.listReactionUsers?.items ?? [];

    return Object.values(
      (props.message?.reactions || []).reduce<Record<string, ReactionMap>>(
        (acc, reaction) => {
          if (!acc[reaction.reactionType]) {
            acc[reaction.reactionType] = {
              reaction,
              count: 0,
              users: [],
            };
          }
          const user = users.find(
            (user: UserInfoReaction) => user.id === reaction.createdBy
          );

          if (user) {
            acc[reaction.reactionType].users.push({
              ...user,
              reactionId: reaction.id,
            });
          }
          acc[reaction.reactionType].count += 1;
          return acc;
        },
        {}
      )
    );
  }, [props.message.reactions, usersReaction]);

  if (
    !props?.message?.reactions?.length ||
    !isUserReactionMessageEnabled ||
    isWeb
  )
    return null;

  const onDeleteReaction = async (itemReaction: ReactionMap) => {
    const user = itemReaction.users.find(
      (user: UserInfoReaction) => user.id === account?.id
    );

    if (!user) {
      addNewEmoji(itemReaction.reaction.reactionType);
      return;
    }

    if (isPendingDeleteEmoji) return;

    await DeleteReactionIssue({
      input: {
        issueMessageId: props.message.id,
        reactionId: user.reactionId,
      },
    });
  };

  const addNewEmoji = async (emoji: EmojiType['emoji']) => {
    if (isPendingNewEmoji) return;

    await AddReaction({
      input: {
        issueMessageId: props.message.id,
        reactionType: emoji,
      },
    });
  };

  const activeEmoji = (itemReaction: ReactionMap) =>
    itemReaction.users.some(
      (user: UserInfoReaction) => user.id === account?.id
    );

  return (
    <View style={styles.reactionListContainer}>
      <FlatList
        data={reactionCounts}
        horizontal
        keyExtractor={(item, index) => `reaction-${item.reaction.id}-${index}`}
        renderItem={({ item }) => (
          <View key={item.reaction.id}>
            <TouchableOpacity
              style={{
                ...styles.singleEmoji,
                backgroundColor: activeEmoji(item)
                  ? Colors.orangeActive
                  : Colors.primaryGrayBackground,
              }}
              activeOpacity={1}
              onPress={() => onDeleteReaction(item)}
              onLongPress={() => {
                setUsersReactions({
                  users: item.users,
                  reactionType: item.reaction.reactionType,
                });
                onOpen();
              }}
            >
              <SingleEmoji
                emojiStyle={styles.emojiStyle}
                emoji={item.reaction.reactionType}
                onPress={() => onDeleteReaction(item)}
                onLongPress={() => {
                  setUsersReactions({
                    users: item.users,
                    reactionType: item.reaction.reactionType,
                  });
                  onOpen();
                }}
              />
              <Text>{item.count}</Text>
            </TouchableOpacity>
          </View>
        )}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.flatListContent}
      />
      <NewReactionButton
        onOpenEmojiPicker={onOpenNewReaction}
        emojiContainerStyle={styles.newEmojiStyle}
      />
      <UsersReactionModal
        isOpened={isOpened}
        usersReactions={usersReactions}
        onUsersInfoClose={() => {
          setUsersReactions({
            users: [],
            reactionType: '',
          });
          onClose();
        }}
      />
    </View>
  );
};

type UsersReactionModalProps = {
  usersReactions: UserInfoModal;
  isOpened: boolean;
  onUsersInfoClose: () => void;
};
const UsersReactionModal: React.FC<UsersReactionModalProps> = (props) => {
  const { usersReactions, onUsersInfoClose, isOpened } = props;

  return (
    <Modal
      visible={isOpened}
      onRequestClose={onUsersInfoClose}
      animationType="slide"
      statusBarTranslucent={true}
      transparent
      onDismiss={onUsersInfoClose}
    >
      <View style={styles.containerModal}>
        <UserInfomation
          users={usersReactions.users}
          reactionType={usersReactions.reactionType}
        />
        <Pressable
          onPress={onUsersInfoClose}
          style={{ marginHorizontal: 'auto', bottom: -120 }}
        >
          <Avatar.Icon
            icon={'close'}
            color={Colors.white}
            size={40}
            style={styles.closeButton}
          />
        </Pressable>
      </View>
    </Modal>
  );
};

const UserInfomation = ({ users, reactionType }: UserInfoModal) => (
  <View style={styles.containerFlatUser}>
    <View style={styles.emojiStyleContainerModal}>
      <SingleEmoji emojiStyle={styles.emojiStyle} emoji={reactionType} />
      <Text style={styles.emojiStyleCounter}>{users.length}</Text>
    </View>
    <FlatList
      data={users}
      keyExtractor={(item, index) =>
        `reaction-${reactionType}-${item.id}-${index}`
      }
      renderItem={({ item }) => (
        <Text
          key={item.id}
          style={{
            paddingHorizontal: 8,
            paddingVertical: 4,
          }}
        >
          {item.name.first} {item.name.last}
        </Text>
      )}
      showsVerticalScrollIndicator
    />
  </View>
);

const styles = StyleSheet.create({
  flatListContent: {
    columnGap: 11,
  },
  reactionListContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: 11,
    zIndex: 9,
    marginTop: 6,
  },
  singleEmoji: {
    columnGap: 8,
    flexDirection: 'row',
    borderRadius: 8,
    backgroundColor: Colors.primaryGrayBackground,
    alignItems: 'center',
    justifyContent: 'center',
    width: 70,
    height: 36,
    zIndex: 9,
  },
  emojiStyle: {
    fontWeight: 400,
    fontSize: 20,
  },
  newEmojiStyle: {
    borderRadius: 8,
    width: 54,
    height: 36,
  },
  containerModal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.blackOverlay,
    position: 'relative',
  },
  closeButton: {
    backgroundColor: Colors.primaryDark,
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  containerFlatUser: {
    height: '30%',
    width: '80%',
    zIndex: 9,
    backgroundColor: Colors.white,
    borderRadius: 8,
  },
  emojiStyleContainerModal: {
    marginTop: 6,
    flexDirection: 'row',
    gap: 4,
    justifyContent: 'center',
    position: 'relative',
  },
  emojiStyleCounter: {
    position: 'absolute',
    top: -5,
    right: '43%',
    padding: 2,
    color: Colors.primaryDark,
  },
});
