import { Controller } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import { TextInput } from 'react-native-paper';
import { Colors } from '../../themes/colors';

type ControlledTextInputPropsDeprecated = {
  control: any;
  name: string;
  separatedLabelInputStyle?: boolean;
  placeholder?: string;
  label?: string;
  editable?: boolean;
  autoFocus?: boolean;
  first?: boolean;
  middle?: boolean;
  last?: boolean;
  disabled?: boolean;
};

/**
 * @deprecated Use `ControlledTextInput` instead.
 */

export const ControlledTextInputDeprecated = (
  props: ControlledTextInputPropsDeprecated
) => {
  const editable = props.editable === undefined ? true : props.editable;

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, onBlur, value } }) => (
        <TextInput
          label={props.label}
          mode={props.separatedLabelInputStyle ? 'outlined' : 'flat'}
          style={[
            props.separatedLabelInputStyle
              ? inputStyles.separatedLabelInput
              : inputStyles.input,
            props.first ? inputStyles.first : {},
            props.middle ? inputStyles.middle : {},
            props.last ? inputStyles.last : {},
          ]}
          value={value}
          onBlur={onBlur}
          onChangeText={(value) => onChange(value)}
          autoFocus={props.autoFocus}
          placeholder={props.placeholder}
          editable={editable}
          outlineStyle={props.separatedLabelInputStyle && { borderWidth: 0 }}
          activeOutlineColor={Colors.black}
          underlineColor={props.last ? Colors.white : Colors.grayDivider}
          activeUnderlineColor={Colors.black}
          contentStyle={inputStyles.text}
          disabled={props.disabled}
        />
      )}
    />
  );
};

export const inputStyles = StyleSheet.create({
  input: {
    backgroundColor: Colors.white,
    borderRadius: 0,
    textAlign: 'right',
    fontSize: 16,
  },
  separatedLabelInput: {
    backgroundColor: Colors.white,
    textAlign: 'left',
    fontSize: 16,
    height: 44,
  },
  text: {
    alignSelf: 'flex-end',
    width: '100%',
  },
  first: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  middle: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  last: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
});
