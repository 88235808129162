import { SortDirectionEnum, TagStatus, getEnumKey } from '@gripp/shared-logic';
import {
  InfiniteDataGrid,
  StatusBadge,
  StatusBadgeType,
} from '@gripp/shared-ui';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { useWindowDimensions } from '../../shared/hooks/useWindowDimensions';
import { GET_TAGS } from '../operations/getTags';

export const Tags = () => {
  const windowDimensions = useWindowDimensions();
  const maxHeight = (windowDimensions?.height ?? 0) - 136; // substract banner + padding height
  const listMaxHeight = maxHeight - 48;

  const { t } = useTranslation();
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('status', {
      cell: (info) => {
        const enumStatus = info.getValue() as TagStatus;
        const badgeStatus = enumStatus as StatusBadgeType;

        return (
          <StatusBadge
            type={badgeStatus}
            text={getEnumKey(TagStatus, enumStatus)}
          />
        );
      },
      header: () => t('tags.status'),
      meta: { sortable: true },
    }),
    columnHelper.accessor('tagCode', {
      cell: (info) => <Text>{info.getValue()}</Text>,
      header: () => t('tags.tagCode'),
      meta: { sortable: true },
    }),
    columnHelper.accessor('link', {
      cell: ({ row }) => {
        const original = row.original as any;
        return <Text>{original.link?.linkData?.title}</Text>;
      },
      header: () => t('tags.assetName'),
    }),
    columnHelper.accessor('version', {
      cell: (info) => <Text>{info.getValue()?.version}</Text>,
      header: () => t('tags.version'),
      meta: { sortable: true },
    }),
    columnHelper.accessor('updatedAt', {
      cell: (info) => <Text>{info.getValue()}</Text>,
      header: () => t('tags.updatedAt'),
      meta: { sortable: true },
    }),
  ];

  return (
    <View
      style={[styles.container, { height: maxHeight, maxHeight: maxHeight }]}
    >
      <InfiniteDataGrid
        modelName="tag"
        query={GET_TAGS}
        orderBy={{
          field: 'updatedAt',
          order: SortDirectionEnum.Desc,
        }}
        pageSize={50}
        columns={columns}
        listMaxHeight={listMaxHeight as number}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 12,
    overflow: 'hidden',
    // borderWidth: 2,
    // borderColor: 'blue',
  },
});
