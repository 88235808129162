import {
  CheckboxQuestionConfig,
  CheckboxResponseState,
} from '@gripp/shared-logic';
import { Text } from 'react-native-paper';
import { RoutineQuestionMessage, RoutineQuestionMessageProps } from '../types';

export const CheckboxQuestionMessage: RoutineQuestionMessage<
  CheckboxQuestionConfig,
  CheckboxResponseState
> = (
  props: RoutineQuestionMessageProps<
    CheckboxQuestionConfig,
    CheckboxResponseState
  >
) => {
  const { response } = props;

  return <Text style={props.textStyle}>{response?.options?.join(', ')}</Text>;
};
