import { useQuery } from '@tanstack/react-query';
import { StorageKeys, useStorageKeys } from '../../storage';
import { useGrippContext } from '../../grippContext';

export const useFilter = (
  queryKey: string,
  storageKey: string,
  deserializeStorage: (filter: any) => any
): { data: any; isLoading: boolean } => {
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();
  const { data, isLoading } = useQuery<any>({
    staleTime: Infinity,
    queryKey: [queryKey],
    queryFn: async () => {
      const filter = JSON.parse(
        (await storageHandler.getItem(
          storageKeys[storageKey as keyof StorageKeys]
        )) as string
      );

      return deserializeStorage(filter);
    },
  });

  return { data, isLoading };
};
