import { AssetFilterKey, AssetFilterModel } from '../domain';
import { useFilter } from '../../filter';

export const useAssetListFilter = () => {
  return useFilter(
    AssetFilterKey,
    'assetFilter',
    (filter: string) => new AssetFilterModel(filter)
  );
};
