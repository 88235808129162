import { UseMutationResult } from '@tanstack/react-query';
import { ColumnModel } from '../domain';
import { OrderByInput, SortDirectionEnum } from '../../graphql';
import { UseListOrderResult } from './useListOrder';

export const useValidateListOrder = (
  useListOrderHook: () => UseListOrderResult,
  useSetListOrderHook: () => UseMutationResult
) => {
  const { data } = useListOrderHook();
  const { mutateAsync } = useSetListOrderHook();

  return async (filter: ColumnModel) => {
    if (!data) return;

    if (!filter.columns?.some((x) => x == (data as OrderByInput).field)) {
      await mutateAsync({
        field: 'name',
        order: SortDirectionEnum.Asc,
      });
    }
  };
};
