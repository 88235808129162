import {
  AUTHENTICATION_QUERY_KEY,
  Graphback,
  JwtAuthentication,
  useGrippContext,
  useStorageKeys,
} from '@gripp/shared-logic';
import { useQueryClient } from '@tanstack/react-query';

//TODO: move to shared-logic

export const useAccessTokenReceived = () => {
  const queryClient = useQueryClient();
  const { secureStorageHandler, storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();

  const onAccessTokenReceived = async (accessToken: string) => {
    await secureStorageHandler.setItem(storageKeys.accessToken, accessToken);

    Graphback.initialize(accessToken);

    queryClient.setQueryData(
      AUTHENTICATION_QUERY_KEY,
      new JwtAuthentication(accessToken),
      { updatedAt: Date.now() }
    );

    // clean up local storage
    await storageHandler.deleteItem(storageKeys.redirectPath);
    await storageHandler.deleteItem(storageKeys.redirectUrl);
  };

  return onAccessTokenReceived;
};
