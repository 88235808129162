import { scaleToWidth } from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { Animated, Image, StyleSheet, View } from 'react-native';
import { useAnimation } from '../../hooks/useAnimation';
import { Colors } from '../../themes/colors';

export type OutlinedImageProps = {
  url?: string;
  size: number;
};

const MAX_RETRIES = 3;
const DEFAULT_SIZE = 0;
const BORDER_IMAGE = 2;

export const OutlinedImage = (props: OutlinedImageProps) => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: DEFAULT_SIZE, height: DEFAULT_SIZE });
  const { opacity, fadeIn } = useAnimation();

  useEffect(() => {
    let retries = 0;

    const checkImageSize = () => {
      if (props.url && props.size) {
        getImageSize(props.url)
          .then(({ width, height }) => {
            const dimensionsScale = scaleToWidth(props.size, width, height);
            setDimensions(dimensionsScale);
          })
          .catch((error) => {
            console.warn('Failed to get image size:', error);
            if (retries < MAX_RETRIES) {
              retries++;
              checkImageSize();
            }
          });
      }
    };

    checkImageSize();
  }, [props.size, props.url]);

  const finishLoading = () => {
    fadeIn();
  };
  return (
    <View style={[dimensions?.width !== DEFAULT_SIZE ? styles.border : styles.noBorder]}>
      <Animated.Image
        key={props.url}
        style={{
          width: dimensions?.width - BORDER_IMAGE,
          height: dimensions?.height - BORDER_IMAGE,
          borderRadius: 7,
          opacity: opacity,
        }}
        source={{ uri: props.url }}
        onLoadEnd={finishLoading}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  border: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.grayDivider,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  noBorder: {
    borderWidth: 0,
  },
});

//move to utils
const getImageSize = (
  url: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    Image.getSize(
      url,
      (width, height) => resolve({ width, height }),
      (error) => reject(error)
    );
  });
};
