import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { Spacing } from '../../styles';
import { Colors } from '../../themes';
import { RightButton } from './leftRightButtons';

type SaveButtonProps = {
  onPress: () => void;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  label?: string;
  isLoading?: boolean;
  activeTextColor?: string;
};

export const SaveButton: FC<SaveButtonProps> = (props: SaveButtonProps) => {
  const { t } = useTranslation();
  const buttonColor = props.activeTextColor
    ? props.activeTextColor
    : Colors.primaryDark;

  return (
    <RightButton
      onPress={props.onPress}
      label={props.label ? props.label : t('topNav.save')}
      disabled={props.disabled}
      color={props.disabled ? Colors.grayDisabled : buttonColor}
      hideCaret={true}
      isLoading={props.isLoading}
      style={props.style ? props.style : styles.right}
    />
  );
};

const styles = StyleSheet.create({
  right: {
    marginRight: Spacing.baseMargin.base,
  },
});
