import { useGrippContext } from '@gripp/shared-logic';
import {
  FallbackProps,
  ErrorBoundary as ReactErrorBoundary,
} from 'react-error-boundary';
import { Button } from 'react-native-paper';

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  const { logger } = useGrippContext();
  return (
    <ReactErrorBoundary
      FallbackComponent={FallBack}
      onError={(e, i) => logger?.fatal(e, i?.componentStack as string)}
    >
      {children}
    </ReactErrorBoundary>
  );
}

const FallBack = (props: FallbackProps) => {
  return (
    <div role="alert">
      <p>Something went wrong.</p>
      <Button onPress={props.resetErrorBoundary}>Try again</Button>
    </div>
  );
};
