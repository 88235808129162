export enum ActionType {
  Alert = 'ALERT',
}

export enum ActivityInboxType {
  IssueMessageCreated = 'ISSUE_MESSAGE_CREATED',
  IssueUpdated = 'ISSUE_UPDATED',
}

export enum ActivityType {
  AssetCreated = 'ASSET_CREATED',
  BlueCreated = 'BLUE_CREATED',
  GreenCreated = 'GREEN_CREATED',
  GroupCreated = 'GROUP_CREATED',
  IssueUpdated = 'ISSUE_UPDATED',
  MessageCreated = 'MESSAGE_CREATED',
  PurpleCreated = 'PURPLE_CREATED',
  RedCreated = 'RED_CREATED',
  TagScanCreated = 'TAG_SCAN_CREATED',
  UserJoined = 'USER_JOINED',
}

export enum FeatureName {
  AccountPage = 'ACCOUNT_PAGE',
  ActivitySortFilter = 'ACTIVITY_SORT_FILTER',
  Alerts = 'ALERTS',
  AssetGrouping = 'ASSET_GROUPING',
  AssetSortFilter = 'ASSET_SORT_FILTER',
  BulkInvites = 'BULK_INVITES',
  GroupsPage = 'GROUPS_PAGE',
  IssueMessageReaction = 'ISSUE_MESSAGE_REACTION',
  Mentions = 'MENTIONS',
  OrangeButton = 'ORANGE_BUTTON',
  PeopleGrouping = 'PEOPLE_GROUPING',
  ReassignIssue = 'REASSIGN_ISSUE',
  Routines = 'ROUTINES',
  SmsLogin = 'SMS_LOGIN',
  NewRegistrationFlow = 'NEW_REGISTRATION_FLOW',
}

export enum GeoJsonType {
  Point = 'Point',
}

export enum GrippLinkType {
  Asset = 'ASSET',
}

export enum IssueMessageType {
  RoutineExceptionMessage = 'ROUTINE_EXCEPTION_MESSAGE',
  RoutineMessage = 'ROUTINE_MESSAGE',
  RoutinePromptMessage = 'ROUTINE_PROMPT_MESSAGE',
  SystemMessage = 'SYSTEM_MESSAGE',
  UserMessage = 'USER_MESSAGE',
}

export enum IssueType {
  Alert = 'ALERT',
  AllGood = 'ALL_GOOD',
  Feedback = 'FEEDBACK',
  Note = 'NOTE',
  Report = 'REPORT',
  Routine = 'ROUTINE',
}

export enum MediaStatus {
  Pending = 'PENDING',
  Processed = 'PROCESSED',
}

export enum MediaType {
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Video = 'VIDEO',
}

export enum RoleType {
  Account = 'ACCOUNT',
  System = 'SYSTEM',
}

export enum RoutineType {
  Counter = 'COUNTER',
  QuickConfirm = 'QUICK_CONFIRM',
  Status = 'STATUS',
  Workflow = 'WORKFLOW',
}

export enum ScheduleDays {
  EveryDay = 'EVERY_DAY',
  WeekDays = 'WEEK_DAYS',
}

export enum Severity {
  Blue = 'blue',
  Green = 'green',
  Purple = 'purple',
  Red = 'red',
  Yellow = 'yellow',
}

export enum SortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum TagBatchStatus {
  Delivered = 'DELIVERED',
  Ordered = 'ORDERED',
  Printer = 'PRINTER',
  Provisioned = 'PROVISIONED',
  Shipping = 'SHIPPING',
}

export enum TagStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum TriggerType {
  Routine = 'ROUTINE',
}
