import { StatusQuestionConfig, StatusResponseState } from '@gripp/shared-logic';
import { RoutineQuestionModule } from '../types';
import { StatusQuestionAdminForm } from './statusQuestionAdminForm';
import { StatusQuestionMessage } from './statusQuestionMessage';
import { StatusQuestionExecutionForm } from './statusQuestionExecutionForm';

export const StatusQuestionModule: RoutineQuestionModule<
  StatusQuestionConfig,
  StatusResponseState
> = {
  adminForm: StatusQuestionAdminForm,
  executionForm: StatusQuestionExecutionForm,
  message: StatusQuestionMessage,
};
