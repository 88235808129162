import { Account } from '../../auth';
import { RoutineType } from '../../graphql';
import { Workspace } from '../../workspace';
import { RoutineConfig } from './routineConfig';

export interface WorkspaceRoutine {
  id: string;
  type?: RoutineType;
  name?: string;
  order: number;
  config: RoutineConfig;
  workspace?: Workspace;
  currentVersion: WorkspaceRoutineVersion;
  createdBy?: Account;
  deactivated?: boolean;
}

export interface WorkspaceRoutineVersion {
  id: string;
}
