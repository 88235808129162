import { useExportHook, useExportProps } from './types';
import { useEffect, useState } from 'react';
import { SortDirectionEnum, useCustomQuery } from '@gripp/shared-logic';

export const useActivityExport: useExportHook = (props: useExportProps) => {
  const [rows, setRows] = useState([]);
  const { data, isLoading } = useCustomQuery({
    cacheKey: ['exportActivities', props.workspace.id],
    query: EXPORT_ACTIVITIES,
    variables: {
      filter: {
        workspaceId: props.workspace.id,
      },
    },
    options: {
      enabled: props.download,
      gcTime: 10000,
      staleTime: 10000,
    },
  });

  const headers = [
    { label: 'Asset ID', key: 'assetId' },
    { label: 'Asset Name', key: 'assetName' },
    { label: 'Activity ID', key: 'activityId' },
    { label: 'Activity Title', key: 'activityTitle' },
    { label: 'Activity Type', key: 'activityType' },
    { label: 'Activity Created By', key: 'activityCreatedBy' },
    { label: 'Activity Created At', key: 'activityCreatedAt' },
    { label: 'Message ID', key: 'messageId' },
    { label: 'Message Text', key: 'messageText' },
    { label: 'Message Created By', key: 'messageCreatedBy' },
    { label: 'Message Created At', key: 'messageCreatedAt' },
    { label: 'Groups', key: 'groups' },
  ];

  useEffect(() => {
    if (!data) return;

    setRows(data.issue.exportActivity.data);
    props.onDataReady();
  }, [data, props]);

  return {
    headers,
    rows,
    isLoading,
  };
};

const EXPORT_ACTIVITIES = `
  query Issue($filter: ExportActivityFilter) {
    issue {
      exportActivity(filter: $filter) {
        data {
          activityCreatedAt
          activityCreatedBy
          activityId
          activityTitle
          activityType
          assetId
          assetName
          messageCreatedAt
          messageCreatedBy
          messageId
          messageText
          groups
        }
      }
    }
  } 
`;
