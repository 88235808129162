import { createClient, Client } from 'graphql-ws';
import { EnvConfig } from '../../envConfig';

export class SubscriptionClientProvider {
  private static instance: Client;

  private static createInstance(accessToken: string): void {
    this.instance = createClient({
      url: EnvConfig.subscriptionsUrl,
      lazy: true,
      connectionParams: {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
    });
  }

  public static getInstance(accessToken: string): Client {
    if (!this.instance) {
      this.createInstance(accessToken);
    }

    return this.instance;
  }
}
