import {
  MediaPickerComponent,
  MediaType,
  NewMessage,
  PickerMode,
} from '@gripp/shared-logic';
import { useEffect, useRef, useState } from 'react';
import {
  FlatList,
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { IconButton } from 'react-native-paper';
import { Layout, Spacing } from '../../styles';
import { Colors } from '../../themes/colors';
import { RenderMediaItem } from '../media/renderMediaItem';
import { useTypedMedia } from '../media/useTypedMedia';
import { MentionInput } from '../mentionInputField';

type IssueFooterProps = {
  onNewMessage: (message: NewMessage) => Promise<void>;
  mediaPicker: MediaPickerComponent;
  acceptedMediaTypes?: MediaType[];
  workspaceId?: string;
  mode?: PickerMode;
  containerStyle?: StyleProp<ViewStyle>;
  maxImagePreviewWidth?: number;
  focusOnMessage?: boolean;
};

export const IssueFooter = (props: IssueFooterProps) => {
  const {
    handleAdd,
    handleUploadProgress,
    typedMedia: messageMedia,
    handleDelete,
    reset: resetMessageMedia,
  } = useTypedMedia([]);

  const [message, setMessage] = useState('');
  const [canSend, setCanSend] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [showPreviewGallery, setShowThumbnailPreview] = useState(false);
  const [isMultiline, setIsMultiline] = useState<boolean>(false);
  const [inputHeight, setInputHeight] = useState<number>(
    minHeightInputContainerPhone
  );

  const isWeb = Platform.OS === 'web';

  const MediaPicker = props.mediaPicker;
  const pickerMode = props.mode || PickerMode.CAMERA;
  let pickerIcon;

  const messageInputRef = useRef(null);

  const borderRadius =
    isMultiline || isWeb
      ? inputContainerBorderRadius
      : inputContainerBorderRadiusPhone;

  const footerHeight = isWeb
    ? footerHeightWeb
    : inputHeight + inputContainerPaddingBottom;

  switch (pickerMode) {
    case PickerMode.PICKER:
      pickerIcon = 'image-outline';
      break;
    case PickerMode.CAMERA:
      pickerIcon = 'camera-outline';
      break;
    case PickerMode.ATTACHMENT:
      pickerIcon = 'paperclip';
      break;
    default:
      pickerIcon = 'camera-outline';
      break;
  }

  useEffect(() => {
    if (isWeb) {
      setInputHeight(inputHeightWeb);
    }
  }, [isWeb]);

  useEffect(() => {
    setCanSend((messageMedia.length > 0 && !isUploading) || message.length > 0);
  }, [messageMedia, message, isUploading]);

  useEffect(() => {
    setShowThumbnailPreview(messageMedia.length > 0);
  }, [messageMedia]);

  const send = () => {
    dismissKeyboard();
    props.onNewMessage({
      message: message,
      media: messageMedia,
    });
    setMessage('');
    resetMessageMedia();
  };

  const dismissKeyboard = () => {
    messageInputRef.current?.blur();
  };

  const deleteMediaItem = (mediaId: string) => {
    handleDelete(mediaId);
  };

  return (
    <View
      style={[
        {
          minHeight: showPreviewGallery
            ? footerHeight + 130 // add height for images container + 10 margin
            : footerHeight,
          height: showPreviewGallery ? footerHeight + 130 : footerHeight,
          paddingTop: 0,
          paddingBottom: 8,
        },
        styles.footer,
        props.containerStyle,
      ]}
    >
      {showPreviewGallery && (
        <View
          style={{
            width: '100%',
            paddingLeft: imagePickerContainerWidth,
            paddingRight: inputMarginRight,
            marginTop: 10,
          }}
        >
          <View style={[styles.mediaContainer, { flexDirection: 'row' }]}>
            {messageMedia.length > 0 && (
              <FlatList
                horizontal={true}
                data={messageMedia}
                style={styles.mediaList}
                renderItem={({ item }) => (
                  <View style={styles.mediaItemContainer} key={item.id}>
                    <RenderMediaItem
                      mediaItem={item}
                      key={item.id}
                      deleteMediaItem={deleteMediaItem}
                      hasLightbox
                      canDelete
                    />
                  </View>
                )}
              ></FlatList>
            )}
          </View>
        </View>
      )}

      <View style={styles.footerContainer}>
        <View
          style={[
            isMultiline
              ? { alignItems: 'flex-end', paddingBottom: 4 }
              : { alignItems: 'center', paddingBottom: 0 },
            styles.imagePickerContainer,
          ]}
        >
          <MediaPicker
            iconColor={Colors.primary}
            cardStyle={'contained'}
            mode={pickerMode}
            style={styles.imagePickerIcon}
            icon={pickerIcon}
            iconSize={30}
            setMedia={handleAdd}
            setProgress={handleUploadProgress}
            setUploading={setUploading}
            workspaceId={props.workspaceId}
            dismissKeyboard={dismissKeyboard}
            acceptedMediaTypes={props.acceptedMediaTypes}
          />
        </View>
        <View
          style={[
            {
              borderRadius,
              height: inputHeight,
              minHeight: minHeightInputContainerPhone,
            },
            styles.messageContainer,
          ]}
        >
          <View style={styles.inputContainer}>
            <MentionInput
              inputFieldText={message}
              setInputFieldText={setMessage}
              setIsMultiline={setIsMultiline}
              inputHeight={inputHeight}
              setInputHeight={setInputHeight}
              send={send}
            />
          </View>
          <View
            style={[
              styles.sendMessage,
              isMultiline || isWeb
                ? { justifyContent: 'flex-end' }
                : { justifyContent: 'center' },
            ]}
          >
            <IconButton
              icon="send-outline"
              iconColor={canSend ? Colors.primary : Colors.grayText}
              style={{ margin: 0, padding: 0 }}
              disabled={!canSend}
              onPress={send}
              size={22}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

const imagePickerContainerWidth = 68;
const inputMarginRight = 20;
const inputContainerBorderRadiusPhone = 40;
const inputContainerBorderRadius = 12;
const mediaContainerHeight = 120;
const footerHeightWeb = 115;
const minHeightInputContainerPhone = 36;
const inputHeightWeb = 69;
const inputContainerPaddingBottom = 34;

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
  },
  imagePickerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
    ...Platform.select({
      ios: {
        width: imagePickerContainerWidth,
      },
      android: {
        width: imagePickerContainerWidth,
      },
      web: {
        width: 45,
        marginHorizontal: 10,
        alignItems: 'flex-end',
        paddingBottom: 8,
      },
    }),
  },
  imagePickerIcon: {
    height: 45,
    maxHeight: 45,
    width: 45,
    backgroundColor: Colors.white,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendMessage: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 0,
    ...Platform.select({
      web: {
        flex: 1 / 2,
      },
    }),
  },
  messageContainer: {
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    borderWidth: 1,
    paddingVertical: 4,
    backgroundColor: Colors.primaryGrayBackground,
    marginRight: inputMarginRight,
    borderColor: Colors.primaryGrayBackground,
  },
  inputContainer: {
    flex: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  mediaContainer: {
    height: mediaContainerHeight,
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: 12,
    paddingLeft: 16,
    paddingBottom: 16,
    backgroundColor: Colors.primaryGrayBackground,
  },
  footer: {
    borderTopWidth: 1,
    borderTopColor: Colors.grayDisabled,
    backgroundColor: Colors.white,
    ...Platform.select({
      web: {
        height: 115,
        maxHeight: 156,
      },
      default: {
        minHeight: 60,
      },
    }),
  },
  footerContainer: {
    ...Layout.row,
    ...Layout.container,
    ...Platform.select({
      web: {
        alignItems: 'flex-start',
        paddingTop: 20,
        paddingBottom: 8,
      },
      default: {
        alignItems: 'center',
      },
    }),
    paddingHorizontal: Spacing.basePadding,
  },
  mediaList: {
    width: '100%',
    height: '100%',
    flex: 1,
  },
  mediaItemContainer: {
    alignSelf: 'center',
    marginLeft: 8,
  },
});
