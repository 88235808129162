import { RadioQuestionConfig, RadioState } from '@gripp/shared-logic';
import { RoutineQuestionModule } from '../types';
import { RadioQuestionMessage } from './radioQuestionMessage';
import { RadioQuestionAdminForm } from './radioQuestionAdminForm';
import { RadioQuestionExecutionForm } from './radioQuestionExecutionForm';

export const RadioQuestionModule: RoutineQuestionModule<
  RadioQuestionConfig,
  RadioState
> = {
  adminForm: RadioQuestionAdminForm,
  executionForm: RadioQuestionExecutionForm,
  message: RadioQuestionMessage,
};
