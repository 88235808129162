import { ActivityFilterKey } from '@gripp/shared-logic';
import { ActivityList as ActivityListBase } from '@gripp/shared-ui';
import { StyleSheet, View } from 'react-native';

export type ActivitiesProps = {
  assetId?: string;
  onIssueSelected?: (id: string) => void;
  showAssetName?: boolean;
  selectedIssue?: string;
  maxHeight?: number | null;
  onActivityCountChange?: (count: number) => void;
  listMaxHeight?: number;
};

export const ActivityList = (props: ActivitiesProps) => {
  const heightStyle = {
    minHeight: props.listMaxHeight,
    maxHeight: props.listMaxHeight,
  };

  const filterKey = props.assetId
    ? ActivityFilterKey.AssetActivity
    : ActivityFilterKey.Activity;

  return (
    <View style={[styles.container, heightStyle]}>
      <ActivityListBase
        showScrollIndicator={true}
        containerStyle={styles.container}
        filterKey={filterKey}
        listMaxHeight={props.listMaxHeight}
        {...props}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    borderRadius: 8,
  },
});
