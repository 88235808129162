import { useGetQuery } from '../../graphql';
import { WorkspaceRoutine } from '@gripp/shared-logic';
import { useEffect, useState } from 'react';

export const useWorkspaceRoutine = (
  routineId: string
): WorkspaceRoutine | undefined => {
  const [workspaceRoutine, setWorkspaceRoutine] = useState<
    WorkspaceRoutine | undefined
  >();
  const { data } = useGetQuery({
    modelName: 'WorkspaceRoutine',
    query: GET_WORKSPACE_ROUTINE,
    id: routineId,
  });

  useEffect(() => {
    if (!data) return;
    setWorkspaceRoutine(data as WorkspaceRoutine);
  }, [data]);

  return workspaceRoutine;
};

const GET_WORKSPACE_ROUTINE = `
  query GetWorkspaceRoutine($id: ID!) {
    getWorkspaceRoutine(id: $id) {
      id
      type
      config
      name
    }
  }
`;
