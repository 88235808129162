import jwt from 'jwt-decode';

export interface Authentication {
  isAuthenticated: boolean;
  accessToken?: string;
  jwt?: Jwt;
  isLoading?: boolean;
}

export interface Jwt {
  sub: string;
  exp: number;
}

export class JwtAuthentication implements Authentication {
  isAuthenticated: boolean;
  accessToken: string;
  jwt: Jwt;
  isExpired?: boolean;

  constructor(accessToken: string) {
    this.accessToken = accessToken;
    this.jwt = jwt(accessToken);
    this.checkExpired();
    this.isAuthenticated = !this.isExpired;
  }

  private checkExpired() {
    const now = Date.now().valueOf() / 1000;
    this.isExpired = this.jwt.exp < now;
  }
}
