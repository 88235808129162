import { RadioQuestionConfig, RadioState } from '@gripp/shared-logic';
import { Text } from 'react-native-paper';
import { RoutineQuestionMessage, RoutineQuestionMessageProps } from '../types';

export const RadioQuestionMessage: RoutineQuestionMessage<
  RadioQuestionConfig,
  RadioState
> = (props: RoutineQuestionMessageProps<RadioQuestionConfig, RadioState>) => {
  const { response } = props;

  return <Text style={props.textStyle}>{response?.option}</Text>;
};
