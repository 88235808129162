import {
  PermissionValues,
  useCurrentAccount,
  useDeleteMutation,
  WorkspaceAsset,
} from '@gripp/shared-logic';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { CustomAlert } from '../customAlert';

type DeleteAssetProps = {
  workspaceAsset?: WorkspaceAsset;
  onDeleted: () => Promise<void>;
};

export const DeleteAsset = (props: DeleteAssetProps) => {
  const [showAlert, setShowAlert] = useState(false);
  const { account } = useCurrentAccount();
  const { t } = useTranslation();
  const { mutateAsync } = useDeleteMutation({
    modelName: 'asset',
    query: DELETE_ASSET,
  });

  const onDelete = async () => {
    const workspaceAsset = props.workspaceAsset;
    if (!workspaceAsset) return;

    const input = {
      id: workspaceAsset.asset.id,
      workspaces: [{ id: workspaceAsset.workspace.id }],
    };

    await mutateAsync({ input });
    await props.onDeleted();
  };

  return (
    account &&
    account.hasPermission(PermissionValues.globalAdmin) && (
      <>
        <Pressable onPress={() => setShowAlert(true)}>
          <Text variant="titleMedium" style={styles.deleteText}>
            {t('asset.delete.button')}
          </Text>
        </Pressable>
        <CustomAlert
          visible={showAlert}
          hideAlert={() => setShowAlert(false)}
          action={onDelete}
          title={t('asset.delete.title')}
          primaryButtonLabel={t('asset.delete.confirm')}
          secondaryButtonLabel={t('asset.delete.cancel')}
        />
      </>
    )
  );
};

const DELETE_ASSET = `
  mutation Mutation($input: MutateAssetInput!) {
    deleteAsset(input: $input) {
      id
    }
  }
`;

const styles = StyleSheet.create({
  deleteText: {
    color: Colors.grayDisabled,
    paddingVertical: 20,
  },
});
