/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AccountFilterAdminColumns,
  AccountFilterColumns,
  AccountFilterModel,
  useAccountFilter,
  useCurrentAccount,
  useCurrentWorkspace,
  useSetAccountFilter,
  useValidateAccountListOrder,
} from '@gripp/shared-logic';
import {
  AccountTypeFilter,
  ColumnSelection,
  DropdownFooter,
  FilterType,
  FormContext,
  GroupFilter,
  filterStyles as styles,
} from '@gripp/shared-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { ScrollView, View } from 'react-native';
import { array, string, object as yupObject } from 'yup';

type AccountFilterProps = {
  onCloseSideBar: () => void;
  type: FilterType;
};

export const AccountFilter = (props: AccountFilterProps) => {
  const { data: accountFilter } = useAccountFilter();
  const { account } = useCurrentAccount();
  const workspace = useCurrentWorkspace(true);
  const { mutateAsync: setAccountFilter } = useSetAccountFilter();
  const validateListOrder = useValidateAccountListOrder();

  const formSchema = yupObject({
    columns: array().of(string().optional().nullable()).optional().nullable(),
    groups: array().of(string().optional().nullable()).optional().nullable(),
    roles: array().of(string().optional().nullable()).optional().nullable(),
  });
  const formMethods = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      columns: accountFilter?.columns,
      groups: accountFilter?.groups,
      roles: accountFilter?.roles,
    },
  });

  const {
    formState: { isDirty },
    getValues,
    handleSubmit,
    reset,
    watch,
  } = formMethods;

  const selectedColumns = watch('columns');

  const isGroupsFilter = props.type === FilterType.ACCOUNTGROUPS;
  const isColumnsFilter = props.type === FilterType.ACCOUNTCOLUMNS;
  const isAccountTypesFilter = props.type === FilterType.ACCOUNTTYPES;

  const resetForm = useCallback(() => {
    reset({
      ...selectedColumns,
      ...(isColumnsFilter && { columns: new AccountFilterModel().columns }),
      ...(isGroupsFilter && { groups: [] }),
      ...(isAccountTypesFilter && { roles: [] }),
    });
  }, [
    isAccountTypesFilter,
    isColumnsFilter,
    isGroupsFilter,
    reset,
    selectedColumns,
  ]);

  const canReset = (): boolean => {
    return !Object.values(getValues()).every((value) => value === undefined);
  };

  const applyFilter = async (filterModel: any) => {
    await Promise.all([
      setAccountFilter(filterModel),
      validateListOrder(filterModel),
    ]);

    props.onCloseSideBar();
  };

  const onReset = async () => {
    const model = new AccountFilterModel();
    const updatedFields = {
      ...(isColumnsFilter && { columns: model.columns }),
      ...(isGroupsFilter && { groups: [] }),
      ...(isAccountTypesFilter && { roles: [] }),
    };

    resetForm();

    const mergedFilters = { ...accountFilter, ...updatedFields };
    account && model.updateGroupFilterForAccount(account);
    account && model.updateAccountTypeFilterForAccount(account);
    await applyFilter(new AccountFilterModel(mergedFilters));
  };

  const onSubmit = async (data: any) => {
    const model = new AccountFilterModel({
      ...accountFilter,
      ...data,
    });
    await applyFilter(model);
  };

  const renderGroups = () => {
    return (
      workspace && (
        <View style={styles.groupsSection}>
          <GroupFilter
            fieldName="groups"
            workspace={workspace}
            headerStyle={styles.filterRowHeader}
          />
        </View>
      )
    );
  };

  const renderAccountTypes = () => {
    return (
      workspace && (
        <View style={styles.groupsSection}>
          <AccountTypeFilter
            fieldName="roles"
            workspace={workspace}
            headerStyle={styles.filterRowHeader}
          />
        </View>
      )
    );
  };

  const renderStandardData = () => {
    return (
      <ColumnSelection
        baseColumns={AccountFilterColumns}
        adminColumns={AccountFilterAdminColumns}
        i18nPrefix="account.filter"
      />
    );
  };

  return (
    <FormContext.Provider value={formMethods}>
      <View style={styles.container}>
        <View style={[styles.scrollViewContainer, { marginBottom: 30 }]}>
          <ScrollView
            contentContainerStyle={styles.scrollViewContent}
            scrollEnabled={true}
            showsVerticalScrollIndicator={true}
          >
            {isGroupsFilter && renderGroups()}
            {isAccountTypesFilter && renderAccountTypes()}
            {isColumnsFilter && renderStandardData()}
          </ScrollView>
        </View>
        <DropdownFooter
          isDirty={isDirty}
          onSubmit={handleSubmit(onSubmit)}
          canReset={canReset}
          onReset={onReset}
        />
      </View>
    </FormContext.Provider>
  );
};
