import React, { useMemo } from 'react';
import { RoutineSelector } from '../../../shared/components/alerts/routineSelector';
import { useFindWorkspaceAssets } from './operations';
import { PromptRoutineSelectorProps } from './types';

export const PromptRoutineSelector = (props: PromptRoutineSelectorProps) => {
  const { data } = useFindWorkspaceAssets(
    props.whatItemType,
    props.whatItemId,
    !!props.whatItemId && !!props.whyItemId
  );

  const dataItems = useMemo(() => {
    if (!props.whyItemId || !data || !data.items || data.items.length === 0)
      return [];
    const assetsContainingContainerWhyRoutine = data.items.filter((wa) =>
      wa.routines?.some((r) => r.id === props.whyItemId)
    );
    const allDataItems = assetsContainingContainerWhyRoutine
      .flatMap((wa) => wa.routines || [])
      .filter((r: any) => r.deactivated !== true);
    return [
      { id: undefined, label: props.t('alerts.edit.none') },
      ...new Map(
        allDataItems.map((r) => [
          r.id,
          {
            id: r.id,
            label: r.name,
            type: r.type,
          },
        ])
      ).values(),
    ] as any[];
  }, [data, props.whyItemId]);

  return (
    <RoutineSelector
      dataItems={dataItems}
      control={props.control}
      name="promptWorkspaceRoutineItem"
      placeholder={props.t('alerts.edit.promptRoutinePlaceholder')}
    />
  );
};
