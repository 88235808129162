import { CounterConfig } from '@gripp/shared-logic';
import { MessageComponent, MessageComponentProps } from '../../issue/types';
import { CommonRoutineMessage } from '../components/commonRoutineMessage';

export const CounterMessage: MessageComponent = (
  props: MessageComponentProps
) => {
  const assetRoutine = props.message.assetRoutine!;
  const config = assetRoutine.routineVersion.config as CounterConfig;
  const count = assetRoutine.data.count.toString();
  const uom = config.uom;
  const routineText = `${count} ${uom}`;

  return <CommonRoutineMessage routineText={routineText} {...props} />;
};
