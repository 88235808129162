import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { ControlledRadioButtons } from '../forms';
import { preferencesStyles } from './styles';

type LanguagePreferencesProps = {
  control: Control<any>;
  disabled?: boolean;
};

export const LanguagePreferences = ({
  control,
  disabled,
}: LanguagePreferencesProps) => {
  const { t } = useTranslation();

  const languageOptions = [
    { label: t('preferences.language.languages.english'), value: 'en' },
    { label: t('preferences.language.languages.spanish'), value: 'es' },
  ];

  return (
    <View style={preferencesStyles.contentContainer}>
      <View>
        <Text style={preferencesStyles.heading}>
          {t('preferences.language.preferredLanguageTitle')}
        </Text>
        <View style={preferencesStyles.contentFieldForm}>
          <ControlledRadioButtons
            control={control}
            name="preferredLanguage"
            options={languageOptions}
            horizontal={true}
          />
        </View>
      </View>
    </View>
  );
};
