import { WorkflowQuestionConfig } from '../workflowQuestionConfig';
import { WorkflowResponseState } from '../workflowResponseState';
import { QuestionExceptionConfig } from './questionException';

export interface MediaQuestionConfig
  extends WorkflowQuestionConfig,
    QuestionExceptionConfig {}

export interface MediaResponseState extends WorkflowResponseState {
  mediaIds?: string[];
}
