import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Severity } from '@gripp/shared-logic';
import { ReactNode } from 'react';
import {
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { Colors } from '../../../themes';
import { SeverityIcons } from '../../issue/severityIcons';

type RoutineButtonProps = {
  onClick: () => void;
  text: ReactNode;
  buttonStyle?: StyleProp<ViewStyle>;
  buttonIcon?: Severity;
  buttonIconStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<ViewStyle>;
  buttonLabelStyle?: StyleProp<TextStyle>;
  leftButtonIcon?: ReactNode;
  children?: ReactNode;
  underlineInfoText?: string;
};

export const RoutineButton = (props: RoutineButtonProps) => {
  return (
    <TouchableOpacity
      onPress={props.onClick}
      style={[styles.button, props.buttonStyle]}
      activeOpacity={0.7}
    >
      {props.leftButtonIcon && (
        <View style={{ marginRight: 4 }}>{props.leftButtonIcon}</View>
      )}

      {props.buttonIcon && (
        <MaterialCommunityIcons
          name={SeverityIcons[props.buttonIcon]}
          size={20}
          style={props.buttonIconStyle ? props.buttonIconStyle : styles.icon}
        />
      )}
      <Text
        style={[
          props.labelStyle ? props.labelStyle : styles.buttonLabel,
          props.buttonLabelStyle,
        ]}
      >
        {props.text}
      </Text>
      {props.underlineInfoText && <Text>{props.underlineInfoText}</Text>}
      {props.children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: Colors.white,
    borderRadius: 8,
    borderColor: Colors.blue,
    borderWidth: 2,
    height: 44,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 16,
    textAlignVertical: 'center',
  },
  icon: {
    color: Colors.blue,
    ...Platform.select({
      web: {
        marginBottom: 0,
        marginRight: 6,
      },
      default: {
        marginBottom: 2,
      },
    }),
  },
});
