import { MaterialCommunityIcons } from '@expo/vector-icons';
import { getLanguage } from '@gripp/shared-logic';
import { random } from 'lodash';
import { Dispatch, SetStateAction, useRef } from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { Button } from 'react-native-paper';
import { useOpenedState } from '../../../hooks';
import { Colors } from '../../../themes';
import { DropDownView } from '../../modals';
import { datePickerStyle as styles } from './datePickerStyle';
import { LocaleConfig } from './localeConfig';

export const DatePickerCustomHeader = ({
  currentDate,
  setCurrentDate,
}: {
  currentDate: Date;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
}) => {
  const mothsRef = useRef<View>(null);
  const yearRef = useRef<View>(null);
  const {
    onOpen: onMonthOpen,
    onClose: onMonthClose,
    isOpened: isMonthOpened,
  } = useOpenedState();

  const {
    onOpen: onYearOpen,
    onClose: onYearClose,
    isOpened: isYearOpened,
  } = useOpenedState();

  const years = Array.from(
    { length: 3 },
    (_, i) => i + (new Date().getFullYear() - 2)
  );
  const monthNamesShort: string[] =
    LocaleConfig.locales[getLanguage()].monthNamesShort;

  return (
    <View style={styles.header}>
      {/* Month Dropdown */}
      <View ref={mothsRef}>
        <TouchableOpacity
          onPress={onMonthOpen}
          style={styles.buttonHeaderMonth}
        >
          <Text style={styles.labelHeader}>
            {monthNamesShort[currentDate.getMonth()]}
          </Text>
          <MaterialCommunityIcons
            name={isMonthOpened ? 'chevron-down' : 'chevron-up'}
            size={16}
            color={Colors.black}
          />
        </TouchableOpacity>
        <DropDownView
          anchorRef={mothsRef}
          isVisible={isMonthOpened}
          onDismiss={onMonthClose}
          width={100}
          height={300}
        >
          <ScrollView scrollEnabled={true} showsVerticalScrollIndicator>
            {monthNamesShort.map((month, index) => (
              <Button
                key={`${month}-${random}`}
                onPress={() => {
                  setCurrentDate(new Date(currentDate.setMonth(index)));
                  onMonthClose();
                }}
                contentStyle={{ justifyContent: 'flex-start' }}
              >
                <Text
                  style={[
                    styles.labelHeader,
                    monthNamesShort[currentDate.getMonth()] === month
                      ? styles.labelHeaderSelected
                      : {},
                  ]}
                >
                  {month}
                </Text>
              </Button>
            ))}
          </ScrollView>
        </DropDownView>
      </View>
      {/* Year Dropdown */}
      <View ref={yearRef}>
        <TouchableOpacity onPress={onYearOpen} style={styles.buttonHeaderMonth}>
          <Text style={styles.labelHeader}>{currentDate.getFullYear()}</Text>
          <MaterialCommunityIcons
            name={isYearOpened ? 'chevron-down' : 'chevron-up'}
            size={16}
            color={Colors.black}
          />
        </TouchableOpacity>
        <DropDownView
          anchorRef={yearRef}
          isVisible={isYearOpened}
          onDismiss={onYearClose}
          width={100}
          height={114}
        >
          <ScrollView scrollEnabled={true} showsVerticalScrollIndicator>
            {years.map((year, index) => (
              <Button
                key={`${year}-${random}`}
                onPress={() => {
                  setCurrentDate(new Date(currentDate.setFullYear(year)));
                  onYearClose();
                }}
                contentStyle={{ justifyContent: 'flex-start' }}
              >
                <Text
                  style={[
                    styles.labelHeader,
                    currentDate.getFullYear() === year
                      ? styles.labelHeaderSelected
                      : {},
                  ]}
                >
                  {year}
                </Text>
              </Button>
            ))}
          </ScrollView>
        </DropDownView>
      </View>
    </View>
  );
};
