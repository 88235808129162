import { CounterRoutineTriggerConfig, RoutineType } from '@gripp/shared-logic';
import { useMemo } from 'react';
import { RoutineTrigger } from './routineTrigger';

type CounterRoutineTriggerProps = {
  workspaceRoutineId: string;
  control: any;
  name?: string;
};

export const CounterRoutineTrigger = (props: CounterRoutineTriggerProps) => {
  const i18nPrefix = 'alerts.trigger.counter';
  const fieldName = props.name ?? 'config';

  const InitConfig = useMemo(
    () => ({
      onInterval: undefined,
      ceiling: undefined,
      floor: undefined,
      workspaceRoutineId: props.workspaceRoutineId,
      onCompletionCount: undefined,
      daysSinceCompleted: undefined,
      every: undefined,
      routineType: RoutineType.Counter,
    }),
    [props.workspaceRoutineId]
  );

  props.control.register(fieldName, {
    value: InitConfig,
  });

  return (
    <RoutineTrigger<CounterRoutineTriggerConfig>
      initConfig={InitConfig}
      control={props.control}
      name={fieldName}
      i18nPrefix={i18nPrefix}
    />
  );
};
