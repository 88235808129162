import { ActivityFilterKey, ActivityFilterModel } from '../domain';
import { useFilter } from '../../filter';

export const useActivityListFilter = (filterKey: ActivityFilterKey) => {
  const storageKey =
    filterKey === ActivityFilterKey.Activity
      ? 'activityFilter'
      : 'assetActivityFilter';
  return useFilter(
    filterKey,
    storageKey,
    (filter: string) => new ActivityFilterModel(filter)
  );
};
