import { CallingCode, CountryCode } from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import PhoneInput, { ICountry } from 'react-native-international-phone-number';
import { mixed, string, object as yupObject } from 'yup';
import 'yup-phone-lite';
import { Colors } from '../../themes/colors';

const getNumberWithSpaces = (value: string, pattern: string) => {
  let i = 0;
  let phone = value.toString();
  return pattern.replace(/#/g, (_) => phone[i++]);
};

const getMask = (countryCode: string) => {
  switch (countryCode) {
    case CountryCode.US:
      return '### ### ####';
    case CountryCode.CA:
      return '### ### ####';
    case CountryCode.MX:
      return '## #### ####';
    default:
      return '';
  }
};

export const getFormattedPhoneNumber = (
  countryCode: CountryCode,
  callingCode: string,
  phoneNumber: string
) => {
  return `${callingCode} ${getNumberWithSpaces(
    phoneNumber,
    getMask(countryCode)
  )}`;
};

type ControlledPhoneInputProps = {
  control: any;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  height?: number;
};

export const ControlledPhoneInput = (props: ControlledPhoneInputProps) => {
  const { t } = useTranslation();

  const [country, setCountry] = useState<undefined | ICountry>(undefined);
  const [countryCode, setCountryCode] = useState<undefined | string>(undefined);
  const [phoneNumber, setPhoneNumber] = useState<undefined | string>(undefined);
  const [mask, setMask] = useState<string>(getMask(CountryCode.US));

  const { field } = useController({
    control: props.control,
    name: props.name,
  });

  const onChangePhoneNumber = (newPhoneNumber: string) => {
    const value = newPhoneNumber
      ? {
          phoneNumber: newPhoneNumber.replace(/\s/g, ''),
          countryCode: country?.cca2,
          callingCode: country?.callingCode,
        }
      : null;

    setPhoneNumber(newPhoneNumber);
    field.onChange(value);
  };

  const onChangeCountry = (newCountry: ICountry) => {
    setCountry(newCountry);
    setMask(getMask(newCountry?.cca2));
    field.onChange(null);
  };

  useEffect(() => {
    if (!countryCode && field?.value?.countryCode) {
      setCountryCode(field.value.countryCode);
    }
    if (!phoneNumber && field?.value?.phoneNumber) {
      setPhoneNumber(
        getNumberWithSpaces(
          field?.value?.phoneNumber,
          getMask(field.value.countryCode)
        )
      );
    }
  }, [field]);

  return (
    <PhoneInput
      value={phoneNumber || ''}
      onBlur={field.onBlur}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      defaultCountry={countryCode || CountryCode.US}
      customMask={[mask]}
      onChangePhoneNumber={onChangePhoneNumber}
      selectedCountry={country}
      placeholder={t('phone')}
      popularCountries={[CountryCode.US]}
      showOnly={[CountryCode.CA, CountryCode.MX]}
      onChangeSelectedCountry={onChangeCountry}
      modalHeight={240}
      modalStyles={{
        modal: styles.modalStyle,
        flag: styles.modalFlag,
        searchInput: styles.modalSearchInput,
      }}
      phoneInputStyles={{
        container: [styles.phoneContainer, { height: props.height || 55 }],
        flag: styles.phoneFlag,
        flagContainer: styles.phoneFlagContainer,
        input: styles.phoneInput,
        callingCode: styles.phoneCallingCode,
      }}
    />
  );
};

const styles = StyleSheet.create({
  modalStyle: {
    maxWidth: 500,
  },
  modalFlag: {
    color: Colors.black,
  },
  phoneContainer: {
    backgroundColor: Colors.white,
    borderWidth: 0,
    borderStyle: 'solid',
    borderRadius: 6,
  },
  phoneFlag: {
    color: Colors.black,
  },
  phoneFlagContainer: {
    backgroundColor: Colors.white,
    justifyContent: 'center',
  },
  phoneInput: {
    color: Colors.black,
    borderWidth: 0,
    outlineStyle: 'none',
  },
  phoneCallingCode: {
    fontSize: 16,
    color: Colors.black,
  },
  modalSearchInput: {
    display: 'none',
  },
});

export const PhoneValidator = yupObject({
  countryCode: mixed<CountryCode>()
    .required()
    .oneOf(Object.values(CountryCode)),
  callingCode: mixed<CallingCode>()
    .required()
    .oneOf(Object.values(CallingCode)),
  phoneNumber: string().required().phone(),
});
