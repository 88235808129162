import {
  useAccountFilter,
  useCurrentWorkspace,
  useSetAccountFilter,
} from '@gripp/shared-logic';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { TextInput } from 'react-native-paper';
import { useParams } from 'react-router-dom';
import { Colors } from '../../themes';
import { DebouncedTextInput } from '../forms';

export const AccountSearchInput = () => {
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const { data: accountFilter } = useAccountFilter();
  const { mutateAsync: setAccountFilter } = useSetAccountFilter();
  const impersonatingWorkspace = useCurrentWorkspace(true);

  const [text, setText] = useState('');

  useEffect(() => {
    if (!text && accountFilter?.keyword) {
      setText(accountFilter.keyword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountFilter?.keyword]);

  const currentWorkspaceId = useCallback(() => {
    if (workspaceId) {
      return workspaceId;
    }

    if (impersonatingWorkspace) {
      return impersonatingWorkspace.id;
    }

    return undefined;
  }, [impersonatingWorkspace, workspaceId]);

  const updateSearch = async (term: string) => {
    if (!accountFilter) return;
    accountFilter.workspaceId = currentWorkspaceId();
    accountFilter.keyword = term;
    await setAccountFilter(accountFilter);
  };

  return (
    accountFilter && (
      <DebouncedTextInput
        debounceTimeMs={500}
        debounceLength={3}
        debounceFunc={updateSearch}
        placeholder={t('account.filter.searchPlaceholder')}
        value={text}
        onBlur={() => null}
        onChangeText={(text) => setText(text)}
        left={<TextInput.Icon icon="magnify" color={Colors.grayFill} />}
        right={
          text ? (
            <TextInput.Icon
              icon="close"
              size={20}
              color={Colors.black}
              onPress={() => setText('')}
            />
          ) : (
            <View />
          )
        }
        height={44}
        outlineStyle={styles.inputStyle}
        disableInnerStyle={true}
      />
    )
  );
};

const styles = StyleSheet.create({
  inputStyle: {
    backgroundColor: Colors.white,
    borderColor: Colors.grayDivider,
    borderWidth: 1,
    borderRadius: 8,
  },
});
