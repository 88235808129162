import { UseQueryResult, useQueryClient } from '@tanstack/react-query';
import { useCustomQuery } from '../graphql';

export const CONFIG_CACHE_KEY = ['config'];

export const useInvalidateConfig = () => {
  const queryClient = useQueryClient();
  return async () =>
    await queryClient.invalidateQueries({ queryKey: CONFIG_CACHE_KEY });
};

export type useConfigHook = () => UseQueryResult<any, any>;

export const useConfig = () => {
  return useCustomQuery({
    cacheKey: CONFIG_CACHE_KEY,
    query: query,
    options: {
      queryKey: CONFIG_CACHE_KEY,
      staleTime: Infinity,
    },
  });
};

const query = `
  query config {
    config {
      appInsightsConnectionString
      mediaUploadUrl
    }
  }
`;
