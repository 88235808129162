import { useSendAuthCode } from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { object as yupObject } from 'yup';
import {
  ControlledPhoneInput,
  PhoneValidator,
} from '../../components/forms/controlledPhoneInput';
import { LoginProps } from './props';
import { styles } from './styles';
import { ViewError } from './viewError';

export default function SmsCodeLogin(props: LoginProps) {
  const { t } = useTranslation();
  const [error] = useState<string | undefined>(undefined);
  const { sendAuthCodeAsync, isPending: isLoading } = useSendAuthCode();

  const formSchema = yupObject({
    phone: PhoneValidator,
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      phone: undefined,
    },
  });

  const onSubmit = async (data: any) => {
    const input = {
      phone: data.phone,
    };

    await sendAuthCodeAsync(input, props.onCodeSent!);
  };

  return (
    <View
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 20,
      }}
    >
      <View style={[{ maxWidth: 450 }, { paddingBottom: 28 }]}>
        <ControlledPhoneInput
          control={control}
          name="phone"
          placeholder={'phone'}
          height={48}
        />
      </View>

      <View style={styles.containerButton}>
        <Button
          mode="contained"
          disabled={!isValid || isLoading}
          onPress={handleSubmit(onSubmit)}
          loading={isLoading}
          style={[
            styles.button,
            !isValid || isLoading
              ? styles.disabledButton
              : styles.enabledButton,
          ]}
        >
          <Text style={styles.buttonText}>{t('login.login')}</Text>
        </Button>
      </View>
      <ViewError error={error} />
    </View>
  );
}

const sendAuthCode = `
  mutation Mutation($input: AuthCodeInput!) {
    authentication {
      sendAuthCode(input: $input) {
        success
      }
    }
  }
`;
