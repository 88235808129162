import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { useFindByQuery } from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { StyleSheet } from 'react-native';
import { GET_BATCH_TAGS } from '../operations/getBatchTags';

export const Download = ({ id, name }) => {
  const [rows, setRows] = useState(undefined);
  const { data } = useFindByQuery({
    query: GET_BATCH_TAGS,
    modelName: 'tag',
    variables: { batch: id },
  });

  const headers = [
    { label: 'Internal ID', key: 'id' },
    { label: 'Gripp ID', key: 'tagCode' },
    { label: 'Printed URL', key: 'printedUrl' },
    { label: 'Embedded App Url', key: 'embeddedAppUrl' },
    { label: 'Version', key: 'version' },
  ];

  useEffect(() => {
    if (data) {
      const rows = (data as any)?.items?.map((tag: any) => {
        const code = tag.tagCode.toUpperCase();
        return {
          id: tag.id,
          tagCode: code,
          printedUrl: `${code}@gripp.it`,
          embeddedAppUrl: `https://www.gripp.it/${code}`,
          version: tag.version.version,
        };
      });
      setRows(rows);
    }
  }, [data]);

  return rows ? (
    <CSVLink
      data={rows}
      filename={`${name}-tags.csv`}
      headers={headers}
      target="_blank"
      style={styles.link}
    >
      <Icon name="file-download" size={20} />
    </CSVLink>
  ) : (
    <h1>Loading...</h1>
  );
};

const styles = StyleSheet.create({
  link: {
    textDecorationLine: 'none',
  },
});
