import { Issue, IssueMessageType, Message } from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../themes';
import { AccountAvatar } from '../accountAvatar';
import {
  getIssueMessageHeader,
  getIssueMessageTextStyle,
} from './issueFormatUtils';
import { NewReaction } from './newReaction';
import { ReactionList } from './reactionList';
import { RoutineExceptionMessage } from './routineExceptionMessage';
import { RoutineMessage } from './routineMessage';
import { RoutinePromptMessage } from './routinePromptMessage';
import { MessageComponent } from './types';
import { UserMessage } from './userMessage';

export type IssueMessageProps = {
  message: Message;
  mediaUrl: string;
  maxImageWidth?: number;
  isUnread: boolean;
  issue: Issue;
};

const MessageComponents: Record<IssueMessageType, MessageComponent> = {
  [IssueMessageType.UserMessage]: UserMessage,
  [IssueMessageType.SystemMessage]: UserMessage,
  [IssueMessageType.RoutineMessage]: RoutineMessage,
  [IssueMessageType.RoutinePromptMessage]: RoutinePromptMessage,
  [IssueMessageType.RoutineExceptionMessage]: RoutineExceptionMessage,
};

const UNREAD_HIGHLIGHT_TIME_MS = 3000;

export const IssueMessage = (props: IssueMessageProps) => {
  const Message = MessageComponents[props.message.type];
  const [reactionVisible, setReactionVisible] = useState(false);

  const maxImageWidth = props.maxImageWidth || 320;
  const imageDimensions = {
    width: maxImageWidth,
    height: maxImageWidth,
  };
  const message = props.message;
  const header = getIssueMessageHeader(message);
  const textStyle = getIssueMessageTextStyle(message);

  const [unreadStyle, setUnreadStyle] = useState(props.isUnread);

  useEffect(() => {
    if (props.isUnread) {
      setTimeout(() => {
        setUnreadStyle(false);
      }, UNREAD_HIGHLIGHT_TIME_MS);
    }
  }, []);

  return (
    <>
      <TouchableOpacity
        activeOpacity={1}
        onLongPress={() => setReactionVisible(true)}
      >
        <View
          style={[styles.container, unreadStyle ? styles.unread : styles.read]}
        >
          <View>
            <AccountAvatar
              avatar={header.avatar}
              avatarIcon={header.avatarIcon}
              mediaUrl={props.mediaUrl}
              size={header.avatarSize}
              style={header.avatarIcon ? header.avatarIconStyle : {}}
              iconColor={header.avatarIconColor}
              hasLightbox
            />
          </View>
          <View style={styles.contentContainer}>
            <View style={styles.nameTimeContainer}>
              <Text
                variant="titleMedium"
                style={[styles.name, header.fontStyle]}
              >
                {header.updatedBy}
              </Text>
              <Text style={styles.time} variant="labelMedium">
                {header.updatedAt}
              </Text>
            </View>
            <View style={styles.message}>
              <Message
                message={message}
                imageDimensions={imageDimensions}
                mediaUrl={props.mediaUrl}
                textStyle={textStyle}
                issue={props.issue}
              />
              <ReactionList
                onOpenNewReaction={() => setReactionVisible(true)}
                issue={props.issue}
                message={message}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
      <NewReaction
        onCloseReaction={() => setReactionVisible(false)}
        reactionVisible={reactionVisible}
        issue={props.issue}
        message={message}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingVertical: 12,
    paddingHorizontal: 20,
  },
  contentContainer: {
    marginLeft: 12,
    flex: 1,
  },
  nameTimeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 16,
  },
  time: {
    marginLeft: 4,
    color: Colors.grayFill,
    fontSize: 12,
    fontWeight: '500',
    letterSpacing: -0.4,
  },
  message: {
    marginTop: 4,
  },
  messageText: {
    flexWrap: 'wrap',
    flexShrink: 1,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19,
  },
  read: {
    backgroundColor: Colors.white,
  },
  unread: {
    backgroundColor: Colors.unreadBackground,
  },
  modalContainer: {
    backgroundColor: 'transparent',
  },
  modalContentContainer: {
    marginTop: 'auto',
    height: 500,
  },
});
