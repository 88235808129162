import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { GraphbackOperationType, getDataObject } from './getDataObject';
import { cacheKeys } from './cacheKeys';
import { GetQueryArgs } from '../types';
import { useFetcher } from './useFetcher';

/**
 * shareable logic for using a graphback get query
 **/

export const useGetQuery = (opts: GetQueryArgs) => {
  const queryClient = useQueryClient();
  const cacheKey = cacheKeys(opts.modelName).detail(opts.id);

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries({ queryKey: cacheKey });
  };

  const variables = {
    id: opts.id,
  };

  const { data, isLoading } = useQuery({
    queryKey: cacheKey as QueryKey,
    queryFn: useFetcher(opts.query).bind(null, variables),
    ...opts.options,
    select: (data) => {
      return getDataObject(data, opts.modelName, GraphbackOperationType.get);
    },
  });

  return {
    data,
    isLoading,
    invalidateQuery,
  };
};
