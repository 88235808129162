import { NotificationSchedule as NotificationScheduleEnum } from '@gripp/shared-logic';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { Colors } from '../../themes/colors';
import { ControlledRadioButtons } from '../forms';
import { ControlledTimeInput } from '../forms/controlledTimeInput';
import { preferencesStyles } from './styles';

type NotificationScheduleProps = {
  control: Control<any>;
  disabled?: boolean;
};

export const NotificationSchedule = ({
  control,
}: NotificationScheduleProps) => {
  const scheduleDaysName = 'notificationPreferences.schedule.scheduleDays';
  const startTimeName = 'notificationPreferences.schedule.startTime';
  const endTimeName = 'notificationPreferences.schedule.endTime';

  const { t } = useTranslation();

  const scheduleDaysOptions = [
    {
      label: t('preferences.notifications.schedule.everyDay'),
      value: NotificationScheduleEnum.EveryDay,
    },
    {
      label: t('preferences.notifications.schedule.weekDays'),
      value: NotificationScheduleEnum.WeekDays,
    },
  ];

  const startTime = useWatch({
    control: control,
    name: startTimeName,
  });

  const endTime = useWatch({
    control: control,
    name: endTimeName,
  });

  const getTimeString = (date: Date): string => {
    return date
      ? new Date(date).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })
      : t('preferences.notifications.schedule.notSet');
  };

  return (
    <View style={preferencesStyles.contentContainer}>
      <View>
        <Text style={preferencesStyles.heading}>
          {t('preferences.notifications.schedule.notificationSchedule')}
        </Text>
        <View style={preferencesStyles.contentFieldForm}>
          <ControlledRadioButtons
            control={control}
            name={scheduleDaysName}
            options={scheduleDaysOptions}
            horizontal={true}
          />
          <View style={[{ minWidth: 500 }]}>
            <View style={styles.timeContainer}>
              <ControlledTimeInput
                control={control}
                name={startTimeName}
                modalLabel={t('preferences.notifications.schedule.set')}
                cancelLabel={t('preferences.notifications.schedule.cancel')}
                acceptLabel={t('preferences.notifications.schedule.accept')}
                defaultHours={9}
                defaultMinutes={0}
              />
              <Text style={styles.label}>
                {t('preferences.notifications.schedule.startTime')}
              </Text>
              <Text style={styles.time}>{getTimeString(startTime)}</Text>
            </View>
            <View style={styles.timeContainer}>
              <ControlledTimeInput
                control={control}
                name={endTimeName}
                modalLabel={t('preferences.notifications.schedule.set')}
                cancelLabel={t('preferences.notifications.schedule.cancel')}
                acceptLabel={t('preferences.notifications.schedule.accept')}
                defaultHours={17}
                defaultMinutes={0}
              />
              <Text style={styles.label}>
                {t('preferences.notifications.schedule.endTime')}
              </Text>
              <Text style={styles.time}>{getTimeString(endTime)}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  timeContainer: {
    flexDirection: 'row',
    alignContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    marginLeft: 20,
    height: 50,
  },
  label: {
    marginLeft: 10,
    fontSize: 16,
    color: Colors.black,
    fontWeight: '500',
    lineHeight: 19,
    paddingRight: 10,
  },
  time: {
    fontSize: 16,
    color: Colors.black,
    fontWeight: '400',
    lineHeight: 19,
    paddingRight: 10,
  },
});
