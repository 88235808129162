import {
  AssetRoutineState,
  useFindByQuery,
  useGrippContext,
} from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { RoutineModules } from '../routine';
import { SubHeader, SubHeaderProps } from './headerTypes';

interface SortedRoutineState {
  state: AssetRoutineState<any>;
  config: any;
  order?: number;
  type: any;
}

export const RoutineSubHeader: SubHeader = (props: SubHeaderProps) => {
  const { logger } = useGrippContext();
  const { workspaceAsset } = props;
  const [sorted, setSorted] = useState<SortedRoutineState[] | undefined>();
  const { data } = useFindByQuery({
    modelName: 'WorkspaceRoutineVersion',
    query: GET_ROUTINE_VERSIONS,
    variables: {
      filter: {
        id: {
          in: workspaceAsset.routineState?.map(
            (x) => x.workspaceRoutineVersionId
          ),
        },
      },
    },
  });

  useEffect(() => {
    if (!data) return;

    const sortedRoutines = workspaceAsset.routineState
      ?.map((state) => {
        const version = data.items.find(
          (x: { id: string }) => x.id === state.workspaceRoutineVersionId
        );
        const routine = workspaceAsset.routines?.find(
          (x) => x.id === state.workspaceRoutineId
        );
        const routineOrder = workspaceAsset.routineOrder?.find(
          (x) => x.workspaceRoutineId === state.workspaceRoutineId
        );
        return {
          state,
          config: version?.config,
          order: routineOrder?.order ?? routine?.order,
          type: version?.type,
        };
      })
      .filter((x) => x.state)
      .sort((a, b) => (a.order ?? 0) - (b?.order ?? 0))
      .slice(0, 3);

    setSorted(sortedRoutines);
  }, [data, workspaceAsset]);

  return (
    sorted && (
      <View style={styles.container}>
        {sorted.map((item, index) => {
          const Header = RoutineModules[item.type]?.header;
          if (!Header) {
            logger?.warning(
              `Routine Module not found (${workspaceAsset.id}) ${JSON.stringify(
                item
              )}`
            );
            return <></>;
          }
          return (
            <View key={`view_${index}`} style={styles.header}>
              <Header
                key={`header_${index}`}
                config={item.config}
                currentState={item.state}
              />
            </View>
          );
        })}
      </View>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  item: {
    margin: 0,
    padding: 0,
  },
});

const GET_ROUTINE_VERSIONS = `
  query FindWorkspaceRoutineVersions($filter: WorkspaceRoutineVersionFilter) {
    findWorkspaceRoutineVersions(filter: $filter) {
      items {
        config
        id
        type
      }
    }
  }
`;
