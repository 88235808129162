import { useRoles } from '@gripp/shared-logic';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, TextStyle, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../themes';
import { useFormContext } from '../forms';
import LoadingIndicator from '../loadingIndicator';
import { CheckBoxSelector } from './checkBoxSelector';

type AccountTypeFilterProps = {
  fieldName: string;
  workspace?: any;
  headerStyle: StyleProp<TextStyle>;
};

export const AccountTypeFilter = (props: AccountTypeFilterProps) => {
  const { roles, isLoading } = useRoles();
  const { setValue, watch } = useFormContext();
  const selectedAccountType = watch(props.fieldName);
  const { t } = useTranslation();

  const onAccountTypesChange = useCallback(
    (role: string) => {
      const setOptions = {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      };
      if (selectedAccountType?.includes(role)) {
        setValue(
          props.fieldName,
          selectedAccountType.filter((c: string) => c !== role),
          setOptions
        );
      } else {
        setValue(
          props.fieldName,
          [...(selectedAccountType || []), role],
          setOptions
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAccountType, setValue]
  );

  return isLoading ? (
    <LoadingIndicator flexContainer backgroundColor="transparent" />
  ) : props.workspace && roles && roles.length > 0 ? (
    roles.map((role) => (
      <CheckBoxSelector
        key={role.id}
        option={role.id}
        options={selectedAccountType}
        onChange={onAccountTypesChange}
        label={role.name}
      />
    ))
  ) : (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text
        style={{ fontSize: 16, fontWeight: '500', color: Colors.blackText }}
      >
        {t('account.filter.noAccountTypes')}
      </Text>
    </View>
  );
};
