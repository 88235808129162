import { FC } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { TopComponentBar, TopComponentBarProps } from '../navigation';
import { CancelButton } from './cancelButton';

export const TopModalBar: FC<TopComponentBarProps> = (
  props: TopComponentBarProps
) => {
  const cancelButton = (
    <CancelButton
      onCancel={props.onCancel!}
      showAlert={props.showAlert}
      confirmBack={false}
    />
  );
  return (
    <TopComponentBar
      {...props}
      backButton={cancelButton}
      style={styles.container}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    ...Platform.select({
      web: {
        height: 74,
      },
      default: {
        height: 64,
      },
    }),
  },
});
