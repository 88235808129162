import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Account } from '../domain/account';
import { useFetcher } from '../../graphql/hooks/useFetcher';
import { useAuthentication } from './useAuthentication';

export type useCurrentAccountHook = () => Account | null;

export const useCurrentAccount = () => {
  const [keyCloakId, setKeyCloakId] = useState<string | null>(null);
  const queryKey = ['currentAccount'];
  const authentication = useAuthentication();
  const cacheTime = 4 * 3600 * 1000; // 4 hours

  const variables = {
    keyCloakId: keyCloakId,
  };
  const [account, setAccount] = useState<Account | null>(null);
  const { data, isLoading, isFetching } = useQuery({
    queryKey: queryKey,
    queryFn: useFetcher(LOAD_CURRENT_ACCOUNT).bind(null, variables),
    enabled: variables.keyCloakId !== null,
    gcTime: cacheTime,
    staleTime: cacheTime,
  });

  useEffect(() => {
    if (authentication?.jwt?.sub) {
      setKeyCloakId(authentication?.jwt?.sub);
    }
  }, [authentication?.jwt?.sub]);

  useEffect(() => {
    if (data) {
      setAccount(getAccount(data));
    }
  }, [data]);

  return { account, isLoading, isFetching };
};

const getAccount = (data: any) => {
  const record = data.findAccounts.items[0];
  return new Account(record);
};

const LOAD_CURRENT_ACCOUNT = `
  query LoadAccount ( $keyCloakId: String! ) {
    findAccounts( filter: {
      keyCloakId: { eq: $keyCloakId }
    }) {
      items {
        id
        roles {
          permissions
        }
        name {
          first
          last
        }
        avatar {
          id
          type
          extension
        }
        email
        phone {
          callingCode
          countryCode
          phoneNumber
        }
        jobTitle
        workspace {
          name
          id
        }
        groups {
          id
        }
        preferences {
          preferredLanguage
          notificationPreferences {
            newIssueSeverities
            issueReplies
            issueSeverityChanges
            issueTitleChanges
            groupIds
          }
        }
      }
    }
  }
`;
