import {
  SignatureQuestionConfig,
  SignatureResponseState,
  useGrippContext,
} from '@gripp/shared-logic';
import { useFormContext } from '../../../../forms';
import {
  RoutineQuestionExecutionForm,
  RoutineQuestionExecutionFormProps,
} from '../types';

export const SignatureQuestionExecutionForm: RoutineQuestionExecutionForm<
  SignatureQuestionConfig,
  SignatureResponseState
> = (
  props: RoutineQuestionExecutionFormProps<
    SignatureQuestionConfig,
    SignatureResponseState
  >
) => {
  const fieldName = `responses.${props.responseIndex}.mediaId`;
  const { control, setValue } = useFormContext();
  control.register(fieldName, { value: undefined });
  const { signaturePadScreen } = useGrippContext();

  const SignaturePadScreen = signaturePadScreen!;

  return (
    <SignaturePadScreen
      setValue={setValue}
      fieldName={fieldName}
      toggleProcessing={props.toggleProcessing}
    />
  );
};
