import { useUpdateMutation } from '../../graphql';

export const useUpdateIssue = () => {
  return useUpdateMutation({
    modelName: 'issue',
    query: UPDATE_ISSUE,
  });
};

const UPDATE_ISSUE = `
  mutation UpdateIssue($input: MutateIssueInput!) {
    updateIssue(input: $input) {
      id
      title
      severity
    }
  }
`;
