import { ContainerClient } from '@azure/storage-blob';
import { useConfig, useGrippContext } from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

export const useContainerClient = () => {
  const [containerClient, setContainerClient] = useState<
    ContainerClient | undefined
  >(undefined);
  const { data } = useConfig();
  const { logger } = useGrippContext();

  // Microsoft...ugh; this makes the http agent for container client not throw errors on the web
  Platform.constants = {
    ...Platform.constants,
    reactNativeVersion: {
      major: 1,
      minor: 0,
      patch: 0,
    },
  };

  useEffect(() => {
    if (!data) return;
    if (containerClient) return;

    try {
      const url = data.config.mediaUploadUrl;
      setContainerClient(new ContainerClient(url));
    } catch (err: any) {
      logger?.error(err);
    }
  }, [containerClient, data]);

  return containerClient;
};
