import { StyleSheet, View } from 'react-native';
import { Colors } from '../themes';

export const HeaderDot = () => {
  return <View style={styles.bulletDiv} />;
};

const styles = StyleSheet.create({
  bulletDiv: {
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: Colors.grayText,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 1,
    marginHorizontal: 4,
  },
});

export default HeaderDot;
