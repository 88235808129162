import { MediaQuestionConfig } from '@gripp/shared-logic';
import { QuestionException } from '../shared/questionExceptionHandling';
import {
  RoutineQuestionAdminForm,
  RoutineQuestionAdminFormProps,
} from '../types';

export const MediaQuestionAdminForm: RoutineQuestionAdminForm<
  MediaQuestionConfig
> = (props: RoutineQuestionAdminFormProps<MediaQuestionConfig>) => {
  return <QuestionException {...props} />;
};
