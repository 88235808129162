import { ActivityFilterKey } from '@gripp/shared-logic';
import { ActivityFilter, DropDownView, FilterType } from '@gripp/shared-ui';
import { View } from 'react-native';

type ActivityGroupsFilterMenuProps = {
  onFilterOpen: () => void;
  onFilterClose: () => void;
  isOpened: boolean;
  anchorRef: React.RefObject<View>;
  filterKey?: ActivityFilterKey;
  type?: FilterType;
};

export const ActivityGroupsFilterMenu = ({
  onFilterClose,
  onFilterOpen,
  isOpened,
  anchorRef,
  filterKey,
  type,
}: ActivityGroupsFilterMenuProps) => {
  return (
    <DropDownView
      anchorRef={anchorRef}
      isVisible={isOpened}
      onDismiss={onFilterClose}
    >
      {filterKey && (
        <ActivityFilter
          goBack={onFilterClose}
          filterKey={filterKey}
          type={type}
        />
      )}
    </DropDownView>
  );
};
