import { useRef } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { useFormContext } from '../../forms';
import { GenericCounterExecutionComponent } from './genericCounterExecutionComponent';
import { TallyCounterExecutionComponent } from './tallyCounterExecutionComponent';

export type CounterExecutionComponentBaseProps = {
  formKeyPrefix?: string;
  instructions: string;
  initialValue: number;
  value: number;
  onChange: (value: number) => void;
  onBlur: () => void;
  autoFocus?: boolean;
  decimalPlaces?: number;
  tally: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  inputContainerStyle?: StyleProp<ViewStyle>;
  pillToggleContainerStyle?: StyleProp<ViewStyle>;
};

export type CounterExecutionComponentProps =
  CounterExecutionComponentBaseProps & {
    inputRef: any;
    onFocus: any;
    fieldName: string;
  };

export const CounterExecutionComponent = (
  props: CounterExecutionComponentBaseProps
) => {
  const inputRef = useRef(null);

  const fieldName = props.formKeyPrefix
    ? `${props.formKeyPrefix}.count`
    : 'count';

  const { watch } = useFormContext();
  const currentValue = watch(fieldName);

  const autoFocus = props.autoFocus === undefined ? true : props.autoFocus;

  const setCursorToEnd = () => {
    const num = currentValue ?? 0;
    const length = num.toString().length;
    const selection = { end: length, start: length };
    inputRef.current &&
      inputRef.current.setNativeProps &&
      inputRef.current.setNativeProps({
        selection: selection,
      });
    setTimeout(() => {
      inputRef.current &&
        inputRef.current.setNativeProps &&
        inputRef.current.setNativeProps({ selection: null });
    }, 0);
  };

  if (props.tally) {
    return (
      <TallyCounterExecutionComponent
        {...props}
        inputRef={inputRef}
        fieldName={fieldName}
        autoFocus={autoFocus}
        onFocus={setCursorToEnd}
      />
    );
  } else {
    return (
      <GenericCounterExecutionComponent
        {...props}
        inputRef={inputRef}
        fieldName={fieldName}
        autoFocus={autoFocus}
        onFocus={setCursorToEnd}
      />
    );
  }
};
