import { scaleDimensions, scaleToWidth } from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Animated,
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import ImageZoom from 'react-native-image-pan-zoom';
import { useAnimation } from '../../hooks/useAnimation';
import { Colors } from '../../themes/colors';

const maxWidth = 668;
const { width: widthWindow, height: heigthWindow } = Dimensions.get('window');

export const ZoomableImage = ({ uri }: { uri?: string }) => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: widthWindow, height: heigthWindow });
  const { opacity, fadeIn } = useAnimation();
  const [isLoading, setIsLoading] = useState(true);

  const isWeb = Platform.OS === 'web';
  const widthDimensions = maxWidth >= widthWindow ? widthWindow - 40 : maxWidth;

  useEffect(() => {
    if (uri) {
      Image.getSize(uri!, (width, height) => {
        const dimensionsScale = isWeb
          ? scaleDimensions(widthDimensions, width, height)
          : scaleToWidth(widthDimensions, width, height);

        setDimensions(dimensionsScale);
      });
    }
  }, [uri]);

  const finishLoading = () => {
    setIsLoading(false);
    fadeIn();
  };

  return (
    <View
      style={[styles.container, { width: widthWindow, height: heigthWindow }]}
    >
      <ActivityIndicator
        animating={isLoading}
        style={styles.loading}
        color={Colors.primary}
        size={30}
      />
      <ImageZoom
        cropWidth={widthWindow}
        cropHeight={heigthWindow}
        imageWidth={dimensions.width}
        imageHeight={dimensions.height}
        style={styles.containerImageViewer}
      >
        <Animated.Image
          style={{
            width: dimensions?.width,
            height: dimensions?.height,
            borderRadius: 12,
            opacity,
          }}
          source={{
            uri,
          }}
          onLoadEnd={finishLoading}
        />
      </ImageZoom>
    </View>
  );
};

const styles = StyleSheet.create({
  containerImageViewer: {
    ...Platform.select({
      web: {
        overflow: 'auto',
      },
    }),
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    position: 'absolute',
    zIndex: 9,
  },
});
