import { useCurrentAccount, useUpdateAccount } from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { string, object as yupObject } from 'yup';
import 'yup-phone-lite';
import { PhoneValidator } from '../forms/controlledPhoneInput';

export const useProfileForm = () => {
  const { account } = useCurrentAccount();

  const { mutateAsync: updateAccount } = useUpdateAccount();
  const queryClient = useQueryClient();

  const formSchema = yupObject({
    firstName: string().required(),
    lastName: string().optional().nullable(),
    jobTitle: string().optional().nullable(),
    email: string().optional().nullable(),
    phone: PhoneValidator.optional().nullable(),
  }).test('email or phone', (value) => !!(value.phone || value.email));

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    values: {
      firstName: account?.name?.first ?? '',
      lastName: account?.name?.last ?? '',
      jobTitle: account?.jobTitle ?? '',
      email: account?.email ?? '',
      phone: account?.phone,
    },
  });

  const nullIfEmpty = (value: string) => {
    return value === '' ? null : value;
  };

  const onSubmit = async (data: any) => {
    updateAccount(
      {
        input: {
          id: account?.id,
          name: {
            first: data.firstName,
            last: nullIfEmpty(data.lastName),
          },
          jobTitle: nullIfEmpty(data.jobTitle),
          email: data.email,
          phone: nullIfEmpty(data.phone),
        },
      },
      {
        onSettled: async () => {
          reset({}, { keepValues: true });
          await queryClient.invalidateQueries({ queryKey: ['currentAccount'] });
        },
      }
    );
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    formState: { isValid, isDirty },
  };
};
