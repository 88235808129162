import { omit } from 'lodash';
import { useState } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import DropDownPicker, {
  DropDownPickerProps,
  ItemType,
  ListModeType,
  ValueType,
} from 'react-native-dropdown-picker';
import { HelperText } from 'react-native-paper';
import { Colors } from '../../themes';

const DropDownPickerSpecificPropsList: (keyof DropDownPickerProps<ValueType>)[] =
  [
    'placeholder',
    'open',
    'setOpen',
    'onChangeValue',
    'items',
    'value',
    'setValue',
  ];

type DropDownPickerSpecificProps =
  | 'placeholder'
  | 'open'
  | 'setOpen'
  | 'onChangeValue'
  | 'items'
  | 'value'
  | 'setValue';

type BasePropsSelect<T extends ValueType> = {
  errorMessage?: string;
  label: string;
  dropDownPickerProps?: Omit<
    DropDownPickerProps<T>,
    DropDownPickerSpecificProps
  > & { [key: string]: any };
  items: ItemType<T>[];
  listMode?: ListModeType; // TODO: is this even used?
};

type SelectProps<T extends ValueType> = BasePropsSelect<T> & {
  onSelectItem: (item: ItemType<T> | null) => void;
  listMode?: ListModeType;
  disabled?: boolean;
};

export type ControlledSelectProps<T extends ValueType> = BasePropsSelect<T> & {
  name: string;
  errors?: FieldErrors;
  control: Control<any>;
  onSelectItem?: (item: T | null) => void;
};

export const ControlledSelect = <T extends ValueType>(
  props: ControlledSelectProps<T>
) => {
  const errorMessage = props.errors?.[props.name]?.message?.toString();

  const cleanedDropDownPickerProps = props.dropDownPickerProps
    ? omit(props.dropDownPickerProps, DropDownPickerSpecificPropsList)
    : {};

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => (
        <Select
          {...props}
          label={props.label}
          errorMessage={errorMessage}
          onSelectItem={(item) => {
            onChange(item);
          }}
          dropDownPickerProps={{
            ...cleanedDropDownPickerProps,
            value: value as T,
          }}
        />
      )}
    />
  );
};

export const Select = <T extends ValueType>(props: SelectProps<T>) => {
  const {
    label,
    errorMessage,
    onSelectItem,
    dropDownPickerProps,
    items,
    disabled,
  } = props;

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<T | null>(
    (dropDownPickerProps?.value as T) ?? null
  );

  const cleanedDropDownPickerProps = dropDownPickerProps
    ? omit(dropDownPickerProps, DropDownPickerSpecificPropsList)
    : {};

  return (
    <View
      style={{ width: '100%', zIndex: open ? 100000 : 0, position: 'relative' }}
    >
      <DropDownPicker
        testID="dropdown-picker"
        placeholder={label}
        open={open}
        setOpen={setOpen}
        onChangeValue={(selectedValue) => {
          setValue(selectedValue as T);
          onSelectItem(selectedValue as ItemType<T> | null);
        }}
        items={items}
        value={value}
        disabled={disabled}
        setValue={(callback) => {
          const newValue = callback(value);
          setValue(newValue);
          onSelectItem(newValue as ItemType<T> | null);
        }}
        style={disabled ? styles.disabled : {}}
        selectedItemContainerStyle={{
          backgroundColor: Colors.primary,
        }}
        selectedItemLabelStyle={{
          color: Colors.white,
        }}
        showTickIcon={false}
        {...cleanedDropDownPickerProps}
        listMode={props.listMode}
        containerProps={{
          style: {
            zIndex: open ? 10 : 1,
          },
        }}
      />
      {errorMessage && <HelperText type="error">{errorMessage}</HelperText>}
    </View>
  );
};

const styles = StyleSheet.create({
  dropdown: {
    backgroundColor: Colors.white,
    textAlign: 'right',
  },
  disabled: {
    backgroundColor: Colors.grayDisabled,
  },
});
