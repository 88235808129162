import { Pressable, StyleProp, ViewStyle } from 'react-native';
import { Avatar } from 'react-native-paper';
import { Colors } from '../../themes';

interface OrangeButtonProps {
  onButtonPress: () => void;
  isMenuVisible?: boolean;
  propStyle?: StyleProp<ViewStyle>;
}

export const OrangeButton = ({
  onButtonPress,
  isMenuVisible,
  propStyle,
}: OrangeButtonProps) => {
  return (
    <Pressable onPress={onButtonPress} style={propStyle}>
      <Avatar.Icon
        icon={isMenuVisible ? 'close' : 'plus'}
        color={Colors.white}
        size={50}
        style={[
          {
            backgroundColor: isMenuVisible
              ? Colors.primaryDark
              : Colors.primary,
          },
        ]}
      />
    </Pressable>
  );
};

export default OrangeButton;
