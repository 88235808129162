import { CounterBaseConfig, CounterConfig } from '@gripp/shared-logic';

import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { bool, number, string } from 'yup';
import { Colors } from '../../../themes';
import { ControlledCheckboxItem } from '../../forms';
import { RoutineAdminInputText } from '../components/routineAdminInputText';
import { RoutineAdminForm, RoutineAdminFormProps } from '../types';
import { CounterDecimalSelection } from './counterDecimalSelection';

export const CounterAdminSchema = {
  uom: string().required().notOneOf(['']),
  tally: bool().notRequired(),
  decimal: bool().notRequired(),
  decimalPlaces: number().when('decimal', {
    is: (value: any) => value === true,
    then: () => number().required(),
    otherwise: () => number().optional(),
  }),
};

export const CounterAdminForm: RoutineAdminForm<CounterConfig> = (
  props: RoutineAdminFormProps<CounterConfig>
) => {
  return (
    <CounterAdminComponent config={props.config} control={props.control} />
  );
};

type CounterAdminComponentProps = {
  config: CounterBaseConfig;
  control: Control;
  indexPrefix?: string;
};

export const CounterAdminComponent = (props: CounterAdminComponentProps) => {
  const { t } = useTranslation();
  const i18nPrefix = 'routines.counter.admin';

  const uomFieldName = props.indexPrefix ? `${props.indexPrefix}.uom` : 'uom';

  const tallyFieldName = props.indexPrefix
    ? `${props.indexPrefix}.tally`
    : 'tally';

  const decimalFieldName = props.indexPrefix
    ? `${props.indexPrefix}.decimal`
    : 'decimal';

  const decimalPlacesFieldName = props.indexPrefix
    ? `${props.indexPrefix}.decimalPlaces`
    : 'decimalPlaces';

  props.control.register(uomFieldName, {
    value: props.config.uom ?? '',
  });

  props.control.register(tallyFieldName, {
    value: props.config.tally ?? false,
  });

  props.control.register(decimalFieldName, {
    value: props.config.decimal ?? false,
  });

  props.control.register(decimalPlacesFieldName, {
    value: props.config.decimalPlaces ?? undefined,
  });

  return (
    <View style={styles.container}>
      <RoutineAdminInputText
        control={props.control}
        name="uom"
        i18nPrefix={i18nPrefix}
        indexPrefix={props.indexPrefix}
      />
      <View style={styles.tallyContainer}>
        <ControlledCheckboxItem
          control={props.control}
          name={tallyFieldName}
          label={t(`${i18nPrefix}.tallyCounter`)}
          position="trailing"
          labelStyle={styles.tallyLabel}
        />
      </View>
      <CounterDecimalSelection
        control={props.control}
        decimalFieldName={decimalFieldName}
        decimalPlacesFieldName={decimalPlacesFieldName}
        i18nPrefix={i18nPrefix}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
  },
  tallyContainer: {
    flexDirection: 'row',
    marginVertical: 10,
  },
  tallyLabel: {
    fontSize: 16,
    lineHeight: 16,
    color: Colors.black,
    fontWeight: '700',
  },
});
