import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Account, Role } from '@gripp/shared-logic';
import { useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Menu } from 'react-native-paper';
import { Colors } from '../../themes';
import { AccountTypeSelector } from './accountTypeSelector';
import { onPressProps } from './getAccountColumn';

type AccountChipMenuProps = {
  account: Account;
  onPress: (props: onPressProps) => Promise<void>;
  roleList: Role[];
};

export const AccountTypeMenu = ({
  account,
  onPress,
  roleList,
}: AccountChipMenuProps) => {
  const { roles } = account;
  const [visible, setVisible] = useState(false);

  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  if (!roles || roles.length === 0) {
    return null;
  }

  return (
    <Menu
      visible={visible}
      onDismiss={closeMenu}
      contentStyle={styles.menuContent}
      anchor={
        <Pressable onPress={openMenu}>
          <View style={styles.anchor}>
            <Text>{roles?.map((r) => r.name).join(', ')}</Text>
            <MaterialCommunityIcons
              name={visible ? 'chevron-up' : 'chevron-down'}
              size={24}
              color={Colors.blackText}
            />
          </View>
        </Pressable>
      }
    >
      <AccountTypeSelector
        account={account}
        onSelect={onPress}
        roleList={roleList}
        closeMenu={closeMenu}
      />
    </Menu>
  );
};

const styles = StyleSheet.create({
  menuContent: {
    backgroundColor: Colors.white,
    flexDirection: 'column',
    flex: 1,
    top: 30,
  },
  anchor: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
