import { Account, Audit } from '../../auth';
import { IssueMessageType } from '../../graphql';
import { Media } from '../../media';
import { WorkspaceRoutine } from '../../routine';
import { AssetRoutine } from '../../routine/domain/assetRoutine';
import { I18n } from '../../I18n';
import { Issue } from './issue';
import { EmojiType } from 'rn-emoji-keyboard/lib/typescript/types';

export interface Message extends Audit {
  id: string;
  type: IssueMessageType;
  issue: Issue;
  text: string;
  media?: Media[];
  assetRoutine?: AssetRoutine;
  promptRoutine: WorkspaceRoutine;
  linkedIssue?: Issue;
  i18n?: I18n;
  userMentions: Account[];
  reactions?: Reaction[];
}

export interface Reaction {
  id: string;
  createdAt: string;
  createdBy: string;
  reactionType: EmojiType['emoji'];
}
