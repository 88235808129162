import { RoutineExecutionProps } from '../routine';
import { createContext, useContext } from 'react';

// rather than passing callbacks all the way down, IssueContext can be used to provide the callbacks to the components that need them

export interface IssueContextData {
  onRoutineSelected: (props: RoutineExecutionProps) => Promise<void>;
  onRouteToIssue: (issueId: string) => Promise<void>;
}

export const IssueContext = createContext<IssueContextData | undefined>(
  undefined
);

export const useIssueContext = (): IssueContextData | undefined => {
  return useContext(IssueContext)!;
};
