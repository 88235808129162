export const LIST_GROUPS = `
  query FindGroups($filter: ListGroupsFilter!, $page: PageRequest, $orderBy: OrderByInput) {
    group {
      listGroups(filter: $filter, page: $page, orderBy: $orderBy) {
        count
        limit
        offset
        items {
          id
          name
          people
          assets
          numReportsRed
          numReportsYellow
        }
      }
    }
  }
`;
