import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../themes/colors';

type FilterHeaderProps = {
  canReset: () => boolean;
  onReset: () => void;
  goBack: () => void;
};

export const FilterHeader = (props: FilterHeaderProps) => {
  const { t } = useTranslation();
  const btnHitSlop = 15;

  return (
    <View style={styles.headerContainer}>
      <View style={styles.leftHeaderContainer}>
        <Pressable hitSlop={btnHitSlop} onPress={props.goBack}>
          <Text style={styles.cancel}>{t('activity.filter.cancel')}</Text>
        </Pressable>
      </View>
      <View>
        <Text variant="titleMedium" style={{ fontSize: 16, fontWeight: '700' }}>
          {t('activity.filter.title')}
        </Text>
      </View>
      <View style={styles.rightHeaderContainer}>
        <Pressable
          disabled={!props.canReset()}
          hitSlop={btnHitSlop}
          onPress={props.onReset}
        >
          <Text
            style={[
              styles.reset,
              {
                color: props.canReset()
                  ? Colors.primaryDark
                  : Colors.grayDisabled,
              },
            ]}
          >
            {t('activity.filter.reset')}
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  leftHeaderContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  rightHeaderContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  headerContainer: {
    borderBottomColor: Colors.black,
    borderBottomWidth: 0.5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 30,
    paddingBottom: 10,
  },
  cancel: {
    marginLeft: 20,
    fontSize: 16,
    fontWeight: '500',
    color: Colors.primaryDark,
  },
  reset: {
    marginRight: 20,
    fontSize: 16,
    fontWeight: '500',
  },
});
