import { MaterialCommunityIcons } from '@expo/vector-icons';
import { WorkspaceRoutine } from '@gripp/shared-logic';
import { Colors } from '@gripp/shared-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import { Menu } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';
import { CopyRoutineMenuItem } from './copyRoutineMenuItem';
import { DeleteRoutineMenuItem } from './deleteRoutineMenuItem';
import { ExportRoutineMenuItem } from './exportRoutineMenuItem';
import { menuStyles } from './menuStyles';

type RoutineMenuProps = {
  workspaceRoutine: WorkspaceRoutine;
};

export const RoutineMenu = (props: RoutineMenuProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuFired, setMenuFired] = useState(0);

  const openMenu = () => {
    setMenuVisible(true);
    setMenuFired(Date.now());
  };

  const closeMenu = () => {
    // Add fired check to combat paper menu bug:
    // https://github.com/callstack/react-native-paper/issues/3716
    if (Date.now() - menuFired > 400) {
      setMenuVisible(false);
    }
  };

  return (
    <View>
      <Menu
        statusBarHeight={0}
        visible={menuVisible}
        onDismiss={closeMenu}
        contentStyle={[menuStyles.menuContent, menuStyles.white]}
        style={menuStyles.menuContainer}
        anchor={
          <TouchableOpacity onPress={openMenu}>
            <MaterialCommunityIcons
              name="dots-vertical"
              color={Colors.primary}
              size={24}
            />
          </TouchableOpacity>
        }
      >
        <Menu.Item
          leadingIcon={'pencil'}
          style={[menuStyles.white, menuStyles.menuItem]}
          onPress={() => {
            closeMenu();
            navigate('edit');
          }}
          title={t('routines.menu.editRoutine')}
        />
        <ExportRoutineMenuItem
          workspaceRoutine={props.workspaceRoutine}
          onComplete={closeMenu}
        />
        <CopyRoutineMenuItem
          workspaceRoutine={props.workspaceRoutine}
          onSelected={closeMenu}
        />
        <DeleteRoutineMenuItem
          workspaceRoutine={props.workspaceRoutine}
          onSelected={closeMenu}
        />
      </Menu>
    </View>
  );
};
