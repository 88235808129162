import { useUpdateAccount } from '../../auth/hooks/useUpdateAccount';
import { Account } from '../../auth';
import i18n from 'i18next';

export const useSetDefaultPreferredLanguage = () => {
  const { mutateAsync } = useUpdateAccount();

  return async (account: Account) => {
    if (account.preferences?.preferredLanguage) return;

    let language = i18n.languages[0];
    if (language.includes('_')) {
      language = language.split('_')[0];
    }

    const input = {
      id: account.id,
      preferences: {
        ...account.preferences,
        preferredLanguage: language,
      },
    };

    await mutateAsync({ input });
  };
};
