import { useEffect } from 'react';
import { useAuthentication } from '../../auth/hooks/useAuthentication';
import { useGrippContext } from '../../grippContext';
import { SubscriptionClientProvider } from './subscriptionClientProvider';
import { SubscribePayload } from 'graphql-ws';

export const useSubscription = async (
  payload: SubscribePayload,
  onNewData: (data: any) => Promise<void>,
  enabled = true
) => {
  const { logger } = useGrippContext();
  const auth = useAuthentication();

  useEffect(() => {
    let unsubscribe = () => {};

    if (!auth || !auth.isAuthenticated || !auth.accessToken || !enabled) {
      return;
    }

    const client = SubscriptionClientProvider.getInstance(auth.accessToken);

    (async () => {
      const onNext = async (data: any) => {
        await onNewData(data);
      };

      await new Promise((resolve, reject) => {
        unsubscribe = client.subscribe(payload, {
          next: onNext,
          error: (error) => {
            logger?.error(error);
            reject();
          },
          complete: () => resolve(null),
        });
      });
    })();

    return unsubscribe;
  }, [auth, enabled]);
};
