import { FC } from 'react';
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { TouchableButton } from '../touchableButton';
import { Spacing } from '../../styles';

type CancelButtonProps = {
  onCancel: () => void;
  showAlert?: () => void;
  confirmBack?: boolean;
};

export const CancelButton: FC<CancelButtonProps> = (
  props: CancelButtonProps
) => {
  const { t } = useTranslation();

  const onPress = () => {
    if (props.confirmBack && props.showAlert) {
      props.showAlert();
    } else {
      props.onCancel();
    }
  };

  return <TouchableButton onPress={onPress} style={styles.left} label={t('topNav.cancel')} />;
};

const styles = StyleSheet.create({
  left: {
    marginLeft: Spacing.baseMargin.base,
  },
});


