import { MutateOptions } from '@tanstack/react-query';
import { IssueMessageType, useCreateMutation } from '../../graphql';
import { NewMessage } from '../domain';
import { ISSUE_MESSAGE_FRAGMENT } from './issueMessageFragment';
import {
  extractMentionsFromText,
  replaceMentionNamesWithPlaceholder,
} from '../../utils/mentionsHelper';

export type CreateIssueMessageArgs = {
  issue: any;
  message: NewMessage;
  options?: MutateOptions<any, any, unknown, unknown>;
};

export const useCreateIssueMessage = () => {
  const { mutateAsync, isPending } = useCreateMutation({
    modelName: 'issueMessage',
    query: CREATE_ISSUE_MESSAGE,
  });

  const createIssueMessage = async (args: CreateIssueMessageArgs) => {
    const mentions = extractMentionsFromText(args.message.message);

    args.message.message = replaceMentionNamesWithPlaceholder(
      args.message.message
    );

    const input: any = {
      issue: { id: args.issue.id },
      type: IssueMessageType.UserMessage,
      text: args.message.message,
      workspace: { id: args.issue.workspace.id },
      userMentions: mentions,
    };

    if (args.message.media) {
      input.media = args.message.media.map((x) => {
        return { id: x.id };
      });
    }

    return await mutateAsync({ input }, args.options);
  };

  return {
    createIssueMessage,
    isPending,
  };
};

const CREATE_ISSUE_MESSAGE = `
  mutation createIssueMessage($input: CreateIssueMessageInput!) {
    createIssueMessage(input: $input) {
      ${ISSUE_MESSAGE_FRAGMENT}
    }
  }
`;
