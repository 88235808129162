import { useUpdateMutation } from '@gripp/shared-logic';
import {
  Colors,
  ControlledTextInput,
  CreateInvitation,
  KeyboardSafeView,
  SaveFormTop,
  Spacing,
  TopModalBar,
  useAutoFocusRef,
} from '@gripp/shared-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { string, object as yupObject } from 'yup';
import { CopyText } from '../copyText';

interface InviteProps {
  headerCancel: () => void;
}
interface Initivation {
  url: string;
  invitationId: string;
}

export const Invite = ({ headerCancel }: InviteProps) => {
  const { t } = useTranslation();
  const [isFormInvite, setIsFormInvite] = useState(false);
  const [invitation, setInvitation] = useState<Initivation | undefined>(
    undefined
  );

  const handleToggleForm = () => setIsFormInvite(!isFormInvite);

  return (
    <View style={styles.modalContainer}>
      <View style={!isFormInvite ? { display: 'flex' } : { display: 'none' }}>
        <TopModalBar title={t('topNav.invite')} onCancel={headerCancel} />
        <View style={styles.contentContainer}>
          <CreateInvitation qrSize={250} onSetInvitation={setInvitation} />(
          <Button
            style={styles.button}
            mode="text"
            icon="account-plus-outline"
            textColor={Colors.primaryDark}
            onPress={handleToggleForm}
            disabled={!invitation}
          >
            <Text
              style={[
                styles.buttonText,
                invitation
                  ? { color: Colors.primaryDark }
                  : { color: Colors.grayDisabled },
              ]}
            >
              {t('invitation.invitePeople.invitePeopleForm')}
            </Text>
          </Button>
          )
        </View>
      </View>
      {isFormInvite && !!invitation && (
        <InviteForm
          onSettled={headerCancel}
          handleBack={handleToggleForm}
          invitation={invitation}
        />
      )}
    </View>
  );
};

const inviteFormSchema = yupObject({
  emails: string()
    .required()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(\s*,\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*$/,
      ''
    ),
});

const InviteForm = ({
  onSettled,
  invitation,
  handleBack,
}: {
  onSettled: () => void;
  handleBack: () => void;
  invitation: Initivation;
}) => {
  const { t } = useTranslation();
  const elementFocusCallBack = useAutoFocusRef();
  const { mutateAsync, isPending } = useUpdateMutation({
    modelName: 'invitation',
    query: UPDATE_INVITATION,
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    resolver: yupResolver(inviteFormSchema),
    mode: 'onChange',
    defaultValues: {
      emails: '',
    },
  });

  const onSubmit = async (data: { emails: string }) => {
    const emailList = data.emails.replace(/\s/g, '').split(',');
    const input = {
      recipients: emailList.map((email) => ({ email })),
      id: invitation.invitationId,
    };

    await mutateAsync(
      { input },
      {
        onSettled: () => {
          onSettled();
        },
      }
    );
  };

  const handleCancel = () => {
    if (!isPending) {
      onSettled();
    }
  };

  return (
    <View>
      <SaveFormTop
        isDirty={isDirty}
        isValid={isValid}
        onSubmit={handleSubmit(onSubmit)}
        title={t('topNav.invite')}
        saveLabel={t('invitation.invitePeople.submit')}
        saveButtonType="button"
        topBar={TopModalBar}
        onCancel={handleCancel}
      >
        <KeyboardSafeView style={styles.keyboardContainer}>
          <ScrollView contentContainerStyle={styles.formContainer}>
            <View>
              <View style={styles.formItem}>
                <View>
                  <Text style={styles.formItemTitle}>
                    {t('invitation.invitePeople.to')}:
                  </Text>
                </View>
                <ControlledTextInput
                  textArea={true}
                  control={control}
                  name="emails"
                  placeholder={t('invitation.invitePeople.placeholder')}
                  innerRef={elementFocusCallBack}
                />
              </View>
              <View style={styles.copy}>
                <CopyText
                  buttonText={t('invitation.invitePeople.copyInviteLink')}
                  successMessage={t(
                    'invitation.invitePeople.copiedInvitationLink'
                  )}
                  errorMessage={t(
                    'invitation.invitePeople.errorCopyingInvitationLink'
                  )}
                  textToCopy={invitation.url}
                />
              </View>
              <View>
                <Text style={styles.linkDescription}>
                  {t('invitation.invitePeople.linkAllowsToJoinWorkplace')}
                </Text>
              </View>
            </View>
            <View>
              <Button
                style={styles.button}
                mode="text"
                icon="account-plus-outline"
                textColor={Colors.primaryDark}
                onPress={handleBack}
                disabled={isPending}
              >
                <Text style={styles.buttonText}>
                  {t('invitation.invitePeople.invitePeopleQR')}
                </Text>
              </Button>
            </View>
          </ScrollView>
        </KeyboardSafeView>
      </SaveFormTop>
    </View>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    width: 560,
    height: 444,
    borderRadius: 12,
    backgroundColor: Colors.primaryGrayBackground,
  },
  contentContainer: {
    height: 370,
    width: '100%',
    alignItems: 'center',
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    paddingBottom: Spacing.basePadding.paddingVertical,
    justifyContent: 'space-around',
  },
  button: {
    borderWidth: 0,
    paddingTop: 10,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  buttonText: {
    fontWeight: '400',
    color: Colors.primaryDark,
    fontSize: 16,
    lineHeight: 19,
  },
  keyboardContainer: {
    height: 370,
  },
  formContainer: {
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    paddingVertical: Spacing.basePadding.paddingVertical,
    height: '100%',
    justifyContent: 'space-between',
  },
  formItemTitle: {
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 16,
    marginBottom: 10,
  },
  formItem: {
    marginBottom: Spacing.baseMargin.marginVertical,
  },
  copy: {
    marginBottom: 4,
  },
  linkDescription: {
    fontSize: 14,
    fontWeight: '400',
  },
});

const UPDATE_INVITATION = `
  mutation updateInvitation($input: MutateInvitationInput!) {
    updateInvitation(input: $input) {
      id,
      recipients {
        email
      }
    }
  }
`;

export default Invite;
