import { Colors } from '@gripp/shared-ui';
import { Image, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { getCurrentDimensions } from '../shared/hooks/useWindowDimensions';
import { DownloadApp } from './downloadApp';

type AnonymousReportedProps = {
  message?: { message: string; messageDownloadApp: string };
};

export const AnonymousReported = (props: AnonymousReportedProps) => {
  const dimensions = getCurrentDimensions();
  const heightStyle = { minHeight: dimensions.height };

  return (
    <View style={[styles.container, heightStyle]}>
      <View>
        <Image
          style={styles.image}
          source={require('../../static-assets/GrippIconDark.png')}
          resizeMode="contain"
        />
      </View>
      <View>
        <Text style={[styles.font, styles.title]}>
          {props?.message?.message}
        </Text>
        <Text style={[styles.font, styles.downloadApp]}>
          {props.message?.messageDownloadApp}
        </Text>
      </View>
      <View style={styles.download}>
        <DownloadApp />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.black,
    width: '100%',
    height: '100%',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    paddingHorizontal: 50,
  },
  image: {
    height: 119,
    width: 121,
  },
  font: {
    color: Colors.white,
  },
  title: {
    fontSize: 38,
    fontWeight: '700',
    paddingTop: 32,
    flexWrap: 'wrap',
  },
  downloadApp: {
    paddingTop: 24,
    fontSize: 24,
    fontWeight: '500',
    lineHeight: 30,
    letterSpacing: 0,
    textAlign: 'left',
  },
  download: {
    marginTop: 67,
  },
});
