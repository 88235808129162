import { useMutation } from '@tanstack/react-query';
import { CustomMutationArgs } from '../types';
import { useFetcher } from './useFetcher';

export const useCustomMutation = (args: CustomMutationArgs) => {
  return useMutation({
    mutationFn: useFetcher(args.query),
    ...args.options,
  });
};
