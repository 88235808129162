import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { Checkbox } from 'react-native-paper';
import { filterStyles as styles } from './filterStyle';

export interface CheckBoxSelectorProps {
  option: string;
  options: string[];
  onChange: (option: string) => void;
  max?: number;
  label: string;
}

export const CheckBoxSelector = (props: CheckBoxSelectorProps) => {
  const [status, setStatus] = useState<
    'checked' | 'unchecked' | 'indeterminate'
  >('unchecked');
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();

  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    if (props.options?.includes(props.option)) {
      setStatus('checked');
      setDisabled(false);
    } else {
      setStatus('unchecked');
      if (props.max && props.options?.length >= props.max - 1) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [props.options]);

  return (
    <Checkbox.Item
      key={props.option}
      status={status}
      disabled={disabled}
      labelStyle={styles.checkboxLabel}
      position="trailing"
      label={props.label}
      style={[styles.rowContainer, { paddingVertical: isWeb ? 10 : undefined }]}
      rippleColor={isWeb ? 'transparent' : undefined}
      onPress={() => props.onChange(props.option)}
      mode="android"
    />
  );
};
