import { TextQuestionConfig } from '@gripp/shared-logic';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { ControlledCheckboxItem } from '../../../../../components/forms';
import { Colors } from '../../../../../themes/colors';
import { QuestionException } from '../shared/questionExceptionHandling';
import {
  RoutineQuestionAdminForm,
  RoutineQuestionAdminFormProps,
} from '../types';

export const TextQuestionAdminForm: RoutineQuestionAdminForm<
  TextQuestionConfig
> = (props: RoutineQuestionAdminFormProps<TextQuestionConfig>) => {
  const { t } = useTranslation();
  const label = t('routines.workflow.admin.question.multiline');
  const fieldName = props.indexPrefix
    ? `${props.indexPrefix}.multiline`
    : 'multiline';
  props.control.register(fieldName, {
    value: props.config.multiline,
  });

  return (
    <>
      <ControlledCheckboxItem
        control={props.control}
        name={fieldName}
        label={label}
        position="trailing"
        style={styles.container}
        labelStyle={styles.label}
      />
      <QuestionException {...props} />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
  },
  label: {
    fontSize: 16,
    lineHeight: 16,
    color: Colors.black,
    marginBottom: 10,
    fontWeight: '700',
  },
});
