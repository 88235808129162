import { useUpdateMutation } from '../../graphql';

export const useUpdateFeature = () => {
  return useUpdateMutation({
    modelName: 'feature',
    query: UPDATE_FEATURE,
  });
};

const UPDATE_FEATURE = `
  mutation updateFeature($input: MutateFeatureInput!) {
    updateFeature(input: $input) {
      id
      enabled
    }
  }
`;
