import {
  ActivityFilterKey,
  useActivityListFilter,
  useSetActivityListFilter,
} from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { TextInput } from 'react-native-paper';
import { Colors } from '../../themes';
import { DebouncedTextInput } from '../forms';

type ActivitySearchInputProps = {
  filterKey?: ActivityFilterKey.AssetActivity | ActivityFilterKey.Activity;
};

export const ActivitySearchInput: React.FC<ActivitySearchInputProps> = ({
  filterKey = ActivityFilterKey.Activity,
}) => {
  const { t } = useTranslation();
  const { data: activityFilter } = useActivityListFilter(filterKey);

  const { mutateAsync } = useSetActivityListFilter(filterKey);

  const [text, setText] = useState('');

  useEffect(() => {
    if (!text && activityFilter?.keyword) {
      setText(activityFilter.keyword);
    }
  }, [activityFilter]);

  const updateSearch = async (keyword: string) => {
    activityFilter.keyword = keyword;
    await mutateAsync(activityFilter);
  };

  return (
    activityFilter && (
      <DebouncedTextInput
        debounceTimeMs={500}
        debounceLength={3}
        debounceFunc={updateSearch}
        placeholder={t('activity.filter.searchPlaceholder')}
        value={text}
        onBlur={() => {}}
        onChangeText={(text) => setText(text)}
        left={<TextInput.Icon icon="magnify" color={Colors.black} size={24} />}
        right={
          text ? (
            <TextInput.Icon
              icon="close"
              size={20}
              color={Colors.black}
              onPress={() => setText('')}
            />
          ) : (
            <View />
          )
        }
        height={44}
        outlineStyle={styles.inputStyle}
        disableInnerStyle={true}
      />
    )
  );
};

const styles = StyleSheet.create({
  inputStyle: {
    backgroundColor: Colors.white,
    borderColor: Colors.grayDivider,
    borderWidth: 1,
    borderRadius: 8,
  },
});
