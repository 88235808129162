import { AssetFilter, DropDownView, FilterType } from '@gripp/shared-ui';
import { View } from 'react-native';

type AssetFilterMenuProps = {
  onColumnFilterOpen: () => void;
  onColumnFilterClose: () => void;
  isOpened: boolean;
  anchorRef: React.RefObject<View>;
  type: FilterType;
};

export const AssetFilterMenu = ({
  onColumnFilterClose,
  onColumnFilterOpen,
  isOpened,
  anchorRef,
  type,
}: AssetFilterMenuProps) => {
  return (
    <DropDownView
      anchorRef={anchorRef}
      isVisible={isOpened}
      onDismiss={onColumnFilterClose}
    >
      <AssetFilter closeFilter={onColumnFilterClose} type={type} />
    </DropDownView>
  );
};
