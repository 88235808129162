import { AccountFilterKey, AccountFilterModel } from '../domain';
import { useFilter } from '../../filter';

export const useAccountFilter = () => {
  return useFilter(
    AccountFilterKey,
    'accountFilter',
    (filter: any) => new AccountFilterModel(filter)
  );
};
