import {
  RoutineQuestionConfig,
  RoutineType,
  WorkflowQuestionType,
  WorkspaceRoutine,
  useCurrentWorkspace,
  useFilteredWorkspaceRoutines,
} from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../../../../themes';
import { Select, useFormContext } from '../../../../forms';
import {
  RoutineQuestionAdminForm,
  RoutineQuestionAdminFormProps,
} from '../types';

export const ExistingRoutineQuestionAdminForm: RoutineQuestionAdminForm<
  RoutineQuestionConfig
> = (props: RoutineQuestionAdminFormProps<RoutineQuestionConfig>) => {
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext();
  const [items, setItems] = useState<any[]>([]);
  const [selectedRoutine, setSelectedRoutine] = useState<
    WorkspaceRoutine | undefined
  >(undefined);
  const workspace = useCurrentWorkspace();

  const routines = useFilteredWorkspaceRoutines(
    {
      workspace: { eq: workspace?.id },
      type: {
        in: [RoutineType.Counter, RoutineType.Status, RoutineType.QuickConfirm],
      },
      deactivated: { ne: true },
    },
    workspace?.id,
    !workspace
  );

  useEffect(() => {
    if (!routines) return;

    // Filter out already selected routines in this Workflow
    const currentRoutineId = getValues(
      `${props.indexPrefix}.workspaceRoutineId`
    );
    const formData = getValues();
    const routineQuestions = formData?.pages
      ?.flatMap((page: any) => page.questions)
      .filter(
        (q: any) =>
          q.type === WorkflowQuestionType.Routine &&
          q.workspaceRoutineId !== currentRoutineId
      );

    const newItems = routines
      .filter(
        (r) => !routineQuestions.some((q: any) => q.workspaceRoutineId === r.id)
      )
      .map((routine) => ({
        label: routine.name,
        value: routine.id,
      }));
    setItems(newItems);

    if (currentRoutineId) {
      const routine = routines.find((r) => r.id === currentRoutineId);
      setSelectedRoutine(routine);
    }
  }, [routines]);

  const onRoutineSelected = (routineId: string) => {
    if (!routineId) return;

    const routine = routines?.find((r) => r.id === routineId);
    setSelectedRoutine(routine);
    setValue(
      `${props.indexPrefix}.instructions`,
      (routine?.config as any).instructions
    );
    props.control.register(`${props.indexPrefix}.workspaceRoutineId`, {
      value: routineId,
    });
    setValue(`${props.indexPrefix}.workspaceRoutineId`, routineId, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    items &&
    items.length > 0 && (
      <View>
        <Text style={styles.label}>
          {t('routines.workflow.admin.routine.label')}
        </Text>
        {selectedRoutine ? (
          <Text style={styles.routineName}>{selectedRoutine.name}</Text>
        ) : (
          <Select
            items={items}
            label={t('routines.workflow.admin.routine.select')}
            onSelectItem={onRoutineSelected}
            disabled={!!selectedRoutine}
            listMode="SCROLLVIEW"
            dropDownPickerProps={{
              dropDownDirection: 'TOP',
            }}
          />
        )}
      </View>
    )
  );
};

const styles = StyleSheet.create({
  label: {
    fontSize: 16,
    lineHeight: 16,
    color: Colors.black,
    marginBottom: 10,
    fontWeight: '700',
  },
  routineName: {
    fontSize: 16,
    lineHeight: 16,
    color: Colors.black,
    marginLeft: 10,
  },
});
