import { WorkflowQuestionConfig } from '../workflowQuestionConfig';
import { WorkflowResponseState } from '../workflowResponseState';
import { QuestionMultipleValueExceptionConfig } from './questionException';

export interface RadioQuestionConfig
  extends WorkflowQuestionConfig,
    QuestionMultipleValueExceptionConfig {
  options: string[];
}

export interface RadioState extends WorkflowResponseState {
  option: string;
}
