import {
  TagBatchStatus,
  getEnumKey,
  useFindByQuery,
} from '@gripp/shared-logic';
import { DataGrid, StatusBadge, StatusBadgeType } from '@gripp/shared-ui';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Modal, Portal, Text } from 'react-native-paper';
import { UpdateForm } from '../../shared/components/forms/mutationForm';
import { GET_TAG_BATCH, UPDATE_TAG_BATCH } from '../operations';
import { GET_TAG_BATCHES } from '../operations/getTagBatches';
import { Download } from './download';
import { EditTagBatchForm } from './editTagBatchForm';

// NOTE: this is a temporary component to be used until we get filters on grids

export const TagBatches = () => {
  const [dataRows, setDataRows] = useState(undefined);
  const { data, isLoading } = useFindByQuery({
    modelName: 'tagbatch',
    query: GET_TAG_BATCHES,
  });

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('status', {
      cell: (info) => {
        const enumStatus = info.getValue() as TagBatchStatus;
        const status = info.getValue();
        const statusBadgeType =
          enumStatus === TagBatchStatus.Delivered
            ? StatusBadgeType.Active
            : StatusBadgeType.Inactive;
        return (
          <StatusBadge
            type={statusBadgeType}
            text={getEnumKey(TagBatchStatus, status)}
          />
        );
      },
      header: () => 'Status',
      meta: { style: styles.status },
    }),
    columnHelper.accessor('id', {
      cell: ({ row }) => {
        const original = row.original as any;
        const enumStatus = original.status as TagBatchStatus;
        return enumStatus === TagBatchStatus.Provisioned ? (
          <Download id={original.id} name={original.name} />
        ) : (
          <></>
        );
      },
      header: () => null,
      meta: { style: styles.downloadCell },
    }),
    columnHelper.accessor('name', {
      cell: ({ row }) => {
        return <EditBatchModal id={row.original.id} name={row.original.name} />;
      },
      header: () => 'Name',
    }),
    columnHelper.accessor('workspace', {
      cell: (info) => info.getValue()?.name,
      header: () => 'Workspace',
    }),
    columnHelper.accessor('quantity', {
      cell: (info) => info.getValue(),
      header: () => 'Quantity',
    }),
    columnHelper.accessor('memo', {
      cell: (info) => info.getValue(),
      header: () => 'Memo',
    }),
    columnHelper.accessor('updatedAt', {
      cell: (info) => {
        if (info.getValue()) {
          const date = moment(info.getValue());
          return date.fromNow();
        }
      },
      header: () => 'Updated',
    }),
  ];

  useEffect(() => {
    if (data) {
      setDataRows(data.items);
    }
  }, [data]);

  return dataRows ? (
    <DataGrid data={dataRows} columns={columns} isLoading={isLoading} />
  ) : (
    <h1>Loading...</h1>
  );
};

type EditBatchModalProps = {
  id: string;
  name: string;
};
const EditBatchModal = (props: EditBatchModalProps) => {
  const dataTransformer = (data) => {
    return {
      id: data.id,
      status: data.status,
    };
  };

  const [visible, toggleVisibility] = useState(false);
  return (
    <View>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={() => toggleVisibility(false)}
          contentContainerStyle={styles.modal}
        >
          <UpdateForm
            formComponent={EditTagBatchForm}
            id={props.id}
            modelName="tagbatch"
            invalidateCache={true}
            mutationOperation={UPDATE_TAG_BATCH}
            queryOperation={GET_TAG_BATCH}
            dataTransformer={dataTransformer}
            onComplete={() => toggleVisibility(false)}
          />
        </Modal>
      </Portal>
      <Text style={styles.link} onClick={() => toggleVisibility(true)}>
        {props.name}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  link: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  button: {
    padding: 0,
    margin: 0,
  },
  modal: {
    alignSelf: 'center',
    backgroundColor: 'white',
    padding: 20,
    margin: 20,
    borderRadius: 10,
    width: 500,
    minHeight: 400,
  },
  status: {
    width: 100,
    maxWidth: 100,
  },
  downloadCell: {
    paddingLeft: 20,
    width: 60,
    maxWidth: 60,
    alignContent: 'center',
  },
});
