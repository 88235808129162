import {
  SortDirectionEnum,
  getFullName,
  useAllWorkspaces,
  useFindByQuery,
} from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { BulkUpdateFileUploader } from './bulkUpdateFileUploader';

export const AllowedWorkspacesAdmin = ({ workspaceId, workspaceName }) => {
  const [rows, setRows] = useState(undefined);
  const [headers, setHeaders] = useState(undefined);

  const filter = {
    workspace: { eq: workspaceId },
  };
  const orderBy = { field: 'name', order: SortDirectionEnum.Asc };

  const { data: accounts } = useFindByQuery({
    query: FIND_ACCOUNTS_IN_WORKSPACE,
    modelName: 'account',
    variables: { filter, orderBy },
  }) as any;

  const availableWorkspaces = useAllWorkspaces().filter(
    (x) => x.id !== workspaceId
  );

  useEffect(() => {
    if (!accounts || !availableWorkspaces) return;

    const rows = processData(accounts.items, availableWorkspaces);
    const header = processHeaders(availableWorkspaces);
    setHeaders(header);
    setRows(rows);
  }, [accounts, availableWorkspaces]);

  return (
    <BulkUpdateFileUploader
      title="Users"
      headers={headers}
      rows={rows}
      workspaceId={workspaceId}
      workspaceName={workspaceName}
    />
  );
};

const workspaceKey = (workspace: any) =>
  `${workspace.name.substring(0, 20)}+${workspace.id}`;

const processData = (accounts: any, workspaces: any) => {
  const rows = accounts.map((account) => {
    const row: { [key: string]: string } = {
      UserId: account.id,
      FullName: getFullName(account.name),
      Email: account.email ?? '',
      Phone: account.phone ?? '',
    };
    workspaces.forEach((workspace) => {
      row[workspaceKey(workspace)] = account.allowedWorkspaces?.some(
        (w) => w.id === workspace.id
      )
        ? 'x'
        : '';
    });
    return row;
  });
  return rows;
};

const processHeaders = (availableWorkspaces: any) => {
  return [
    'UserId',
    'FullName',
    'Email',
    'Phone',
    ...availableWorkspaces.map((x) => workspaceKey(x)),
  ];
};

const FIND_ACCOUNTS_IN_WORKSPACE = `
  query FindAccounts($filter: AccountFilter, $orderBy: OrderByInput) {
    findAccounts(filter: $filter, orderBy: $orderBy) {
      items {
        id
        name {
          first
          last
        }
        email
        phone {
          callingCode
          countryCode
          phoneNumber
        }
        allowedWorkspaces {
          id
        }
      }
    }
  }
`;
