import { ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg';

export enum GrippIconVariant {
  Light = 'Light',
  Dark = 'Dark',
}

interface GrippIconProps {
  variant: GrippIconVariant;
}

export const GrippIcon = (props: GrippIconProps) => {
  const Icon = props.variant === GrippIconVariant.Light ? LighIcon : DarkIcon;

  return Icon;
};

const LighIcon = (
  <Svg width="25" height="24" viewBox="0 0 25 24" fill="none">
    <G clipPath="url(#clip0_239_5371)">
      <Path
        d="M19.1761 8.71324C19.3424 9.14516 19.3879 9.60183 19.4835 10.0495C19.7454 11.2733 19.9708 12.5061 20.2577 13.7254C20.3123 13.9571 20.244 14.0651 20.137 14.2113C19.5883 14.9627 19.0555 15.7253 18.4863 16.4609C18.3383 16.6521 18.3041 16.8276 18.3041 17.048C18.3064 19.2729 18.2996 21.4978 18.3041 23.7226C18.3041 23.9273 18.2495 23.9813 18.0423 23.9813C15.219 23.9746 12.3934 23.9768 9.57004 23.9768C9.14199 23.9768 9.07369 23.9206 9.02587 23.4954C8.98034 23.113 8.86194 22.7463 8.78225 22.3706C8.64108 21.698 8.47943 21.0298 8.33371 20.3595C8.30411 20.2177 8.19482 20.1345 8.12651 20.0198C8.08097 19.9095 8.04454 19.7926 7.98762 19.6891C7.65748 19.0704 7.32505 18.4518 6.99036 17.8354C6.95393 17.7679 6.94709 17.6689 6.83781 17.6667C6.80138 17.6284 6.76495 17.5902 6.72852 17.5542C6.81276 17.4575 6.74901 17.3765 6.70119 17.2887C6.31868 16.5824 5.93617 15.876 5.56276 15.1651C5.50811 15.0639 5.43298 15.0144 5.3419 14.9694C5.37606 14.8569 5.34418 14.7444 5.2736 14.6702C4.9981 14.3845 4.96167 13.9796 4.74309 13.6669C4.66795 13.5589 4.77269 13.5229 4.82278 13.4734C5.39655 12.9043 5.98626 12.3531 6.56686 11.793C7.62105 10.7806 8.64108 9.73681 9.68617 8.71324C11.0363 7.39271 12.3956 6.07894 13.7504 4.76292C13.8619 4.65494 13.9735 4.54246 14.0737 4.42323C14.1853 4.2905 14.2763 4.30175 14.4061 4.40748C14.8729 4.78767 15.351 5.15885 15.82 5.53679C15.8929 5.59528 15.9589 5.65377 16.0546 5.66052C16.1456 5.73025 16.0409 5.79999 16.0591 5.87873C16.3027 6.97204 16.535 8.0676 16.7672 9.16316C16.8697 9.64457 16.9699 10.1282 17.07 10.6119C17.1884 10.5894 17.2317 10.5129 17.2909 10.4589C17.9216 9.87853 18.55 9.29813 19.1784 8.71549L19.1761 8.71324Z"
        fill="#DD7F1C"
      />
      <Path
        d="M19.1756 8.71323C18.5472 9.29363 17.9188 9.87628 17.2881 10.4567C17.2289 10.5107 17.1856 10.5849 17.0672 10.6097C16.9671 10.126 16.8669 9.64232 16.7644 9.1609C16.5299 8.06534 16.2977 6.96978 16.0563 5.87647C16.0381 5.79774 16.1428 5.72575 16.0518 5.65826C16.4297 5.49404 16.6551 5.21509 16.7439 4.81016C16.7712 4.68193 16.8942 4.5852 16.9101 4.42548C16.958 3.95756 16.9716 3.48514 17.0946 3.02397C17.3928 1.90816 18.1009 1.16354 19.1597 0.713621C19.8997 0.398676 20.6715 0.191712 21.4593 0.0364888C21.6916 -0.010753 21.8896 0.0589849 22.0263 0.250202C22.1697 0.452666 22.1583 0.763112 21.9807 0.931833C21.58 1.31652 21.1633 1.68545 20.7512 2.05889C20.4529 2.32884 20.182 2.62579 19.8837 2.89799C19.779 2.99248 19.7858 3.24893 19.861 3.39515C19.9771 3.62686 19.9338 3.88557 20.0408 4.10828C20.1547 4.34449 20.116 4.66618 20.4302 4.78766C20.8514 4.94964 21.284 5.07786 21.7303 5.1431C21.9215 5.1701 22.0968 5.07111 22.2243 4.91814C22.5431 4.53346 22.962 4.25676 23.315 3.90807C23.5427 3.68535 23.7658 3.45814 24.0162 3.25793C24.2098 3.10271 24.5536 3.01497 24.7312 3.1117C24.9702 3.24218 25.0477 3.40865 24.9725 3.75284C24.7676 4.69093 24.4534 5.59527 23.9434 6.41413C23.4356 7.22849 22.682 7.70316 21.7212 7.86513C21.2977 7.93712 20.8719 7.92362 20.4507 7.97761C19.9315 8.0451 19.524 8.33755 19.1779 8.71773L19.1756 8.71323Z"
        fill="#010100"
      />
      <Path
        d="M1.58004 21.3604C1.15199 21.3267 0.610096 21.1692 0.118293 20.8565C-0.0183192 20.7688 -0.0479185 20.7193 0.0955241 20.5821C0.270843 20.4111 0.402901 20.1906 0.534959 19.9814C1.0427 19.1716 1.66656 18.5102 2.62057 18.1975C3.00764 18.0715 3.39243 18.0715 3.7795 18.0737C4.30317 18.0737 4.76082 18.3234 5.23441 18.5079C5.41429 18.5777 5.40745 18.7509 5.23669 18.8679C5.15472 18.9241 5.10236 19.0073 5.04543 19.0883C4.64243 19.6755 4.25991 20.2784 3.67476 20.7148C3.10099 21.1445 2.45436 21.3582 1.58232 21.3604H1.58004Z"
        fill="#010100"
      />
      <Path
        d="M6.83757 17.6622C6.94914 17.6644 6.95597 17.7634 6.99012 17.8309C7.32482 18.4473 7.65724 19.0659 7.98739 19.6846C8.04431 19.7903 8.08074 19.905 8.12628 20.0153C7.95096 20.2222 7.99194 20.5012 7.8781 20.7306C7.73693 21.0186 7.59121 21.3043 7.36353 21.5337C6.94686 21.9589 6.51653 22.3706 6.09987 22.7913C6.01107 22.8813 5.96325 22.8925 5.86535 22.798C5.65588 22.6001 5.52837 22.3526 5.37582 22.1164C5.08666 21.6687 5.05251 21.1626 5.04568 20.6631C5.04112 20.3167 5.05251 19.9208 5.21416 19.6328C5.41453 19.2751 5.58302 18.8769 5.91088 18.5845C6.23648 18.2943 6.53019 17.9704 6.83757 17.6599V17.6622Z"
        fill="#010100"
      />
      <Path
        d="M5.33914 14.9648C5.43249 15.0098 5.50763 15.0571 5.56 15.1606C5.9334 15.8714 6.31592 16.5778 6.69843 17.2842C6.74624 17.3719 6.81 17.4507 6.72575 17.5496C6.4548 17.5159 6.23395 17.6846 5.97666 17.7319C4.91792 17.9208 3.92065 17.5901 3.1875 16.841C3.81819 16.3124 4.29633 15.6217 5.01582 15.1831C5.12284 15.1178 5.20025 14.9986 5.33686 14.9671L5.33914 14.9648Z"
        fill="#010100"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_239_5371">
        <Rect
          width="25"
          height="23.9583"
          fill="white"
          transform="translate(0 0.020752)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);

const DarkIcon = (
  <Svg width="25" height="24" viewBox="0 0 25 24" fill="none">
    <G clipPath="url(#clip0_674_31415)">
      <Path
        d="M19.1761 8.71336C19.3424 9.14528 19.3879 9.60195 19.4835 10.0496C19.7454 11.2734 19.9708 12.5062 20.2577 13.7255C20.3123 13.9572 20.244 14.0652 20.137 14.2114C19.5883 14.9628 19.0555 15.7254 18.4863 16.461C18.3383 16.6522 18.3041 16.8277 18.3041 17.0482C18.3064 19.273 18.2996 21.4979 18.3041 23.7227C18.3041 23.9275 18.2495 23.9815 18.0423 23.9815C15.219 23.9747 12.3934 23.977 9.57004 23.977C9.14199 23.977 9.07369 23.9207 9.02587 23.4955C8.98034 23.1131 8.86194 22.7464 8.78225 22.3707C8.64108 21.6981 8.47943 21.03 8.33371 20.3596C8.30411 20.2179 8.19482 20.1346 8.12651 20.0199C8.08097 19.9097 8.04454 19.7927 7.98762 19.6892C7.65748 19.0706 7.32505 18.4519 6.99036 17.8355C6.95393 17.768 6.94709 17.6691 6.83781 17.6668C6.80138 17.6286 6.76495 17.5903 6.72852 17.5543C6.81276 17.4576 6.74901 17.3766 6.70119 17.2889C6.31868 16.5825 5.93617 15.8761 5.56276 15.1652C5.50811 15.064 5.43298 15.0145 5.3419 14.9695C5.37606 14.857 5.34418 14.7446 5.2736 14.6703C4.9981 14.3846 4.96167 13.9797 4.74309 13.667C4.66795 13.559 4.77269 13.523 4.82278 13.4735C5.39655 12.9044 5.98626 12.3532 6.56686 11.7931C7.62105 10.7807 8.64108 9.73693 9.68617 8.71336C11.0363 7.39284 12.3956 6.07907 13.7504 4.76304C13.8619 4.65506 13.9735 4.54258 14.0737 4.42335C14.1853 4.29063 14.2763 4.30187 14.4061 4.40761C14.8729 4.78779 15.351 5.15898 15.82 5.53691C15.8929 5.5954 15.9589 5.65389 16.0546 5.66064C16.1456 5.73038 16.0409 5.80011 16.0591 5.87885C16.3027 6.97216 16.535 8.06772 16.7672 9.16328C16.8697 9.6447 16.9699 10.1284 17.07 10.612C17.1884 10.5895 17.2317 10.513 17.2909 10.4591C17.9216 9.87866 18.55 9.29826 19.1784 8.71561L19.1761 8.71336Z"
        fill="#DD7F1C"
      />
      <Path
        d="M19.1756 8.71335C18.5472 9.29375 17.9188 9.8764 17.2881 10.4568C17.2289 10.5108 17.1856 10.585 17.0672 10.6098C16.9671 10.1261 16.8669 9.64244 16.7644 9.16103C16.5299 8.06547 16.2977 6.96991 16.0563 5.8766C16.0381 5.79786 16.1428 5.72587 16.0518 5.65838C16.4297 5.49416 16.6551 5.21521 16.7439 4.81028C16.7712 4.68205 16.8942 4.58532 16.9101 4.4256C16.958 3.95768 16.9716 3.48526 17.0946 3.02409C17.3928 1.90829 18.1009 1.16366 19.1597 0.713743C19.8997 0.398798 20.6715 0.191834 21.4593 0.0366109C21.6916 -0.0106309 21.8896 0.0591069 22.0263 0.250324C22.1697 0.452789 22.1583 0.763235 21.9807 0.931955C21.58 1.31664 21.1633 1.68557 20.7512 2.05901C20.4529 2.32896 20.182 2.62591 19.8837 2.89811C19.779 2.9926 19.7858 3.24905 19.861 3.39528C19.9771 3.62699 19.9338 3.88569 20.0408 4.1084C20.1547 4.34461 20.116 4.66631 20.4302 4.78779C20.8514 4.94976 21.284 5.07798 21.7303 5.14322C21.9215 5.17022 22.0968 5.07124 22.2243 4.91826C22.5431 4.53358 22.962 4.25688 23.315 3.90819C23.5427 3.68548 23.7658 3.45827 24.0162 3.25805C24.2098 3.10283 24.5536 3.01509 24.7312 3.11183C24.9702 3.2423 25.0477 3.40877 24.9725 3.75296C24.7676 4.69105 24.4534 5.59539 23.9434 6.41425C23.4356 7.22861 22.682 7.70328 21.7212 7.86525C21.2977 7.93724 20.8719 7.92374 20.4507 7.97773C19.9315 8.04522 19.524 8.33767 19.1779 8.71785L19.1756 8.71335Z"
        fill="white"
      />
      <Path
        d="M1.58004 21.3607C1.15199 21.3269 0.610096 21.1694 0.118293 20.8568C-0.0183192 20.769 -0.0479185 20.7195 0.0955241 20.5823C0.270843 20.4113 0.402901 20.1909 0.534959 19.9817C1.0427 19.1718 1.66656 18.5104 2.62057 18.1977C3.00764 18.0717 3.39243 18.0717 3.7795 18.074C4.30317 18.074 4.76082 18.3237 5.23441 18.5082C5.41429 18.5779 5.40745 18.7511 5.23669 18.8681C5.15472 18.9243 5.10236 19.0076 5.04543 19.0886C4.64243 19.6757 4.25991 20.2786 3.67476 20.715C3.10099 21.1447 2.45436 21.3584 1.58232 21.3607H1.58004Z"
        fill="white"
      />
      <Path
        d="M6.83757 17.6623C6.94914 17.6645 6.95597 17.7635 6.99012 17.831C7.32482 18.4474 7.65724 19.066 7.98739 19.6847C8.04431 19.7904 8.08074 19.9051 8.12628 20.0154C7.95096 20.2223 7.99194 20.5013 7.8781 20.7307C7.73693 21.0187 7.59121 21.3044 7.36353 21.5339C6.94686 21.959 6.51653 22.3707 6.09987 22.7914C6.01107 22.8814 5.96325 22.8926 5.86535 22.7981C5.65588 22.6002 5.52837 22.3527 5.37582 22.1165C5.08666 21.6688 5.05251 21.1627 5.04568 20.6633C5.04112 20.3168 5.05251 19.9209 5.21416 19.6329C5.41453 19.2753 5.58302 18.8771 5.91088 18.5846C6.23648 18.2944 6.53019 17.9705 6.83757 17.66V17.6623Z"
        fill="white"
      />
      <Path
        d="M5.33914 14.965C5.43249 15.01 5.50763 15.0572 5.56 15.1607C5.9334 15.8716 6.31592 16.5779 6.69843 17.2843C6.74624 17.372 6.81 17.4508 6.72575 17.5498C6.4548 17.516 6.23395 17.6847 5.97666 17.732C4.91792 17.921 3.92065 17.5903 3.1875 16.8411C3.81819 16.3125 4.29633 15.6219 5.01582 15.1832C5.12284 15.1179 5.20025 14.9987 5.33686 14.9672L5.33914 14.965Z"
        fill="white"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_674_31415">
        <Rect
          width="25"
          height="23.9583"
          fill="white"
          transform="translate(0 0.020874)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);

export const DesktopGrippLogoBlackWrench = () => (
  <Svg width="106" height="26" viewBox="0 0 106 26" fill="none">
    <Path
      d="M43.3429 17.1117H40.5326V13.6241H48.22V20.4415C46.0568 21.7026 43.5495 22.3331 40.6977 22.3331C37.6114 22.3331 35.2423 21.7026 33.589 20.4415C31.9358 19.1805 31.1094 17.3746 31.1094 15.0232C31.1094 13.5127 31.4573 12.1957 32.1531 11.0727C32.8486 9.94968 33.8716 9.08265 35.2219 8.47189C36.5717 7.86113 38.1838 7.55576 40.0575 7.55576C42.248 7.55576 44.0318 7.9764 45.4096 8.81679C46.7872 9.65748 47.6551 10.8398 48.0133 12.3634L42.6612 12.7969C42.4819 12.311 42.1858 11.9628 41.7726 11.7526C41.3593 11.5427 40.753 11.4374 39.9539 11.4374C38.7968 11.4374 37.9355 11.723 37.3709 12.2945C36.8057 12.866 36.5237 13.7364 36.5237 14.9052C36.5237 16.1662 36.8405 17.0725 37.4742 17.6243C38.1075 18.176 39.1616 18.4518 40.6359 18.4518C41.7239 18.4518 42.6266 18.3533 43.3429 18.1563V17.112V17.1117Z"
      fill="#F47A20"
    />
    <Path
      d="M60.5747 22.3331L57.8879 17.8213H55.4031V22.3331H50.5547V7.55576H58.2111C62.7495 7.55576 65.0191 9.24607 65.0191 12.6264C65.0191 13.6337 64.8273 14.4755 64.4433 15.1513C64.0596 15.8275 63.4432 16.3794 62.5948 16.8071L66.1098 22.3331H60.5747ZM57.9082 14.1167C58.7164 14.1167 59.2818 14.0063 59.605 13.7856C59.9282 13.5651 60.0897 13.1925 60.0897 12.6679C60.0897 12.1434 59.9282 11.7955 59.605 11.5814C59.2818 11.3675 58.7293 11.2606 57.9484 11.2606H55.4031V14.1167H57.9082Z"
      fill="#F47A20"
    />
    <Path
      d="M67.6641 22.3331V7.55576H73.1083V22.3331H67.6641Z"
      fill="#F47A20"
    />
    <Path
      d="M75.4437 22.3331V7.55576H83.0863C85.4854 7.55576 87.2747 7.99382 88.453 8.86996C89.6313 9.74641 90.2207 11.0741 90.2207 12.8541C90.2207 14.634 89.6137 15.9998 88.3999 16.8693C87.1859 17.7386 85.3301 18.1732 82.8319 18.1732H80.6936V22.3331H75.4434H75.4437ZM82.6842 14.3029C83.5026 14.3029 84.0816 14.1925 84.4203 13.9717C84.7591 13.7513 84.9283 13.3786 84.9283 12.8541C84.9283 12.3295 84.7552 11.9779 84.4097 11.7572C84.0636 11.5367 83.5026 11.426 82.7267 11.426H80.6943V14.3029H82.6842Z"
      fill="#F47A20"
    />
    <Path
      d="M91.7757 22.3331V7.55576H99.0161C101.289 7.55576 102.984 7.99382 104.1 8.86996C105.217 9.74641 105.775 11.0741 105.775 12.8541C105.775 14.634 105.2 15.9998 104.05 16.8693C102.9 17.7386 101.142 18.1732 98.775 18.1732H96.7493V22.3331H91.7754H91.7757ZM98.6351 14.3029C99.4104 14.3029 99.959 14.1925 100.28 13.9717C100.601 13.7513 100.761 13.3786 100.761 12.8541C100.761 12.3295 100.597 11.9779 100.27 11.7572C99.9419 11.5367 99.4104 11.426 98.6754 11.426H96.7499V14.3029H98.6351Z"
      fill="#F47A20"
    />
    <Path
      d="M0.996591 20.449L0 21.8656C1.40672 22.8687 3.36268 22.5363 4.35927 21.1197L5.35586 19.7036C3.94914 18.7004 1.99318 19.0329 0.996591 20.4494V20.449Z"
      fill="white"
    />
    <Path
      d="M5.43136 15.9748C5.05291 16.1303 4.69078 16.3611 4.38677 16.6723L3.1665 17.9007C4.14187 18.8826 5.59137 19.0756 6.76364 18.4963L5.43659 15.9804L5.43136 15.9751V15.9748Z"
      fill="white"
    />
    <Path
      d="M6.87796 18.7324L5.96659 19.6497C4.74632 20.8781 4.74632 22.8739 5.96659 24.1023L7.18686 22.8739C7.70377 22.3534 8.00222 21.6938 8.07667 21.0179L6.87241 18.738L6.87763 18.7328L6.87796 18.7324Z"
      fill="white"
    />
    <Path
      d="M18.2632 25.1538L18.274 17.7666L20.2564 14.9878L19.1641 9.59631L17.0429 11.5973L16.0143 6.56506L14.2132 5.11132L4.70081 14.6015L8.29794 21.4199L9.1613 25.1538H18.2632Z"
      fill="#F47A20"
    />
    <Path
      d="M23.9749 4.06005L22.0353 5.90009C21.8861 6.04507 21.6728 6.09307 21.4756 6.03952L20.4098 5.73388C20.2021 5.67478 20.0473 5.50857 19.9993 5.29926L19.7489 4.19426C19.7009 3.9905 19.7649 3.77597 19.9193 3.63098L21.8485 1.79617C22.2374 1.43143 21.9285 0.776724 21.4008 0.852154C20.5962 0.959585 19.6797 1.28677 18.9495 1.59796C17.9957 2.00026 17.2816 2.83195 17.0259 3.84029C16.9299 4.21581 16.8607 4.61811 16.8499 5.0044C16.8339 5.5249 16.6367 6.02385 16.2582 6.38304L16.0237 6.60835L17.0468 11.5923L18.9972 9.75225L19.3224 9.43583C19.6793 9.08709 20.159 8.91533 20.6547 8.91533C21.0011 8.91533 21.3636 8.87256 21.7045 8.8079C22.7223 8.61492 23.5805 7.93344 24.0226 6.99464C24.3635 6.27038 24.7367 5.36392 24.8807 4.559C24.9767 4.03328 24.353 3.69009 23.9694 4.05483L23.9746 4.06005H23.9749Z"
      fill="white"
    />
  </Svg>
);

export const GrippLogoBlackWrench = ({
  width = 171,
  height = 36,
}: {
  width?: number;
  height?: number;
}) => (
  <Svg width={width} height={height} viewBox="0 0 171 36" fill="none">
    <Path
      d="M62.7349 25.0975H57.8839V19.0773H71.1535V30.8452C67.4195 33.022 63.0915 34.1103 58.169 34.1103C52.8415 34.1103 48.752 33.022 45.8983 30.8452C43.0446 28.6685 41.618 25.5512 41.618 21.4923C41.618 18.8851 42.2186 16.6117 43.4197 14.6732C44.6203 12.7348 46.3861 11.2381 48.7168 10.1839C51.0469 9.12961 53.8296 8.60248 57.0638 8.60248C60.8449 8.60248 63.9241 9.32857 66.3024 10.7792C68.6802 12.2304 70.1784 14.2712 70.7968 16.9011L61.5582 17.6495C61.2487 16.8107 60.7376 16.2097 60.0243 15.8469C59.311 15.4846 58.2644 15.3027 56.8849 15.3027C54.8876 15.3027 53.4009 15.7957 52.4263 16.7823C51.4507 17.7688 50.964 19.2711 50.964 21.2887C50.964 23.4654 51.5109 25.0298 52.6047 25.9822C53.6979 26.9347 55.5174 27.4106 58.0622 27.4106C59.9403 27.4106 61.4986 27.2406 62.7349 26.9006V25.098V25.0975Z"
      fill="#F47A20"
    />
    <Path
      d="M92.4772 34.1103L87.8394 26.3223H83.5502V34.1103H75.1812V8.60248H88.3973C96.2312 8.60248 100.149 11.5202 100.149 17.3552C100.149 19.0939 99.8178 20.547 99.1549 21.7136C98.4926 22.8807 97.4287 23.8334 95.9642 24.5716L102.032 34.1103H92.4772ZM87.8743 19.9277C89.2695 19.9277 90.2454 19.7371 90.8034 19.3561C91.3613 18.9755 91.64 18.3323 91.64 17.4268C91.64 16.5214 91.3613 15.921 90.8034 15.5513C90.2454 15.1822 89.2917 14.9976 87.9438 14.9976H83.5502V19.9277H87.8743Z"
      fill="#F47A20"
    />
    <Path
      d="M104.716 34.1103V8.60248H114.114V34.1103H104.716Z"
      fill="#F47A20"
    />
    <Path
      d="M118.142 34.1103V8.60248H131.334C135.476 8.60248 138.564 9.35865 140.598 10.871C142.632 12.3839 143.649 14.6757 143.649 17.7482C143.649 20.8206 142.602 23.1782 140.507 24.6791C138.411 26.1795 135.207 26.9297 130.895 26.9297H127.204V34.1103H118.142H118.142ZM130.64 20.249C132.053 20.249 133.052 20.0585 133.637 19.6774C134.222 19.2969 134.514 18.6536 134.514 17.7482C134.514 16.8427 134.215 16.2358 133.619 15.8548C133.021 15.4742 132.053 15.2832 130.714 15.2832H127.205V20.249H130.64Z"
      fill="#F47A20"
    />
    <Path
      d="M146.335 34.1103V8.60248H158.833C162.756 8.60248 165.682 9.35865 167.609 10.871C169.536 12.3839 170.5 14.6757 170.5 17.7482C170.5 20.8206 169.507 23.1782 167.522 24.6791C165.537 26.1795 162.502 26.9297 158.417 26.9297H154.92V34.1103H146.335H146.335ZM158.175 20.249C159.514 20.249 160.461 20.0585 161.015 19.6774C161.569 19.2969 161.845 18.6536 161.845 17.7482C161.845 16.8427 161.562 16.2358 160.997 15.8548C160.431 15.4742 159.514 15.2832 158.245 15.2832H154.921V20.249H158.175Z"
      fill="#F47A20"
    />
    <Path
      d="M1.47595 29.0318L0 31.1296C2.08336 32.6153 4.98013 32.123 6.45609 30.0251L7.93204 27.9277C5.84868 26.4421 2.95191 26.9344 1.47595 29.0323V29.0318Z"
      fill="black"
    />
    <Path
      d="M8.04274 22.4059C7.48225 22.6361 6.94593 22.978 6.4957 23.4389L4.68848 25.2582C6.133 26.7124 8.27971 26.9982 10.0158 26.1403L8.05048 22.4142L8.04274 22.4064V22.4059Z"
      fill="black"
    />
    <Path
      d="M10.1885 26.4895L8.83881 27.8479C7.03159 29.6672 7.03159 32.623 8.83881 34.4423L10.646 32.623C11.4116 31.8521 11.8536 30.8753 11.9639 29.8742L10.1803 26.4977L10.1881 26.49L10.1885 26.4895Z"
      fill="black"
    />
    <Path
      d="M27.0464 36L27.0623 25.0595L29.9983 20.944L28.3806 12.9593L25.2392 15.9228L23.7158 8.46998L21.0483 6.31699L6.96045 20.3719L12.2878 30.47L13.5665 36H27.0464Z"
      fill="#F47A20"
    />
    <Path
      d="M35.5072 4.75978L32.6346 7.48488C32.4136 7.6996 32.0978 7.77069 31.8057 7.69138L30.2272 7.23873C29.9197 7.1512 29.6905 6.90504 29.6194 6.59505L29.2484 4.95854C29.1773 4.65678 29.2721 4.33905 29.5009 4.12433L32.358 1.40697C32.934 0.866786 32.4765 -0.102836 31.695 0.00887607C30.5034 0.167981 29.1459 0.652551 28.0646 1.11342C26.652 1.70922 25.5943 2.94096 25.2157 4.43432C25.0735 4.99046 24.971 5.58626 24.955 6.15836C24.9313 6.92922 24.6392 7.66817 24.0787 8.20013L23.7315 8.53381L25.2466 15.915L28.1352 13.1899L28.6169 12.7213C29.1454 12.2048 29.8558 11.9505 30.59 11.9505C31.1031 11.9505 31.6399 11.8871 32.1447 11.7914C33.6521 11.5056 34.923 10.4963 35.5778 9.10592C36.0827 8.03329 36.6355 6.69081 36.8487 5.49873C36.9909 4.72013 36.0672 4.21186 35.499 4.75205L35.5067 4.75978H35.5072Z"
      fill="black"
    />
  </Svg>
);

export const GrippLogoWhiteWrench = () => (
  <Svg width="231" height="36" viewBox="0 0 231 36" fill="none">
    <Path
      d="M62.735 25.0974H57.884V19.0773H71.1536V30.8452C67.4196 33.0219 63.0917 34.1103 58.1691 34.1103C52.8416 34.1103 48.7522 33.0219 45.8984 30.8452C43.0447 28.6684 41.6182 25.5511 41.6182 21.4923C41.6182 18.885 42.2187 16.6117 43.4198 14.6732C44.6204 12.7347 46.3863 11.2381 48.7169 10.1838C51.047 9.12955 53.8297 8.60242 57.0639 8.60242C60.845 8.60242 63.9242 9.32851 66.3026 10.7791C68.6804 12.2303 70.1785 14.2711 70.7969 16.9011L61.5583 17.6494C61.2488 16.8106 60.7377 16.2096 60.0244 15.8468C59.3111 15.4845 58.2645 15.3026 56.8851 15.3026C54.8877 15.3026 53.401 15.7957 52.4265 16.7822C51.4508 17.7688 50.9641 19.2711 50.9641 21.2886C50.9641 23.4654 51.511 25.0297 52.6048 25.9822C53.698 26.9346 55.5176 27.4106 58.0623 27.4106C59.9404 27.4106 61.4987 27.2405 62.735 26.9005V25.0979V25.0974Z"
      fill="#F47A20"
    />
    <Path
      d="M92.4767 34.1103L87.8389 26.3222H83.5497V34.1103H75.1807V8.60242H88.3968C96.2307 8.60242 100.148 11.5202 100.148 17.3551C100.148 19.0938 99.8173 20.547 99.1545 21.7135C98.4921 22.8806 97.4282 23.8333 95.9637 24.5716L102.031 34.1103H92.4767ZM87.8739 19.9276C89.269 19.9276 90.245 19.7371 90.8029 19.356C91.3608 18.9755 91.6395 18.3322 91.6395 17.4268C91.6395 16.5213 91.3608 15.9209 90.8029 15.5513C90.245 15.1821 89.2912 14.9976 87.9433 14.9976H83.5497V19.9276H87.8739Z"
      fill="#F47A20"
    />
    <Path
      d="M104.717 34.1103V8.60242H114.114V34.1103H104.717Z"
      fill="#F47A20"
    />
    <Path
      d="M118.142 34.1103V8.60242H131.334C135.476 8.60242 138.564 9.35859 140.598 10.8709C142.632 12.3838 143.649 14.6757 143.649 17.7481C143.649 20.8206 142.602 23.1781 140.507 24.679C138.411 26.1794 135.208 26.9296 130.895 26.9296H127.204V34.1103H118.142H118.142ZM130.64 20.249C132.053 20.249 133.053 20.0584 133.637 19.6774C134.222 19.2968 134.514 18.6536 134.514 17.7481C134.514 16.8427 134.215 16.2358 133.619 15.8547C133.021 15.4742 132.053 15.2831 130.714 15.2831H127.205V20.249H130.64Z"
      fill="#F47A20"
    />
    <Path
      d="M146.335 34.1103V8.60242H158.833C162.757 8.60242 165.683 9.35859 167.61 10.8709C169.537 12.3838 170.5 14.6757 170.5 17.7481C170.5 20.8206 169.508 23.1781 167.523 24.679C165.538 26.1794 162.503 26.9296 158.417 26.9296H154.921V34.1103H146.335H146.335ZM158.176 20.249C159.514 20.249 160.461 20.0584 161.015 19.6774C161.569 19.2968 161.846 18.6536 161.846 17.7481C161.846 16.8427 161.563 16.2358 160.998 15.8547C160.432 15.4742 159.514 15.2831 158.245 15.2831H154.922V20.249H158.176Z"
      fill="#F47A20"
    />
    <Path
      d="M1.47595 29.0318L0 31.1296C2.08336 32.6152 4.98013 32.1229 6.45609 30.0251L7.93204 27.9277C5.84868 26.4421 2.95191 26.9344 1.47595 29.0322V29.0318Z"
      fill="white"
    />
    <Path
      d="M8.04274 22.4059C7.48225 22.6361 6.94593 22.978 6.4957 23.4389L4.68848 25.2582C6.133 26.7124 8.27971 26.9982 10.0158 26.1403L8.05048 22.4141L8.04274 22.4064V22.4059Z"
      fill="white"
    />
    <Path
      d="M10.1885 26.4895L8.83881 27.8479C7.03159 29.6672 7.03159 32.623 8.83881 34.4423L10.646 32.623C11.4116 31.8522 11.8536 30.8753 11.9639 29.8742L10.1803 26.4977L10.1881 26.49L10.1885 26.4895Z"
      fill="white"
    />
    <Path
      d="M27.0459 36L27.0619 25.0595L29.9978 20.944L28.3802 12.9593L25.2387 15.9228L23.7153 8.47001L21.0478 6.31702L6.95996 20.3719L12.2873 30.47L13.566 36H27.0459Z"
      fill="#F47A20"
    />
    <Path
      d="M35.5072 4.75978L32.6346 7.48488C32.4136 7.6996 32.0978 7.77069 31.8057 7.69138L30.2272 7.23873C29.9196 7.1512 29.6904 6.90504 29.6193 6.59505L29.2484 4.95854C29.1773 4.65678 29.2721 4.33905 29.5008 4.12433L32.3579 1.40697C32.9339 0.866786 32.4764 -0.102836 31.6949 0.00887607C30.5033 0.167981 29.1459 0.652551 28.0645 1.11342C26.6519 1.70922 25.5943 2.94096 25.2156 4.43432C25.0734 4.99046 24.9709 5.58626 24.955 6.15836C24.9313 6.92922 24.6392 7.66817 24.0787 8.20013L23.7314 8.53381L25.2466 15.915L28.1351 13.1899L28.6168 12.7213C29.1454 12.2048 29.8558 11.9505 30.5899 11.9505C31.103 11.9505 31.6398 11.8871 32.1447 11.7914C33.6521 11.5056 34.923 10.4963 35.5778 9.10592C36.0826 8.03329 36.6354 6.69081 36.8487 5.49873C36.9908 4.72013 36.0672 4.21186 35.4989 4.75205L35.5067 4.75978H35.5072Z"
      fill="white"
    />
  </Svg>
);
