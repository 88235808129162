import { useFindByQuery } from '../../graphql';

export const useFeatures = () => {
  const { data, isLoading } = useFindByQuery({
    modelName: 'feature',
    query: GET_FEATURES,
  });

  return { data, isLoading };
};

export const GET_FEATURES = `
  query findFeatures {
    findFeatures {
      items {
        id
        name
        enabled
        description
      }
    }
  }
`;
