/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LIST_ASSETS,
  PermissionValues,
  useAssetListFilter,
  useAssetListOrder,
  useCurrentAccount,
  useCurrentWorkspace,
  useSetAssetListOrder,
} from '@gripp/shared-logic';
import { ColumnDef } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteDataGrid } from '../infiniteDataGrid';
import { useColumns } from './useColumns';

export type AssetListProps = {
  onAssetClick: any;
  maxHeight?: number;
  paddingBottom?: number;
  onAssetsCountChange?: (count: number) => void;
  updateRowsSelected?: (rows: Array<any>) => void;
  preserveScrollKey?: string;
  addToGroupsComponent?: JSX.Element;
};

export const AssetsList = ({
  onAssetClick,
  maxHeight,
  paddingBottom,
  onAssetsCountChange,
  updateRowsSelected,
  preserveScrollKey,
  addToGroupsComponent,
}: AssetListProps) => {
  const { t } = useTranslation();

  const { data } = useAssetListFilter();
  const { data: orderData } = useAssetListOrder();
  const { mutateAsync: setOrder } = useSetAssetListOrder();
  const workspace = useCurrentWorkspace(true);
  const { account } = useCurrentAccount();
  const showImpersonateWorkspace = useMemo(
    () => !!(account && account.hasPermission(PermissionValues.globalAdmin)),
    [account]
  );

  const setOrderData = useCallback(
    (order: any) => {
      setOrder(order);
    },
    [setOrder]
  );

  const [filter, setFilter] = useState<any>(undefined);
  const columns = useColumns(showImpersonateWorkspace, filter) as ColumnDef<
    any,
    any
  >[];

  useEffect(() => {
    if (!data || workspace === undefined) return;

    const f = { ...data };
    f.workspaceId = workspace?.id;
    if (f.keyword === '') delete f.keyword;
    if (f.groups?.length === 0) delete f.groups;
    setFilter(f);
  }, [data, workspace]);

  return !filter ? null : (
    <InfiniteDataGrid
      modelName="asset"
      query={LIST_ASSETS}
      orderBy={orderData}
      filter={filter}
      pageSize={50}
      columns={columns}
      onRowClick={onAssetClick}
      listMaxHeight={maxHeight as number}
      paddingBottom={paddingBottom as number}
      textEmpty={t('assets.textEmpty')}
      onItemsCountChange={onAssetsCountChange}
      dataPrefix="asset.listAssets"
      onOrderByChange={setOrderData}
      updateRowsSelected={workspace ? updateRowsSelected : undefined}
      enableScrollRefresh={true}
      preserveScrollKey={preserveScrollKey}
      showScrollIndicator={true}
      headerContent={addToGroupsComponent}
      exportFileName="assets"
    />
  );
};
