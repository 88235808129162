import { Group } from '@gripp/shared-logic';
import { StyleProp, StyleSheet } from 'react-native';
import { Chip } from 'react-native-paper';
import { Colors } from '../../themes';

type GroupChipProps = {
  group: Group;
  onPress: (groupId: string) => Promise<void>;
  additionalStyle?: StyleProp<any>;
};

export const GroupChip = (props: GroupChipProps) => {
  const onPress = async (groupId: string) => {
    await props.onPress(groupId);
  };

  const chipStyle = props.additionalStyle
    ? [styles.chip, props.additionalStyle]
    : [styles.chip];

  return (
    <Chip
      onPress={async () => await onPress(props.group.id)}
      textStyle={styles.chipText}
      style={chipStyle}
      ellipsizeMode="tail"
    >
      {props.group.name}
    </Chip>
  );
};

const styles = StyleSheet.create({
  chip: {
    backgroundColor: Colors.primaryGrayBackground,
  },
  chipText: {
    lineHeight: 14,
    fontWeight: '400',
    color: Colors.black,
    paddingVertical: 1,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 5,
    marginBottom: 5,
  },
});

export const GroupChipTextStyle = styles.chipText;
export const GroupChipStyle = styles.chip;
