import { StatusQuestionConfig } from '@gripp/shared-logic';
import { StatusAdminComponent } from '../../../status/statusAdminForm';
import {
  RoutineQuestionAdminForm,
  RoutineQuestionAdminFormProps,
} from '../types';

export const StatusQuestionAdminForm: RoutineQuestionAdminForm<
  StatusQuestionConfig
> = (props: RoutineQuestionAdminFormProps<StatusQuestionConfig>) => {
  return (
    <StatusAdminComponent
      config={props.config}
      control={props.control}
      indexPrefix={props.indexPrefix}
      showResponseExceptions={true}
    />
  );
};
