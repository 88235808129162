import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Fragment, memo, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Spacing } from '../../styles';
import { Colors } from '../../themes/colors';

type AccordionProps = {
  title: string;
  items: React.ReactNode[];
  isExpanded?: boolean;
};

export const Accordion = memo((props: AccordionProps) => {
  const [expanded, setExpanded] = useState(props.isExpanded || false);

  const handlePress = () => setExpanded(!expanded);

  return (
    <View>
      <Pressable onPress={handlePress} style={styles.header}>
        <Text style={styles.title}>{props.title}</Text>
        <MaterialCommunityIcons
          name={expanded ? 'chevron-up' : 'chevron-down'}
          size={24}
          style={styles.icon}
        />
      </Pressable>
      <View style={[{ height: expanded ? 'auto' : 0 }, styles.item]}>
        {props.items.map((item, index) => (
          <Fragment key={index}>{item}</Fragment>
        ))}
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  item: {
    marginTop: Spacing.baseMargin.base,
    overflow: 'hidden',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: Colors.primaryDark,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19,
    marginRight: 12,
  },
  icon: {
    color: Colors.primaryDark,
  },
});
