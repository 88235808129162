import { DateTime } from 'luxon';
import moment from 'moment';

export const formatShortDate = (value) => {
  return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
};

export const formatShortDateAndTime = (value) => {
  const date = DateTime.fromISO(value);
  return getDateAndTime(date);
};

export const formatShortDateTimeOrSameDay = (value) => {
  const dateTime = DateTime.fromISO(value);

  return isSameDay(dateTime)
    ? dateTime.toLocaleString(DateTime.TIME_SIMPLE)
    : dateTime.toLocaleString(DateTime.DATETIME_SHORT);
};

export const formatShortDateOrSameDay = (value) => {
  const dateTime = DateTime.fromISO(value);

  return isSameDay(dateTime)
    ? dateTime.toLocaleString(DateTime.TIME_SIMPLE)
    : formatShortDate(dateTime);
};

export const formatShortDateAndTimeOrSameDay = (value) => {
  const dateTime = DateTime.fromISO(value);

  return isSameDay(dateTime)
    ? dateTime.toLocaleString(DateTime.TIME_SIMPLE)
    : formatShortDateAndTime(dateTime);
};

const getDateAndTime = (dateTime) => {
  return `${dateTime.toLocaleString(
    DateTime.DATE_SHORT
  )}  ${dateTime.toLocaleString(DateTime.TIME_SIMPLE)}`;
};

const isSameDay = (dateTime) => {
  const today = DateTime.now();
  return dateTime.hasSame(today, 'day');
};

export const DATE_FORMAT_MMDDYYYY = 'MM/DD/YYYY';
export const DATE_FORMAT_MMDDYYYYLUXON = 'MM/dd/yyyy';

export const getYearBounds = () => {
  const firstDay = moment(`2023-01-01`).startOf('day').toDate();
  const lastDay = moment(`${new Date().getFullYear()}-12-31`)
    .endOf('day')
    .toDate();

  return { firstDay, lastDay };
};
