export type AuthConfig = {
  scheme: string;
  accessToken?: string;
};

export type ConfigType = {
  apiUrl: string;
  graphqlUrl: string;
  subscriptionsUrl: string;
  mediaUrl: string;
  auth: AuthConfig;
  prefix: string;
  version: string;
  sentryDsn: string;
  sentryEnv: string;
};

const EnvConfig: ConfigType = {
  apiUrl: process.env.EXPO_PUBLIC_API_URL || '',
  graphqlUrl: `${process.env.EXPO_PUBLIC_API_URL}/graphql`,
  subscriptionsUrl: `${process.env.EXPO_PUBLIC_API_WS_URL}/graphql`,
  mediaUrl: process.env.EXPO_PUBLIC_MEDIA_URL || '',
  prefix: process.env.EXPO_PUBLIC_PREFIX || '',
  auth: {
    scheme: 'gripp',
    accessToken: process.env.EXPO_PUBLIC_TEST_ACCESS_TOKEN,
  },
  version: process.env.EXPO_PUBLIC_VERSION || '...',
  sentryDsn: process.env.EXPO_PUBLIC_SENTRY_DSN || '',
  sentryEnv: process.env.EXPO_PUBLIC_SENTRY_ENV || '',
};

export { EnvConfig };
