import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import React, { useEffect, useRef, useState } from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { RadioButton } from 'react-native-paper';
import { Colors } from '../../themes';

interface RadioSelectProps {
  label: string;
  items: { label: string; value: string }[];
  selectedValue: string | null;
  onValueChange: (value: string) => void;
}

export const RadioSelect: React.FC<RadioSelectProps> = ({
  label,
  items,
  selectedValue,
  onValueChange,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<View>(null);

  const handleSelectItem = (value: string) => {
    onValueChange(value);
    setDropdownVisible(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownVisible]);

  return (
    <TouchableWithoutFeedback onPress={() => setDropdownVisible(false)}>
      <View style={styles.container}>
        <TouchableOpacity
          onPress={() => setDropdownVisible(!dropdownVisible)}
          style={styles.selectBox}
        >
          <View style={styles.selectedContainer}>
            <Text style={styles.selectedValue}>
              {selectedValue
                ? items.find((item) => item.value === selectedValue)?.label
                : label}
            </Text>
            {dropdownVisible && (
              <Icon style={styles.selectIcon} name="chevron-up" />
            )}
            {!dropdownVisible && (
              <Icon style={styles.selectIcon} name="chevron-down" />
            )}
          </View>
        </TouchableOpacity>

        {dropdownVisible && (
          <View style={styles.dropdown} ref={dropdownRef}>
            {items.map((item) => (
              <TouchableOpacity
                key={item.value}
                onPress={() => handleSelectItem(item.value)}
                style={styles.radioItem}
              >
                <RadioButton
                  value={item.value}
                  status={
                    selectedValue === item.value ? 'checked' : 'unchecked'
                  }
                  onPress={() => handleSelectItem(item.value)}
                />
                <Text>{item.label}</Text>
              </TouchableOpacity>
            ))}
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    position: 'relative',
  },
  selectedValue: {
    color: Colors.primary,
  },
  selectIcon: {
    color: Colors.primary,
    paddingRight: 10,
    paddingTop: 0,
    fontSize: 22,
  },
  selectedContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectBox: {
    borderWidth: 1,
    borderColor: Colors.primary,
    paddingLeft: 10,
    paddingTop: 5,
    borderRadius: 5,
    height: 32,
    backgroundColor: Colors.white,
  },
  dropdown: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    borderWidth: 1,
    borderColor: '#ccc',
    backgroundColor: 'white',
    borderRadius: 5,
    marginTop: 5,
    zIndex: 1,
  },
  radioItem: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
});
