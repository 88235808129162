import { CounterBaseConfig, CounterResponse } from '../../counter';
import { WorkflowQuestionConfig } from '../workflowQuestionConfig';
import { WorkflowResponseState } from '../workflowResponseState';

export interface CounterQuestionConfig
  extends WorkflowQuestionConfig,
    CounterBaseConfig {}

export interface CounterResponseState
  extends WorkflowResponseState,
    CounterResponse {}
