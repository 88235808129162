import { StyleSheet, View } from 'react-native';
import { Button, Modal, Portal, Text } from 'react-native-paper';
import { Colors } from '../themes';

type CustomAlertProps = {
  visible: boolean;
  hideAlert: () => void;
  title: string;
  primaryButtonLabel?: string; // red button
  secondaryButtonLabel: string; // white button
  action?: () => void;
};

export const CustomAlert = (props: CustomAlertProps) => {
  const handlePrimaryAlertAction = () => {
    props.action && props.action();
    props.hideAlert();
  };

  return (
    <Portal>
      <Modal
        visible={props.visible}
        onDismiss={props.hideAlert}
        dismissable={false}
        contentContainerStyle={styles.containerStyle}
      >
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{props.title}</Text>
        </View>
        {props.action && (
          <Button
            style={[styles.button, styles.primaryButton]}
            textColor={Colors.white}
            labelStyle={styles.buttonLabel}
            onPress={handlePrimaryAlertAction}
          >
            {props.primaryButtonLabel}
          </Button>
        )}
        <Button
          style={[styles.button, styles.secondaryButton]}
          textColor={Colors.black}
          labelStyle={styles.buttonLabel}
          onPress={props.hideAlert}
        >
          {props.secondaryButtonLabel && props.secondaryButtonLabel}
        </Button>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  containerStyle: {
    alignSelf: 'center',
    backgroundColor: Colors.white,
    paddingHorizontal: 20,
    paddingVertical: 24,
    marginHorizontal: 20,
    borderRadius: 12,
    minWidth: 350,
    justifyContent: 'flex-start',
  },
  titleContainer: {
    marginBottom: 24,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: '400',
  },
  button: {
    borderWidth: 1,
    borderRadius: 8,
    height: 44,
    justifyContent: 'center',
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '600',
    textAlignVertical: 'center',
  },
  primaryButton: {
    marginBottom: 12,
    backgroundColor: Colors.red,
    color: Colors.white,
  },
  secondaryButton: {
    borderColor: Colors.grayDisabled,
  },
});

export default CustomAlert;
