import { Name } from '../auth';

export const getFullName = (name: Name): string => {
  if (!name) return '';

  const { first, last } = name;
  let fullName = first;
  if (last) fullName += ` ${last}`;

  return fullName || '';
};
