import { StyleSheet, View } from 'react-native';
import { StepBackButton } from './stepBackButton';
import { StepPrimaryButton } from './stepPrimaryButton';

type Props = {
  label: string;
  onPress: () => void;
  onBack?: () => void;
};

export const StepButtons = ({ label, onPress, onBack }: Props) => {
  return (
    <View style={styles.container}>
      <StepPrimaryButton onPress={onPress} label={label} />
      {onBack && <StepBackButton onPress={onBack} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 100,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});
