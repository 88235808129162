/* 
Storage Key Constants

  This mainly exists for the Expo GO app.
    When switching between Dev / & Production builds, we do not want stored data to overlap
*/

export interface StorageKeys {
  redirectPath: string;
  redirectUrl: string;
  authCodeInput: string;
  accessToken: string;
  workspace: string;
  accountFilter: string;
  accountTypeFilter: string;
  accountOrderBy: string;
  activityFilter: string;
  assetActivityFilter: string;
  assetFilter: string;
  email: string;
  assetOrderBy: string;
  groupFilter: string;
  groupOrderBy: string;
  location: string;
  assetsScrollData: string;
}

export type StorageKeysHook = () => StorageKeys;

export const useStorageKeys = (): StorageKeys => {
  if (!process.env.EXPO_PUBLIC_PREFIX) {
    throw new Error(
      'EXPO_PUBLIC_PREFIX must be set in the environment variables.'
    );
  }
  const prefix = process.env.EXPO_PUBLIC_PREFIX;

  return {
    redirectPath: `${prefix}_redirectPath`,
    redirectUrl: `${prefix}_redirectUrl`,
    accessToken: `${prefix}_accessToken`,
    authCodeInput: `${prefix}_authCodeInput`,
    workspace: `${prefix}_workspace`,
    accountFilter: `${prefix}_accountFilter`,
    accountTypeFilter: `${prefix}_accountTypeFilter`,
    accountOrderBy: `${prefix}_accountOrderBy`,
    activityFilter: `${prefix}_activityFilter`,
    assetActivityFilter: `${prefix}_assetActivityFilter`,
    assetFilter: `${prefix}_assetFilter`,
    assetOrderBy: `${prefix}_assetOrderBy`,
    groupFilter: `${prefix}_groupFilter`,
    groupOrderBy: `${prefix}_groupOrderBy`,
    email: `${prefix}_email`,
    location: `${prefix}_location`,
    assetsScrollData: `${prefix}_assetsScrollData`,
  };
};
