import { StyleSheet } from 'react-native';

export const messageStyles = StyleSheet.create({
  messageText: {
    flexWrap: 'wrap',
    flexShrink: 1,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19,
  },
});
