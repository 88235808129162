import { useCustomMutation } from '@gripp/shared-logic';
import { Colors, Spacing } from '@gripp/shared-ui';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text } from 'react-native';

export type ClearGroupsProps = {
  accountIds: string[];
  onSubmit: () => Promise<void>;
};

export const ClearGroups = (props: ClearGroupsProps) => {
  const { t } = useTranslation();
  const { mutateAsync } = useCustomMutation({
    query: CLEAR_GROUPS,
  });

  const onPress = async () => {
    await mutateAsync(
      {
        input: {
          accountIds: props.accountIds,
        },
      },
      {
        onSettled: async (data, error) => {
          await props.onSubmit();
        },
      }
    );
  };

  return (
    props.accountIds.length > 0 && (
      <Pressable style={styles.clearButton} onPress={onPress}>
        <Text style={styles.buttonLabel}>
          {t('groups.groupsSelector.clearGroups')}
        </Text>
      </Pressable>
    )
  );
};

const CLEAR_GROUPS = `
  mutation ClearGroups($input: ClearGroupsInput!) {
    account {
      clearGroups(input: $input) {
        success
      }
    }
  }
`;

const styles = StyleSheet.create({
  clearButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: Spacing.basePadding.paddingHorizontal,
    borderRadius: 12,
    height: 32,
    borderWidth: 2,
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    borderColor: Colors.primaryDark,
    zIndex: -1,
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.primaryDark,
  },
});
