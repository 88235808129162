import { useGrippContext, useStorageKeys } from '@gripp/shared-logic';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuLink, NavLinkProps } from '../../dashboard/components/menuLink';

export const AssetMenuItem = (props: NavLinkProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = location.pathname === props.route;
  const storageKeys = useStorageKeys();
  const { storageHandler } = useGrippContext();

  const onClick = async () => {
    await storageHandler.deleteItem(storageKeys.assetsScrollData);
    navigate(props.route);
  };

  return (
    <MenuLink
      icon={props.icon}
      title={t(props.titleKey)}
      isActive={isActive}
      onPress={onClick}
    />
  );
};
