import { useQueryClient } from '@tanstack/react-query';
import { cacheKeys, useCustomMutation } from '../../graphql';

type UpdateAssetProps = {
  assetId: string;
};

export const useUpdateAsset = (props: UpdateAssetProps) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateAsset, isPending: isLoading } = useCustomMutation({
    query: UPDATE_ASSET,
  });
  const filter = {
    asset: { eq: props.assetId },
  };

  const cacheKey = cacheKeys('workspaceAsset').list({ filter });

  const clearCache = async () => {
    await queryClient.invalidateQueries({ queryKey: cacheKey });
  };

  return {
    updateAsset,
    clearCache,
    isUpdating: isLoading,
  };
};

const UPDATE_ASSET = `
  mutation Asset($input: UpdateAssetInput!) {
    asset {
      updateAsset(input: $input) {
        id
      }
    }
  }
`;
