import { accountPageRoutes } from '../account/routes';
import { accountRoute } from '../accounts/routes';
import { activityRoutes } from '../activity/routes';
import { alertsRoutes } from '../alerts/routes';
import { assetsRoutes } from '../assets/routes';
import { ProtectedRoute } from '../auth/components/protectedRoute';
import { featuresRoute } from '../features/routes';
import { groupsRoutes } from '../groups/routes';
import { profileRoutes } from '../profile/routes';
import { routinesRoutes } from '../routines/routes';
import { tagRoutes } from '../tags/routes';
import { workspaceRoute } from '../workspaces/routes';
import { Layout } from './components/layout';

export const dashboardRoutes = [
  {
    path: '/',
    element: (
      <Layout>
        <ProtectedRoute />
      </Layout>
    ),
    handle: {
      requiredPermission: 'none',
    },
    children: [
      accountRoute,
      ...activityRoutes,
      ...alertsRoutes,
      ...assetsRoutes,
      ...groupsRoutes,
      ...routinesRoutes,
      ...tagRoutes,
      featuresRoute,
      ...profileRoutes,
      ...accountPageRoutes,
      workspaceRoute,
    ],
  },
];
