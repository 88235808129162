import {
  AssetTag,
  Media,
  MediaPickerComponent,
  MediaType,
  useCurrentWorkspace,
  useFindByQuery,
  useGroups,
  useUpdateAsset,
  useWorkspaceAsset,
} from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View, ViewStyle } from 'react-native';
import { Text } from 'react-native-paper';
import { array, string, object as yupObject } from 'yup';
import { Spacing } from '../../styles/index';
import { Colors } from '../../themes/colors';
import {
  Accordion,
  ControlledMultiSelectDropdown,
  ControlledTextInput,
  SaveFormComponent,
} from '../forms';
import { KeyboardSafeView } from '../keyboard/keyboardSafeView';
import { HorizontalMediaGallery, SingleImageInput } from '../media';
import { AssetDetails } from './assetDetails';
import { AssetTagGallery } from './assetTagGallery';
import { DeleteAsset } from './deleteAsset';
import { TagPickerComponent } from './types';

export type EditAssetStyles = {
  container: ViewStyle;
  contentContainer: ViewStyle;
  imageGalleryContainer?: ViewStyle;
};

export type EditAssetProps = {
  assetId: string;
  mediaPicker: MediaPickerComponent;
  tagPicker?: TagPickerComponent;
  saveForm: SaveFormComponent;
  formHasBorder?: boolean;
  styles: StyleSheet.NamedStyles<EditAssetStyles>;
  allowsEditingCover?: boolean;
  onDeleted: () => Promise<void>;
};

export const EditAsset = (props: EditAssetProps) => {
  // move to custom hook if need to reuse
  const useAssetTags = (assetId: string) => {
    const [assetTags, setAssetTags] = useState<AssetTag[]>([]);

    const filter = {
      json: {
        'link.linkData.id': assetId,
      },
    };

    const { data } = useFindByQuery({
      modelName: 'tag',
      query: FIND_ASSET_TAGS,
      variables: { filter },
      options: {
        staleTime: 0,
        gcTime: 0,
      },
    });

    useEffect(() => {
      if (!data) return;
      setAssetTags(data.items as AssetTag[]);
    }, [data]);

    return {
      assetTags,
    };
  };

  const SaveForm = props.saveForm;
  const MediaPicker = props.mediaPicker;

  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);

  const { updateAsset, clearCache } = useUpdateAsset({
    assetId: props.assetId,
  });

  const workspace = useCurrentWorkspace(true);
  const { groups } = useGroups(workspace?.id);
  const { workspaceAsset } = useWorkspaceAsset(props.assetId);
  const { assetTags } = useAssetTags(props.assetId);
  const asset = workspaceAsset?.asset;

  const formSchema = yupObject({
    name: string().required(),
    description: string().optional(),
    details: string().optional(),
    coverImageId: string().optional().nullable(),
    mediaIds: array().of(string()).optional().nullable(),
    tagIds: array().of(string()).optional().nullable(),
    groupIds: array().of(string()).optional().nullable(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    values: {
      name: asset?.name ?? '',
      coverImageId: asset?.coverImage ? asset?.coverImage.id : undefined,
      mediaIds: asset?.media?.map((media) => media.id),
      description: asset?.description ?? '',
      details: asset?.details ?? '',
      tagIds: assetTags.map((tag) => tag.id),
      groupIds: workspaceAsset?.groups?.map((group) => group.id),
    },
  });

  const setCoverImage = (image: Media) => {
    setValue('coverImageId', image.id, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const addMedia = (mediaItem: Media) => {
    const mediaIds = getValues('mediaIds') || [];
    setValue('mediaIds', [...mediaIds, mediaItem.id], {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const addAssetTag = (tag: AssetTag) => {
    const tagIds = getValues('tagIds') || [];
    setValue('tagIds', [...tagIds, tag.id], {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const deleteAssetTag = (tagId: string) => {
    const tagIds = getValues('tagIds') || [];
    setValue(
      'tagIds',
      tagIds.filter((id) => id !== tagId),
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
  };

  const onSubmit = async (data: any) => {
    const input: any = {
      ...data,
      id: props.assetId,
    };

    await updateAsset(
      {
        input: input,
      },
      {
        onSettled: async () => {
          reset({}, { keepValues: true });
          await clearCache();
        },
      }
    );
  };

  return (
    <SaveForm
      title={t('asset.editTitle')}
      onSubmit={handleSubmit(onSubmit)}
      isDirty={isDirty}
      isValid={isValid && !uploading}
      hasBorder={props.formHasBorder}
    >
      <KeyboardSafeView style={props.styles.container}>
        <ScrollView
          scrollEnabled
          showsVerticalScrollIndicator
          style={props.styles.contentContainer}
        >
          <View style={styles.coverImage}>
            <SingleImageInput
              image={asset?.coverImage}
              onImageAdded={setCoverImage}
              onUploadingChanged={setUploading}
              imagePicker={MediaPicker}
              allowsEditing={props.allowsEditingCover}
            />
            <View style={styles.assetDetailsContainer}>
              <AssetDetails workspaceAsset={workspaceAsset} />
            </View>
          </View>
          <View>
            <View>
              <Text variant={'titleMedium'} style={styles.formItemTitle}>
                {t('asset.mediaLibrary')}
              </Text>
            </View>
            <View style={props.styles.imageGalleryContainer}>
              <HorizontalMediaGallery
                media={asset?.media}
                onMediaAdded={addMedia}
                mediaPicker={MediaPicker}
                onUploadingChanged={setUploading}
                acceptedMediaTypes={[MediaType.Image, MediaType.Document]}
              />
            </View>
          </View>

          <View style={styles.formItem}>
            <View>
              <Text variant={'titleMedium'} style={styles.formItemTitle}>
                {t('asset.name')}
              </Text>
            </View>
            <ControlledTextInput
              control={control}
              placeholder={t('asset.namePlaceholder')}
              name="name"
            />
          </View>
          <View style={styles.formItem}>
            <View>
              <Text variant={'titleMedium'} style={styles.formItemTitle}>
                {t('asset.description')}
              </Text>
            </View>
            <ControlledTextInput
              control={control}
              placeholder={t('asset.descriptionPlaceholder')}
              textArea={false}
              name="description"
            />
          </View>
          <View style={styles.formItem}>
            <View>
              <Text variant={'titleMedium'} style={styles.formItemTitle}>
                {t('asset.details')}
              </Text>
            </View>
            <ControlledTextInput
              control={control}
              placeholder={t('asset.detailsPlaceHolder')}
              textArea={true}
              name="details"
            />
          </View>
          {groups && groups.length > 0 && (
            <View style={styles.formItem}>
              <View>
                <Text variant={'titleMedium'} style={styles.formItemTitle}>
                  {t('asset.groups')}
                </Text>
              </View>
              <ControlledMultiSelectDropdown
                control={control}
                placeholder={t('asset.groupsPlaceHolder')}
                searchPlaceholder={t('asset.groupsSearchPlaceHolder')}
                name="groupIds"
                options={groups.map((group) => {
                  return {
                    value: group.id,
                    label: group.name,
                  };
                })}
              />
            </View>
          )}
          <View style={styles.formItem}>
            <Accordion
              title={t('asset.advancedAssetInformation')}
              items={[
                <>
                  <Text
                    variant={'titleMedium'}
                    style={[styles.formItemTitle, styles.grippIdTitle]}
                  >
                    {t('asset.tagCode')}
                  </Text>
                  <AssetTagGallery
                    existingAssetTags={assetTags}
                    onAssetTagAdded={addAssetTag}
                    onAssetTagDeleted={deleteAssetTag}
                    tagPicker={props.tagPicker}
                  />
                </>,
              ]}
            />
          </View>
          <DeleteAsset
            workspaceAsset={workspaceAsset}
            onDeleted={props.onDeleted}
          />
        </ScrollView>
      </KeyboardSafeView>
    </SaveForm>
  );
};

const FIND_ASSET_TAGS = `
  query FindTags($filter: TagFilter) {
    findTags(filter: $filter) {
      items {
        id
        tagCode
        status
      }
    }
  }
`;

const styles = StyleSheet.create({
  imageGalleryContainer: {
    borderTopColor: Colors.grayDivider,
    borderTopWidth: 1,
    borderBottomColor: Colors.grayDivider,
    borderBottomWidth: 1,
    paddingTop: Spacing.basePadding.paddingVertical,
    paddingBottom: Spacing.basePadding.base,
  },
  assetDetailsContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginHorizontal: Spacing.baseMargin.marginHorizontal,
    marginVertical: Spacing.baseMargin.marginVertical,
  },
  coverImage: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 30,
    marginHorizontal: 2,
  },
  formItem: {
    width: '100%',
    maxWidth: 473,
    marginTop: Spacing.baseMargin.marginVertical,
  },
  formItemTitle: {
    fontSize: 16,
    lineHeight: 16,
    color: Colors.black,
    marginBottom: 10,
    fontWeight: '700',
  },
  grippIdTitle: {
    marginBottom: 0,
  },
});
