import {
  QuickConfirmQuestionConfig,
  QuickConfirmResponseState,
} from '@gripp/shared-logic';
import { Text } from 'react-native-paper';
import { RoutineQuestionMessage, RoutineQuestionMessageProps } from '../types';

export const QuickConfirmQuestionMessage: RoutineQuestionMessage<
  QuickConfirmQuestionConfig,
  QuickConfirmResponseState
> = (
  props: RoutineQuestionMessageProps<
    QuickConfirmQuestionConfig,
    QuickConfirmResponseState
  >
) => {
  const { response } = props;

  return <Text style={props.textStyle}>{response?.confirmedValue}</Text>;
};
