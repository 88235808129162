import {
  cacheKeys,
  FIND_GROUP_ASSIGNMENTS,
  useCurrentWorkspace,
  useCustomMutation,
  useCustomQuery,
} from '@gripp/shared-logic';
import {
  Colors,
  LoadingIndicator,
  SaveFormTop,
  TopModalBar,
  useGetDimensions,
} from '@gripp/shared-ui';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { EditGroupAssetsList } from './editGroupAssetsList';
import { GroupAssignmentSearchInput } from './groupAssignmentSearchInput';

type GroupAssignmentFormProps = {
  groupId: string;
  title: string;
  onComplete: () => void;
};

export const GroupAssignmentForm = ({
  title,
  onComplete,
  groupId,
}: GroupAssignmentFormProps) => {
  const { mutateAsync: assignAssetsToGroup, isPending: isLoading } =
    useCustomMutation({
      query: ASSIGN_ASSETS_TO_GROUP,
    });

  const { data: assignments } = useCustomQuery({
    query: FIND_GROUP_ASSIGNMENTS,
    cacheKey: [],
    variables: {
      groupId: groupId,
    },
  });

  const [originalAssetAssignments, setOriginalAssetAssignments] = useState<
    string[] | undefined
  >();

  const [assetAssignments, setAssetAssignments] = useState<
    string[] | undefined
  >(undefined);

  useEffect(() => {
    if (assignments) {
      const assetAssignments = assignments.group.findGroupAssignments.assets;
      setOriginalAssetAssignments(assetAssignments);
      setAssetAssignments(assetAssignments);
    }
  }, [assignments]);

  const currentWorkspace = useCurrentWorkspace(true);
  const queryClient = useQueryClient();
  const { dimensions, onLayout } = useGetDimensions();
  const [keyword, setKeyword] = useState('');

  const height = dimensions?.height ?? 0;
  const tableResultsBlockHeight = 50;
  const listMaxHeight = height - tableResultsBlockHeight;

  const handleSubmit = async () => {
    const input = {
      assetIds: assetAssignments,
      groupId: groupId,
      workspaceId: currentWorkspace.id,
    };

    await assignAssetsToGroup(
      { input: input },
      {
        onSettled: async () => {
          await queryClient.invalidateQueries({
            queryKey: cacheKeys('group').all,
          });
        },
      }
    );

    onComplete();
  };

  const isDirty = () =>
    originalAssetAssignments &&
    assetAssignments &&
    !(
      originalAssetAssignments.length === assetAssignments.length &&
      originalAssetAssignments.every((item) =>
        assetAssignments.includes(item)
      ) &&
      assetAssignments.every((item) => originalAssetAssignments.includes(item))
    );

  return (
    <SaveFormTop
      onSubmit={handleSubmit}
      title={title}
      topBar={TopModalBar}
      onCancel={onComplete}
      isDirty={isDirty()}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <View style={styles.container}>
          <GroupAssignmentSearchInput keyword={keyword} onChange={setKeyword} />

          <View style={[styles.listContainer]} onLayout={onLayout}>
            {assetAssignments && (
              <EditGroupAssetsList
                workspace={currentWorkspace}
                groupId={groupId}
                maxHeight={listMaxHeight}
                keyword={keyword}
                assignments={assetAssignments}
                updateAssignments={setAssetAssignments}
              />
            )}
          </View>
        </View>
      )}
    </SaveFormTop>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    paddingHorizontal: 20,
    paddingTop: 24,
    paddingBottom: 20,
  },
  listContainer: {
    flex: 1,
    borderRadius: 12,
    overflow: 'hidden',
    marginTop: 20,
  },
  inputStyle: {
    backgroundColor: Colors.white,
    borderColor: Colors.grayDivider,
    borderWidth: 1,
    borderRadius: 8,
  },
});

const ASSIGN_ASSETS_TO_GROUP = `
  mutation Group($input: AssignToGroupInput!) {
    group {
      assignToGroup(input: $input) {
        message
        success
      }
    }
  }
  `;
