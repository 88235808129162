import { Severity } from '@gripp/shared-logic';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export type SeverityIconDictionary = Record<
  Severity,
  React.ComponentProps<typeof MaterialCommunityIcons>['name']
>;

export const SeverityIcons: SeverityIconDictionary = {
  [Severity.Red]: 'flag',
  [Severity.Yellow]: 'flag-outline',
  [Severity.Green]: 'check-circle',
  [Severity.Purple]: 'note',
  [Severity.Blue]: 'format-list-checks',
};
