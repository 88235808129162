export enum WorkflowQuestionType {
  Checkbox = 'CHECKBOX',
  Counter = 'COUNTER',
  Media = 'MEDIA',
  QuickConfirm = 'QUICK_CONFIRM',
  Radio = 'RADIO',
  Routine = 'ROUTINE',
  Signature = 'SIGNATURE',
  Status = 'STATUS',
  Text = 'TEXT',
}
