import {
  WorkflowRoutineConfig,
  WorkflowRoutineState,
} from '@gripp/shared-logic';
import { MessageComponent, MessageComponentProps } from '../../../issue/types';
import { CommonRoutineMessageHeader } from '../../components/commonRoutineMessageHeader';
import { WorkflowMessagePage } from './workflowMessagePage';

export const WorkflowMessage: MessageComponent = (
  props: MessageComponentProps
) => {
  const assetRoutine = props.message.assetRoutine!;
  const config = assetRoutine.routineVersion.config as WorkflowRoutineConfig;
  const responses = (assetRoutine.data as WorkflowRoutineState).responses;
  const pages = config.pages;
  pages.sort((a, b) => a.order - b.order);

  return (
    <CommonRoutineMessageHeader {...props}>
      {pages.map((page) => (
        <WorkflowMessagePage
          key={page.id}
          page={page}
          responses={responses}
          exceptionsOnly={props.exceptionsOnly}
          assetRoutine={assetRoutine}
          message={props.message}
        />
      ))}
    </CommonRoutineMessageHeader>
  );
};
