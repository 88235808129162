import { PermissionValues as Permissions } from '@gripp/shared-logic';
import { Workspaces } from './components/workspaces';
import { WorkspacesToolbar } from './components/workspacesToolbar';

export const workspacePaths = {
  root: 'workspaces',
};

export const workspaceRoute = {
  path: workspacePaths.root,
  element: <Workspaces />,
  handle: {
    header: {
      titleKey: 'admin.menu.workspaces',
      icon: 'group',
    },
    requiredPermission: Permissions.workspace.all,
    toolbar: <WorkspacesToolbar />,
  },
};
