import { RoutineType } from '@gripp/shared-logic';
import { Colors, SaveFormBottom } from '@gripp/shared-ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';

export const NewRoutineType = () => {
  const navigate = useNavigate();
  const [currentType, setCurrentType] = useState<RoutineType | undefined>();
  const { t } = useTranslation();

  const routineTypes = useMemo(() => {
    const _ = [];
    for (const type in RoutineType) {
      _.push(type);
    }
    return _;
  }, []);

  const onSubmit = async () => {
    navigate(RoutineType[currentType]);
  };

  return (
    <SaveFormBottom
      onSubmit={onSubmit}
      saveLabel={t('routines.new.chooseTypeButton')}
      isValid={currentType !== undefined}
      buttonStyle={styles.saveButton}
    >
      <View style={styles.container}>
        {routineTypes.map((type) => (
          <RoutineTypeTile
            key={type}
            type={type}
            currentType={currentType}
            onPress={setCurrentType}
          />
        ))}
      </View>
    </SaveFormBottom>
  );
};

const RoutineTypeTile = ({
  type,
  currentType,
  onPress,
}: {
  type: RoutineType;
  currentType?: RoutineType;
  onPress: (type: RoutineType) => void;
}) => {
  const { t } = useTranslation();
  const i18nPrefix = 'routines.types';
  const style: any = [styles.tile];
  if (currentType === type) style.push(styles.tileSelected);

  return (
    <TouchableOpacity style={style} onPress={() => onPress(type)}>
      <View style={styles.titleContainer}>
        <Text style={styles.typeText}>
          {t(`${i18nPrefix}.${RoutineType[type]}`)}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 600,
  },
  tile: {
    flex: 1,
    flexBasis: '50%',
    flexGrow: 1,
    maxWidth: 253,
    height: 145,
    margin: 10,
    borderRadius: 12,
    borderColor: Colors.black,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tileSelected: {
    borderColor: Colors.primary,
    borderWidth: 5,
  },
  titleContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  typeText: {
    fontSize: 16,
  },
  saveButton: {
    alignSelf: 'flex-end',
    marginTop: 20,
    minWidth: 305,
  },
});
