import {
  RoutineQuestionConfig,
  RoutineResponseState,
} from '@gripp/shared-logic';
import { Text } from 'react-native-paper';
import { RoutineQuestionMessage, RoutineQuestionMessageProps } from '../types';

export const ExistingRoutineQuestionMessage: RoutineQuestionMessage<
  RoutineQuestionConfig,
  RoutineResponseState
> = (
  props: RoutineQuestionMessageProps<
    RoutineQuestionConfig,
    RoutineResponseState
  >
) => {
  const genericResponse = props.response as any;
  return (
    <Text style={props.textStyle}>
      {genericResponse?.count ?? genericResponse?.status}
    </Text>
  );
};
