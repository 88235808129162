import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FlatList, StyleSheet, View } from 'react-native';
import {
  Checkbox,
  RadioButton,
  Text,
  TextInput,
  TouchableRipple,
} from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { DebouncedTextInput } from './debouncedTextInput';

type SelectorProps = {
  control: any;
  name: string;
  items: Item[];
  multiselect?: boolean;
  isLoading?: boolean;
  searchable?: boolean;
  searchOutline?: boolean;
  searchPlaceholder?: string;
  searchAutoFocus?: boolean;
  onSearch?: (query: string) => void;
  onScrollEnd?: () => void;
};

type Item = {
  id: string;
  label: string;
  infoLabel?: string;
};

export const ControlledSelector = (props: SelectorProps) => {
  const Selector = props.multiselect ? Checkbox : RadioButton;
  const [searchText, setSearchText] = useState('');

  const handleSearch = async (query: string) => {
    props.onSearch?.(query);
  };

  const renderItem = ({ item, onChange, selected }) => (
    <TouchableRipple
      key={item.label}
      onPress={() =>
        onChange(getSelectedItems(item, selected, props.multiselect))
      }
    >
      <View style={styles.optionRow}>
        <View style={styles.option}>
          <Selector
            value={item.label}
            status={
              isItemSelected(item, selected, props.multiselect)
                ? 'checked'
                : 'unchecked'
            }
            onPress={props.multiselect ? undefined : () => onChange(item)}
          />
          <Text style={styles.labelText} numberOfLines={1}>
            {item.label}
          </Text>
        </View>
        {item.infoLabel && (
          <View style={styles.infoLabel}>
            <Text style={styles.infoLabelText} numberOfLines={1}>
              {item.infoLabel}
            </Text>
          </View>
        )}
      </View>
    </TouchableRipple>
  );

  return (
    <>
      {props.searchable && (
        <View style={props.searchOutline && styles.outlinedSearchContainer}>
          <DebouncedTextInput
            debounceTimeMs={500}
            debounceLength={3}
            debounceFunc={handleSearch}
            placeholder={props.searchPlaceholder}
            value={searchText}
            onBlur={() => {}}
            autoFocus={props.searchAutoFocus}
            onChangeText={(text) => setSearchText(text)}
            right={
              props.isLoading && (
                <TextInput.Icon
                  icon="loading"
                  color={Colors.primary}
                  style={styles.icon}
                />
              )
            }
            left={
              <TextInput.Icon
                icon="magnify"
                color={Colors.grayFill}
                style={styles.icon}
              />
            }
            height={40}
            outlineStyle={props.searchOutline && styles.outlinedSearch}
          />
        </View>
      )}
      {props.items?.length > 0 && (
        <Controller
          control={props.control}
          name={props.name}
          render={({ field: { onChange, value: selected } }) => (
            <FlatList
              data={props.items}
              extraData={props.items}
              renderItem={({ item }) =>
                renderItem({ item, onChange, selected })
              }
              keyExtractor={(item) => item.id}
              onEndReached={props.onScrollEnd}
              onEndReachedThreshold={0.2}
              style={styles.scrollViewContainer}
            />
          )}
        />
      )}
    </>
  );
};

const isItemSelected = (
  item: Item,
  selected: Item[] | Item,
  multiselect?: boolean
) => {
  if (multiselect) {
    return (selected as Item[]).some((i: Item) => i?.id === item.id);
  } else {
    return (selected as Item)?.id === item.id;
  }
};

const getSelectedItems = (
  item: Item,
  selected: Item[] | Item,
  multiselect?: boolean
) => {
  if (multiselect) {
    return isItemSelected(item, selected, multiselect)
      ? (selected as Item[]).filter((i: Item) => i.id !== item.id)
      : [...(selected as Item[]), item];
  } else {
    return item;
  }
};

const styles = StyleSheet.create({
  scrollViewContainer: {
    scrollbarWidth: 'thin',
    flex: 1,
  },
  optionRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8,
    flexWrap: 'wrap',
  },
  option: {
    maxWidth: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  labelText: {
    fontWeight: '400',
    color: Colors.blackText,
    fontSize: 16,
  },
  infoLabel: {},
  infoLabelText: {
    fontWeight: '400',
    color: Colors.grayText,
    fontSize: 16,
  },
  icon: {
    left: -10,
  },
  outlinedSearch: {
    backgroundColor: Colors.white,
    borderColor: Colors.grayDivider,
    borderWidth: 0.5,
    borderRadius: 8,
  },
  outlinedSearchContainer: {
    marginBottom: 16,
    paddingRight: 16,
    paddingLeft: 4,
  },
});
