import { useGrippContext } from '@gripp/shared-logic';
import { RoutineModules } from '../routine';
import { MessageComponent, MessageComponentProps } from './types';

export const RoutineExceptionMessage: MessageComponent = (
  props: MessageComponentProps
) => {
  const { logger } = useGrippContext();
  if (
    !props.message.assetRoutine ||
    !props.message.assetRoutine.routineVersion
  ) {
    return null;
  }

  const routineType = props.message.assetRoutine.routineVersion.type;
  const routineModule =
    RoutineModules[routineType as keyof typeof RoutineModules];

  if (!routineModule) {
    logger.error(`No routine module found for type: ${routineType}`);
    return null;
  }

  const Message = routineModule.message;

  return <Message {...props} exceptionsOnly={true} />;
};
