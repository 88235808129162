import { Preferences } from './components/preferences';
import { Profile } from './components/profile';

export const profilePaths = {
  root: 'profile',
  preferences: 'profile/preferences',
};

export const profileRoutes = [
  {
    path: profilePaths.root,
    element: <Profile />,
    handle: {
      header: {
        titleKey: 'profile.edit.menu.title',
        icon: 'account-outline',
      },
    },
  },
  {
    path: profilePaths.preferences,
    element: <Preferences />,
    handle: {
      header: {
        titleKey: 'preferences.menu.title',
      },
    },
  },
];
