import { CounterConfig, CounterState } from '@gripp/shared-logic';
import { RoutineModule } from '../types';
import { CounterButtons } from './counterButtons';
import {
  CounterExecutionForm,
  getExecutionFormDefaultValues,
  getExecutionFormResolver,
} from './counterExecutionForm';
import { CounterMessage } from './counterMessage';
import { CounterHeader } from './counterHeader';
import { CounterAdminForm, CounterAdminSchema } from './counterAdminForm';

export const CounterModule: RoutineModule<CounterConfig, CounterState> = {
  header: CounterHeader,
  buttons: CounterButtons,
  executionForm: CounterExecutionForm,
  getExecutionFormDefaultValues,
  getExecutionFormResolver,
  adminForm: CounterAdminForm,
  adminSchema: CounterAdminSchema,
  message: CounterMessage,
};
