import { StatusQuestionConfig, StatusResponseState } from '@gripp/shared-logic';
import { Text } from 'react-native-paper';
import { RoutineQuestionMessage, RoutineQuestionMessageProps } from '../types';

export const StatusQuestionMessage: RoutineQuestionMessage<
  StatusQuestionConfig,
  StatusResponseState
> = (
  props: RoutineQuestionMessageProps<StatusQuestionConfig, StatusResponseState>
) => {
  const { response } = props;

  return <Text style={props.textStyle}>{response?.status}</Text>;
};
