export interface RoutineConfig {
  title: string;
  routineButtonText?: string;
  issuePrefix?: string;
  reportOnException?: boolean;
}

export interface SingleStateConfig extends RoutineConfig {
  instructions: string;
}
