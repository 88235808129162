import { MD3LightTheme as DefaultTheme, MD3Theme } from 'react-native-paper';
import { Colors } from './colors';

export const BaseTheme: MD3Theme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        ...Colors,
    },
    roundness: 8
};
