import { StyleSheet } from "react-native";

export const mediaStyles = StyleSheet.create({
  imageCard: {
    height: 100,
    width: 100,
  },
  imageCardContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
  },
  cardIcon: {
    height: 45,
    width: 45,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginTop: -30,
    marginRight: -20,
  },
  progress: {
    marginTop: 5,
  },
});
