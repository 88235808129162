import {
  getFullName,
  RoutineType,
  SortDirectionEnum,
  useCustomQuery,
  WorkflowRoutineConfig,
  WorkspaceRoutine,
} from '@gripp/shared-logic';
import { isArray } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { Menu } from 'react-native-paper';
import { menuStyles } from './menuStyles';

type ExportRoutineMenuItemProps = {
  workspaceRoutine: WorkspaceRoutine;
  onComplete: () => void;
};

export const ExportRoutineMenuItem = (props: ExportRoutineMenuItemProps) => {
  const { t } = useTranslation();
  const csvRef = useRef();
  const [headers, setHeaders] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [doExport, toggleExport] = useState(false);
  const [label, setLabel] = useState('export');
  const { data } = useCustomQuery({
    query: GET_ROUTINE_DATA,
    cacheKey: ['exportRoutineData', props.workspaceRoutine.id],
    variables: {
      filter: {
        routine: { eq: props.workspaceRoutine.id },
      },
      orderBy: { field: 'createdAt', order: SortDirectionEnum.Asc },
    },
    options: {
      enabled: doExport,
      gcTime: 10000,
      staleTime: 10000,
    },
  });
  const baseHeaders = useMemo(
    () => [
      { label: 'Asset', key: 'asset' },
      { label: 'Created By', key: 'createdBy' },
      { label: 'Created At', key: 'createdAt' },
      { label: 'Groups', key: 'groups' },
    ],
    []
  );

  const onDownload = () => {
    if (rows?.length > 0) {
      // user already clicked on icon 1x, so just download it
      doDownload();
    } else {
      toggleExport(true);
      setLabel('exporting');
    }
  };

  const doDownload = useCallback(() => {
    setTimeout(() => {
      csvRef.current.link.click();
      toggleExport(false);
      setLabel('export');
      props.onComplete();
    }, 50);
  }, [props]);

  const getBaseRowData = useCallback((item: any) => {
    return {
      asset: item.asset.name,
      createdBy: getFullName(item.createdBy.name),
      createdAt: item.createdAt,
      groups: item.workspaceAsset.groups
        ? item.workspaceAsset.groups.map((g: any) => g.name).join(', ')
        : '',
    };
  }, []);

  const getResponseKey = useCallback((response: any) => {
    const keys = Object.keys(response);
    const valueKeys = [
      'value',
      'confirmedValue',
      'count',
      'status',
      'option',
      'options',
      'mediaIds',
    ];
    return keys.find((key) => valueKeys.includes(key));
  }, []);

  const setWorkflowData = useCallback(
    (items: any[]) => {
      const workflowConfig = props.workspaceRoutine
        .config as WorkflowRoutineConfig;
      const questions = workflowConfig.pages
        .flatMap((page) => page.questions)
        .sort((a, b) => a.order - b.order);

      setHeaders([
        ...baseHeaders,
        ...questions.map((question) => ({
          label: question.instructions,
          key: question.id,
        })),
      ]);
      setRows(
        items.map((item) => {
          const baseRowData = getBaseRowData(item);
          const row: any = {
            ...baseRowData,
          };
          item.data.responses.forEach((response: any) => {
            const valueKey = getResponseKey(response);
            const value = response[valueKey as string];
            if (isArray(value)) {
              row[response.questionId] = value.join(', ');
            } else {
              row[response.questionId] = value;
            }
          });
          return row;
        })
      );
    },
    [baseHeaders, getBaseRowData, props.workspaceRoutine.config]
  );

  const setNonWorkflowData = useCallback(
    (items: any[]) => {
      setHeaders([
        ...baseHeaders,
        { label: props.workspaceRoutine.config.title, key: 'data' },
      ]);

      setRows(
        items.map((item) => {
          const baseRowData = getBaseRowData(item);
          return {
            ...baseRowData,
            data: Object.values(item.data)[0], // there is only 1 "value" on non-workflow routines, so this will get "count", "confirmedValue", "value", etc.
          };
        })
      );
    },
    [baseHeaders, getBaseRowData, props.workspaceRoutine.config.title]
  );

  useEffect(() => {
    if (!data) return;

    if (props.workspaceRoutine.type === RoutineType.Workflow) {
      setWorkflowData(data.findAssetRoutines.items);
    } else {
      setNonWorkflowData(data.findAssetRoutines.items);
    }

    if (doExport) doDownload();
  }, [
    data,
    props.workspaceRoutine,
    doExport,
    doDownload,
    setWorkflowData,
    setNonWorkflowData,
  ]);

  return (
    <>
      <Menu.Item
        leadingIcon="file-export-outline"
        style={[menuStyles.white, menuStyles.menuItem]}
        onPress={onDownload}
        title={t(`routines.menu.${label}`)}
        disabled={doExport}
      />
      <CSVLink
        style={{ display: 'none' }}
        ref={csvRef}
        data={rows}
        headers={headers}
        filename={`${props.workspaceRoutine.name}-data.csv`}
      />
    </>
  );
};

const GET_ROUTINE_DATA = `
  query FindAssetRoutines($filter: AssetRoutineFilter, $orderBy: OrderByInput) {
    findAssetRoutines(filter: $filter, orderBy: $orderBy) {
      items {
        asset {
          name
        }
        workspaceAsset {
          groups {
            name
          }
        }
        createdAt
        createdBy {
          name {
            first
            last
          }
        }
        data
      }
    }
  }
`;
