import { Asset, IssueType, Severity } from '@gripp/shared-logic';
import { Colors, SeverityIcons, TouchableButton } from '@gripp/shared-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { NewIssueModal, NewIssueModalProps } from '../issues';

type ActionButtonsProps = {
  onCompleted: (message: string, messageDownloadApp: string) => void;
  asset: Asset;
  workspaceId: string;
  buttonStyle?: StyleProp<ViewStyle>;
  titleFontSize?: number;
};

export const ActionButtons = (props: ActionButtonsProps) => {
  const { t } = useTranslation();
  const [issueModalVisible, setIssueModalVisible] = useState(false);
  const [newIssueModalProps, setNewIssueModalProps] = useState<
    NewIssueModalProps | undefined
  >(undefined);

  const onReportIssue = () => {
    setNewIssueModalProps({
      assetId: props.asset.id,
      workspaceId: props.workspaceId!,
      issueType: IssueType.Report,
      severity: Severity.Red,
      isOpened: issueModalVisible,  
      modalType: 'sheet',    
      onComplete: () =>
        onIssueComplete(
          t('issue.anonymous.reported'),
          t('issue.anonymous.reportAddedDownloadGrippApp')
        ),
      onCancel: onCancel,
    });
    setIssueModalVisible(true);
  };

  const onNewNote = () => {
    setNewIssueModalProps({
      assetId: props.asset.id,
      workspaceId: props.workspaceId!,
      issueType: IssueType.Note,
      severity: Severity.Purple,
      isOpened: issueModalVisible,
      modalType: 'sheet',
      onComplete: () =>
        onIssueComplete(
          t('issue.anonymous.noteAdded'),
          t('issue.anonymous.noteAddedDownloadGrippApp')
        ),
      onCancel: onCancel,
    });
    setIssueModalVisible(true);
  };

  const onIssueComplete = (message: string, messageDownloadApp: string) => {
    setIssueModalVisible(false);
    props.onCompleted(message, messageDownloadApp);
  };

  const onCancel = () => {
    setIssueModalVisible(false);
  };

  return (
    <>
      <View style={styles.container}>
        <TouchableButton
          color={Colors.white}
          icon={SeverityIcons[Severity.Red]}
          label={t('issue.anonymous.createReport')}
          style={[
            styles.button,
            styles.buttonText,
            styles.report,
            props.buttonStyle,
          ]}
          onPress={onReportIssue}
          titleFontSize={props.titleFontSize}
        />
        <TouchableButton
          color={Colors.white}
          icon={SeverityIcons[Severity.Purple]}
          label={t('issue.anonymous.addNote')}
          style={[
            styles.button,
            styles.buttonText,
            styles.note,
            props.buttonStyle,
          ]}
          onPress={onNewNote}
          titleFontSize={props.titleFontSize}
        />
      </View>
      <NewIssueModal {...newIssueModalProps} isOpened={issueModalVisible} />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 8,
    paddingLeft: 20,
  },
  button: {
    maxWidth: 167,
    height: 81,
    borderRadius: 8,
    marginBottom: 20,
  },
  buttonText: {
    flex: 1,
    flexDirection: 'column',
    lineHeight: 18,
    fontWeight: '500',
    justifyContent: 'center',
    alignItems: 'center',
  },
  report: {
    backgroundColor: Colors.red,
  },
  note: {
    backgroundColor: Colors.purple,
  },
});
