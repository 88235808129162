import {
  Media,
  PickerMode,
  useCurrentAccount,
  useCurrentAccountAvatar,
  useUpdateAccount,
} from '@gripp/shared-logic';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Colors } from '../themes';
import { AccountAvatar } from './accountAvatar';
import { ImageData, ImagePickerComponent } from './media/types';
import { useTypedMediaItem } from './media/useTypedMediaItem';

export type CurrentAccountAvatarProps = {
  mediaUrl: string;
  size: number;
  onPress?: any;
  editable?: boolean;
  imagePicker?: ImagePickerComponent | undefined;
  isIconInBox?: boolean;
  allowsEditingAvatar?: boolean;
};

export const CurrentAccountAvatar = ({
  mediaUrl,
  size,
  onPress = () => {},
  editable = false,
  imagePicker = undefined,
  isIconInBox = false,
  allowsEditingAvatar = false,
}: CurrentAccountAvatarProps) => {
  const ImagePicker = imagePicker;
  const { account } = useCurrentAccount();
  const { currentAvatar } = useCurrentAccountAvatar();
  const { typedMediaItem: newAvatar, handleSet } =
    useTypedMediaItem<ImageData>(undefined);

  const { mutateAsync: updateAccount } = editable
    ? useUpdateAccount()
    : { mutateAsync: () => {} };

  const updateAvatar = async (image: Media) => {
    await updateAccount({
      input: {
        id: account?.id,
        avatar: {
          id: image.id,
        },
      },
    });
  };

  const sizeCircle = { width: size, height: size };
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[styles.circleContainer, sizeCircle]}>
        <AccountAvatar
          avatar={currentAvatar}
          mediaUrl={mediaUrl}
          size={size}
          style={styles.mainAvatar}
          directAvatarUrl={newAvatar?.url}
        />
        {editable && ImagePicker && (
          <View style={styles.boxIcon}>
            <ImagePicker
              mode={PickerMode.CAMERA}
              IsSquareCameraView={true}
              setMedia={(media: ImageData) => handleSet(media, updateAvatar)}
              setProgress={() => {}}
              setUploading={() => {}}
              isIconInBox={isIconInBox}
              allowsEditing={allowsEditingAvatar}
            />
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  circleContainer: {
    borderRadius: 50,
    overflow: 'hidden',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mainAvatar: {
    position: 'absolute',
  },
  boxIcon: {
    width: 100,
    height: 20,
    backgroundColor: `${Colors.primary}50`,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
  },
});
