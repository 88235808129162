import { useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { Colors } from '../../themes/colors';
import { CloseButton } from './closeButton';
import { ZoomableImage } from './zoomableImage';

const { width } = Dimensions.get('window');

export type CarouselBaseProps = {
  mediaItems: ImageData[];
  activeIndexImage: number;
};

type CarouselProps = CarouselBaseProps & {
  onPressOut: () => void;
};

export const Carousel = ({
  mediaItems = [],
  activeIndexImage,
  onPressOut,
}: CarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(activeIndexImage);
  const scrollViewRef = useRef<ScrollView>(null);

  const handleScroll = (event: any) => {
    const slideSize = event.nativeEvent.layoutMeasurement.width;
    const index = event.nativeEvent.contentOffset.x / slideSize;
    setActiveIndex(Math.round(index));
  };

  const scrollToIndex = (index: number) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        x: index * width,
        animated: true,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToIndex(activeIndexImage);
    }, 200);
  }, [activeIndexImage]);

  return (
    <View style={styles.container}>
      <View style={styles.buttonClose}>
        <CloseButton onClose={onPressOut} />
      </View>
      <ScrollView
        ref={scrollViewRef}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        onScroll={handleScroll}
        style={styles.scrollView}
        contentContainerStyle={styles.scrollViewContent}
        scrollEventThrottle={16}
      >
        {mediaItems.map((image, index) => (
          <ZoomableImage key={image.id} uri={image.mediaUrlOriginal} />
        ))}
      </ScrollView>
      <View style={styles.pagination}>
        {mediaItems.map((_, index) => (
          <TouchableOpacity key={index} onPress={() => scrollToIndex(index)}>
            <View
              style={[
                styles.paginationDot,
                index === activeIndex && styles.paginationDotActive,
              ]}
            />
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: 20,
  },
  scrollView: {
    width: width,
  },
  scrollViewContent: {
    alignItems: 'center',
    ...Platform.select({
      web: {
        justifyContent: 'center',
      },
    }),
  },
  buttonClose: {
    position: 'absolute',
    zIndex: 9,
    ...Platform.select({
      ios: {
        top: 35,
        left: 10,
      },
      android: {
        top: 20,
        left: 10,
      },
      web: {
        position: 'absolute',
        top: 35,
        transform: [{ translateX: -320 }],
      },
    }),
  },
  pagination: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 30,
    ...Platform.select({
      web: {
        bottom: 0,
      },
    }),
  },
  paginationDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: Colors.white,
    marginHorizontal: 5,
  },
  paginationDotActive: {
    backgroundColor: Colors.primary,
  },
});
