import { AssetTag } from '@gripp/shared-logic';
import { ReactNode } from 'react';

export type AssetTagGalleryProps = {
  existingAssetTags: AssetTag[];
  onAssetTagAdded: (assetTag: AssetTag) => void;
  onAssetTagDeleted: (assetTagId: string) => void;
  tagPicker?: TagPickerComponent;
};

export type TagPickerProps = {
  setTag: (tag: any) => void;
  setAlertTitle?: (title: string) => void;
  setShowAlert?: (visible: boolean) => void;
};

export type TagPickerComponent = (props: TagPickerProps) => ReactNode;
