import { PermissionValues } from '@gripp/shared-logic';
import { Features } from './components/features';

export const featuresPaths = {
  features: 'features',
};

export const featuresRoute = {
  path: featuresPaths.features,
  element: <Features />,
  handle: {
    header: {
      titleKey: 'features.title',
      icon: 'test-tube',
    },
    requiredPermission: PermissionValues.feature.all,
  },
};
