import { MaterialIcons } from '@expo/vector-icons';
import { DATE_FORMAT_MMDDYYYY } from '@gripp/shared-logic';
import moment from 'moment';
import { RefObject, useCallback, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { Calendar, DateData } from 'react-native-calendars';
import { DayProps } from 'react-native-calendars/src/calendar/day';
import { Colors } from '../../../themes';
import { DropDownView } from '../../modals';
import { DatePickerCustomHeader } from './datePickerCustomHeader';
import { datePickerStyle as styles } from './datePickerStyle';
import { DateRangeSelectorProps } from './dateRangeSelector';

type DateRangePickerSelectorProps = DateRangeSelectorProps & {
  anchorRef: RefObject<View>;
  isVisible: boolean;
  onClose: () => void;
  onDateSelected: (...event: any[]) => void;
  value: string;
  isStartDate?: boolean;
};
export const DateRangePickerSelector = ({
  anchorRef,
  isVisible,
  onClose,
  onDateSelected,
  value,
  isStartDate,
  ...props
}: DateRangePickerSelectorProps) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const handleDayPress = useCallback(
    (date?: DateData) => {
      if (!date) return;

      if (isStartDate) {
        onDateSelected(moment(date.dateString).format(DATE_FORMAT_MMDDYYYY));
        const startDate = moment(date.dateString);
        const endDate = moment(
          props.control._formValues.endDate,
          DATE_FORMAT_MMDDYYYY
        );

        if (startDate.isAfter(endDate)) props.setValue('endDate', '');

        onClose();
        return;
      }
      const startDate = moment(
        props.control._formValues.startDate,
        DATE_FORMAT_MMDDYYYY
      );
      const endDate = moment(date.dateString);

      if (startDate.isAfter(endDate)) return;

      onDateSelected(moment(date.dateString).format(DATE_FORMAT_MMDDYYYY));
      onClose();
    },
    [isStartDate, onClose, onDateSelected, props]
  );

  const getMarkedDates = useCallback(() => {
    const markedDates: Record<string, any> = {};
    if (value) {
      const formattedDate = moment(value, DATE_FORMAT_MMDDYYYY).format(
        'YYYY-MM-DD'
      );
      markedDates[formattedDate] = {
        customStyles: {
          container: {
            backgroundColor: Colors.primary,
          },
          text: {
            color: Colors.white,
          },
        },
      };
    }

    return markedDates;
  }, [value]);

  const handleMonthChange = useCallback((date: DateData) => {
    setCurrentDate(new Date(date.dateString));
  }, []);

  const _renderArrow = useCallback(
    (direction: 'left' | 'right') => (
      <MaterialIcons
        name={direction === 'left' ? 'chevron-left' : 'chevron-right'}
        size={16}
        color={Colors.black}
      />
    ),
    []
  );

  return (
    <DropDownView
      anchorRef={anchorRef}
      isVisible={isVisible}
      onDismiss={onClose}
      width={306}
      height={380}
      backgroundColor={Colors.primaryGrayBackground}
    >
      <View style={styles.container}>
        <Calendar
          key={currentDate.toISOString()}
          markingType={'custom'}
          markedDates={getMarkedDates()}
          onDayPress={handleDayPress}
          current={currentDate.toISOString().split('T')[0]}
          renderHeader={() => (
            <DatePickerCustomHeader
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
            />
          )}
          style={styles.calendar}
          renderArrow={_renderArrow}
          onMonthChange={handleMonthChange}
          theme={{
            calendarBackground: Colors.primaryGrayBackground,
            'stylesheet.calendar.header': {
              arrow: {
                padding: 0,
              },
            },
          }}
          hideExtraDays
          dayComponent={({
            date,
            state,
            marking,
          }: DayProps & { date?: DateData }) => {
            return (
              <TouchableOpacity onPress={() => handleDayPress(date)}>
                <Text
                  style={[
                    {
                      backgroundColor: marking?.customStyles?.container
                        ? marking?.customStyles?.container?.backgroundColor
                        : Colors.white,
                      color: marking?.customStyles?.container
                        ? Colors.white
                        : state === 'today'
                        ? Colors.primary
                        : Colors.black,
                    },
                    styles.textDay,
                  ]}
                >
                  {date?.day}
                </Text>
              </TouchableOpacity>
            );
          }}
        />
      </View>
    </DropDownView>
  );
};
