import { Colors } from '@gripp/shared-ui';
import { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';

export const ReportContainer = ({ children }: { children: ReactNode }) => (
  <View style={styles.modalContainer}>
    <View style={styles.contentContainer}>{children}</View>
  </View>
);

const styles = StyleSheet.create({
  modalContainer: {
    width: 560,
    height: 'auto',
    borderRadius: 12,
    backgroundColor: Colors.primaryGrayBackground,
  },
  contentContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
});
