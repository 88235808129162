import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { useCurrentAccount, useGrippContext } from '@gripp/shared-logic';
import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { StyleSheet, View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { useContainerClient } from '../../shared/hooks/useContainerClient';

type BulkUpdateFileUploaderProps = {
  title: string;
  rows: any;
  headers: any;
  workspaceId: string;
  workspaceName: string;
};

export const BulkUpdateFileUploader = (props: BulkUpdateFileUploaderProps) => {
  const { logger } = useGrippContext();
  const { account } = useCurrentAccount();
  const containerClient = useContainerClient();
  const [upload, toggleUpload] = useState(false);
  const fileUploadRef = useRef(null);
  const onFileSelected = async (e) => {
    const file = e.target.files[0];
    const blobRoot = props.title.toLowerCase();
    const blob = `${blobRoot}/${account.id}/${props.workspaceId}/${props.workspaceName}/${file.name}`;
    const blockBlobClient = containerClient?.getBlockBlobClient(blob);
    await blockBlobClient
      ?.uploadData(file, {
        blobHTTPHeaders: {
          blobContentType: 'text/csv',
        },
      })
      .catch((err) => {
        logger?.error(err, `Failed to upload bulk file: ${file.name}`);
        alert('Failed to upload file. Please retry');
      });
    toggleUpload(true);
    fileUploadRef.current.value = null;
  };

  const onUpload = () => {
    toggleUpload(false);
    fileUploadRef.current.click();
  };

  return (
    <View style={styles.container}>
      <Text variant="headlineMedium" style={styles.text}>
        {props.workspaceName} {props.title}
      </Text>
      {props.rows && (
        <CSVLink
          headers={props.headers}
          style={styles.link}
          data={props.rows}
          filename={`${props.workspaceName}-${props.title.toLowerCase()}.csv`}
        >
          <Icon name="file-download-outline" size={20} />
          Download
        </CSVLink>
      )}
      <input
        ref={fileUploadRef}
        type="file"
        style={{ display: 'none' }}
        accept=".csv"
        onChange={onFileSelected}
        multiple={false}
      />
      <Button
        style={styles.button}
        mode="contained"
        icon="file-upload-outline"
        onPress={onUpload}
      >
        Upload
      </Button>
      {upload && <Text variant="headlineSmall">Uploaded!</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    margin: 20,
  },
  link: {
    textDecorationLine: 'none',
  },
  text: {
    padding: 20,
  },
});
