import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Group, useGroups } from '@gripp/shared-logic';
import {
  Colors,
  ControlledSelector,
  SingleInputModalForm,
  Spacing,
} from '@gripp/shared-ui';
import { random } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

export type GroupsSelectorProps = {
  headerLabel: string;
  workspaceId: string;
  onSubmit: (selectedGroups: Group[]) => void;
  isUpdating: boolean;
};

export const GroupsSelector = (props: GroupsSelectorProps) => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      groupItems: [],
    },
    mode: 'onChange',
  });
  const { groups, invalidateQuery: clearGroupsCache } = useGroups(
    props.workspaceId
  );
  const selectedGroupItems = watch('groupItems');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalId = `modal-${random(0, 1000)}`;

  const canApply = useMemo(() => {
    return isDirty;
  }, [isDirty]);

  const handleApply = async (newGroupName?: string) => {
    const groupsToApply = newGroupName
      ? [{ name: newGroupName } as Group]
      : selectedGroupItems.map((item: any) => {
          return { id: item.id, name: item.label } as Group;
        });
    props.onSubmit(groupsToApply);
    await clearGroupsCache();
    reset();
  };

  const handleModalSubmit = (newGroupName: string) => {
    setIsModalOpen(false);
    handleApply(newGroupName);
  };

  return (
    <>
      <View style={styles.headerOrFooter}>
        <Text style={styles.headerLabel}>{props.headerLabel}</Text>
      </View>
      <ControlledSelector
        multiselect
        items={groups.map((group) => ({ id: group.id, label: group.name }))}
        control={control}
        name="groupItems"
      />
      <View style={styles.headerOrFooter}>
        {canApply ? (
          <Pressable style={styles.applyButton} onPress={() => handleApply()}>
            {props.isUpdating && (
              <ActivityIndicator color={Colors.primaryDark} size={14} />
            )}
            <Text style={styles.buttonLabel}>
              {t('groups.groupsSelector.apply')}
            </Text>
          </Pressable>
        ) : (
          <Pressable
            style={styles.addNewButton}
            onPress={() => setIsModalOpen(true)}
          >
            <MaterialCommunityIcons
              name="plus"
              color={Colors.primaryDark}
              size={24}
            />
            <Text style={styles.buttonLabel}>
              {t('groups.groupsSelector.addtoNewGroup')}
            </Text>
          </Pressable>
        )}
      </View>
      <SingleInputModalForm
        id={modalId}
        formTitle={t('groups.groupsSelector.addtoNewGroup')}
        isOpen={isModalOpen}
        onSubmit={handleModalSubmit}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  buttonLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.primaryDark,
  },
  headerLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.blackText,
  },
  groupsPlaceholder: {
    height: 100,
  },
  headerOrFooter: {
    justifyContent: 'center',
    paddingVertical: Spacing.basePadding.base,
  },
  addNewButton: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 32,
    paddingVertical: 12,
  },
  applyButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: 8,
    width: '100%',
    height: 32,
    borderWidth: 2,
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    borderColor: Colors.primaryDark,
  },
});
