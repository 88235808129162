import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Colors } from '@gripp/shared-ui';
import React, { useCallback } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import Toast, { ToastType } from 'react-native-toast-message';
import { GestureResponderEvent } from 'react-native/types';

type CopyTextProps = {
  textToCopy: string;
  buttonText: string;
  successMessage: string;
  errorMessage: string;
}

export const CopyText = (props: CopyTextProps) => {

  const showToast = useCallback((type: ToastType, message: string) => {
    Toast.show({
      type,
      text1: message,
    });
  }, []);

  const handleCopy = useCallback((e: GestureResponderEvent) => {
    if (!props.textToCopy) return;
    e.preventDefault();
    e.stopPropagation();
    const copyUrl = async () => {
      try {
        await navigator.clipboard.writeText(props.textToCopy);
        showToast('success', props.successMessage);
      } catch (error) {
        showToast('error', props.errorMessage)
      }
    }
    copyUrl();
  }, []);

  return (
    <Pressable style={styles.button} onPress={handleCopy}>
      <MaterialCommunityIcons name="link-variant" size={24} color={Colors.primaryDark} />
      <Text style={styles.buttonText}>
        {props.buttonText}
      </Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6
  },
  buttonText: {
    fontWeight: '400',
    color: Colors.primaryDark,
    fontSize: 16,
    lineHeight: 19
  },
})