import { MaterialCommunityIcons } from '@expo/vector-icons';
import { WorkspaceAsset } from '@gripp/shared-logic';
import { Platform, Pressable, StyleSheet, View } from 'react-native';
import openMap from 'react-native-open-maps';
import { Text } from 'react-native-paper';
import { useResponsiveDesign } from '../../hooks/useResponsiveDesign';
import { Colors } from '../../themes';
import { RoutineSubHeader } from './routineSubHeader';

type AssetDetailsProps = {
  workspaceAsset: WorkspaceAsset | undefined;
};

export const AssetDetails = (props: AssetDetailsProps) => {
  const { workspaceAsset } = props;
  const isResponsiveStyle = useResponsiveDesign();

  if (!workspaceAsset) {
    return null;
  }

  const { asset } = workspaceAsset;
  const showSubHeader =
    workspaceAsset?.routineState && workspaceAsset.routineState.length > 0;

  return (
    asset && (
      <>
        <Text
          style={[styles.assetTitle, { fontSize: isResponsiveStyle ? 18 : 22 }]}
        >
          {asset.name}
        </Text>
        {asset.lastAddress && (
          <Text style={styles.locationText}>
            {asset.lastAddress}
            <Pressable
              onPress={() =>
                openMap({
                  query: `${asset.lastLocation.coordinates[1]}, ${asset.lastLocation.coordinates[0]}`,
                })
              }
            >
              <MaterialCommunityIcons
                name="open-in-new"
                color={Colors.primary}
                size={17}
                style={styles.locationButton}
              />
            </Pressable>
          </Text>
        )}
        <View
          style={[
            {
              marginTop: 5,
              overflow: 'hidden',
            },
          ]}
        >
          {showSubHeader && (
            <RoutineSubHeader workspaceAsset={workspaceAsset} />
          )}
        </View>
      </>
    )
  );
};

const styles = StyleSheet.create({
  locationButton: {
    paddingLeft: 5,
    top: 3,
  },
  locationText: {
    ...Platform.select({
      web: {
        fontSize: 12,
        fontWeight: '400',
      },
      default: {
        fontSize: 12,
        fontWeight: '500',
      },
    }),
  },
  assetTitle: {
    fontWeight: '700',
  },
});
