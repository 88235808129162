import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Media } from '@gripp/shared-logic';
import { Image, Platform, Pressable, StyleSheet, View } from 'react-native';
import { useOpenedState } from '../../hooks/useOpenedState';
import { Colors } from '../../themes';
import {
  ImageData,
  SizePrefix,
  useTypedMediaItem,
} from '../media';
import { ImageLightBox } from '../modalImages/imageLightbox';

type AssetHeaderImageProps = {
  assetCoverImage: Media;
  imgSize?: number;
};

export const AssetHeaderImage = (props: AssetHeaderImageProps) => {
  const { isOpened, onOpen, onClose } = useOpenedState();
  const { typedMediaItem: coverImage } = useTypedMediaItem<ImageData>(
    props.assetCoverImage
  );
  return (
    <View>
      {coverImage ? (
        <>
          <Pressable onPress={onOpen}>
            <Image
              style={[
                styles.cardImage,
                { width: props.imgSize || 56, height: props.imgSize || 56 },
              ]}
              source={{ uri: coverImage.urlsBySize?.[SizePrefix.extraSmall] }}
            />
          </Pressable>
          <ImageLightBox
            url={coverImage.urlsBySize?.[SizePrefix.original]}
            isOpened={isOpened}
            onClose={onClose}
          />
        </>
      ) : (
        <MaterialCommunityIcons
          name="qrcode"
          size={props.imgSize || 56}
          style={styles.cardImage}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  cardImage: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    ...Platform.select({
      web: {
        marginRight: 20,
        borderWidth: 1,
        borderColor: Colors.grayDivider,
      },
      default: {
        marginRight: 16,
      },
    }),
  },
});
