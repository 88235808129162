import { Control } from 'react-hook-form';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { date, string, object as yupObject } from 'yup';
import { Spacing } from '../../styles';
import { LanguagePreferences } from './languagePreferences';
import { NotificationPreferences } from './notificationPreferences';
import { NotificationSchedule } from './notificationSchedule';

type PreferencesFormProps = {
  control: Control<any>;
  isUpdating?: boolean;
};

export const PreferencesForm = (props: PreferencesFormProps) => {
  const isDesktop = Platform.OS === 'web';

  return (
    <ScrollView scrollEnabled={true} showsVerticalScrollIndicator={false}>
      <View style={styles.form}>
        <NotificationPreferences
          control={props.control}
          disabled={props.isUpdating}
        />
        <LanguagePreferences
          control={props.control}
          disabled={props.isUpdating}
        />
        {!isDesktop && (
          <NotificationSchedule
            control={props.control}
            disabled={props.isUpdating}
          />
        )}
      </View>
    </ScrollView>
  );
};

export const PreferencesFormSchema = yupObject({
  notificationPreferences: yupObject()
    .shape({
      schedule: yupObject().shape({
        scheduleDays: string().notRequired(),
        startTime: date().notRequired(),
        endTime: date().notRequired(),
      }),
    })
    .notRequired(),
}).test((preferences) => {
  let valid = true;
  if (
    (preferences?.notificationPreferences?.schedule?.startTime &&
      !preferences?.notificationPreferences?.schedule?.endTime) ||
    (!preferences?.notificationPreferences?.schedule?.startTime &&
      preferences?.notificationPreferences?.schedule?.endTime)
  ) {
    valid = false;
  }
  return valid;
});

const styles = StyleSheet.create({
  form: {
    ...Platform.select({
      default: {
        paddingHorizontal: Spacing.basePadding.paddingHorizontal,
      },
      web: {
        paddingHorizontal: 0,
      },
    }),
  },
});
