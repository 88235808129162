import { Feature, useFeatures, useUpdateFeature } from '@gripp/shared-logic';
import { DataGrid, Select } from '@gripp/shared-ui';
import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';

export const Features = () => {
  const [dataRows, setDataRows] = useState(undefined);

  const { data, isLoading } = useFeatures();
  const { mutate: updateFeature } = useUpdateFeature();

  const queryClient = useQueryClient();

  const statusOptions = [
    { label: 'Disabled', value: false },
    { label: 'Enabled', value: true },
  ];

  const setFeatureEnabled = (enabled: boolean, feature: Feature) => {
    const input = {
      id: feature.id,
      enabled: enabled,
    };

    updateFeature({ input: input });
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: () => 'Name',
    }),
    columnHelper.accessor('status', {
      cell: (info) => (
        <Select
          label={'enabled'}
          items={statusOptions}
          onSelectItem={(value) => setFeatureEnabled(value, info.row.original)}
          dropDownPickerProps={{
            value: data.items.filter((f) => f.id === info.row.original.id)[0]
              .enabled,
            dropDownDirection: 'TOP',
          }}
        />
      ),
      header: () => 'Enabled',
    }),
    columnHelper.accessor('description', {
      cell: (info) => info.getValue(),
      header: () => 'Description',
    }),
  ];

  useEffect(() => {
    if (data) {
      setDataRows(data.items);
    }
  }, [data]);

  return dataRows && !isLoading ? (
    <DataGrid data={dataRows} columns={columns} isLoading={isLoading} />
  ) : (
    <h1>Loading...</h1>
  );
};
