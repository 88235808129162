import { Checkbox } from 'react-native-paper';

enum CheckboxStatus {
  checked = 'checked',
  unchecked = 'unchecked',
  indeterminate = 'indeterminate',
}

export const BaseColumnCheckbox: Record<string, (info: any) => JSX.Element> = {
  checkboxHeader: ({ table }) => {
    return (
      <Checkbox.Item
        label=""
        status={
          table.getIsAllRowsSelected()
            ? CheckboxStatus.checked
            : CheckboxStatus.unchecked
        }
        style={{
          padding: 0,
          margin: 0,
          minHeight: 45,
          top: -10,
        }}
        position="leading"
        rippleColor={'transparent'}
        onPress={() => table.toggleAllRowsSelected()}
      />
    );
  },
  checkbox: ({ row }) => {
    return (
      <Checkbox.Item
        label=""
        status={
          row.getIsSelected()
            ? CheckboxStatus.checked
            : CheckboxStatus.unchecked
        }
        rippleColor={'transparent'}
        onPress={() => row.toggleSelected()}
      />
    );
  },
}
