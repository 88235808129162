import { ActivityFilterKey } from '@gripp/shared-logic';
import { ActivityDateFilter, DropDownView, FilterType } from '@gripp/shared-ui';
import { View } from 'react-native';

type ActivityDateFilterMenuProps = {
  onFilterOpen: () => void;
  onFilterClose: () => void;
  isOpened: boolean;
  anchorRef: React.RefObject<View>;
  filterKey?: ActivityFilterKey;
  type?: FilterType;
};

export const ActivityDateFilterMenu = ({
  onFilterClose,
  onFilterOpen,
  isOpened,
  anchorRef,
  filterKey,
  type,
}: ActivityDateFilterMenuProps) => {
  return (
    <DropDownView
      anchorRef={anchorRef}
      isVisible={isOpened}
      onDismiss={onFilterClose}
      width={370}
    >
      {filterKey && (
        <ActivityDateFilter
          goBack={onFilterClose}
          filterKey={filterKey}
          type={type}
        />
      )}
    </DropDownView>
  );
};
