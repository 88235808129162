import { Asset } from '../../assets';
import { Audit } from '../../auth';
import { IssueType, Severity } from '../../graphql';
import { Workspace } from '../../workspace';

export interface Issue extends Audit {
  id: string;
  severity: Severity;
  title: string;
  type: IssueType;
  asset: Asset;
  workspace: Workspace;
}
