import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../../themes';
import { ControlledTextInput } from '../../forms';

type RoutineAdminInputTextProps = {
  control: Control;
  name: string;
  i18nPrefix: string;
  indexPrefix?: string;
  textArea?: boolean;
};

export const RoutineAdminInputText = ({
  control,
  name,
  i18nPrefix,
  indexPrefix,
  textArea = false,
}: RoutineAdminInputTextProps) => {
  const { t } = useTranslation();
  const fieldName = indexPrefix ? `${indexPrefix}.${name}` : name;

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.label}>{t(`${i18nPrefix}.${name}`)}</Text>
      </View>
      <ControlledTextInput
        control={control}
        name={fieldName}
        textArea={textArea}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
  label: {
    fontSize: 16,
    lineHeight: 16,
    color: Colors.black,
    marginBottom: 10,
    fontWeight: '700',
  },
});
