import { DATE_FORMAT_MMDDYYYY } from '@gripp/shared-logic';
import { t } from 'i18next';
import { View } from 'react-native';
import { DatePickerSelector } from './datePickerSelector';
import { datePickerStyle as styles } from './datePickerStyle';

export type DateRangeSelectorProps = {
  control: any;
  setValue: any;
};

export const DateRangeSelector = (props: DateRangeSelectorProps) => (
  <View style={styles.dateRangeContainer}>
    <DatePickerSelector
      control={props.control}
      setValue={props.setValue}
      name="startDate"
      placeholder={DATE_FORMAT_MMDDYYYY}
      label={t('activity.filter.from')}
      height={16}
      isStartDate
    />
    <DatePickerSelector
      control={props.control}
      setValue={props.setValue}
      name="endDate"
      placeholder={DATE_FORMAT_MMDDYYYY}
      label={t('activity.filter.to')}
      height={16}
    />
  </View>
);
