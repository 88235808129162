import * as Haptics from 'expo-haptics';
import { Colors } from 'libs/shared-ui/src/themes';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';

interface FormState {
  isDirty?: boolean;
  isValid?: boolean;
}

export type CommonSubmitButtonProps = {
  onPress: () => void;
  formState: FormState;
  buttonText?: string;
  isPending?: boolean;
};

export const CommonSubmitButton = (props: CommonSubmitButtonProps) => {
  const { t } = useTranslation();
  const { isDirty, isValid } = props.formState;
  const buttonText = props.buttonText?.toLocaleLowerCase() || 'update';

  const isDisabled =
    isValid === false || isDirty === false || props.isPending === true;

  return (
    <Button
      mode="contained"
      style={[
        styles.submitButton,
        {
          backgroundColor: isDisabled ? Colors.grayFill : Colors.primary,
        },
      ]}
      labelStyle={styles.buttonLabel}
      onPress={() => {
        if (Platform.OS === 'ios') {
          Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
        }
        props.onPress();
      }}
      disabled={isDisabled}
    >
      {t(`routines.${buttonText}`)}
    </Button>
  );
};

const styles = StyleSheet.create({
  submitButton: {
    flex: 1,
    height: 52,
    borderRadius: 12,
    justifyContent: 'center',
  },
  buttonLabel: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '600',
    color: Colors.white,
  },
});
