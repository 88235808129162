import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { Colors } from '@gripp/shared-ui';
import { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { PaperProvider, Text } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';

export const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/activities');
  }, [navigate]);

  return (
    <PaperProvider>
      <View style={styles.container}>
        <Icon name="qrcode-scan" style={styles.icon} />
        <Text style={styles.text}>Gripp Web</Text>
      </View>
    </PaperProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.primary,
    height: 70,
    overflow: 'hidden',
    paddingHorizontal: 25,
    paddingVertical: 25,
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  text: {
    color: Colors.black,
    letterSpacing: 0,
    lineHeight: 28,
    fontWeight: '700',
    textAlign: 'left',
    fontSize: 24,
  },
  icon: {
    color: Colors.black,
    paddingRight: 10,
    fontSize: 30,
  },
});
