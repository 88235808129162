import { useAllWorkspaces } from '@gripp/shared-logic';
import {
  ControlledSelect,
  ControlledTextInputDeprecated as ControlledTextInput,
} from '@gripp/shared-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Button, Divider, Text } from 'react-native-paper';
import { number, string, object as yupObject } from 'yup';
import { FormComponent } from '../../shared/components/forms/mutationForm';

export const CreateTagBatchForm: FormComponent = (props) => {
  const [workspaces, setWorkspaces] = useState<any[]>([]);
  const data = useAllWorkspaces();
  const { t } = useTranslation();

  const formSchema = yupObject({
    quantity: number().required('Quantity is required'),
    workspace: string().required('Workspace is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: props.defaultValues,
  });

  useEffect(() => {
    if (data) {
      setWorkspaces(data.map((w) => ({ label: w.name, value: w.id })));
    }
  }, [data]);

  return (
    <View>
      <View>
        <Text variant="titleLarge">Create Tag Batch</Text>
      </View>
      <Divider />
      <View>
        <ControlledTextInput
          control={control}
          name="quantity"
          label="Quantity"
          autoFocus={true}
          errors={errors}
        />
        <ControlledSelect
          control={control}
          name="workspace"
          label={t('workspace.list.workspace')}
          errors={errors}
          items={workspaces}
        />
        <ControlledTextInput control={control} name="memo" label="Memo" />
        <Button onPress={handleSubmit(props.onSubmit)}>Create Batch</Button>
      </View>
    </View>
  );
};
