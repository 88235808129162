import {
  QuestionMultipleValueExceptionConfig,
  StatusBaseConfig,
  StatusConfig,
} from '@gripp/shared-logic';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { array, string } from 'yup';
import { ControlledTextArrayInput } from '../../forms/controlledTextArrayInput';
import { RoutineAdminForm, RoutineAdminFormProps } from '../types';

export const StatusAdminSchema = {
  statuses: array().of(string()).min(2),
};

export const StatusAdminForm: RoutineAdminForm<StatusConfig> = (
  props: RoutineAdminFormProps<StatusConfig>
) => {
  return <StatusAdminComponent config={props.config} control={props.control} />;
};

type StatusConfigAdminComponentProps = {
  config: StatusBaseConfig;
  control: Control;
  indexPrefix?: string;
  showResponseExceptions?: boolean;
};

export const StatusAdminComponent = (
  props: StatusConfigAdminComponentProps
) => {
  const { t } = useTranslation();

  const fieldName = props.indexPrefix
    ? `${props.indexPrefix}.statuses`
    : 'statuses';

  return (
    <ControlledTextArrayInput
      label={t('routines.status.admin.statuses')}
      addMoreLabel={t('routines.status.admin.addStatus')}
      name={fieldName}
      control={props.control}
      defaultValues={props.config.statuses}
      showResponseExceptions={props.showResponseExceptions}
      exceptionsPrefix={props.indexPrefix}
      defaultExceptionValues={
        props.showResponseExceptions
          ? (props.config as QuestionMultipleValueExceptionConfig)
              .responseExceptions ?? []
          : undefined
      }
    />
  );
};
