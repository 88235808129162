import {
  ActivityFilterKey,
  ActivityFilterModel,
  ActivityInboxType,
  getActivityFilter,
  SortDirectionEnum,
  useActivityListFilter,
  useCurrentWorkspace,
  useFindUnreadActivityInbox,
} from '@gripp/shared-logic';
import { createColumnHelper } from '@tanstack/react-table';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { useParams } from 'react-router-dom';
import { Colors } from '../../themes/colors';
import { InfiniteDataGrid } from '../infiniteDataGrid';
import { IssueCard, IssueCardData } from './issueCard';

export type ActivityListProps = {
  assetId?: string;
  showAssetName?: boolean;
  onIssueSelected?: (id: string) => void;
  onActivityCountChange?: (count: number) => void;
  selectedIssue?: string;
  listMaxHeight?: number;
  showScrollIndicator?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  right?: FC;
  filterKey: ActivityFilterKey;
  textEmpty?: string;
  mobileTableHeader?: JSX.Element;
  excludeFilter?: boolean;
};

export const ActivityList = (props: ActivityListProps) => {
  const { data: filterData, isLoading: isLoadingFilter } =
    useActivityListFilter(props.filterKey);

  const { t } = useTranslation();
  const [filter, setFilter] = useState(undefined);
  const [inbox, setInbox] = useState([]);
  const { issueId } = useParams();

  const { data: inboxData, isLoading: isLoadingInbox } =
    useFindUnreadActivityInbox();

  const workspace = useCurrentWorkspace(true);

  useEffect(() => {
    if (inboxData) {
      setInbox(inboxData.items);
    }
  }, [inboxData]);

  useEffect(() => {
    if (!filterData || workspace === undefined) return;

    // reset filtering only for Recent Activity
    const aFilter = props.excludeFilter
      ? new ActivityFilterModel()
      : filterData;

    const f = getActivityFilter(aFilter, workspace?.id, props.assetId);

    setFilter(f);
  }, [filterData, workspace, props.assetId]);

  const hasUnread = (activity: any) => {
    return inbox.some(
      (i) =>
        (i.type === ActivityInboxType.IssueUpdated ||
          i.type === ActivityInboxType.IssueMessageCreated) &&
        i.data.id === activity.id
    );
  };

  const unreadCount = (activity: any) => {
    return inbox.filter(
      (i) =>
        i.type === ActivityInboxType.IssueMessageCreated &&
        i.data.id === activity.id
    ).length;
  };

  const rowStyle = (row) => {
    return [
      styles.row,
      hasUnread(row.original) ? styles.unread : styles.read,
      row.original.id === issueId && {
        backgroundColor: Colors.blackWithAlpha,
      },
    ];
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('id', {
      cell: ({ row }) => {
        const activity = row.original as IssueCardData;
        return (
          activity && (
            <IssueCard
              activity={activity}
              showAssetName={props.showAssetName}
              newMessageCount={unreadCount(activity)}
              filter={filterData}
            />
          )
        );
      },
      meta: {
        style: styles.cell,
        customRender: true,
      },
    }),
  ];
  if (props.right) {
    const RightComponent = props.right;
    columns.push(
      columnHelper.accessor('type', {
        cell: ({ row }) => {
          const activity = row.original as IssueCardData;
          return activity && <RightComponent activity={activity} />;
        },
        meta: {
          style: styles.right,
          customRender: true,
        },
      })
    );
  }

  const onActivitySelected = (activity) => {
    props.onIssueSelected && props.onIssueSelected(activity.id);
  };

  if (isLoadingFilter || isLoadingInbox) {
    return null;
  }

  return !filter ? null : (
    <InfiniteDataGrid
      columns={columns}
      query={getActivities}
      dataPrefix="issue.findIssues"
      modelName="issue"
      onRowClick={onActivitySelected}
      orderBy={{
        field: 'updatedAt',
        order: SortDirectionEnum.Desc,
      }}
      pageSize={50}
      filter={filter}
      hideHeader={true}
      refetchOnFocus={true}
      listMaxHeight={props.listMaxHeight}
      showScrollIndicator={props.showScrollIndicator}
      containerStyle={props.containerStyle}
      rowStyle={rowStyle}
      textEmpty={t('activity.textEmpty')}
      onItemsCountChange={props.onActivityCountChange}
      enableScrollRefresh={true}
      mobileTableHeader={props.mobileTableHeader}
    />
  );
};

const getActivities = `
  query FindIssues($filter: FindIssuesFilter, $page: PageRequest, $orderBy: OrderByInput) {
    issue {
      findIssues(filter: $filter, page: $page, orderBy: $orderBy) {
        count
        offset
        limit
        items {
          id
          severity
          title
          asset {
            name
          }
          workspace {
            name
          }
          createdAt
          createdBy {
            id
            name {
              first
              last
            }
          }
          updatedAt
          updatedBy {
            id
            name {
              first
              last
            }
          }
          latestMessage {
            text
            i18n
            createdBy {
              id
              name {
                first
                last
              }
            }
            userMentions {
              id
              name {
                first
                last
              }
            }
            reactions {
              id
              createdAt
              createdBy 
              reactionType
            }
            type
          }
        }
      }
    }
  }
`;

const styles = StyleSheet.create({
  cell: {
    alignSelf: 'stretch',
    alignContent: 'stretch',
    width: '100%',
  },
  row: {
    paddingLeft: 0,
    paddingRight: 0,
    borderBottomWidth: 1,
    borderBottomColor: Colors.secondaryGrayHeader,
  },
  right: {
    maxWidth: 5,
  },
  read: {
    backgroundColor: Colors.white,
  },
  unread: {
    backgroundColor: Colors.unreadBackground,
  },
});
