import { Colors, StyledTextInput } from '@gripp/shared-ui';
import React, { useCallback, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { string, object as yupObject } from 'yup';

const isoDatePattern =
  /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})?)?$/;
const currentYear = new Date().getUTCFullYear();
export const DateRangeValidationSchema = {
  dateRange: yupObject({
    start: string().optional().matches(isoDatePattern),
    end: string().optional().matches(isoDatePattern),
  }).test(({ start, end }) => {
    return (start !== undefined) === (end !== undefined);
  }),
};

//Uncomment code when ready to update Binary to add calendar picker
//Install date-fns and react-native-calendar-picker + types/react-native-calendar-picker as dev dependency

type DateRangePickerProps = {
  control: any;
  name: string;
  outlined?: boolean;
};
type DatePickerProps = {
  value: string;
  // onDatePicked: (date: Date) => void;
  onChangeText: (text: string) => void;
  outlined?: boolean;
};
type RangePart = 'start' | 'end';

const DEFAULT_DATE_RANGE = {
  start: undefined,
  end: undefined,
};
export const DateRangePicker = (props: DateRangePickerProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => {
        const dateRange = value || DEFAULT_DATE_RANGE;
        const handleChangeText = (text: string, part: RangePart) => {
          if (text === '') {
            onChange({ ...dateRange, [part]: undefined });
            return;
          }
          if (!isAllowedText(text)) return;
          const date = parseDateToISOString(text);
          onChange({ ...dateRange, [part]: date ?? text });
        };
        // const handleDatePicked = (date: Date, part: RangePart) => {
        //   onChange({ ...dateRange, [part]: date.toISOString() });
        // };

        return (
          <View style={styles.dateRangeContainer}>
            <DatePicker
              value={getTextValue(dateRange?.start)}
              onChangeText={(text) => handleChangeText(text, 'start')}
              // onDatePicked={(date) => handleDatePicked(date, 'start')}
              outlined={props.outlined}
            />
            <Text style={styles.dateRangeText}>and</Text>
            <DatePicker
              value={getTextValue(dateRange?.end)}
              onChangeText={(text) => handleChangeText(text, 'end')}
              // onDatePicked={(date) => handleDatePicked(date, 'end')}
              outlined={props.outlined}
            />
          </View>
        );
      }}
    />
  );
};

const DatePicker = (props: DatePickerProps) => {
  // const calendarRef = useRef();
  // const [showCalendar, setShowCalendar] = useState(false);
  // useOutsideClick(calendarRef, showCalendar, () => setShowCalendar(false));
  // const handleDatePicked = (date: Date) => {
  //   props.onDatePicked(date);
  //   setShowCalendar(false);
  // };
  return (
    <View>
      <StyledTextInput
        placeholder="MM/DD"
        onBlur={() => {}}
        value={props.value}
        onChangeText={props.onChangeText}
        height={52}
        width={244}
        outlinedTextInput={props.outlined}
        // right={
        //   <TextInput.Icon
        //     icon="calendar"
        //     size={24}
        //     color={Colors.primaryDark}
        //     onPress={() => setShowCalendar(true)}
        //   />
        // }
      />
      {/* {showCalendar && (
        <View style={styles.calendarContainer} ref={calendarRef}>
          <CalendarPicker
            onDateChange={handleDatePicked}
            width={244}
            minDate={new Date(new Date().getFullYear(), 0, 1)}
            maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
          />
        </View>
      )} */}
    </View>
  );
};

const isAllowedText = (text: string) => {
  return /^(0?[1-9]|1[012])(\/(0?[1-9]|[12][0-9]|3[01])?)?$/.test(text);
};

const getTextValue = (value: string) => {
  if (!value) return '';
  if (!isoDatePattern.test(value)) return value;

  const date = new Date(value);
  const month = (date.getUTCMonth() + 1).toString();
  const day = date.getUTCDate().toString();
  return `${month}/${day}`;
};

const parseDateToISOString = (text: string) => {
  if (!text || text.length < 3) return;

  const dateParts = text.split('/');
  if (dateParts.length < 2) return;

  const month = parseInt(dateParts[0]);
  const day = parseInt(dateParts[1]);
  if (!isNaN(month) && !isNaN(day))
    return new Date(currentYear, month - 1, day).toISOString();
};

const useOutsideClick = (ref, isOpen, callback) => {
  const handleClick = useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    },
    [ref, callback]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
    }
  }, [isOpen, handleClick]);
};

const styles = StyleSheet.create({
  dateRangeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dateRangeText: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.blackText,
  },
  calendarContainer: {
    position: 'absolute',
    zIndex: 200,
    top: 57,
    backgroundColor: Colors.white,
    borderRadius: 8,
    boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.15)',
  },
});
