/* eslint-disable @typescript-eslint/no-explicit-any */
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  InfiniteFindByQueryOptions,
  OrderByInput,
  SortDirectionEnum,
  useGrippContext,
  useInfiniteFindByQuery,
} from '@gripp/shared-logic';
import {
  ColumnDef,
  Row,
  RowSelectionState,
  TableState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CursorValue,
  DimensionValue,
  Keyboard,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  RefreshControl,
  ScrollView,
  StyleProp,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import { ActivityIndicator, DataTable, Text } from 'react-native-paper';
import { LoadingIndicator } from '../components/loadingIndicator';
import { useScrollXPosition } from '../hooks';
import { Colors } from '../themes';
import { DataGridExportButton } from './dataGridExport';

export type InfiniteDataGridProps = InfiniteFindByQueryOptions & {
  columns: ColumnDef<any, any>[];
  onRowClick?: (row: any) => void;
  listMaxHeight: number;
  paddingBottom: number;
  hideHeader?: boolean;
  showScrollIndicator?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  rowStyle?: (row: any) => StyleProp<ViewStyle>;
  textEmpty?: string;
  onItemsCountChange?: (count: number) => void;
  dataPrefix?: string;
  onOrderByChange?: (order: OrderByInput) => void;
  updateRowsSelected?: (rows: Array<any>) => void;
  isRowSelected?: (row: any) => boolean;
  enableScrollRefresh?: boolean;
  rightButtonSelectedId?: (id: string | undefined) => void;
  rightButtonHoverId?: (id: string | undefined) => void;
  preserveScrollKey?: string;
  initialState?: Partial<TableState>;
  headerContent?: JSX.Element;
  exportFileName?: string;
  mobileTableHeader?: JSX.Element;
};

interface CustomColumnMeta {
  style?: ViewStyle;
  customRender?: boolean;
  sortable?: boolean;
}

type CustomColumnDef = ColumnDef<any, any> & {
  accessorKey?: string;
  meta?: CustomColumnMeta;
};

interface ScrollPreservationData {
  itemId: string;
  scrollPosition: number;
}

type QueryData = {
  data: {
    pages: {
      count: number;
      limit: number;
      offset: number;
      items: never[];
    }[];
    pageParams: {
      offset: number;
    }[];
  };
  isLoading: boolean;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: any;
  refetch: any;
};

export const InfiniteDataGrid = (props: InfiniteDataGridProps) => {
  const [dataRows, setDataRows] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState<OrderByInput>(props.orderBy);
  const { t } = useTranslation();
  const [isHoverStyle, setIsHoverStyle] = useState(false);
  const [rowHoverId, setRowHoverId] = useState<number | null>(null);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [refreshing, setRefreshing] = useState(false);
  const [rowSelectedId, setRowSelectedId] = useState<string | undefined>(
    undefined
  );
  const [scrollPosition, setScrollPosition] = useState(0);
  const { storageHandler } = useGrippContext();

  const VScrollRef = useRef<ScrollView | null>(null);
  const HScrollRef = useRef<ScrollView | null>(null);

  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteFindByQuery({
    ...props,
    ...{ orderBy },
  }) as QueryData;
  const { onUpdatePositionX, onUpdateHasUpdatedData } = useScrollXPosition(
    isLoading,
    dataRows,
    HScrollRef
  );

  useEffect(() => {
    if (
      props.orderBy?.field !== orderBy?.field ||
      props.orderBy?.order !== orderBy?.order
    ) {
      setOrderBy(props.orderBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.orderBy]);

  useEffect(() => {
    if (data) {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy]);

  useEffect(() => {
    if (data?.pages) {
      props.onItemsCountChange && props.onItemsCountChange(data.pages[0].count);
    }
  }, [data, props]);

  // restore scroll position, if requested
  useEffect(() => {
    const restoreScrollPosition = async () => {
      if (!props.preserveScrollKey) return;

      const scrollDataString = await storageHandler.getItem(
        props.preserveScrollKey
      );

      let yOffset = 0;
      if (scrollDataString) {
        const scrollData = JSON.parse(
          scrollDataString
        ) as ScrollPreservationData;
        yOffset = scrollData.scrollPosition;
      }

      setTimeout(() => {
        VScrollRef.current?.scrollTo({ y: yOffset, animated: false });
      }, 300);
    };
    restoreScrollPosition();
  }, [props.preserveScrollKey, storageHandler]);

  const table = useReactTable({
    data: dataRows,
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
      ...props.initialState,
    },
  });

  useEffect(() => {
    if (props.updateRowsSelected && Object.keys(rowSelection).length > 0) {
      const selectedRows = table.getSelectedRowModel().flatRows.map((row) => {
        return row.original.id;
      });

      selectedRows && props.updateRowsSelected(selectedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  const onScrollEnd = () => {
    if (hasNextPage && !isFetchingNextPage) {
      setPage(currentPage + 1);
      fetchNextPage();
    }
  };

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: NativeScrollEvent) => {
    const paddingToBottom = 20;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  const sortColumn = (column: CustomColumnDef) => {
    if (!column.meta?.sortable) {
      return;
    }

    const field = column.accessorKey || '';
    let order = SortDirectionEnum.Desc;

    if (orderBy?.field === field && orderBy.order === SortDirectionEnum.Desc) {
      order = SortDirectionEnum.Asc;
    }

    setOrderBy({ field, order });
    props.onOrderByChange && props.onOrderByChange({ field, order });
    onUpdateHasUpdatedData(true);
  };

  const renderSortArrow = (column: CustomColumnDef) => {
    if (orderBy?.field === column.accessorKey) {
      return orderBy.order === SortDirectionEnum.Asc ? (
        <MaterialCommunityIcons name="arrow-down" size={16} />
      ) : (
        <MaterialCommunityIcons name="arrow-up" size={16} />
      );
    }
    return null;
  };

  useMemo(() => {
    if (data) {
      const items = [].concat(...data.pages.map((page) => page.items));
      setDataRows(items);
    }

    // reset row selections on any change with table data (ex: sort and filter)
    if (data && data.pages.length < 2 && props.updateRowsSelected) {
      if (props.isRowSelected) {
        const items = [].concat(...data.pages.map((page) => page.items));
        const newSelection: RowSelectionState = {};

        for (var i = 0; i < items.length; i++) {
          newSelection[i] = props.isRowSelected(items[i]);
        }

        setRowSelection(newSelection);
      } else {
        setRowSelection({});
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [containerWidth, setContainerWidth] = useState<DimensionValue>('100%');
  const [hScrollViewWidth, setHScrollViewWidth] = useState(0);

  const handleLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setHScrollViewWidth(width);
  };

  useEffect(() => {
    if (hScrollViewWidth && props.columns) {
      const totalSize = table.getTotalSize();
      if (totalSize > hScrollViewWidth) {
        setContainerWidth(totalSize);
      } else {
        setContainerWidth('100%');
      }
    }
  }, [hScrollViewWidth, props.columns, table]);

  const onRefresh = () => {
    setRefreshing(true);
    refetch().then(() => {
      setRefreshing(false);
    });
  };

  const onRowClick = async (row: Row<any>) => {
    if (props.preserveScrollKey) {
      const scrollData: ScrollPreservationData = {
        itemId: row.original.id,
        scrollPosition: scrollPosition,
      };

      await storageHandler.setItem(
        props.preserveScrollKey,
        JSON.stringify(scrollData)
      );
    }
    props.onRowClick && props.onRowClick(row.original);
  };

  const handleScroll = (nativeEvent: NativeScrollEvent, refName: string) => {
    if (refName === 'hScroll') {
      onUpdatePositionX(nativeEvent.contentOffset.x);
    }

    if (isCloseToBottom(nativeEvent)) {
      onScrollEnd();
    }
    if (props.preserveScrollKey) {
      setScrollPosition(nativeEvent.contentOffset.y);
    }
  };

  const TableHeader: FC<{ position: string }> = ({ position }) => {
    const headers = table.getFlatHeaders();

    return (
      <DataTable.Header
        style={[
          styles.header,
          {
            width: containerWidth,
          },
        ]}
      >
        {headers.map((header) => {
          return (
            <DataTable.Title
              onPress={() =>
                sortColumn(header.column.columnDef as CustomColumnDef)
              }
              key={header.id}
              style={[
                header.id === 'selected' && styles.checkboxHeaderContainer,
                {
                  cursor: (header.column.columnDef.meta as CustomColumnMeta)
                    ?.sortable
                    ? 'pointer'
                    : ('default' as CursorValue),
                },
              ]}
            >
              <Text style={styles.titleText}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </Text>
              {renderSortArrow(header.column.columnDef as CustomColumnDef)}
            </DataTable.Title>
          );
        })}
      </DataTable.Header>
    );
  };

  const renderResult = (isShown = false) => {
    if (!data || !data.pages || !data.pages[0]) {
      return null;
    }

    const count = data.pages[0].count;

    return count !== undefined && count !== null && isShown ? (
      <Text style={styles.resultLabel}>
        {count} {t('activity.topBar.results')}
      </Text>
    ) : null;
  };

  return isLoading ? (
    <LoadingIndicator />
  ) : !dataRows?.length ? (
    <View
      style={[
        styles.noResultsContainer,
        props.containerStyle,
        styles.container,
      ]}
    >
      <Text style={styles.emptyRow}>
        {props?.textEmpty ? props?.textEmpty : t('textEmptyList')}
      </Text>
    </View>
  ) : (
    <View style={[styles.container, props.containerStyle]}>
      {(Platform.OS === 'web' || !props.hideHeader) && (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <View style={styles.tableHeaderContainer}>
            {renderResult(true)}
            {props.headerContent && props.headerContent}
            {props.exportFileName && Platform.OS === 'web' && (
              <View style={{ marginLeft: 'auto' }}>
                <DataGridExportButton
                  fileName={props.exportFileName}
                  query={props.query}
                  filter={props.filter}
                  orderBy={props.orderBy}
                  dataPrefix={props.dataPrefix!}
                  columns={props.columns}
                />
              </View>
            )}
          </View>
        </TouchableWithoutFeedback>
      )}
      <DataTable>
        <View
          key={'tableView'}
          style={{
            maxHeight: props.listMaxHeight,
          }}
        >
          <ScrollView
            horizontal
            scrollEventThrottle={16}
            key={'HScrollView'}
            ref={HScrollRef}
            showsHorizontalScrollIndicator={true}
            onLayout={handleLayout}
            onScroll={({ nativeEvent }) => handleScroll(nativeEvent, 'hScroll')}
            onTouchStart={(e) => e.stopPropagation()}
            contentContainerStyle={{
              width: containerWidth,
              flexDirection: 'column',
            }}
          >
            {!props.hideHeader && <TableHeader position="center" />}
            <ScrollView
              ref={VScrollRef}
              contentContainerStyle={[
                styles.scrollView,
                {
                  maxHeight: props.listMaxHeight,
                  paddingBottom: props.paddingBottom,
                },
              ]}
              showsVerticalScrollIndicator={props.showScrollIndicator}
              scrollEventThrottle={10}
              refreshControl={
                props.enableScrollRefresh === true ? (
                  <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                  />
                ) : undefined
              }
              onScroll={(event: NativeSyntheticEvent<NativeScrollEvent>) => {
                handleScroll(event.nativeEvent, 'vScroll');
              }}
            >
              {Platform.OS !== 'web' && props.hideHeader && (
                <View style={styles.tableHeaderContainer}>
                  {props.mobileTableHeader !== undefined
                    ? props.mobileTableHeader
                    : renderResult(true)}
                </View>
              )}
              {table.getRowModel().rows.map((row) => (
                <DataTable.Row
                  style={[
                    (props.rowStyle && props.rowStyle(row)) || styles.row,
                    isHoverStyle && rowHoverId === row.index && styles.hover,
                    props.rightButtonSelectedId &&
                      rowSelectedId === row.original.id &&
                      styles.selected,
                  ]}
                  key={row.id}
                  onPress={() => {
                    onRowClick(row);
                    props.rightButtonSelectedId &&
                      props.rightButtonSelectedId(row.original.id);
                    setRowSelectedId(row.original.id);
                  }}
                  onHoverIn={() => {
                    setIsHoverStyle(true);
                    setRowHoverId(Number(row.id));

                    if (Number(row.id) === row.index) {
                      props.rightButtonHoverId &&
                        props.rightButtonHoverId(row.original.id);
                    }
                  }}
                  onHoverOut={() => {
                    setIsHoverStyle(false);
                    setRowHoverId(null);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <DataTable.Cell
                      key={cell.id}
                      style={[
                        (cell.column.columnDef.meta as CustomColumnMeta)?.style,
                      ]}
                    >
                      {(cell.column.columnDef.meta as CustomColumnMeta)
                        ?.customRender ? (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      ) : (
                        <>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </>
                      )}
                    </DataTable.Cell>
                  ))}
                </DataTable.Row>
              ))}
            </ScrollView>
          </ScrollView>
        </View>
      </DataTable>
      {isFetchingNextPage && (
        <ActivityIndicator animating={true} size="large" />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: Colors.primaryGrayHeader,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottomWidth: 0,
    ...Platform.select({
      web: {
        paddingTop: 0,
        marginTop: 0,
        height: 'auto',
        paddingRight: 31,
      },
      default: {
        paddingLeft: 20,
        height: 44,
      },
    }),
  },
  hover: {
    backgroundColor: Colors.secondaryGrayHeader,
  },
  selected: {
    backgroundColor: Colors.unreadBackground,
  },
  checkboxHeaderContainer: {
    height: '100%',
    maxWidth: 50,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    ...Platform.select({
      ios: {
        lineHeight: 20,
        fontWeight: '500',
      },
      android: {
        lineHeight: 20,
        fontWeight: '700',
      },
      web: {
        fontWeight: '500',
      },
    }),
    fontSize: 16,
    color: Colors.blackText,
    letterSpacing: -0.2,
  },
  row: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.secondaryGrayHeader,
  },
  scrollView: {
    flexGrow: 1,
  },
  noResultsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 150,
    height: '100%',
    width: '100%',
  },
  emptyRow: {
    textAlign: 'center',
    paddingVertical: 8,
    fontSize: 17,
    fontWeight: '600',
  },
  tableHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 50,
    paddingHorizontal: 16,
    ...Platform.select({
      web: {
        backgroundColor: Colors.secondaryGrayHeader,
      },
      default: {
        backgroundColor: Colors.white,
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderColor: Colors.secondaryGrayHeader,
      },
    }),
  },
  resultLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.grayText,
    letterSpacing: -0.25,
  },
});
