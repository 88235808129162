import { useFindByQuery } from '../../graphql/hooks';
import { useCurrentAccount } from './useCurrentAccount';

export const useIsAccountDeleted = () => {
  const { account } = useCurrentAccount();

  const result = useFindByQuery({
    modelName: 'deleteAccount',
    query: FIND_DELETE_ACCOUNTS,
    variables: {
      accountId: account?.id,
    },
    options: {
      enabled: !!account?.id,
    },
  });

  return {
    isLoading: result.isLoading,
    isDeleted: result.data?.items?.length > 0,
  };
};

const FIND_DELETE_ACCOUNTS = `
  query findDeleteAccounts($accountId: ID!) {
    findDeleteAccounts(filter: {
      createdBy: { eq: $accountId}
    }) {
      items {
        id
      }
    }
  }
`;
