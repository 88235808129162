import { MaterialCommunityIcons } from '@expo/vector-icons';
import { MediaType, cacheKeys, useWorkspaceAsset } from '@gripp/shared-logic';
import { AssetHeader, Colors, useResponsiveDesign } from '@gripp/shared-ui';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { ActivityFold } from '../../activity/components/activityFold';
import { NewIssueModal, NewIssueModalProps } from '../../issues';
import { RoutinesAssignedListModal } from '../../routines/components/routinesAssignedListModal';
import { AssetActionButtonsWrapper } from '../../shared/components/assetActionButtonsWrapper';
import { useWindowDimensions } from '../../shared/hooks/useWindowDimensions';
import { GroupsHeader } from './groupsHeader';

export const AssetCard = ({ assetId }: { assetId: string }) => {
  const [issueModalVisible, setIssueModalVisible] = useState(false);
  const [newIssueModalProps, setNewIssueModalProps] = useState<
    NewIssueModalProps | undefined
  >(undefined);
  const { t } = useTranslation();
  const { workspaceAsset, invalidateQuery: invalidateWorkspaceAsset } =
    useWorkspaceAsset(assetId);
  const workspaceId = workspaceAsset?.workspace?.id;
  const navigate = useNavigate();
  const windowDimensions = useWindowDimensions();
  const isResponsiveDesign = useResponsiveDesign();
  const maxHeight =
    windowDimensions.height && windowDimensions.height - (136 + 165);
  const [routinesModalOpened, setRoutinesModalOpened] = useState(false);
  const queryClient = useQueryClient();

  const invalidateIssues = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: cacheKeys('issue').all });
  }, [queryClient]);

  const onRoutineCompleted = useCallback(async () => {
    setRoutinesModalOpened(false);
    await invalidateWorkspaceAsset();
    await invalidateIssues();
  }, [invalidateWorkspaceAsset, invalidateIssues]);

  const onAddNew = () => {
    setNewIssueModalProps({
      assetId: assetId,
      workspaceId: workspaceId!,
      isOpened: issueModalVisible,
      modalType: 'modal',
      onComplete: onIssueComplete,
    });
    setIssueModalVisible(true);
  };

  const onIssueComplete = () => {
    setIssueModalVisible(false);
  };

  const onIssueSelected = (issueId: string) => {
    navigate(`/assets/${assetId}/activities/${issueId}`);
  };

  const viewAssetInfoLink = (
    <Pressable
      style={styles.viewAssetButton}
      onPress={() => {
        navigate(`/assets/${assetId}/edit`);
      }}
    >
      <Text style={styles.viewAssetButtonLabel}>
        {`${t('topNav.moreAssetInfo')}`}
      </Text>
      <MaterialCommunityIcons
        size={24}
        color={Colors.primaryDark}
        name="chevron-right"
        style={{
          marginLeft: -4,
          textAlign: 'justify',
        }}
      />
    </Pressable>
  );

  return (
    workspaceAsset !== undefined && (
      <>
        <View
          style={[styles.container, { height: isResponsiveDesign ? 120 : 145 }]}
        >
          <View style={[styles.leftContainer, styles.backgroundBase]}>
            <AssetHeader
              workspaceAsset={workspaceAsset!}
              additionalHeaderBlock={viewAssetInfoLink}
              assetDetailsContainerStyle={styles.assetDetailsContainerStyle}
              imgSize={isResponsiveDesign ? 85 : 112}
            />
          </View>
          <View
            style={[
              styles.rightContainer,
              isResponsiveDesign
                ? { gap: 8, width: '52%' }
                : { gap: 24, width: '55%' },
            ]}
          >
            <View style={[styles.subContainer, styles.backgroundBase]}>
              <GroupsHeader workspaceAsset={workspaceAsset!} />
            </View>
            <View style={[styles.subContainer, styles.backgroundBase]}>
              <AssetActionButtonsWrapper
                buttonContainerStyle={styles.buttonContainer}
                buttonStyle={styles.button}
                onAddNew={onAddNew}
                onRoutinesButton={() => setRoutinesModalOpened(true)}
                buttonRoutines={styles.button}
              />
            </View>
          </View>
        </View>
        <ActivityFold
          assetId={assetId}
          onIssueSelected={onIssueSelected}
          showAssetName={false}
          maxHeight={maxHeight}
        />
        {newIssueModalProps && (
          <NewIssueModal
            {...newIssueModalProps}
            isOpened={issueModalVisible}
            acceptedMediaTypes={[MediaType.Image, MediaType.Document]}
          />
        )}
        <RoutinesAssignedListModal
          isOpened={routinesModalOpened}
          onCompleteRoutines={onRoutineCompleted}
          onCancel={() => setRoutinesModalOpened(false)}
          workspaceAsset={workspaceAsset!}
        />
      </>
    )
  );
};

const styles = StyleSheet.create({
  backgroundBase: {
    backgroundColor: Colors.white,
    borderRadius: 12,
    height: '100%',
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
  },
  leftContainer: {
    width: '45%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  assetDetailsContainerStyle: {
    height: 112,
    justifyContent: 'space-between',
  },
  rightContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 24,
  },
  viewAssetButton: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 24,
    marginTop: 5,
    paddingVertical: 0,
    marginVertical: 0,
  },
  viewAssetButtonLabel: {
    fontSize: 12,
    fontWeight: '500',
    color: Colors.primaryDark,
    lineHeight: 19,
    marginRight: 0,
    padding: 0,
  },
  subContainer: {
    flex: 1 / 2,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'hidden',
  },
  buttonContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 8,
  },
  responsiveButtonRoutines: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 36,
    borderRadius: 8,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 32,
    borderRadius: 8,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
  },
  assetInfoButton: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  assetInfoButtonLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.primaryDark,
    lineHeight: 16,
  },
});
