import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Controller } from 'react-hook-form';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { Colors } from '../../themes/colors';

type ButtonOption = {
  label?: string;
  value: string;
  icon?: {
    style: ViewStyle;
    name: string;
    size?: number;
    color?: string;
  };
};

type ControlledSelectButtonProps = {
  control: any;
  name: string;
  options: ButtonOption[];
  horizontal?: boolean;
};

export const ControlledSelectButtons = ({
  control,
  name,
  options,
  horizontal = false,
}: ControlledSelectButtonProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={options?.[0]?.value}
      render={({ field: { onChange, value } }) => (
        <View
          style={horizontal ? styles.horizontalContainer : styles.container}
        >
          {options.map((option) => (
            <TouchableOpacity
              key={option.value}
              style={[styles.button, option.value === value && styles.active]}
              onPress={() => onChange(option.value)}
            >
              {option?.icon && (
                <View style={[styles.iconContainer, option.icon.style]}>
                  <MaterialCommunityIcons
                    name={
                      option.icon.name as React.ComponentProps<
                        typeof MaterialCommunityIcons
                      >['name']
                    }
                    size={option.icon.size || 12}
                    color={option.icon.color || Colors.white}
                  />
                </View>
              )}
              <Text style={styles.buttonText}>{option.label}</Text>
            </TouchableOpacity>
          ))}
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    gap: 12,
  },
  horizontalContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 12,
  },
  button: {
    borderWidth: 1,
    borderColor: Colors.grayFill,
    borderRadius: 100,
    paddingHorizontal: 20,
    paddingVertical: 12,
    backgroundColor: Colors.white,
    flexDirection: 'row',
    gap: 12,
    alignItems: 'center',
  },
  buttonText: {
    color: Colors.black,
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 16,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxHeight: 40,
    paddingRight: 16,
    marginBottom: 16,
  },
  active: {
    borderWidth: 2,
    borderColor: Colors.primary,
  },
  iconContainer: {
    width: 16,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
});
