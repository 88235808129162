import { useWindowDimensions } from './useWindowDimensions';

export const useMaxHeight = (
  scrollViewOffset = 44,
  maxHeight = 725,
  windowOffset = 136
) => {
  const windowDimensions = useWindowDimensions();
  const containerMaxHeight = windowDimensions?.height
    ? windowDimensions.height - windowOffset
    : maxHeight;

  return {
    containerMaxHeight,
    listMaxHeight: containerMaxHeight - scrollViewOffset,
  };
};
