import { FeatureName } from '../../graphql';
import { useEffect, useState } from 'react';
import { Feature } from '../domain';
import { useFeatures } from './useFeatures';

export const useIsFeatureEnabled = (featureName: FeatureName): Boolean => {
  const [features, setFeatures] = useState<Feature[]>([]);

  const { data } = useFeatures();

  useEffect(() => {
    if (data) {
      setFeatures(data.items);
    }
  }, [data]);

  if (!features || features.length === 0) {
    return false;
  }

  return features.filter((f) => f.name === featureName)[0]?.enabled;
};

export const useIsFeatureEnabledFunc = () => {
  const [features, setFeatures] = useState<Feature[]>([]);
  const { data } = useFeatures();
  useEffect(() => {
    if (data) {
      setFeatures(data.items);
    }
  }, [data]);

  const isFeatureEnabled = (featureName: FeatureName): Boolean => {
    if (!features || features.length === 0) {
      return false;
    }

    return features.filter((f) => f.name === featureName)[0]?.enabled;
  };

  return isFeatureEnabled;
};
