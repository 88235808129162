import { useEffect, useState } from 'react';
import { useStorageKeys } from '../../storage';
import { useCurrentAccount } from './useCurrentAccount';
import { useQuery } from '@tanstack/react-query';
import { useGrippContext } from '../../grippContext';

export type useCurrentWorkspaceHook = (storageOnly?: boolean) => any;

export const useCurrentWorkspace = (storageOnly?: boolean) => {
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();
  const [workspace, setWorkspace] = useState<any>(undefined);
  const { account, isFetching } = useCurrentAccount();

  const { data } = useQuery<string | null>({
    queryKey: ['currentWorkspace'],
    queryFn: async () => {
      return JSON.parse(
        (await storageHandler.getItem(storageKeys.workspace)) as string
      );
    },
  });

  useEffect(() => {
    if (data) {
      setWorkspace(data);
    } else if (account) {
      if (isFetching || (account.isGlobalAdmin() && storageOnly)) {
        setWorkspace(null);
      } else {
        setWorkspace(account.workspace);
      }
    }
  }, [account, data, isFetching]);

  return workspace;
};

/* 
  for queries: 
    - if local storage is set, use that
    - else,
        if global admin, do nothing
          else, use account workspace
*/
