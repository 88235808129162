import { RoutineConfig, RoutineType } from '@gripp/shared-logic';
import {
  ControlledCheckboxItem,
  FormContext,
  RoutineAdminInputText,
  RoutineModules,
  SaveFormBottom,
} from '@gripp/shared-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { boolean, string, object as yupObject } from 'yup';
type RoutineAdminFormProps = {
  type: RoutineType;
  config: RoutineConfig;
  saveButtonText: string;
  onSubmit: (data: any) => Promise<void>;
};

export const RoutineEditForm = (props: RoutineAdminFormProps) => {
  const i18nPrefix = 'routines.admin';
  const module = RoutineModules[props.type];
  const AdminForm = module.adminForm ? module.adminForm : () => <></>;
  const { t } = useTranslation();

  const schemaObject = {
    title: string().required(),
    routineButtonText: string().required(),
    issuePrefix: string().required(),
    reportOnException: boolean().optional(),
    ...module.adminSchema,
  };

  if (props.type !== RoutineType.Workflow) {
    schemaObject.instructions = string().required();
  }

  const formSchema = yupObject(schemaObject);

  const formDefaultValues = useMemo(() => {
    const defaultValues: any = {
      ...props.config,
    };
    if (props.type !== RoutineType.Workflow) {
      defaultValues.instructions = defaultValues.instructions ?? '';
    }

    return defaultValues;
  }, [props.type]);

  const formMethods = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: formDefaultValues,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const onSubmit = async (data: any) => {
    await props.onSubmit(data).then(() => {
      reset(data);
    });
  };

  return (
    <FormContext.Provider value={formMethods}>
      <ScrollView>
        <View>
          <SaveFormBottom
            onSubmit={handleSubmit(onSubmit)}
            saveLabel={props.saveButtonText}
            isValid={isValid}
            isDirty={isDirty}
            buttonStyle={styles.saveButton}
          >
            <View style={styles.typeRow}>
              <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                <View style={{ flexDirection: 'row' }}>
                  <Text>{t('routines.admin.type')}: </Text>
                  <Text style={styles.type}>
                    {t(`routines.types.${props.type}`)}
                  </Text>
                </View>
                {props.type === RoutineType.Workflow && (
                  <View>
                    <ControlledCheckboxItem
                      control={control}
                      name="reportOnException"
                      label={t('routines.admin.reportOnException')}
                      position="trailing"
                      style={{ marginRight: 0, paddingRight: 0 }}
                    />
                  </View>
                )}
              </View>
            </View>
            <RoutineAdminInputText
              control={control}
              name="title"
              i18nPrefix={i18nPrefix}
            />
            <RoutineAdminInputText
              control={control}
              name="routineButtonText"
              i18nPrefix={i18nPrefix}
            />
            <RoutineAdminInputText
              control={control}
              name="issuePrefix"
              i18nPrefix={i18nPrefix}
            />
            {props.type !== RoutineType.Workflow && (
              <RoutineAdminInputText
                control={control}
                name="instructions"
                i18nPrefix={i18nPrefix}
                textArea={true}
              />
            )}
            <AdminForm control={control} config={props.config} />
          </SaveFormBottom>
        </View>
      </ScrollView>
    </FormContext.Provider>
  );
};

const styles = StyleSheet.create({
  typeRow: {
    flexDirection: 'row',
    marginBottom: 10,
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
  },
  type: {
    fontWeight: '700',
  },
  saveButton: {
    alignSelf: 'flex-end',
    marginTop: 20,
  },
});
