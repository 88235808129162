export enum CountryCode {
  US = 'US',
  MX = 'MX',
  CA = 'CA',
}

export enum CallingCode {
  US = '+1',
  MX = '+52',
  CA = '+1',
}

export interface Phone {
  countryCode: CountryCode;
  callingCode: CallingCode;
  phoneNumber: string;
}
