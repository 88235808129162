import {
  WorkflowQuestionConfig,
  WorkflowResponseState,
} from '@gripp/shared-logic';
import { useMemo } from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { routineMessageStyles } from '../../components/routineMessageStyles';
import { RoutineQuestionModules } from '../questions/routineQuestionModules';
import { RoutineQuestionMessageProps } from '../questions/types';

type WorkflowMessageQuestionHeaderProps<
  C extends WorkflowQuestionConfig,
  S extends WorkflowResponseState
> = RoutineQuestionMessageProps<C, S> & {
  index: number;
};

export const WorkflowMessageQuestion = (
  props: WorkflowMessageQuestionHeaderProps<any, any>
) => {
  const module = useMemo(() => {
    return RoutineQuestionModules[
      props.question.type as keyof typeof RoutineQuestionModules
    ];
  }, [props.question.type]);
  const Message = useMemo(() => module.message, [module]);
  const hasExceptions = useMemo(
    () => props.response.exceptionCount && props.response.exceptionCount > 0,
    [props.response.exceptionCount]
  );

  return (
    <View
      style={[
        routineMessageStyles.questionContainer,
        hasExceptions
          ? routineMessageStyles.exceptionsContainer
          : routineMessageStyles.noExceptionsContainer,
      ]}
    >
      <Text
        style={
          hasExceptions
            ? routineMessageStyles.questionLabelException
            : routineMessageStyles.questionLabel
        }
      >
        {props.index}. {props.question.instructions}
      </Text>
      <Message
        {...props}
        textStyle={
          hasExceptions
            ? routineMessageStyles.textException
            : routineMessageStyles.text
        }
      />
    </View>
  );
};
