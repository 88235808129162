import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { StyleSheet, Text, View } from "react-native";
import { Colors } from "../themes";
import { getEnumKey } from "../utils/getEnumKey";

export enum StatusBadgeType {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type StatusBadgeProps = {
  type: StatusBadgeType;
  text?: string;
};

export const StatusBadge = (props: StatusBadgeProps) => {
  const styleName = getEnumKey(StatusBadgeType, props.type)?.toLowerCase();
  const badgeStyle = styles[styleName as keyof typeof styles];
  const textStyle = styles[`text_${styleName}` as keyof typeof styles];

  return (
    <View style={styles.container}>
      <View style={[styles.badge, badgeStyle]}>
        <Icon name="circle-small" size={32} style={[textStyle]} />
        <Text style={[styles.text, textStyle]}>
          {props.text}
        </Text>
      </View>
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 100,
    maxHeight: 30
  },
  badge: {
    borderRadius: 16,
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: 0,
    paddingTop: 2,
    paddingRight: 8,
    paddingBottom: 2,
  },
  active: {
    backgroundColor: Colors.active,
  },
  inactive: {
    backgroundColor: Colors.inactive,
  },
  text: {
    fontSize: 12,
    lineHeight: 18,
    fontWeight: "500",
    textAlign: "center",
    padding: 0,
    margin: 0
  },
  text_active: {
    color: '#037847'
  },
  text_inactive: {
    color: '#780303'
  },
});