export const GET_TAGS = `
  query FindTags($filter: TagFilter, $page: PageRequest, $orderBy: OrderByInput) {
    findTags(filter: $filter, page: $page, orderBy: $orderBy) {
      items {
        id
        tagCode
        link {
          linkData
        }
        version {
          version
        }
        status
        updatedAt
      }
      count
      limit
      offset
    }
  }
`;