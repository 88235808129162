import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGrippContext } from '../../grippContext';
import { StorageKeys, useStorageKeys } from '../../storage';

export const useSetFilter = (queryKey: string, storageKey: string) => {
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [queryKey],
    mutationFn: async (filter: any) => {
      await storageHandler.setItem(
        storageKeys[storageKey as keyof StorageKeys],
        JSON.stringify(filter)
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [queryKey] });
    },
  });
};
