import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { CustomQueryArgs } from '../types';
import { useFetcher } from './useFetcher';

/**
 * shareable logic for using a graphback custom query
 **/

export const useCustomQuery = (opts: CustomQueryArgs): UseQueryResult<any> => {
  return useQuery<any>({
    queryKey: opts.cacheKey,
    queryFn: useFetcher<any, any>(opts.query).bind(null, opts.variables),
    ...opts.options,
  });
};
