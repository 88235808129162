import { useEffect, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import { useOpenedState } from '../../hooks/useOpenedState';
import { Colors } from '../../themes/colors';
import { ImageLightBox } from '../modalImages/imageLightbox';
import { ImageThumbnail } from './imageThumbnail';
import { OutlinedImage } from './outlinedImage';
import { ProcessingPlaceholder } from './processingPlaceholder';
import {
  ImageData,
  MediaComponent,
  MediaComponentProps,
  SizePrefix,
} from './types';

export const ImageComponent: MediaComponent<ImageData> = ({
  hasLightbox = true,
  displayMode = 'card',
  displayWidth: dipslayWidth = 80,
  ...props
}: MediaComponentProps<ImageData>) => {
  const { isOpened, onOpen, onClose } = useOpenedState();
  const [showLightbox, setShowLightbox] = useState(false);

  useEffect(() => {
    setShowLightbox(hasLightbox && !props.mediaItem.isUploading);
  }, [hasLightbox, props.mediaItem.isUploading]);

  return (
    <ProcessingPlaceholder
      processing={props.mediaItem.showProcessing ?? false}
      width={dipslayWidth}
      height={dipslayWidth}
      displayMode={displayMode}
      url={props.mediaItem.url}
      name={props.mediaItem.name ?? ''}
      extension={props.mediaItem.extension}
    >
      {displayMode === 'outlined' ? (
        <Pressable onPress={onOpen} style={{ width: dipslayWidth }}>
          <OutlinedImage
            url={props.mediaItem.urlsBySize?.[SizePrefix.small]}
            size={dipslayWidth}
          />
        </Pressable>
      ) : (
        <Card
          onPress={onOpen}
          style={[
            styles.imageCard,
            { width: dipslayWidth, height: dipslayWidth },
          ]}
        >
          <ImageThumbnail
            style={[
              styles.imageThumbnail,
              { width: dipslayWidth, height: dipslayWidth },
            ]}
            imageUri={props.mediaItem.urlsBySize?.[SizePrefix.extraSmall]}
            progress={props.mediaItem.uploadProgress}
            uploading={props.mediaItem.isUploading}
          />
        </Card>
      )}

      {showLightbox && (
        <ImageLightBox
          url={props.mediaItem.urlsBySize?.[SizePrefix.original]}
          isOpened={isOpened}
          onClose={onClose}
        />
      )}
    </ProcessingPlaceholder>
  );
};

const styles = StyleSheet.create({
  imageCard: {
    height: 80,
    width: 80,
    borderRadius: 8,
    border: 0.5,
    borderColor: Colors.grayDivider,
    cursor: 'pointer',
  },
  imageThumbnail: {
    height: 80,
    width: 80,
    borderRadius: 8,
  },
});
