import { View } from 'react-native';
import { HelperText } from 'react-native-paper';
import { styles } from './styles';

export const ViewError = ({ error }: { error: any }) => {
  return (
    <>
      {error && (
        <View style={{ height: 80 }}>
          <View style={styles.errorContainer}>
            <HelperText type="error">{error}</HelperText>
          </View>
        </View>
      )}
    </>
  );
};
