import { SingleStateConfig } from './routineConfig';
import { RoutineState } from './routineState';

export interface StatusBaseConfig {
  statuses: string[];
}

export interface StatusConfig extends SingleStateConfig, StatusBaseConfig {}

export interface StatusResponse {
  status: string;
}

export interface StatusState extends RoutineState, StatusResponse {}
