/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LIST_GROUPS,
  useCurrentWorkspace,
  useGroupListFilter,
  useGroupListOrder,
} from '@gripp/shared-logic';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteDataGrid } from '../infiniteDataGrid';
import { GroupTableData, useColumns } from './useColumns';

export type GroupListProps = {
  maxHeight?: number;
  paddingBottom?: number;
  preserveScrollKey?: string;
  assetButton?: (data: GroupTableData) => JSX.Element;
};

export const GroupsList = ({
  maxHeight,
  paddingBottom,
  preserveScrollKey,
  assetButton,
}: GroupListProps) => {
  const { t } = useTranslation();

  const { data } = useGroupListFilter();
  const { data: orderData } = useGroupListOrder();
  const workspace = useCurrentWorkspace(true);

  const [filter, setFilter] = useState<any>(undefined);

  useEffect(() => {
    if (!data || workspace === undefined) return;
    const f = { ...data };
    f.workspaceId = workspace?.id;
    if (f.keyword === '') delete f.keyword;
    setFilter(f);
  }, [data, workspace]);

  const AssetButton = assetButton;

  const columns = useColumns(filter, AssetButton) as ColumnDef<any, any>[];

  return !filter ? null : (
    <InfiniteDataGrid
      modelName="group"
      query={LIST_GROUPS}
      orderBy={orderData}
      filter={filter}
      pageSize={50}
      columns={columns}
      listMaxHeight={maxHeight as number}
      paddingBottom={paddingBottom as number}
      textEmpty={t('groups.common.textEmpty')}
      dataPrefix="group.listGroups"
      enableScrollRefresh={true}
      preserveScrollKey={preserveScrollKey}
      showScrollIndicator={true}
      exportFileName="groups"
    />
  );
};
