import { Workspace } from '@gripp/shared-logic';
import { Text } from 'react-native';
import { ImpersonateWorkspaceChip } from './impersonateWorkspaceChip';

type WorkspaceColumnProps = {
  workspace: Workspace;
  canImpersonate: boolean;
};

export const WorkspaceColumn = (props: WorkspaceColumnProps) => {
  return props.canImpersonate ? (
    <ImpersonateWorkspaceChip workspace={props.workspace} />
  ) : (
    <Text>{props.workspace?.name}</Text>
  );
};
