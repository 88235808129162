import { Platform } from 'react-native';
import { MentionsInputFieldMobile } from './mentionFieldMobile';
import { MentionsInputFieldWeb } from './mentionFieldWeb';

export type MentionInputFieldProps = {
  inputFieldText: string;
  setInputFieldText: (text: string) => void;
  setIsMultiline: (value: boolean) => void;
  inputHeight: number;
  setInputHeight: (value: number) => void;
  send: () => void;
};

export const MentionInput = (props: MentionInputFieldProps) => {
  const {
    inputFieldText,
    setInputFieldText,
    setIsMultiline,
    inputHeight,
    setInputHeight,
    send,
  } = props;

  const isWeb = Platform.OS === 'web';

  const renderMobileMentionInput = () => (
    <MentionsInputFieldMobile
      inputFieldText={inputFieldText}
      setInputFieldText={setInputFieldText}
      setIsMultiline={setIsMultiline}
      inputHeight={inputHeight}
      setInputHeight={setInputHeight}
    />
  );

  const renderWebMentionInput = () => (
    <MentionsInputFieldWeb
      inputFieldText={inputFieldText}
      setInputFieldText={setInputFieldText}
      send={send}
    />
  );

  return isWeb ? renderWebMentionInput() : renderMobileMentionInput();
};
