import { useEffect, useState } from 'react';
import { Account } from '../../auth';
import { useCustomQuery } from '../../graphql';
import { NotificationPreferenceFields } from '../notificationPreferenceFields';
import { PreferencesCacheKey } from './cacheKeys';

type LoadAccountPreferences = {
  account?: Account | null;
};

export const useLoadAccountPreferences = (props: LoadAccountPreferences) => {
  const [preferences, setPreferences] = useState<any>(null);

  const { data } = useCustomQuery({
    cacheKey: PreferencesCacheKey,
    query: GET_PREFERENCES,
    variables: {
      id: props.account?.id,
    },
    options: {
      enabled: props.account?.id !== undefined,
    },
  });

  useEffect(() => {
    if (data) {
      setPreferences(data.getAccount.preferences);
    }
  }, [data]);

  return preferences;
};

const GET_PREFERENCES = `
  query GET_ACCOUNT_PREFERENCES($id: ID!) {
    getAccount(id: $id) {
      preferences {
        ${NotificationPreferenceFields}
        preferredLanguage
      }
    }
  }
`;
