import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  ActivityOrderBy,
  Issue,
  IssueType,
  Severity,
  formatShortDate,
} from '@gripp/shared-logic';
import * as Haptics from 'expo-haptics';
import { useEffect, useMemo, useState } from 'react';
import {
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { IconButton, Text } from 'react-native-paper';
import { Layout, Spacing } from '../../styles';
import { Colors } from '../../themes/colors';
import { HeaderDot } from '../HeaderDot';
import { getIssueAuditData } from './issueFormatUtils';
import { IssueMenu } from './issueMenu';
import { SeverityIcons } from './severityIcons';

export type IssueHeaderProps = {
  issue: Issue;
  onChanged: (severity: string) => void;
  setShowFooter: (show: boolean) => void;
  titleStyle?: StyleProp<ViewStyle>;
  viewAssetInfo?: () => void;
  onIssueDelete: () => void;
  hideMenu?: boolean;
  onPrint?: () => Promise<void>;
};

export const IssueHeader = (props: IssueHeaderProps) => {
  const issue = props.issue;

  if (!issue) {
    return;
  }

  const createdAt = issue.createdAt;

  const [currentSeverity, setSeverity] = useState(issue.severity);
  const [showSeverity, setShowSeverity] = useState(false);

  const severityList = useMemo(() => {
    const statuses = [Severity.Red, Severity.Yellow, Severity.Green];

    return statuses.map((x, i) => ({
      label: '',
      value: x,
      icon: () => (
        <OptionButton
          selected={currentSeverity}
          severity={x}
          icon={SeverityIcons[x]}
        />
      ),
    }));
  }, [currentSeverity]);

  useEffect(() => {
    setSeverity(issue.severity);
  }, [issue]);

  const toggleIcon = () => {
    if (showSeverity) {
      return 'chevron-up';
    } else {
      return 'chevron-down';
    }
  };

  const isSeverityDisabled = () => {
    return (
      !issue ||
      issue.type === IssueType.Note ||
      issue.type === IssueType.Feedback ||
      issue.type === IssueType.Routine
    );
  };

  const { who } = getIssueAuditData(issue, ActivityOrderBy.MostRecentReported);

  return (
    <View style={styles.headerContainer}>
      <View style={styles.dropdownContainer}>
        <DropDownPicker
          open={showSeverity}
          setOpen={setShowSeverity}
          value={currentSeverity}
          setValue={setSeverity}
          items={severityList}
          onSelectItem={(item) => {
            props.onChanged(item.value as string);
          }}
          onClose={() => {
            Platform.OS === 'ios' &&
              Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
          }}
          showArrowIcon={false}
          showTickIcon={false}
          containerStyle={{
            width: 70,
          }}
          dropDownContainerStyle={{
            marginTop: 32,
            borderWidth: 0,
            backgroundColor: 'transparent',
          }}
          listItemContainerStyle={{
            paddingHorizontal: 0,
          }}
          props={{
            style: { display: 'none' },
            disabled: true,
          }}
          itemProps={{
            activeOpacity: 0.7,
          }}
        />
        <View style={styles.floatingButtonContainer}>
          <Pressable
            style={[
              styles.dropdownButton,
              { backgroundColor: Colors[currentSeverity] },
            ]}
            pointerEvents={isSeverityDisabled() ? 'none' : 'auto'}
            onPress={
              isSeverityDisabled()
                ? undefined
                : () => {
                    Platform.OS === 'ios' &&
                      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                    setShowSeverity((prev) => !prev);
                  }
            }
          >
            <MaterialCommunityIcons
              name={SeverityIcons[currentSeverity]}
              color={Colors.white}
              size={20}
            />
            {isSeverityDisabled() ? undefined : (
              <MaterialCommunityIcons
                name={toggleIcon()}
                color={Colors.white}
                size={24}
                style={{ opacity: 0.5 }}
              />
            )}
          </Pressable>
        </View>
      </View>
      <View style={styles.contentContainer}>
        <View style={styles.titleRow}>
          <Text style={[styles.titleStyle]}>{issue.title}</Text>
        </View>

        <View style={styles.infoRow}>
          <Text style={styles.infoText}>
            {issue.asset?.name || issue.workspace.name}
          </Text>
          <HeaderDot />
          <Text style={styles.infoText}>{who}</Text>
          <HeaderDot />
          <Text style={styles.infoText}>{formatShortDate(createdAt)}</Text>
        </View>
      </View>
      <View>
        {!props.hideMenu && (
          <IssueMenu
            issue={issue}
            onIssueDelete={props.onIssueDelete}
            onPrint={props.onPrint}
          />
        )}
      </View>
    </View>
  );
};

type OptionButtonProps = {
  selected: string;
  severity: Severity;
  icon: string;
};

const OptionButton = (props: OptionButtonProps) => (
  <IconButton
    icon={props.icon}
    iconColor={Colors.white}
    containerColor={Colors[props.severity]}
    style={[
      styles.dropdownButton,
      props.severity === Severity.Red && styles.redPickerButton,
      props.severity === Severity.Yellow && styles.yellowPickerButton,
      props.severity === Severity.Green && styles.greenPickerButton,
    ]}
  />
);

const styles = StyleSheet.create({
  headerContainer: {
    ...Layout.row,
    ...Layout.justifyBetween,
    ...Layout.alignCenter,
    ...Spacing.basePadding,
    backgroundColor: Colors.white,
    borderBottomColor: Colors.grayDisabled,
    borderBottomWidth: 0.5,
    overflow: 'visible',
    height: 80,
    zIndex: 1,
  },
  contentContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    ...Layout.container,
    width: '100%',
    marginLeft: 4,
    height: 50,
    overflow: 'hidden',
  },
  dropdownContainer: {
    height: 42,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  floatingButtonContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  dropdownButton: {
    width: 60,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 0,
    borderRadius: 8,
    margin: 0,
  },

  redPickerButton: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  yellowPickerButton: {
    borderRadius: 0,
  },
  greenPickerButton: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  titleStyle: {
    flex: 1,
    color: Colors.black,
    padding: 0,
    margin: 0,
    fontSize: 18,
    fontWeight: '600',
    textAlignVertical: 'bottom',
  },
  infoRow: {
    flexDirection: 'row',
    width: '100%',
    height: 14,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 0,
  },
  titleRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxHeight: 25,
    marginBottom: 2,
  },
  infoText: {
    color: Colors.grayFill,
    textAlignVertical: 'center',
    fontSize: 12,
    fontWeight: '500',
    letterSpacing: -0.4,
  },
});
