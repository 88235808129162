export const ACCOUNT_INFO_FRAGMENT = `
  id
  name {
    first
    last
  }
  avatar {
    id
    extension
    status
  }
`;