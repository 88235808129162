import {
  PermissionValues as Permissions,
  useCurrentAccount,
} from '@gripp/shared-logic';
import { Colors } from '@gripp/shared-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { CustomMutationForm } from '../../shared/components/forms/mutationForm';
import { ModalButton } from '../../shared/components/modalButton';
import { CREATE_WORKSPACE_WITH_OWNER } from '../operations/createWorkspaceWithOwner';
import { CreateWorkspaceForm } from './createWorkspaceForm';

export const WorkspacesToolbar = () => {
  const { t } = useTranslation();
  const [canAddWorkspace, setCanAddWorkspace] = useState(false);
  const { account } = useCurrentAccount();

  useEffect(() => {
    if (!account) return;

    if (account.hasPermission(Permissions.globalAdmin)) {
      setCanAddWorkspace(true);
    }
  }, [account]);

  const dataTransformer = (data) => {
    return {
      name: data.name,
      owner: {
        first: data.ownerFirstName,
        last: data.ownerLastName,
      },
      ownerEmail: data.ownerEmail.toLowerCase(),
      routineIds: data.routineIds,
    };
  };

  return (
    canAddWorkspace && (
      <ModalButton
        label={t('workspace.createButton')}
        icon="plus"
        requiredPermission={Permissions.workspace.create}
        containerStyle={styles.modalContainer}
        modalComponent={({ onComplete }) => {
          return (
            <CustomMutationForm
              defaultValues={{
                name: '',
                ownerFirstName: '',
                ownerLastName: '',
                ownerEmail: '',
                routineIds: [],
              }}
              formComponent={CreateWorkspaceForm}
              modelName="workspace"
              mutationOperation={CREATE_WORKSPACE_WITH_OWNER}
              invalidateCache={true}
              onComplete={onComplete}
              dataTransformer={dataTransformer}
            />
          );
        }}
      />
    )
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 560,
    maxHeight: 780,
    borderRadius: 12,
    backgroundColor: Colors.primaryGrayBackground,
  },
});
