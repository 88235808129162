import { ActivityFilterKey } from '@gripp/shared-logic';
import { FilterButton, FilterType, useOpenedState } from '@gripp/shared-ui';
import { random } from 'lodash';
import { ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { AccountFilterMenu } from '../../../accounts/components/accountFilterMenu';
import { ActivityDateFilterMenu } from '../../../assets/components/activityDateFilterMenu';
import { ActivityGroupsFilterMenu } from '../../../assets/components/activityGroupsFilterMenu';
import { AssetFilterMenu } from '../../../assets/components/assetFilterMenu';
import { AssetGroupsFilterMenu } from '../../../assets/components/assetGroupsFilterMenu';
import { TypesFilterMenu } from '../../../assets/components/typesFilterMenu';
import { GroupFilterMenu } from '../../../groups/components/groupFilterMenu';

type FilterBarProps = {
  filterButtonTypes: FilterType[];
  filterKey?: ActivityFilterKey;
};

export const FilterBar = ({ filterButtonTypes, filterKey }: FilterBarProps) => {
  const { t } = useTranslation();

  const {
    onOpen: onTypesFilterOpen,
    onClose: onTypesFilterClose,
    isOpened: isTypesFilterOpened,
  } = useOpenedState();

  const {
    onOpen: onActivityGroupsFilterOpen,
    onClose: onActivityGroupsFilterClose,
    isOpened: isActivityGroupsFilterOpened,
  } = useOpenedState();

  const {
    onOpen: onAssetGroupsFilterOpen,
    onClose: onAssetGroupsFilterClose,
    isOpened: isAssetGroupsFilterOpened,
  } = useOpenedState();

  const {
    onOpen: onColumnsFilterOpen,
    onClose: onColumnsFilterClose,
    isOpened: isColumnsFilterOpened,
  } = useOpenedState();

  const {
    onOpen: onGroupColumnsFilterOpen,
    onClose: onGroupColumnsFilterClose,
    isOpened: isGroupColumnsFilterOpened,
  } = useOpenedState();

  const {
    onOpen: onAccountColumnsFilterOpen,
    onClose: onAccountColumnsFilterClose,
    isOpened: isAccountColumnsFilterOpened,
  } = useOpenedState();

  const {
    onOpen: onAccountGroupsFilterOpen,
    onClose: onAccountGroupsFilterClose,
    isOpened: isAccountGroupsFilterOpened,
  } = useOpenedState();

  const {
    onOpen: onAccountTypesFilterOpen,
    onClose: onAccountTypesFilterClose,
    isOpened: isAccountTypesFilterOpened,
  } = useOpenedState();

  const {
    onOpen: onActivityDateFilterOpen,
    onClose: onActivityDateFilterClose,
    isOpened: isActivityDateFilterOpened,
  } = useOpenedState();

  // Refs for dropdownview
  const typesButtonRef = useRef<View>(null);
  const groupsButtonRef = useRef<View>(null);
  const columnsButtonRef = useRef<View>(null);
  const groupColumnsButtonRef = useRef<View>(null);
  const accountColumnsButtonRef = useRef<View>(null);
  const accountGroupsButtonRef = useRef<View>(null);
  const accountTypesButtonRef = useRef<View>(null);
  const dateButtonRef = useRef<View>(null);

  const HandleOpenTypes = (): ReactNode => {
    return (
      <TypesFilterMenu
        type={FilterType.ACTIVITYTYPES}
        filterKey={filterKey}
        isOpened={isTypesFilterOpened}
        onFilterOpen={onTypesFilterOpen}
        onFilterClose={onTypesFilterClose}
        anchorRef={typesButtonRef}
      />
    );
  };

  const HandleOpenAssetGroups = (): ReactNode => {
    return (
      <AssetGroupsFilterMenu
        isOpened={isAssetGroupsFilterOpened}
        onFilterOpen={onAssetGroupsFilterOpen}
        onFilterClose={onAssetGroupsFilterClose}
        anchorRef={groupsButtonRef}
        type={FilterType.ASSETGROUPS}
      />
    );
  };

  const HandleOpenColumns = (): ReactNode => {
    return (
      <AssetFilterMenu
        isOpened={isColumnsFilterOpened}
        onColumnFilterOpen={onColumnsFilterOpen}
        onColumnFilterClose={onColumnsFilterClose}
        anchorRef={columnsButtonRef}
        type={FilterType.ASSETCOLUMNS}
      />
    );
  };

  const HandleOpenGroupColumns = (): ReactNode => {
    return (
      <GroupFilterMenu
        isOpened={isGroupColumnsFilterOpened}
        onColumnFilterOpen={onGroupColumnsFilterOpen}
        onColumnFilterClose={onGroupColumnsFilterClose}
        anchorRef={groupColumnsButtonRef}
        type={FilterType.GROUPCOLUMNS}
      />
    );
  };

  const HandleOpenAccountGroups = (): ReactNode => {
    return (
      <AccountFilterMenu
        isOpened={isAccountGroupsFilterOpened}
        onAccountFilterOpen={onAccountGroupsFilterOpen}
        onAccountFilterClose={onAccountGroupsFilterClose}
        anchorRef={accountGroupsButtonRef}
        type={FilterType.ACCOUNTGROUPS}
      />
    );
  };

  const HandleOpenAccountTypes = (): ReactNode => {
    return (
      <AccountFilterMenu
        isOpened={isAccountTypesFilterOpened}
        onAccountFilterOpen={onAccountTypesFilterOpen}
        onAccountFilterClose={onAccountTypesFilterClose}
        anchorRef={accountTypesButtonRef}
        type={FilterType.ACCOUNTTYPES}
      />
    );
  };

  const HandleOpenAccountColumns = (): ReactNode => {
    return (
      <AccountFilterMenu
        isOpened={isAccountColumnsFilterOpened}
        onAccountFilterOpen={onAccountColumnsFilterOpen}
        onAccountFilterClose={onAccountColumnsFilterClose}
        anchorRef={accountColumnsButtonRef}
        type={FilterType.ACCOUNTCOLUMNS}
      />
    );
  };

  const HandleOpenActivityGroups = (): ReactNode => {
    return (
      <ActivityGroupsFilterMenu
        type={FilterType.ACTIVITYGROUPS}
        filterKey={filterKey}
        isOpened={isActivityGroupsFilterOpened}
        onFilterOpen={onActivityGroupsFilterOpen}
        onFilterClose={onActivityGroupsFilterClose}
        anchorRef={groupsButtonRef}
      />
    );
  };

  const HandleOpenActivityDate = (): ReactNode => {
    return (
      <ActivityDateFilterMenu
        type={FilterType.ACTIVITYDATE}
        filterKey={filterKey}
        isOpened={isActivityDateFilterOpened}
        onFilterOpen={onActivityDateFilterOpen}
        onFilterClose={onActivityDateFilterClose}
        anchorRef={dateButtonRef}
      />
    );
  };

  const RenderFilterButtons = (): ReactNode => {
    let openFilterMenu = () => {};

    return filterButtonTypes.map((type, index) => {
      let ref = null;
      let filterKeyType = undefined;

      switch (type) {
        case FilterType.ACTIVITYTYPES:
          ref = typesButtonRef;
          filterKeyType = filterKey;
          openFilterMenu = onTypesFilterOpen;
          break;

        case FilterType.ACTIVITYGROUPS:
          ref = groupsButtonRef;
          filterKeyType = filterKey;
          openFilterMenu = onActivityGroupsFilterOpen;
          break;

        case FilterType.ASSETGROUPS:
          ref = groupsButtonRef;
          openFilterMenu = onAssetGroupsFilterOpen;
          break;

        case FilterType.ASSETCOLUMNS:
          ref = columnsButtonRef;
          openFilterMenu = onColumnsFilterOpen;
          break;

        case FilterType.GROUPCOLUMNS:
          ref = groupColumnsButtonRef;
          openFilterMenu = onGroupColumnsFilterOpen;
          break;

        case FilterType.ACCOUNTCOLUMNS:
          ref = accountColumnsButtonRef;
          openFilterMenu = onAccountColumnsFilterOpen;
          break;

        case FilterType.ACCOUNTGROUPS:
          ref = accountGroupsButtonRef;
          openFilterMenu = onAccountGroupsFilterOpen;
          break;

        case FilterType.ACCOUNTTYPES:
          ref = accountTypesButtonRef;
          openFilterMenu = onAccountTypesFilterOpen;
          break;

        case FilterType.ACTIVITYDATE:
          ref = dateButtonRef;
          filterKeyType = filterKey;
          openFilterMenu = onActivityDateFilterOpen;
          break;

        default:
          openFilterMenu = () => {};
          break;
      }

      return (
        <View key={`${index}-filterBtn-${random}`} ref={ref}>
          <FilterButton
            type={type}
            filterKey={filterKeyType}
            onGoFilter={openFilterMenu}
          />
        </View>
      );
    });
  };

  return (
    <View style={[styles.filterBarContainer]}>
      <Text style={styles.filtersLabel}>{t('activity.filter.title')}: </Text>
      <RenderFilterButtons />

      {isTypesFilterOpened && <HandleOpenTypes />}
      {isActivityGroupsFilterOpened && <HandleOpenActivityGroups />}
      {isAssetGroupsFilterOpened && <HandleOpenAssetGroups />}
      {isColumnsFilterOpened && <HandleOpenColumns />}
      {isGroupColumnsFilterOpened && <HandleOpenGroupColumns />}
      {isActivityDateFilterOpened && <HandleOpenActivityDate />}
      {isAccountGroupsFilterOpened && <HandleOpenAccountGroups />}
      {isAccountTypesFilterOpened && <HandleOpenAccountTypes />}
      {isAccountColumnsFilterOpened && <HandleOpenAccountColumns />}
    </View>
  );
};

const styles = StyleSheet.create({
  filterBarContainer: {
    height: 36,
    flexDirection: 'row',
    alignItems: 'center',
  },
  filtersLabel: {
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.25,
    marginRight: 12,
  },
});
