import { useCurrentAccount } from '../../auth';
import { useSubscription } from '../../graphql';
import { ISSUE_MESSAGE_FRAGMENT } from './issueMessageFragment';

export const useNewIssueMessageSubscription = (
  issueId: string,
  onNewData: (data: any) => Promise<void>
) => {
  const { account } = useCurrentAccount();

  useSubscription(
    {
      query: NEW_ISSUE_MESSAGES,
      variables: {
        filter: {
          issue: { eq: issueId },
          createdBy: { ne: account?.id },
        },
      },
    },
    onNewData,
    account != null
  );
};

const NEW_ISSUE_MESSAGES = `
  subscription newIssueMessage($filter: IssueMessageSubscriptionFilter) {
    newIssueMessage(filter: $filter) {
      ${ISSUE_MESSAGE_FRAGMENT}
    }
  }
`;
