import { useCreateMutation } from '../../graphql';
import { useCurrentLocation } from '../../location';

export const useTagScan = () => {
  const { mutate } = useCreateMutation({
    modelName: 'tagScan',
    query: createTagScan,
  });

  const { getLocation } = useCurrentLocation();

  const tagScan = async (tagId: string) => {
    const location = await getLocation();

    const input = {
      tag: { id: tagId },
      location: location,
    };

    await mutate({ input: input });
  };

  return tagScan;
};

const createTagScan = `
  mutation Mutation($input: CreateTagScanInput!) {
    createTagScan(input: $input) {
      id
    }
  }
`;
