import {
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { OrderByInput } from '../types';
import { cacheKeys } from './cacheKeys';
import { GraphbackOperationType, getDataObject } from './getDataObject';
import { useFetcher } from './useFetcher';

export type InfiniteFindByQueryOptions = {
  query: any;
  modelName: string;
  filter?: any;
  orderBy: OrderByInput;
  pageSize: number;
  withoutCache?: boolean;
  dataPrefix?: string;
};

export const useInfiniteFindByQuery = <TData, TError>(
  opts: InfiniteFindByQueryOptions
): UseInfiniteQueryResult<TData, TError> => {
  const fetcher = useFetcher<any, any>(opts.query);
  const cacheKey = cacheKeys(opts.modelName).list({
    ...opts.filter,
    ...opts.orderBy,
  });
  const cacheTimeDefault = 300000; // 5 minutes - default react-query cache time
  const cacheTime = opts.withoutCache ? 0 : cacheTimeDefault;

  const pageFetcher = async (args: any) => {
    const { offset } = args.pageParam || { offset: 0 };
    const results = await fetcher({
      filter: opts.filter,
      orderBy: opts.orderBy,
      page: {
        limit: opts.pageSize,
        offset: offset,
      },
    });

    const data = getDataObject(
      results,
      opts.modelName,
      GraphbackOperationType.find,
      opts.dataPrefix
    );

    return data;
  };

  return useInfiniteQuery({
    queryKey: cacheKey,
    queryFn: pageFetcher,
    gcTime: cacheTime,
    initialPageParam: { offset: 0 },
    getNextPageParam: (lastPage: any) => {
      if (lastPage.offset + lastPage.limit >= lastPage.count) return undefined;

      return { offset: lastPage.offset + opts.pageSize };
    },
    getPreviousPageParam: (firstPage: any) => {
      const prevPage = firstPage.offset - opts.pageSize;
      return prevPage <= 0 ? undefined : { offset: prevPage };
    },
  });
};
