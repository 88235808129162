import { plural } from 'pluralize';

export enum GraphbackOperationType {
  find,
  get,
  create,
  update,
  delete,
}

/**
 * graphback operations return data with the name of the query / mutation
 * this function strips that prefix object and returns the desired data object
 *
 * @param data - the data returned from the query / mutation
 * @param modelName  - the name of the model
 * @param operation - the operation (query / mutation)
 */
export const getDataObject = <TData>(
  data: any,
  modelName: string,
  operation: GraphbackOperationType,
  dataPrefix?: string
): TData | null => {
  if (!data) return null;

  const prefixName = dataPrefix ?? getPrefixName(modelName, operation);
  return findData(data, prefixName);
};

const getPrefixName = (
  modelName: string,
  operation: GraphbackOperationType
): string => {
  const operationName = GraphbackOperationType[operation];
  let suffix = modelName;
  if (operation === GraphbackOperationType.find) suffix = plural(modelName);

  return `${operationName}${suffix}`;
};

const findData = (data: any, prefixName: string): any => {
  if (prefixName.includes('.')) {
    return prefixName.split('.').reduce((o, i) => o[i], data) as any;
  } else {
    const dataKey = Object.keys(data).find(
      (x) => x.toLowerCase() === prefixName.toLowerCase()
    );
    return dataKey ? data[dataKey] : null;
  }
};
