import {
  QuickConfirmBaseConfig,
  QuickConfirmResponse,
} from '../../quickConfirm';
import { WorkflowQuestionConfig } from '../workflowQuestionConfig';
import { WorkflowResponseState } from '../workflowResponseState';
import { QuestionMultipleValueExceptionConfig } from './questionException';

export interface QuickConfirmQuestionConfig
  extends WorkflowQuestionConfig,
    QuickConfirmBaseConfig,
    QuestionMultipleValueExceptionConfig {}

export interface QuickConfirmResponseState
  extends WorkflowResponseState,
    QuickConfirmResponse {}
