import { useCurrentWorkspace, useStorageKeys } from '@gripp/shared-logic';
import { AssetSearchInput, AssetsList, FilterType } from '@gripp/shared-ui';
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { FilterBar } from '../../shared/components/filter/filterBar';
import { useMaxHeight } from '../../shared/hooks/useMaxHeight';
import { AssetGroups } from './assetGroups';

export const Assets = () => {
  const navigate = useNavigate();
  const workspace = useCurrentWorkspace(true);
  const storageKeys = useStorageKeys();
  const { containerMaxHeight, listMaxHeight } = useMaxHeight(64, 0, 228);

  const [rowsSelected, setRowsSelected] = useState(Array<any>);

  const onAssetClick = (asset) => {
    navigate(`/assets/${asset.id}`);
  };

  const updateRowsSelected = (rows: Array<any>) => {
    setRowsSelected(rows);
  };

  const AddToGroups = () => (
    <View style={styles.addToGroupsContainer}>
      <AssetGroups assetIds={rowsSelected} workspaceId={workspace?.id} />
    </View>
  );

  return (
    <>
      <View style={styles.filtersContainer}>
        <View style={styles.assetSearchContainer}>
          <AssetSearchInput />
        </View>
        <FilterBar
          filterButtonTypes={[FilterType.ASSETGROUPS, FilterType.ASSETCOLUMNS]}
        />
      </View>
      <View
        style={[
          styles.assetsContainer,
          {
            maxHeight: containerMaxHeight,
          },
        ]}
      >
        <AssetsList
          onAssetClick={onAssetClick}
          maxHeight={listMaxHeight}
          updateRowsSelected={updateRowsSelected}
          preserveScrollKey={storageKeys.assetsScrollData}
          addToGroupsComponent={AddToGroups()}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 44,
    marginBottom: 20,
  },
  assetSearchContainer: {
    width: 390, // current minimum size needed to avoid placeholder clipping on web
    marginRight: 20,
  },
  addToGroupsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  assetsContainer: {
    flex: 1,
    borderRadius: 8,
    overflow: 'hidden',
    zIndex: -1,
  },
});
