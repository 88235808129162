import { useSubscription } from '../../graphql';
import { useCurrentAccount } from '../../auth/hooks/useCurrentAccount';

export const useUnreadActivityInboxSubscription = (
  onNewData: (data: any) => Promise<void>
) => {
  const { account } = useCurrentAccount();

  useSubscription(
    {
      query: INBOX_SUBSCRIPTION,
      variables: {
        filter: {
          and: [{ for: { eq: account?.id } }, { read: { eq: false } }],
        },
      },
    },
    async (response) => {
      await onNewData(response.data.newActivityInbox);
    },
    account != null
  );
};

const INBOX_SUBSCRIPTION = `
subscription newActivityInbox($filter: ActivityInboxSubscriptionFilter) {
  newActivityInbox(filter: $filter) {
    id
    type
    data
  }
}
`;
