import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Colors } from '@gripp/shared-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Menu } from 'react-native-paper';

type AlertMenuProps = {
  toggleAlert: () => Promise<void>;
  deleteAlert: () => Promise<void>;
  isDisabled: boolean;
};

export const AlertMenu = ({
  toggleAlert,
  deleteAlert,
  isDisabled,
}: AlertMenuProps) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuFired, setMenuFired] = useState(0);

  const { t } = useTranslation();

  const openMenu = () => {
    setMenuVisible(true);
    setMenuFired(Date.now());
  };

  const closeMenu = () => {
    // Add fired check to combat paper menu bug:
    // https://github.com/callstack/react-native-paper/issues/3716
    if (Date.now() - menuFired > 400) {
      setMenuVisible(false);
    }
  };

  return (
    <Menu
      statusBarHeight={0}
      visible={menuVisible}
      onDismiss={closeMenu}
      contentStyle={[styles.menuContent, styles.white]}
      style={styles.menuContainer}
      anchor={
        <TouchableOpacity onPress={openMenu}>
          <MaterialCommunityIcons
            name="dots-vertical"
            color={Colors.primaryDark}
            size={24}
          />
        </TouchableOpacity>
      }
    >
      {/* // TODO: implement View Alert Activities */}
      {/* <Menu.Item
        leadingIcon={'bell-outline'}
        style={[styles.white, styles.menuItem]}
        onPress={() => {
          closeMenu();
        }}
        title={t('alerts.menu.viewAlertActivities')}
      /> */}
      <Menu.Item
        leadingIcon={isDisabled ? 'bell-check-outline' : 'bell-remove-outline'}
        style={[styles.white, styles.menuItem]}
        onPress={async () => {
          await toggleAlert();
          closeMenu();
        }}
        title={
          isDisabled
            ? t('alerts.menu.enableAlert')
            : t('alerts.menu.disableAlert')
        }
      />
      <Menu.Item
        leadingIcon={'delete-outline'}
        style={[styles.white, styles.menuItem]}
        onPress={async () => {
          await deleteAlert();
          closeMenu();
        }}
        title={t('alerts.menu.delete')}
      />
    </Menu>
  );
};

const styles = StyleSheet.create({
  menuContainer: {
    width: 254,
  },
  white: {
    backgroundColor: Colors.white,
  },
  menuContent: {
    top: 30,
    right: 20,
    paddingHorizontal: 8,
    paddingVertical: 24,
    rowGap: 20,
  },
  menuItem: {
    height: 24,
    fontSize: 16,
    fontWeight: 500,
  },
});

export default AlertMenu;
