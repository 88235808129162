import { DropDownView, FilterType, GroupPageFilter } from '@gripp/shared-ui';
import { View } from 'react-native';

type GroupFilterMenuProps = {
  onColumnFilterOpen: () => void;
  onColumnFilterClose: () => void;
  isOpened: boolean;
  anchorRef: React.RefObject<View>;
  type: FilterType;
};

export const GroupFilterMenu = ({
  onColumnFilterClose,
  onColumnFilterOpen,
  isOpened,
  anchorRef,
  type,
}: GroupFilterMenuProps) => {
  return (
    <DropDownView
      anchorRef={anchorRef}
      isVisible={isOpened}
      onDismiss={onColumnFilterClose}
    >
      <GroupPageFilter closeFilter={onColumnFilterClose} type={type} />
    </DropDownView>
  );
};
