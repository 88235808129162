import {
  cleanNumber,
  incrementNumber,
  isValidNumber,
} from '@gripp/shared-logic';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { IconButton, TextInput } from 'react-native-paper';
import { Colors } from '../../../themes';
import { CounterExecutionComponentProps } from './counterExecutionComponent';
import { CounterSummary } from './counterSummary';

export const GenericCounterExecutionComponent = (
  props: CounterExecutionComponentProps
) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState<string>(
    props.initialValue?.toString() || ''
  );

  const change = (input: string) => {
    input = cleanNumber(input, props.decimalPlaces, true);
    setInputValue(input);

    if (isValidNumber(input)) {
      props.onChange(parseFloat(input));
    } else {
      props.onChange(NaN);
    }
  };

  const incrementCount = (increment: number) => {
    const newCount = incrementNumber(
      inputValue,
      increment,
      props.decimalPlaces
    );
    setInputValue(newCount.toString());
    props.onChange(newCount);
  };

  const initialValueLabel = t(
    'routines.counter.user.genericCounter.initialValue'
  );

  const getDeltaValueLabel = () =>
    t('routines.counter.user.genericCounter.change');

  const getDeltaValue = () => {
    return String(
      parseFloat(
        ((props.value || 0) - props.initialValue).toFixed(props.decimalPlaces)
      )
    );
  };

  const getNewValueLabel = () =>
    t('routines.counter.user.genericCounter.newValue');

  const getNewValue = () => {
    return inputValue || '--';
  };

  return (
    <View
      style={props.containerStyle ? props.containerStyle : styles.container}
    >
      {props.instructions?.trim() !== '' && (
        <Text style={styles.sectionTitle}>{props.instructions}</Text>
      )}
      <View style={[styles.inputContainer, props?.inputContainerStyle]}>
        <IconButton
          mode="outlined"
          icon={'minus'}
          iconColor={Colors.grayText}
          size={24}
          style={[
            styles.counterButton,
            { borderRightWidth: 1, borderRightColor: Colors.grayText },
          ]}
          onPress={() => incrementCount(-1)}
        />
        <TextInput
          value={inputValue}
          autoFocus={props.autoFocus}
          onFocus={props.onFocus}
          ref={props.inputRef}
          onBlur={props.onBlur}
          onChangeText={change}
          inputMode={props.decimalPlaces ? 'decimal' : 'numeric'}
          mode={'outlined'}
          selectionColor={Colors.grayText}
          cursorColor={Colors.grayText}
          outlineStyle={styles.inputOutline}
          contentStyle={styles.inputBody}
          style={{ flex: 1 }}
        />
        <IconButton
          mode="outlined"
          icon={'plus'}
          iconColor={Colors.grayText}
          size={24}
          style={[
            styles.counterButton,
            { borderLeftWidth: 1, borderLeftColor: Colors.grayText },
          ]}
          onPress={() => incrementCount(1)}
        />
      </View>
      <CounterSummary
        initialValueLabel={initialValueLabel}
        initialValue={props.initialValue}
        getDeltaValueLabel={getDeltaValueLabel}
        getDeltaValue={getDeltaValue}
        getNewValueLabel={getNewValueLabel}
        getNewValue={getNewValue}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderRadius: 12,
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: Colors.blackText,
    letterSpacing: -0.25,
  },
  inputContainer: {
    width: '100%',
    height: 64,
    maxHeight: 64,
    marginTop: 20,
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: Colors.grayText,
    borderRadius: 12,
    overflow: 'hidden',
  },
  inputOutline: {
    borderWidth: 0,
  },
  inputBody: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    color: Colors.blackText,
    borderRadius: 0,
    padding: 20,
    fontSize: 24,
    fontWeight: '500',
    lineHeight: 28.8,
    textAlign: 'center',
  },
  counterButton: {
    width: 64,
    height: '100%',
    backgroundColor: Colors.white,
    borderRadius: 0,
    borderWidth: 0,
    margin: 0,
  },
});
