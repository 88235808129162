import { Colors } from 'libs/shared-ui/src/themes';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

export type StatusExecutionComponentProps = {
  statuses: string[];
  value: string;
  onChange: (status: string) => void;
};

export const StatusExecutionComponent = (props: any) => {
  const lastStatusIndex = props.statuses.length - 1;

  return (
    <View style={styles.buttonsContainer}>
      {props.statuses.map((status: string | number, i: number) => {
        const buttonBackgroundStyle = [styles.button];
        const buttonLabelStyle = [styles.buttonLabel];

        if (status === props.value) {
          buttonBackgroundStyle.push(styles.currentStateButton);
          buttonLabelStyle.push(styles.currentButtonLabel);
        }

        return (
          <TouchableOpacity
            key={status}
            activeOpacity={0.7}
            style={[
              buttonBackgroundStyle,
              i !== lastStatusIndex
                ? { marginBottom: 20 }
                : { marginBottom: 0 },
            ]}
            onPress={() => props.onChange(status)}
          >
            <Text style={buttonLabelStyle}>{status}</Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  button: {
    height: 52,
    borderWidth: 2,
    borderRadius: 51,
    borderColor: Colors.blue,
    alignItems: 'center',
    justifyContent: 'center',
  },
  currentStateButton: {
    backgroundColor: Colors.blue,
  },
  buttonLabel: {
    color: Colors.black,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 19,
  },
  currentButtonLabel: {
    color: Colors.white,
  },
});
