import { StyleSheet, View } from 'react-native';
import { useMatches } from 'react-router-dom';

export const Toolbar = () => {
  let component = null;
  const matches = useMatches();
  const match = matches.at(-1);

  if (match?.handle?.toolbar) {
    component = match.handle.toolbar;
  }

  return <View style={styles.container}>{component}</View>;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});
