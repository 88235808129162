import { useCreateMutation } from '../../graphql/hooks';

export const useDeleteAccount = () => {
  return useCreateMutation({
    modelName: 'deleteAccount',
    query: CREATE_DELETE_ACCOUNT,
  });
};

const CREATE_DELETE_ACCOUNT = `
  mutation createDeleteAccount($input: CreateDeleteAccountInput!) {
    createDeleteAccount(input: $input) {
      id
    }
  }
`;
