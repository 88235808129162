import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Colors } from '../../themes/colors';

export type PillToggleOption = {
  value: any;
  label: string;
};

export type PillToggleProps = {
  options: PillToggleOption[];
  value: any;
  setValue: (value: any) => void;
};

export const PillToggle = (props: PillToggleProps) => {
  const onPress = (option: PillToggleOption) => {
    const selected = props.options.filter((o) => o === option)[0];
    props.setValue(selected.value);
  };

  return (
    <View style={styles.container}>
      {props.options.map((option) => (
        <TouchableOpacity
          key={option.value}
          style={[
            styles.option,
            props.value === option.value && styles.selected,
          ]}
          onPress={() => onPress(option)}
        >
          <Text
            style={
              props.value === option.value ? styles.selectedText : styles.text
            }
          >
            {option.label}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
    borderRadius: 25,
    overflow: 'hidden',
    borderColor: Colors.grayButtonBorder,
    borderWidth: 1,
    padding: 1,
  },
  option: {
    borderRadius: 25,
    paddingVertical: 15,
    paddingHorizontal: 20,
    width: '50%',
    backgroundColor: Colors.white,
  },
  selected: {
    backgroundColor: '#007BFF',
  },
  text: {
    color: '#007BFF',
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 16,
  },
  selectedText: {
    color: Colors.white,
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 16,
  },
});
