import { PermissionValues as Permissions } from '@gripp/shared-logic';
import { EditRoutine } from './components/editRoutine';
import { NewRoutine } from './components/newRoutine';
import { NewRoutineType } from './components/newRoutineType';
import { Routine } from './components/routine';
import { Routines } from './components/routines';
import { routinesPaths } from './routinesPaths';

const newRoutineHeader = {
  titleKey: 'routines.new.header',
  icon: 'format-list-checks',
};

const editRoutineHeader = {
  titleKey: 'routines.edit.header',
  icon: 'format-list-checks',
};

export const routinesRoutes = [
  {
    path: `${routinesPaths.root}/*`,
    element: <Routines />,
    handle: {
      header: {
        titleKey: 'assets.list.routines',
        icon: 'format-list-checks',
      },
      requiredPermission: Permissions.globalAdmin,
    },
    children: [
      {
        path: ':routineId',
        element: <Routine />,
      },
    ],
  },
  {
    path: routinesPaths.new,
    element: <NewRoutineType />,
    handle: {
      header: newRoutineHeader,
      requiredPermission: Permissions.globalAdmin,
    },
  },
  {
    path: `${routinesPaths.new}/:type`,
    element: <NewRoutine />,
    handle: {
      header: newRoutineHeader,
      requiredPermission: Permissions.globalAdmin,
    },
  },
  {
    path: `${routinesPaths.edit}`,
    element: <EditRoutine />,
    handle: {
      header: editRoutineHeader,
      requiredPermission: Permissions.globalAdmin,
    },
  },
];
