import { VerifyAuthCode as VerifyAuthCodeBase } from '@gripp/shared-ui';
import { useNavigate } from 'react-router-dom';

export default function VerifyAuthCode() {
  const navigate = useNavigate();

  const handleRedirect = async (redirectPath: string) => {
    navigate(redirectPath);
    return Promise.resolve();
  };

  return (
    <VerifyAuthCodeBase
      onTryAnotherMethod={() => navigate('/login')}
      handleRedirect={handleRedirect}
      hideBackButton
    />
  );
}
