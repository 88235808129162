import { Controller } from 'react-hook-form';
import { Pressable, StyleSheet, View } from 'react-native';
import { RadioButton, Text } from 'react-native-paper';
import { Colors } from '../../themes/colors';

type RadioButtonOption = {
  label?: string;
  value: any;
};

type ControlledRadioButtonProps = {
  control: any;
  name: string;
  options: RadioButtonOption[];
  horizontal?: boolean;
};

export const ControlledRadioButtons = ({
  control,
  name,
  options,
  horizontal = false,
}: ControlledRadioButtonProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <View
          style={horizontal ? styles.horizontalContainer : styles.container}
        >
          {options.map((option) => (
            <Pressable
              key={option.value}
              style={
                horizontal
                  ? styles.horizontalItemContainer
                  : styles.itemContainer
              }
              onPress={() => onChange(option.value)}
            >
              <RadioButton.Android
                value={option.value}
                status={option.value === value ? 'checked' : 'unchecked'}
                onPress={() => onChange(option.value)}
              />
              <View style={styles.labelsContainer}>
                <Text numberOfLines={1} style={styles.label}>
                  {option.label || option.value}
                </Text>
              </View>
            </Pressable>
          ))}
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  horizontalContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxHeight: 40,
    paddingRight: 16,
    marginBottom: 16,
  },
  labelsContainer: {
    flex: 1,
  },
  horizontalItemContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 16,
    width: '50%',
  },
  label: {
    fontSize: 16,
    color: Colors.black,
    fontWeight: '400',
    lineHeight: 19,
  },
});
