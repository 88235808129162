import {
  LIST_ASSETS,
  RoutineType,
  SortDirectionEnum,
  useCustomQuery,
  useStatefulWorkspaceRoutines,
} from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { useExportHook, useExportProps, useExportResult } from './types';

export const useAssetExport: useExportHook = (
  props: useExportProps
): useExportResult => {
  const [queryEnabled, setQueryEnabled] = useState(false);
  const [rows, setRows] = useState([]);
  const routines = useStatefulWorkspaceRoutines(
    props.workspace.id,
    !props.download
  );
  const [headers, setHeaders] = useState<any[]>([
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Description', key: 'description' },
    { label: 'Last Address', key: 'lastAddress' },
    { label: 'Last Scanned At', key: 'lastScannedAt' },
    { label: 'Last Updated', key: 'lastUpdated' },
    { label: '# Notes', key: 'numNotes' },
    { label: '# Reports', key: 'numReportsRed' },
    { label: '# Alerts', key: 'numReportsYellow' },
    { label: '# Routines', key: 'numRoutines' },
    { label: 'Groups', key: 'groups' },
    { label: 'Tag Code', key: 'tagCode' },
  ]);
  const [columns, setColumns] = useState<string[]>([
    'description',
    'lastAddress',
    'lastScannedAt',
    'lastUpdated',
    'reports',
    'notes',
    'routines',
    'groups',
    'tagCode',
  ]);

  useEffect(() => {
    if (!routines || routines.length === 0) return;

    const routineHeaders: any[] = [];
    const routineColumns: any[] = [];
    routines.forEach((routine) => {
      routineHeaders.push({
        label: routine.config.title ?? routine.name,
        key: `routine:${routine.id}`,
      });
      routineColumns.push(`routine:${routine.id}`);
    });

    if (routineHeaders.length > 0) {
      setHeaders([...headers, ...routineHeaders]);
      setColumns([...columns, ...routineColumns]);
    }

    setQueryEnabled(true);
  }, [routines]);

  const { data: assetsToExport, isLoading } = useCustomQuery({
    query: LIST_ASSETS,
    cacheKey: ['exportAssets', props.workspace.id],
    variables: {
      filter: {
        workspaceId: props.workspace.id,
        columns: columns,
      },
      orderBy: { field: 'name', order: SortDirectionEnum.Asc },
      page: { limit: 10000, offset: 0 },
    },
    options: {
      enabled: queryEnabled,
      gcTime: 10000,
      staleTime: 10000,
    },
  });

  useEffect(() => {
    if (!assetsToExport) return;

    const exportRows = assetsToExport.asset.listAssets.items.map(
      (item: any) => {
        const row: any = {};
        Object.keys(item).forEach((key) => {
          if (key === 'groups') {
            const groups = item[key];
            row[key] = groups
              ? groups.map((group: any) => group.name).join(', ')
              : '';
          } else if (key !== 'routineState') {
            row[key] = item[key];
          }
        });

        if (item.routineState) {
          item.routineState.forEach((routineState: any) => {
            const routine = routines.find(
              (r) => r.id === routineState.workspaceRoutineId
            );
            const state = routineState.state;
            let value: string | undefined = undefined;

            if (routine?.type === RoutineType.Counter) {
              value = state.count;
            } else if (routine?.type === RoutineType.Status) {
              value = state.status;
            }

            if (value !== undefined) {
              row[`routine:${routine?.id}`] = value;
            }
          });
        }

        return row;
      }
    );

    setRows(exportRows);
    props.onDataReady();
  }, [assetsToExport]);

  return {
    headers,
    rows,
    isLoading,
  };
};
