import { useUpdateMutation } from '../../graphql';

export const useUpdateWorkspaceAsset = () => {
  return useUpdateMutation({
    modelName: 'workspaceAsset',
    query: UPDATE_WORKSPACE_ASSET,
  });
};

const UPDATE_WORKSPACE_ASSET = `
  mutation updateWorkspaceAsset($input: MutateWorkspaceAssetInput!) {
    updateWorkspaceAsset(input: $input) {
      id
    }
  }
`;
