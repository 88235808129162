import { useState, useCallback } from 'react';
import { LayoutChangeEvent } from 'react-native';

/* Gets width and height of any component on screen during render.

  USAGE EX: 
  const { dimensions, onLayout } = useGetDimensions();
    <View onLayout={onLayout}>
      <Text> w: {dimensions.width}, h: {dimensions.height} </Text> // gets dimensions of parent View
    </View> 
*/

export type Dimensions = {
  width: number;
  height: number;
};

export const useGetDimensions = () => {
  const [dimensions, setDimensions] = useState<Dimensions | null>(null);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const { width, height } = event.nativeEvent.layout;
    setDimensions({ width, height });
  }, []);

  return { dimensions, onLayout };
};
