import {
  AssetRoutineState,
  RoutineState,
  WorkflowPageConfig,
  WorkflowQuestionConfig,
  WorkspaceAsset,
} from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { useFormContext } from '../../../forms';
import { styles } from './styles';
import { WorkflowExecutionQuestion } from './workflowExecutionQuestion';

type WorkflowExecutionPageProps = {
  page: WorkflowPageConfig;
  workspaceAsset: WorkspaceAsset;
  toggleProcessing?: (value: boolean) => void;
  currentState: AssetRoutineState<RoutineState> | undefined;
};

interface QuestionResponse {
  index: number;
  config: WorkflowQuestionConfig;
}

export const WorkflowExecutionPage = (props: WorkflowExecutionPageProps) => {
  const [initialized, setInitialized] = useState(false);
  const [questionResponses, setQuestionResponses] = useState<
    QuestionResponse[]
  >([]);

  const { getValues } = useFormContext();
  const fields = getValues('responses');

  useEffect(() => {
    const responses: QuestionResponse[] = [];
    props.page.questions
      .sort((a, b) => a.order - b.order)
      .forEach((question) => {
        const index = fields.findIndex(
          (field: any) => field.questionId === question.id
        );
        responses.push({ index, config: question });
      });
    setQuestionResponses(responses);

    setInitialized(true);
  }, []);

  return (
    initialized && (
      <>
        {props.page.instructions && (
          <View style={styles.instructionsContainer}>
            <Text style={styles.instructionsText}>
              {props.page.instructions}
            </Text>
          </View>
        )}
        {questionResponses.map((response, index) => (
          <WorkflowExecutionQuestion
            key={`question_${index}`}
            config={response.config}
            questionIndex={index}
            responseIndex={response.index}
            workspaceAsset={props.workspaceAsset}
            toggleProcessing={props.toggleProcessing}
            currentState={props.currentState}
          />
        ))}
      </>
    )
  );
};
