import { useTranslation } from 'react-i18next';
import { Button } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';

export const AlertsToolbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button icon={'plus'} onPress={() => navigate('alerts/new')}>
      {t('alerts.createButton')}
    </Button>
  );
};
