import { SortDirectionEnum, useCustomQuery } from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { useExportHook, useExportProps, useExportResult } from './types';

export const useAccountsExport: useExportHook = (
  props: useExportProps
): useExportResult => {
  const [rows, setRows] = useState([]);

  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phone' },
    { label: 'Date Created', key: 'createdAt' },
    { label: 'Roles', key: 'roles' },
    { label: 'Groups', key: 'groups' },
  ];

  const { data: accounts, isLoading } = useCustomQuery({
    query: LIST_ACCOUNTS,
    cacheKey: props.workspace
      ? ['exportWorkspaceAccounts', props.workspace.id]
      : ['exportAccounts'],
    variables: {
      filter: props.workspace
        ? {
            workspace: { eq: props.workspace.id },
          }
        : {},
      orderBy: { field: 'createdAt', order: SortDirectionEnum.Asc },
    },
    options: {
      enabled: props.download,
      gcTime: 10000,
      staleTime: 10000,
    },
  });

  useEffect(() => {
    if (!accounts) return;

    const exportRows = accounts.findAccounts.items.map((item: any) => {
      return {
        id: item.id,
        firstName: item.name?.first,
        lastName: item.name?.last,
        email: item.email,
        phone: `${item.phone?.callingCode}${item.phone?.phoneNumber}` || '',
        createdAt: item.createdAt,
        roles: item.roles?.map((r: any) => r.name).join(', '),
        groups: item.groups?.map((g: any) => g.name).join(', '),
      };
    });

    setRows(exportRows);
    props.onDataReady();
  }, [accounts]);

  return {
    headers,
    rows,
    isLoading,
  };
};

const LIST_ACCOUNTS = `
  query findAccounts($filter: AccountFilter, $orderBy: OrderByInput) {
    findAccounts(filter: $filter, orderBy: $orderBy) {
      items {
        id
        name {
          first
          last
        }
        email
        phone {
          callingCode
          phoneNumber
        }
        createdAt
        roles {
          id
          name
        }
        groups {
          name
        }
      }
    }
  }
`;
