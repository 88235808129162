import '@expo/metro-runtime'; // this enables fast-refresh in development...what happens in production?
import '@gripp/i18n';
import { GrippContext, useWatchLocation } from '@gripp/shared-logic';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { MediaPicker } from '../shared/components/media/mediaPicker';
import { SignatureScreenWeb } from '../shared/components/signature/signatureScreenWeb';
import { localStorageHandler } from '../shared/localStorageHandler';
import { ErrorBoundary } from '../shared/logging/errorBoundry';
import { SentryWebLogger } from '../shared/logging/sentryWebLogger';
import { routes } from './routing';

export const App = () => {
  const client = new QueryClient();
  const router = SentryWebLogger.wrap(createBrowserRouter)(routes);
  const { watchLocation } = useWatchLocation(localStorageHandler);
  watchLocation(true);

  return (
    <GrippContext.Provider
      value={{
        logger: SentryWebLogger,
        secureStorageHandler: localStorageHandler,
        storageHandler: localStorageHandler,
        mediaPickerComponent: MediaPicker,
        signaturePadScreen: SignatureScreenWeb,
      }}
    >
      <QueryClientProvider client={client}>
        <ErrorBoundary>
          <RouterProvider router={router} />
        </ErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </GrippContext.Provider>
  );
};

export default App;
