import { MaterialCommunityIcons } from '@expo/vector-icons';
import { EnvConfig, useWorkspace } from '@gripp/shared-logic';
import { Colors } from '@gripp/shared-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { useNavigate, useParams } from 'react-router-dom';
import { DownloadApp } from './downloadApp';

export const Invitation = () => {
  const { t } = useTranslation();
  const [workspaceName, setWorkspaceName] = useState('');
  const { invitationId, workspaceId } = useParams();
  const workspace = useWorkspace(workspaceId!);
  const navigate = useNavigate();
  const pathParams = `${invitationId}/${workspaceId}`;
  const scheme = EnvConfig.prefix === 'prod' ? 'gripp' : 'gripp-beta';
  const appUrl = `${scheme}://invitation/${pathParams}`;

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  useEffect(() => {
    if (!isMobile()) {
      navigate(`/acceptInvitation/${pathParams}`, {
        replace: true,
      });
    }
  }, []);

  useEffect(() => {
    if (!workspace) return;
    setWorkspaceName(workspace.name);
  }, [workspace]);

  const openApp = () => {
    window.location.href = appUrl;
  };

  return (
    isMobile() && (
      <View style={styles.container}>
        <View style={styles.row}>
          <Text style={[styles.text, styles.heading]}>
            {t('invitation.initial.heading', { workspaceName: workspaceName })}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={[styles.text, styles.step]}>
            {t('invitation.initial.step1')}
          </Text>
          <Text style={[styles.text, styles.instructions]}>
            {t('invitation.initial.step1Instructions')}
          </Text>
          <DownloadApp />
        </View>
        <View>
          <Text style={[styles.text, styles.step]}>
            {t('invitation.initial.step2')}
          </Text>
          <Text style={[styles.text, styles.instructions]}>
            {t('invitation.initial.step2Instructions')}
          </Text>
          <Pressable style={styles.button} onPress={openApp}>
            <MaterialCommunityIcons
              name="key-outline"
              color={Colors.white}
              size={24}
            />
            <Text style={[styles.text, styles.link]}>
              {t('invitation.initial.login')}
            </Text>
          </Pressable>
        </View>
      </View>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.black,
    width: '100%',
    height: '100%',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    paddingHorizontal: 50,
  },
  text: {
    color: Colors.white,
  },
  row: {
    paddingBottom: 30,
  },
  heading: {
    fontSize: 34,
    fontWeight: '700',
  },
  step: {
    fontSize: 24,
    fontWeight: '400',
  },
  instructions: {
    fontSize: 24,
    fontWeight: '700',
    paddingBottom: 16,
  },
  button: {
    flexDirection: 'row',
    borderRadius: 12,
    backgroundColor: Colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 44,
  },
  link: {
    marginLeft: 5,
    fontSize: 16,
    fontWeight: '500',
  },
});
