import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  ILogger,
  MediaItem,
  MediaPickerProps,
  MediaType,
  PickerMode,
  getAcceptedFormatsForMediaTypes,
  getFileDataFromUrl,
  getMediaTypeByExt,
  useGrippContext,
} from '@gripp/shared-logic';
import { Colors } from '@gripp/shared-ui';
import { useRef } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import { useMediaUpload } from '../../hooks/useMediaUpload';

export const MediaPicker = ({
  acceptedMediaTypes = [MediaType.Image],
  ...props
}: MediaPickerProps) => {
  const { logger } = useGrippContext();
  let icon;
  if (props.icon) {
    icon = props.icon;
  } else {
    icon = props.mode === PickerMode.CAMERA ? 'camera' : 'image-plus';
  }
  const iconSize = props.iconSize || 24;
  const fileUploadRef = useRef(null);
  const uploadMedia = useMediaUpload({
    onProgress: (mediaId: string, progress: number) => {
      props.setProgress(mediaId, progress);
    },
    onComplete: (mediaId: string) => {
      props.setUploading && props.setUploading(false);
    },
    onStart: (mediaItem: MediaItem) => {
      props.setUploading && props.setUploading(true);
      props.setMedia({
        ...mediaItem,
        isUploading: true,
        uploadProgress: 0,
      });
    },
  });
  const captureProps: any = {};
  if (props.mode === PickerMode.CAMERA) {
    captureProps.capture = 'environment';
  }

  const acceptedFormats = getAcceptedFormatsForMediaTypes(acceptedMediaTypes);

  const onFileSelected = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (!shouldProcessFile(file.name, acceptedMediaTypes, logger)) {
      return;
    }

    await uploadMedia(file, props.workspaceId);
  };

  return (
    <>
      <input
        ref={fileUploadRef}
        type="file"
        style={{ display: 'none' }}
        accept={acceptedFormats}
        onChange={onFileSelected}
        multiple={false}
        {...captureProps}
      />
      {props.isIconInBox ? (
        <MaterialCommunityIcons
          name={props.icon || 'camera-outline'}
          color={props.iconColor || Colors.white}
          size={props.iconSize || 16}
          onPress={() => fileUploadRef.current.click()}
        />
      ) : (
        <Pressable onPress={() => fileUploadRef.current.click()}>
          <Card
            mode={props.cardStyle || undefined}
            style={props.style || styles.cardIcon}
          >
            <MaterialCommunityIcons
              name={icon}
              color={props.iconColor || Colors.black}
              size={iconSize}
            />
          </Card>
        </Pressable>
      )}
    </>
  );
};

const shouldProcessFile = (
  fileName: string,
  acceptedMediaTypes: MediaType[],
  logger?: ILogger
) => {
  try {
    const ext = getFileDataFromUrl(fileName).ext;
    const fileMediaType = getMediaTypeByExt(ext);

    if (!acceptedMediaTypes.includes(fileMediaType)) {
      logger?.warning(
        `Media picker web - format does not match accepted types: ${fileMediaType}`
      );
      return false;
    }

    return true;
  } catch (e) {
    logger?.warning(`Media picker web - failed to check file format: ${e}`);
    return false;
  }
};

const styles = StyleSheet.create({
  imageCard: {
    height: 100,
    width: 100,
  },
  imageCardContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
  },
  cardIcon: {
    height: 45,
    width: 45,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginTop: -30,
    marginRight: -20,
  },
  progress: {
    marginTop: 5,
  },
});
