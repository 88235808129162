import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Divider, Menu } from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { DeleteIssueModal } from './deleteIssueModal';
import { FunctionId } from './issueFormatUtils';
import { IssueTitleModal } from './issueTitleModal';
import { ReassignModal } from './reassignModal';

type IssueMenuProps = {
  issue: any;
  onIssueDelete: () => void;
  onPrint?: () => Promise<void>;
};

export const IssueMenu = (props: IssueMenuProps) => {
  const { t } = useTranslation();

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuFired, setMenuFired] = useState(0);
  const [isTitleModalVisible, setIsTitleModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isReassignModalVisible, setIsReassignModalVisible] = useState(false);

  const isDesktop = () => Platform.OS === 'web';

  const openMenu = () => {
    setMenuVisible(true);
    setMenuFired(Date.now());
  };

  const closeMenu = () => {
    // Add fired check to combat paper menu bug:
    // https://github.com/callstack/react-native-paper/issues/3716
    if (Date.now() - menuFired > 400) {
      setMenuVisible(false);
    }
  };

  if (!props.issue) {
    return null;
  }

  return (
    <View>
      <Menu
        statusBarHeight={0}
        visible={menuVisible}
        onDismiss={closeMenu}
        contentStyle={[
          isDesktop() ? styles.desktopMenu : styles.mobileMenu,
          styles.white,
        ]}
        anchor={
          <TouchableOpacity onPress={openMenu}>
            <MaterialCommunityIcons
              name="dots-vertical"
              color={Colors.primary}
              size={24}
            />
          </TouchableOpacity>
        }
      >
        <Menu.Item
          leadingIcon={'pencil'}
          style={styles.white}
          onPress={() => {
            setIsTitleModalVisible(true);
            closeMenu();
          }}
          title={t('issue.menu.editTitle')}
        />
        <Menu.Item
          leadingIcon="swap-horizontal"
          style={styles.white}
          title={t('issue.menu.reassign')}
          onPress={() => {
            setIsReassignModalVisible(true);
            closeMenu();
          }}
        />
        {Platform.OS === 'web' && (
          <Menu.Item
            onPress={() => {
              props.onPrint && props.onPrint();
              closeMenu();
            }}
            leadingIcon="printer"
            title={t('issue.menu.print')}
          />
        )}
        {props.issue.createdBy.id !== FunctionId && (
          <>
            <Divider style={styles.divider} />
            <Menu.Item
              style={styles.white}
              titleStyle={styles.delete}
              onPress={() => {
                setIsDeleteModalVisible(true);
                closeMenu();
              }}
              title={t('issue.menu.delete')}
            />
          </>
        )}
      </Menu>
      <IssueTitleModal
        visible={isTitleModalVisible}
        hide={() => setIsTitleModalVisible(false)}
        issueId={props.issue?.id}
        currentTitle={props.issue?.title}
      />
      <DeleteIssueModal
        visible={isDeleteModalVisible}
        hide={() => setIsDeleteModalVisible(false)}
        issueId={props.issue?.id}
        onDelete={props.onIssueDelete}
      />
      <ReassignModal
        visible={isReassignModalVisible}
        hide={() => setIsReassignModalVisible(false)}
        issue={props.issue}
        presentWebStyle={isDesktop()}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  white: {
    backgroundColor: Colors.white,
  },
  delete: {
    paddingLeft: 10,
    color: Colors.red,
  },
  mobileMenu: {
    top: 60,
    right: 20,
  },
  desktopMenu: {
    top: 30,
    right: 150,
  },
  divider: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 20,
    marginRight: 20,
  },
});
