import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Button } from 'react-native-paper';
import { Spacing } from '../../styles';
import { Colors } from '../../themes';
import { SaveFormProps } from './formTypes';

export const SaveFormBottom = (props: SaveFormProps) => {
  const { t } = useTranslation();
  const canSave = useMemo(() => {
    const isDirty = props.isDirty === undefined ? true : props.isDirty;
    const isValid = props.isValid === undefined ? true : props.isValid;
    return isDirty && isValid;
  }, [props.isDirty, props.isValid]);

  return (
    <>
      {props.children}
      <View style={[styles.bottonContainer, props.hasBorder && styles.border]}>
        <Button
          disabled={!canSave}
          style={[styles.button, props.buttonStyle]}
          buttonColor={Colors.primary}
          labelStyle={styles.labelStyle}
          mode="contained"
          onPress={props.onSubmit}
        >
          {props.saveLabel ? props.saveLabel : t('saveChanges')}
        </Button>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    borderRadius: 8,
    width: '100%',
    maxWidth: 156,
    height: 44,
    justifyContent: 'center',
  },
  labelStyle: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.white,
  },
  bottonContainer: {
    paddingBottom: Spacing.basePadding.base * 1.5,
    zIndex: -1,
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.grayDivider,
  },
});
