import {
  MediaType,
  PickerMode,
  useCurrentWorkspace,
} from '@gripp/shared-logic';
import {
  Colors,
  NewIssue as NewIssueBase,
  TopModalBar,
} from '@gripp/shared-ui';
import { useCallback } from 'react';
import { Dimensions, Modal, StyleSheet, View } from 'react-native';
import { MediaPicker } from '../../shared/components/media/mediaPicker';

export type NewIssueModalProps = {
  assetId?: string;
  workspaceId?: string;
  isOpened: boolean;
  onComplete: () => void;
  onCancel?: () => void;
  modalType: 'sheet' | 'modal';
  acceptedMediaTypes?: MediaType[];
};

export const NewIssueModal = (props: NewIssueModalProps) => {
  const onComplete = () => {
    props.onComplete();
  };

  const onCancel = () => {
    if (!props.onCancel) {
      onComplete();
    } else {
      props.onCancel();
    }
  };

  return (
    <Modal
      presentationStyle={props.modalType === 'sheet' ? 'pageSheet' : undefined}
      transparent
      visible={props.isOpened}
      onRequestClose={props.onCancel}
    >
      <View style={getOuterContainerStyle(props.modalType)}>
        <NewIssueBody {...props} onComplete={onComplete} onCancel={onCancel} />
      </View>
    </Modal>
  );
};

export const NewIssueBody = (props: NewIssueModalProps) => {
  const currentWorkspace = useCurrentWorkspace();
  const getWorkspaceId = useCallback(() => {
    return props.workspaceId ?? currentWorkspace?.id;
  }, [props.workspaceId, currentWorkspace]);

  return (
    <View style={getInnerContainerStyle(props.modalType)}>
      <NewIssueBase
        assetId={props.assetId as string}
        workspaceId={getWorkspaceId()}
        mediaPicker={MediaPicker}
        acceptedMediaTypes={props.acceptedMediaTypes}
        onIssueCreated={props.onComplete}
        topBar={TopModalBar}
        mode={PickerMode.PICKER}
        onCancel={props.onCancel}
      />
    </View>
  );
};

const getOuterContainerStyle = (modalType: string) => {
  return modalType === 'sheet' ? styles.sheetContainer : styles.modalContainer;
};

const getInnerContainerStyle = (modalType: string) => {
  return modalType === 'sheet'
    ? styles.sheetInnerContainer
    : styles.innerContainer;
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: Dimensions.get('window').height / 7,
    backgroundColor: Colors.blackInactive,
  },
  innerContainer: {
    width: '100%',
    maxWidth: 560,
    minHeight: 461,
  },
  sheetContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  sheetInnerContainer: {
    width: '100%',
    height: '100%',
  },
});
