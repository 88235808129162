import i18n from 'i18next';
import { Platform } from 'react-native';
import { initReactI18next } from 'react-i18next';
import * as en from './locales/en.json';
import * as es from './locales/es.json';
import { NativeModules } from 'react-native';

const getDeviceLanguage = () => {
  if (Platform.OS === 'android') {
    return NativeModules['I18nManager']?.localeIdentifier || 'en';
  } else if (Platform.OS === 'ios') {
    return (
      NativeModules['SettingsManager']?.settings?.AppleLocale ||
      NativeModules['SettingsManager']?.settings?.AppleLanguages[0] ||
      'en'
    );
  }
  return navigator?.language || navigator?.languages[0] || 'en';
};

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  debug: true,
  resources: {
    en: { translation: en },
    es: { translation: es },
  },
  lng: getDeviceLanguage(),
  fallbackLng: 'en',
});

export default i18n;

export const changeLanguage = async (language: string) => {
  const newLanguageCode = language.split(/[-_]/)[0];
  const actualLanguageCode = i18n.language.split(/[-_]/)[0];

  if (actualLanguageCode !== newLanguageCode) {
    i18n.changeLanguage(newLanguageCode);
  }
};
