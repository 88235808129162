import { StyleSheet, View } from 'react-native';
import { Outlet } from 'react-router-dom';
import { RoutinesBanner } from './routinesBanner';
import { RoutinesList } from './routinesList';

type RoutineFoldProps = {
  onRoutineSelected: (routineId: string) => void;
  maxHeight: number;
};

export const RoutineFold = (props: RoutineFoldProps) => {
  const heightStyle = { maxHeight: props.maxHeight };
  const listMaxHeight = props.maxHeight && props.maxHeight - (151 + 20);

  return (
    <View style={[heightStyle, styles.pageContainer]}>
      <RoutinesBanner />

      <View style={styles.foldContainer}>
        <View style={{ width: '45%' }}>
          <RoutinesList
            onRoutineSelected={props.onRoutineSelected}
            listMaxHeight={listMaxHeight}
          />
        </View>
        <View style={styles.gutter} />
        <Outlet />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
  },
  foldContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  gutter: {
    width: 24,
  },
});

export default RoutineFold;
