import { RoutineType, WorkflowQuestionType } from '@gripp/shared-logic';
import { Colors } from '@gripp/shared-ui';
import { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';

export interface RoutineCardData {
  id: string;
  name: string;
  order: number;
  type: string;
  config: any;
  createdAt: string;
  createdBy: {
    name: {
      first: string;
      last: string;
    };
  };
  assignedCount: number;
}

export type RoutineCardProps = {
  routine: RoutineCardData;
};

export const RoutineCard = (props: RoutineCardProps) => {
  const { routine } = props;
  const [isIncomplete, setIsIncomplete] = useState(false);

  useEffect(() => {
    if (routine.type === RoutineType.Workflow) {
      const questions = routine.config.pages.flatMap((p) =>
        p.questions.filter((q) => q.type === WorkflowQuestionType.Routine)
      );

      if (questions.some((q) => !q.workspaceRoutineId)) {
        setIsIncomplete(true);
      }
    }
  }, [routine]);

  return (
    <View style={styles.container}>
      <Text style={styles.name}>{routine.name}</Text>
      {isIncomplete && <Text style={styles.incomplete}>***</Text>}
      {routine.assignedCount > 0 && (
        <View style={styles.assignedCountContainer}>
          <Text>({routine.assignedCount})</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    fontSize: 14,
    fontWeight: '500',
  },
  incomplete: {
    color: Colors.red,
    marginLeft: 10,
    fontSize: 20,
  },
  assignedCountContainer: {
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
