import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  WorkflowPageConfig,
  WorkflowQuestionConfig,
  WorkflowQuestionType,
  newGUID,
} from '@gripp/shared-logic';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../../../themes';
import { DragList } from '../../../dragList/dragList';
import { RoutineAdminInputText } from '../../components/routineAdminInputText';
import { AddQuestion } from './addQuestion';
import { WorkflowAdminQuestion } from './workflowAdminQuestion';

type WorkflowAdminPageProps = {
  page: WorkflowPageConfig;
  index: number;
  control: Control;
};

export const WorkflowAdminPage = (props: WorkflowAdminPageProps) => {
  const { t } = useTranslation();
  const { index, control } = props;
  const indexPrefix = `pages.${index}`;
  const i18nPrefix = 'routines.workflow.admin.page';
  const {
    fields: questionFields,
    append: appendQuestion,
    remove: removeQuestion,
    move: moveQuestion,
    update: updateQuestion,
  } = useFieldArray({
    control: control,
    name: `${indexPrefix}.questions`,
  });

  const DefaultQuestion: WorkflowQuestionConfig = {
    id: newGUID(),
    name: `Question ${questionFields.length + 1}`,
    order: questionFields.length + 1,
    type: WorkflowQuestionType.Text,
    required: false,
    instructions: '',
  };

  const onAddQuestion = (type: WorkflowQuestionType) => {
    appendQuestion({
      ...DefaultQuestion,
      type,
    });
  };

  const onDeleteQuestion = (questionIndex: number) => {
    removeQuestion(questionIndex);
  };

  const renderItem = (question: any) => {
    const findQuestionIndex = questionFields.findIndex(
      (q) => q.id === question.id
    );

    return (
      <View style={styles.questionContainer}>
        <Pressable
          style={styles.delete}
          onPress={() => onDeleteQuestion(findQuestionIndex)}
        >
          <MaterialCommunityIcons
            name={'close-circle-outline'}
            color={Colors.primary}
            size={24}
          />
        </Pressable>
        <WorkflowAdminQuestion
          key={`question_${question.id}`}
          control={control}
          index={findQuestionIndex}
          question={question as WorkflowQuestionConfig}
          type={(question as any).type}
          pagePrefix={indexPrefix}
        />
      </View>
    );
  };

  const onReorderedFromTo = (fromIndex: number, toIndex: number) => {
    moveQuestion(fromIndex, toIndex);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.header}>
        {t(`${i18nPrefix}.page`, { page: index + 1 })}
      </Text>

      <RoutineAdminInputText
        control={props.control}
        i18nPrefix={i18nPrefix}
        name="instructions"
        indexPrefix={indexPrefix}
        textArea={true}
      />
      <View style={styles.questions}>
        <DragList
          list={questionFields}
          pivotKey={'id'}
          sortKey={'order'}
          renderCustomItem={renderItem}
          onReorderedFromTo={onReorderedFromTo}
          customItemStyle={styles.question}
        />
      </View>
      <AddQuestion i18nPrefix={i18nPrefix} onPress={onAddQuestion} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: Colors.black,
    paddingHorizontal: 10,
  },
  header: {
    fontSize: 18,
    fontWeight: '700',
    marginBottom: 20,
  },
  questions: {
    marginBottom: 20,
    borderColor: Colors.grayDivider,
    borderWidth: 1,
    paddingTop: 10,
  },
  question: {
    height: '100%',
  },
  questionContainer: {
    flex: 1,
    maxWidth: '80%',
    padding: 12,
  },
  delete: {
    alignSelf: 'flex-end',
    margin: -10,
    top: -5,
    right: -5,
  },
});
