import { MaterialCommunityIcons } from '@expo/vector-icons';
import { FormFactor, SectionHeader } from '@gripp/shared-ui';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { UIMatch, useMatches } from 'react-router-dom';
import { Toolbar } from './toolbar';

export const Header = () => {
  const { t } = useTranslation();

  const matches = useMatches() as UIMatch<
    unknown,
    {
      header: {
        titleKey: string;
        icon: string;
      };
    }
  >[];
  const headerMatch = matches.filter((match) => match.handle?.header);
  const header = headerMatch[0]?.handle?.header;

  return (
    <View style={styles.container}>
      <SectionHeader
        title={t(header?.titleKey)}
        icon={
          header?.icon as React.ComponentProps<
            typeof MaterialCommunityIcons
          >['name']
        }
        variant={FormFactor.Desktop}
      />
      <Toolbar />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
