import { Media } from '@gripp/shared-logic';
import { StyleSheet, View } from 'react-native';
import { useTypedMedia } from '../media';
import { RenderMediaItem } from '../media/renderMediaItem';

type MessageMediaProps = {
  media?: Media[];
  displayWidth?: number;
};

export const MessageMedia = (props: MessageMediaProps) => {
  const { typedMedia: messageMedia } = useTypedMedia(props.media);

  return (
    messageMedia.length > 0 &&
    props.displayWidth &&
    messageMedia.map((item) => (
      <View style={styles.mediaItemContainer} key={item.id}>
        <RenderMediaItem
          mediaItem={item}
          key={item.id}
          displayWidth={props.displayWidth}
          displayMode="outlined"
        />
      </View>
    ))
  );
};

const styles = StyleSheet.create({
  mediaItemContainer: {
    marginTop: 8,
  },
});
