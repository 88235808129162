import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../../themes/colors';
import {
  ControlledCheckboxItem,
  ControlledRadioButtons,
  useFormContext,
} from '../../forms';

type CounterDecimalSelectionProps = {
  control: Control;
  decimalFieldName: string;
  decimalPlacesFieldName: string;
  i18nPrefix: string;
};

export const CounterDecimalSelection = ({
  control,
  decimalFieldName,
  decimalPlacesFieldName,
  i18nPrefix,
}: CounterDecimalSelectionProps) => {
  const { t } = useTranslation();

  const [showDecimalPlaces, setShowDecimalPlaces] = useState(false);

  const { setValue, watch } = useFormContext();
  const decimalValue = watch(decimalFieldName);

  useEffect(() => {
    setShowDecimalPlaces(decimalValue);

    if (!decimalValue) {
      setValue(decimalPlacesFieldName, undefined);
    }
  }, [decimalValue]);

  const listSelections = [
    {
      label: '0.1',
      value: 1,
    },
    {
      label: '0.12',
      value: 2,
    },
    {
      label: '0.123',
      value: 3,
    },
  ];

  return (
    <View style={styles.selectorContainer}>
      <ControlledCheckboxItem
        control={control}
        name={decimalFieldName}
        label={t(`${i18nPrefix}.decimalPlaces`)}
        labelStyle={styles.label}
        position="trailing"
      />
      {showDecimalPlaces && (
        <View style={styles.radioContainer}>
          <ControlledRadioButtons
            control={control}
            name={decimalPlacesFieldName}
            options={listSelections}
            horizontal={false}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  selectorContainer: {
    marginVertical: 10,
    flexDirection: 'row',
  },
  label: {
    fontSize: 16,
    lineHeight: 16,
    color: Colors.black,
    fontWeight: '700',
  },
  radioContainer: {
    paddingTop: 10,
  },
});
