import { Modal, Platform, StyleSheet, View } from 'react-native';
import { Colors } from '../../themes/colors';
import { CloseButton } from './closeButton';
import { ZoomableImage } from './zoomableImage';

type ImageLightboxProps = {
  url?: string;
  isOpened: boolean;
  onClose: () => void;
};

export const ImageLightBox: React.FC<ImageLightboxProps> = (
  props: ImageLightboxProps
) => {
  return (
    <Modal
      transparent={true}
      visible={props.isOpened}
      onRequestClose={props.onClose}
    >
      <View style={styles.modalContainer}>
        <View style={styles.innerContainer}>
          <View style={styles.buttonClose}>
            <CloseButton onClose={props.onClose} />
          </View>
          <ZoomableImage uri={props.url} />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.blackInactive,
    position: 'relative',
    ...Platform.select({
      web: {
        cursor: 'default',
      },
    }),
  },
  innerContainer: {
    alignItems: 'center',
  },
  buttonClose: {
    alignSelf: 'center',
    zIndex: 9,
    position: 'absolute',
    ...Platform.select({
      web: {
        top: 20,
      },
      default: {
        justifyContent: 'center',
        bottom: 84,
      },
    }),
  },
});
