import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Colors } from '@gripp/shared-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { useLocation, useNavigate } from 'react-router-dom';

export interface NavLinkProps {
  icon: string;
  titleKey: string;
  route: string;
}

export const NavLink = (props: NavLinkProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = location.pathname === props.route;

  return (
    <MenuLink
      icon={
        props.icon as React.ComponentProps<
          typeof MaterialCommunityIcons
        >['name']
      }
      title={t(props.titleKey)}
      isActive={isActive}
      onPress={() => navigate(props.route)}
    />
  );
};

interface MenuLinkProps {
  icon: React.ComponentProps<typeof MaterialCommunityIcons>['name'];
  title: string;
  isActive: boolean;
  onPress: () => void;
}

export const MenuLink = ({
  icon,
  title,
  isActive = false,
  onPress,
}: MenuLinkProps) => {
  const [isHoverStyle, setIsHoverStyle] = useState(false);
  const style = isActive ? styles.active : styles.pending;

  return (
    <View style={styles.menuItem}>
      <Pressable
        style={[
          isActive ? styles.activeBg : isHoverStyle ? styles.hover : {},
          styles.buttonContainer,
        ]}
        onHoverIn={() => setIsHoverStyle(true)}
        onHoverOut={() => setIsHoverStyle(false)}
        onPress={() => onPress()}
      >
        <MaterialCommunityIcons
          name={icon}
          size={24}
          style={[style, { marginRight: 6 }]}
        />
        <Text style={[style, styles.label]}>{title}</Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  menuItem: {
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    flex: 1,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 36,
    flex: 1,
    paddingVertical: 6,
    paddingHorizontal: 12,
  },
  label: {
    fontSize: 16,
    lineHeight: 20,
    fontStyle: 'normal',
    fontWeight: '600',
  },
  active: {
    color: Colors.black,
  },
  pending: {
    color: Colors.grayDivider,
  },
  hover: { backgroundColor: Colors.blackText, borderRadius: 8 },
  activeBg: { backgroundColor: Colors.white, borderRadius: 8 },
});
