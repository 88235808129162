import { FC } from 'react';
import { RoutineComponentProps, RoutineModule } from '../types';
import {
  WorkflowRoutineConfig,
  WorkflowRoutineState,
} from '@gripp/shared-logic';
import {
  WorkflowAdminForm,
  WorkflowAdminSchema,
} from './admin/workflowAdminForm';
import { WorkflowExecutionForm } from './execution/workflowExecutionForm';
import { WorkflowButtons } from './execution/workflowButtons';
import { WorkflowMessage } from './message/workflowMessage';

const NoOpStateComponent: FC<
  RoutineComponentProps<WorkflowRoutineConfig, WorkflowRoutineState>
> = () => null;

export const WorkflowModule: RoutineModule<
  WorkflowRoutineConfig,
  WorkflowRoutineState
> = {
  header: NoOpStateComponent,
  buttons: WorkflowButtons,
  executionForm: WorkflowExecutionForm,
  adminForm: WorkflowAdminForm,
  adminSchema: WorkflowAdminSchema,
  message: WorkflowMessage,
};
