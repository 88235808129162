import { MediaQuestionConfig, MediaResponseState } from '@gripp/shared-logic';
import { RoutineQuestionModule } from '../types';
import { MediaQuestionExecutionForm } from './mediaQuestionExecutionForm';
import { MediaQuestionMessage } from './mediaQuestionMessage';
import { MediaQuestionAdminForm } from './mediaQuestionAdminForm';

export const MediaQuestionModule: RoutineQuestionModule<
  MediaQuestionConfig,
  MediaResponseState
> = {
  adminForm: MediaQuestionAdminForm,
  executionForm: MediaQuestionExecutionForm,
  message: MediaQuestionMessage,
};
