import { Platform } from 'react-native';
import { ColumnModel, FilterModel } from '../../filter';

export const GroupFilterKey = 'GROUP_FILTER';
export const GroupListOrderKey = 'GROUP_LIST_ORDER_KEY';

export const GroupFilterBaseColumns = ['reports', 'assets', 'people'];
export const GlobalAdminGroupColumns = [];

export class GroupFilterModel implements FilterModel, ColumnModel {
  keyword?: string;
  columns?: string[];

  constructor(data?: any) {
    this.keyword = data?.keyword;
    this.columns =
      data?.columns ||
      (Platform.OS === 'web' ? ['name', 'assets', 'people', 'reports'] : []);
  }

  public getFilter(): any {
    const filter = {} as any;

    if (this.keyword) {
      filter.or = [
        { name: { contains: this.keyword } },
        { description: { contains: this.keyword } },
      ];
    }

    return filter;
  }

  public getColumnsCount(): number {
    let count = 0;

    if (this.columns) {
      count += this.columns.length;
    }

    return count;
  }
}
