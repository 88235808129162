import { SaveButton } from './saveButton';
import { TopBar } from './topBar';
import { TopComponentBarBaseProps } from './types';

export const TopComponentBar = (props: TopComponentBarBaseProps) => {
  const left = props.left || props.backButton;
  const right =
    props.right ||
    (props.showSave ? (
      <SaveButton
        onPress={props.onRightPress!}
        disabled={props.rightPressDisabled}
        label={props.saveLabel}
      />
    ) : undefined);

  return (
    <TopBar
      style={props.style}
      left={left}
      title={props.title}
      right={right}
      additionalHeaderBlock={props.additionalHeaderBlock}
    />
  );
};
