import { RoutineConfig, getFullName } from '@gripp/shared-logic';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { MessageComponentProps } from '../../issue/types';
import { routineMessageStyles } from './routineMessageStyles';

interface CommonRoutineMessageHeaderProps extends MessageComponentProps {
  children: React.ReactNode;
}

export const CommonRoutineMessageHeader = (
  props: CommonRoutineMessageHeaderProps
) => {
  const { t } = useTranslation();
  const assetRoutine = props.message.assetRoutine!;
  const who = assetRoutine.createdBy;
  const config = assetRoutine.routineVersion.config as RoutineConfig;
  const name = getFullName(who.name);

  return (
    <View style={routineMessageStyles.container}>
      <Text style={routineMessageStyles.messageTitle}>
        {t('routines.message.messageTitle', { name: name })}
      </Text>
      <Text style={routineMessageStyles.questionLabel}>
        {config.routineButtonText}
      </Text>
      {props.children}
    </View>
  );
};
