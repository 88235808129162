import {
  OrderByInput,
  useAccountListOrder,
  useSetAccountListOrder,
} from '@gripp/shared-logic';
import { InfiniteDataGrid } from '@gripp/shared-ui';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useMaxHeight } from '../../shared/hooks/useMaxHeight';
import { useColumnsAccount } from './useColumnsAccount';

type AccountListProps = {
  filter: any;
  updateRowsSelected?: (rows: Array<any>) => void;
  showCheckboxColumn: boolean;
  showImpersonateWorkspace: boolean;
  onItemsCountChange?: (count: number) => void;
  addToGroupsComponent?: JSX.Element;
};

export const AccountList = ({
  filter,
  updateRowsSelected,
  showCheckboxColumn,
  showImpersonateWorkspace,
  onItemsCountChange,
  addToGroupsComponent,
}: AccountListProps) => {
  const { containerMaxHeight, listMaxHeight } = useMaxHeight(64, 0, 228);

  const { data: accountOrder } = useAccountListOrder();
  const { mutateAsync: setAccountOrder } = useSetAccountListOrder();

  const columns = useColumnsAccount(
    showCheckboxColumn,
    showImpersonateWorkspace
  );

  const onOrderByChange = async (orderBy: OrderByInput) => {
    await setAccountOrder(orderBy);
  };

  return (
    (showImpersonateWorkspace || filter.workspace) && (
      <View
        style={[
          styles.accountsContainer,
          {
            maxHeight: containerMaxHeight,
          },
        ]}
      >
        <InfiniteDataGrid
          columns={columns}
          query={FIND_WORKSPACE_ACCOUNTS}
          modelName="account"
          orderBy={accountOrder}
          pageSize={50}
          filter={filter}
          hideHeader={false}
          listMaxHeight={listMaxHeight}
          showScrollIndicator={true}
          updateRowsSelected={updateRowsSelected}
          onItemsCountChange={onItemsCountChange}
          onOrderByChange={onOrderByChange}
          headerContent={addToGroupsComponent}
        />
      </View>
    )
  );
};

const FIND_WORKSPACE_ACCOUNTS = `
  query FindWorkspaceAccounts($filter: AccountFilter, $page: PageRequest, $orderBy: OrderByInput) {
    findAccounts(filter: $filter, page: $page, orderBy: $orderBy) {
      count
      offset
      limit
      items {
        id
        createdAt
        name {
          first
          last
        }
        email
        phone {
          callingCode,
          countryCode,
          phoneNumber
        }
        jobTitle
        roles {
          id
          name
        }
        groups {
          id
          name
        }
        workspace {
          id
          name
        }
      }
    }
  }
`;

const styles = StyleSheet.create({
  accountsContainer: {
    flex: 1,
    borderRadius: 8,
    overflow: 'hidden',
    zIndex: -1,
  },
});
