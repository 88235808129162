import { useGroupListFilter, useSetGroupListFilter } from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { TextInput } from 'react-native-paper';
import { Colors } from '../../themes';
import { DebouncedTextInput } from '../forms';

export const GroupSearchInput = () => {
  const { t } = useTranslation();
  const { data: groupFilter } = useGroupListFilter();
  const { mutateAsync } = useSetGroupListFilter();

  const [text, setText] = useState('');

  useEffect(() => {
    if (!text && groupFilter?.keyword) {
      setText(groupFilter.keyword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupFilter]);

  const updateSearch = async (keyword: string) => {
    groupFilter.keyword = keyword;
    await mutateAsync(groupFilter);
  };

  return (
    groupFilter && (
      <DebouncedTextInput
        debounceTimeMs={500}
        debounceLength={3}
        debounceFunc={updateSearch}
        placeholder={t('groups.filter.searchPlaceholder')}
        value={text}
        onBlur={() => null}
        onChangeText={(text) => setText(text)}
        left={<TextInput.Icon icon="magnify" color={Colors.black} size={24} />}
        right={
          text ? (
            <TextInput.Icon
              icon="close"
              size={20}
              color={Colors.black}
              onPress={() => setText('')}
            />
          ) : (
            <View />
          )
        }
        height={44}
        outlineStyle={styles.inputStyle}
        disableInnerStyle={true}
      />
    )
  );
};

const styles = StyleSheet.create({
  inputStyle: {
    backgroundColor: Colors.white,
    borderColor: Colors.grayDivider,
    borderWidth: 1,
    borderRadius: 8,
  },
});
