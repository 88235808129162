import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  AssetRoutine,
  IssueMessageType,
  Message,
  WorkflowPageConfig,
  WorkflowQuestionConfig,
  WorkflowResponseState,
} from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../../../themes/colors';
import { useIssueContext } from '../../../issue/issueContext';
import { WorkflowMessageQuestion } from './workflowMessageQuestion';

type WorkflowMessagePageProps = {
  page: WorkflowPageConfig;
  responses: WorkflowResponseState[];
  exceptionsOnly?: boolean;
  assetRoutine: AssetRoutine;
  message: Message;
};

interface QuestionResponse {
  index: number;
  config: WorkflowQuestionConfig;
  response: WorkflowResponseState;
}

export const WorkflowMessagePage = (props: WorkflowMessagePageProps) => {
  const { t } = useTranslation();
  const [initialized, setInitialized] = useState(false);
  const [responses, setResponses] = useState<QuestionResponse[]>([]);
  const { onRouteToIssue } = useIssueContext();
  useEffect(() => {
    let responses = props.page.questions
      .sort((a, b) => a.order - b.order)
      .map((question, index) => {
        const response = props.responses.find(
          (response) => response.questionId === question.id
        )!;

        return { index: index + 1, config: question, response };
      });
    if (props.exceptionsOnly) {
      responses = responses.filter(
        (x) =>
          x.response.exceptionCount !== undefined &&
          x.response.exceptionCount > 0
      );
    }

    setResponses(responses);
    setInitialized(true);
  }, []);

  const exceptionCount = props.assetRoutine.exceptionCount ?? 0;
  const linkButtonTextKey =
    props.message.type === IssueMessageType.RoutineExceptionMessage
      ? 'routines.exceptions.viewRelatedRoutine'
      : 'routines.exceptions.viewRelatedReport';

  return (
    initialized && (
      <>
        {exceptionCount > 0 && (
          <Text style={styles.exceptionCount}>
            {t('routines.exceptions.exceptionsNoticed', {
              count: props.assetRoutine.exceptionCount,
            })}
          </Text>
        )}
        {responses.map((response) => (
          <WorkflowMessageQuestion
            key={response.index}
            index={response.index}
            question={response.config}
            response={response.response}
          />
        ))}
        {exceptionCount > 0 && props.message.linkedIssue && (
          <>
            {props.message.type === IssueMessageType.RoutineMessage && (
              <Text style={styles.additionalText}>
                {t('routines.exceptions.reportCreated')}
              </Text>
            )}
            <Pressable
              onPress={() => onRouteToIssue(props.message.linkedIssue?.id)}
              style={styles.linkButton}
            >
              <Text style={styles.linkButtonText}>{t(linkButtonTextKey)}</Text>
              <MaterialCommunityIcons
                name="arrow-right"
                size={24}
                color={Colors.primary}
              />
            </Pressable>
            {props.message.type ===
              IssueMessageType.RoutineExceptionMessage && (
              <Text style={styles.additionalText}>
                {t('routines.exceptions.addDetails')}
              </Text>
            )}
          </>
        )}
      </>
    )
  );
};

const styles = StyleSheet.create({
  exceptionCount: {
    marginVertical: 8,
    color: Colors.redText,
  },
  linkButton: {
    marginTop: 16,
    borderWidth: 2,
    borderColor: Colors.primary,
    borderRadius: 8,
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkButtonText: {
    color: Colors.primary,
    marginRight: 8,
    fontWeight: '500',
  },
  additionalText: {
    marginTop: 8,
  },
});
