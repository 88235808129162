/*
  base component shared by topNavigationBar and topComponentBar
*/
import { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import { Appbar, Text } from 'react-native-paper';
import { Colors } from '../../themes';
import { TopBarProps } from './types';

export const TopBar: FC<TopBarProps> = (props: TopBarProps) => {
  return (
    <Appbar.Header
      statusBarHeight={0}
      mode="center-aligned"
      style={[
        props.style
          ? [props.style, styles.headerContainer]
          : styles.headerContainer,
        !props.hideBottomBorder && styles.bottomBorder,
      ]}
    >
      {props.left && props.left}
      <Appbar.Content
        title={
          <View style={styles.titleContainer}>
            {props.additionalHeaderBlock && props.additionalHeaderBlock}
            <Text style={styles.titleLabel}>{props.title}</Text>
          </View>
        }
      />
      {props.right && props.right}
    </Appbar.Header>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: Colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlignVertical: 'center',
  },
  bottomBorder: {
    borderBottomColor: Colors.graySpacer,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 3,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleLabel: {
    fontSize: 16,
    fontWeight: '700',
    textAlignVertical: 'center',
  },
});
