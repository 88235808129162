import { Workspace } from '../../workspace';
import { Permission } from './permission';
import { PermissionValues } from '../permissionValues';
import { Media } from '../../media';
import { Phone } from './phone';
import { Preferences } from './preferences';
import { Group } from './group';

const NOOP_PERMISSION = 'none';

export class Name {
  first: string | undefined;
  last: string | undefined;
}

export interface Role {
  id: string;
  name: string;
}

export class Account {
  id: string | undefined;
  name?: Name | undefined;
  email?: string | undefined;
  jobTitle?: string | undefined;
  phone?: Phone | undefined;
  effectivePermissions: string[] | undefined;
  parsedPermissions: Permission[] | undefined;
  roles: Role[] | undefined;
  workspace: Workspace | undefined;
  avatar?: Media;
  preferences?: Preferences;
  groups?: Group[];

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.email = data.email;
      this.jobTitle = data.jobTitle;
      this.phone = data.phone;
      this.roles = data.roles;
      this.workspace = data.workspace;
      this.avatar = data.avatar;
      this.setPermissions(data.roles);
      this.preferences = data.preferences;
      this.groups = data.groups;
    }
  }

  private setPermissions(roles: any[]) {
    this.effectivePermissions = roles
      ?.reduce((a, b) => a.concat(b.permissions), [])
      .sort();
    this.parsedPermissions = this.effectivePermissions?.map(
      (p) => new Permission(p)
    );
  }

  public isGlobalAdmin(): boolean {
    return this.hasPermission(PermissionValues.globalAdmin);
  }

  public hasPermission(permission: string | Permission): boolean {
    if (!permission) throw new Error('Permission request is required');
    if (permission === NOOP_PERMISSION) return true;

    const requested =
      typeof permission === 'string' ? new Permission(permission) : permission;

    const permissions = this.parsedPermissions;
    if (!permissions) return false;

    if (permission === PermissionValues.globalAdmin) {
      return permissions.some(
        (p) => p.scope === '*' && p.action === '*' && !p.context
      );
    }

    return permissions?.some((p) => {
      return (
        ['*', requested.scope].includes(p.scope) &&
        ['*', requested.action].includes(p.action)
      );
    });
  }
}
