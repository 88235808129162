import { ModalButton } from "../../shared/components/modalButton";

export const TagsToolbar = () => {

  return (
      <ModalButton
        modalComponent={({ onComplete}) => (<h1>creating tags</h1>)}
        label="Create Tag"
        icon="plus"
        requiredPermission="tag.create"
      />
  );
};