import {
  AssetRoutineState,
  Issue,
  WorkspaceAsset,
  WorkspaceRoutine,
} from '@gripp/shared-logic';
import { Dimensions, Modal, StyleSheet, View } from 'react-native';
import { Colors } from '../../../themes';
import { TopModalBar } from '../../modals/topModalBar';
import { RoutineModule } from '../types';
import { RoutineForm } from './routineForm';

export type RoutineModalProps = {
  workspaceAsset: WorkspaceAsset;
  module: RoutineModule<any, any>;
  routine?: WorkspaceRoutine;
  state?: AssetRoutineState<any>;
  additionalState?: any;
  visible: boolean;
  sourceIssue?: Issue;
  onCompleted: () => Promise<void>;
  onCanceled: () => void;
  confirmCancel?: boolean;
};

export const RoutineModal = (props: RoutineModalProps) => {
  return (
    <Modal
      visible={props.visible}
      onRequestClose={props.onCanceled}
      transparent={true}
    >
      <View style={styles.outerContainer}>
        <View style={styles.innerContainer}>
          <View style={styles.container}>
            <RoutineForm
              workspaceAsset={props.workspaceAsset}
              state={props.state}
              additionalState={props.additionalState}
              module={props.module}
              routine={props.routine}
              onCompleted={props.onCompleted}
              sourceIssue={props.sourceIssue}
              onCancel={props.onCanceled}
              topBar={TopModalBar}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    backgroundColor: Colors.primaryGrayBackground,
    height: '100%',
  },
  container: {
    flex: 1,
    backgroundColor: Colors.primaryGrayBackground,
    borderRadius: 12,
    paddingBottom: 12,
  },
  innerContainer: {
    width: '100%',
    maxWidth: 560,
    minHeight: 461,
    maxHeight: Dimensions.get('window').height * 0.8,
  },
  outerContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: Dimensions.get('window').height / 7,
    backgroundColor: Colors.blackInactive,
  },
});
