import { DropDownView, FilterType } from '@gripp/shared-ui';
import { View } from 'react-native';
import { AccountFilter } from './accountFilter';

type AccountFilterMenuProps = {
  onAccountFilterOpen: () => void;
  onAccountFilterClose: () => void;
  isOpened: boolean;
  anchorRef: React.RefObject<View>;
  type: FilterType;
};

export const AccountFilterMenu = ({
  onAccountFilterClose,
  onAccountFilterOpen,
  isOpened,
  anchorRef,
  type,
}: AccountFilterMenuProps) => {
  return (
    <DropDownView
      anchorRef={anchorRef}
      isVisible={isOpened}
      onDismiss={onAccountFilterClose}
    >
      <AccountFilter onCloseSideBar={onAccountFilterClose} type={type} />
    </DropDownView>
  );
};
