import { useEffect, useState } from 'react';
import { StyledTextInput, StyledTextInputProps } from './controlledTextInput';

type DebouncedTextInputProps = {
  debounceTimeMs: number;
  debounceLength: number;
  debounceFunc: any;
} & StyledTextInputProps;

export const DebouncedTextInput = (props: DebouncedTextInputProps) => {
  const [timeoutRef, setTimeoutRef] = useState<any>();

  useEffect(() => {
    if (props.value?.length === 0) {
      const update = debounce(props.debounceFunc, 0);
      update('');
    }

    if (props.value?.length < props.debounceLength) {
      return;
    }

    const update = debounce(props.debounceFunc, props.debounceTimeMs);
    update(props.value);
  }, [props.value]);

  const debounce = (func: any, wait: number) => {
    return (...args: any) => {
      clearTimeout(timeoutRef);

      const timeout = setTimeout(() => {
        func(...args);
      }, wait);

      setTimeoutRef(timeout);
    };
  };

  return (
    <StyledTextInput
      placeholder={props.placeholder}
      value={props.value}
      onBlur={() => {}}
      onChangeText={props.onChangeText}
      left={props.left}
      right={props.right}
      height={props.height}
      outlineStyle={props.outlineStyle}
      autoFocus={props.autoFocus}
      disableInnerStyle={true}
    />
  );
};
