import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { MutationArgs, CreateMutationVariables } from '../types';
import { GraphbackOperationType, getDataObject } from './getDataObject';
import { cacheKeys } from './cacheKeys';
import { useFetcher } from './useFetcher';

export const useCreateMutation = (
  opts: MutationArgs
): UseMutationResult<any, any, any, any> => {
  const queryClient = useQueryClient();
  const cacheKey = cacheKeys(opts.modelName).all;

  const fetcherFn = useFetcher(opts.query);

  return useMutation({
    mutationFn: async (variables: CreateMutationVariables) => {
      const data = await fetcherFn(variables);

      return getDataObject(data, opts.modelName, GraphbackOperationType.create);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: cacheKey });
    },
  });
};
