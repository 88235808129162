import {
  useCurrentAccount,
  useCurrentWorkspace,
  useIsFeatureEnabledFunc,
} from '@gripp/shared-logic';
import { useCallback } from 'react';
import { IMenu, MenuItems } from '../components/menuItems';

export const useMenuPermision = () => {
  const { account } = useCurrentAccount();
  const workspace = useCurrentWorkspace(true);
  const isFeatureEnabled = useIsFeatureEnabledFunc();

  const menuList = useCallback(
    (): IMenu[][] =>
      MenuItems.map((group) =>
        group.filter((link) => {
          if (link.requiredFeature && !isFeatureEnabled(link.requiredFeature)) {
            return false;
          }

          if (!link.requiredPermission) return true;

          return account?.hasPermission(link.requiredPermission);
        })
      ).filter((items) => items.length > 0),
    [isFeatureEnabled, account, workspace]
  );

  return menuList;
};
