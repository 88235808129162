import { CounterQuestionModule } from './counter';
import { TextQuestionModule } from './text';
import { WorkflowQuestionType } from '@gripp/shared-logic';
import { RoutineQuestionModuleDictionary } from './types';
import { MediaQuestionModule } from './media';
import { StatusQuestionModule } from './status';
import { ExistingRoutineQuestionModule } from './routine';
import { RadioQuestionModule } from './radio';
import { CheckboxQuestionModule } from './checkbox';
import { QuickConfirmQuestionModule } from './quickConfirm';
import { SignatureQuestionModule } from './signature';

export const RoutineQuestionModules: RoutineQuestionModuleDictionary = {
  [WorkflowQuestionType.Checkbox]: CheckboxQuestionModule,
  [WorkflowQuestionType.Counter]: CounterQuestionModule,
  [WorkflowQuestionType.Media]: MediaQuestionModule,
  [WorkflowQuestionType.QuickConfirm]: QuickConfirmQuestionModule,
  [WorkflowQuestionType.Radio]: RadioQuestionModule,
  [WorkflowQuestionType.Routine]: ExistingRoutineQuestionModule,
  [WorkflowQuestionType.Signature]: SignatureQuestionModule,
  [WorkflowQuestionType.Status]: StatusQuestionModule,
  [WorkflowQuestionType.Text]: TextQuestionModule,
};
