import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { StyleSheet } from 'react-native';
import { DataTable, Text } from 'react-native-paper';

export type DataGridProps = {
  isLoading: boolean;
  data: any;
  columns: ColumnDef<any, any>[];
  onRowClick?: (row: any) => void;
  hideHeader?: boolean;
};

export const DataGrid = (props: DataGridProps) => {
  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return props.isLoading ? (
    <Text>Loading...</Text>
  ) : (
    <DataTable style={styles.table}>
      {!props.hideHeader && (
        <DataTable.Header style={styles.header}>
          {table.getFlatHeaders().map((header) => (
            <DataTable.Title
              key={header.id}
              style={[styles.title, header.column.columnDef.meta?.style]}
            >
              <Text style={styles.titleText}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </Text>
            </DataTable.Title>
          ))}
        </DataTable.Header>
      )}
      {table.getRowModel().rows.map((row) => (
        <DataTable.Row
          key={row.id}
          onPress={() => props.onRowClick && props.onRowClick(row.original)}
        >
          {row.getVisibleCells().map((cell) => (
            <DataTable.Cell
              key={cell.id}
              style={cell.column.columnDef.meta?.style}
            >
              <Text style={styles.itemText}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Text>
            </DataTable.Cell>
          ))}
        </DataTable.Row>
      ))}
    </DataTable>
  );
};

const styles = StyleSheet.create({
  titleText: {
    fontSize: 16,
    color: 'black',
  },
  itemText: {
    fontSize: 16,
    color: 'black',
    width: '100%',
  },
  header: {
    backgroundColor: '#F7F7FB',
  },
  table: {
    backgroundColor: 'white',
  },
});
