import { PermissionValues as Permissions } from '@gripp/shared-logic';
import { Groups } from './components/groups';
import { GroupsToolbar } from './components/groupsToolbar';
import { groupPaths } from './groupPaths';

export const groupsRoutes = [
  {
    path: groupPaths.root,
    element: <Groups />,
    handle: {
      header: {
        titleKey: 'groups.menu.title',
        icon: 'google-circles-extended',
      },
      requiredPermission: Permissions.group.all,
      toolbar: <GroupsToolbar />,
    },
  },
];
