import {
  CheckboxQuestionConfig,
  CheckboxResponseState,
} from '@gripp/shared-logic';
import { useController } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import { Colors } from '../../../../../themes/colors';
import { useFormContext } from '../../../../forms';
import { ControlledCheckboxItems } from '../../../../forms/controlledCheckboxItems';
import {
  RoutineQuestionExecutionForm,
  RoutineQuestionExecutionFormProps,
} from '../types';

export const CheckboxQuestionExecutionForm: RoutineQuestionExecutionForm<
  CheckboxQuestionConfig,
  CheckboxResponseState
> = (
  props: RoutineQuestionExecutionFormProps<
    CheckboxQuestionConfig,
    CheckboxResponseState
  >
) => {
  const fieldPrefix = `responses.${props.responseIndex}`;
  const { control } = useFormContext();
  const { field } = useController({
    control: control,
    name: `${fieldPrefix}.options`,
  });

  return (
    <ControlledCheckboxItems
      control={control}
      name={field.name}
      options={props.config.options.map((option) => {
        return {
          value: option,
        };
      })}
      position="leading"
      style={styles.container}
      labelStyle={styles.label}
      mode="android"
    />
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
  },
  label: {
    fontSize: 16,
    lineHeight: 19,
    color: Colors.black,
    fontWeight: '400',
    textAlign: 'left',
  },
});
