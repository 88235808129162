import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  Account,
  ActivityFilterModel,
  Issue,
  IssueMessageType,
  replaceMentionPlaceholderWithNames,
  Severity,
  useTranslatedText,
} from '@gripp/shared-logic';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import { Icon, Text } from 'react-native-paper';
import { Layout } from '../../styles';
import { Colors } from '../../themes';
import { getIssueAuditData, getIssueFullName } from '../issue/issueFormatUtils';
import { SeverityIcons } from '../issue/severityIcons';

export interface IssueCardData {
  type: string;
  asset: {
    name: string;
  };
  workspace: {
    name: string;
  };
  id: string;
  title: string;
  severity: Severity;
  updatedAt: string;
  latestMessage: {
    text: string;
    createdBy: Account;
    userMentions: Account[];
    type: IssueMessageType;
  };
}

export type IssueCardProps = {
  activity: IssueCardData;
  showAssetName?: boolean;
  newMessageCount?: number;
  filter: ActivityFilterModel;
};

export const IssueCard = (props: IssueCardProps) => {
  const { t } = useTranslation();

  const { activity } = props;
  const [auditData, setAuditData] = useState({ who: '...', when: '...' });
  const [isHovered, setIsHovered] = useState(false);
  const isUnread = useMemo(
    () => props.newMessageCount > 0,
    [props.newMessageCount]
  );

  useEffect(() => {
    setAuditData(
      getIssueAuditData(activity as unknown as Issue, props.filter.orderBy!)
    );
  }, [activity, props.filter.orderBy]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getLatestMessageText = () => {
    const latestMessage = activity?.latestMessage;

    if (!latestMessage) {
      return '';
    }

    const user = getIssueFullName(
      latestMessage.createdBy?.id,
      latestMessage.createdBy?.name
    );

    if (
      activity.latestMessage.type === IssueMessageType.RoutineMessage ||
      activity.latestMessage.type === IssueMessageType.RoutineExceptionMessage
    ) {
      return t('routines.message.messageTitle', { name: user }).slice(0, -1);
    }

    if (
      activity.latestMessage.type === IssueMessageType.SystemMessage ||
      activity.latestMessage.type === IssueMessageType.RoutinePromptMessage
    ) {
      const { translatedText } = useTranslatedText('text', latestMessage);
      return translatedText;
    }

    if (!latestMessage.text) {
      return `${user}: ${t('issue.latestMessage.media')}`;
    }

    const { translatedText } = useTranslatedText('text', latestMessage);
    return `${user}: ${replaceMentionPlaceholderWithNames(
      translatedText || latestMessage?.text,
      latestMessage?.userMentions,
      t('mentions.unknownUser'),
      true
    )}`;
  };

  const hoverProps =
    Platform.OS === 'web'
      ? {
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
        }
      : {};

  return (
    <View style={styles.card} {...hoverProps}>
      <View style={[Layout.row, Layout.justifyBetween]}>
        <View style={[styles.row, { flex: 1 }]}>
          <IssuesSeverity severity={activity.severity} />
          <View
            style={[
              styles.row,
              styles.subHeaderContainer,
              isUnread && styles.nameUnreadBg,
              isHovered && { backgroundColor: Colors.white },
              {
                ...Platform.select({
                  web: { maxWidth: '95%' },
                  default: { maxWidth: '90%' },
                }),
              },
            ]}
          >
            <MaterialCommunityIcons
              name="qrcode"
              color={Colors.grayText}
              size={14}
            />
            <Text
              variant="bodyMedium"
              style={[styles.detailText, styles.details]}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {activity.asset?.name || activity.workspace.name}
            </Text>
          </View>
        </View>

        <View
          style={[
            styles.row,
            styles.whenBg,
            isUnread ? styles.whenBgUnread : styles.whenBgDefault,
          ]}
        >
          <Text
            variant="bodyMedium"
            style={[
              styles.detailText,
              isUnread ? styles.detailsWhite : styles.details,
            ]}
          >
            {auditData.when}
          </Text>
        </View>
      </View>
      <View style={styles.content}>
        <View style={styles.row}>
          <Text style={styles.title} numberOfLines={1}>
            {activity.title}
          </Text>
        </View>
        <View style={styles.row}>
          <Text
            variant="bodyMedium"
            style={styles.lastMessage}
            numberOfLines={1}
          >
            {getLatestMessageText()}
          </Text>
        </View>
      </View>
    </View>
  );
};

const IssuesSeverity = (props: { severity: Severity }) => {
  const iconContainer = {
    backgroundColor: Colors[props.severity],
    width: 31,
    height: 20,
    borderRadius: 4,
    marginRight: 4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
  };

  return (
    <View style={iconContainer}>
      <Icon
        size={16}
        color={Colors.white}
        source={SeverityIcons[props.severity]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    borderRadius: 0,
    width: '100%',
    alignSelf: 'stretch',
    flex: 1,
    padding: 16,
    ...Platform.select({
      web: { gap: 12 },
      default: {
        gap: 10,
      },
    }),
  },
  content: {
    flexDirection: 'column',
    flex: 1,
    ...Platform.select({
      web: { gap: 4 },
      default: {
        gap: 2,
      },
    }),
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    color: Colors.black,
    fontWeight: '500',
  },
  assetDetails: {
    marginRight: 4,
  },
  whenBg: {
    paddingHorizontal: 8,
    borderRadius: 100,
    zIndex: 2,
    marginLeft: 20,
  },
  whenBgDefault: {
    backgroundColor: Colors.primaryGrayBackground,
  },
  whenBgUnread: {
    backgroundColor: Colors.primary,
  },
  details: {
    color: Colors.grayText,
  },
  detailsWhite: {
    color: Colors.white,
  },
  detailText: {
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: -0.4,
    lineHeight: 16,
  },
  bulletDiv: {
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: Colors.grayText,
    marginHorizontal: 4,
  },
  badgeStyle: {
    right: 30,
    backgroundColor: Colors.primary,
    alignSelf: 'flex-end',
    fontSize: 12,
    fontWeight: '500',
  },
  subHeaderContainer: {
    // flex: 1,
    // width: '100%',
    overflow: 'hidden',
    backgroundColor: Colors.primaryGrayBackground,
    paddingHorizontal: 4,
    borderRadius: 4,
    gap: 4,
    height: 20,
  },
  lastMessage: {
    fontSize: 16,
    fontWeight: '400',
    color: Colors.grayText,
  },
  nameUnreadBg: {
    backgroundColor: Colors.white,
  },
});
