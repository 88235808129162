import { MaterialCommunityIcons } from '@expo/vector-icons';
import { newGUID } from '@gripp/shared-logic';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  FlatList,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import { Colors } from '../../themes';
import DragListLib, { DragListRenderItemInfo } from './dragListLib';

type DragListProps = {
  /** @list to display data */
  list: any[];

  /** @onReordered Callaback that returns the list after changing an element. */
  onReordered?: (list: any[]) => void;
  onReorderedFromTo?: (fromIndex: number, toIndex: number) => void;

  /** @pivotKey to identify the unique id */
  pivotKey: string;

  /** @sortKey To do the initial sorting by key */
  sortKey: string;

  /** @customItemStyle and @activeItemStyle Add styles to the rendered item, please note when using horizontal padding on Web. */
  customItemStyle?: StyleProp<ViewStyle>;
  activeItemStyle?: StyleProp<ViewStyle>;

  /** @renderCustomItem render custom Item or use for default <Text style={styles.text}>{item?.title}</Text> */
  renderCustomItem?: (item: any) => JSX.Element;

  /** @selectedItem boolean for selected item */
  selectedItem?: boolean;

  /** @setSelectedItem toggle function boolean for selected item */
  setSelectedItem?: (item: boolean) => void;

  /** @selectedItemId id for selected item */
  selectedItemId?: string;

  /** @setSelectedItemId function for selected item id */
  setSelectedItemId?: (item: string) => void;
};

/** DragList not support inside of ScrollView */

export const DragList: React.FC<DragListProps> = ({
  list,
  onReordered,
  pivotKey,
  sortKey,
  customItemStyle,
  activeItemStyle,
  selectedItem,
  setSelectedItem,
  selectedItemId,
  setSelectedItemId,
  renderCustomItem,
  onReorderedFromTo,
}) => {
  const [scrollData, setScrollData] = useState(orderBy(list, sortKey, 'asc'));
  const listRef = React.useRef<FlatList<string>>(null);
  const [keyId, setKeyId] = useState(newGUID());
  const isWeb = Platform.OS === 'web';
  const [isHoverStyle, setIsHoverStyle] = useState(false);
  const [itemHoverId, setItemHoverId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (list.length !== scrollData.length) {
      setScrollData(list);
    }
  }, [list]);

  const keyExtractor = (item: any) => {
    return item[pivotKey];
  };

  const renderItem = (info: DragListRenderItemInfo<any>) => {
    const { item, onDragStart, onDragEnd, isActive } = info;

    // Note: separation of web: (isWeb), and mobile is required, due to the handling of drag functions on web and mobile platforms.
    return isWeb ? (
      <Pressable
        onHoverIn={() => {
          setIsHoverStyle(true);
          setItemHoverId(item.id);
        }}
        onHoverOut={() => {}}
        style={[
          customItemStyle ?? styles.item,
          isHoverStyle &&
            itemHoverId === item.id && {
              backgroundColor: Colors.secondaryGrayHeader,
            },
          selectedItem &&
            selectedItemId === item.id && {
              backgroundColor: Colors.primaryGrayHeader,
            },
          isActive && (activeItemStyle ?? styles.active),
        ]}
      >
        <Pressable
          key={item[pivotKey]}
          onMouseDown={onDragStart}
          onMouseUp={onDragEnd}
        >
          <MaterialCommunityIcons
            name="drag"
            size={20}
            style={styles.dragIconStyle}
          />
        </Pressable>
        {renderCustomItem ? (
          renderCustomItem(item)
        ) : (
          <Text style={styles.text}>{item?.title ?? item?.name}</Text>
        )}
      </Pressable>
    ) : (
      <Pressable
        key={item[pivotKey]}
        style={[
          styles.item,
          customItemStyle,
          isActive && (activeItemStyle ?? styles.active),
        ]}
        onPressIn={onDragStart}
        onPressOut={onDragEnd}
      >
        <MaterialCommunityIcons
          name="drag"
          size={20}
          style={styles.dragIconStyle}
        />
        {renderCustomItem ? (
          renderCustomItem(item)
        ) : (
          <Text style={styles.text}>{item?.title}</Text>
        )}
      </Pressable>
    );
  };

  async function onScrollReordered(fromIndex: number, toIndex: number) {
    const copy = [...scrollData];
    const removed = copy.splice(fromIndex, 1);

    copy.splice(toIndex, 0, removed[0]);
    setScrollData(copy);
    const auxToUpdateSorKey = copy.map((l, index) => ({
      ...l,
      [sortKey]: index + 1,
    }));
    onReordered?.(auxToUpdateSorKey);
    onReorderedFromTo?.(fromIndex, toIndex);
    setKeyId(newGUID());
  }

  return (
    <View style={[styles.container, isWeb && { overflowX: 'hidden' }]}>
      <DragListLib
        style={styles.scrolledList}
        ref={listRef}
        data={scrollData}
        keyExtractor={keyExtractor}
        onReordered={onScrollReordered}
        renderItem={renderItem}
        key={keyId}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    overflow: 'scroll',
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: Colors.graySpacer,
    minHeight: 40,
    height: 40,
    width: '100%',
    margin: 0,
    userSelect: 'none',
    paddingHorizontal: 8,
    cursor: 'pointer',
  },
  dragIconStyle: {
    marginRight: 4,
    color: Colors.grayText,
  },
  text: {
    fontWeight: '500',
    fontSize: 14,
  },
  active: {
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    backgroundColor: 'orange',
  },
  scrolledList: {
    height: '100%',
    width: '100%',
    flex: 1,
  },
});
