import {
  SortDirectionEnum,
  useAllWorkspaces,
  useFindByQuery,
} from '@gripp/shared-logic';
import {
  Colors,
  ControlledCheckboxItems,
  ControlledTextInput,
  Select,
  Spacing,
} from '@gripp/shared-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button, Divider, Text } from 'react-native-paper';
import { array, string, object as yupObject } from 'yup';
import { FormComponent } from '../../shared/components/forms/mutationForm';

export const CreateWorkspaceForm: FormComponent = (props) => {
  const DEFAULT_ROUTINE_WORSKSPACE_ID = '00000000-0000-0000-0000-000000000001'; //Gripp Template Routines
  const [workspaces, setWorkspaces] = useState<any[]>([]);
  const [routines, setRoutines] = useState<any[]>([]);
  const [findRoutinesVariables, setFindRoutinesVariables] = useState<any>({
    orderBy: {
      field: 'order',
      order: SortDirectionEnum.Asc,
    },
    filter: {
      workspace: { eq: DEFAULT_ROUTINE_WORSKSPACE_ID },
      deactivated: { ne: true },
    },
  });
  const data = useAllWorkspaces();
  const { data: routineData, refetch } = useFindByQuery({
    modelName: 'WorkspaceRoutine',
    query: FIND_WORKSPACE_ROUTINES,
    variables: findRoutinesVariables,
  });
  const { t } = useTranslation();
  const formSchema = yupObject({
    name: string().required(t('workspace.create.validation.name_required')),
    ownerFirstName: string().required(
      t('workspace.create.validation.admin_first_name_required')
    ),
    ownerLastName: string().required(
      t('workspace.create.validation.admin_last_name_required')
    ),
    ownerEmail: string()
      .required(t('workspace.create.validation.admin_email_required'))
      .email(t('workspace.create.validation.valid_emil')),
    routineIds: array().of(string()).required().min(1),
  });
  const { control, handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: props.defaultValues,
  });

  useEffect(() => {
    if (!data) return;

    setWorkspaces(data.map((w) => ({ label: w.name, value: w.id })));
  }, [data]);

  useEffect(() => {
    if (!routineData) return;

    setRoutines(
      routineData.items.map((r: { name: string; id: string }) => ({
        label: r.name,
        value: r.id,
      }))
    );
    setValue(
      'routineIds',
      routineData.items.map((r: { id: string }) => r.id)
    );
  }, [routineData, setValue]);

  const onRoutineWorkspaceChanged = async (item: any) => {
    setFindRoutinesVariables((prev: any) => {
      if (!prev?.filter) return prev;

      prev.filter.workspace.eq = item;
      return prev;
    });
    await refetch();
  };

  return (
    <ScrollView
      scrollEnabled
      showsVerticalScrollIndicator
      style={styles.contentContainer}
    >
      <View>
        <Text variant="titleLarge">{t('workspace.create.title')}</Text>
      </View>
      <Divider />
      <View>
        <View style={styles.formItem}>
          <Text style={styles.formItemTitle}>
            {t('workspace.create.workspaceName')}
          </Text>
          <ControlledTextInput control={control} name="name" autoFocus={true} />
        </View>
        <View style={styles.formItem}>
          <Text style={styles.formItemTitle}>
            {t('workspace.create.administratorFirstName')}
          </Text>
          <ControlledTextInput control={control} name="ownerFirstName" />
        </View>
        <View style={styles.formItem}>
          <Text style={styles.formItemTitle}>
            {t('workspace.create.administratorLastName')}
          </Text>
          <ControlledTextInput control={control} name="ownerLastName" />
        </View>
        <View style={styles.formItem}>
          <Text style={styles.formItemTitle}>
            {t('workspace.create.administratorEmail')}
          </Text>
          <ControlledTextInput control={control} name="ownerEmail" />
        </View>
        <View style={styles.formItem}>
          <Text variant="titleMedium">
            {t('workspace.create.copyRoutines')}
          </Text>
          <Divider />
        </View>
        <View style={styles.formItem}>
          <Select
            label={t('routines.copyToWorkspace.workspace')}
            items={workspaces}
            dropDownPickerProps={{
              value: DEFAULT_ROUTINE_WORSKSPACE_ID,
            }}
            onSelectItem={onRoutineWorkspaceChanged}
          />

          <ControlledCheckboxItems
            control={control}
            name="routineIds"
            options={routines}
            position="leading"
            style={styles.routinesContainer}
            labelStyle={styles.routinesLabel}
            mode="android"
          />
        </View>
        <View style={styles.formItem}>
          <Button
            onPress={handleSubmit(props.onSubmit)}
            disabled={!formState.isValid}
          >
            {t('workspace.create.addButton')}
          </Button>
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    paddingVertical: Spacing.basePadding.paddingVertical,
  },
  formItem: {
    width: '100%',
    maxWidth: 473,
    marginTop: Spacing.baseMargin.marginVertical,
  },

  formItemTitle: {
    marginBottom: 10,
    fontWeight: '700',
  },
  header: {},
  routinesContainer: {
    paddingHorizontal: 0,
  },
  routinesLabel: {
    fontSize: 16,
    lineHeight: 19,
    color: Colors.black,
    fontWeight: '400',
    textAlign: 'left',
  },
});

const FIND_WORKSPACE_ROUTINES = `
  query FindWorkspaceRoutines($orderBy: OrderByInput, $filter: WorkspaceRoutineFilter) {
    findWorkspaceRoutines(orderBy: $orderBy, filter: $filter) {
      items {
        id
        name
      }
    }
  }
`;
