import { FontAwesome } from '@expo/vector-icons';
import { Colors } from '@gripp/shared-ui';
import {
  Image,
  Linking,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { Text } from 'react-native-paper';

export const DownloadApp = () => {
  const stars = [1, 2, 3, 4, 5];
  const downloadLinkPlayStore =
    'https://play.google.com/store/apps/details?id=com.gripp.grippapp';
  const downloadLinkAppStore =
    'https://apps.apple.com/us/app/gripp-it/id6468079271';

  return (
    <View style={styles.containerApp}>
      <View style={{ flexDirection: 'row', gap: 12 }}>
        <Image
          style={styles.logoApp}
          source={require('../../static-assets/logo-app.png')}
          resizeMode="contain"
        />
        <View style={{ flexDirection: 'column' }}>
          <Text style={styles.titleApp}>Gripp It</Text>
          <Text style={styles.subtitleApp}>Productivity</Text>
          <View style={styles.stars}>
            {stars.map((_) => (
              <FontAwesome
                key={_}
                name="star"
                size={10}
                color={Colors.yellow}
              />
            ))}
          </View>
        </View>
      </View>
      <View style={styles.imagesApp}>
        <TouchableOpacity
          onPress={() => Linking.openURL(downloadLinkPlayStore)}
        >
          <Image
            style={styles.imageAppStore}
            source={require('../../static-assets/google-play-badge.png')}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => Linking.openURL(downloadLinkAppStore)}>
          <Image
            style={styles.imageAppStore}
            source={require('../../static-assets/app-store.png')}
            resizeMode="contain"
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  logoApp: {
    height: 60,
    width: 60,
    borderRadius: 12,
  },
  containerApp: {
    backgroundColor: Colors.white,
    padding: 20,
    borderRadius: 12,
    maxWidth: 615,
  },
  titleApp: {
    fontSize: 20,
    fontWeight: '600',
    lineHeight: 20,
    paddingBottom: 4,
  },
  subtitleApp: {
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 12,
    letterSpacing: -0.2,
    color: Colors.grayText,
  },
  stars: {
    justifyContent: 'space-around',
    width: 65,
    flexDirection: 'row',
    marginTop: 8,
    marginBottom: 6,
  },
  imagesApp: {
    flexDirection: 'row',
    gap: 4,
    marginTop: 20,
    flexWrap: 'wrap',
  },
  imageAppStore: {
    width: 122,
    height: 36,
  },
});
