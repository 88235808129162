import {
  CheckboxQuestionConfig,
  CheckboxResponseState,
} from '@gripp/shared-logic';
import { RoutineQuestionModule } from '../types';
import { CheckboxQuestionAdminForm } from './checkboxQuestionAdminForm';
import { CheckboxQuestionMessage } from './checkboxQuestionMessage';
import { CheckboxQuestionExecutionForm } from './checkboxQuestionExecutionForm';

export const CheckboxQuestionModule: RoutineQuestionModule<
  CheckboxQuestionConfig,
  CheckboxResponseState
> = {
  adminForm: CheckboxQuestionAdminForm,
  executionForm: CheckboxQuestionExecutionForm,
  message: CheckboxQuestionMessage,
};
