import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Severity } from '@gripp/shared-logic';
import { AssetActionButton, Colors, SeverityIcons } from '@gripp/shared-ui';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

type AssetActionButtonsWrapperProps = {
  onAddNew: () => void;
  onRoutinesButton: () => void;
  buttonContainerStyle: StyleProp<ViewStyle>;
  buttonStyle: StyleProp<ViewStyle>;
  buttonRoutines: StyleProp<ViewStyle>;
  iconSize?: number;
};

export const AssetActionButtonsWrapper = (
  props: AssetActionButtonsWrapperProps
) => {
  const { t } = useTranslation();

  return (
    <View style={props.buttonContainerStyle}>
      <AssetActionButton
        onAddNew={props.onAddNew}
        buttonContainerStyle={styles.internalButtonContainer}
        buttonStyle={props.buttonStyle}
        iconSize={props.iconSize}
        text={t('asset.header.buttons.addNew')}
      />
      <TouchableOpacity
        style={[props.buttonRoutines, { backgroundColor: Colors.blue }]}
        activeOpacity={0.7}
        onPress={props.onRoutinesButton}
      >
        <MaterialCommunityIcons
          name={SeverityIcons[Severity.Blue]}
          size={props.iconSize || 20}
          style={styles.icon}
        />
        <Text style={styles.buttonLabel}>
          {t('asset.header.buttons.routines')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  icon: {
    color: Colors.white,
    ...Platform.select({
      web: {
        marginBottom: 0,
        marginRight: 6,
      },
      default: {
        marginBottom: 2,
      },
    }),
  },
  buttonLabel: {
    color: Colors.white,
    fontWeight: '500',
    ...Platform.select({
      web: {
        fontSize: 14,
      },
      default: {
        fontSize: 12,
      },
    }),
  },
  internalButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
  },
});
