import React from 'react';
import { Platform, Pressable, StyleSheet } from 'react-native';
import { IconButton } from 'react-native-paper';
import { Colors } from '../../themes/colors';

type CloseButtonProps = {
  onClose: () => void;
};

export const CloseButton: React.FC<CloseButtonProps> = ({
  onClose,
}: CloseButtonProps) => {
  const size = Platform.select({
    web: 20,
    default: 32,
  });

  const containerColor = Platform.select({
    web: Colors.primary,
    default: Colors.white,
  });

  const iconColor = Platform.select({
    web: Colors.white,
    default: Colors.primaryDark,
  });

  return (
    <Pressable onPress={onClose}>
      <IconButton
        icon="close"
        mode="contained"
        containerColor={containerColor}
        size={size}
        iconColor={iconColor}
        style={styles.iconClose}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  iconClose: {
    ...Platform.select({
      web: {
        width: 32,
        height: 32,
      },
      default: {
        width: 72,
        height: 72,
        borderRadius: 36,
      },
    }),
  },
});
