import {
  WorkflowRoutineConfig,
  WorkflowRoutineState,
} from '@gripp/shared-logic';
import { RoutineButton } from '../../components/routineButton';
import { RoutineButtonProps } from '../../types';

type WorkflowButtonsProps = RoutineButtonProps<
  WorkflowRoutineConfig,
  WorkflowRoutineState
>;

export const WorkflowButtons: FC<WorkflowButtonsProps> = (
  props: WorkflowButtonsProps
) => {
  return (
    <RoutineButton
      text={props.config.routineButtonText!}
      onClick={props.onClick}
      buttonStyle={props.styleOverrides?.buttonStyle}
      labelStyle={props.styleOverrides?.labelStyle}
      buttonIcon={props.styleOverrides?.buttonIcon}
      buttonIconStyle={props.styleOverrides?.buttonIconStyle}
    />
  );
};
