import {
  useAllWorkspaces,
  useGrippContext,
  useImpersonateWorkspace,
  useStorageKeys,
} from '@gripp/shared-logic';
import { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { Checkbox } from 'react-native-paper';
import { Select } from '../forms/select';

type AdminWorkspacePickerProps = {
  disabled?: boolean;
};

export const AdminWorkspacePicker = (props: AdminWorkspacePickerProps) => {
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();
  const [workspace, setWorkspace] = useState<string | undefined>(undefined);
  const [workspaces, setWorkspaces] = useState<any[]>([]);
  const [storageChecked, toggleStorageChecked] = useState(false);
  const { impersonateWorkspace } = useImpersonateWorkspace();
  const data = useAllWorkspaces();

  // on mount, get stored workspace
  useEffect(() => {
    const getStoredWorkspace = async () => {
      const value = await storageHandler.getItem(storageKeys.workspace);
      if (value) {
        const existingWorkspace = JSON.parse(value);
        setWorkspace(existingWorkspace.id);
      }
      toggleStorageChecked(true);
    };

    getStoredWorkspace();
  }, []);

  useEffect(() => {
    if (!data) return;

    setWorkspaces(
      data.map((w) => ({
        label: w.name,
        value: w.id,
      }))
    );
  }, [data]);

  const onWorkspaceChanged = useCallback(
    async (newWorkspace: any, workspaces: any[]) => {
      const update = {
        id: newWorkspace,
        name: workspaces.find((w) => w.value === newWorkspace)?.label,
      };
      setWorkspace(newWorkspace);
      await impersonateWorkspace(update);
    },
    []
  );

  const onEnableChooseWorkspace = useCallback(
    async (newSelection: boolean) => {
      if (!newSelection) {
        setWorkspace(undefined);
        await impersonateWorkspace(undefined);
      }
    },
    [impersonateWorkspace]
  );

  return (
    storageChecked &&
    workspaces?.length > 0 && (
      <Picker
        onEnableChooseWorkspace={onEnableChooseWorkspace}
        onWorkspaceChanged={(newValue) =>
          onWorkspaceChanged(newValue, workspaces)
        }
        workspaces={workspaces}
        workspace={workspace}
        disabled={props.disabled}
      />
    )
  );
};

type PickerProps = {
  onEnableChooseWorkspace: (newSelection: boolean) => void;
  onWorkspaceChanged: (newWorkspace: any) => void;
  workspaces: any[];
  workspace?: string;
  disabled?: boolean;
};

const Picker = (props: PickerProps) => {
  const listMode = Platform.OS === 'web' ? 'SCROLLVIEW' : 'MODAL';
  const [canSelectWorkspace, toggleSelectWorkspace] = useState(false);

  useEffect(() => {
    if (props.workspace) {
      toggleSelectWorkspace(true);
    }
  }, []); // run once on mount

  const onEnableChooseWorkspace = useCallback(async () => {
    props.onEnableChooseWorkspace(!canSelectWorkspace);
    toggleSelectWorkspace(!canSelectWorkspace);
  }, [canSelectWorkspace]);

  return (
    <>
      <Checkbox.Item
        status={canSelectWorkspace ? 'checked' : 'unchecked'}
        position="leading"
        label="Impersonate Workspace"
        onPress={onEnableChooseWorkspace}
        mode="android"
        disabled={props.disabled}
      />
      {canSelectWorkspace && (
        <Select
          listMode={listMode}
          items={props.workspaces}
          label="Workspace"
          onSelectItem={props.onWorkspaceChanged}
          dropDownPickerProps={{
            value: props.workspace,
            listMode: 'SCROLLVIEW', // this doesn't seem to do anything, also see https://hossein-zare.github.io/react-native-dropdown-picker-website/docs/advanced/list-modes#notes
            dropDownDirection: 'TOP',
          }}
        />
      )}
    </>
  );
};

/* 
  behavior:
    if set (local storage):
      - show checked
      - show workspace picker
      - set workspace picker to stored
      : on uncheck:
        - hide picker
        - delete local storage
    if not set (local storage):
      - show unchecked
      - show nothing else
      : on check:
        - show workspace picker
        : on workspace selection
          - set local storage
*/
