import { useEffect, useState } from 'react';
import { SortDirectionEnum, useFindByQuery } from '../../graphql';
import { Group } from '../domain';
import { useCurrentAccount } from '../../auth';

export const useGroups = (workspaceId?: string) => {
  const { account } = useCurrentAccount();
  const [groups, setGroups] = useState<Group[]>([]);
  const [filter, setFilter] = useState<any | undefined>();

  useEffect(() => {
    if (!account || !workspaceId) return;

    const filter: any = {
      workspace: { eq: workspaceId },
    };

    if (account.groups && account.groups.length > 0) {
      filter.id = { in: account.groups.map((group) => group.id) };
    }

    setFilter(filter);
  }, [account, workspaceId]);

  const { data, isLoading, invalidateQuery } = useFindByQuery({
    modelName: 'group',
    query: GET_GROUPS,
    variables: {
      filter,
      orderBy: {
        field: 'name',
        order: SortDirectionEnum.Asc,
      },
    },
    options: {
      enabled: !!filter,
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    setGroups(data.items);
  }, [data]);

  return {
    groups,
    isLoading,
    invalidateQuery,
  };
};

const GET_GROUPS = `
  query findGroups($filter: GroupFilter, $orderBy: OrderByInput) {
    findGroups(filter: $filter, orderBy: $orderBy) {
      items {
        id
        name
      }
    }
  }
`;
