import {
  PermissionValues,
  SortDirectionEnum,
  Workspace,
  getFullName,
  useCurrentAccount,
  useImpersonateWorkspace,
} from '@gripp/shared-logic';
import { Colors, InfiniteDataGrid } from '@gripp/shared-ui';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import { Button } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';
import { ModalButton } from '../../shared/components/modalButton';
import { useMaxHeight } from '../../shared/hooks/useMaxHeight';
import { AllowedWorkspacesAdmin } from './allowedWorkspacesAdmin';
import { AssetAdmin } from './assetAdmin';
import { ExportButton } from './dataExport/exportButton';
import { useAccountsExport } from './dataExport/useAccountsExport';
import { useActivityExport } from './dataExport/useActivityExport';
import { useAssetExport } from './dataExport/useAssetExport';
import { useRoutineExport } from './dataExport/useRoutineExport';
import { DeleteWorkspace } from './deleteWorkspace';
import { EditWorkspace } from './editWorkspace';
import { WorkspaceRoutineAdmin } from './workspaceRoutineAdmin';

export const Workspaces = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { account } = useCurrentAccount();
  const { impersonateWorkspace } = useImpersonateWorkspace();

  const { containerMaxHeight, listMaxHeight } = useMaxHeight();

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      cell: ({ row }) => {
        const original = row.original;
        const onPress = async () => {
          await impersonateWorkspace(original as Workspace);
          navigate('/activities');
        };
        return (
          <Text>
            <Button mode="text" onPress={onPress}>
              {original.name}
            </Button>
          </Text>
        );
      },
      header: () => t('workspace.list.workspace'),
      meta: { sortable: true },
    }),
    columnHelper.accessor('owner', {
      cell: (info) => {
        const name = info.getValue()?.name;
        return <Text>{getFullName(name)}</Text>;
      },
      header: () => t('workspace.list.administrator'),
      meta: { sortable: true },
    }),
    columnHelper.accessor('id', {
      cell: ({ row }) => {
        const original = row.original;
        return (
          <ModalButton
            icon="format-list-bulleted-type"
            modalComponent={() => {
              return (
                <AssetAdmin
                  workspaceId={original.id}
                  workspaceName={original.name}
                />
              );
            }}
          />
        );
      },
      header: () => 'Assets',
    }),
    columnHelper.accessor('assetId', {
      cell: ({ row }) => {
        const original = row.original;
        return (
          <ModalButton
            icon="format-list-bulleted-type"
            modalComponent={() => {
              return (
                <WorkspaceRoutineAdmin
                  workspaceId={original.id}
                  workspaceName={original.name}
                />
              );
            }}
          />
        );
      },
      header: () => 'Routines',
    }),
    columnHelper.accessor('users', {
      cell: ({ row }) => {
        const original = row.original;
        return (
          <ModalButton
            icon="format-list-bulleted-type"
            modalComponent={() => {
              return (
                <AllowedWorkspacesAdmin
                  workspaceId={original.id}
                  workspaceName={original.name}
                />
              );
            }}
          />
        );
      },
      header: () => 'Users',
    }),
    columnHelper.accessor('exports', {
      cell: ({ row }) => {
        const original = row.original;
        return (
          <View style={styles.export}>
            <ExportButton
              workspace={original as Workspace}
              icon="tractor"
              toolTip="Export Assets"
              useExport={useAssetExport}
              fileName="assets"
            />
            <ExportButton
              workspace={original as Workspace}
              icon="chart-timeline-variant-shimmer"
              toolTip="Export Activity"
              useExport={useActivityExport}
              fileName="activity"
            />
            <ExportButton
              workspace={original as Workspace}
              icon="clipboard-list-outline"
              toolTip="Export Routines"
              useExport={useRoutineExport}
              fileName="routines"
            />
            <ExportButton
              workspace={original as Workspace}
              icon="account-outline"
              toolTip="Export Accounts"
              useExport={useAccountsExport}
              fileName="accounts"
            />
          </View>
        );
      },
      header: () => 'Exports',
    }),
    columnHelper.accessor('edit', {
      cell: ({ row }) => {
        const original = row.original;
        return (
          <View style={styles.editColumn}>
            <ModalButton
              icon="pencil"
              containerStyle={styles.editModal}
              modalComponent={({ onComplete }: { onComplete: () => void }) => {
                return (
                  <EditWorkspace
                    workspace={original as Workspace}
                    onComplete={onComplete}
                  />
                );
              }}
            />
            <DeleteWorkspace workspace={original as Workspace} />
          </View>
        );
      },
      header: () => '',
    }),
  ];

  useEffect(() => {
    if (!account) return;

    if (!account.hasPermission(PermissionValues.globalAdmin)) {
      navigate(`/accounts/${account.workspace?.id}`);
    }
  }, [account, navigate]);

  return (
    <View>
      <View style={styles.exportAllAccountsContainer}>
        <ExportButton
          icon="account-multiple-outline"
          toolTip="Export All Accounts"
          useExport={useAccountsExport}
          fileName="accounts"
        />
      </View>
      <View
        style={[
          styles.workspacesContainer,
          {
            maxHeight: containerMaxHeight,
          },
        ]}
      >
        <InfiniteDataGrid
          columns={columns}
          query={GET_WORKSPACES}
          modelName="workspace"
          orderBy={{
            field: 'name',
            order: SortDirectionEnum.Asc,
          }}
          pageSize={50}
          hideHeader={false}
          listMaxHeight={listMaxHeight}
          showScrollIndicator={true}
        />
      </View>
    </View>
  );
};

const GET_WORKSPACES = `
  query GetWorkspaces($filter: WorkspaceFilter, $page: PageRequest, $orderBy: OrderByInput) {
    findWorkspaces(filter: $filter, page: $page, orderBy: $orderBy) {
      count
      offset
      limit
      items {
        id
        name
        owner {
          name {
            first
            last
          }
        }
      }
    }
  }
`;

const styles = StyleSheet.create({
  workspacesContainer: {
    flex: 1,
    borderRadius: 12,
    overflow: 'hidden',
  },
  export: {
    flexDirection: 'row',
    margin: 5,
  },
  exportAllAccountsContainer: {
    flexDirection: 'column',
    alignSelf: 'flex-end',
  },
  editColumn: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: 30,
    maxWidth: 30,
    marginLeft: 50,
  },
  editModal: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: Dimensions.get('window').height / 4,
    backgroundColor: Colors.blackInactive,
  },
});
