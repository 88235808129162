import { ACCOUNT_INFO_FRAGMENT } from '../../graphql';

export const ISSUE_MESSAGE_FRAGMENT = `
  id
  type
  text
  media {
    id
    name
    type
    extension
    status
  }
  assetRoutine {
    exceptionCount
    data
    routineVersion {
      id
      type
      config
    }
    createdAt
    createdBy {
      id
      name {
        first
        last
      }
    }
  }
  updatedAt
  updatedBy {
    ${ACCOUNT_INFO_FRAGMENT}
  }
  linkedIssue {
    id
  }
  userMentions {
    id
    name {
      first
      last
    }
  }
  reactions{
    id
    createdAt
    createdBy 
    reactionType
  }
  i18n 
`;
