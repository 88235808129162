import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { Colors } from '../../themes/colors';

type AssetActionButtonProps = {
  onAddNew: () => void;
  buttonContainerStyle: StyleProp<ViewStyle>;
  buttonStyle: StyleProp<ViewStyle>;
  text: string;
  iconSize?: number;
};

export const AssetActionButton = (props: AssetActionButtonProps) => {
  const { t } = useTranslation();

  return (
    <View style={props.buttonContainerStyle}>
      <TouchableOpacity
        style={[props.buttonStyle, { backgroundColor: Colors.primary }]}
        activeOpacity={0.7}
        onPress={props.onAddNew}
      >
        <MaterialCommunityIcons
          name="plus-circle-outline"
          size={props.iconSize || 16}
          style={styles.icon}
        />
        <Text style={styles.buttonLabel}>{props.text}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  icon: {
    color: Colors.white,
    ...Platform.select({
      web: {
        marginBottom: 0,
        marginRight: 4,
      },
      default: {
        marginBottom: 2,
      },
    }),
  },
  buttonLabel: {
    color: Colors.white,
    fontWeight: '500',
    fontSize: 14,
  },
});
