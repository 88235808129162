import {
  QuestionExceptionConfig,
  QuestionExceptionHandling,
} from '@gripp/shared-logic';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../../../../themes/colors';
import { useFormContext } from '../../../../forms';

export type QuestionExceptionHandlingProps = {
  config: QuestionExceptionConfig;
  indexPrefix: string;
  control: Control;
};

export const QuestionException = (props: QuestionExceptionHandlingProps) => {
  const { t } = useTranslation();
  const labelPrefix = 'routines.workflow.admin.question.exception';
  const fieldName = props.indexPrefix
    ? `${props.indexPrefix}.responseException`
    : 'responseException';
  const { control, setValue, watch } = useFormContext();

  control.register(fieldName, {
    value: props.config.responseException ?? QuestionExceptionHandling.Off,
  });

  const currentEnum = watch(fieldName);

  const currentValue = useMemo(() => {
    return QuestionExceptionHandling[currentEnum];
  }, [currentEnum]);

  const currentColor = useMemo(() => {
    switch (currentEnum) {
      case QuestionExceptionHandling.HasResponse:
        return Colors.red;
      case QuestionExceptionHandling.MissingResponse:
        return Colors.yellow;
      default:
        return Colors.primaryGrayBackground;
    }
  }, [currentEnum]);

  const toggleValue = () => {
    const nextValue = EnumShift.of(QuestionExceptionHandling).next(currentEnum);
    setValue(fieldName, nextValue, { shouldDirty: true });
  };

  return (
    <View style={styles.container}>
      <Text style={styles.label}>{t(`${labelPrefix}.label`)}</Text>
      <Pressable
        onPress={toggleValue}
        style={[
          styles.button,
          {
            backgroundColor: currentColor,
          },
        ]}
      >
        <Text suppressHighlighting={true} selectable={false}>
          {t(`${labelPrefix}.${currentValue}`)}
        </Text>
      </Pressable>
    </View>
  );
};

class EnumShift {
  static of<T extends object>(e: T) {
    const values = Object.values(e);
    const map = new Map(values.map((k, i) => [k, values[i + 1]]));
    return {
      next: <K extends keyof T>(v: T[K]) => {
        const nextValue = map.get(v);
        return nextValue ? nextValue : 0;
      },
    };
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: 16,
    marginRight: 20,
    fontWeight: '700',
  },
  button: {
    paddingHorizontal: 5,
    paddingVertical: 5,
    borderRadius: 8,
    width: 150,
    alignItems: 'center',
    borderColor: Colors.black,
    borderWidth: 1,
  },
});
