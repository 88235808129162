import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationArgs, UpdateMutationVariables } from '../types';
import { GraphbackOperationType, getDataObject } from './getDataObject';
import { cacheKeys } from './cacheKeys';
import { useFetcher } from './useFetcher';

export const useUpdateMutation = (opts: MutationArgs) => {
  const queryClient = useQueryClient();
  const cacheKey = cacheKeys(opts.modelName).all;

  const fetcherFn = useFetcher(opts.query);

  return useMutation({
    mutationFn: async (variables: UpdateMutationVariables) => {
      const data = await fetcherFn(variables);

      return getDataObject(data, opts.modelName, GraphbackOperationType.update);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: cacheKey });
    },
  });
};
