import { CounterConfig, CounterState } from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { number, object as yupObject } from 'yup';
import { useFormContext } from '../../forms';
import { RoutineExecutionForm, RoutineExecutionFormProps } from '../types';
import { CounterExecutionComponent } from './counterExecutionComponent';

export const getExecutionFormDefaultValues = (
  _: CounterConfig,
  state: CounterState
) => {
  return {
    count: state?.count,
  };
};

export const getExecutionFormResolver = () => {
  const validationSchema = yupObject({
    count: number().required().nonNullable(),
  });
  return yupResolver(validationSchema);
};

export const CounterExecutionForm: RoutineExecutionForm<
  CounterConfig,
  CounterState
> = (props: RoutineExecutionFormProps<CounterConfig, CounterState>) => {
  const formKey = 'count';
  const { control, setValue } = useFormContext();
  const { field } = useController({
    control: control,
    name: formKey,
  });

  useEffect(() => {
    if (props.currentState?.state?.count) {
      setValue(formKey, props.currentState.state.count, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [props.currentState]);

  return (
    <CounterExecutionComponent
      onBlur={field.onBlur}
      instructions={props.config.instructions}
      initialValue={props.currentState?.state.count || 0}
      onChange={field.onChange}
      value={field.value}
      decimalPlaces={
        props.config.decimal ? props.config.decimalPlaces : undefined
      }
      tally={props.config.tally || false}
    />
  );
};
