import { GroupFilterKey, GroupFilterModel } from '../domain';
import { useFilter } from '../../filter';

export const useGroupListFilter = () => {
  return useFilter(
    GroupFilterKey,
    'groupFilter',
    (filter: string) => new GroupFilterModel(filter)
  );
};
