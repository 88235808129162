
export const basePadding = {
  paddingHorizontal: 20,
  paddingVertical: 20,
  base: 16,
}

export const baseMargin = {
  marginHorizontal: 20,
  marginVertical: 20,
  base: 16,
}
