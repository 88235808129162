import {
  QuestionMultipleValueExceptionConfig,
  QuickConfirmBaseConfig,
  QuickConfirmConfig,
} from '@gripp/shared-logic';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { array, string } from 'yup';
import { ControlledTextArrayInput } from '../../forms/controlledTextArrayInput';
import { RoutineAdminForm, RoutineAdminFormProps } from '../types';

export const QuickConfirmAdminSchema = {
  options: array().of(string()).min(1),
};

export const QuickConfirmAdminForm: RoutineAdminForm<QuickConfirmConfig> = (
  props: RoutineAdminFormProps<QuickConfirmConfig>
) => {
  return (
    <QuickConfirmAdminComponent config={props.config} control={props.control} />
  );
};

type QuickConfirmConfigAdminComponentProps = {
  config: QuickConfirmBaseConfig;
  control: Control;
  indexPrefix?: string;
  showResponseExceptions?: boolean;
};

export const QuickConfirmAdminComponent = (
  props: QuickConfirmConfigAdminComponentProps
) => {
  const { t } = useTranslation();

  const fieldName = props.indexPrefix
    ? `${props.indexPrefix}.options`
    : 'options';

  return (
    <ControlledTextArrayInput
      label={t('routines.quickConfirm.admin.options')}
      addMoreLabel={t('routines.quickConfirm.admin.addOption')}
      name={fieldName}
      control={props.control}
      defaultValues={props?.config?.options ?? ['Confirmed']}
      showResponseExceptions={props.showResponseExceptions}
      exceptionsPrefix={props.indexPrefix}
      defaultExceptionValues={
        props.showResponseExceptions
          ? (props.config as QuestionMultipleValueExceptionConfig)
              .responseExceptions ?? []
          : undefined
      }
    />
  );
};
