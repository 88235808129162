import { RoutineTriggerConfig } from '@gripp/shared-logic';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import {
  IconButton,
  RadioButton,
  Text,
  TextInput,
  TouchableRipple,
} from 'react-native-paper';
import { string, object as yupObject } from 'yup';
import { Colors } from '../../../../../themes/colors';

export const RoutineTriggerConfigValidadationSchema = {
  config: yupObject({
    workspaceRoutineId: string().required(),
    routineType: string().required(),
  }).test((obj) => {
    const { workspaceRoutineId, routineType, ...rest } = obj;
    return Object.values(rest).some(
      (value) =>
        value !== undefined && typeof value === 'number' && !isNaN(value)
    );
  }),
};
type RoutineTriggerProps<C extends RoutineTriggerConfig> = {
  control: any;
  initConfig: C;
  name: string;
  i18nPrefix: string;
};

export const RoutineTrigger = <C extends RoutineTriggerConfig>(
  props: RoutineTriggerProps<C>
) => {
  const { t, i18n } = useTranslation();
  const [selectedKey, setSelectedKey] = useState<string | undefined>();

  const getNewConfig = useCallback(
    <K extends keyof C>(selectedKey: K, value: C[K]): C => {
      const newConfigFromDefault = { ...props.initConfig } as C;
      newConfigFromDefault[selectedKey] = value;

      return newConfigFromDefault as C;
    },
    [props.initConfig]
  );

  const structure = useMemo(() => {
    return Object.keys(props.initConfig).filter(
      (key) => key !== 'workspaceRoutineId' && key !== 'routineType'
    );
  }, [props.initConfig]);

  useEffect(() => {
    setSelectedKey(undefined);
  }, [props.initConfig]);

  const onTriggerChanged = useCallback(
    <K extends keyof C>(
      key: K,
      value: C[K],
      onChange: (...event: any) => void
    ) => {
      if (!isSelected(selectedKey, key, value)) {
        onChange(getNewConfig(key, undefined));
        setSelectedKey(key);
      }
    },
    [props.initConfig]
  );

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => {
        return (
          <View style={styles.container}>
            {structure.map((key) => (
              <View key={key} style={styles.optionRow}>
                <TouchableRipple
                  onPress={() => onTriggerChanged(key, value, onChange)}
                >
                  <View style={styles.option}>
                    <RadioButton
                      value={key}
                      status={
                        isSelected(selectedKey, key, value)
                          ? 'checked'
                          : 'unchecked'
                      }
                      onPress={() => onTriggerChanged(key, value, onChange)}
                    />
                    <Text style={styles.label} numberOfLines={1}>
                      {t(`${props.i18nPrefix}.${key}`)}
                    </Text>
                  </View>
                </TouchableRipple>
                <View style={styles.inputContainer}>
                  <IconButton
                    mode="outlined"
                    icon={'minus'}
                    iconColor={Colors.grayText}
                    size={24}
                    style={[
                      styles.counterButton,
                      {
                        borderRightWidth: 1,
                        borderRightColor: Colors.grayText,
                      },
                    ]}
                    onPress={() => {
                      if (isSelected(selectedKey, key, value)) {
                        onChange(getNewConfig(key, setCount(value[key], -1)));
                      }
                    }}
                  />
                  <TextInput
                    key={`${selectedKey}-${key}`}
                    value={setTextValue(value, key)}
                    autoFocus={isSelected(selectedKey, key, value)}
                    editable={isSelected(selectedKey, key, value)}
                    keyboardType={'numeric'}
                    onChangeText={(text) => {
                      if (isValidTextValue(text)) {
                        onChange(getNewConfig(key, parseInt(text)));
                      }
                    }}
                    inputMode={'numeric'}
                    mode={'outlined'}
                    selectionColor={Colors.grayText}
                    cursorColor={Colors.grayText}
                    outlineStyle={styles.inputOutline}
                    contentStyle={styles.inputContent}
                    style={styles.input}
                    onPointerUp={(e) => {
                      onTriggerChanged(key, value, onChange);
                      e.currentTarget.focus();
                    }}
                  />
                  <IconButton
                    mode="outlined"
                    icon={'plus'}
                    iconColor={Colors.grayText}
                    size={24}
                    style={[
                      styles.counterButton,
                      {
                        borderLeftWidth: 1,
                        borderLeftColor: Colors.grayText,
                      },
                    ]}
                    onPress={() => {
                      if (isSelected(selectedKey, key, value)) {
                        onChange(getNewConfig(key, setCount(value[key], 1)));
                      }
                    }}
                  />
                </View>
                <Text style={styles.label} numberOfLines={1}>
                  {i18n.exists(`${props.i18nPrefix}.${key}-end`)
                    ? t(`${props.i18nPrefix}.${key}-end`)
                    : ''}
                </Text>
              </View>
            ))}
          </View>
        );
      }}
    />
  );
};

const isSelected = (
  selectedKey: string | undefined,
  key: string,
  value: any
) => {
  return selectedKey ? selectedKey === key : value?.[key] !== undefined;
};

const setTextValue = (value: any, key: string) => {
  return value && !isNaN(value[key]) && value[key] !== 0
    ? value[key]?.toString()
    : '';
};

const isValidTextValue = (text: string) => {
  return text === '' || (!isNaN(parseInt(text)) && parseInt(text) !== 0);
};

const setCount = (value: any, increment: number) => {
  const newCount = (isNaN(value) ? 0 : value) + increment;
  if (newCount > 0) {
    return newCount;
  }
};

const styles = StyleSheet.create({
  container: {
    marginBottom: -16,
  },
  optionRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 8,
    flexWrap: 'wrap',
  },
  option: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  label: {
    fontWeight: '400',
    color: Colors.blackText,
    fontSize: 16,
  },
  inputContainer: {
    width: 170,
    height: 32,
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: Colors.grayText,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 4,
    overflow: 'hidden',
  },
  inputOutline: {
    borderWidth: 0,
  },
  input: {
    flex: 1,
    height: 32,
  },
  inputContent: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    color: Colors.grayText,
    borderRadius: 0,
    fontSize: 16,
    fontWeight: '400',
    textAlign: 'center',
  },
  counterButton: {
    width: 32,
    height: 32,
    backgroundColor: Colors.white,
    borderRadius: 0,
    borderWidth: 0,
    margin: 0,
  },
});
