import AcceptInvitation from './acceptInvitation';
import { Invitation } from './invitation';

export const anonymousRoutes = [
  { path: '/invitation/:invitationId/:workspaceId', element: <Invitation /> },
  {
    path: '/acceptInvitation/:invitationId/:workspaceId',
    element: <AcceptInvitation />,
  },
];
