import { Account } from '../auth';
import { MentionsType } from '../issues/domain/mentions';

// Regular expression to match the mention pattern @[name](guid)
export const mentionsRegex = /(@\[([^\]]+)\]\(([^\)]+)\))/g;

export const getMentionTextPartsForHighlighting = (inputText: string) => {
  const matches = [];
  let match;

  while ((match = mentionsRegex.exec(inputText)) !== null) {
    matches.push(match);
  }

  const parts = [];
  let lastIndex = 0;

  matches.forEach(({ 0: fullMatch, index, 2: name, 3: guid }) => {
    if (index > lastIndex) {
      parts.push({
        text: inputText.substring(lastIndex, index),
        isHighlighted: false,
      });
    }
    parts.push({
      text: fullMatch,
      name: name,
      guid: guid,
      isHighlighted: true,
    });
    lastIndex = index + fullMatch.length;
  });

  if (lastIndex < inputText.length) {
    parts.push({
      text: inputText.substring(lastIndex),
      isHighlighted: false,
    });
  }

  return parts;
};

export const extractMentionsFromText = (message: string) => {
  const ids = new Set();
  let match;

  while ((match = mentionsRegex.exec(message)) !== null) {
    ids.add(match[3]);
  }

  return Array.from(ids).map((id) => ({ id }));
};

export const replaceMentionNamesWithPlaceholder = (message: string) => {
  return message.replace(
    mentionsRegex,
    (match, fullMatch, name, id) => `@[${MentionsType.User}](${id})`
  );
};

export const replaceMentionPlaceholderWithNames = (
  message: string,
  mentions: Account[],
  fallbackName: string,
  removeId: boolean = false
) => {
  return message.replace(mentionsRegex, (match, fullMatch, name, id) => {
    const mention = mentions?.find((um) => um.id == id);

    const replaced = mention?.name
      ? `${mention.name.first} ${mention.name.last}`
      : fallbackName;

    return removeId ? `@${replaced}` : `@[${replaced}](${id})`;
  });
};
