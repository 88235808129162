import { useCurrentAccount } from '../../auth';
import { useGetQuery } from '../../graphql';

export const useCurrentAccountAvatar = () => {
  const { account } = useCurrentAccount();

  const { data, isLoading } = useGetQuery({
    modelName: 'account',
    query: LOAD_CURRENT_AVATAR,
    id: account?.id,
    options: {
      enabled: account?.id !== undefined,
      retry: 15,
      refetchInterval: (query) => {
        if (query?.state?.data?.getAccount?.avatar?.status === 'PENDING') {
          return 1000;
        }

        return false;
      },
    },
  });

  return { currentAvatar: data?.avatar, isLoading };
};

const LOAD_CURRENT_AVATAR = `
  query GetAccountAvatar($id: ID!) {
    getAccount(id: $id) {
      avatar {
        id
        type
        extension
        status
      }
    }
  }
  `;
