import {
  RoutineTriggerConfig,
  RoutineType,
  Workflow,
  useFindByQuery,
  useGetQuery,
  useGrippContext,
  useUpdateMutation,
} from '@gripp/shared-logic';
import {
  Colors,
  ControlledTextInput,
  CounterRoutineTrigger,
  DefaultRoutineTrigger,
  RoutineTriggerConfigValidadationSchema,
  SaveFormBottom,
} from '@gripp/shared-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import {
  ScrollView,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native';
import { Text } from 'react-native-paper';
import { useParams } from 'react-router-dom';
import { string, object as yupObject } from 'yup';
import { RoutineSelector } from '../../shared/components/alerts/routineSelector';
import {
  DateRangePicker,
  DateRangeValidationSchema,
} from '../../shared/components/dates/controlledDatePicker';

export const EditAlert = () => {
  const params = useParams();
  const { alertId } = params;
  const { logger } = useGrippContext();
  const { t } = useTranslation();
  const windowDimensions = useWindowDimensions();
  const maxHeight = windowDimensions?.height - 136;
  const heightStyle = { maxHeight: maxHeight };

  const editAlertFormSchema = yupObject({
    name: string().required(),
    whenConfig: RoutineTriggerConfigValidadationSchema.config.required(),
    promptWorkspaceRoutineItem: yupObject().optional(),
    disabledBetween: DateRangeValidationSchema.dateRange.optional(),
  });

  const { workflow } = useWorkflow(alertId as string);
  const { data: workspaceAssetsData } = useFindWorkspaceAssets(
    workflow?.workspaceAsset?.id,
    workflow?.group?.id
  );
  const { mutateAsync: updateWorkflow } = useUpdateMutation({
    modelName: 'workflow',
    query: UPDATE_WORKFLOW,
  });

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty, isValid },
  } = useForm({
    resolver: yupResolver(editAlertFormSchema),
    mode: 'onSubmit',
    values: {
      name: workflow?.name ?? '',
      whenConfig: workflow?.trigger?.config as RoutineTriggerConfig,
      promptWorkspaceRoutineItem: {
        id: workflow?.action?.config?.promptWorkspaceRoutineId,
      },
      disabledBetween: workflow?.disabledBetween ?? ({} as any),
    },
  });

  const whenConfig: any = watch('whenConfig');

  const { setForItem, setOnItem, uniqueRoutineItems } = useMemo(() => {
    if (
      !workflow ||
      !workspaceAssetsData ||
      !workspaceAssetsData.items ||
      workspaceAssetsData.items.length === 0
    )
      return {};

    const setOnRoutineId = workflow.trigger.config?.workspaceRoutineId;
    const { items: wAssets } = workspaceAssetsData;
    const assetsContainingSetRoutine = workspaceAssetsData.items.filter((wa) =>
      wa.routines?.some((r) => r.id === setOnRoutineId)
    );
    const allSiblingRoutines = assetsContainingSetRoutine.flatMap(
      (wa: any) => wa.routines || []
    );
    const uniqueRoutineItems = [
      ...new Map(
        allSiblingRoutines.map((r) => [
          r.id,
          {
            id: r.id,
            label: r.name,
          },
        ])
      ).values(),
    ] as any[];

    const tac = wAssets.length;
    const acc = assetsContainingSetRoutine.length;

    const setForItem = {
      label: workflow.group?.name ?? wAssets[0]?.asset?.name,
      infoLabel: tac > 1 ? `${tac} ${t('alerts.edit.assets')}` : '',
    };

    const setOnRoutine = uniqueRoutineItems.find(
      (r) => r.id === setOnRoutineId
    );
    const setOnItem = {
      label: setOnRoutine?.label,
      infoLabel: tac > 1 ? `${acc}/${tac} ${t('alerts.edit.assets')}` : '',
    };

    uniqueRoutineItems.unshift({ id: undefined, label: t('alerts.edit.none') });

    return {
      setForItem,
      setOnItem,
      uniqueRoutineItems,
    };
  }, [workspaceAssetsData, workflow]);

  const onSubmit = async (data: any) => {
    const input = {
      id: workflow?.id,
      name: data.name,
      trigger: { type: workflow?.trigger?.type, config: data.whenConfig },
      action: {
        type: workflow?.action?.type,
        config: data.promptWorkspaceRoutineItem?.id
          ? {
              promptWorkspaceRoutineId: data.promptWorkspaceRoutineItem?.id,
            }
          : undefined,
      },
      disabledBetween:
        data.disabledBetween?.start && data.disabledBetween?.end
          ? {
              start: data.disabledBetween.start.slice(0, 10),
              end: data.disabledBetween.end.slice(0, 10),
            }
          : undefined,
    };
    await updateWorkflow(
      { input },
      {
        onSuccess: () => {
          reset(data);
        },
        onError: (error) => {
          alert('Could not update alert. Please try again.');
          logger?.error(error, 'Error update alert');
        },
      }
    );
  };

  return (
    <View style={[heightStyle, styles.container]}>
      <ScrollView style={styles.scrollViewContainer}>
        <SaveFormBottom
          onSubmit={handleSubmit(onSubmit)}
          isDirty={isDirty}
          isValid={isValid}
        >
          <View style={styles.formContainer}>
            <View style={styles.formItem}>
              <View style={styles.formItemTitle}>
                <Text style={styles.boldLabel}>
                  {t('alerts.edit.alertName')}
                </Text>
              </View>
              <ControlledTextInput
                control={control}
                name="name"
                height={52}
                outlinedTextInput
              />
            </View>
            <View style={styles.formItem}>
              <View style={styles.formItemTitle}>
                <Text style={styles.boldLabel}>{t('alerts.edit.setFor')}</Text>
              </View>
              <View style={styles.disabledItem}>
                <Text style={styles.disabledText}>{setForItem?.label}</Text>
                <Text style={styles.disabledText}>{setForItem?.infoLabel}</Text>
              </View>
            </View>
            <View style={styles.formItem}>
              <View style={styles.formItemTitle}>
                <Text style={styles.boldLabel}>{t('alerts.edit.setOn')}</Text>
              </View>
              <View style={styles.disabledItem}>
                <Text style={styles.disabledText}>{setOnItem?.label}</Text>
                <Text style={styles.disabledText}>{setOnItem?.infoLabel}</Text>
              </View>
            </View>
            <View style={styles.formItem}>
              <View style={styles.formItemTitle}>
                <Text style={styles.boldLabel}>{t('alerts.edit.when')}</Text>
              </View>
              {whenConfig?.routineType === RoutineType.Counter ? (
                <CounterRoutineTrigger //only counter routines for now
                  control={control}
                  name={'whenConfig'}
                  workspaceRoutineId={whenConfig?.workspaceRoutineId}
                />
              ) : (
                <DefaultRoutineTrigger
                  control={control}
                  name={'whenConfig'}
                  workspaceRoutineId={whenConfig?.workspaceRoutineId}
                  type={whenConfig?.routineType}
                />
              )}
            </View>
            <View style={[styles.formItem, styles.dateRange]}>
              <View style={styles.formItemTitle}>
                <Text style={styles.boldLabel}>
                  {t('alerts.edit.doNotAlert')}
                </Text>
              </View>
              <DateRangePicker
                control={control}
                name="disabledBetween"
                outlined
              />
            </View>
            <View style={[styles.formItem, styles.removeMarginBottom]}>
              <View style={styles.formItemTitle}>
                <Trans
                  i18nKey="alerts.edit.promptRoutine"
                  style={styles.formItemTitle}
                  components={[
                    <Text style={styles.boldLabelPart} children={undefined} />,
                    <Text style={styles.labelPart} children={undefined} />,
                  ]}
                />
              </View>
              <RoutineSelector
                dataItems={uniqueRoutineItems}
                control={control}
                name="promptWorkspaceRoutineItem"
                placeholder={t('alerts.edit.promptRoutinePlaceholder')}
                outlined
              />
            </View>
          </View>
        </SaveFormBottom>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 12,
    backgroundColor: Colors.white,
    overflow: 'hidden',
  },
  scrollViewContainer: {
    padding: 20,
  },
  formContainer: {
    flexDirection: 'column',
  },
  formItem: {
    marginBottom: 20,
  },
  formItemTitle: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: 10,
  },
  whenStepSection: {
    marginRight: 0,
    minWidth: 530,
  },
  boldLabel: {
    fontSize: 16,
    fontWeight: '600',
    color: Colors.blackText,
  },
  labelPart: {
    fontSize: 16,
    fontWeight: '400',
    color: Colors.blackText,
  },
  boldLabelPart: {
    fontWeight: '600',
    fontSize: 16,
    color: Colors.blackText,
    marginRight: 4,
  },
  disabledText: {
    fontSize: 16,
    fontWeight: '400',
    color: Colors.grayText,
  },
  disabledItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.grayText,
    height: 52,
    backgroundColor: Colors.secondaryGrayHeader,
    paddingHorizontal: 20,
  },
  dateRange: {
    width: 541,
  },
  removeMarginBottom: {
    marginBottom: 0,
  },
});

export const useWorkflow = (workflowId: string) => {
  const [workflow, setWorkflow] = useState<Workflow>();

  const { data, isLoading } = useGetQuery({
    modelName: 'workflow',
    query: GET_WORKFLOW,
    id: workflowId,
    options: {
      staleTime: 0,
      gcTime: 0,
      enabled: !!workflowId,
    },
  });

  useEffect(() => {
    if (!data) return;
    setWorkflow(data as Workflow);
  }, [data]);

  return { workflow, isLoading };
};

const GET_WORKFLOW = `
  query GetWorkflow($id: ID!) {
    getWorkflow(id: $id) {
      id
      name
      group {
        id
        name
      }
      workspaceAsset {
        id
      }
      trigger {
        type
        config
      }
      action {
        type
        config
      }
      disabledBetween {
        start
        end
      }
    }
  }
`;

export const useFindWorkspaceAssets = (
  workspaceAssetId: string | undefined,
  groupId: string | undefined
) => {
  const filter = useMemo(() => {
    return workspaceAssetId
      ? {
          id: { eq: workspaceAssetId },
        }
      : {
          groups: { eq: groupId },
        };
  }, [workspaceAssetId, groupId]);

  const { data, isLoading } = useFindByQuery({
    query: FIND_WORKSPACE_ASSETS,
    modelName: 'workspaceAsset',
    variables: { filter },
    options: {
      enabled: !!workspaceAssetId || !!groupId,
    },
  });

  return { data, isLoading };
};

export const FIND_WORKSPACE_ASSETS = `
  query FindWorkspaceAssets($filter: WorkspaceAssetFilter) {
    findWorkspaceAssets(filter: $filter) {
      items {
        id
        asset {
          id
          name
        }
        routines {
          id
          name
          type
        }
      }
    }
  }
`;

const UPDATE_WORKFLOW = `
  mutation UpdateWorkflow($input: MutateWorkflowInput!) {
    updateWorkflow(input: $input) {
      id
    }
  }
`;
