import { ActivityFilterKey } from '../domain';
import { useSetFilter } from '../../filter';

export const useSetActivityListFilter = (filterKey: ActivityFilterKey) => {
  const storageKey =
    filterKey === ActivityFilterKey.Activity
      ? 'activityFilter'
      : 'assetActivityFilter';

  return useSetFilter(filterKey, storageKey);
};
