import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { ActivityIndicator, Text } from 'react-native-paper';
import { Colors } from '../themes';

type TouchableButtonProps = {
  onPress?: () => void;
  color?: string;
  style?: StyleProp<ViewStyle>;
  icon?: string;
  iconSize?: number;
  label?: string;
  right?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  titleFontSize?: number;
};

export const TouchableButton = ({
  onPress,
  color,
  style,
  icon,
  iconSize = 24,
  label,
  right,
  disabled,
  isLoading,
  titleFontSize = 16,
}: TouchableButtonProps) => {
  const iconDisplay = icon && (
    <MaterialCommunityIcons
      name={icon}
      size={iconSize}
      style={styles.icon}
      color={color ? color : Colors.primaryDark}
    />
  );

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[style || styles.row]}
      disabled={disabled}
    >
      {!right && iconDisplay}
      {label && (
        <View style={styles.row}>
          {isLoading && (
            <ActivityIndicator
              size={15}
              color={color ? color : Colors.primaryDark}
            />
          )}
          <Text
            style={[
              styles.title,
              {
                color: color ? color : Colors.primaryDark,
                fontSize: titleFontSize,
              },
            ]}
            variant="bodyLarge"
          >
            {label}
          </Text>
        </View>
      )}
      {right && iconDisplay}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  icon: {
    paddingRight: 0,
    marginRight: 0,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    fontWeight: '500',
    textTransform: 'capitalize',
  },
  buttonText: {
    textTransform: 'capitalize',
  },
});
