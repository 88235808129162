import { QuickConfirmQuestionConfig } from '@gripp/shared-logic';
import { QuickConfirmAdminComponent } from '../../../quickConfirm/quickConfirmAdminForm';
import {
  RoutineQuestionAdminForm,
  RoutineQuestionAdminFormProps,
} from '../types';

export const QuickConfirmQuestionAdminForm: RoutineQuestionAdminForm<
  QuickConfirmQuestionConfig
> = (props: RoutineQuestionAdminFormProps<QuickConfirmQuestionConfig>) => {
  return (
    <QuickConfirmAdminComponent
      config={props.config}
      control={props.control}
      indexPrefix={props.indexPrefix}
      showResponseExceptions={true}
    />
  );
};
