import { Platform } from 'react-native';
import { FilterModel, ColumnModel } from '../../filter/domain';

export const AssetFilterKey = 'ASSET_FILTER';
export const AssetListOrderKey = 'ASSET_LIST_ORDER_KEY';

export const AssetFilterBaseColumns = [
  'description',
  'lastUpdated',
  'lastScannedAt',
  'reports',
  'routines',
  'notes',
  'lastAddress',
  'tagCode',
  'groups',
];

export const GlobalAdminColumns = ['createdAt', 'workspaces'];

export class AssetFilterModel implements FilterModel, ColumnModel {
  keyword?: string;
  columns?: string[];
  groups?: string[];

  constructor(data?: any) {
    this.keyword = data?.keyword;
    this.columns =
      data?.columns ||
      (Platform.OS === 'web'
        ? ['description', 'lastUpdated', 'reports', 'lastAddress', 'groups']
        : ['reports', 'lastAddress']);
    this.groups = data?.groups;
  }

  public getFilter(): any {
    const filter = {} as any;

    if (this.keyword) {
      filter.or = [
        { name: { contains: this.keyword } },
        { description: { contains: this.keyword } },
      ];
    }

    return filter;
  }

  public getGroupsCount(): number {
    let count = 0;

    if (this.groups) {
      count += this.groups.length;
    }

    return count;
  }

  public getColumnsCount(): number {
    let count = 0;

    if (this.columns) {
      count += this.columns.length;
    }

    return count;
  }

  public scrubWorkspaceData() {
    this.groups = [];
    this.columns = this.columns?.filter((x) => !x.startsWith('routine:'));
  }
}
