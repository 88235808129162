import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { FilterModel } from '@gripp/shared-logic';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, StyleSheet, Text } from 'react-native';
import { Badge } from 'react-native-paper';
import { Colors } from '../../themes';
import { MaterialCommunityIconName, MaterialIconName } from '../../utils';

type BaseFilterButtonProps = {
  filter: FilterModel;
  onGoFilter: () => void;
  icon?: MaterialIconName | MaterialCommunityIconName;
  label?: string;
  isColumnsFilter?: boolean;
  isAccountTypesFilter?: boolean;
  isTypesFilter?: boolean;
  isDateFilter?: boolean;
};

export const BaseFilterButton = ({
  filter,
  onGoFilter,
  icon,
  label,
  isColumnsFilter,
  isAccountTypesFilter,
  isTypesFilter,
  isDateFilter,
}: BaseFilterButtonProps) => {
  const { t } = useTranslation();
  const isWeb = Platform.OS === 'web';

  const [isHovered, setIsHovered] = useState(false);

  const hasGroups = () => {
    return filter.getGroupsCount && filter.getGroupsCount() > 0;
  };

  const hasAccountTypes = () => {
    return filter.getAccountTypesCount && filter.getAccountTypesCount() > 0;
  };

  const hasTypes = () => {
    return filter.getTypesCount && filter.getTypesCount() > 0;
  };

  const hasColumns = () => {
    return filter.getColumnsCount && filter.getColumnsCount() > 0;
  };

  const hasDate = () => {
    return filter.isDateFilterActive();
  };

  const isFilterActive =
    (hasGroups() &&
      !isColumnsFilter &&
      !isTypesFilter &&
      !isAccountTypesFilter) ||
    (hasAccountTypes() && isAccountTypesFilter) ||
    (isColumnsFilter && hasColumns()) ||
    (isTypesFilter && hasTypes()) ||
    (isDateFilter && hasDate());

  const getBackgroundColor = () => {
    return (
      (isFilterActive && Colors.orangeActive) ||
      (isWeb ? Colors.white : Colors.primaryGrayBackground)
    );
  };

  const getHoverStyle = () => {
    if (isFilterActive) {
      return isHovered ? styles.hoveredActive : undefined;
    }
    return isHovered ? styles.hovered : undefined;
  };

  const RenderBadge = () => {
    //TODO: in the future we can update this function
    if (hasGroups() && !isColumnsFilter && !isTypesFilter && !isDateFilter)
      return <Badge style={styles.badgeStyle}>{filter.getGroupsCount()}</Badge>;
    else if (isAccountTypesFilter && hasAccountTypes())
      return (
        <Badge style={styles.badgeStyle}>
          {filter.getAccountTypesCount && filter.getAccountTypesCount()}
        </Badge>
      );
    else if (isColumnsFilter && hasColumns())
      return (
        <Badge style={styles.badgeStyle}>
          {filter.getColumnsCount && filter.getColumnsCount()}
        </Badge>
      );
    else if (isTypesFilter && hasTypes())
      return (
        <Badge style={styles.badgeStyle}>
          {filter.getTypesCount && filter.getTypesCount()}
        </Badge>
      );
    else {
      return;
    }
  };

  const isMaterialIcon = (
    icon?: MaterialIconName | MaterialCommunityIconName
  ): icon is MaterialIconName => {
    return icon ? icon in MaterialIcons.glyphMap : false;
  };

  return (
    <Pressable
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      style={[
        { backgroundColor: getBackgroundColor() },
        getHoverStyle(),
        styles.container,
      ]}
      onPress={onGoFilter}
    >
      {isMaterialIcon(icon) ? (
        <MaterialIcons name={icon as MaterialIconName} size={16} />
      ) : (
        <MaterialCommunityIcons
          name={icon as MaterialCommunityIconName}
          size={16}
        />
      )}
      <Text style={styles.label}>{t(`${label}`)}</Text>
      {RenderBadge()}
      <MaterialCommunityIcons
        name={'chevron-down'}
        size={16}
        color={Colors.black}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 32,
    borderRadius: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 8,
    marginRight: 8,
  },
  label: {
    fontSize: 16,
    fontWeight: '500',
    letterSpacing: -0.25,
    marginHorizontal: 4,
  },
  badgeStyle: {
    backgroundColor: Colors.primary,
    alignSelf: 'center',
    fontSize: 12,
    fontWeight: '500',
    marginRight: 4,
  },
  hovered: {
    backgroundColor: Colors.grayDivider,
  },
  hoveredActive: {
    backgroundColor: Colors.orangeActiveHovered,
  },
});
