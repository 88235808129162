import { useCurrentWorkspace } from '@gripp/shared-logic';
import { Colors } from '@gripp/shared-ui';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Button } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';
import { ModalButton } from '../../shared/components/modalButton';
import { WorkspaceRoutineAdmin } from '../../workspaces/components/workspaceRoutineAdmin';

export const RoutinesBanner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const workspace = useCurrentWorkspace();

  const routeToNewRoutine = () => {
    navigate('new');
  };

  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <Button
          icon="plus"
          style={styles.button}
          labelStyle={styles.buttonLabel}
          onPress={routeToNewRoutine}
          buttonColor={Colors.primary}
        >
          {t('routines.createNew')}
        </Button>
        <ModalButton
          icon="format-list-bulleted-type"
          label={t('routines.assign')}
          modalComponent={() => {
            return (
              <WorkspaceRoutineAdmin
                workspaceId={workspace?.id}
                workspaceName={workspace?.name}
              />
            );
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    height: 151,
    borderRadius: 12,
    backgroundColor: Colors.white,
    marginBottom: 20,
    justifyContent: 'flex-end',
    paddingRight: 30,
    alignItems: 'center',
  },
  inner: {
    flexDirection: 'column',
  },
  button: {
    borderRadius: 8,
    width: '100%',
    maxWidth: 256,
    height: 44,
    justifyContent: 'center',
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.white,
  },
});

export default RoutinesBanner;
