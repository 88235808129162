/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AccountFilterModel,
  PermissionValues,
  useAccountFilter,
  useCurrentAccount,
  useCurrentWorkspace,
} from '@gripp/shared-logic';
import { AccountSearchInput, FilterType } from '@gripp/shared-ui';
import { useCallback, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useParams } from 'react-router-dom';
import { FilterBar } from '../../shared/components/filter/filterBar';
import { AccountGroups } from './accountGroups';
import { AccountList } from './accountList';

export const Accounts = () => {
  const { workspaceId } = useParams();
  const { data: accountFilter } = useAccountFilter();

  const { account } = useCurrentAccount();
  const [rowsSelected, setRowsSelected] = useState(Array<any>);

  const impersonatingWorkspace = useCurrentWorkspace(true);

  const showCheckboxColumn = useMemo(
    () => !!impersonatingWorkspace,
    [impersonatingWorkspace]
  );

  const showImpersonateWorkspace = useMemo(
    () => !!(account && account.hasPermission(PermissionValues.globalAdmin)),
    [account]
  );

  const currentWorkspaceId = useCallback(() => {
    if (workspaceId) {
      return workspaceId;
    }

    if (impersonatingWorkspace) {
      return impersonatingWorkspace.id;
    }

    return undefined;
  }, [impersonatingWorkspace, workspaceId]);

  const filter = useMemo(() => {
    const filter: AccountFilterModel =
      accountFilter ?? new AccountFilterModel();
    filter.workspaceId = currentWorkspaceId();

    return filter.getGraphQLFilter();
  }, [accountFilter, currentWorkspaceId]);

  const updateRowsSelected = useCallback((rows: Array<any>) => {
    setRowsSelected(rows);
  }, []);

  const AddToGroups = () =>
    showCheckboxColumn ? (
      <AccountGroups
        accountIds={rowsSelected}
        workspaceId={currentWorkspaceId() as string}
      />
    ) : null;

  return (
    <View style={styles.container}>
      <View style={styles.filtersContainer}>
        <View style={styles.searchContainer}>
          <AccountSearchInput />
        </View>
        <FilterBar
          filterButtonTypes={[
            FilterType.ACCOUNTGROUPS,
            FilterType.ACCOUNTTYPES,
            FilterType.ACCOUNTCOLUMNS,
          ]}
        />
      </View>
      <AccountList
        filter={filter}
        updateRowsSelected={updateRowsSelected}
        showCheckboxColumn={showCheckboxColumn}
        showImpersonateWorkspace={showImpersonateWorkspace}
        addToGroupsComponent={<AddToGroups />}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  filtersContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 44,
    marginBottom: 20,
  },
  searchContainer: {
    width: 390,
    marginRight: 20,
  },
});
