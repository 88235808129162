import { useGroups } from '@gripp/shared-logic';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, TextStyle, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../themes';
import { useFormContext } from '../forms';
import LoadingIndicator from '../loadingIndicator';
import { CheckBoxSelector } from './checkBoxSelector';

type GroupFilterProps = {
  fieldName: string;
  workspace?: any;
  headerStyle: StyleProp<TextStyle>;
};

export const GroupFilter = (props: GroupFilterProps) => {
  const { groups, isLoading } = useGroups(props.workspace?.id);
  const { setValue, watch } = useFormContext();
  const selectedGroups = watch(props.fieldName);
  const { t } = useTranslation();

  const onGroupsChange = useCallback(
    (group: string) => {
      const setOptions = {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      };
      if (selectedGroups?.includes(group)) {
        setValue(
          props.fieldName,
          selectedGroups.filter((c) => c !== group),
          setOptions
        );
      } else {
        setValue(
          props.fieldName,
          [...(selectedGroups || []), group],
          setOptions
        );
      }
    },
    [selectedGroups, setValue]
  );

  return isLoading ? (
    <LoadingIndicator flexContainer backgroundColor="transparent" />
  ) : props.workspace && groups && groups.length > 0 ? (
    <>
      <Text style={props.headerStyle}>{t('assets.filter.groups')}</Text>
      {groups.map((group) => (
        <CheckBoxSelector
          key={group.id}
          option={group.id}
          options={selectedGroups}
          onChange={onGroupsChange}
          label={group.name}
        />
      ))}
    </>
  ) : (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text
        style={{ fontSize: 16, fontWeight: '500', color: Colors.blackText }}
      >
        {t('groups.noGroups')}
      </Text>
    </View>
  );
};
