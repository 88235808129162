import { CurrentAccountAvatar } from '@gripp/shared-ui';
import { StyleSheet, View } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { profilePaths } from '../../profile/routes';
import { getMediaBaseUrl } from '../../shared/mediaUtils';

export const ProfileMenu = () => {
  const navigate = useNavigate();
  const mediaBaseUrl = getMediaBaseUrl();

  const onNavigate = (path: string) => {
    navigate(`/${path}`);
  };

  return (
    <View style={styles.profileIcon}>
      <CurrentAccountAvatar
        mediaUrl={mediaBaseUrl}
        size={36}
        onPress={() => onNavigate(profilePaths.root)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  profileIcon: {
    marginLeft: 'auto',
  },
});
