import { useGetQuery } from '../../graphql';
import { Workspace } from '../../workspace/domain';
import { useEffect, useState } from 'react';

export const useWorkspace = (workspaceId: string): Workspace | undefined => {
  const [workspace, setWorkspace] = useState<Workspace | undefined>();

  const { data } = useGetQuery({
    modelName: 'workspace',
    query: GET_WORKSPACE,
    id: workspaceId,
    options: {
      enabled: !!workspaceId,
    },
  });

  useEffect(() => {
    if (!data) return;
    setWorkspace(data as Workspace);
  }, [data]);

  return workspace;
};

const GET_WORKSPACE = `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      name
    }
  }
`;
