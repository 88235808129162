import { Platform, StyleSheet } from 'react-native';
import { Colors } from '../../themes/colors';
import { Spacing } from '../../styles';

export const preferencesStyles = StyleSheet.create({
  contentContainer: {
    paddingTop: Spacing.basePadding.paddingVertical,
    gap: 24,
    marginBottom: Spacing.baseMargin.base,
    ...Platform.select({
      web: {
        paddingTop: 0,
      },
    }),
  },
  heading: {
    fontSize: 16,
    lineHeight: 16,
    color: Colors.black,
    marginBottom: 12,
    fontWeight: '700',
  },
  checkbox: {
    flexShrink: 1,
    maxWidth: '100%',
    paddingHorizontal: Spacing.basePadding.base,
  },
  label: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19,
    paddingLeft: 8,
  },
  contentFieldForm: {
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    borderRadius: 12,
    flexShrink: 1,
    ...Platform.select({
      web: {
        borderRadius: 8,
      },
    }),
  },
  severityIcon: {
    width: 24,
    height: 24,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxPressable: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  checkboxPressableItem: {
    height: 48,
    paddingLeft: 0,
    paddingRight: 0,
    transform: [{ translateX: 6 }],
  },
  checkboxPressableIconLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    height: 0.5,
    margin: 'auto',
    borderBottomColor: Colors.graySpacer,
    width: '100%',
    ...Platform.select({
      web: {
        width: 400,
      },
    }),
  },
});
