import { PermissionValues as Permissions } from '@gripp/shared-logic';
import { Accounts } from './components/accounts';

export const accountRoute = {
  path: 'accounts/:workspaceId?',
  element: <Accounts />,
  handle: {
    header: {
      titleKey: 'people.menu.people',
      icon: 'account-multiple',
    },
    requiredPermission: Permissions.account.allWorkspace,
  },
};
