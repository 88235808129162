import { MaterialCommunityIcons as Icon } from '@expo/vector-icons';
import { StatusConfig, StatusState } from '@gripp/shared-logic';
import { FC } from 'react';
import { RoutineButton } from '../components/routineButton';
import { RoutineButtonProps } from '../types';
import { StatusComponentProps } from './types';

interface StatusButtonsProps
  extends StatusComponentProps,
    RoutineButtonProps<StatusConfig, StatusState> {}

export const StatusButtons: FC<StatusButtonsProps> = (
  props: StatusButtonsProps
) => {
  const ButtonText = (
    <>
      {props.config.routineButtonText}
      <Icon name="chevron-down" />
    </>
  );

  return (
    <RoutineButton
      text={ButtonText}
      onClick={props.onClick}
      buttonStyle={props.styleOverrides?.buttonStyle}
      labelStyle={props.styleOverrides?.labelStyle}
      buttonIcon={props.styleOverrides?.buttonIcon}
      buttonIconStyle={props.styleOverrides?.buttonIconStyle}
    />
  );
};
