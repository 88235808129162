import { RadioQuestionConfig } from '@gripp/shared-logic';
import { useTranslation } from 'react-i18next';
import { ControlledTextArrayInput } from '../../../../forms/controlledTextArrayInput';
import {
  RoutineQuestionAdminForm,
  RoutineQuestionAdminFormProps,
} from '../types';

export const RadioQuestionAdminForm: RoutineQuestionAdminForm<
  RadioQuestionConfig
> = (props: RoutineQuestionAdminFormProps<RadioQuestionConfig>) => {
  const { t } = useTranslation();

  const fieldName = props.indexPrefix
    ? `${props.indexPrefix}.options`
    : 'options';

  props.control.register(fieldName, {
    value: props.config.options,
  });

  return (
    <ControlledTextArrayInput
      label={t('routines.workflow.admin.question.options')}
      addMoreLabel={t('routines.workflow.admin.question.addOption')}
      name={fieldName}
      control={props.control}
      defaultValues={props.config.options}
      showResponseExceptions={true}
      defaultExceptionValues={props.config.responseExceptions}
      exceptionsPrefix={props.indexPrefix}
    />
  );
};
