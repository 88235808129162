import { useUpdateMutation } from '../../graphql';

export const useUpdateAccount = () => {
  return useUpdateMutation({
    modelName: 'account',
    query: UPDATE_ACCOUNT,
  });
};

const UPDATE_ACCOUNT = `
  mutation UpdateAccount($input: MutateAccountInput!) {
    updateAccount(input: $input) {
      id
      name {
        first
        last
      }
      email
      phone {
        callingCode
        countryCode
        phoneNumber
      }
      jobTitle
    }
  }
`;
