import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, Platform, StyleSheet, View } from 'react-native';
import Credentials from './credentials';
import { DividerText } from './dividerText';
import EmailCodeLogin from './emailCodeLogin';
import { LogoHeader } from './logoHeader';
import { LoginProps } from './props';
import SmsCodeLogin from './smsCodeLogin';
import { styles } from './styles';
import { ToggleLoginType } from './toggleLoginType';

const enum LoginStrategy {
  SMS = 'SMS',
  Email = 'EMAIL',
  Password = 'PASSWORD',
}

export const CodeLogin = (props: LoginProps) => {
  const { t } = useTranslation();

  const [loginStrategy, setLoginStrategy] = useState(LoginStrategy.SMS);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? -90 : -120}
      style={styles.container}
    >
      {!props.hideLogoHeader && <LogoHeader />}
      {loginStrategy === LoginStrategy.SMS && <SmsCodeLogin {...props} />}
      {loginStrategy === LoginStrategy.Email && <EmailCodeLogin {...props} />}
      {loginStrategy === LoginStrategy.Password && <Credentials {...props} />}

      <View style={localStyles.toggleContainer}>
        <ToggleLoginType
          text={
            loginStrategy === LoginStrategy.SMS
              ? t('login.withEmail')
              : t('login.withPhone')
          }
          onChangeLoginType={() =>
            setLoginStrategy(
              loginStrategy === LoginStrategy.SMS
                ? LoginStrategy.Email
                : LoginStrategy.SMS
            )
          }
        />
        <DividerText text={t('or')} />
        <ToggleLoginType
          text={
            loginStrategy === LoginStrategy.Password
              ? t('login.withEmail')
              : t('login.withPassword')
          }
          onChangeLoginType={() =>
            setLoginStrategy(
              loginStrategy === LoginStrategy.Password
                ? LoginStrategy.Email
                : LoginStrategy.Password
            )
          }
        />
      </View>
    </KeyboardAvoidingView>
  );
};

const localStyles = StyleSheet.create({
  toggleContainer: {
    marginTop: 60,
    minWidth: 260,
  },
});
