import { useFindByQuery } from '@gripp/shared-logic';
import { useMemo } from 'react';
import { WhatItemType } from './types';

export const useFindWorkspaceAssets = (
  type: string,
  id: string,
  enabled: boolean
) => {
  const filter = useMemo(() => {
    return type === WhatItemType.Assets
      ? {
          asset: { eq: id },
        }
      : {
          groups: { eq: id },
        };
  }, [id]);

  const { data, isLoading } = useFindByQuery({
    query: FIND_WORKSPACE_ASSETS,
    modelName: 'workspaceAsset',
    variables: { filter },
    options: {
      enabled: enabled,
    },
  });

  return { data, isLoading };
};

export const FIND_ASSETS = `
  query FindAssets($filter: AssetFilter, $page: PageRequest, $orderBy: OrderByInput) {
    findAssets(filter: $filter, page: $page, orderBy: $orderBy) {
      items {
        id
        description
        name
      }
      count
      limit
      offset
    }
  }
`;

export const FIND_GROUPS = `
  query FindGroups($filter: GroupFilter, $page: PageRequest, $orderBy: OrderByInput) {
    findGroups(filter: $filter, page: $page, orderBy: $orderBy) {
      items {
        id
        name
      }
      count
      limit
      offset
    }
  }
`;

export const FIND_WORKSPACE_ASSETS = `
  query FindWorkspaceAssets($filter: WorkspaceAssetFilter) {
    findWorkspaceAssets(filter: $filter) {
      items {
        id
        routines {
          id
          name
          type
          deactivated
        }
      }
    }
  }
`;

export const CREATE_WORKFLOW = `
  mutation createWorkflow($input: CreateWorkflowInput!) {
    createWorkflow(input: $input) {
      id
    }
  }
`;
