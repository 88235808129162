import { Controller } from 'react-hook-form';
import { StyleProp, ViewStyle } from 'react-native';
import { Checkbox } from 'react-native-paper';

type ControlledCheckboxItemProps = {
  control: any;
  name: string;
  label: string;
  position: 'leading' | 'trailing';
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<ViewStyle>;
};

export const ControlledCheckboxItem = (props: ControlledCheckboxItemProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => (
        <Checkbox.Item
          style={props.style}
          labelStyle={props.labelStyle}
          label={props.label}
          position={props.position}
          status={value ? 'checked' : 'unchecked'}
          onPress={() => onChange(!value)}
        />
      )}
    />
  );
};
