import { useGetQuery } from '../../graphql';

export const useIssue = (issueId: string) => {
  const { data, isLoading } = useGetQuery({
    modelName: 'issue',
    query: GET_ISSUE,
    id: issueId,
  });

  return { issue: data, isLoading };
};

const GET_ISSUE = `
    query getIssue($id: ID!) {
      getIssue(id: $id) {
        id
        title
        type
        severity
        workspace {
          id
          name
        }
        asset {
          id
          name
          description
        }
        createdBy {
          id
          name {
            first
            last
          }
        }
        createdAt
        updatedAt
      }
    }
`;
