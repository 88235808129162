import {
  PermissionValues,
  useCurrentAccount,
  useCurrentWorkspace,
  useImpersonateWorkspace,
} from '@gripp/shared-logic';
import { Colors } from '@gripp/shared-ui';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Button } from 'react-native-paper';
import { Header } from './header';
import { ProfileMenu } from './profileMenu';

export const Banner = () => {
  const { account } = useCurrentAccount();
  const workspace = useCurrentWorkspace(true);
  const [isInWorkspace, setIsInWorkspace] = useState<boolean | undefined>();
  const { impersonateWorkspace } = useImpersonateWorkspace();

  useEffect(() => {
    if (!account) {
      return;
    }
    setIsInWorkspace(
      workspace && account.hasPermission(PermissionValues.globalAdmin)
    );
  }, [account, workspace]);

  const onLeaveWorkspace = async () => {
    await impersonateWorkspace(undefined);
  };

  return (
    <>
      {isInWorkspace && (
        <View style={styles.workspaceBanner}>
          <Text style={styles.workspaceBannerText}>
            You are acting as "Gripp Admin" in {workspace?.name} workspace
          </Text>
          <Button
            style={styles.button}
            labelStyle={{ color: Colors.white }}
            onPress={onLeaveWorkspace}
            mode="outlined"
          >
            Leave
          </Button>
        </View>
      )}
      <View style={styles.container}>
        <Header />
        <ProfileMenu />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 84,
    backgroundColor: Colors.white,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  workspaceBanner: {
    flexDirection: 'row',
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.blue,
  },
  workspaceBannerText: {
    textAlign: 'center',
    color: Colors.white,
    fontSize: 20,
  },
  button: {
    borderColor: Colors.white,
    borderWidth: 1,
    borderRadius: 6,
    marginHorizontal: 10,
    maxHeight: 40,
  },
});
