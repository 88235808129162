import {
  WorkflowQuestionConfig,
  WorkflowQuestionType,
} from '@gripp/shared-logic';
import { useEffect } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { useFormContext } from '../../../forms/formContext';
import { RoutineAdminInputText } from '../../components/routineAdminInputText';
import { RoutineQuestionModules } from '../questions/routineQuestionModules';

type WorkflowAdminQuestionProps = {
  question: WorkflowQuestionConfig;
  index: number;
  control: Control;
  type: WorkflowQuestionType;
  pagePrefix: string;
};

export const WorkflowAdminQuestion = (props: WorkflowAdminQuestionProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { question, index, control, type: questionType, pagePrefix } = props;
  const i18nPrefix = 'routines.workflow.admin.question';
  const indexPrefix = `${pagePrefix}.questions.${index}`;
  const questionOrder = index + 1;
  const QuestionAdmin =
    RoutineQuestionModules[questionType].adminForm ?? (() => null);

  useEffect(() => {
    if (question.order === questionOrder) return;
    setValue(`${indexPrefix}.name`, `Question ${questionOrder}`);
    setValue(`${indexPrefix}.order`, questionOrder);
  }, [questionOrder]);

  return (
    <>
      <View style={styles.headerRow}>
        <Text style={styles.header}>
          {t(`${i18nPrefix}.question`, { question: index + 1 })}
        </Text>
        <Text style={[styles.header, styles.questionType]}>
          {t(`routines.workflow.questionTypes.${questionType}`)}
        </Text>
      </View>
      <RoutineAdminInputText
        control={control}
        name="instructions"
        i18nPrefix={i18nPrefix}
        indexPrefix={indexPrefix}
        textArea={true}
      />
      <QuestionAdmin
        config={question}
        control={control}
        indexPrefix={indexPrefix}
      />
    </>
  );
};

const styles = StyleSheet.create({
  headerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  header: {
    fontSize: 18,
    fontWeight: '700',
  },
  questionType: {
    flexGrow: 1,
    textAlign: 'right',
    marginRight: 10,
  },
});
