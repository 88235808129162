import {
  RoutineConfig,
  RoutineType,
  useUpdateMutation,
  useWorkspaceRoutine,
} from '@gripp/shared-logic';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RoutineEditForm } from './routineEditForm';

export const EditRoutine = () => {
  const { t } = useTranslation();
  const { routineId } = useParams();
  const workspaceRoutine = useWorkspaceRoutine(routineId as string);
  const { mutateAsync: updateRoutine } = useUpdateMutation({
    modelName: 'WorkspaceRoutine',
    query: UPDATE_WORKSPACE_ROUTINE,
  });

  const onSubmit = async (data: RoutineConfig) => {
    const sortDataByQuestions = data.pages?.map((page) => ({
      ...page,
      questions: page.questions.map((q, index) => ({ ...q, order: index + 1 })),
    }));
    const input = {
      config: { ...data, pages: sortDataByQuestions },
      name: data.title,
      id: routineId,
    };

    await updateRoutine({ input });
  };

  return (
    workspaceRoutine && (
      <RoutineEditForm
        config={workspaceRoutine?.config as RoutineConfig}
        type={workspaceRoutine?.type as RoutineType}
        onSubmit={onSubmit}
        saveButtonText={t('routines.admin.update')}
      />
    )
  );
};

const UPDATE_WORKSPACE_ROUTINE = `
  mutation UpdateWorkspaceRoutine($input: MutateWorkspaceRoutineInput!) {
    updateWorkspaceRoutine(input: $input) {
      id
    }
  }
`;
