import { CounterConfig, CounterState } from '@gripp/shared-logic';
import { FC } from 'react';
import { RoutineButton } from '../components/routineButton';
import { RoutineButtonProps } from '../types';
import { CounterComponentProps } from './types';

interface CounterButtonsProps
  extends CounterComponentProps,
    RoutineButtonProps<CounterConfig, CounterState> {}

export const CounterButtons: FC<CounterButtonsProps> = (
  props: CounterButtonsProps
) => {
  return (
    <RoutineButton
      text={props.config.routineButtonText!}
      onClick={props.onClick}
      buttonStyle={props.styleOverrides?.buttonStyle}
      labelStyle={props.styleOverrides?.labelStyle}
      buttonIcon={props.styleOverrides?.buttonIcon}
      buttonIconStyle={props.styleOverrides?.buttonIconStyle}
    />
  );
};
