import { Group } from '../../group/domain/group';
import { AssetRoutineState, WorkspaceRoutine } from '../../routine';
import { WorkflowState } from '../../workflow';
import { Workspace } from '../../workspace';
import { Asset } from './asset';

export interface WorkspaceAsset {
  id: string;
  asset: Asset;
  workspace: Workspace;
  routines?: WorkspaceRoutine[];
  routineOrder?: RoutineOrder[];
  routineState?: AssetRoutineState<any>[];
  groups?: Group[];
  workflowState?: WorkflowState[];
}

export interface RoutineOrder {
  workspaceRoutineId: string;
  order: number;
}
