import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  ActivityFilterKey,
  ActivityFilterModel,
  Severity,
  useActivityListFilter,
  useCurrentWorkspace,
  useSetActivityListFilter,
} from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, ScrollView, View } from 'react-native';
import { Checkbox, Text } from 'react-native-paper';
import { array, string, object as yupObject } from 'yup';
import { Colors } from '../../themes/colors';
import { FormContext } from '../forms';
import { SeverityIcons } from '../issue/severityIcons';
import { DropdownFooter } from '../modals/dropdownFooter';
import { ApplyButton } from './applyButton';
import { FilterType } from './filterButton';
import { FilterHeader } from './filterHeader';
import { filterStyles as styles } from './filterStyle';
import { GroupFilter } from './groupFilter';

interface ActivityFilterScreenProps {
  filterKey: ActivityFilterKey;
  goBack: () => void;
  type: FilterType;
}

export const ActivityFilter = ({
  filterKey,
  goBack,
  type,
}: ActivityFilterScreenProps) => {
  const { t } = useTranslation();
  const workspace = useCurrentWorkspace(true);
  const isWeb = Platform.OS === 'web';

  const { data: filter } = useActivityListFilter(filterKey);

  const { mutateAsync: setActivityListFilter } =
    useSetActivityListFilter(filterKey);

  const formSchema = yupObject({
    orderBy: string().optional().nullable(),
    severity: array().of(string()).optional().nullable(),
    assetName: string().optional().nullable(),
    groupIds: array().of(string()).optional().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    values: {
      orderBy: filter?.orderBy,
      severity: filter?.severity,
      assetName: filter?.assetName,
      groupIds: filter?.groupIds,
    },
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
    watch,
  } = formMethods;

  const formData = watch();

  const isTypesFilter = type === FilterType.ACTIVITYTYPES;
  const isGroupsFilter = type === FilterType.ACTIVITYGROUPS;

  const resetForm = useCallback(() => {
    reset({
      ...formData,
      ...(isTypesFilter && { severity: [] }),
      ...(isGroupsFilter && { groupIds: [] }),
    });
  }, [reset, formData, isTypesFilter, isGroupsFilter]);

  const onReset = async () => {
    const updatedFields = {
      ...(isTypesFilter && { severity: [] }),
      ...(isGroupsFilter && { groupIds: [] }),
    };

    resetForm();

    const mergedFilter = { ...filter, ...updatedFields };
    await setActivityListFilter(mergedFilter);
    goBack();
  };

  const onSubmit = async (data: any) => {
    await setActivityListFilter(new ActivityFilterModel(data));
    goBack();
  };

  const onPressColorFilter = (
    color: string,
    value: (string | undefined)[],
    onChange: (...event: any[]) => void
  ) => {
    let newValue = [];
    if (!value) {
      newValue = [color];
    } else if (!value.includes(color)) {
      newValue = value.concat(color);
    } else {
      newValue = value.filter((c: string | undefined) => c !== color);
    }
    onChange(newValue);
  };

  const canReset = useCallback(() => {
    const orderBy = formData.orderBy;
    const assetName = formData.assetName;
    const severity = formData.severity;
    const groupIds = formData.groupIds;
    const defaultFilter = new ActivityFilterModel();

    const undefinedIfEmpty = (value?: (string | undefined)[] | null) => {
      return value?.length === 0 ? undefined : value;
    };

    return (
      orderBy !== defaultFilter.orderBy ||
      assetName !== defaultFilter.assetName ||
      undefinedIfEmpty(severity) !== defaultFilter.severity ||
      undefinedIfEmpty(groupIds) !== defaultFilter.groupIds
    );
  }, [formData]);

  useEffect(() => {
    if (!filter) {
      resetForm();
    }
  }, [filter, resetForm]);

  const getFlagButtonLabel = (color: string) => {
    switch (color) {
      case Severity.Red:
        return t('activity.filter.urgent');
      case Severity.Green:
        return t('activity.filter.solved');
      case Severity.Yellow:
        return t('activity.filter.pending');
      case Severity.Purple:
        return t('activity.filter.notes');
      case Severity.Blue:
        return t('activity.filter.routines');
      default:
        return '';
    }
  };

  const renderColorFlags = () => (
    <View>
      <Text variant="titleMedium" style={styles.filterRowHeader}>
        {t('activity.filter.activityTypes')}
      </Text>
      <Controller
        control={control}
        name={'severity'}
        render={({ field: { onChange, value } }) => (
          <View style={styles.markersContainer}>
            {Object.entries(SeverityIcons).map(([color, icon]) => (
              <Pressable
                key={color}
                onPress={() => onPressColorFilter(color, value, onChange)}
              >
                <View style={[styles.markerItemRow]}>
                  <View style={styles.markerLeft}>
                    <View
                      style={[
                        styles.colorButton,
                        { backgroundColor: Colors[color] },
                      ]}
                    >
                      <MaterialCommunityIcons
                        size={16}
                        style={{ color: Colors.white }}
                        name={icon}
                      />
                    </View>

                    <Text style={styles.markerLabel}>
                      {getFlagButtonLabel(color)}
                    </Text>
                  </View>
                  <Checkbox.Item
                    label=""
                    status={value?.includes(color) ? 'checked' : 'unchecked'}
                    style={[
                      {
                        borderWidth: 0,
                        padding: 0,
                      },
                    ]}
                    onPress={() => onPressColorFilter(color, value, onChange)}
                    rippleColor={isWeb ? 'transparent' : undefined}
                    uncheckedColor={Colors.grayFill}
                    mode="android"
                  />
                </View>
                <View
                  style={{
                    borderBottomWidth: color !== Severity.Blue ? 1 : 0,
                    borderColor: Colors.graySpacer,
                    marginHorizontal: 12,
                  }}
                />
              </Pressable>
            ))}
          </View>
        )}
      />
    </View>
  );

  const renderGroups = () => (
    <View style={styles.groupsSection}>
      <GroupFilter
        fieldName="groupIds"
        headerStyle={styles.filterRowHeader}
        workspace={workspace}
      />
    </View>
  );

  return (
    <FormContext.Provider value={formMethods}>
      {!isWeb && (
        <FilterHeader canReset={canReset} onReset={onReset} goBack={goBack} />
      )}
      <View style={styles.container}>
        <View
          style={[
            styles.scrollViewContainer,
            isWeb ? { height: 416 } : { marginBottom: 30 },
          ]}
        >
          <ScrollView
            contentContainerStyle={styles.scrollViewContent}
            scrollEnabled={true}
            showsVerticalScrollIndicator={true}
          >
            {isTypesFilter && renderColorFlags()}
            {isGroupsFilter && renderGroups()}
          </ScrollView>
        </View>

        {isWeb ? (
          <DropdownFooter
            isDirty={isDirty}
            onSubmit={handleSubmit(onSubmit)}
            canReset={canReset}
            onReset={onReset}
          />
        ) : (
          <ApplyButton isDirty={isDirty} onSubmit={handleSubmit(onSubmit)} />
        )}
      </View>
    </FormContext.Provider>
  );
};
