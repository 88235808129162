import {
  PermissionValues as Permissions,
  useCurrentWorkspace,
} from '@gripp/shared-logic';
import { Colors } from '@gripp/shared-ui';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { ModalButton } from '../../shared/components/modalButton';

import { CreateGroupForm } from './createGroupForm';

export const GroupsToolbar = () => {
  const { t } = useTranslation();
  const currentWorkspace = useCurrentWorkspace();

  return (
    currentWorkspace && (
      <ModalButton
        label={t('groups.create.label')}
        icon="plus"
        requiredPermission={Permissions.workspace.create}
        containerStyle={styles.modalContainer}
        modalComponent={({ onComplete }) => {
          return (
            <CreateGroupForm
              onComplete={onComplete}
              workspaceId={currentWorkspace.id}
            />
          );
        }}
      />
    )
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 560,
    maxHeight: 193,
    borderRadius: 12,
    backgroundColor: Colors.primaryGrayBackground,
  },
});
