import { OrderByInput, useExportAssets } from '@gripp/shared-logic';
import { ColumnDef } from '@tanstack/react-table';
import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { StyleSheet } from 'react-native';
import { IconButton } from 'react-native-paper';
import { Colors } from '../../themes';

type ExportButtonProps = {
  fileName: string;
  query: any;
  filter: any;
  orderBy: OrderByInput;
  dataPrefix: string;
  columns: ColumnDef<any, any>[];
};

export const DataGridExportButton = (props: ExportButtonProps) => {
  const csvRef = useRef();
  const [download, toggleDownload] = useState(false);

  const onDataReady = () => {
    if (download) {
      doDownload();
    }
  };

  const { headers, rows, isLoading } = useExportAssets({
    query: props.query,
    filter: props.filter,
    orderBy: props.orderBy,
    dataPrefix: props.dataPrefix,
    columns: props.columns,
    download: download,
    onDataReady: onDataReady,
  });

  const doDownload = () => {
    setTimeout(() => {
      csvRef.current.link.click();
      toggleDownload(false);
    }, 50);
  };

  const onDownload = () => {
    toggleDownload(true);
  };

  return (
    <>
      <IconButton
        icon="download"
        iconColor={isLoading ? Colors.grayDisabled : Colors.orangeDark}
        onPress={onDownload}
        disabled={isLoading}
        style={styles.icon}
        size={25}
      />
      <CSVLink
        data={rows}
        headers={headers}
        filename={`${props.fileName}.csv`}
        ref={csvRef}
      />
    </>
  );
};

const styles = StyleSheet.create({
  icon: {
    margin: 0,
  },
});
