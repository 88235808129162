import { PermissionValues } from '@gripp/shared-logic';
import moment from 'moment';
import { CreateForm } from '../../shared/components/forms/mutationForm';
import { ModalButton } from '../../shared/components/modalButton';
import { CREATE_TAG_BATCH } from '../operations/createTagBatch';
import { CreateTagBatchForm } from './createTagBatchForm';

export const TagBatchesToolbar = () => {
  const transformFormSubmission = (data) => {
    return {
      quantity: data.quantity,
      workspace: { id: data.workspace },
      memo: data.memo,
      version: {
        id: '00000000-0000-0000-0000-000000000001',
      },
      status: 'ORDERED',
      name: moment().format('YYYY-MM-DD_HHMMSS'),
    };
  };

  return (
    <ModalButton
      label="Create Tag Batch"
      icon="plus"
      requiredPermission={PermissionValues.tagbatch.create}
      modalComponent={({ onComplete }) => {
        return (
          <CreateForm
            defaultValues={{
              quantity: '',
              workspace: '',
              memo: '',
            }}
            formComponent={CreateTagBatchForm}
            modelName="tagbatch"
            mutationOperation={CREATE_TAG_BATCH}
            invalidateCache={true}
            dataTransformer={transformFormSubmission}
            onComplete={onComplete}
          />
        );
      }}
    />
  );
};
