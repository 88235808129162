import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { Colors } from '../../../themes';

export type CounterSummaryProps = {
  initialValueLabel: string;
  initialValue: number;
  getDeltaValueLabel: () => string;
  getDeltaValue: () => string;
  getNewValueLabel: () => string;
  getNewValue: () => string;
};

export const CounterSummary = (props: CounterSummaryProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.summaryContainer}>
      <View style={[styles.summaryRow, { marginTop: 0 }]}>
        <Text style={styles.summaryLabel}>{props.initialValueLabel}</Text>
        <Text style={styles.summaryLabel}>{props.initialValue}</Text>
      </View>
      <View style={styles.summaryRow}>
        <Text style={[styles.summaryLabel, styles.changeLabel]}>
          {props.getDeltaValueLabel()}
        </Text>
        <Text style={[styles.summaryLabel, styles.changeLabel]}>
          {props.getDeltaValue()}
        </Text>
      </View>
      <View style={styles.summaryRow}>
        <Text style={[styles.summaryLabel, styles.newValueLabel]}>
          {props.getNewValueLabel()}
        </Text>
        <Text style={[styles.summaryLabel, styles.newValueLabel]}>
          {props.getNewValue()}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  summaryContainer: {
    marginTop: 20,
  },
  summaryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 6,
  },
  summaryLabel: {
    fontSize: 16,
    fontWeight: '400',
    color: Colors.blackText,
    letterSpacing: -0.25,
  },
  changeLabel: {
    color: Colors.info,
  },
  newValueLabel: {
    fontWeight: '500',
  },
});
