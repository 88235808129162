import { TextInput } from "react-native-paper";
import { Colors } from "../../themes/colors";

type FieldCheckIconProp = {
  invalid: boolean;
  value: string;
  icon?: string;
  color?: string;
}
export const FieldCheckIcon = ({ invalid, value, icon = "check", color = Colors.green}: FieldCheckIconProp) => (
    invalid || !value? null : <TextInput.Icon icon={icon} color={color}/>
);
