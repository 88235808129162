import { useQueryClient } from '@tanstack/react-query';
import { useCustomMutation } from '../../graphql';
import { cacheKeys } from '../../graphql';

export const useCreateIssueWithMessage = () => {
  const queryClient = useQueryClient();

  return useCustomMutation({
    query: CREATE_ISSUE,
    options: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: cacheKeys('issue').all,
        });
      },
    },
  });
};

const CREATE_ISSUE = `
  mutation Mutation($input: ReportIssueInput!) {
    issue {
      reportIssue(input: $input) {
        id
      }
    }
  } 
`;
