import { GeoJsonType, IssueType, SortDirectionEnum } from './enums';
import { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type PageRequest = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};
export type OrderByInput = {
  field: Scalars['String']['input'];
  order?: InputMaybe<SortDirectionEnum>;
};

export type FindQueryVariables = {
  filter?: InputMaybe<any>;
  orderBy?: InputMaybe<OrderByInput>;
  page?: InputMaybe<PageRequest>;
  [prop: string]: any;
};
export type GetQueryVariables = {
  id: Scalars['ID']['input'];
};

export type FindQueryResult = {
  items?: any[];
  count?: number;
  limit?: number;
  offset?: number;
};

export type CreateInput = {
  [prop: string]: any;
};
export type CreateMutationVariables = {
  input: CreateInput;
};

export type UpdateInput = {
  id: Scalars['ID']['input'];
  [prop: string]: any;
};
export type UpdateMutationVariables = {
  input: UpdateInput;
};

export type DeleteInput = {
  id: Scalars['ID']['input'];
};
export type DeleteMutationVariables = {
  input: DeleteInput;
};

export type MutationArgs = {
  query: any;
  modelName: string;
  options?: UseMutationOptions;
};
export type CustomMutationArgs = {
  query: any;
  options?: UseMutationOptions;
};

export type QueryArgs = {
  modelName: string;
  query: any;
  options?: Partial<UseQueryOptions>;
};
export type QueryInvalidationArgs = {
  invalidateQuery: () => Promise<void>;
};

export type FindByQueryArgs = QueryArgs & {
  variables?: FindQueryVariables;
};
export type FindByQueryResults = QueryInvalidationArgs & {
  data: any;
  isLoading: boolean;
  refetch: () => Promise<any>;
};

export type GetQueryArgs = QueryArgs & {
  id: string;
};
export type CustomQueryArgs = {
  cacheKey: unknown[];
  query: any;
  options?: Partial<UseQueryOptions>;
  variables?: any;
};

export type LocationInput = {
  coordinates?: InputMaybe<Scalars['JSON']['input']>;
  type: GeoJsonType;
};

export type ReportIssueInput = {
  assetId?: string;
  location?: InputMaybe<LocationInput>;
  media?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  message: Scalars['String']['input'];
  severity: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: IssueType;
  userMentions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  workspaceId: Scalars['ID']['input'];
};
