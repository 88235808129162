import { Colors } from 'libs/shared-ui/src/themes';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

export type QuickConfirmExecutionComponentProps = {
  options: string[];
  confirmedValue: string;
  onChange: (confirmedValue: string) => void;
};

export const QuickConfirmExecutionComponent = (
  props: QuickConfirmExecutionComponentProps
) => {
  const lastValue = props.options[props.options.length - 1];

  return (
    <View style={styles.buttonsContainer}>
      {props.options.length > 1
        ? props.options.map((confirmedValue) => {
            const buttonBackgroundStyle = [styles.button];
            const buttonLabelStyle = [styles.buttonLabel];

            if (confirmedValue === props.confirmedValue) {
              buttonBackgroundStyle.push(styles.currentStateButton);
              buttonLabelStyle.push(styles.currentButtonLabel);
            }

            return (
              <TouchableOpacity
                key={confirmedValue}
                activeOpacity={0.7}
                style={[
                  buttonBackgroundStyle,
                  lastValue === confirmedValue && styles.bottomValueButton,
                ]}
                onPress={() => props.onChange(confirmedValue)}
              >
                <Text style={buttonLabelStyle}>{confirmedValue}</Text>
              </TouchableOpacity>
            );
          })
        : null}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  button: {
    height: 52,
    borderWidth: 2,
    borderRadius: 51,
    borderColor: Colors.blue,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  currentStateButton: {
    backgroundColor: Colors.blue,
  },
  bottomValueButton: {
    marginBottom: 0,
  },
  buttonLabel: {
    color: Colors.black,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 19,
  },
  currentButtonLabel: {
    color: Colors.white,
  },
});
