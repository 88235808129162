import { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import { Graphback } from '../graphback';

export const useFetcher = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
  let config: Partial<AxiosRequestConfig>;

  if (options) {
    const headers = { ...options } as RawAxiosRequestHeaders;
    config = {
      headers,
    };
  }

  return async (variables?: TVariables) => {
    const res = await Graphback.getInstance().post(
      '',
      { query, variables },
      config
    );

    const json = res.data;

    if (json.errors) {
      const { message } = json.errors[0] || {};
      throw new Error(message || 'Error…');
    }

    return json.data;
  };
};
