import { SingleStateConfig } from './routineConfig';
import { RoutineState } from './routineState';

export interface QuickConfirmBaseConfig {
  options: string[];
}

export interface QuickConfirmConfig
  extends SingleStateConfig,
    QuickConfirmBaseConfig {}

export interface QuickConfirmResponse {
  confirmedValue: string;
}

export interface QuickConfirmState extends RoutineState, QuickConfirmResponse {}
