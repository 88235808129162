import { Text, View } from 'react-native';
import { styles } from './styles';

type DividerTextProps = {
  text: string;
};

export const DividerText = (props: DividerTextProps) => {
  return (
    <View style={styles.dividerContainer}>
      <View style={styles.divider} />
      <View>
        <Text style={styles.dividerText}>{props.text}</Text>
      </View>
      <View style={styles.divider} />
    </View>
  );
};
