import { Colors } from '@gripp/shared-ui';
import { t } from 'i18next';
import React from 'react';
import { Dimensions, Modal, StyleSheet, View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { useVersionCheck } from '../hooks/useVersionCheck';

export const CheckAppVersion = () => {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  useVersionCheck({
    onVersionMismatch: () => {
      setModalVisible(true);
    },
  });

  const handleRefresh = () => {
    setModalVisible(false);
    window.location.href = '/';
  };

  return (
    <Modal
      visible={modalVisible}
      transparent={true}
      onRequestClose={() => setModalVisible(false)}
    >
      <View style={styles.modalContainer}>
        <View style={styles.contentContainer}>
          <Text style={styles.description}>
            {t('versionCheck.description')}
          </Text>
          <Button
            style={styles.button}
            onPress={handleRefresh}
            labelStyle={styles.refreshText}
            focusable={false}
          >
            {t('versionCheck.refreshButton')}
          </Button>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: Dimensions.get('window').height / 50,
    backgroundColor: Colors.blackOverlay,
  },
  contentContainer: {
    backgroundColor: Colors.orangeActive,
    width: '100%',
    maxWidth: 731,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
    paddingVertical: 20,
    paddingHorizontal: 12,
    justifyContent: 'space-between',
    gap: 4,
  },
  button: {
    borderRadius: 8,
    width: 75,
    height: 24,
    maxHeight: 24,
    justifyContent: 'center',
    backgroundColor: Colors.primary,
    marginHorizontal: 0,
    outlineStyle: 'none',
    outlineWidth: 0,
  },
  description: {
    fontSize: 16,
    color: Colors.blackText,
  },
  refreshText: {
    color: Colors.white,
    fontSize: 14,
    margin: 0,
    fontWeight: 400,
    lineHeight: 16,
  },
});
