import { EnvConfig, Media, MediaData } from '@gripp/shared-logic';
import { useEffect, useRef, useState } from 'react';
import { createTypedMediaItem, processTypedMediaItem } from './mediaUtils';

export const useTypedMedia = <T extends MediaData>(
  existingMedia: Media[] | undefined
) => {
  const [typedMedia, setTypedMedia] = useState<T[]>([]);
  const baseMediaUrl = EnvConfig.mediaUrl;
  const baseApiUrl = EnvConfig.apiUrl;
  const existingMediaProcessed = useRef(false);

  useEffect(() => {
    if (existingMedia && !existingMediaProcessed.current) {
      setTypedMedia(
        existingMedia.map((m) =>
          createTypedMediaItem(m, baseMediaUrl, baseApiUrl)
        )
      );
      existingMediaProcessed.current = true;
    }
  }, [existingMedia]);

  const handleAdd = (
    newMediaItem: T,
    callback?: (newMediaItem: Media) => void
  ) => {
    setTypedMedia((prevState: T[]) => {
      processTypedMediaItem(newMediaItem);
      callback?.(newMediaItem);

      return [...prevState, newMediaItem];
    });
  };

  const handleUpdate = (updatedMediaItem: T) => {
    setTypedMedia((prevState: T[]) => {
      return prevState.map((media) =>
        media.id === updatedMediaItem.id
          ? { ...media, ...updatedMediaItem }
          : media
      );
    });
  };

  const handleDelete = (
    mediaId: string,
    callback?: (mediaId: string) => void
  ) => {
    setTypedMedia((prevState: T[]) => {
      callback?.(mediaId);

      return prevState.filter((media) => media.id !== mediaId);
    });
  };

  const handleUploadProgress = (mediaId: string, progress: number) => {
    const toSetProgress = {
      id: mediaId,
      uploadProgress: progress,
      isUploading: progress !== 1,
    } as T;
    handleUpdate(toSetProgress);
  };

  const reset = () => {
    setTypedMedia([]);
  };

  //todo: add error handling
  return {
    typedMedia,
    handleAdd,
    handleDelete,
    handleUploadProgress,
    handleUpdate,
    reset,
  };
};
