import {
  ActivityFilterKey,
  ActivityFilterModel,
  DateTypeFilter,
  useActivityListFilter,
  useSetActivityListFilter,
} from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, ScrollView, View } from 'react-native';
import { RadioButton, Text } from 'react-native-paper';
import { mixed, string, object as yupObject } from 'yup';
import { DateRangeSelector, FormContext } from '../forms';
import { DropdownFooter } from '../modals/dropdownFooter';
import { ApplyButton } from './applyButton';
import { FilterType } from './filterButton';
import { FilterHeader } from './filterHeader';
import { filterStyles as styles } from './filterStyle';

interface ActivityDateFilterProps {
  filterKey: ActivityFilterKey;
  goBack: () => void;
  type: FilterType;
}

export const ActivityDateFilter = ({
  filterKey,
  goBack,
  type,
}: ActivityDateFilterProps) => {
  const { t } = useTranslation();
  const isWeb = Platform.OS === 'web';

  const { data: filter } = useActivityListFilter(filterKey);

  const { mutateAsync: setActivityListFilter } =
    useSetActivityListFilter(filterKey);

  const formSchema = yupObject({
    dateType: mixed<DateTypeFilter>()
      .oneOf(Object.values(DateTypeFilter))
      .required(),
    startDate: string().when('dateType', (dateType, schema) => {
      const value = Array.isArray(dateType) ? dateType[0] : dateType;
      return value === DateTypeFilter.CUSTOMDATE ? schema.required() : schema;
    }),
    endDate: string().when('dateType', (dateType, schema) => {
      const value = Array.isArray(dateType) ? dateType[0] : dateType;
      return value === DateTypeFilter.CUSTOMDATE ? schema.required() : schema;
    }),
  });

  const formMethods = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    values: {
      dateType: filter?.dateType,
      startDate: filter?.startDate,
      endDate: filter?.endDate,
    },
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
    setValue,
    watch,
  } = formMethods;

  const formData = watch();
  const selectedDate = watch('dateType');

  const resetForm = useCallback(() => {
    reset({ dateType: DateTypeFilter.ALL });
  }, [reset]);

  const onReset = async () => {
    await setActivityListFilter(
      new ActivityFilterModel({
        ...filter,
        dateType: DateTypeFilter.ALL,
        startDate: '',
        endDate: '',
      })
    );
    resetForm();
    goBack();
  };

  const onSubmit = async (data: any) => {
    await setActivityListFilter(
      new ActivityFilterModel({ ...filter, ...data })
    );
    goBack();
  };

  const canReset = useCallback(() => {
    const date = formData.dateType;
    const defaultFilter = {
      dateType: DateTypeFilter.ALL,
      startDate: '',
      endDate: '',
    };

    return date !== defaultFilter.dateType;
  }, [formData]);

  useEffect(() => {
    if (!filter) {
      resetForm();
    }
  }, [filter, resetForm]);

  const onDateChange = (date: string) => {
    if (
      date !== DateTypeFilter.CUSTOMDATE &&
      (control._formValues.endDate || control._formValues.startDate)
    ) {
      setValue('startDate', '');
      setValue('endDate', '');
    }
    setValue('dateType', date, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const renderCustomDate = (value: string) => {
    if (value !== DateTypeFilter.CUSTOMDATE) return null;

    return (
      <View style={styles.renderCustomDate}>
        <DateRangeSelector control={control} setValue={setValue} />
      </View>
    );
  };

  return (
    <FormContext.Provider value={formMethods}>
      {!isWeb && (
        <FilterHeader canReset={canReset} onReset={onReset} goBack={goBack} />
      )}
      <View style={styles.container}>
        <View
          style={[
            styles.scrollViewContainer,
            isWeb ? { height: 416 } : { marginBottom: 30 },
          ]}
        >
          <ScrollView
            contentContainerStyle={styles.scrollViewContent}
            scrollEnabled={true}
            showsVerticalScrollIndicator={true}
          >
            <View>
              {Object.entries(DateTypeFilter).map(([key, value]) => {
                const enumKey = key as keyof typeof DateTypeFilter;
                return (
                  <Pressable
                    key={enumKey}
                    onPress={() => onDateChange(DateTypeFilter[enumKey])}
                  >
                    <View style={styles.dateFilterRowRadioButton}>
                      <View
                        style={[
                          styles.rowDateFilterContainer,
                          styles.rowRadioButton,
                        ]}
                      >
                        <Text numberOfLines={1} style={styles.checkboxLabel}>
                          {t(`activity.filter.${value}`)}
                        </Text>
                        <RadioButton.Android
                          value={DateTypeFilter[enumKey]}
                          status={
                            DateTypeFilter[enumKey] === selectedDate
                              ? 'checked'
                              : 'unchecked'
                          }
                          onPress={() => onDateChange(DateTypeFilter[enumKey])}
                        />
                      </View>
                      {renderCustomDate(value)}
                    </View>
                  </Pressable>
                );
              })}
            </View>
          </ScrollView>
        </View>

        {isWeb ? (
          <DropdownFooter
            isDirty={isDirty}
            onSubmit={handleSubmit(onSubmit)}
            canReset={canReset}
            onReset={onReset}
          />
        ) : (
          <ApplyButton isDirty={isDirty} onSubmit={handleSubmit(onSubmit)} />
        )}
      </View>
    </FormContext.Provider>
  );
};
