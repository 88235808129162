import {
  ActivityOrderBy,
  Issue,
  IssueMessageType,
  Media,
  Message,
  Name,
  formatShortDateOrSameDay,
  formatShortDateTimeOrSameDay,
  formatShortDateAndTimeOrSameDay,
  getFullName,
} from '@gripp/shared-logic';
import {
  Platform,
  StyleProp,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from 'react-native';
import { Colors } from '../../themes';

export type IssueMessageHeader = {
  updatedBy: string;
  updatedAt: string;
  fontStyle: StyleProp<TextStyle>;
  avatarSize: number;
  avatar?: Media;
  avatarIcon?: string;
  avatarIconStyle?: StyleProp<ViewStyle>;
  avatarIconColor?: string;
};

export const AnonymousId = '00000000-0000-0000-0000-000000000000';
export const AnonymousName = 'Anonymous';
export const FunctionId = '99999999-9999-9999-9999-999999999999';
export const SystemName = 'Gripp';

export type IssueAuditData = {
  who: string;
  when: string;
};
export const getIssueAuditData = (
  issue: Issue,
  orderBy: ActivityOrderBy
): IssueAuditData => {
  const isWeb = Platform.OS === 'web';

  const auditPrefix =
    orderBy === ActivityOrderBy.MostRecentReported ? 'created' : 'updated';
  const auditBy = (issue as any)[`${auditPrefix}By`];
  const auditWhen = (issue as any)[`${auditPrefix}At`];

  let who;

  if (!auditBy) {
    who = AnonymousName;
  } else {
    who = getIssueFullName(auditBy.id, auditBy.name);
  }
  return {
    who,
    when: isWeb
      ? formatShortDateAndTimeOrSameDay(auditWhen)
      : formatShortDateOrSameDay(auditWhen),
  };
};

export const getIssueMessageTextStyle = (
  message: Message
): StyleProp<TextStyle> | undefined => {
  return message.type === IssueMessageType.UserMessage
    ? undefined
    : styles.systemText;
};

export const getIssueMessageHeader = (
  issueMessage: Message
): IssueMessageHeader => {
  const header: Partial<IssueMessageHeader> = {
    avatarSize: 34,
    updatedAt: formatShortDateTimeOrSameDay(issueMessage.updatedAt),
  };

  if (
    issueMessage.type === IssueMessageType.UserMessage &&
    ![AnonymousId, FunctionId].includes(issueMessage.updatedBy.id!)
  ) {
    assignUserHeader(header, issueMessage);
  } else {
    assignSystemHeader(header, issueMessage);
  }

  return header as IssueMessageHeader;
};

export const getIssueFullName = (
  id: string | undefined,
  name: Name | undefined
) => {
  switch (id) {
    case AnonymousId:
      return AnonymousName;
    case FunctionId:
      return SystemName;
    default:
      return name ? getFullName(name) : '';
  }
};

const assignUserHeader = (
  header: Partial<IssueMessageHeader>,
  issueMessage: Message
): void => {
  header.updatedBy = getFullName(issueMessage.updatedBy.name);
  header.avatar = issueMessage.updatedBy.avatar;
};

const assignSystemHeader = (
  header: Partial<IssueMessageHeader>,
  issueMessage: Message
): void => {
  header.fontStyle = styles.systemText;
  header.avatarIcon = getSystemAvatarIcon(issueMessage);
  header.avatarIconStyle = styles.systemAvatar;
  header.avatarIconColor = Colors.white;
  header.updatedBy = getSystemUpdatedBy(issueMessage);
};

const getSystemUpdatedBy = (issueMessage: Message): string => {
  return issueMessage.updatedBy.id === AnonymousId &&
    issueMessage.type === IssueMessageType.UserMessage
    ? AnonymousName
    : SystemName;
};

const getSystemAvatarIcon = (issueMessage: Message): string => {
  return issueMessage.updatedBy.id === AnonymousId &&
    issueMessage.type === IssueMessageType.UserMessage
    ? 'incognito'
    : 'robot';
};

const styles = StyleSheet.create({
  systemText: {
    color: Colors.grayText,
  },
  systemAvatar: {
    backgroundColor: Colors.grayText,
  },
});
