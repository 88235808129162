import { Asset, GrippContext, useWorkspaceAsset } from '@gripp/shared-logic';
import {
  AssetHeader,
  BaseTheme,
  Colors,
  KeyboardSafeView,
  RoutineButtons,
  RoutineExecutionProps,
  RoutineModal,
  Spacing,
} from '@gripp/shared-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { PaperProvider, Text } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { SignatureScreenWeb } from '../../src/shared/components/signature/signatureScreenWeb';
import { Logo } from '../shared/components/logo';
import { MediaPicker } from '../shared/components/media/mediaPicker';
import { localStorageHandler } from '../shared/localStorageHandler';
import { SentryWebLogger } from '../shared/logging/sentryWebLogger';
import { ActionButtons } from './actionButtons';
import { AnonymousReported } from './anonymousReported';

type AnonymousAssetProps = {
  asset: Asset;
  tagCode: string;
};

export const AnonymousAsset = (props: AnonymousAssetProps) => {
  const { t } = useTranslation();
  const [showReported, toggleShowReported] = useState<boolean>(false);
  const [reportedMessage, setReportedMessage] = useState<{
    message: string;
    messageDownloadApp: string;
  }>();
  const { workspaceAsset } = useWorkspaceAsset(props.asset.id);
  const [routineExecutionProps, setRoutineExecutionProps] = useState<
    RoutineExecutionProps | undefined
  >();
  const [executeModalVisible, setExecuteModalVisible] = useState(false);

  const onIssueCompleted = (message: string, messageDownloadApp: string) => {
    setReportedMessage({ message, messageDownloadApp });
    toggleShowReported(true);
  };

  const onRoutineCompleted = async () => {
    setReportedMessage({
      message: t('issue.anonymous.routineCompleted'),
      messageDownloadApp: t('issue.anonymous.routineCompletedDownloadGrippApp'),
    });
    toggleShowReported(true);
  };

  function onRoutineSelected(props: RoutineExecutionProps): void {
    setRoutineExecutionProps(props);
    setExecuteModalVisible(true);
  }

  return (
    <PaperProvider theme={BaseTheme}>
      <SafeAreaProvider>
        <KeyboardSafeView>
          <GrippContext.Provider
            value={{
              logger: SentryWebLogger,
              secureStorageHandler: localStorageHandler,
              storageHandler: localStorageHandler,
              mediaPickerComponent: MediaPicker,
              signaturePadScreen: SignatureScreenWeb,
            }}
          >
            {showReported ? (
              <AnonymousReported message={reportedMessage} />
            ) : (
              workspaceAsset && (
                <View style={styles.container}>
                  <View style={styles.logo}>
                    <Logo />
                  </View>
                  <View style={styles.asset}>
                    <AssetHeader
                      workspaceAsset={workspaceAsset}
                      assetDetailsContainerStyle={styles.assetDetailsContainer}
                    />
                  </View>
                  <View style={styles.actionButons}>
                    <ActionButtons
                      asset={props.asset}
                      onCompleted={onIssueCompleted}
                      workspaceId={workspaceAsset.workspace.id}
                      buttonStyle={styles.buttonStyle}
                      titleFontSize={12}
                    />
                  </View>
                  <View style={styles.sectionContainer}>
                    <View style={styles.activityListHeaderContainer}>
                      <Text
                        variant="titleMedium"
                        style={styles.activitiesTitle}
                      >
                        {t('routines.header.title')}
                      </Text>
                    </View>
                    <View style={styles.routinesContainer}>
                      <RoutineButtons
                        workspaceAsset={workspaceAsset}
                        onRoutineSelected={onRoutineSelected}
                        showAll={true}
                        initialRoutinesToShow={3}
                        hideAssignRoutinesButton={true}
                      />
                    </View>
                  </View>
                </View>
              )
            )}
            {routineExecutionProps && (
              <RoutineModal
                {...routineExecutionProps}
                visible={executeModalVisible}
                confirmCancel={true}
                onCanceled={() => {
                  setExecuteModalVisible(false);
                }}
                onCompleted={async () => {
                  setExecuteModalVisible(false);
                  await onRoutineCompleted();
                }}
              />
            )}
          </GrippContext.Provider>
        </KeyboardSafeView>
      </SafeAreaProvider>
    </PaperProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: Colors.primaryGrayBackground,
    width: '100%',
    marginHorizontal: 0,
    paddingHorizontal: 0,
  },
  routinesContainer: {
    flex: 1,
    padding: 16,
    backgroundColor: Colors.white,
    borderRadius: 12,
  },
  logo: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderBottomColor: Colors.grayDisabled,
    borderBottomWidth: 0.5,
  },
  asset: {
    paddingVertical: 20,
    paddingLeft: 20,
  },
  actionButons: {
    paddingRight: 20,
  },
  sectionContainer: {
    marginBottom: 24,
    marginHorizontal: Spacing.baseMargin.marginHorizontal,
    height: 350,
    maxHeight: 400,
  },
  activitiesTitle: {
    fontWeight: '700',
    color: Colors.blackText,
    fontSize: 16,
  },
  activityListHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  assetDetailsContainer: {
    justifyContent: 'center',
  },
  buttonStyle: {
    maxWidth: '100%',
    fontSize: 12,
  },
});
