import { StatusState } from '@gripp/shared-logic';
import { MessageComponent, MessageComponentProps } from '../../issue/types';
import { CommonRoutineMessage } from '../components/commonRoutineMessage';

export const StatusMessage: MessageComponent = (
  props: MessageComponentProps
) => {
  const assetRoutine = props.message.assetRoutine;
  const data = assetRoutine.data as StatusState;
  const routineText = `Status: ${data.status}`;

  return <CommonRoutineMessage routineText={routineText} {...props} />;
};
