import { IssueType, Severity, useCurrentWorkspace } from '@gripp/shared-logic';
import {
  AssetMenu,
  Colors,
  InviteButton,
  OrangeButton,
  useAnimation,
  useResponsiveDesign,
} from '@gripp/shared-ui';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { Animated, Modal, StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';
import NewAssetModal from '../../assets/components/newAssetModal';
import {
  NewIssueBody,
  NewIssueModalProps,
} from '../../issues/components/newIssueModal';
import Invite from '../../shared/components/taglessModal/invite';
import { ReportContainer } from '../../shared/components/taglessModal/report';

export const OrangeButtonModal = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [showNewAsset, setShowNewAsset] = useState(false);
  const [issueModalVisible, setIssueModalVisible] = useState(false);
  const [newIssueModalProps, setNewIssueModalProps] = useState<
    NewIssueModalProps | undefined
  >(undefined);
  const currentWorkspace = useCurrentWorkspace();
  const getWorkspaceId = useMemo(
    () => currentWorkspace?.id,
    [currentWorkspace]
  );

  const openCloseAnimationDuration = 300;
  const popupMenuInitialX = 40;
  const popupMenuFinalX = 67;
  const popupMenuInitialY = 90;

  const popupMenuFinalY = 0;
  const modalOpenCloseInitialY = 100;
  const modalOpenCloseFinalY = 0;

  const {
    opacity,
    singleTranslationRef,
    translationRefXY,
    translationTwoRefXY,
    fadeIn,
    fadeOut,
    startAnimationTiming,
    startTwoComponentStaggerAnimationXY,
  } = useAnimation();

  const isResponsiveDesign = useResponsiveDesign();

  const togglePopupMenuTransparency = (isVisible: boolean) => {
    return !isVisible ? fadeOut() : fadeIn();
  };

  const openPopupMenuAnimation = () => {
    togglePopupMenuTransparency(true);

    // animations for both invite button and assetMenu buttons
    startTwoComponentStaggerAnimationXY(
      popupMenuInitialX,
      popupMenuInitialY,
      popupMenuFinalX,
      popupMenuFinalY,
      openCloseAnimationDuration,
      0
    );
  };

  const closePopupMenuAnimation = () => {
    togglePopupMenuTransparency(false);

    startTwoComponentStaggerAnimationXY(
      popupMenuFinalX,
      popupMenuFinalY,
      popupMenuInitialX,
      popupMenuInitialY,
      openCloseAnimationDuration,
      0,
      true
    );
  };

  const subModalOpenCloseAnimation = (isShown: boolean) => {
    return isShown
      ? startAnimationTiming(
          modalOpenCloseInitialY,
          modalOpenCloseFinalY,
          300,
          300
        )
      : startAnimationTiming(modalOpenCloseFinalY, modalOpenCloseInitialY);
  };

  const onOrangeButtonPress = () => {
    setModalVisible(!modalVisible);

    if (modalVisible) {
      closePopupMenuAnimation();
      onCloseAll();
    } else {
      openPopupMenuAnimation();
    }
  };

  useLayoutEffect(() => {
    showInvite || showNewAsset
      ? subModalOpenCloseAnimation(true)
      : subModalOpenCloseAnimation(false);
  }, [showInvite, showNewAsset]);

  const onCloseAll = useCallback(() => {
    subModalOpenCloseAnimation(false);
    setModalVisible(false);
    setIssueModalVisible(false);
    setShowNewAsset(false);
    setShowInvite(false);
  }, []);

  const onReportIssue = useCallback(() => {
    closePopupMenuAnimation();
    subModalOpenCloseAnimation(true);
    setNewIssueModalProps({
      workspaceId: getWorkspaceId!,
      issueType: IssueType.Report,
      severity: Severity.Red,
      isOpened: issueModalVisible,
      modalType: 'modal',
      onComplete: onCloseAll,
      onCancel: onCloseAll,
    });
    setIssueModalVisible(true);
  }, []);

  const onNewNote = useCallback(() => {
    closePopupMenuAnimation();
    subModalOpenCloseAnimation(true);
    setNewIssueModalProps({
      workspaceId: getWorkspaceId!,
      issueType: IssueType.Note,
      severity: Severity.Purple,
      isOpened: issueModalVisible,
      modalType: 'modal',
      onComplete: onCloseAll,
      onCancel: onCloseAll,
    });
    setIssueModalVisible(true);
  }, []);

  const onAddAsset = useCallback(() => {
    closePopupMenuAnimation();
    subModalOpenCloseAnimation(true);
    setShowNewAsset(true);
  }, []);

  return (
    <>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          if (modalVisible) onCloseAll();
        }}
      >
        <View style={styles.modalContainer}>
          <Animated.View
            style={[
              styles.popupMenuContainer,
              {
                opacity,
                transform: [
                  {
                    scaleX: opacity.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0.1, 1],
                    }),
                  },
                ],
              },
            ]}
          >
            <Animated.View
              style={[
                {
                  transform: [
                    { translateX: translationRefXY.x },
                    { translateY: translationRefXY.y },
                  ],
                },
              ]}
            >
              <InviteButton
                buttonOnPress={() => {
                  closePopupMenuAnimation();
                  setShowInvite(true);
                  subModalOpenCloseAnimation(true);
                }}
              />
            </Animated.View>

            <Animated.View
              style={[
                {
                  transform: [
                    { translateX: translationTwoRefXY.x },
                    { translateY: translationTwoRefXY.y },
                  ],
                },
              ]}
            >
              <AssetMenu
                onReportIssue={onReportIssue}
                onAddNote={onNewNote}
                onAddAsset={onAddAsset}
              />
            </Animated.View>
          </Animated.View>

          <View
            style={[
              styles.orangeButtonContainer,
              isResponsiveDesign ? { minWidth: 140 } : { minWidth: 180 },
            ]}
          >
            <OrangeButton
              onButtonPress={onOrangeButtonPress}
              isMenuVisible={modalVisible}
              propStyle={styles.orangeButtonStyle}
            />
          </View>

          {showNewAsset && (
            <Animated.View
              style={{
                opacity: singleTranslationRef.interpolate({
                  inputRange: [0, 100],
                  outputRange: [1, 0],
                }),
                transform: [{ translateY: singleTranslationRef }],
              }}
            >
              <NewAssetModal closeModal={onCloseAll} />
            </Animated.View>
          )}

          {showInvite && (
            <Animated.View
              style={{
                opacity: singleTranslationRef.interpolate({
                  inputRange: [0, 100],
                  outputRange: [1, 0],
                }),
                transform: [{ translateY: singleTranslationRef }],
              }}
            >
              <Invite
                headerCancel={() => {
                  subModalOpenCloseAnimation(false);
                  onCloseAll();
                }}
              />
            </Animated.View>
          )}
          {issueModalVisible && newIssueModalProps && (
            <Animated.View
              style={{
                opacity: singleTranslationRef.interpolate({
                  inputRange: [0, 100],
                  outputRange: [1, 0],
                }),
                transform: [{ translateY: singleTranslationRef }],
              }}
            >
              <ReportContainer>
                <NewIssueBody {...newIssueModalProps} />
              </ReportContainer>
            </Animated.View>
          )}
        </View>
        <Toast />
      </Modal>

      <View
        style={[
          styles.orangeButtonContainer,
          isResponsiveDesign ? { minWidth: 140 } : { minWidth: 180 },
        ]}
      >
        <OrangeButton
          onButtonPress={onOrangeButtonPress}
          isMenuVisible={modalVisible}
          propStyle={styles.orangeButtonStyle}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: 180,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRightColor: Colors.grayDivider,
    borderRightWidth: 1,
    borderStyle: 'solid',
    paddingVertical: 20,
    paddingLeft: 40,
    paddingRight: 0,
  },
  modalContainer: {
    backgroundColor: Colors.blackInactive,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  popupMenuContainer: {
    width: 200,
    height: 276,
    position: 'absolute',
    left: 0,
    bottom: 84,
    justifyContent: 'flex-start',
  },
  orangeButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  orangeButtonStyle: {
    bottom: 32,
  },
});
