import { useQueryClient } from '@tanstack/react-query';
import { cacheKeys, useUpdateMutation } from '../../graphql';

export const useSetActivityInboxRead = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useUpdateMutation({
    modelName: 'activityInbox',
    query: UPDATE_ACTIVITY_INBOX,
  });

  return {
    setAsRead: (issueId: string, inboxes: any[], invalidate: boolean) => {
      const promises = inboxes
        .filter((i) => i.data.id === issueId)
        .map(async (inbox) => {
          await mutateAsync({
            input: {
              id: inbox.id,
              read: true,
            },
          });
        });

      return Promise.all(promises).then(async () => {
        if (invalidate) {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          await queryClient.invalidateQueries({
            queryKey: cacheKeys('activityInbox').lists(),
          });
        }
      });
    },
  };
};

const UPDATE_ACTIVITY_INBOX = `
  mutation updateActivityInbox($input: MutateActivityInboxInput!) {
    updateActivityInbox(input: $input) {
      id
    }
  }
`;
