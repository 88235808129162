import {
  CounterQuestionConfig,
  CounterResponseState,
} from '@gripp/shared-logic';
import { Text } from 'react-native-paper';
import { RoutineQuestionMessage, RoutineQuestionMessageProps } from '../types';

export const CounterQuestionMessage: RoutineQuestionMessage<
  CounterQuestionConfig,
  CounterResponseState
> = (
  props: RoutineQuestionMessageProps<
    CounterQuestionConfig,
    CounterResponseState
  >
) => {
  const { response, question } = props;

  return (
    <Text style={props.textStyle}>
      {response.count} {question.uom}
    </Text>
  );
};
