import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ILogger, MediaStatus, useGrippContext } from '@gripp/shared-logic';
import { useCallback } from 'react';
import { Linking, StyleSheet, Text } from 'react-native';
import { Card, ProgressBar } from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { getMediaTitleForWidth } from './mediaUtils';
import { ProcessingPlaceholder } from './processingPlaceholder';
import { DocumentData, MediaComponent, MediaComponentProps } from './types';

// app + web, will need to separate the web and app components if the functionality diverges further
export const DocumentComponent: MediaComponent<DocumentData> = ({
  displayMode = 'card',
  ...props
}: MediaComponentProps<DocumentData>) => {
  const { logger } = useGrippContext();
  const documentCardStyle =
    displayMode === 'card'
      ? styles.documentThumbCard
      : styles.documentOutlinedCard;
  const defaultWidth = 80;
  const defaultHeight = 80;
  const dipslayWidth = props.displayWidth ?? defaultWidth;
  const displayHeight =
    props.displayWidth && props.displayWidth > defaultHeight
      ? props.displayWidth / 2
      : defaultHeight;
  const fileIconSize = dipslayWidth / 4;

  const handlePress = useCallback(
    () => downloadDocument(props.mediaItem.status, props.mediaItem.url, logger),
    [props.mediaItem.status, props.mediaItem.url, logger]
  );

  return (
    <ProcessingPlaceholder
      processing={props.mediaItem.showProcessing ?? false}
      width={dipslayWidth}
      height={displayHeight}
      displayMode={displayMode}
      url={props.mediaItem.url}
      name={props.mediaItem.name}
      extension={props.mediaItem.extension}
    >
      <Card
        onPress={handlePress}
        mode={displayMode === 'outlined' ? 'outlined' : 'elevated'}
        style={[
          styles.baseCard,
          documentCardStyle,
          { width: dipslayWidth, height: displayHeight },
        ]}
      >
        <Card.Content
          style={[
            styles.cardContent,
            { width: dipslayWidth, height: displayHeight },
          ]}
        >
          <MaterialCommunityIcons
            style={styles.icon}
            name={'file'}
            color={Colors.primaryDark}
            size={fileIconSize}
          />
          <Text style={styles.documentTitle}>
            {getMediaTitleForWidth(
              props.mediaItem.name,
              props.mediaItem.extension,
              dipslayWidth
            )}
          </Text>
        </Card.Content>
        <ProgressBar
          animatedValue={props.mediaItem.uploadProgress}
          visible={props.mediaItem.isUploading ?? false}
        />
      </Card>
    </ProcessingPlaceholder>
  );
};

const downloadDocument = async (
  mediaStatus: MediaStatus,
  url: string,
  logger?: ILogger
) => {
  if (isLocalUrl(url)) {
    await openUrl(url);
    return;
  }
  fetch(url, { method: 'GET' })
    .then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        await openUrl(data.downloadUrl);
      }
    })
    .catch((e) => {
      logger?.error(e, `Failed to download document from ${url}`);
    });
};

const openUrl = async (url: string) => {
  const supported = await Linking.canOpenURL(url);
  if (supported) {
    await Linking.openURL(url);
  }
};

const isLocalUrl = (url: string) => {
  return url.startsWith('blob:') || url.startsWith('file://');
};

const styles = StyleSheet.create({
  icon: {
    alignSelf: 'center',
  },
  baseCard: {
    borderRadius: 8,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.secondaryGrayHeader,
    cursor: 'pointer',
  },
  cardContent: {
    paddingHorizontal: 2,
  },
  documentThumbCard: {},
  documentOutlinedCard: {
    border: 0.5,
    borderColor: Colors.grayDivider,
  },
  documentTitle: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 12,
    width: '100%',
  },
});
