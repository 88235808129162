import {
  ActivityInboxType,
  ISSUE_MESSAGE_PAGE_COUNT,
  Issue,
  Message,
  useFindIssueMessages,
  useNewIssueMessageSubscription,
  useUnreadActivityInboxSubscription,
} from '@gripp/shared-logic';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { Layout } from '../../styles';
import { Colors } from '../../themes';
import { IssueMessage } from './issueMessage';

export type IssueMessagesProps = {
  issue: Issue;
  onMessagesCountChange?: (messagesCount: number) => void;
  mediaUrl: string;
  inbox: any[];
  tsUpdate?: string;
  setAsRead: (
    issueId: string,
    inboxes: any[],
    invalidate: boolean
  ) => Promise<void>;
};

export type IssueMessagesRef = {
  addNewMessage: (message: Message) => void;
};

export const IssueMessages = forwardRef<IssueMessagesRef, IssueMessagesProps>(
  (props, ref) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const {
      data,
      isLoading,
      hasNextPage,
      isFetchingNextPage,
      fetchNextPage,
      refetch,
    } = useFindIssueMessages(props.issue.id);
    // if a user goes back and then revisits this page, data might be cached and we could already have more than 1 page
    const [page, setPage] = useState(data?.pages?.length || 0);
    const initialPages = data?.pages?.length || 0;

    useNewIssueMessageSubscription(props.issue.id, (response) => {
      addNewMessage({ ...response.data.newIssueMessage, isNew: true });
      return Promise.resolve();
    });

    useUnreadActivityInboxSubscription(async (inboxItem) => {
      if (inboxItem.data.id === props.issue.id) {
        await props.setAsRead(props.issue.id, [inboxItem], false);
      }
    });

    const addNewMessage = (message: Message) => {
      setMessages((prevMessages: Message[]) => [message, ...prevMessages]);
    };

    useImperativeHandle(ref, () => ({
      addNewMessage: addNewMessage,
    }));

    useEffect(() => {
      if (!props.tsUpdate) return;

      refetch();
    }, [props.tsUpdate, refetch]);

    useEffect(() => {
      if (
        isLoading ||
        isFetchingNextPage ||
        !data ||
        !data.pages ||
        data.pages.length === 0
      )
        return;

      if (page > 0 && page > initialPages) {
        setMessages((prevMessages) => [
          ...prevMessages,
          ...data.pages[page].items,
        ]);
      } else {
        setTotalCount(data.pages[0].count);
        props.onMessagesCountChange &&
          props.onMessagesCountChange(data.pages[0].count);
        setMessages(data.pages.flatMap((page) => page?.items));
      }
    }, [data, initialPages, isFetchingNextPage, isLoading, page]);

    const onEndReached = () => {
      if (!totalCount || totalCount <= ISSUE_MESSAGE_PAGE_COUNT) return;
      if (!isFetchingNextPage && hasNextPage) {
        setPage(page + 1);
        fetchNextPage();
      }
    };

    const isUnread = (message: any) => {
      if (!props.inbox) {
        return false;
      }

      return (
        message.isNew ||
        props.inbox.some(
          (i) =>
            i.type === ActivityInboxType.IssueMessageCreated &&
            i.data.messageId === message.id
        )
      );
    };

    return (
      <View style={[styles.container]}>
        {isFetchingNextPage && (
          <View>
            <ActivityIndicator
              size="large"
              animating={isLoading || isFetchingNextPage}
            />
          </View>
        )}
        <FlatList
          refreshing={isFetchingNextPage}
          data={messages}
          extraData={messages}
          inverted={true}
          onEndReached={onEndReached}
          renderItem={({ item }) => (
            <IssueMessage
              key={item.id}
              message={item}
              mediaUrl={props.mediaUrl}
              isUnread={isUnread(item)}
              issue={props.issue}
            />
          )}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    ...Layout.container,
    backgroundColor: Colors.white,
  },
});
