import { I18n } from '../../../../I18n';
import { WorkflowQuestionConfig } from '../workflowQuestionConfig';
import { WorkflowResponseState } from '../workflowResponseState';
import { QuestionExceptionConfig } from './questionException';

export interface TextQuestionConfig
  extends WorkflowQuestionConfig,
    QuestionExceptionConfig {
  multiline?: boolean;
}

export interface TextResponseState extends WorkflowResponseState {
  value?: string;
  i18n?: I18n;
}
