import { Colors, DesktopGrippLogoBlackWrench } from '@gripp/shared-ui';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useMenuPermision } from '../hooks/useMenuPermision';
import { IMenu } from './menuItems';
import { NavLink } from './menuLink';
import { OrangeButtonModal } from './orangeButtonMenu';

export const Menu = () => {
  const menuList = useMenuPermision();

  return (
    <View style={styles.container}>
      <View>
        <View style={styles.logoContainer}>
          <DesktopGrippLogoBlackWrench />
        </View>
        <View style={styles.menuList}>
          {menuList().map((group, gdx) => {
            return (
              <React.Fragment key={gdx}>
                {gdx > 0 && group?.length > 0 && (
                  <View style={styles.divider} />
                )}
                {group?.map((link: IMenu, index: number) => {
                  const ComponentToRender = link.component || NavLink;
                  return <ComponentToRender key={index} {...link} />;
                })}
              </React.Fragment>
            );
          })}
        </View>
      </View>
      <OrangeButtonModal />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundColor: Colors.black,
  },
  logoContainer: {
    paddingVertical: 18,
    paddingHorizontal: 28,
    width: 180,
  },
  menuList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: 16,
  },
  divider: {
    height: 0.5,
    backgroundColor: Colors.graySpacer,
    width: '100%',
    marginVertical: 4,
  },
});
