import { FC } from 'react';
import { CounterConfig, CounterState } from '@gripp/shared-logic';
import { SubHeaderText } from '../../asset/subHeaderText';
import { RoutineHeaderProps } from '../types';
import { CounterComponentProps } from './types';

interface CounterHeaderProps
  extends CounterComponentProps,
    RoutineHeaderProps<CounterConfig, CounterState> {}

export const CounterHeader: FC<CounterHeaderProps> = (
  props: CounterHeaderProps
) => {
  const { config, currentState } = props;

  return (
    <SubHeaderText>
      {`${currentState?.state.count} ${config.uom}`}
    </SubHeaderText>
  );
};
