import { MaterialCommunityIcons } from '@expo/vector-icons';
import { MediaType } from '@gripp/shared-logic';
import { Pressable, StyleSheet, View } from 'react-native';
import { Colors } from '../../themes';
import { DocumentComponent } from './documentComponent';
import { ImageComponent } from './imageComponent';
import { MediaComponent, MediaComponentProps, MediaData } from './types';

export type RenderMediaItemProps<T extends MediaData> =
  MediaComponentProps<T> & {
    canDelete?: boolean;
    deleteMediaItem?: (mediaId: string) => void;
  };

const MediaComponents: Record<MediaType, MediaComponent<any>> = {
  [MediaType.Document]: DocumentComponent,
  [MediaType.Image]: ImageComponent,
  [MediaType.Video]: () => null,
  [MediaType.Audio]: () => null,
};

export const RenderMediaItem = <T extends MediaData>(
  props: RenderMediaItemProps<T>
) => {
  const Component = MediaComponents[props.mediaItem.type];

  return (
    <View style={props.canDelete && styles.mediaItemContainer}>
      {props.canDelete && (
        <Pressable
          hitSlop={10}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 100,
          }}
          onPress={() =>
            props.deleteMediaItem && props.deleteMediaItem(props.mediaItem.id)
          }
        >
          <MaterialCommunityIcons
            name="close-circle"
            color={Colors.primary}
            size={24}
          />
        </Pressable>
      )}
      <Component {...props} />
    </View>
  );
};

const styles = StyleSheet.create({
  mediaItemContainer: {
    alignSelf: 'center',
    paddingRight: 10,
    paddingTop: 12,
  },
});
