export const scaleDimensions = (
  maxDimension: number,
  width: number,
  height: number
) => {
  if (width >= height) {
    const newWidth = maxDimension;
    const newHeight = maxDimension * (height / width);
    return { width: newWidth, height: newHeight };
  } else {
    const newHeight = maxDimension;
    const newWidth = maxDimension * (width / height);
    return { width: newWidth, height: newHeight };
  }
};

export const scaleToWidth = (
  maxWidth: number,
  width: number,
  height: number
) => {
  const newWidth = maxWidth;
  const newHeight = maxWidth * (height / width);
  return { width: newWidth, height: newHeight };
}

export const scaleToHeight = (
  maxHeight: number,
  width: number,
  height: number
) => {
  const newHeight = maxHeight;
  const newWidth = maxHeight * (width / height);
  return { width: newWidth, height: newHeight };
}